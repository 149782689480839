import { lazy } from 'react';
import PrivateRoute from 'utils/privateRoute';
import Machines from 'components/machines/machines';
import reasonsOfSLA from 'components/reasonsOfSLA/reasonsOfSLA';
import Processes from 'components/processes/processes';
import Sectors from 'components/sectors/sectors';
import Systems from 'components/systems/systems';

// const Machines = lazy(() => import('components/machines/machines'));
// const reasonsOfSLA = lazy(() => import('components/reasonsOfSLA/reasonsOfSLA'));
// const Processes = lazy(() => import('components/processes/processes'));
// const Sectors = lazy(() => import('components/sectors/sectors'));
// const Systems = lazy(() => import('components/systems/systems'));

const MasterSystemRoutes = [
	{
		key: 'mast_systems',
		route: '/master/systems',
		component: (
			<PrivateRoute
				Component={Systems}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'mast_sectors',
		route: '/master/sectors',
		component: (
			<PrivateRoute
				Component={Sectors}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'mast_proc',
		route: '/master/processes',
		component: (
			<PrivateRoute
				Component={Processes}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'mast_mach',
		route: '/master/machines',
		component: (
			<PrivateRoute
				Component={Machines}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'mast_reas_sla',
		route: '/master/reasonsOfSLA',
		component: (
			<PrivateRoute
				Component={reasonsOfSLA}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	}
];

export default MasterSystemRoutes;
