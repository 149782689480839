import React, { useEffect, useRef, useState } from 'react';
import {
	Button,
	Chip,
	Grid,
	Menu,
	Stack,
	TextField,
	List,
	ListItem,
	ListItemText,
	FormControlLabel,
	Select,
	InputLabel,
	FormControl,
	Tooltip
} from '@mui/material';
import { dashboardUrl, isEnvTypePROD } from '../../service/config';
import AnalyticsVisualization from '../framework/analyticsVisualization';
import '../TableauObs/Summary/Summary.scss';
// import Workbooks from './Workbooks';
// import TableauUsers from '../../TableauObs/Summary/Users';
import moment from 'moment';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as DatePickerIcon } from '../../assets/calendar-outline_white.svg';
import { ReactComponent as FilterIcon } from '../../assets/ml_summary_filter.svg';
import { ReactComponent as ServerIcon } from '../../assets/solar_server-outline.svg';
import { getCostSummaryChart, getDecodedToken, getSector } from '../../service/service';
import useStyles from '../jobs/jobs_styles';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ToolTipIcon from "../../assets/info-icon.svg";

import '../DataEstate/data-estate.scss';
import ReactECharts from 'echarts-for-react';
import { fetchDEStorageSummary, fetchDEStorageTrends, getSectorSql, fetchDEStorageTable } from '../../service/service';
import { ReactComponent as CalendarIcon } from '../../assets/calendar-outline_white.svg';
import ArrowUpBlackIcon  from '../../assets/up-arrow-black.svg';
import { getPercentageOfTwoNumbers , formatByByte } from '../../utils/common-util';
import { ReactComponent as Downarrow } from '../../assets/down-arrow.svg';
import { CustomizedDataGrid } from '../customized_data_grid';
import { Row, type Column } from 'react-data-grid';
import Loader from '../Loader/loader';
import NoPrgramFound from '../../assets/no-program-found.svg';

const StorageSummary = () => {	
	const [searchParams, setSearchParams] = useSearchParams();
	const hasDrillDown = searchParams.has('isDrillDowned');
	const minJobExecDate = '2023-03-01';
	const dateFrom: string = searchParams.get('fromDate')?.substring(0, 10) as string;
	const startDt = moment(dateFrom).startOf('day').subtract(1, 'hours').format('YYYY-MM-DD');
	const [loading, setLoading] = useState(false);
	const [fromDateTime, setFromDateTime] = useState<any>(
		searchParams.has('fromDate') ? startDt : moment().startOf('day').subtract(1, 'hours')
	);

	
	const [propsFromDateTime, setPropsFromDateTime] = useState<any>(
		searchParams.has('fromDate') ? startDt : moment().startOf('day').subtract(1, 'hours')
	);
	
		// sector filter
		const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
		const [filterMenu, setFilterMenu] = useState<{
			mouseX: number;
			mouseY: number;
		} | null>(null);
		
		const [dateFilterMenu, setDateFilterMenu] = useState<{
			mouseX: number;
			mouseY: number;
		} | null>(null);

		const classes = useStyles();
		const [itemsChecked, setItemsChecked] = useState(false);
		const [sectorOptionsList, setSectorOptionsList] = useState<any[]>([]);
		const [checkedList, setCheckedList] = useState<any[]>([]);
		const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);
		const [sector, setCurrSector] = useState<any[]>([]);
		const dataFetchedRef = useRef(false);

		const [teradataStorageLastYear, SetTeradataGrowthLastYear] = useState(0);
		const [teradataStorageCurrent, SetTeradataGrowthCurrent] = useState(0);
		const [teradataBubbleValue, SetTeradataBubbleValue] = useState(0)
		const [teradataStorageStartMonth, setTeradataStorageStartMonth] = useState('');
		const [isNoAnnualTeradataStorage, setIsNoAnnualTeradataStorage] = useState(false);
	
		const [azureStorageLastYear, SetAzureStorageLastYear] = useState(0);
		const [azureStorageCurrent, SetAzureStorageCurrent] = useState(0);
		const [azureBubbleValue, SetAzureBubbleValue] = useState(0)
		const [azureStorageStartMonth, setAzureStorageStartMonth] = useState('');
		const [isNoAnnualAzureStorage, setIsNoAnnualAzureStorage] = useState(false);
	
		const [TeradataSize, SetTeradataSize] = useState('-')
		const [AdlsSize, SetAdlsSize] = useState('-')
		const [AzureSqlSize, SetAzureSqlSize] = useState('-')
		const [AzureSynapse, SetAzureSynapse] = useState('-')
		const [AzureCosmos, SetAzureCosmos] = useState('-')
	
		const [TeradataSizeRaw, SetTeradataSizeRaw] = useState(0);
		const [AdlsSizeRaw, SetAdlsSizeRaw] = useState(0);
		const [AzureSqlSizeRaw, SetAzureSqlSizeRaw] = useState(0);
		const [AzureSynapseRaw, SetAzureSynapseRaw] = useState(0);
		const [AzureCosmosRaw, SetAzureCosmosRaw] = useState(0);

		const [grafanaAzureLoading, setGrafanaAzureLoading] = useState(true);
		const [grafanaAzureOptions, setGrafanaAzureOptions] = useState<any>()
		const [grafanaTeradataLoading, setGrafanaTeradataLoading] = useState(true);
		const [grafanaTeradataOptions, setGrafanaTeradataOptions] = useState<any>()
		const [filterMonth, setFilterMonth] = useState("0M");
		const [filterDefaultMonth, setFilterDefaultMonth] = useState("0M");
		const [customYear, setCustomYear] = useState(moment().format("YYYY"));
        const [isDateOpen, setIsDateOpen] = useState(false);
		const isCustomMonth = useRef(false);
		const [byteValue, setByteValue] = useState('PB');
		const selectedByte = useRef('PB');

		const [teradataRes, setTeradataRes] = useState<any[]>([]);
		const [azureRes, setAzureRes] = useState<any[]>([]);

		function formatNumber(number:any) {
			if (number != null && !isNaN(number)) {
				return Number(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
			} else {
				return "0.00";
			}
		}

		// function formatByByte(selectByte: string, kpiValue: any) {
		// 	return (selectByte === 'PB') ? kpiValue : (selectByte === 'TB') ? kpiValue*1024 : (kpiValue*1024)*1024; 
		// }

		const dateFilterMenuClick = (event: any) => {
			setDateFilterMenu(
				dateFilterMenu === null
					? {
							mouseX: parseInt(event.clientX) - 260,
							mouseY: parseInt(event.clientY) - 50
					  }
					: null
			);
		};
		const handleDateFilterMenuClose = (event: any) => {
			setDateFilterMenu(null);
		};
	
	const sectorFilterClick = (event: any) => {
		event.preventDefault();
		setFilterMenu(
			filterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 20,
						mouseY: parseInt(event.clientY) - 6
				  }
				: null
		);
	};

	const handleClose = (event: any) => {
		setFilterMenu(null);
	};

	const selectAllCheck = (e: any) => {
		const { checked } = e.target;
		const collection: any[] = [];
		if (checked) setCheckedList(sectorOptionsList);
		else setCheckedList([]);
		setItemsChecked(checked);
	};

	const handleChange = (e: any) => {
		const { value, checked } = e.target;
		const newlyChecked = [];
		if (checked && e.target.value) {
			newlyChecked.push(e.target.value);
		}
		if (checked) {
			setCheckedList([...checkedList, ...newlyChecked]);
			if(sectorOptionsList.length === (checkedList.length + newlyChecked.length)){
				setItemsChecked(true);
			}
		} else {
			setCheckedList(checkedList.filter((item) => item !== value));
			setItemsChecked(false);
		}
	};
	const handleOkClick = (event: any) => {
		clearMetricsSummary();
		localStorage.setItem('selectedSector', JSON.stringify(checkedList)); // Tableau drilldown filter changes
		if (checkedList.length > 0) {
			setCurrSectorFilter(checkedList);
		} else {
			setCurrSectorFilter([]);
		}
		getStorageSummaryDetails(checkedList, filterMonth)
		setCurrSector( checkedList );
		handleClose(event);
	};

	async function fetchSectorOptionsData() {
		const sectorRes = await getSector('1', '100', '');
		if (sectorRes.success) {
			const filteredSectorOptions = sectorRes.data.sector.filter((x: any) => x.name !== 'S&T').map((x: any) => x.name);
			filteredSectorOptions.push('Blank');
        	setSectorOptionsList(filteredSectorOptions);
		}
	}

	const teradataTrend = (resData : any) => {
		let allDataAsArray: any = Object.entries(resData);
		const allMonths: any = Object.keys(resData);			 
	   let currentMonth = new Date().getMonth();
	   if (currentMonth == 0)
		   currentMonth = 12
	   const firstMonthFromAllMonths = allMonths[0];
	   const lastMonth = moment().subtract(1, 'months').format('MM');
	   const indexValue = moment().year() + '-' + lastMonth;
	   const lastIndex = allMonths.indexOf(indexValue);
	   const lastMonthFromAllMonths = allMonths[lastIndex];
	   const lastMonthFromForecast = allMonths[allMonths.length - 1];

	   const currentYear = new Date().getFullYear();
	   const lastMonthString = (currentMonth).toString().padStart(2, '0');
	   const filterMonth = `${currentYear-1}-${lastMonthString}` //(currentYear-1) +'-12';
	   const filterDateEntry = Object.entries(resData).filter(
		   x => x[0] === filterMonth
	   );
	   if(filterDateEntry.length) {
		   let count: any = 0;
		   count = filterDateEntry[0][1];
		   const lastMonthDataIndex = `${currentYear}-${lastMonthString}`;
		   const lastMonthEntry = Object.entries(resData).filter(
				   x => x[0] === lastMonthDataIndex
		   );
		   if (lastMonthEntry.length){
			   setIsNoAnnualTeradataStorage(false);	
			   const lastmonthvalue = lastMonthEntry[0][1] as number;
			   let lastMonthVal = formatByByte(selectedByte.current, lastmonthvalue);
			   let lastYearVal = formatByByte(selectedByte.current, count);
			   SetTeradataGrowthCurrent(lastMonthVal);
			   SetTeradataGrowthLastYear(lastYearVal)
			   SetTeradataBubbleValue(getPercentageOfTwoNumbers(count, lastmonthvalue));
		   }
	   }
	   else
	   {
		   const annualMonth = moment(firstMonthFromAllMonths, 'YYYY-MM').format('MMMM YYYY');
		   setTeradataStorageStartMonth('Data available from ' + annualMonth);
		   setIsNoAnnualAzureStorage(true);	
	   }
	   
	   const option = {
		   title: {
			   show: Object.keys(allDataAsArray).length == 0 ? true : false,
			   textStyle:{
				 color:'#bcbcbc',
				 fontWeight: 'normal',
				 fontSize: 16
			   },
			   text: 'No data for the selected filters',
			   left: 'center',
			   top: 'center'
			 },
		   backgroundColor: '#053367',
		   grid: {
			 top: '13%',
			 left: '10%',
			 right: '2%',
			 bottom: '12%'
		   },
		   xAxis: {
			   type: 'category',
			   splitLine: { 
				   show: true, 
				   lineStyle: { 
					   type: 'dashed',
					   color: '#004477'
				   }
			   },
		   data: allMonths,
			   axisLabel: {
				   rotate: 30,
				   color: '#A7ADCB',
				   formatter: function (value: any) {
					   return `${moment(value).format('MMM-YY')}`;
					 }
				},
		   },
		   yAxis: {
			   type: 'value',
			   splitLine: { 
				   show: true, 
				   lineStyle: { 
					   type: 'dashed',
					   color: '#004477'
				   }
			   },
		   axisLabel: { 
			   color: '#A7ADCB',
				   rotate: selectedByte.current === 'GB' ? 45 : 0,
				   formatter: function (value: any) {
					   let optValue = formatByByte(selectedByte.current, value);
					   return `${new Intl.NumberFormat().format(optValue)}`;
					}
			   },
		   },				
		   visualMap: {
			   show: false,
			   dimension: 0,
			   pieces: [
				 {
				   lte: lastIndex,
				   color: '#009BD8'
				 },
				 {
				   gt: lastIndex,
				   lte: allMonths.length - 1,
				   color: '#FD783B'
				 }
			   ]
			 },
		   series: [
			   {
				 name: 'Current Growth',
				 type: 'line',
				 data: allDataAsArray,
				 markArea: {
				   itemStyle: {
					 color: 'rgba(255, 173, 177, 0.1)'
				   },
				   data: [
					   [
						   {
							   name: 'Current Growth',
							   xAxis: firstMonthFromAllMonths
						   },
						   {
							   xAxis: lastMonthFromAllMonths
						   }
					   ],
					   [
						   {
							   name: 'Forecast',
							   xAxis: moment().format('YYYY-MM')
						   },
						   {
							   xAxis: lastMonthFromForecast
						   }
					   ]
				   ]
				 }
			   }
		   ],
		   tooltip: {
			   trigger: 'axis',
			   formatter: (params: any) => {
				 const { name, seriesName, value } = params[0]; 
				 let optValue = formatByByte(selectedByte.current, value[1]);
				 return `${moment(value[0]).format('MMM-YY')}: ${formatNumber(optValue)} ${selectedByte.current}`; 
			   },
			   backgroundColor: '#053367',
			   textStyle: {
				   color: '#A7ADCB' 
				 }
			 }
	   };
	   setGrafanaTeradataOptions(option);
	   setGrafanaTeradataLoading(false);
	}

	const azureTrend = (resData : any) => {
		let allDataAsArray = Object.entries(resData);
		const allMonths = Object.keys(resData);

		const firstMonthFromAllMonths = allMonths[0];
		const lastMonth = moment().subtract(1, 'months').format('MM');
		const indexValue = moment().year() + '-' + lastMonth;
		const lastIndex = allMonths.indexOf(indexValue);
		const lastMonthFromAllMonths = allMonths[lastIndex];
		const lastMonthFromForecast = allMonths[allMonths.length - 1];
		const currentYear = new Date().getFullYear();
		let currentMonth = new Date().getMonth();
		if (currentMonth == 0)
			currentMonth = 12
		const lastMonthString = (currentMonth).toString().padStart(2, '0');
		const filterMonth = `${currentYear-1}-${lastMonthString}` //(currentYear-1) +'-12';
		const filterDateEntry = Object.entries(resData).filter(
			x => x[0] === filterMonth
		);
		if(filterDateEntry.length) {
			let count: any = 0;
			count = filterDateEntry[0][1];
			const lastMonthDataIndex = `${currentYear}-${lastMonthString}`;
			const lastMonthEntry = Object.entries(resData).filter(
					x => x[0] === lastMonthDataIndex
			);
			if (lastMonthEntry.length){
				setIsNoAnnualAzureStorage(false);	
				const lastmonthvalue = lastMonthEntry[0][1] as number;
				let lastMonthVal = formatByByte(selectedByte.current, lastmonthvalue);
			   	let lastYearVal = formatByByte(selectedByte.current, count);
				SetAzureStorageCurrent(lastMonthVal)
				SetAzureStorageLastYear(lastYearVal)
				SetAzureBubbleValue(getPercentageOfTwoNumbers(count, lastmonthvalue));
			}
		}
		else
		{
			const annualMonth = moment(firstMonthFromAllMonths, 'YYYY-MM').format('MMMM YYYY');
			setAzureStorageStartMonth('Data available from ' + annualMonth);
			setIsNoAnnualAzureStorage(true);	
		}
		const option = {
			title: {
				show: Object.keys(allDataAsArray).length == 0 ? true : false,
				textStyle:{
					color:'#bcbcbc',
					fontWeight: 'normal',
					fontSize: 16
				},
				text: 'No data for the selected filters',
				left: 'center',
				top: 'center'
				},
			backgroundColor: '#053367',
			grid: {
				top: '13%',
				left: '10%',
				right: '2%',
				bottom: '12%'
			},
			xAxis: {
				type: 'category',
				splitLine: { 
					show: true, 
					lineStyle: { 
						type: 'dashed',
						color: '#004477'
					}
				},
			data: allMonths,
				axisLabel: {
					rotate: 30,
					color: '#A7ADCB',
					formatter: function (value: any) {
						return `${moment(value).format('MMM-YY')}`;
						}
					},
			},
			yAxis: {
				type: 'value',
				splitLine: { 
					show: true, 
					lineStyle: { 
						type: 'dashed',
						color: '#004477'
					}
				},
			axisLabel: { 
					color: '#A7ADCB',
					rotate: selectedByte.current === 'GB' ? 45 : 0,
					formatter: function (value: any) {
						let optValue = formatByByte(selectedByte.current, value);
						return `${new Intl.NumberFormat().format(optValue)}`;
					}
				},
			},
			visualMap: {
				show: false,
				dimension: 0,
				pieces: [
				{
					lte: lastIndex,
					color: '#009BD8'
				},
				{
					gt: lastIndex,
					lte: allMonths.length - 1,
					color: '#FD783B'
				}
				]
				},
			series: [
				{
					name: 'Current Growth',
					type: 'line',
					smooth: false,
					data: allDataAsArray,
					markArea: {
					itemStyle: {
						color: 'rgba(255, 173, 177, 0.1)'
					},
					data: [
						[
						{
							name: 'Current Growth',
							xAxis: firstMonthFromAllMonths
						},
						{
							xAxis: lastMonthFromAllMonths
						}
						],
						[
						{
							name: 'Forecast',
							xAxis: moment().format('YYYY-MM')
						},
						{
							xAxis: lastMonthFromForecast
						}
						]
					]
					}
				}
			],
			tooltip: {
				trigger: 'axis',
				formatter: (params: any) => {
					const { name, seriesName, value } = params[0]; 
				let optValue = formatByByte(selectedByte.current, value[1]);
				return `${moment(value[0]).format('MMM-YY')}: ${formatNumber(optValue)} ${selectedByte.current}`; 
				},
				backgroundColor: '#053367',
				textStyle: {
					color: '#A7ADCB' 
					}
				}
		};
		setGrafanaAzureOptions(option);
		setGrafanaAzureLoading(false);
	}

	async function getStorageSummaryDetails(sectors: any, month: any) {
		let sector = sectors.length == sectorOptionsList.length ? [] : sectors;
		// Trend APIs
		SetTeradataSize('-')
		SetAdlsSize('-')
		SetAzureSqlSize('-')
		SetAzureSynapse('-')
		SetAzureCosmos('-')

		setTeradataRes([]);
		setAzureRes([]);

		// setGrafanaTeradataLoading(true);
		// Teradata
		fetchDEStorageTrends('storage', 'teradata', sector, month).then(res => {
			// let allDataAsArray: any = Object.entries(res.data).splice(0, moment().month()+1);
			// const allMonths: any = Object.keys(res.data).splice(0, moment().month()+1);

			// allDataAsArray.sort((a: any, b: any) => a[0].substring(5,7) - b[0].substring(5,7))
			// allMonths.sort((a: any, b: any) => a.substring(5,7) - b.substring(5,7))

			// const currentMonth = new Date().getMonth();
			// const pastMonths = Object.entries(allDataAsArray).filter(([date]) => new Date(date).getMonth()+1 <= currentMonth).splice(0, moment().month()+1);
			setTeradataRes(res.data);
			teradataTrend(res.data);
		}).catch((error: any) => { 
			console.error(error);
		})
		// Azure
		fetchDEStorageTrends('storage', 'azure', sector, month).then(res => {
			setAzureRes(res.data);
			azureTrend(res.data);
		}).catch((error: any) => { 
			console.error(error);
		})
		

		// Summary APIs
		fetchDEStorageSummary(sector, month).then(res => {
			let teradataSize = res?.data?.dataStoredInTeradata?.[0]?.["VALUE"] ?? 0;
			let teradataLastYearSize = res?.data?.dataStoredInTeradataLastYear?.[0]?.["VALUE"] ?? 0;
			
			// const resData: any = Object.entries(res?.data?.dataStoredInTeradata[0].VALUE).filter(([date]) => new Date(date));
			// // let teradataCurrentMonthSize = resData.length ? resData[0][1].dev + resData[0][1].uat + resData[0][1].prod : 0;
			// let teradataCurrentMonthSize = resData.length ? resData[0][1].prod : 0;
			SetTeradataSize(formatByByte(byteValue, teradataSize));
			SetTeradataSizeRaw(teradataSize);

			// let teradataSize = res?.data?.dataStoredInTeradata?.[0]?.["VALUE"] ?? 0; 
			// SetTeradataSize(teradataSize.toFixed(2).toString());
			let adlsSize = res?.data?.dataStoredInADLS?.[0]?.["VALUE"] ?? 0;
			SetAdlsSize(formatByByte(byteValue, adlsSize));
			SetAdlsSizeRaw(adlsSize);
			let azureSize = res?.data?.dataStoredInSQL?.[0]?.["VALUE"] ?? 0;
			SetAzureSqlSize(formatByByte(byteValue, azureSize));
			SetAzureSqlSizeRaw(azureSize);
			let azureSynaps = res?.data?.dataStoredInSynapse?.[0]?.["VALUE"] ?? 0;
			SetAzureSynapse(formatByByte(byteValue, azureSynaps));
			SetAzureSynapseRaw(azureSynaps);
			let azureCosmos = res?.data?.dataStoredInCosmos?.[0]?.["VALUE"] ?? 0;
			SetAzureCosmos(formatByByte(byteValue, azureCosmos));
			SetAzureCosmosRaw(azureCosmos);
		}).catch(error => { 
			console.error(error);
		})
	}
	
	
	useEffect(() => {
		if (hasDrillDown) {
			
			const selectedSector = localStorage.getItem('selectedSector')
			if (selectedSector) {
				const selectedSec = JSON.parse(selectedSector).length ? JSON.parse(selectedSector) : ''
				setCheckedList(selectedSec)
				setCurrSectorFilter(selectedSec)
			}
		} else {
			localStorage.removeItem('selectedSector')
		}
		
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		if (userSector === 'all') fetchSectorOptionsData();
		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/dataestate/storagesummary') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				document.body.classList.add('custom-class');
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('datefilter-align-sector');
				document.body.classList.add('sector-label');
				document.body.classList.add('byte');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				document.body.classList.add('dashboard-panel-dataestate');
				document.body.classList.add('datefilter-align-sector');
			}
		}
		getStorageSummaryDetails(sector, filterMonth);
	}, []);

	async function programListingFunc( pageNumber: any, size: any, sortingInfo: any, dimension: any) {	
		console.log(dimension, 'data')
		let month = customMonth ? customMonth : filterMonth;
		const res = await fetchDEStorageTable('storage', dimension.current, sector, month);		
		if (res.success) {
			setTableData(res.data.summary);
			setPaginationData(res.data.page);
			// if (res.data.page){
            //     setPaginationData(res.data.page);
            // }
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
			setTimeout(() => {
				if (tableRef.current) {
				  tableRef.current.scrollIntoView({ behavior: 'smooth' });
				}
		  }, 1000);
		}
		console.log(defaultColumnNames,"default final")
	}
	interface teradata {
		dataBaseName: string;
		usedSpaceInGB: string;
		maxSpaceInGB: string;
		percentageUsed: string;
		remainingSpaceInGB: string;
	}
	interface ADLS {
		azureSubscription: string;
		resourceGroup: string;
		storageAccountName: string;
		fileName: string;
		usedCapacityInGB: string;
	}
	interface SQL {
		azureSubscription: string;
		resourceGroup: string;
		sqlDatabase: string;
		usedSpaceInGB: string;
		dataSpaceAllocatedInGB: string;
		dataSpaceAllocatedButUnused: string;
		dataMaxSize: string;
	}

	interface Row{};
	function getColumns(): Array<Column<Row>> {
		if(typeName === 'Data Stored in Teradata'){
			return [
				{
					key: 'dataBaseName',
					name: 'Database name',
					minWidth: 180
				},
				{
					key: 'usedSpaceInGB',
					name: 'Used space in GB',
					minWidth: 170
				},
				{
					key: 'maxSpaceInGB',
					name: 'Max space in GB',
					minWidth: 170
				},
				{
					key: 'percentageUsed',
					name: 'Percentage used',
					minWidth: 170
				},
				{
					key: 'remainingSpaceInGB',
					name: 'Remaining space in GB',
					minWidth: 170
				},
			];
		}
		else if(typeName === 'Data Stored in ADLS  & Blob'){
			return [
				{
					key: 'azureSubscription',
					name: 'Azure subscription',
					minWidth: 180
				},
				{
					key: 'resourceGroup',
					name: 'Resource group',
					minWidth: 170
				},
				{
					key: 'storageAccountName',
					name: 'Storage account name',
					minWidth: 170
				},
				{
					key: 'fileName',
					name: 'File name',
					minWidth: 170
				},
				{
					key: 'usedCapacityInGB',
					name: 'Used Capacity in GB',
					minWidth: 170
				},
			];
		}
		else if(typeName === 'Data Stored in Azure SQL'){
			return [
				{
					key: 'azureSubscription',
					name: 'Azure subscription',
					minWidth: 180
				},
				{
					key: 'resourceGroup',
					name: 'Resource group',
					minWidth: 170
				},
				{
					key: 'sqlDatabase',
					name: 'SQL database',
					minWidth: 170
				},
				{
					key: 'usedSpaceInGB',
					name: 'Used space in GB',
					minWidth: 170
				},
				{
					key: 'dataSpaceAllocatedInGB',
					name: 'Data space allocated in GB',
					minWidth: 170
				},
				{
					key: 'dataSpaceAllocatedButUnused',
					name: 'Data space allocated but unused',
					minWidth: 170
				},
				{
					key: 'dataMaxSize',
					name: 'Data max size',
					minWidth: 170
				},
				
			];
		}
		else{
			return [];
		}
	}
	const TeradataColumns =['Database name','Used space in GB','Max space in GB','Percentage used','Remaining space in GB',]
	const ADLSColumns = ['Azure subscription','Resource group', 'Storage account name', 'File name', 'Used Capacity in GB',]
	const SQLColumns = ['Azure subscription', 'Resource group', 'SQL database', 'Used space in GB', 'Data space allocated in GB', 'Data space allocated but unused', 'Data max size',];
	const [defaultColumnNames, setDynamicValue] = useState(['']);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [isTableVisible, setIsTableVisible] = useState(false);
	const tableRef = useRef<HTMLDivElement>(null);
	const [tableDataList, setTableData] = useState<Row[]>([]);
	const [tableHeading, setTableHeading] = useState('');
	const [typeName, setTypeName] = useState('');
	const dimension = useRef('');
	const [customMonth, setCustomMonth] = useState('');
	async function sectorListingFunc(pageNumber: any, size: any, sortingInfo: any) {
		const res = await getSectorSql(pageNumber.toString(), size.toString(), sortingInfo);
		if (res.success) {
			setPaginationData(res.data.page);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
			setTableData(res.data.sector);
		}
	}
	const handleSorting = (sortingInfo: any) => {
		sectorListingFunc(page, pageSize, sortingInfo);
	};
	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		sectorListingFunc(value, pageSize, sortingInfo);
	};
	const handlepageSizeChange = (event: any) => {
		sectorListingFunc(page, event.target.value, sortingInfo);
	};
	const handleClick = (typeName:any) => {
		setDynamicValue(['']);
		setTableData([]);
		setIsTableVisible(true);
    	// setTimeout(() => {
      	// 	if (tableRef.current) {
        // 		tableRef.current.scrollIntoView({ behavior: 'smooth' });
      	// 	}
    	// }, 1000);
		if(typeName === 'Data Stored in Teradata'){
			console.log("default before",defaultColumnNames)
			setDynamicValue(TeradataColumns);
			setTableHeading("Data Stored in Teradata");
			type Row = keyof teradata;
			setTypeName('Data Stored in Teradata')
			dimension. current = 'teradata'
			console.log("default",defaultColumnNames)
		}
		if(typeName === 'Data Stored in ADLS  & Blob')
		{
			console.log("default before",defaultColumnNames)
			setDynamicValue(ADLSColumns);
			setTableHeading("Data Stored in ADLS  & Blob");
			type Row = keyof ADLS;
			setTypeName('Data Stored in ADLS  & Blob')
			dimension. current = 'storage'
			console.log("default",defaultColumnNames)
		}
		if(typeName === 'Data Stored in Azure SQL')
		{
			console.log("default before",defaultColumnNames)
			setDynamicValue(SQLColumns);
			setTableHeading("Data Stored in Azure SQL");
			type Row = keyof SQL;
			setTypeName('Data Stored in Azure SQL')
			dimension. current = 'azure_sql'
			console.log("default",defaultColumnNames)
		}	
		programListingFunc(page,pageSize,sortingInfo,dimension)
	}

	const clearMetricsSummary = () => {
		setTableData([]);
		setIsTableVisible(false);
	}

	const handleByteChange = (event: any) => {
		const value = event.target.value;
		setByteValue(value)
		selectedByte.current = value;
		SetTeradataSize(formatByByte(value, TeradataSizeRaw.toString()));
		SetAdlsSize(formatByByte(value, AdlsSizeRaw.toString()));
		SetAzureSqlSize(formatByByte(value, AzureSqlSizeRaw.toString()));
		SetAzureSynapse(formatByByte(value, AzureSynapseRaw.toString()));
		SetAzureCosmos(formatByByte(value, AzureCosmosRaw.toString()));
		teradataTrend(teradataRes);
		azureTrend(azureRes);
	};

	const handleDateFilter = (field: any, selectedDate: any) => {
		clearMetricsSummary();
		if (field === 'dateFilter') 
		{
			setIsDateOpen(false);
			isCustomMonth.current = false;
			setCustomMonth('');
			setFilterMonth(selectedDate.target.value);
			if(selectedDate.target.value === "2023-01") {
				isCustomMonth.current = true;
				setIsDateOpen(true);
				return;
			}
			setFilterDefaultMonth(selectedDate)
		}
		selectedDate = selectedDate.target.value
		setFilterMonth(selectedDate);
		getStorageSummaryDetails(sector, selectedDate)
	};
	const handleMonthOnclick = (value:any) => {
		isCustomMonth.current = false;
		setIsDateOpen(false);
		const selectedDate = customYear + "-" + value;
		setCustomMonth(selectedDate);
		getStorageSummaryDetails(sector, selectedDate);
		setDateFilterMenu(null);
	}
	const handleYearAdd = () => {
		let selectedYear = Number(customYear);
		selectedYear++;
		setCustomYear(selectedYear.toString());
	}
	const handleYearMinus = () => {
		let selectedYear = Number(customYear);
		selectedYear--;
		setCustomYear(selectedYear.toString());
	}
	const handleCustomOnclick = (e:any) => {
		if (e.target.textContent == 'Custom Month') {
			isCustomMonth.current = true;
			dateFilterMenuClick(e);
		}
		if(isCustomMonth.current)
			setIsDateOpen(true);
		else
			setIsDateOpen(false);

		if (e.type == 'click' && e.target.className.includes('MuiModal-backdrop')) {
			isCustomMonth.current = false;
			setDateFilterMenu(null);
			setIsDateOpen(false);
			if (customMonth == '') {
				setFilterMonth(filterDefaultMonth)
			}
		}
	}
	return (
		<div className="dashboard-panel-dataestate job-schedules-wrap storage-base">
			<div className="job-exexutions-dateFilter-div">
			<div className="byte-filter byte-summary">
				{/* Code for Date filter */}
				<Stack>
					<div className="byte-select">
						<FormControl fullWidth>
							<Select
								name="byte-select"
								labelId="byte-select-label"
								id="single-byte-select"
								value={byteValue}
								label="Byte"
								onChange={handleByteChange}
							>
								<MenuItem value={"PB"}>PB</MenuItem>
								<MenuItem value={"TB"}>TB</MenuItem>
								<MenuItem value={"GB"}>GB</MenuItem>
							</Select>
						</FormControl>
					</div>
				</Stack>
			</div>
			<div className="service-date-filter date-picker-summary data-estate-right">
				{/* Code for Date filter */}
				<Stack>
					<div className="date-select">
						<FormControl fullWidth>
							<CalendarIcon className="date-icon" />
							<Select
								name="date-select"
								labelId="demo-simple-select-label"
								id="single-date-select"
								value={filterMonth}
								label="Date"
								onChange={(e) => {
									handleDateFilter('dateFilter', e);
								}}
								classes={{ select: classes.select }}
								IconComponent={() => null} 
								open={isDateOpen} 
								onClick={(e) =>handleCustomOnclick(e)}
								onOpen={() => setIsDateOpen(true)}
								onClose={() => setIsDateOpen(false)}
							>
								<MenuItem style={filterMonth === 'This Month' ? { background: '#676a88', borderRadius: '5px' } : {}} value={"0M"}>This Month</MenuItem>
								<MenuItem style={filterMonth === 'Last Month' ? { background: '#676a88', borderRadius: '5px' } : {}} value={"1M"}>Last Month</MenuItem>
								<MenuItem value={"3M"}>Last 3 Months</MenuItem>
								<MenuItem value={"6M"}>Last 6 Months</MenuItem>
								<MenuItem value={"9M"}>Last 9 Months</MenuItem>
								<MenuItem value={"0Y"}>This Year</MenuItem>
								<MenuItem value={"1Y"}>Last Year</MenuItem>
								<MenuItem value={"2Y"}>Last 2 Years</MenuItem>
								<MenuItem value={"2023-01"}>Custom Month</MenuItem>
							</Select>
						</FormControl>
						{isCustomMonth.current && <Popover
							open={dateFilterMenu !== null}
							onClose={handleDateFilterMenuClose}
							className="custom-date-filter-menu"
							anchorReference="anchorPosition"
							anchorPosition={dateFilterMenu !== null ? { top: dateFilterMenu.mouseY, left: dateFilterMenu.mouseX } : undefined}
						><div className="custom-class-date date-custom">
							<div className='custom-dis'>
								<InputLabel>{customYear}</InputLabel>
								<div style={{marginLeft:'60px'}}>
									<Downarrow className='up-arrow' onClick={handleYearAdd}/>
									<Downarrow className='year-arrow' onClick={handleYearMinus}/>
								</div>
							</div>
							<div className='custom-dis'>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("01")}>January</Button>
								<Button  variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("02")}>February</Button>
							</div>
							<div className='custom-dis'>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("03")}>March</Button>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("04")}>April</Button>
							</div>
							<div className='custom-dis'>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("05")}>May</Button>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("06")}>Jun</Button>
							</div>
							<div className='custom-dis'>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("07")}>July</Button>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("08")}>August</Button>
							</div>
							<div className='custom-dis'>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("09")}>September</Button>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("10")}>October</Button>
							</div>
							<div className='custom-dis'>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("11")}>November</Button>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("12")}>December</Button>
							</div>
						</div></Popover>}
					</div>
				</Stack>
			</div>
				{/* Code for sector filter */}
				{/* <div className="service-sector-filter-cost service-sector-filter">
					<Stack className="sector-filter-dataestate">
						<><span className='sector-cta' onClick={sectorFilterClick}><span className='sector-label'>Sectors</span> <FilterIcon /></span></>
						<Popover
							open={filterMenu !== null}
							onClose={handleClose}
							className="filterContainer"
							anchorReference="anchorPosition"
							anchorPosition={filterMenu !== null ? { top: filterMenu.mouseY, left: filterMenu.mouseX } : undefined}
						>
							<div className="text-filter-row">
								<div className="selectAll-sec">
									<MenuItem className="selectAll">
										<FormControlLabel
											className={classes.checkbox}
											label="All"
											control={
												<Checkbox
													color="success"
													value="all"
													checked={itemsChecked}
													onChange={(e) => {
														selectAllCheck(e);
													}}
												/>
											}
										/>
									</MenuItem>
									<div className="checkbox-sec">
										{sectorOptionsList &&
											sectorOptionsList.map((filter: any) => (
												<MenuItem key={filter}>
													<FormControlLabel
														className={classes.checkbox}
														label={filter === true ? 'Yes' : filter === false ? 'No' : filter}
														control={
															<Checkbox
																color="success"
																value={filter}
																checked={
																	(filter === true && checkedList.includes('true')) ||
																	(filter === false && checkedList.includes('false')) ||
																	checkedList.includes(filter)
																}
																onChange={(e) => {
																	handleChange(e);
																}}
															/>
														}
													/>
												</MenuItem>
											))}
									</div>
								</div>
								<div className="buttonSec">
									<Button variant="contained" className={classes.cancelBtn} onClick={handleClose}>
										cancel
									</Button>
									<Button variant="contained" className={classes.okBtn} onClick={handleOkClick}>
										ok
									</Button>
								</div>
							</div>
						</Popover>
					</Stack>
				</div> */}
			</div>
			<div>
			<div className="page-table jobs-tabledata page-table-ibp-monitor">
					<div className="ibp-dashboard-wrapper">
					<Grid container gap={2}>
						<Grid item xs={12} p={1}
							className="dataestate-title m-0 bgcolor-blue"
							onClick={() => { !isEnvTypePROD ? handleClick('Data Stored in Teradata') : '' }}
						>
							<div className="monitor-head">Data Stored in Teradata</div>
							{/* <div className="dataestate-subheader" title={TeradataSize}>{TeradataSize == '-' ? <span className="loading">Loading...</span> : <>{TeradataSize} <span className="subheader-text">PB</span></>}</div> */}
							<div className="dataestate-subheader" title={TeradataSize}>{TeradataSize == '-' ? <span className="loading">Loading...</span> : <>{formatNumber(TeradataSize)} <span className="subheader-text">{byteValue}</span></>}</div>
						</Grid>
						<Grid item xs={12} p={1}
							className="dataestate-title m-0 bgcolor-blue" onClick={() => { !isEnvTypePROD ? handleClick('Data Stored in ADLS  & Blob') : '' } }
						>
							<div className="monitor-head">Data Stored in ADLS  & Blob</div>
							{/* <div className="dataestate-subheader">{AdlsSize == '-' ? <span className="loading">Loading...</span> : <>{AdlsSize} <span className="subheader-text">PB</span></>}</div> */}
							<div className="dataestate-subheader">{AdlsSize == '-' ? <span className="loading">Loading...</span> : <>{formatNumber(AdlsSize)} <span className="subheader-text">{byteValue}</span></>}</div>
						</Grid>
						<Grid item xs={12} p={1}
							className="dataestate-title m-0 bgcolor-blue" onClick={() => { !isEnvTypePROD ? handleClick('Data Stored in Azure SQL') : '' } }>
							<div className="monitor-head">Data Stored in Azure SQL</div>
							{/* <div className="dataestate-subheader">{AzureSqlSize == '-' ? <span className="loading">Loading...</span> : <>{AzureSqlSize} <span className="subheader-text">PB</span></>}</div> */}
							<div className="dataestate-subheader">{AzureSqlSize == '-' ? <span className="loading">Loading...</span> : <>{formatNumber(AzureSqlSize)} <span className="subheader-text">{byteValue}</span></>}</div>
						</Grid>
						<Grid item xs={12} p={1}
							className="dataestate-title m-0 bgcolor-blue">
							<div className="monitor-head">Data Stored in Synapse</div>
							{/* <div className="dataestate-subheader">{AzureSynapse == '-' ? <span className="loading">Loading...</span> : <>{AzureSynapse} <span className="subheader-text">PB</span></>}</div> */}
							<div className="dataestate-subheader">{AzureSynapse == '-' ? <span className="loading">Loading...</span> : <>{formatNumber(AzureSynapse)} <span className="subheader-text">{byteValue}</span></>}</div>
						</Grid>
						<Grid item xs={12} p={1}
							className="dataestate-title m-0 bgcolor-blue">
							<div className="monitor-head">Data Stored in Cosmos</div>
							{/* <div className="dataestate-subheader">{AzureCosmos == '-' ? <span className="loading">Loading...</span> : <>{AzureCosmos} <span className="subheader-text">PB</span></>}</div> */}
							<div className="dataestate-subheader">{AzureCosmos == '-' ? <span className="loading">Loading...</span> : <>{formatNumber(AzureCosmos)} <span className="subheader-text">{byteValue}</span></>}</div>
						</Grid>
					</Grid>

						<Grid container gap={1} mt={2}>
							<Grid item xs={12} md={6} p={2}
								className="dataestate-title m-0 chart-widget chart-widget-query bgcolor-blue mheight"
							>
								<div className="monitor-head ml-10">Teradata Data Growth with Projections <i>(Trend)</i>
								{!grafanaTeradataLoading && <span className="badge" title={`Last Year: ${formatNumber(teradataStorageLastYear)} ${byteValue} - Previous Month: ${formatNumber(teradataStorageCurrent)} ${byteValue}`}>
									{Math.sign(teradataBubbleValue) == -1 && <><img src={ArrowUpBlackIcon} className="arrow-icon-styles arrow-icon-styles-up-black down-arrow" /> {Math.abs(Number(teradataBubbleValue.toFixed(2)))}% /Yr</> }
									{Math.sign(teradataBubbleValue) != -1 && <><img src={ArrowUpBlackIcon} className="arrow-icon-styles arrow-icon-styles-up-black" /> {Math.abs(Number(teradataBubbleValue.toFixed(2)))}% /Yr</> }
									</span> 
									}
								</div>
								{
									grafanaTeradataLoading ? 'Loading...' : <ReactECharts
									option={grafanaTeradataOptions}
									theme={{
										backgroundColor: '#1b1e35'
									}}
								/>}
							</Grid>
							<Grid item xs={12} md={6} p={2}
								className="dataestate-title m-0 chart-widget chart-widget-query bgcolor-blue mheight"
							>
								<div className="monitor-head ml-10">Azure Data Growth with Projections <i>(Trend)</i>
								{(isNoAnnualAzureStorage) && <span className="badge bg-no">
									<Tooltip title={isNoAnnualAzureStorage ? azureStorageStartMonth : 'Insufficient Data'} arrow>
										<img src={ToolTipIcon} />
									</Tooltip>
								</span>}
					
								{/* {!grafanaAzureLoading && <span className="badge" title={`Last Year: ${formatNumber(azureStorageLastYear)} ${byteValue} - Previous Month: ${formatNumber(azureStorageCurrent)} ${byteValue}`}>
									{Math.sign(azureBubbleValue) == -1 && <><img src={ArrowUpBlackIcon} className="arrow-icon-styles arrow-icon-styles-up-black down-arrow" /> {Math.abs(Math.round(azureBubbleValue))}% /Yr</> }
									{Math.sign(azureBubbleValue) != -1 && <><img src={ArrowUpBlackIcon} className="arrow-icon-styles arrow-icon-styles-up-black" /> {Math.abs(Math.round(azureBubbleValue))}% /Yr</> }
									</span> */
									}</div>
								{
									grafanaAzureLoading ? 'Loading...' : <ReactECharts
									option={grafanaAzureOptions}
									theme={{
										backgroundColor: '#1b1e35'
									}}
								/>}
							</Grid>
						</Grid>
					</div>
				</div>
			</div>
			{isTableVisible && (
				<>
				<h2 className="panel-legend--title mt-35 ">{tableHeading}</h2>
				<div className=" finop_tabledata detail_sum_tbl" ref={tableRef}>
					<Grid>
					{tableDataList && tableDataList.length > 0 ? (
						<CustomizedDataGrid
							tableColumns={getColumns()}
							tableData={tableDataList}
							tableType="sector"
							defaultColumnNames={defaultColumnNames}
							onSorting={handleSorting}
							currSorting={sortingInfo}
							page={page}
							pageSize={pageSize}
							paginationData={paginationData}
							listLength={tableDataList.length}
							handlepageSizeChange={handlepageSizeChange}
							handlePageChange={handlePageChange}
							isDateRangeFilter={false}
							isDisabled={false}
							isFetching={true}
							/> 	
							 ):(
								tableDataList ? <Loader /> : <span  style={{display: 'flex', justifyContent: 'center', }}>No Records Found</span>
							 )
							}
						</Grid>
			   </div>
			   </>
			)}				
		</div>
	);
};

export default StorageSummary;
