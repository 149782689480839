import React from 'react';
import { PepwiseAuthControllerProvider } from './contexts/AuthContext';
import { CacheDataProvider } from './contexts/CacheDataContext';

const PepwiseAppContextProviders = ({ children }: { children: React.ReactNode }) => {
	return (
		<PepwiseAuthControllerProvider>
			<CacheDataProvider>{children}</CacheDataProvider>
		</PepwiseAuthControllerProvider>
	);
};

export default PepwiseAppContextProviders;
