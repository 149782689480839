import { useState, useEffect, useRef } from 'react';
import AnalyticsVisualization from '../../framework/analyticsVisualization';
import { pcobsDashboardUrl } from '../../../service/config';
import useStyles from '../../jobs/jobs_styles';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, Button, Stack } from '@mui/material';
import { ReactComponent as FilterIcon } from '../../../assets/ml_summary_filter.svg';
import { useSearchParams } from 'react-router-dom';

interface ResouceMappingSummary {
	sharedValue: any;
	updateSharedValue: (value: any) => void;
}
const ResourceMappingSummary: React.FC<ResouceMappingSummary> = ({ sharedValue, updateSharedValue }) => {
	const classes = useStyles();
	const dataFetchedRef = useRef(false);

	const [searchParams, setSearchParams] = useSearchParams();
	const hasDrillDown = searchParams.has('isDrillDowned');

	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
	});
	return (
		<Stack>
			<AnalyticsVisualization
				params={{
					dashboardUrl: pcobsDashboardUrl.resourceMappingUrl,
					className: 'pcobsSummaryIframeContainer',
					otherParams: {
						from: 'now-6h',
						to: 'now',
						'var-drillDownSector': hasDrillDown ? searchParams.get('sector') : '',
						'var-drillDownBySector': hasDrillDown ? searchParams.get('bysector') : '',
						'var-drillDownTaggedToProject': hasDrillDown ? searchParams.get('taggedtoproject') : '',
						'var-drillDownTaggedToSector': hasDrillDown ? searchParams.get('taggedtosector') : '',
					},
					seamless: true
				}}
			/>
		</Stack>
	);
};

export default ResourceMappingSummary;
