import { useEffect, useRef, useState } from 'react';
import {
	Button,
	Chip,
	Grid,
	Menu,
	TextField,
} from '@mui/material';
import '../TableauObs/Summary/Summary.scss';
import '../DataEstate/data-estate.scss';
import ReactECharts from 'echarts-for-react';
import { getIncidentDetails, incidentSearchForJobExecutions, getSlaReportData } from '../../service/service';
import { Column } from 'react-data-grid';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { CustomizedDataGrid } from '../customized_data_grid';
import Loader from '../Loader/loader';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import React from 'react';
import { ReactComponent as DatePickerIcon } from '../../assets/calendar-outline_white.svg';
import { ReactComponent as FilterIcon } from '../../assets/ml_summary_filter.svg';
import {
	getJobExecutions,
	getBoxRunsList,
	getReasons
} from '../../service/service';
import { OverflowTooltip } from '../overflow_tooltip';

export default function IncidentImpactAnalysis() {
	const [grafanaChartLoading, setGrafanaChartLoading] = useState(true);
	const [grafanaChartOptions, setGrafanaChartOptions] = useState<any>();
	const [performanceByDay, setPerformanceByDay] = useState<any>(false);
	const [sector, setCurrSector] = useState<any[]>([]);
	const [month, setFilterMonth] = useState("0M");
	const params = useParams();
	const location = useLocation();
	const paramsData = location.state;
	const [searchParams, setSearchParams] = useSearchParams();
	const hasDrillDowned = searchParams.has('isDrillDowned');
	const [executionsList, setExecutionsList] = useState<any[]>([]);
	const [boxLoading, setBoxLoading] = useState(false);
	const minJobExecDate = '2023-03-01';
	const dateFrom: string = searchParams.get('fromDate')?.substring(0, 10) as string;
	const dateTo: string = searchParams.get('toDate')?.substring(0, 10) as string;
	const [fromDateTime, setFromDateTime] = useState<any>(
		paramsData.openedDate ? paramsData.openedDate : moment().startOf('day').subtract(1, 'hours')
	);
	const [toDateTime, setToDateTime] = useState<any>(
		paramsData.closedDate ? paramsData.closedDate : moment().endOf('day').subtract(1, 'hours')
	);
	const [reasonList, setReasonList] = useState<any[]>([]);
	const dataFetchedRef = useRef(false);
	moment.updateLocale(moment.locale(), { invalidDate: '' });
	const [incidentType, setIncidentType] = useState('teradata');
	

	interface Row {
		id: string;
		scheduledJob: string,
		sector: string,
		system: string,
		process: string,
		projects: string,
		jobStatus: string,
		startTime: string,
		endTime: string,
		slaEndTime: string,
		slaStatus: string,
		normalCompletionTime: string,
		actualCompletionTime: string,
		currentExecutionTime: string,
		averageExecutionTime: string,
		reason: string,
		followUp: string,
		actionTaken: string,
		lastSuccessfulRun: string,
		estimatedDuration: string,
		executionFrequency: string,
		criticalFlag: string,
		date: string,
		jobName: string,
		name: string,
		runStatus: string,
		reasonForDelay: string,
		runStartTime: string,
		runEndTime: string
	}

	const renderStatus = (status: any) => {
		switch (status) {
			case 'SUCCESS':
			case 'Successful':
			case 'YES':
			case 'Completed':
				return <Chip label={status} color="success" />;
			case 'SLA Met':
				return <Chip label={status} style={{ backgroundColor: 'rgba(41, 155, 255, 0.15)', color: 'rgba(41, 155, 255, 1)' }} />;
			case 'FAILURE':
			case 'Failure':
			case 'TERMINATED':
			case 'Terminated':
			case 'SUSPENDED':
			case 'NO':
				return <Chip label={status} color="secondary" />;
			case 'SLA Missed':
				return <Chip label={status} style={{ backgroundColor: 'rgba(253, 120, 59, 0.15)', color: 'rgba(253, 120, 59, 1)' }} />;
			case 'NOT STARTED':
			case 'Not Started':
				return <Chip label={status} style={{ backgroundColor: '#1B1E35CC', color: '#71758F' }} />;
			case 'N/A':
				return <Chip label={status} style={{ backgroundColor: '#1B1E35CC', color: '#A9ADCB' }} />;
			case 'Scheduled':
				return <Chip label={status} style={{ backgroundColor: 'rgba(255, 162, 0, 0.2)', color: 'rgba(255, 162, 0, 1)' }} />;
			case 'RUNNING':
			case 'ACTIVATED':
			case 'In Progress':
			case 'Hold':
				return <Chip label={status} style={{ backgroundColor: '#299BFF26', color: '#299BFF' }} />;
			default:
				return <Chip label={status} style={{ backgroundColor: '#299BFF26', color: '#299BFF' }} />;
		}
	};

	function getDatabricksColumns(): Array<Column<Row>> {
		return [
			{
                key: 'date',
                name: 'Event Date',
                width: 180,
                minWidth: 180,
				formatter(props) {
					const data = props.row.date ? moment(props.row.date).utc().format('YYYY-MM-DD') : '-';
					return data;
				},
			},
			{
				key: 'jobName',
				name: 'Cluster Name',
				minWidth: 200,
				width: 200,
				formatter(props) {
					const data = props.row.jobName ? props.row.jobName : '-';
					return <div title={props.row.jobName} className='has-ellipsis'>{data}</div>;
				}
			},
			{
                key: 'process',
                name: 'Workspace ID',
                width: 200,
				minWidth: 250,
				formatter(props) {
					const data = props.row.process ? props.row.process.replaceAll('?', '') : '-';
					return <div title={props.row.process} className='has-ellipsis'>{data}</div>;
				}
            },
			{
				key: 'slaStatus',
				name: 'SLA Status',
				width: 130,
				minWidth: 130,
				cellClass: 'center-text',
				formatter(props) {
					const status = props.row.slaStatus;
					return renderStatus(status);
				}
			},
			{
                key: 'normalCompletionTime',
                name: 'DBR Version',
                minWidth: 150,
                width: 150,
				formatter(props) {
					const data = props.row.normalCompletionTime ? props.row.normalCompletionTime : '-';
					return <div title={props.row.normalCompletionTime} className='has-ellipsis'>{data}</div>;
				}
            },
            {
                key: 'actualCompletionTime',
                name: 'Driver Node Type',
                minWidth: 150,
                width: 180,
				formatter(props) {
					const data = props.row.actualCompletionTime ? props.row.actualCompletionTime : '-';
					return data;
				}
            },
			{
                key: 'reason',
                name: 'Error Message',
                width: 230,
                minWidth: 200,
				formatter(props) {
					const data = props.row.reason ? props.row.reason : '-';
					return <div title={props.row.reason} className='has-ellipsis'>{data}</div>;
				}
            },
		]
	}

	function getColumns(): Array<Column<Row>> {
        return [
			{
                key: 'runStartTime',
				// key: 'date',
                name: 'Date',
                width: 180,
                minWidth: 180,
				formatter(props) {
					const startDate = props.row.runStartTime;
					if (startDate) {
						return moment(startDate).format('MM-DD-YY HH:mm:ss');
					}
					return '';
					// const data = props.row.date ? moment(props.row.date).utc().format('YYYY-MM-DD') : '-';
					// return data;
				}
			},
			{
				key: 'name',
				// key: 'jobName',
				name: 'Job Name',
				minWidth: 200,
				width: 200,
				formatter(props) {
					const data = props.row.name ? props.row.name : '-';
					return <div title={props.row.name} className='has-ellipsis'>{data}</div>;
					// const data = props.row.jobName ? props.row.jobName : '-';
					// return <div title={props.row.jobName} className='has-ellipsis'>{data}</div>;
				}
			},
			{
                key: 'sector',
                name: 'Sector',
				width: 100,
				minWidth: 100,
				formatter(props) {
					const data = props.row.sector ? props.row.sector : '-';
					return <div title={props.row.sector} className='has-ellipsis'>{data}</div>;
				}
            },
			{
                key: 'system',
				name: 'System',
				width: 100,
				minWidth: 100,
				formatter(props) {
					const data = props.row.system ? props.row.system : '-';
					return <div title={props.row.system} className='has-ellipsis'>{data}</div>;
				}
            },
			{
                key: 'process',
                name: 'Projects',
                width: 200,
				minWidth: 250,
				formatter(props) {
					const data = props.row.process ? props.row.process.replaceAll('?', '') : '-';
					return <div title={props.row.process} className='has-ellipsis'>{data}</div>;
				}
            },
			{
				key: 'slaStatus',
				name: 'SLA Status',
				width: 130,
				minWidth: 130,
				cellClass: 'center-text',
				formatter(props) {
					const status = props.row.slaStatus;
					return renderStatus(status);
				}
			},
			{
                key: 'slaEndTime',
				// key: 'normalCompletionTime',
                name: 'Normal Completion Time',
                minWidth: 150,
                width: 200,
				formatter(props) {
					return (
						<OverflowTooltip customTooltip={`Timezone: ${moment().isDST() ? 'EDT(UTC-4)' : 'EST(UTC-5)'}`}>
							{props.row.slaEndTime}
						</OverflowTooltip>
					);
					// const data = props.row.normalCompletionTime ? props.row.normalCompletionTime : '-';
					// return <div title={props.row.normalCompletionTime} className='has-ellipsis'>{data}</div>;
				}
            },
            {
                key: 'runEndTime',
				// key: 'actualCompletionTime',
                name: 'Actual Completion Time',
                minWidth: 150,
                width: 200,
				formatter(props) {
					const endDate = props.row.runEndTime;
					if (endDate) {
						return moment(endDate).format('MM-DD-YY HH:mm:ss');
					}
					return '';
					// const data = props.row.actualCompletionTime ? props.row.actualCompletionTime : '-';
					// return data;
				}
            },
			{
				key: 'runStatus',
				// key: 'jobStatus',
				name: 'Job Status',
				width: 130,
				minWidth: 130,
				cellClass: 'center-text',
				formatter(props) {
					const status = props.row.runStatus;
					// const status = props.row.jobStatus;
					return renderStatus(status);
				}
			},
			{
                key: 'reasonForDelay',
				// key: 'reason',
                name: 'Reason',
                width: 230,
                minWidth: 200,
				formatter(props) {
					const data = props.row.reasonForDelay ? props.row.reasonForDelay : '-';
					return <div title={props.row.reasonForDelay} className='has-ellipsis'>{data}</div>;
					// const data = props.row.reason ? props.row.reason : '-';
					// return <div title={props.row.reason} className='has-ellipsis'>{data}</div>;
				}
            },
            // {
            //     key: 'scheduledJob',
            //     name: 'Scheduled Job',
            //     minWidth: 200,
            //     width: 250,
			// 	formatter(props) {
			// 		const data = props.row.scheduledJob ? props.row.scheduledJob : '-';
			// 		return data;
			// 	}
            // },
            // {
            //     key: 'startTime',
            //     name: 'Start Time',
            //     width: 230,
            //     minWidth: 200,
			// 	formatter(props) {
			// 		const data = props.row.startTime ? props.row.startTime : '-';
			// 		return data;
			// 	}
            // },
            // {
            //     key: 'endTime',
            //     name: 'End Time',
            //     width: 150,
            //     minWidth: 130,
			// 	formatter(props) {
			// 		const data = props.row.endTime ? props.row.endTime : '-';
			// 		return data;
			// 	}
            // },
            // {
            //     key: 'slaEndTime',
            //     name: 'SLA End Time',
            //     minWidth: 150,
			// 	formatter(props) {
			// 		const data = props.row.slaEndTime ? props.row.slaEndTime : '-';
			// 		return data;
			// 	}
            // },
            // {
            //     key: 'currentExecutionTime',
            //     name: 'Current Execution Time',
            //     minWidth: 150,
            //     width: 200,
			// 	formatter(props) {
			// 		const data = props.row.currentExecutionTime ? props.row.currentExecutionTime : '-';
			// 		return data;
			// 	}
            // },
            // {
            //     key: 'averageExecutionTime',
            //     name: 'Average 15 Days Exec. Time',
            //     minWidth: 200,
            //     width: 250,
			// 	formatter(props) {
			// 		const data = props.row.averageExecutionTime ? props.row.averageExecutionTime : '-';
			// 		return data;
			// 	}
            // },
            // {
            //     key: 'followUp',
            //     name: 'Follow Up',
            //     minWidth: 150,
            //     width: 150,
			// 	formatter(props) {
			// 		const data = props.row.followUp ? props.row.followUp : '-';
			// 		return data;
			// 	}
            // },
            // {
            //     key: 'actionTaken',
            //     name: 'Action Taken',
            //     minWidth: 140,
            //     width: 140,
			// 	formatter(props) {
			// 		const data = props.row.actionTaken ? props.row.actionTaken : '-';
			// 		return data;
			// 	}
            // },
            // {
            //     key: 'estimatedDuration',
            //     name: 'Estimated Duration',
            //     width: 230,
            //     minWidth: 200,
			// 	formatter(props) {
			// 		const data = props.row.estimatedDuration ? props.row.estimatedDuration : '-';
			// 		return data;
			// 	}
            // },
        ];
    }

	const [incidentDetails, setIncidentDetails] = useState<Row[]>([]);
	const [slaReport, setSlaReport] = useState([]);
	const [loading, setLoading] = useState(false);
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);

	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [filterData, setFilterData] = useState<Row[]>([]);
	const [filterInfo, setFilterInfo] = useState([]);
	const [searchDetails, setFilterSearchInfo] = useState([]);

	const defaultDatabricksColumnNames = [
		'Event Date',
		'Cluster Name',
        'Workspace ID',
		'SLA Status',
        'DBR Version',
        'Driver Node Type',
		'Error Message'
	];
	const defaultColumnNames = [
		'Date',
		'Job Name',
        'Sector',
        'System',
        'Projects',
		'SLA Status',
        'Normal Completion Time',
        'Actual Completion Time',
        'Job Status',
		'Reason'
		// 'Scheduled Job',
        // 'Start Time',
        // 'End Time',
        // // 'SLA End Time',
        // 'Current Execution Time',
        // 'Average 15 Days Exec. Time',
        // 'Reason',
        // 'Follow Up',
        // 'Action Taken',
        // 'Estimated Duration',
	];

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		fetchIncidentsDetailFunc(value, pageSize, sortingInfo, fromDateTime, toDateTime, parseFilter(filterInfo));
		// fetchIncidentsDetailFunc(value, pageSize, sortingInfo, parseFilter(filterInfo));
	};

	const handlepageSizeChange = (event: any) => {
	   fetchIncidentsDetailFunc('1', event.target.value, sortingInfo, fromDateTime, toDateTime, parseFilter(filterInfo));
		// fetchIncidentsDetailFunc('1', event.target.value, sortingInfo, parseFilter(filterInfo));
	};
	const handleSorting = (sortingInfo: any) => {
		fetchIncidentsDetailFunc(page, pageSize, sortingInfo, fromDateTime, toDateTime, parseFilter(filterInfo));
		// fetchIncidentsDetailFunc(page, pageSize, sortingInfo, parseFilter(filterInfo));
	};

	const handleFilter = (filterInfo: any) => {
		resetSearchParams();
		setFilterInfo(filterInfo);
		fetchIncidentsDetailFunc(1, pageSize, sortingInfo, fromDateTime, toDateTime, parseFilter(filterInfo));
		// fetchIncidentsDetailFunc(1, pageSize, sortingInfo, parseFilter(filterInfo));
	};
	//commen
	const handleSearch = (searchDetails: any, columnKey: any) => {
		incidentSearchFunc(searchDetails, parseFilter(filterInfo, 'scheduledJob'));
	};

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

	// [Date Range Menu]
	const [dateRangeMenuOpen, setDateRangeMenuOpen] = React.useState<null | HTMLElement>(null);

	const handleDateClick = (event: React.MouseEvent<HTMLElement>) => {
		setDateRangeMenuOpen(event.currentTarget);
	};

	const handleDateRangeMenu = () => {
		setDateRangeMenuOpen(null);
	};

	const open = Boolean(dateRangeMenuOpen);
	
	const handleDateTimeChange = (field: any, dateTime: any) => {
		if (field === 'fromDateTime') setFromDateTime(dateTime);
		else setToDateTime(dateTime);
	};
	const applyDateTimeRange = (e: any) => {
		resetSearchParams();
		fetchIncidentsDetailFunc(page, pageSize, sortingInfo, fromDateTime, toDateTime, parseFilter(filterInfo));
		// fetchIncidentsDetailFunc(page, pageSize, sortingInfo, parseFilter(filterInfo));
		setDateRangeMenuOpen(null);
	};
	
	const resetSearchParams = () => {
		if (hasDrillDowned || searchParams.has('fromDate') || searchParams.has('toDate')) {
			searchParams.delete('status');
			searchParams.delete('scheduleDate');
			searchParams.delete('isDrillDowned');
			searchParams.delete('fromDate');
			searchParams.delete('toDate');
			setSearchParams(searchParams);
		}
	};

	async function fetchIncidentsDetailFunc(pageNumber: any,
		size: any,
		sortingInfo: any,
		fromDateTime: any,
        toDateTime: any,
		jobFilterInfo: any) {
		setLoading(true);
		setFromDateTime(fromDateTime);
		setToDateTime(toDateTime);
		let incidentType = 'teradata'
		if (paramsData.incidentId == 'INC08935506') {
			incidentType = 'databricks'
		}
		setIncidentType(incidentType);
		let res
		if (incidentType == 'teradata') {
			jobFilterInfo = { "criticalFlag": ["true"] }
			res = await getJobExecutions(
				pageNumber.toString(),
				size.toString(),
				sortingInfo,
				jobFilterInfo,
				fromDateTime,
				toDateTime);
		} else {
			res = await getIncidentDetails(
				pageNumber.toString(),
				size.toString(),
				sortingInfo,
				jobFilterInfo,
				fromDateTime,
				toDateTime,
				incidentType);
		}



	// async function fetchIncidentsDetailFunc(pageNumber: any, size: any, sortingInfo: any, jobFilterInfo: any) {
	// 	setLoading(true);
	// 	setFromDateTime(fromDateTime);
	// 	setToDateTime(toDateTime);
	// 	let incidentType = 'teradata'
	// 	// if (paramsData.incidentId == 'INC08935506') {
	// 	// 	incidentType = 'databricks'
	// 	// }
	// 	setIncidentType(incidentType);
		const slaData = await getSlaReportData(fromDateTime, toDateTime)

		if (slaData.success) {
			setSlaReport(slaData.data.slaReport)
			incidentChartData(slaData.data.slaReport);
			
		}
	// 	console.log(slaData, slaReport)
	// 	const res = await getIncidentDetails(
	// 		pageNumber.toString(),
	// 		size.toString(),
	// 		sortingInfo,
	// 		jobFilterInfo,
	// 		fromDateTime,
	// 		toDateTime,
	// 		incidentType
	// 	);
		if (res.success) {
			setIncidentDetails(res.data.jobs);
			setPaginationData(res.data.page);
			const filters = res.data.filterOptions;
			let formatedDates: any = [];
			Object.entries(filters).filter((d: any) => { if (d[0] == 'date') {
				formatedDates = d[1].map((s: any) => moment(s).utc().format('YYYY-MM-DD'))
			}  });
			filters['date'] = formatedDates
			setFilterData(filters);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
			setFilterInfo(jobFilterInfo);
			setLoading(false);
			const jobExecutionsState = {
				page: res.data.page,
				filterOptions: res.data.filterOptions,
				pageSize: size,
				pageNumber,
				sortingInfo,
				filterInfo: jobFilterInfo
			};
			localStorage.setItem('jobExecutionsState', JSON.stringify(jobExecutionsState));
		}
		
		setLoading(false);
	}

		async function incidentSearchFunc(searchDetails: any, jobFilterInfo: any) {
		const res = await incidentSearchForJobExecutions(searchDetails, jobFilterInfo);
		if (res.success) {
			setFilterSearchInfo(res.data);
		}
	}
	const incidentChartData = (reportData: any) => {
		// fetchChartData(paramsData.openedDate, paramsData.resolvedDate).then(res => {
		// 	if (res) {
				const startDate = moment(paramsData.openedDate);
				const endDate = moment(paramsData.closedDate);
				const duration = moment.duration(endDate.diff(startDate));
				const hours = duration.asHours();
				if (hours >= 48) {
					setPerformanceByDay(true)
				}
				let option = {};
				let xAxisArray:any =[];
				let sla_met:any =[];
				let sla_failed:any=[];
				let sla_total:any=[];
				
				// if (paramsData.incidentId == 'INC09193065') {
				// 	data = [
				// 	{
				// 		'2024-06-15': {
				// 			'sla_met': 51,
				// 			'sla_missed': 34,
				// 			'sla_pending': 1,
				// 		}  
				// 	},
				// 	{
				// 		'2024-06-16': {
				// 			'sla_met': 47,
				// 			'sla_missed': 38,
				// 			'sla_pending': 1,
				// 		} 
				// 	},
				// 	{ 
				// 		'2024-06-17': {
				// 		'sla_met': 82,
				// 		'sla_missed': 3,
				// 		'sla_pending': 1,
				// 		} 
				// 	},
				// 	{ 
				// 		'2024-06-18': {
				// 			'sla_met': 82,
				// 			'sla_missed': 0,
				// 			'sla_pending': 1,
				// 		}
				// 	}];
				// } else if (paramsData.incidentId == 'INC08954175') {
				// 	data =  [
				// 		{
				// 			"00:00": {
				// 				"sla_met": 0,
				// 				"sla_missed": 3,
				// 				"sla_pending": 0
				// 			}
				// 		},
				// 		{
				// 			"01:00": {
				// 				"sla_met": 0,
				// 				"sla_missed": 1,
				// 				"sla_pending": 0
				// 			}
				// 		},
				// 		{
				// 			"02:00": {
				// 				"sla_met": 1,
				// 				"sla_missed": 2,
				// 				"sla_pending": 0
				// 			}
				// 		},
				// 		{
				// 			"03:00": {
				// 				"sla_met": 1,
				// 				"sla_missed": 1,
				// 				"sla_pending": 0
				// 			}
				// 		},
				// 		{
				// 			"04:00": {
				// 				"sla_met": 1,
				// 				"sla_missed": 2,
				// 				"sla_pending": 0
				// 			}
				// 		},
				// 		{
				// 			"05:00": {
				// 				"sla_met": 2,
				// 				"sla_missed": 4,
				// 				"sla_pending": 0
				// 			}
				// 		},
				// 		{
				// 			"06:00": {
				// 				"sla_met": 1,
				// 				"sla_missed": 1,
				// 				"sla_pending": 0
				// 			}
				// 		},
				// 		{
				// 			"07:00": {
				// 				"sla_met": 0,
				// 				"sla_missed": 2,
				// 				"sla_pending": 1
				// 			}
				// 		},
				// 		{
				// 			"08:00": {
				// 				"sla_met": 0,
				// 				"sla_missed": 3,
				// 				"sla_pending": 0
				// 			}
				// 		},
				// 		{
				// 			"09:00": {
				// 				"sla_met": 0,
				// 				"sla_missed": 0,
				// 				"sla_pending": 0
				// 			}
				// 		},
				// 		{
				// 			"10:00": {
				// 				"sla_met": 0,
				// 				"sla_missed": 3,
				// 				"sla_pending": 0
				// 			}
				// 		},
				// 		{
				// 			"11:00": {
				// 				"sla_met": 0,
				// 				"sla_missed": 0,
				// 				"sla_pending": 0
				// 			}
				// 		},
				// 		{
				// 			"12:00": {
				// 				"sla_met": 0,
				// 				"sla_missed": 1,
				// 				"sla_pending": 0
				// 			}
				// 		},
				// 		{
				// 			"13:00": {
				// 				"sla_met": 0,
				// 				"sla_missed": 2,
				// 				"sla_pending": 0
				// 			}
				// 		},
				// 		{
				// 			"14:00": {
				// 				"sla_met": 1,
				// 				"sla_missed": 1,
				// 				"sla_pending": 0
				// 			}
				// 		},
				// 		{
				// 			"15:00": {
				// 				"sla_met": 0,
				// 				"sla_missed": 3,
				// 				"sla_pending": 0
				// 			}
				// 		},
				// 		{
				// 			"16:00": {
				// 				"sla_met": 0,
				// 				"sla_missed": 3,
				// 				"sla_pending": 0
				// 			}
				// 		},
				// 		{
				// 			"17:00": {
				// 				"sla_met": 0,
				// 				"sla_missed": 2,
				// 				"sla_pending": 0
				// 			}
				// 		},
				// 		{
				// 			"18:00": {
				// 				"sla_met": 0,
				// 				"sla_missed": 0,
				// 				"sla_pending": 0
				// 			}
				// 		},
				// 		{
				// 			"19:00": {
				// 				"sla_met": 0,
				// 				"sla_missed": 1,
				// 				"sla_pending": 0
				// 			}
				// 		},
				// 		{
				// 			"20:00": {
				// 				"sla_met": 0,
				// 				"sla_missed": 0,
				// 				"sla_pending": 0
				// 			}
				// 		},
				// 		{
				// 			"21:00": {
				// 				"sla_met": 0,
				// 				"sla_missed": 0,
				// 				"sla_pending": 0
				// 			}
				// 		},
				// 		{
				// 			"22:00": {
				// 				"sla_met": 0,
				// 				"sla_missed": 3,
				// 				"sla_pending": 0
				// 			}
				// 		},
				// 		{
				// 			"23:00": {
				// 				"sla_met": 0,
				// 				"sla_missed": 37,
				// 				"sla_pending": 0
				// 			}
				// 		}
				// 	]
				// } else if (paramsData.incidentId == 'INC09011819') {
				// 	data = [
				// 		{
				// 	'2024-06-13': {
				// 		'sla_met': 22,
				// 		'sla_missed': 59,
				// 		'sla_pending': 1,
				// 	}
				// 	},
				// 		{
				// 	'2024-06-14': {
				// 		'sla_met': 67,
				// 		'sla_missed': 14,
				// 		'sla_pending': 1,
				// 		}  
				// 	},
				// 	{
				// 		'2024-06-15': {
				// 			'sla_met': 56,
				// 			'sla_missed': 25,
				// 			'sla_pending': 1,
				// 		}  
				// 	},
				// 	{
				// 		'2024-06-16': {
				// 			'sla_met': 43,
				// 			'sla_missed': 5,
				// 			'sla_pending': 0,
				// 		} 
				// 	},
				// 	{ 
				// 		'2024-06-17': {
				// 		'sla_met': 73,
				// 		'sla_missed': 1,
				// 		'sla_pending': 1,
				// 		} 
				// 	},
				// 	{ 
				// 		'2024-06-18': {
				// 			'sla_met': 82,
				// 			'sla_missed': 1,
				// 			'sla_pending': 1,
				// 		} 
				// 	},
				// 	{
				// 	'2024-06-19': {
				// 		'sla_met': 82,
				// 		'sla_missed': 1,
				// 		'sla_pending': 1 
				// 		} 
				// 	}];
				// } else {
				// 	data = [
				// 		{
				// 	'2024-05-30': {
				// 		'sla_met': 0,
				// 		'sla_missed': 752,
				// 		'sla_pending': 0,
				// 	}
				// 	},
				// 		{
				// 	'2024-05-31': {
				// 		'sla_met': 0,
				// 		'sla_missed': 242,
				// 		'sla_pending': 0,
				// 		}  
				// 	},
				// 	{
				// 		'2024-06-01': {
				// 			'sla_met': 0,
				// 			'sla_missed': 482,
				// 			'sla_pending': 0,
				// 		}  
				// 	},
				// 	{
				// 		'2024-06-02': {
				// 			'sla_met': 0,
				// 			'sla_missed': 752,
				// 			'sla_pending': 0,
				// 		} 
				// 	},
				// 	{ 
				// 		'2024-06-03': {
				// 		'sla_met': 0,
				// 		'sla_missed': 242,
				// 		'sla_pending': 0,
				// 		} 
				// 	},
				// 	{ 
				// 		'2024-06-04': {
				// 			'sla_met': 0,
				// 			'sla_missed': 482,
				// 			'sla_pending': 0,
				// 		} 
				// 	},
				// 	{
				// 	'2024-06-05': {
				// 		'sla_met': 0,
				// 		'sla_missed': 752,
				// 		'sla_pending': 0 
				// 		} 
				// 	},
				// 	{
				// 	'2024-06-06': {
				// 		'sla_met': 0,
				// 		'sla_missed': 242,
				// 		'sla_pending': 0 
				// 		} 
				// 	}
				// ];
				// }
				reportData.map((res:any,i:number)=>{
					xAxisArray.push(Object.keys(res)[0])
					// if (paramsData.incidentId != 'INC08935506') {
						sla_met.push(res[xAxisArray[i]].sla_met)
						sla_total.push(res[xAxisArray[i]].sla_pending)
					// }
					sla_failed.push(res[xAxisArray[i]].sla_missed)
				})
				option = {
					backgroundColor: '#053367',
					grid: {
						top: '13%',
						left: '5%',
						right: '2%',
						bottom: '30%'
					},
					xAxis: {
						type: 'category',
						data: xAxisArray,
						axisLabel: {
							color: '#fff',
							// formatter: (value: any) => {
							// 	const evenHours = ['12AM', '2', '4', '6', '8', '10', '12PM', '2', '4', '6', '8', '10'];
							// 	return evenHours.includes(value) ? value : '';
							// }
						}
					},
					yAxis: {
						type: 'value',
						axisLabel: {
							color: '#fff'
						}
					},
					series: [
						{
							silent:  paramsData.incidentId == 'INC08935506' ? false : true,
							name: 'SLA MET',
							type: 'bar',
							stack: 'total',
							data: sla_met,
							itemStyle: {
								color: 'rgba(41, 155, 255, 1)'
							}
						},
						{
							silent: paramsData.incidentId == 'INC08935506' ? false : true,
							name: 'SLA MISSED',
							type: 'bar',
							stack: 'total',
							data: sla_failed,
							itemStyle: {
								color: '#ff7f00'
							}
						},
						{
							silent:  true,
							name: 'N/A',
							type: 'bar',
							stack: 'total',
							data: sla_total,
							itemStyle: {
								color: '#A9ADCB'
							}
						}
					],
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						}
					},
					legend: {
						show: true,
						left: '35px',
						bottom: '15px',
						data: paramsData.incidentId == 'INC08935506' ? ['SLA MISSED'] : ['SLA MET', 'SLA MISSED', 'N/A'],
						textStyle: {
							color: '#fff'
						}
					},
				};
				setGrafanaChartOptions(option);
				setGrafanaChartLoading(false);
			// 	}
			// });
		}
	useEffect(() => {
		localStorage.removeItem('incident_user_col_pref')
		fetchIncidentsDetailFunc(page, pageSize, sortingInfo, fromDateTime, toDateTime, parseFilter(filterInfo));
		// fetchIncidentsDetailFunc(page, pageSize, sortingInfo, parseFilter(filterInfo));
		const activeId = document.getElementById('root');
		if (activeId) {
			document.head.classList.add('theme-bg-blue');
			document.body.classList.add('theme-bg-blue');
			activeId.classList.add('jobs-list-table');
			activeId.classList.remove('jobs-list');
			activeId.classList.remove('root');
			document.body.classList.add('jobs-exe-datas');
			document.body.classList.remove('jobs-run-table');
			document.body.classList.add('jobs-list-datas');
			document.body.classList.add('datefilter-align-sector');
			document.body.classList.add('sector-label');
		}
		// Chart
	
	}, [])

	return (
		<div className="dashboard-panel-dataestate job-schedules-wrap">
			<div className="job-exexutions-dateFilter-div teradataBG">
				<div className="service-date-filter date-picker-summary teradataProd">
					{paramsData.configurationItem ? paramsData.configurationItem : ''}
				</div>
				{/* [Date Range Menu] */}
				<div style={{ display: 'flex', alignItems: 'center', background: '#003771', 
				padding: '20px !important', marginBottom: '1px',float: 'right', width: 'max-content',borderRadius:'10px',paddingRight:'10px'}}>
					{/* condition for default Today  */}
					{moment(fromDateTime).format('DD MM YYYY, HH:mm') ===
						moment().startOf('day').subtract(1, 'hours').format('DD MM YYYY, HH:mm') &&
					moment(toDateTime).format('DD MM YYYY, HH:mm') ===
						moment().endOf('day').subtract(1, 'hours').format('DD MM YYYY, HH:mm') ? (
						<div style={{ marginRight: '80px' }}>Time Range</div>
					) : (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								marginRight: '5px',
								borderRadius:'10px',
                                backgroundColor:'#003771',
                                padding:'10px'
							}}
						>
							{fromDateTime && moment(fromDateTime).tz('America/New_York').format('DD-MM-YYYY')}&nbsp;To&nbsp; 
                            {toDateTime && moment(toDateTime).tz('America/New_York').format('DD-MM-YYYY')}
						</div>
					)}

					{/* <div onClick={handleDateClick} style={{ cursor: 'pointer', paddingRight: '4px' }}> */}
					<div style={{ paddingRight: '4px' }}>
						<DatePickerIcon />
					</div>
				</div>

				<Menu
					id="date-range-menu"
					MenuListProps={{
						'aria-labelledby': 'date-range-button'
					}}
					anchorEl={dateRangeMenuOpen}
					open={open}
					onClose={handleDateRangeMenu}
					PaperProps={{
						style: {
							width: '30ch'
						}
					}}
				>
					{/* From date */}
					<span className="range_menu_title">Absolute time range</span>
					<div key="defaultView">
						<div className="label">From</div>
						<LocalizationProvider className="outerDateContainer" dateAdapter={AdapterMoment}>
							<DateTimePicker
								showToolbar={true}
								components={{
									OpenPickerIcon: DatePickerIcon
								}}
								PopperProps={{ placement: 'auto' }}
								toolbarTitle={
									<>
										Select Date Time{' '}
										<div className="time-picker-zone">{`Timezone: ${
											moment().isDST() ? 'EDT(UTC-4)' : 'EST(UTC-5)'
										}`}</div>
									</>
								}
								className="dateFilter"
								ampm={false}
								value={moment(fromDateTime).tz('America/New_York')}
								minDateTime={moment(minJobExecDate).tz('America/New_York').startOf('day').subtract(1, 'hour')}
								maxDateTime={moment().tz('America/New_York').endOf('day').subtract(1, 'hours')}
								onChange={(dateTime) => {
									handleDateTimeChange('fromDateTime', dateTime);
								}}
								renderInput={(params: any) => <TextField name="startDateTime" {...params} />}
							/>
						</LocalizationProvider>
					</div>

					{/* To date */}
					<div>
						<div className="label">To</div>
						<LocalizationProvider className="outerDateContainer" dateAdapter={AdapterMoment}>
							<DateTimePicker
								showToolbar={true}
								components={{
									OpenPickerIcon: DatePickerIcon
								}}
								PopperProps={{
									placement: 'auto'
								}}
								toolbarTitle={
									<>
										Select Date Time{' '}
										<div className="time-picker-zone">{`Timezone: ${
											moment().isDST() ? 'EDT(UTC-4)' : 'EST(UTC-5)'
										}`}</div>
									</>
								}
								className="dateFilter"
								ampm={false}
								value={moment(toDateTime).tz('America/New_York')}
								minDateTime={moment(fromDateTime).tz('America/New_York')}
								maxDateTime={moment().tz('America/New_York').endOf('day').subtract(1, 'hour')}
								onChange={(dateTime) => {
									handleDateTimeChange('toDateTime', dateTime);
								}}
								renderInput={(params: any) => <TextField name="endDateTime" {...params} />}
							/>
						</LocalizationProvider>
					</div>
					<div className="date_range_button">
						<Button
							variant="contained"
							color="primary"
							style={{ width: '50%' }}
							onClick={() => {
								setFromDateTime(moment().startOf('day').subtract(1, 'hours'));
								setToDateTime(moment().endOf('day').subtract(1, 'hours'));
								setDateRangeMenuOpen(null);
							}}
						>
							Clear
						</Button>

						<Button variant="contained" onClick={applyDateTimeRange} style={{ width: '50%', color: '#00d08d' }}>
							Apply
						</Button>
					</div>
				</Menu>
			</div>
			{/* Bar Chart */}
			{/* Bar Chart */}
			<Grid item xs={6} className="dataestate-title chart-widget chart-widget-query bgcolor-blue">
			<div className="monitor-head job-monitor-head">
			{ incidentType == 'teradata' ? (performanceByDay ? 'Job Performance By Day' : 'Job Performance By Hour') : 'Job clusters by Day' }
				</div>
				{
					grafanaChartLoading ? 'Loading...' : <ReactECharts
						option={grafanaChartOptions}
						theme={{
							backgroundColor: '#1b1e35'
						}}
					/>}
			</Grid>
			{/* Table */}
			<span className="table-title-incident table-heading">Job Executions</span>
			<div className="incidents_tabledata mt20 padl-10">
				<Grid>
					{!loading ? (
						<CustomizedDataGrid
							tableColumns={incidentType == 'teradata' ? getColumns() : getDatabricksColumns()}
							tableData={incidentDetails}
							tableType="incident"
							defaultColumnNames={incidentType == 'teradata' ? defaultColumnNames : defaultDatabricksColumnNames}
							onSorting={handleSorting}
							currSorting={sortingInfo}
							filterDataOptions={filterData}
							searchDataValues={searchDetails}
							onFilterCheck={handleFilter}
							currFilter={filterInfo}
							onFilterSearch={handleSearch}
							page={page}
							pageSize={pageSize}
							paginationData={paginationData}
							listLength={incidentDetails.length}
							handlepageSizeChange={handlepageSizeChange}
							handlePageChange={handlePageChange}
							isFetching={loading}
							showExport={false}
							fileName={`Jobs.xlsx`}
							isDateRangeFilter={false}
							isDisabled={false}
						/>
					) : (
						<Loader />
					)}
				</Grid>
				<br />
			</div>
		</div>
	);
};
