import React from 'react';
import '../dataGrowth/dashboard.css';
import { DataGrowthDashboardUrl } from '../../service/config';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AnalyticsVisualization from '../framework/analyticsVisualization';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function CustomTabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	};
}

const dataGrowth = () => {
	const [value, setValue] = React.useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<div className="base-tab-datagrowth">
			<Box sx={{ width: '100%' }}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs className="PBITabs" value={value} onChange={handleChange} aria-label="basic tabs example">
						<Tab label="(Teradata) Avg.DB Space Growth Report" {...a11yProps(0)} />
						<Tab label="(Teradata) DB Hygiene Monitoring Reports" {...a11yProps(1)} />
					</Tabs>
				</Box>
				<CustomTabPanel value={value} index={0}>
					<AnalyticsVisualization
						params={{
							dashboardUrl: DataGrowthDashboardUrl.AvgdatabasegrowthDashboard,
							className: 'powerBiIframeContainer',
							otherParams: {
								name: 'PowerBI'
							},
							seamless: true
						}}
					/>
				</CustomTabPanel>
				<CustomTabPanel value={value} index={1}>
					<AnalyticsVisualization
						params={{
							dashboardUrl: DataGrowthDashboardUrl.TeradataProductionDashboard,
							className: 'powerBiIframeContainer',
							otherParams: {
								name: 'PowerBI'
							},
							seamless: true
						}}
					/>
				</CustomTabPanel>
			</Box>
		</div>
	);
};

export default dataGrowth;
