import PrivateRoute from 'utils/privateRoute';
import criticalIssues from 'components/Presto/criticalssues';
import prestoSummary from 'components/Presto/summary';
import usageGovernance from 'components/Presto/usageGovernance';
import PrestoObsRules from 'components/Presto/PrestoObsRules/prestoObsRules';

const PrestoObsRoutes = [
	{
		key: 'prest_obs',
		route: '/presto/observation/',
		component: <PrivateRoute Component={PrestoObsRules} restrictedRoles={[]} />
	},
	{
		key: 'prest_dash',
		route: '/presto/dashboard',
		component: (
			<PrivateRoute
				Component={prestoSummary}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'prest_usg_gov',
		route: '/presto/usageGovernance',
		component: (
			<PrivateRoute
				Component={usageGovernance}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'prest_crit',
		route: '/presto/criticalIssue',
		component: (
			<PrivateRoute
				Component={criticalIssues}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	}
];

export default PrestoObsRoutes;
