import PrivateRoute from 'utils/privateRoute';
import dataGrowth from 'components/dataGrowth/dashboard';
import databricksDataGrowth from 'components/dataGrowth/databricksDataGrowth';
import DataGrowthObs from 'components/dataGrowth/PowerBI';
import prestoDataGrowth from 'components/dataGrowth/prestoDataGrowth';
import TableauDataGrowth from 'components/dataGrowth/tableauDataGrowth';
import DatagrowthObsRules from 'components/dataGrowth/DatagrowthObsRules/datagrowthObsRules';

const DataGrowthRoutes = [
	{
		key: 'dgrow_obs',
		route: '/dataGrowth/observation/',
		component: <PrivateRoute Component={DatagrowthObsRules} restrictedRoles={[]} />
	},
	{
		key: 'data_grow_dash',
		route: '/dataGrowth/dashboard',
		component: (
			<PrivateRoute
				Component={dataGrowth}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'data_grow_db_dash',
		route: '/dataGrowthDatabricks/dashboard',
		component: (
			<PrivateRoute
				Component={databricksDataGrowth}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'data_grow_presto_dash',
		route: '/dataGrowthPresto/dashboard',
		component: (
			<PrivateRoute
				Component={prestoDataGrowth}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'data_grow_tab_dash',
		route: '/dataGrowthTableau/dashboard',
		component: (
			<PrivateRoute
				Component={TableauDataGrowth}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'data_grow_datag_dash',
		route: '/dataGrowthDataGrowth/dashboard',
		component: (
			<PrivateRoute
				Component={DataGrowthObs}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	}
];

export default DataGrowthRoutes;
