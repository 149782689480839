import { Dispatch } from "react";

interface User {
	uid: string;
	firstName: string;
	lastName: string;
	displayName: string;
	mail: string;
	tz?: string;
	prefTz?: string;
	active?: boolean;
	title?: string;
	department?: string;
	company?: string | null;
	authEnabled?: boolean;
	office?: string;
	address?: string;
	city?: string;
	state?: string;
	country?: string;
}

export interface StateTypes {
	authType: string | null;
	user: string | null;
	profile: string | null | User;
	accessToken: any | null;
	role: any | null;
	capabilities: any | null;
	isAuthenticated: boolean;
}

export type ActionTypes =
	| { type: 'CLEAR_AUTH_CACHE' }
	| { type: 'SET_AUTH_TYPE'; value: StateTypes['authType'] }
	| { type: 'SET_USER'; value: StateTypes['user'] }
	| { type: 'SET_PROFILE'; value: StateTypes['profile'] }
	| { type: 'SET_TOKEN'; value: StateTypes['accessToken'] }
	| { type: 'SET_AUTHENTICATED'; value: StateTypes['isAuthenticated'] }
	| { type: 'SET_ROLE'; value: StateTypes['role'] }
	| { type: 'SET_CAPABILITIES'; value: StateTypes['capabilities'] };

// Add ActionTypes type to AUTH_ACTIONS
export const AUTH_ACTIONS = [
	'CLEAR_AUTH_CACHE',
	'SET_USER',
	'SET_PROFILE',
	'SET_AUTH_TYPE',
	'SET_TOKEN',
	'SET_ROLE',
	'SET_CAPABILITIES',
	'SET_AUTHENTICATED'
];

export type PepwiseAuthContextType = [StateTypes, Dispatch<ActionTypes>];
