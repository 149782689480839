import React, { useState, useEffect, useRef } from 'react';
import { Button, Chip, Grid, Menu, TextField, FormControlLabel, Stack } from '@mui/material';
import DashboardIcon from '../../assets/material-symbols_dashboard.svg';
import './controlsCompliance.css';
import { getDecodedToken, getSector } from '../../service/service';
import useStyles from '../jobs/jobs_styles';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import { ReactComponent as FilterIcon } from '../../assets/ml_summary_filter.svg';
import UnderDevelopment from '../../assets/under-construction.svg';
import AnalyticsVisualization from '../framework/analyticsVisualization';
import { dashboardUrl } from '../../service/config';


const controlTestDashboard = () => {
	const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
	console.log(userSector, 'userSector test');
	const classes = useStyles();
	const dataFetchedRef = useRef(false);
	const [sectorOptionsList, setSectorOptionsList] = useState<any[]>([]);
	const [filterMenu, setFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	const [itemsChecked, setItemsChecked] = useState(false);
	const [checkedList, setCheckedList] = useState<any[]>([]);
	const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);

	const filterClick = (event: any) => {
		event.preventDefault();
		setFilterMenu(
			filterMenu === null
				? {
					mouseX: parseInt(event.clientX) - 20,
					mouseY: parseInt(event.clientY) - 6
				}
				: null
		);
	};
	const handleClose = (event: any) => {
		setFilterMenu(null);
	};
	const selectAllCheck = (e: any) => {
		const { checked } = e.target;
		const collection: any[] = [];
		if (checked) setCheckedList(sectorOptionsList);
		else setCheckedList([]);
		setItemsChecked(checked);
	};
	const handleChange = (e: any) => {
		const { value, checked } = e.target;
		const newlyChecked = [];
		if (checked && e.target.value) {
			newlyChecked.push(e.target.value);
		}
		if (checked) {
			setCheckedList([...checkedList, ...newlyChecked]);
		} else {
			setCheckedList(checkedList.filter((item) => item !== value));
		}
	};

	const handleOkClick = (event: any) => {
		if (checkedList.length > 0) {
			setCurrSectorFilter(checkedList);
		} else {
			setCurrSectorFilter([]);
		}

	};
	async function fetchSectorOptionsData() {
		const sectorRes = await getSector('1', '100', '');
		if (sectorRes.success) {
			setSectorOptionsList(sectorRes.data.sector.map((x: any) => x.name));
		}
	}
	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;

		if (userSector === 'all') fetchSectorOptionsData();
		let storedSectorFilter = localStorage.getItem('presto-summary-sector-filter')
			? JSON.parse(localStorage.getItem('presto-summary-sector-filter') ?? '')
			: null;
		if (storedSectorFilter) {
			setCheckedList(storedSectorFilter);
			setCurrSectorFilter(storedSectorFilter);
		}
	});
	return (

		<div className="controlCompliance-denodo-panel">
			{/* <Stack>
			{userSector === 'all' ? (
				<FilterIcon onClick={filterClick} className="sector-filter" />
			) : (
				<div className="sector-filter-text">Sector: {getDecodedToken()?.sector?.name}</div>
			)}

			<Popover
				open={filterMenu !== null}
				onClose={handleClose}
				className="filterContainer"
				anchorReference="anchorPosition"
				anchorPosition={filterMenu !== null ? { top: filterMenu.mouseY, left: filterMenu.mouseX } : undefined}
			>
				<div className="text-filter-row">
					<div className="selectAll-sec">
						<MenuItem className="selectAll">
							<FormControlLabel
								className={classes.checkbox}
								label="All"
								control={
									<Checkbox
										color="success"
										value="all"
										checked={itemsChecked}
										onChange={(e) => {
											selectAllCheck(e);
										}}
									/>
								}
							/>
						</MenuItem>
						<div className="checkbox-sec">
							{sectorOptionsList &&
								sectorOptionsList.map((filter: any) => (
									<MenuItem key={filter}>
										<FormControlLabel
											className={classes.checkbox}
											label={filter === true ? 'Yes' : filter === false ? 'No' : filter}
											control={
												<Checkbox
													color="success"
													value={filter}
													checked={
														(filter === true && checkedList.includes('true')) ||
														(filter === false && checkedList.includes('false')) ||
														checkedList.includes(filter)
													}
													onChange={(e) => {
														handleChange(e);
													}}
												/>
											}
										/>
									</MenuItem>
								))}
						</div>
					</div>
					<div className="buttonSec">
						<Button variant="contained" className={classes.cancelBtn} onClick={handleClose}>
							cancel
						</Button>
						<Button variant="contained" className={classes.okBtn} onClick={handleOkClick}>
							ok
						</Button>
					</div>
				</div>
			</Popover>
			
		</Stack> */}


			<div>
				{dashboardUrl.controlsTestingDashboard !== undefined ? (
					<div style={{ marginTop: '37px' }}>
						<AnalyticsVisualization
							params={{
								dashboardUrl: dashboardUrl.controlsTestingDashboard,
								className: 'controlsTestingIframeContainer',

								otherParams: {
									from: 'now-6h',
									to: 'now'
								},
								seamless: true
							}}
						/>
					</div>
				) : (
					<div style={{ position: 'absolute', left: '45%', top: '50%', color: 'darkgray' }}>
						<img src={UnderDevelopment} />
						<p style={{ position: 'relative', right: '25%', margin: '0' }}>Dashboard under development!</p>
					</div>
				)}
			</div>

		</div>
	);
};

export default controlTestDashboard;
