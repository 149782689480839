import React, { useEffect, useState, useRef } from 'react';
import { Grid, Chip } from '@mui/material';
import { getMachines } from '../../service/service';

import { CustomizedDataGrid } from '../customized_data_grid';

import type { Column } from 'react-data-grid';
import '../machines/machines.css';
export default function machinesListing() {
	interface Row {
		name: string;
		schedulerName: string;
		os: string;
		status: string;
		id: string;
	}

	const renderStatus = (status: any) => {
		switch (status) {
			case 'ACTIVE':
				return <Chip label={status} color="success" />;
			default:
				return <Chip label={status} color="secondary" />;
		}
	};
	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'name',
				name: 'Machine',
				minWidth: 150,
				width: 300
			},
			{
				key: 'schedulerName',
				name: 'Scheduler',
				width: 200,
				minWidth: 150
			},
			{
				key: 'os',
				name: 'OS',
				minWidth: 250,
				width: 400
			},
			{
				key: 'status',
				name: 'Status',
				cellClass: 'center-text',
				width: 250,
				minWidth: 150,
				maxWidth: 300,
				formatter(props) {
					const status = props.row.status;
					return renderStatus(status);
				}
			}
		];
	}
	const dataFetchedRef = useRef(false);
	const [machinesList, setMachinesList] = useState<Row[]>([]);
	const [loading, setLoading] = useState(false);
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);

	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [filterData, setFilterData] = useState<Row[]>([]);
	const filterQuerySkeleton = {} as any;
	getColumns().forEach((column: any) => {
		filterQuerySkeleton[column.key] = [];
	});
	const [filterInfo, setFilterInfo] = useState(filterQuerySkeleton);

	const defaultColumnNames = ['Machine', 'Scheduler', 'OS', 'Status'];

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		machinesListingFunc(value, pageSize, sortingInfo, parseFilter(filterInfo));
	};

	const handlepageSizeChange = (event: any) => {
		machinesListingFunc(page, event.target.value, sortingInfo, parseFilter(filterInfo));
	};
	const handleSorting = (sortingInfo: any) => {
		machinesListingFunc(page, pageSize, sortingInfo, parseFilter(filterInfo));
	};
	const handleFilter = (filterInfo: any) => {
		setFilterInfo(filterInfo);
		machinesListingFunc(1, pageSize, sortingInfo, parseFilter(filterInfo));
	};

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		const storedMachinesState = localStorage.getItem('machinesState')
			? JSON.parse(localStorage.getItem('machinesState') ?? '')
			: null;
		if (storedMachinesState && storedMachinesState.pageNumber && storedMachinesState.pageSize) {
			machinesListingFunc(
				1,
				storedMachinesState.pageSize,
				storedMachinesState.sortingInfo,
				parseFilter(storedMachinesState.filterInfo)
			);
			setPaginationData(storedMachinesState.page);
			setFilterData(storedMachinesState.filterOptions);
			setPage(1);
			setPageSize(storedMachinesState.pageSize);
			setSortingInfo(storedMachinesState.sortingInfo);
			setFilterInfo(storedMachinesState.filterInfo);
		} else {
			machinesListingFunc(page, pageSize, sortingInfo, parseFilter(filterInfo));
		}
	}, []);

	async function machinesListingFunc(pageNumber: any, size: any, sortingInfo: any, machineFilterInfo: any) {
		setLoading(true);
		if (pageSize !== size) {
			pageNumber = 1;
		}
		const res = await getMachines(pageNumber.toString(), size.toString(), sortingInfo, machineFilterInfo);
		if (res.success) {
			setMachinesList(res.data.machines);
			setPaginationData(res.data.page);
			setFilterData(res.data.filterOptions);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
			setLoading(false);
			const machinesState = {
				page: res.data.page,
				filterOptions: res.data.filterOptions,
				pageSize: size,
				pageNumber,
				sortingInfo,
				filterInfo: machineFilterInfo
			};
			localStorage.setItem('machinesState', JSON.stringify(machinesState));
		}
	}

	return (
		<div className="machines-table page-table">
			<Grid>
				<CustomizedDataGrid
					tableColumns={getColumns()}
					tableData={machinesList}
					tableType="Machine"
					defaultColumnNames={defaultColumnNames}
					onSorting={handleSorting}
					currSorting={sortingInfo}
					filterDataOptions={filterData}
					onFilterCheck={handleFilter}
					currFilter={filterInfo}
					page={page}
					pageSize={pageSize}
					paginationData={paginationData}
					listLength={machinesList.length}
					handlepageSizeChange={handlepageSizeChange}
					handlePageChange={handlePageChange}
					isFetching={loading}
					isDateRangeFilter={false}
					isDisabled={false}
				/>
			</Grid>
		</div>
	);
}
