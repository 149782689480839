import React from 'react';
import AnalyticsVisualization from '../framework/analyticsVisualization';
import { dashboardUrl } from '../../service/config';
import UnderDevelopment from '../../assets/under-construction.svg';
const Catalogues = () => {
	return (
		<div>
			{dashboardUrl.dmfCatalogues !== undefined ? (
				<div style={{ position: 'relative', top: '140px' }}>
					<AnalyticsVisualization
						params={{
							dashboardUrl: dashboardUrl.dmfCatalogues,
							className: 'powerBiCapacityIframeContainer',

							otherParams: {
								from: 'now-6h',
								to: 'now'
							},
							seamless: true
						}}
					/>
				</div>
			) : (
				<div style={{ position: 'absolute', left: '45%', top: '50%', color: 'darkgray' }}>
					<img src={UnderDevelopment} />
					<p style={{ position: 'relative', right: '25%', margin: '0' }}>Dashboard under development!</p>
				</div>
			)}
		</div>
	);
};

export default Catalogues;

