import { Typography, Table, TableBody, TableRow, TableCell, Paper } from '@mui/material';

const MoreRegressionDetails = ({ regressionPerformance }: any) => {
	return (
		<Paper elevation={8} className="width-65">
			<Typography variant="h6" component="h6">
				More Details
			</Typography>
			{regressionPerformance && (
				<Table className="performance-overview-table">
					<TableBody>
						<TableRow>
							<TableCell className="attrTitle">Mean Absolute Error</TableCell>
							<TableCell className="attrValue">
								{regressionPerformance['Mean Absolute Error'] &&
									regressionPerformance['Mean Absolute Error'].toFixed(6)}
							</TableCell>
							<TableCell className="attrTitle">Root Mean Squared Log Error</TableCell>
							<TableCell className="attrValue" title={regressionPerformance['Root Mean Squared Error']}>
								{regressionPerformance['Root Mean Squared Error'] &&
									regressionPerformance['Root Mean Squared Error'].toFixed(6)}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell className="attrTitle">Mean Squared Error</TableCell>
							<TableCell className="attrValue" title={regressionPerformance['Mean Squared Error']}>
								{regressionPerformance['Mean Squared Error'] && regressionPerformance['Mean Squared Error'].toFixed(6)}
							</TableCell>
							<TableCell className="attrTitle">R Squared Error</TableCell>
							<TableCell className="attrValue" title={regressionPerformance['R Squared']}>
								{regressionPerformance['R Squared'] && regressionPerformance['R Squared'].toFixed(6)}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell className="attrTitle">Root Mean Squared Error</TableCell>
							<TableCell className="attrValue" title={regressionPerformance['Root Mean Squared Logarithmic Error']}>
								{regressionPerformance['Root Mean Squared Logarithmic Error'] &&
									regressionPerformance['Root Mean Squared Logarithmic Error'].toFixed(6)}
							</TableCell>
							<TableCell className="attrTitle"></TableCell>
							<TableCell className="attrValue"></TableCell>
						</TableRow>
					</TableBody>
				</Table>
			)}
		</Paper>
	);
};

export default MoreRegressionDetails;
