import { Chip } from '@mui/material';

function renderSLAStatus(slaStatus: any) {
	return ['Met', 'Not Met'].includes(slaStatus) ? (
		<Chip label={slaStatus} color={slaStatus === 'Met' ? 'success' : 'secondary'} className="slaChip" />
	) : (
		slaStatus
	);
}

export default renderSLAStatus;
