import { Dispatch } from 'react';
import { CacheDataActions, CacheDataState } from '../types/CacheDataContext';
import { DrawerStateType } from './type';

export const drawerState: DrawerStateType = {
	drawerComponent: null,
	drawerCacheData: {}
};

export const drawerReducer = (state: CacheDataState, action: CacheDataActions): CacheDataState => {
	switch (action.type) {
		case 'REMOVE_COMPONENT': {
			return { ...state, drawerComponent: null };
		}
		case 'SET_COMPONENT': {
			return { ...state, drawerComponent: action.value };
		}
		case 'CACHE_DRAWER_DATA': {
			return {
				...state,
				drawerCacheData: {
					...state.drawerCacheData,
					...action.value
				}
			};
		}
		default:
			return state;
	}
};

// Action creators
export const removeDrawerComponent = (dispatch: Dispatch<CacheDataActions>) => {
	dispatch({
		type: 'REMOVE_COMPONENT'
	});
};

export const setDrawerComponent = (dispatch: Dispatch<CacheDataActions>, value: CacheDataState['drawerComponent']) =>
	dispatch({ type: 'SET_COMPONENT', value });

export const setDrawerDataCache = (dispatch: Dispatch<CacheDataActions>, value: CacheDataState['drawerCacheData']) =>
	dispatch({ type: 'CACHE_DRAWER_DATA', value });