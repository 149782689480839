import { Suspense } from 'react';
import { Drawer } from '@mui/material';
import DrawerLoader from 'components/SideDrawer/Loader';
import { useCacheDataController } from 'Context/contexts/CacheDataContext';
import { removeDrawerComponent } from 'Context/contexts/cacheData/SideDrawer';

const SideDrawer = () => {
	const [controller, dispatch] = useCacheDataController();
	const { drawerComponent } = controller;

	const handleDrawerClose = () => {
		removeDrawerComponent(dispatch);
	};

	return (
		<Drawer
			anchor="right"
			open={drawerComponent !== null}
			onClose={handleDrawerClose}
			slotProps={{
				backdrop: {
					sx: { backdropFilter: 'blur(3px)', background: 'none' }
				}
			}}
		>
			{drawerComponent !== null ? <Suspense fallback={<DrawerLoader />}>{drawerComponent}</Suspense> : <></>}
		</Drawer>
	);
};

export default SideDrawer;
