import React, { useEffect, useRef, useState } from 'react';
import { Grid, Box } from '@mui/material';
import { isEnvTypePROD } from '../../service/config';
import '../TableauObs/Summary/Summary.scss';
import moment from 'moment';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
	dataEstateExecutiveSummary,
	dataEstateOverallSummary,
	fetchAnnualSpendDataSourceIncidents,
	fetchAzureMonthlyCost,
	fetchBIApplicationSummary,
	fetchDEStorageSummary,
	fetchDatabricksClustersCount,
	getDecodedToken,
	getOverviewSummaryMetrics,
	getSector
} from '../../service/service';
import useStyles from '../jobs/jobs_styles';
import '../DataEstate/data-estate.scss';
import ArrowIcon from '../../assets/Arrow 253.svg';
import ArrowIconRed from '../../assets/growth arrow down.svg';
import NumberWidget from '../Widgets/NumberWidget';
import WidgetCardContainer from 'components/Widgets/WidgetCardContainer';
import { getPercentageOfTwoNumbers, formatNumberWithCommas } from '../../utils/common-util';
import useDataEstateSideDrawer from './SideDrawers/useDrawerComponent';
import { useCacheDataController } from 'Context/contexts/CacheDataContext';
import { MetricDetails, OverviewMetrics } from 'Context/contexts/cacheData/DataEstate/type';
import { setDataBricksClusters, setExecutiveMetricDetails, setMetricDetails } from 'Context/contexts/cacheData/DataEstate';

const OverallSummary = () => {
	const navigate = useNavigate()
	const {handleSetComponent} = useDataEstateSideDrawer();
	const [searchParams, setSearchParams] = useSearchParams();
	const hasDrillDown = searchParams.has('isDrillDowned');
	const minJobExecDate = '2023-03-01';
	const dateFrom: string = searchParams.get('fromDate')?.substring(0, 10) as string;
	const startDt = moment(dateFrom).startOf('day').subtract(1, 'hours').format('YYYY-MM-DD');
	const [fromDateTime, setFromDateTime] = useState<any>(
		searchParams.has('fromDate') ? startDt : moment().startOf('day').subtract(1, 'hours')
	);

	const [propsFromDateTime, setPropsFromDateTime] = useState<any>(
		searchParams.has('fromDate') ? startDt : moment().startOf('day').subtract(1, 'hours')
	);

	// const [databricksClusters, SetDatabricksClusters] = useState();
	const [overviewMetrics, setOverviewMetrics] = useState<OverviewMetrics | null>(null);
	const [tableauUsers, setTableauUsers] = useState(null);
	const [tableauUsersPrev, setTableauUsersPrev] = useState(null);
	const [tableauUsersGrowth, setTableauUsersGrowth] = useState(0);

	// sector filter
	const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
	const [filterMenu, setFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	const classes = useStyles();
	const [itemsChecked, setItemsChecked] = useState(false);
	const [sectorOptionsList, setSectorOptionsList] = useState<any[]>([]);
	const [checkedList, setCheckedList] = useState<any[]>([]);
	const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);
	const [sector, setCurrSector] = useState<any[]>([]);
	const dataFetchedRef = useRef(false);

	const [TeradataSize, SetTeradataSize] = useState(0);
	const [TeradataPrevSize, SetTeradataPrevSize] = useState(0);
	const [teradataJobs, SetTeradataJobs] = useState(0);
	const [teradataGrowthSize, SetTeradataGrowthSize] = useState(0);

	const [controller, dispatch] = useCacheDataController();
	const { metricDetails, executiveMetricDetails, databricksClusters } = controller;

	const sectorFilterClick = (event: any) => {
		event.preventDefault();
		setFilterMenu(
			filterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 20,
						mouseY: parseInt(event.clientY) - 6
				  }
				: null
		);
	};

	const handleClose = (event: any) => {
		setFilterMenu(null);
	};

	const selectAllCheck = (e: any) => {
		const { checked } = e.target;
		const collection: any[] = [];
		if (checked) setCheckedList(sectorOptionsList);
		else setCheckedList([]);
		setItemsChecked(checked);
	};

	const handleChange = (e: any) => {
		const { value, checked } = e.target;
		const newlyChecked = [];
		if (checked && e.target.value) {
			newlyChecked.push(e.target.value);
		}
		if (checked) {
			setCheckedList([...checkedList, ...newlyChecked]);
			if (sectorOptionsList.length === checkedList.length + newlyChecked.length) {
				setItemsChecked(true);
			}
		} else {
			setCheckedList(checkedList.filter((item) => item !== value));
			setItemsChecked(false);
		}
	};
	const handleOkClick = (event: any) => {
		localStorage.setItem('selectedSector', JSON.stringify(checkedList)); // Tableau drilldown filter changes
		if (checkedList.length > 0) {
			setCurrSectorFilter(checkedList);
		} else {
			setCurrSectorFilter([]);
		}
		setCurrSector(checkedList);
		handleClose(event);
	};

	async function fetchSectorOptionsData() {
		const sectorRes = await getSector('1', '100', '');
		if (sectorRes.success) {
			const filteredSectorOptions = sectorRes.data.sector.filter((x: any) => x.name !== 'S&T').map((x: any) => x.name);
			filteredSectorOptions.push('Blank');
			setSectorOptionsList(filteredSectorOptions);
		}
	}
	const [loading, setLoading] = useState(false);
	// const [metricDetails, setMetricDetails] = useState<MetricDetails | null>(null);
	const [executiveLoading, setExecutiveLoading] = useState(false);
	// const [executiveMetricDetails, setExecutiveMetricDetails] = useState<MetricDetails | null>(null);
	const [OutageDuration, setOutageDuration] = useState<any>(null);
	const [azureMonthlyCostDetails, setAzureMonthlyCostDetails] = useState<MetricDetails | null>(null);
	const [powerBiUsers, setPowerBiUsers] = useState<number | string | null>(null);

	const handleNavigateStorage = () => {
		navigate('/dataestate/storagesummary')
	};

	const handleNavigateDataProcessing = () => {
		navigate('/dataestate/data-processing')
	};

	const handleNavigateQueries = () => {
		navigate('/dataestate/queriessummary')
	};

	const handleNavigateBiApps = () => {
		navigate('/dataestate/biApplicationSummary')
	};

	const handleNavigateService = () => {
		navigate('/dataestate/service-request')
	};

	const handleNavigateIncident = () => {
		navigate('/dataestate/incident-details')
	};

	const handleNavigateCost = () => {
		navigate('/dataestate/costSummary')
	};

	function splitHrsToDays(numberOfHours: any) {
		var Days = Math.floor(numberOfHours / 24);
		var Remainder = numberOfHours % 24;
		var Hours = Math.floor(Remainder);
		var Minutes = Math.floor(60 * (Remainder - Hours));
		return { Days: Days, Hours: Hours, Minutes: Minutes };
	}

	async function fetchDEStorageSummaryFunc() {
		fetchDEStorageSummary(sector, '0M')
			.then((res) => {
				let teradataSize = res?.data?.dataStoredInTeradata?.[0]?.['VALUE'] ?? 0;
				let teradataPrevSize = res?.data?.dataStoredInTeradataPrev?.[0]?.['VALUE'] ?? 0;
				SetTeradataPrevSize(teradataPrevSize);
				SetTeradataSize(teradataSize);

				if (teradataPrevSize) {
					SetTeradataGrowthSize(getPercentageOfTwoNumbers(teradataPrevSize, teradataSize));
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}

	async function loadDatabricksClustersCount() {
		if(databricksClusters === null){
			setLoading(true);
			try {
				const res = await fetchDatabricksClustersCount();
				if (res.success) {
					let count = res.data.databricksClustersCount.length
						? res.data.databricksClustersCount.reduce((count: any, item: any) => count + item.SqlWarehouse + item.Job + item.Interactive, 0) 
						: 0;
						setDataBricksClusters(dispatch, count);
				}
				setLoading(false);
			} catch (error) {
				setLoading(false);
			}
		}
	}

	async function dataestateOverallSummaryFunc() {
		if(metricDetails === null){
			setLoading(true);
			try {
				const res = await dataEstateOverallSummary();
				if (res.success) {
					setMetricDetails(dispatch, res.data);
					fetchAnnualSpendDataSourceIncidents('teradata-jobs', '0M', sector)
						.then((res) => {
							SetTeradataJobs(res?.data?.result[0]?.count);
						})
						.catch((error) => {
							console.error(error);
						});
				}
				setLoading(false);
			} catch (error) {
				setLoading(false);
			}
		}
	}

	async function dataestateExecutiveSummaryFunc() {
		if(executiveMetricDetails === null){
			setExecutiveLoading(true);
			try {
				const res = await dataEstateExecutiveSummary();
				if (res.success) {
					setExecutiveMetricDetails(dispatch, res.data);
					setOutageDuration(splitHrsToDays(res.data.outageDuration));
				}
				setExecutiveLoading(false);
			} catch (error) {
				setExecutiveLoading(false);
			}
		} else {
			setOutageDuration(splitHrsToDays(executiveMetricDetails.outageDuration));
		}
	}

	async function dataestateAzureMonthlyCostFunc() {
		setExecutiveLoading(true);
		try {
			const res = await fetchAzureMonthlyCost();
			if (res.success) {
				setAzureMonthlyCostDetails(res.data);
			}
			setExecutiveLoading(false);
		} catch (error) {
			setExecutiveLoading(false);
		}
	}

	async function getOverviewSummaryMetricsFunc() {
		setLoading(true);
		try {
			const res = await getOverviewSummaryMetrics();
			if (res.success) {
				setOverviewMetrics(res.data[0]);
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	}

	async function tableauUserCount() {
		setLoading(true);
		try {
			const res = await fetchBIApplicationSummary('tableau-users', '', '0M', moment().format());

			if (res.success) {
				let count = res?.data?.result[0]?.TotalRecords;

				setTableauUsers(count);
				const previousData = await fetchBIApplicationSummary('tableau-users', '', '1M', moment().format());
				let previousCount = previousData?.data?.result[0]?.TotalRecords;
				setTableauUsersPrev(previousCount);
				setTableauUsersGrowth(((count - previousCount) / previousCount) * 100);
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	}
	async function powerBIUserCount() {
		setLoading(true);
		try {
			const res = await fetchBIApplicationSummary('powerbi-users', '', '0M', moment().format());
			
			if (res.success) {
				let count = res?.data?.result[0]?.PowerBIUsersCount;
				setPowerBiUsers((count/1000).toFixed(2).toString())
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	}


	// async function getCrossEnvData() {
	// 	setLoading(true);
	// 	try {
	// 		const res = await fetchCrossEnvData('tableau_users', 'Tableau', '0M');
	// 		if (res.success) {
	// 			let count = res?.data[0]?.KPI_VALUE + res?.data[1]?.KPI_VALUE;
	// 			setTableauUsers(count);
	// 			setTableauUsersGrowth(res?.data[0]?.dataGrowth);
	// 		}
	// 		setLoading(false);
	// 	} catch (error) {
	// 		setLoading(false);
	// 	}
	// }

	useEffect(() => {
		if (!loading) dataestateOverallSummaryFunc();
		dataestateExecutiveSummaryFunc();
		fetchDEStorageSummaryFunc();
		loadDatabricksClustersCount();
		// const storedMetricDetails = localStorage.getItem('metricDetails');
				// if (storedMetricDetails) {
				// 	setMetricDetails(JSON.parse(storedMetricDetails));
				// }

				// const storedTeradataJobs = localStorage.getItem('teradataMetrics');
				// if (storedTeradataJobs) {
				// 	SetTeradataJobs(Number(storedTeradataJobs));
				// }
		
				// const storedTeradataSize = localStorage.getItem('teradataSize');
				// if (storedTeradataSize) {
				// 	SetTeradataSize(Number(storedTeradataSize));
				// }	
		// dataestateAzureMonthlyCostFunc();

		// const months = ['2024-06-01'];

		// fetchDatabricksClusters('databricks-clusters', months, checkedList)
		// 	.then((res) => {
		// 		SetDatabricksClusters((res?.data?.totalClustersArray[0]?.count / 1000).toFixed(2).toString());
		// 	})
		// 	.catch((error) => {
		// 		console.error(error);
		// 	});

		// // Summary APIs

		if (hasDrillDown) {
			const selectedSector = localStorage.getItem('selectedSector');
			if (selectedSector) {
				const selectedSec = JSON.parse(selectedSector).length ? JSON.parse(selectedSector) : '';
				setCheckedList(selectedSec);
				setCurrSectorFilter(selectedSec);
			}
		} else {
			localStorage.removeItem('selectedSector');
		}

		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		// if (userSector === 'all') fetchSectorOptionsData();
		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/dataestate/executive-summary') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('datefilter-align-sector');
				document.body.classList.add('sector-label');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				document.body.classList.add('dashboard-panel-dataestate');
				document.body.classList.add('datefilter-align-sector');
			}
		}
		//Metrics
		getOverviewSummaryMetricsFunc();
		tableauUserCount();
		powerBIUserCount();
		// getCrossEnvData();
	}, []);

	const metricDetailsOverSize: number = 
		metricDetails === null || metricDetails.overallGrowth === null
			? 0
			: metricDetails.overallGrowth + teradataGrowthSize;

	return (
		<Box mt="120px" p={4}>
			<Grid container spacing={2}>
				<Grid container item xs={12} md={6} lg={4}>
					<WidgetCardContainer title="Size">
						<Grid item xs={12} display="flex">
							<NumberWidget
								title="Data Stored Across D&AI Platforms - (as of Today)"
								tooltipLabel="Data Stored Across D&AI Platforms (All environments) and Teradata (Prod)"
								number={
									metricDetails && metricDetails !== null ? formatNumberWithCommas((metricDetails.dataStoredDA + TeradataSize).toFixed(2), true) : 0
								}
								isLoading={!metricDetails || metricDetails === null}
								footer={{
									footerLabel: 'Click to look at Data Storage breakdown',
									footerLink: '/dataestate/storagesummary'
								}}
								units="PB"
								// handleEvent={handleNavigateStorage}
								handleEvent={() => handleSetComponent('DAI_PLATEFORMS', {
									sector: [],
									selectedDate: "2023-01",
								})}
							/>
						</Grid>
						<Grid item xs={12} display="flex">
							<NumberWidget
								title="Overall Data Growth (MoM) - (as of Today)"
								tooltipLabel={
									metricDetails && TeradataSize
										? `Month over month data growth across D&AI platforms and Teradata PROD. (Current Month - Azure: ${formatNumberWithCommas(metricDetails.dataStoredDA.toFixed(2),true)} PB Teradata: ${formatNumberWithCommas(TeradataSize.toFixed(2),true)} PB & Last Month - Azure: ${formatNumberWithCommas(metricDetails.storagePrevMonCount.toFixed(2))} PB Teradata: ${TeradataPrevSize.toFixed(2)} PB)`
										: ''
								}
								number={
									metricDetails !== null ? formatNumberWithCommas((metricDetailsOverSize).toFixed(2), true) : 0
								}
								isLoading={!metricDetails || metricDetails === null}
								footer={{
									footerLabel: 'Click to look at Data Storage breakdown',
									footerLink: '/dataestate/storagesummary'
								}}
								units="%"
								handleEvent={() => handleSetComponent('OVERALL_DATA_GROWTH', {
									sector: [],
									selectedDate: "0M",
								})}
								color={
									metricDetails !== null && metricDetailsOverSize !== 0
										? metricDetailsOverSize >= 0
											? 'success.main'
											: 'error.main'
										: 'text.main'
								}
								unitIcon={
									metricDetails !== null && metricDetailsOverSize !== 0
										? metricDetailsOverSize >= 0
											? ArrowIcon
											: ArrowIconRed
										: null
								}
							/>
						</Grid>
					</WidgetCardContainer>
				</Grid>
				<Grid container item xs={12} md={6} lg={4}>
					<WidgetCardContainer title={['Processing', 'Pipelines']}>
						<Grid item xs={12} display="flex">
							<NumberWidget
								title={'Total Pipelines - (as of Today)'}
								tooltipLabel="Total data processing pipelines in D&AI across Azure (All environments) and Teradata Jobs (Prod)"
								number={
									metricDetails !== null && teradataJobs !== null
										? formatNumberWithCommas(((metricDetails.dataPipelineCount + teradataJobs) / 1000).toFixed(2), true)
										: 0
								}
								isLoading={metricDetails === null || teradataJobs === null}
								footer={{
									footerLabel: 'Click to look at processing breakdown',
									footerLink: '/dataestate/data-processing'
								}}
								units="k"
								// handleEvent={handleNavigateDataProcessing}
								handleEvent={() => handleSetComponent('TOTAL_PIPELINES', {
									sector: [],
									selectedDate: "0M",
								})}
								// isInProgress
							/>
						</Grid>
						<Grid item xs={12} display="flex">
							<NumberWidget
								title="Total Clusters (Compute) - (as of Today)"
								tooltipLabel={'Total compute clusters in Databricks (All environments)'}
								number={databricksClusters && databricksClusters !== null ? formatNumberWithCommas((databricksClusters/ 1000).toFixed(2),true) : 0}
								isLoading={databricksClusters === null}
								footer={{
									footerLabel: 'Click to look at processing breakdown',
									footerLink: '/dataestate/data-processing'
								}}
								units="k"
								//handleEvent={handleNavigateDataProcessing}
								handleEvent={() => handleSetComponent('TOTAL_CLUSTER', {
									sector: [],
									selectedDate: "2023-01",
								})}
								// isInProgress
							/>
						</Grid>
					</WidgetCardContainer>
				</Grid>
				<Grid container item xs={12} lg={4}>
					<WidgetCardContainer title="BI Usage">
						<Grid item xs={12} md={3} lg={6} display="flex">
							<NumberWidget
								title="PowerBI Users - (as of Today)"
								tooltipLabel="Total PowerBI users in Prod"
								number={(powerBiUsers && powerBiUsers !== null ? powerBiUsers : 0).toLocaleString(
									undefined,
									{ minimumFractionDigits: 0 }
								)}
								isLoading={!powerBiUsers}
								units="k"
								handleEvent={() => handleSetComponent('POWERBI_USERS', {
									sector: [],
									selectedDate: "2023-01",
								})}
								// isInProgress
							/>
						</Grid>
						<Grid item xs={12} md={3} lg={6} display="flex">
							<NumberWidget
								title="Tableau Users - (as of Today)"
								tooltipLabel={'Total Tableau users in PROD'}
								number={(tableauUsers ? (tableauUsers / 1000).toFixed(2) : 0).toLocaleString(undefined, {
									minimumFractionDigits: 0
								})}
								isLoading={!tableauUsers}
								footer={{
									footerLabel: 'Click to look at processing breakdown',
									footerLink: '/dataestate/biApplicationSummary'
								}}
								units="k"
								// handleEvent={handleNavigateBiApps}
								handleEvent={() => handleSetComponent('TABLEAU_USERS', {
									sector: [],
									selectedDate: "2023-01",
								})}
							/>
						</Grid>
						<Grid item xs={12} md={3} lg={6} display="flex">
							<NumberWidget
								title="PowerBI User Growth (MoM) - (as of Today)"
								tooltipLabel={'Insufficient Data'}
								// Commented as per request by Pepsico
								// number={metricDetails !== null ? (metricDetails.activeReports / 1000).toFixed(2) : 0}
								// isLoading={metricDetails === null}
								number=''
								isLoading={false}
								units=""
								handleEvent={() => handleSetComponent('POWERBI_USER_GROWTH', {
									sector: [],
									selectedDate: "0M",
								})}									
							/>
						</Grid>
						<Grid item xs={12} md={3} lg={6} display="flex">
							<NumberWidget
								title="Tableau User Growth (MoM) - (as of Today)"
								tooltipLabel={`Month over month user growth in Tableau - Current Month: ${formatNumberWithCommas(tableauUsers)} & Last Month: ${formatNumberWithCommas(tableauUsersPrev)}`}
								number={tableauUsersGrowth ? formatNumberWithCommas(tableauUsersGrowth.toFixed(2), true) : 0}
								isLoading={false}
								units="%"
								handleEvent={() => handleSetComponent('TABLEAU_USER_GROWTH', {
									sector: [],
									selectedDate: "0M",
								})}
							/>
						</Grid>
					</WidgetCardContainer>
				</Grid>
				<Grid container item xs={12} lg={6}>
					<WidgetCardContainer title="Sustain">
						<Grid item xs={12} md={4} display="flex">
							<NumberWidget
								title="Major Incidents Open - (as of Today)"
								tooltipLabel="Total D&AI major incidents open"
								number={executiveMetricDetails?.incidentsOpen ?? ''}
								isLoading={!executiveMetricDetails || executiveLoading}
							/>
						</Grid>
						<Grid item xs={12} md={4} display="flex">
							<NumberWidget
								title="Major incidents in Last Month"
								tooltipLabel="D&AI major incidents open in last month"
								number={executiveMetricDetails !== null ? executiveMetricDetails?.incidentOpenedLastMonth : 1}
								isLoading={!executiveMetricDetails || executiveLoading}
								handleEvent={handleNavigateIncident}
								// handleEvent={() => handleSetComponent('MAJOR_INCIDENTS_LAST_MONTH', {
								// 	sector: [],
								// 	selectedDate: "2023-01",
								// })}
							/>
						</Grid>
						<Grid item xs={12} md={4} display="flex">
							<NumberWidget
								title="Total Outages in Last Month"
								tooltipLabel="Total D&AI current outages"
								number={executiveMetricDetails !== null ? executiveMetricDetails?.outage : 1}
								isLoading={!executiveMetricDetails || executiveLoading}
								footer={{
									footerLabel: 'Click to look at processing breakdown',
									footerLink: '/dataestate/service-request'
								}}
								handleEvent={() => handleSetComponent('OUTAGES', {
									sector: [],
									selectedDate: "2023-01",
								})}
							/>
						</Grid>
						<Grid item xs={12} display="flex">
							<NumberWidget
								title="Unplanned Downtime (YTD) - (as of Today)"
								tooltipLabel="Year-to-date total D&AI unplanned downtime"
								number={
									OutageDuration !== null ? [OutageDuration.Days, OutageDuration.Hours, OutageDuration.Minutes] : ''
								}
								isLoading={!OutageDuration || executiveLoading}
								units={OutageDuration !== null ? ['days', 'hours', 'minutes'] : null}
								// handleEvent={handleNavigateService}
								footer={{
									footerLabel: 'Click to look at processing breakdown',
									footerLink: '/dataestate/service-request'
								}}
								handleEvent={() => handleSetComponent('UNPLANNED_DOWNTIME', {
									sector: [],
									selectedDate: "2023-01",
								})}
							/>
						</Grid>
					</WidgetCardContainer>
				</Grid>
				<Grid container item xs={12} lg={6}>
					<WidgetCardContainer title="Cost">
						<Grid item xs={12} display="flex">
							<NumberWidget
								isCost
								title="Annual Spend on Storage & Compute across D&AI Platform - (as of July 2024)"
								tooltipLabel="Total D&AI platform cost"
								number={overviewMetrics?.annualCostAcrossDA ? formatNumberWithCommas(overviewMetrics.annualCostAcrossDA.toFixed(2), true) : 0}
								units="MM"
								isLoading={!overviewMetrics}
								handleEvent={!isEnvTypePROD ? handleNavigateCost : undefined}
								// isInProgress
							/>
						</Grid>
						<Grid item xs={12} md={6} display="flex">
							<NumberWidget
								isCost
								title="YTD Reserved Instance Savings - (as of July 2024)"
								tooltipLabel="Year-to-date savings for using reserved instances"
								number={
									overviewMetrics?.reservedInstanceSavingsYTD
										? formatNumberWithCommas(overviewMetrics.reservedInstanceSavingsYTD.toFixed(2),true)
										: 0
								}
								units="MM"
								isLoading={!overviewMetrics}
								handleEvent={!isEnvTypePROD ? handleNavigateCost : undefined}
							/>
						</Grid>
						<Grid item xs={12} md={6} display="flex">
							<NumberWidget
								title="Average monthly cost growth (Jun - Jul 2024)"
								tooltipLabel="Month over month platform cost growth"
								units="MM"
								number={-14.27}
								unitIcon={ArrowIconRed}
								color={
										-14.27 <= 0
											? 'error.main'
											: 'success.main'
								}
								//Commented as per request by pepsico
								// unitIcon={
								// 	azureMonthlyCostDetails !== null && metricDetailsOverSize !== 0
								// 		? azureMonthlyCostDetails.avgMonthlyCost >= 0
								// 			? ArrowIcon
								// 			: ArrowIconRed
								// 		: null
								// }
								// number={azureMonthlyCostDetails !== null ? azureMonthlyCostDetails.avgMonthlyCost.toFixed(2) : 0}
								// color={
								// 	azureMonthlyCostDetails !== null ? azureMonthlyCostDetails.avgMonthlyCost <= 0
								// 			? 'error.main'
								// 			: 'success.main'
								// 		: 'text.main'
								// }
								// isLoading={!azureMonthlyCostDetails || executiveLoading}
								// isInProgress
								isLoading={false}
								handleEvent={!isEnvTypePROD ? handleNavigateCost : undefined}
								isCost={true}
							/>
						</Grid>
					</WidgetCardContainer>
				</Grid>
			</Grid>
		</Box>
	);
};

export default OverallSummary;
