import { useState, useEffect, useRef } from 'react';
import { Typography, Table, TableBody, TableRow, TableCell, Paper } from '@mui/material';
import moment from 'moment';

const CommonDetails = ({ sharedValue }: any) => {
	const dataFetchedRef = useRef(false);

	const [resourceGroupDetails, setResourceGroupDetails] = useState(sharedValue);

	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		setResourceGroupDetails(sharedValue);
	}, [sharedValue]);
	return (
		<Paper elevation={8} className="w-60">
			<Typography variant="h6" component="h6">
				Resource Group Details
			</Typography>
			<Table className="resourceGroupDetails-overview-table">
				<TableBody>
					<TableRow>
						<TableCell className="attrTitle">Resource Group</TableCell>
						<TableCell className="attrValue">{resourceGroupDetails?.resource_group}</TableCell>
						<TableCell className="attrTitle">Sector</TableCell>
						<TableCell className="attrValue">{resourceGroupDetails?.sector}</TableCell>
						<TableCell className="attrTitle">Environment</TableCell>
						<TableCell className="attrValue">{resourceGroupDetails?.environment}</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</Paper>
	);
};

export default CommonDetails;
