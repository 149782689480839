import React, { useEffect, useRef, useState } from 'react';
import {
	Button,
	Chip,
	Grid,
	Menu,
	Stack,
	TextField,
	List,
	ListItem,
	ListItemText,
	FormControlLabel,
	Select,
	InputLabel,
	FormControl,
	Tooltip
} from '@mui/material';
import { dashboardUrl, isEnvTypePROD } from '../../service/config';
import AnalyticsVisualization from '../framework/analyticsVisualization';
import '../TableauObs/Summary/Summary.scss';
// import Workbooks from './Workbooks';
// import TableauUsers from '../../TableauObs/Summary/Users';
import moment from 'moment';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as DatePickerIcon } from '../../assets/calendar-outline_white.svg';
import { ReactComponent as FilterIcon } from '../../assets/ml_summary_filter.svg';
import { ReactComponent as ServerIcon } from '../../assets/solar_server-outline.svg';
import { biApplicationSummary, fetchCrossEnvData, getDecodedToken, getSector } from '../../service/service';
import useStyles from '../jobs/jobs_styles';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import '../DataEstate/data-estate.scss';
import { fetchBIApplicationSummary, fetchTrends } from '../../service/service';
import ReactECharts from 'echarts-for-react';
import { ReactComponent as CalendarIcon } from '../../assets/calendar-outline_white.svg';

import ArrowUpBlackIcon from '../../assets/up-arrow-black.svg';
import {getPercentageOfTwoNumbers, formatNumberWithCommas} from '../../utils/common-util';
import { ReactComponent as Downarrow } from '../../assets/down-arrow.svg';
import { CustomizedDataGrid } from '../customized_data_grid';
import Loader from '../Loader/loader';
import { Column } from 'react-data-grid';
import NoPrgramFound from '../../assets/no-program-found.svg';
import ToolTipIcon from "../../assets/info-icon.svg";

const BIApplicationSummary = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const hasDrillDown = searchParams.has('isDrillDowned');
	const dateFrom: string = searchParams.get('fromDate')?.substring(0, 10) as string;
	const startDt = moment(dateFrom).startOf('day').subtract(1, 'hours').format('YYYY-MM-DD');
	const [fromDateTime, setFromDateTime] = useState<any>(
		searchParams.has('fromDate') ? startDt : moment().startOf('day').subtract(1, 'hours')
	);
	const [PowerBIReports, SetPowerBIReports] = useState('-');
	const [PowerBIDashboard, SetPowerBIDashboard] = useState('-');
	const [PowerBIDataSets, SetPowerBIDataSets] = useState('-');
	const [PowerBIUsers, SetPowerBIUsers] = useState<number | string>('-');
	const [TableauReports, SetTableauReports] = useState('-');
	const [TableauDatasets, SetTableauDatasets] = useState('-');
	const [TableauCockpitUsers, SetTableauCockpitUsers] = useState('-');
	const [TableauNonCockpitUsers, SetTableauNonCockpitUsers] = useState('-');
	const [TableauLicenses, SetTableauLicenses] = useState('-')

	const [grafanaTableauLoading, setGrafanaTableauLoading] = useState(true);
	const [grafanaTableauOptions, setGrafanaTableauOptions] = useState<any>();
	const [grafanaTableauUserLoading, setGrafanaTableauUserLoading] = useState(true);
	const [grafanaTableauUserOptions, setGrafanaTableauUserOptions] = useState<any>();
	const [grafanaPowerBiLoading, setGrafanaPowerBiLoading] = useState(true);
	const [grafanaPowerBiOptions, setGrafanaPowerBiOptions] = useState<any>();
	const [grafanaPowerBiUserLoading, setGrafanaPowerBiUserLoading] = useState(true);
	const [grafanaPowerBiUserOptions, setGrafanaPowerBiUserOptions] = useState<any>();

	const [isInSuffBiDataset, setIsInSuffBiDataset] = useState(false);
	const [isInSuffBiUser, setIsInSuffBiUser] = useState(false);
	const [isNoAnnualBiDataset, setIsNoAnnualBiDataset] = useState(false);
	const [isNoAnnualBiUser, setIsNoAnnualBiUser] = useState(false);
	const [biDsStartMonth, setBiDsStartMonth] = useState('');
	const [biUserStartMonth, setBiUserStartMonth] = useState('');
	const [biDatasetLastDec, SetPowerBiDatasetLastDec] = useState(0);
	const [biDatasetCurrent, SetPowerBiDatasetCurrent] = useState(0);
	
	const [biUserLastDec, SetPowerBiUserLastYear] = useState(0);
	const [biUserCurrent, SetPowerBiUserCurrent] = useState(0);

	const [PowerBiBubbleValue, SetPowerBiBubbleValue] = useState(0);
	const [PowerBiUserBubbleValue, SetPowerBiUserBubbleValue] = useState(0);
	const [TableauBubbleValue, SetTableauBubbleValue] = useState(0);
	const [TableauUserBubbleValue, SetTableauUserBubbleValue] = useState(0);
	const [tableauUserLastDec, SetTableauUserLastDec] = useState(0);
	const [tableauUserCurrent, SetTableauUserCurrent] = useState(0);
	const [tableauUserStartMonth, setTableauUserStartMonth] = useState('');
	const [isNoAnnualTableauUser, setIsNoAnnualTableauUser] = useState(false);

	const [tableauDatasetLastDec, SetTableauDatasetLastDec] = useState(0);
	const [tableauDatasetCurrent, SetTableauDatasetCurrent] = useState(0);


	const [propsFromDateTime, setPropsFromDateTime] = useState<any>(
		searchParams.has('fromDate') ? startDt : moment().startOf('day').subtract(1, 'hours')
	);

	// sector filter
	const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
	const [filterMenu, setFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	const [dateFilterMenu, setDateFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	
	const classes = useStyles();
	const [itemsChecked, setItemsChecked] = useState(false);
	const [sectorOptionsList, setSectorOptionsList] = useState<any[]>([]);
	const [checkedList, setCheckedList] = useState<any[]>([]);
	const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);
	const [sector, setCurrSector] = useState<any[]>([]);
	const [filterMonth, setFilterMonth] = useState('0M');
	const [filterDefaultMonth, setFilterDefaultMonth] = useState("0M");
	const [customYear, setCustomYear] = useState(moment().format('YYYY'));
	const [isDateOpen, setIsDateOpen] = useState(false);
	const isCustomMonth = useRef(false);

	const dataFetchedRef = useRef(false);

	// table
	const [tableName, setTableName] = useState('');
	const [typeName, setTypeName] = useState('');

	interface BIREPORTS {
		sector: string;
		capacityName: string;
		region: string;
		state: string;
		sku: string;
		workspaceName: string;
		reportName: string;
	}
	interface BIDASHBOARDS {
		sector: string;
		capacityName: string;
		region: string;
		state: string;
		sku: string;
		workspaceName: string;
		dashboardName: string;
	}
	interface BIDATSETS {
		sector: string;
		capacityName: string;
		workspaceName: string;
		reportCount: string;
		dataSourceType: string;
		datasetName: string;
	}
	interface BIUSERS {
		sector: string;
		capacityName: string;
		region: string;
		state: string;
		sku: string;
		workspaceName: string;
		userName: string;
	}
	interface TABLEAUREPORTSUSERS {
		userName: string;
		userType: string;
		gpid: string;
		email: string;
		lastLogin: string;
	}
	interface TABLEAUDATASETS {
		id: number;
		extractType: string;
		extractName: string;
		contentName: string;
		projectName: string;
	}
	interface Row {}
	function getColumns(): Array<Column<Row>> {
		if (typeName === 'BIREPORTS') {
			return [
				{
					key: 'sector',
					name: 'Sector',
					minWidth: 150
				},
				{
					key: 'capacityName',
					name: 'Capacity name',
					minWidth: 170
				},
				{
					key: 'region',
					name: 'Region',
					minWidth: 170
				},
				{
					key: 'state',
					name: 'State',
					minWidth: 170
				},
				{
					key: 'sku',
					name: 'SKU',
					minWidth: 170
				},
				{
					key: 'workspaceName',
					name: 'Workspace name',
					minWidth: 170
				},
				{
					key: 'reportName',
					name: 'Report name',
					minWidth: 150
				}
			];
		} else if (typeName === 'BIDASHBOARDS') {
			return [
				{
					key: 'sector',
					name: 'Sector',
					minWidth: 150
				},
				{
					key: 'capacityName',
					name: 'Capacity name',
					minWidth: 170
				},
				{
					key: 'region',
					name: 'Region',
					minWidth: 170
				},
				{
					key: 'state',
					name: 'State',
					minWidth: 170
				},
				{
					key: 'sku',
					name: 'SKU',
					minWidth: 170
				},
				{
					key: 'workspaceName',
					name: 'Workspace name',
					minWidth: 170
				},
				{
					key: 'dashboardName',
					name: 'Dashboard name',
					minWidth: 150
				}
			];
		} else if (typeName === 'BIDATASETS') {
			return [
				{
					key: 'sector',
					name: 'Sector',
					minWidth: 150
				},
				{
					key: 'capacityName',
					name: 'Capacity name',
					minWidth: 170
				},
				{
					key: 'workspaceName',
					name: 'Workspace name',
					minWidth: 170
				},
				{
					key: 'reportCount',
					name: 'Report count',
					minWidth: 170
				},
				{
					key: 'dataSourceType',
					name: 'Data source type',
					minWidth: 170
				},
				{
					key: 'datasetName',
					name: 'Dataset name',
					minWidth: 170
				}
			];
		} else if (typeName === 'BIUSERS') {
			return [
				{
					key: 'sector',
					name: 'Sector',
					minWidth: 150
				},
				{
					key: 'capacityName',
					name: 'Capacity name',
					minWidth: 170
				},
				{
					key: 'region',
					name: 'Region',
					minWidth: 170
				},
				{
					key: 'state',
					name: 'State',
					minWidth: 170
				},
				{
					key: 'sku',
					name: 'SKU',
					minWidth: 170
				},
				{
					key: 'workspaceName',
					name: 'Workspace name',
					minWidth: 170
				},
				{
					key: 'userName',
					name: 'User name',
					minWidth: 150
				}
			];
		} else if (
			typeName === 'TABLEAUREPORTS' ||
			typeName === 'TABLEAUCOCKPITUSERS' ||
			typeName === 'TABLEAUNONCOCKPITUSERS'
		) {
			return [
				{
					key: 'userName',
					name: 'User name',
					minWidth: 150
				},
				{
					key: 'userType',
					name: 'User type',
					minWidth: 170
				},
				{
					key: 'gpid',
					name: 'GPID',
					minWidth: 170
				},
				{
					key: 'email',
					name: 'Email',
					minWidth: 170
				},
				{
					key: 'lastLogin',
					name: 'Last login',
					minWidth: 170
				}
			];
		} else if (typeName === 'TABLEAUDATASETS') {
			return [
				{
					key: 'id',
					name: 'ID',
					minWidth: 150
				},
				{
					key: 'extractType',
					name: 'Extract type',
					minWidth: 170
				},
				{
					key: 'extractName',
					name: 'Extract name',
					minWidth: 170
				},
				{
					key: 'contentName',
					name: 'Content name',
					minWidth: 170
				},
				{
					key: 'projectName',
					name: 'Project name',
					minWidth: 170
				}
			];
		} else {
			return [];
		}
	}
	const BIReportColumnNames = ['Sector', 'Capacity name', 'Region', 'State', 'SKU', 'Workspace name', 'Report name'];
	const BIDashboardColumnNames = [
		'Sector',
		'Capacity name',
		'Region',
		'State',
		'SKU',
		'Workspace name',
		'Dashboard name'
	];
	const BIDatasetsColumnNames = [
		'Sector',
		'Capacity name',
		'Workspace name',
		'Report count',
		'Data source type',
		'Dataset name'
	];
	const BIUsersColumnNames = ['Sector', 'Capacity name', 'Region', 'State', 'SKU', 'Workspace name', 'User name'];
	const TableauUsersColumnNames = ['User name', 'User type', 'GPID', 'Email', 'Last login'];
	const TableauDatasetsColumnNames = ['ID', 'Extract type', 'Extract name', 'Content name', 'Project name'];
	const [defaultColumnNames, setDynamicValue] = useState(['']);
	const [tableData, setTableData] = useState<Row[]>([]);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [isDetailVisible, setIsDetailVisible] = useState(false);
	const dimension = useRef('');
	const tableRef = useRef<HTMLDivElement>(null);
	const [loading, setLoading] = useState(false);
	const [customMonth, setCustomMonth] = useState('');

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		summaryListingFunc(value, pageSize, sortingInfo);
	};

	const handlepageSizeChange = (event: any) => {
		summaryListingFunc(page, event.target.value, sortingInfo);
	};

	async function summaryListingFunc(pageNumber: any, size: any, sortingInfo: any) {
		setLoading(true);
		let month = customMonth ? customMonth : filterMonth;
		const res = await biApplicationSummary('powerbi', dimension.current, sector, month);
		if (res.success) {
			setLoading(false);
			setTableData(res.data.summary);
			setPaginationData(res.data.page);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
		}
		setTimeout(() => {
			if (tableRef.current) {
				tableRef.current.scrollIntoView({ behavior: 'smooth' });
			}
		}, 1000);
	}

	const handleSorting = (sortingInfo: any) => {
		summaryListingFunc(page, pageSize, sortingInfo);
	};

	const handleMetricsOnclick = (value: any) => {
		setDynamicValue(['']);
		setTableData([]);
		setIsDetailVisible(true);
		setTypeName(value);
		if (value === 'BIREPORTS') {
			setDynamicValue(BIReportColumnNames);
			type Row = keyof BIREPORTS;
			setTableName('PowerBI Reports');
			dimension.current = 'powerbi-reports';
		} else if (value === 'BIDASHBOARDS') {
			setDynamicValue(BIDashboardColumnNames);
			type Row = keyof BIDASHBOARDS;
			setTableName('PowerBI Dashboards');
			dimension.current = 'powerbi-dashboards';
		} else if (value === 'BIDATASETS') {
			setDynamicValue(BIDatasetsColumnNames);
			type Row = keyof BIDATSETS;
			setTableName('PowerBI Datasets');
			dimension.current = 'powerbi-datasets';
		} else if (value === 'BIUSERS') {
			setDynamicValue(BIUsersColumnNames);
			type Row = keyof BIUSERS;
			setTableName('PowerBI Users');
			dimension.current = 'powerbi-users';
		} else if (value === 'TABLEAUREPORTS' || value === 'TABLEAUCOCKPITUSERS' || value === 'TABLEAUNONCOCKPITUSERS') {
			setDynamicValue(TableauUsersColumnNames);
			type Row = keyof TABLEAUREPORTSUSERS;
			if (value === 'TABLEAUREPORTS') {
				setTableName('Tableau Reports');
				dimension.current = 'tableau-reports';
			} else if (value === 'TABLEAUCOCKPITUSERS') {
				setTableName('Tableau Cockpit Users');
				dimension.current = 'tableau-cockpit-users';
			} else {
				setTableName('Tableau Non-Cockpit Users');
				dimension.current = 'tableau-noncockpit-users';
			}
		} else if (value === 'TABLEAUDATASETS') {
			setDynamicValue(TableauDatasetsColumnNames);
			type Row = keyof TABLEAUDATASETS;
			setTableName('Tableau Datasets');
			dimension.current = 'tableau-datasets';
		}
		summaryListingFunc(page, pageSize, sortingInfo);
	};

	const clearMetricsSummary = () => {
		// setDynamicValue(['']);
		setTableData([]);
		setIsDetailVisible(false);
	};

	const handleDateFilter = (field: any, selectedDate: any) => {
		clearMetricsSummary();
		if (field === 'dateFilter') {
			isCustomMonth.current = false;
			setCustomMonth('');
			setFilterMonth(selectedDate.target.value);
			if (selectedDate.target.value === '2023-01') {
				isCustomMonth.current = true;
				setIsDateOpen(true);
				return;
			}
		}
		selectedDate = selectedDate.target.value;
		setFilterDefaultMonth(selectedDate);
		setFilterMonth(selectedDate);
		getBIApplicationSummaryDetails(sector, selectedDate);
	};
	const handleMonthOnclick = (value: any) => {
		isCustomMonth.current = false;
		setIsDateOpen(false);
		const selectedDate = customYear + '-' + value;
		setCustomMonth(selectedDate);
		getBIApplicationSummaryDetails(sector, selectedDate);
		setDateFilterMenu(null);
	};
	const handleYearAdd = () => {
		let selectedYear = Number(customYear);
		selectedYear++;
		setCustomYear(selectedYear.toString());
	};
	const handleYearMinus = () => {
		let selectedYear = Number(customYear);
		selectedYear--;
		setCustomYear(selectedYear.toString());
	};
	const handleCustomOnclick = (e: any) => {
		if (e.target.textContent == 'Custom Month') {
			isCustomMonth.current = true;
			dateFilterMenuClick(e);
		}
		if (isCustomMonth.current) setIsDateOpen(true);
		else setIsDateOpen(false);

		if (e.type == 'click' && e.target.className.includes('MuiModal-backdrop')) {
			isCustomMonth.current = false;
			setDateFilterMenu(null);
			setIsDateOpen(false);
			if (customMonth == '') {
				setFilterMonth(filterDefaultMonth)
			}
		}
	};

	const dateFilterMenuClick = (event: any) => {
		setDateFilterMenu(
			dateFilterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 260,
						mouseY: parseInt(event.clientY) - 50
				  }
				: null
		);
	};
	const handleDateFilterMenuClose = (event: any) => {
		setDateFilterMenu(null);
	};

	const sectorFilterClick = (event: any) => {
		event.preventDefault();
		setFilterMenu(
			filterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 20,
						mouseY: parseInt(event.clientY) - 6
				  }
				: null
		);
	};

	const handleClose = (event: any) => {
		setFilterMenu(null);
	};

	const selectAllCheck = (e: any) => {
		const { checked } = e.target;
		const collection: any[] = [];
		if (checked) setCheckedList(sectorOptionsList);
		else setCheckedList([]);
		setItemsChecked(checked);
	};

	const handleChange = (e: any) => {
		const { value, checked } = e.target;
		const newlyChecked = [];
		if (checked && e.target.value) {
			newlyChecked.push(e.target.value);
		}
		if (checked) {
			setCheckedList([...checkedList, ...newlyChecked]);
			if (sectorOptionsList.length === checkedList.length + newlyChecked.length) {
				setItemsChecked(true);
			}
		} else {
			setCheckedList(checkedList.filter((item) => item !== value));
			setItemsChecked(false);
		}
	};
	const handleOkClick = (event: any) => {
		clearMetricsSummary();
		localStorage.setItem('selectedSector', JSON.stringify(checkedList)); // Tableau drilldown filter changes
		if (checkedList.length > 0) {
			setCurrSectorFilter(checkedList);
		} else {
			setCurrSectorFilter([]);
		}
		getBIApplicationSummaryDetails(checkedList, filterMonth);
		setCurrSector(checkedList);
		handleClose(event);
	};

	async function fetchSectorOptionsData() {
		const sectorRes = await getSector('1', '100', '');
		if (sectorRes.success) {
			const filteredSectorOptions = sectorRes.data.sector.filter((x: any) => x.name !== 'S&T').map((x: any) => x.name);
			filteredSectorOptions.push('Blank');
			setSectorOptionsList(filteredSectorOptions);
		}
	}

	async function getBIApplicationSummaryDetails(sectors: any, month: any) {
		let sector = sectors.length == sectorOptionsList.length ? [] : sectors;
		SetPowerBIReports('-');
		SetPowerBIDashboard('-');
		SetPowerBIDataSets('-');
		SetPowerBIUsers('-');
		SetTableauReports('-');
		SetTableauDatasets('-');
		SetTableauCockpitUsers('-');
		SetTableauNonCockpitUsers('-');
		// setGrafanaTableauLoading(true);
		fetchTrends('datasetgrowth', 'tableau', sector, month)
			.then((res) => {
				let allDataAsArray = Object.entries(res.data);
				const allMonths = Object.keys(res.data);

				const firstMonthFromAllMonths = allMonths[0];
				const lastMonth = moment().subtract(1, 'months').format('MM');
				const indexValue = moment().year() + '-' + lastMonth;
				const lastIndex = allMonths.indexOf(indexValue);
				const lastMonthFromAllMonths = allMonths[lastIndex];
				const lastMonthFromForecast = allMonths[allMonths.length - 1];

				let currentMonth = new Date().getMonth();
				if (currentMonth == 0)
					currentMonth = 12
				const currentYear = new Date().getFullYear();
				const pastMonths = Object.entries(allDataAsArray).filter(
					([date]) => new Date(date).getMonth() + 1 <= currentMonth
				);
				const lastMonthString = (currentMonth).toString().padStart(2, '0');
				const filterMonth = `${currentYear-1}-${lastMonthString}` 
				const filterDateEntry = Object.entries(res.data).filter(
					x => x[0] === filterMonth
				);
				if (filterDateEntry.length) {
					let count: any = 0;
					count = filterDateEntry[0][1];
					const lastMonthDataIndex = `${currentYear}-${lastMonthString}`;
					const lastMonthEntry = Object.entries(res.data).filter(
						x => x[0] === lastMonthDataIndex
					);
					if (lastMonthEntry.length){
						const lastmonthvalue = lastMonthEntry[0][1] as number;
						SetTableauDatasetCurrent(lastmonthvalue as number)
						SetTableauDatasetLastDec(count)
						SetTableauBubbleValue(getPercentageOfTwoNumbers(count, lastmonthvalue));
					}
				}

				const option = {
					title: {
						show: Object.keys(allDataAsArray).length == 0 ? true : false,
						textStyle: {
							color: '#bcbcbc',
							fontWeight: 'normal',
							fontSize: 16
						},
						text: 'No data for the selected filters',
						left: 'center',
						top: 'center'
					},
					backgroundColor: '#053367',
					grid: {
						top: '13%',
						left: '10%',
						right: '2%',
						bottom: '12%'
					},
					xAxis: {
						type: 'category',
						splitLine: {
							show: true,
							lineStyle: {
								type: 'dashed',
								color: '#004477'
							}
						},
						data: allMonths,
						axisLabel: {
							rotate: 30,
							color: '#A7ADCB',
							formatter: function (value: any) {
								return `${moment(value).format('MMM-YY')}`;
							}
						}
					},
					yAxis: {
						type: 'value',
						splitLine: {
							show: true,
							lineStyle: {
								type: 'dashed',
								color: '#004477'
							}
						},
						axisLabel: { color: '#A7ADCB' }
					},
					visualMap: {
						show: false,
						dimension: 0,
						pieces: [
							{
								lte: lastIndex,
								color: '#009BD8'
							},
							{
								gt: lastIndex,
								lte: allMonths.length - 1,
								color: '#FD783B'
							}
						]
					},
					series: [
						{
							name: 'Current Growth',
							type: 'line',
							data: allDataAsArray,
							markArea: {
								itemStyle: {
									color: 'rgba(255, 173, 177, 0.1)'
								},
								data: [
									[
										{
											name: 'Current Growth',
											xAxis: firstMonthFromAllMonths
										},
										{
											xAxis: lastMonthFromAllMonths
										}
									],
									[
										{
											name: 'Forecast',
											xAxis: moment().format('YYYY-MM')
										},
										{
											xAxis: lastMonthFromForecast
										}
									]
								]
							}
						}
					],
					tooltip: {
						trigger: 'axis',
						formatter: (params: any) => {
							const { name, seriesName, value } = params[0];
							return `${moment(value[0]).format('MMM-YY')}: ${parseInt(value[1])}`;
						},
						backgroundColor: '#053367',
						textStyle: {
							color: '#A7ADCB'
						}
					}
				};
				setGrafanaTableauOptions(option);
				setGrafanaTableauLoading(false);
			})
			.catch((error) => {
				console.error(error);
			});
			fetchTrends('tabeauusergrowth', 'tableau', sector, month)
			.then((res) => {
				let allDataAsArray = Object.entries(res.data);
				const allMonths = Object.keys(res.data);

				const firstMonthFromAllMonths = allMonths[0];
				const lastMonth = moment().subtract(1, 'months').format('MM');
				const indexValue = moment().year() + '-' + lastMonth;
				const lastIndex = allMonths.indexOf(indexValue);
				const lastMonthFromAllMonths = allMonths[lastIndex];
				const lastMonthFromForecast = allMonths[allMonths.length - 1];

				let currentMonth = new Date().getMonth();
				if (currentMonth == 0)
					currentMonth = 12
				const currentYear = new Date().getFullYear();
				const pastMonths = Object.entries(allDataAsArray).filter(
					([date]) => new Date(date).getMonth() + 1 <= currentMonth
				);
				const lastMonthString = (currentMonth).toString().padStart(2, '0');
                const filterMonth = `${(currentYear-1)}-${lastMonthString}`
				const filterDateEntry = Object.entries(res.data).filter(
					x => x[0] === filterMonth
				);
				if (filterDateEntry.length) {
					let count: any = 0;
					count = filterDateEntry[0][1];
					const lastMonthDataIndex = `${currentYear}-${lastMonthString}`;
					const lastMonthEntry = Object.entries(res.data).filter(
						x => x[0] === lastMonthDataIndex
					);
					if (lastMonthEntry.length){
						setIsNoAnnualTableauUser(false);	
						const lastmonthvalue = lastMonthEntry[0][1] as number;
						SetTableauUserCurrent(lastmonthvalue as number)
						SetTableauUserLastDec(count)
						SetTableauUserBubbleValue(getPercentageOfTwoNumbers(count, lastmonthvalue));
					}
				}
				else
				{
					const annualMonth = moment(firstMonthFromAllMonths, 'YYYY-MM').format('MMMM YYYY');
					setTableauUserStartMonth('Data available from ' + annualMonth);
					setIsNoAnnualTableauUser(true);	
				}
				// if (pastMonths.length) {
				// 	let count: any = 0;
				// 	count = filterDateEntry[0][1];
				// 	SetTableauUserCurrent(pastMonths[pastMonths.length - 1][1][1] as number)
				// 	SetTableauUserLastDec(count)
				// 	SetTableauLicensBubbleValue(getPercentageOfTwoNumbers(count, pastMonths[pastMonths.length - 1][1][1]));
				// }

				const option = {
					title: {
						show: Object.keys(allDataAsArray).length == 0 ? true : false,
						textStyle: {
							color: '#bcbcbc',
							fontWeight: 'normal',
							fontSize: 16
						},
						text: 'No data for the selected filters',
						left: 'center',
						top: 'center'
					},
					backgroundColor: '#053367',
					grid: {
						top: '13%',
						left: '10%',
						right: '2%',
						bottom: '12%'
					},
					xAxis: {
						type: 'category',
						splitLine: {
							show: true,
							lineStyle: {
								type: 'dashed',
								color: '#004477'
							}
						},
						data: allMonths,
						axisLabel: {
							rotate: 30,
							color: '#A7ADCB',
							formatter: function (value: any) {
								return `${moment(value).format('MMM-YY')}`;
							}
						}
					},
					yAxis: {
						type: 'value',
						splitLine: {
							show: true,
							lineStyle: {
								type: 'dashed',
								color: '#004477'
							}
						},
						axisLabel: { color: '#A7ADCB' }
					},
					visualMap: {
						show: false,
						dimension: 0,
						pieces: [
							{
								lte: lastIndex,
								color: '#009BD8'
							},
							{
								gt: lastIndex,
								lte: allMonths.length - 1,
								color: '#FD783B'
							}
						]
					},
					series: [
						{
							name: 'Current Growth',
							type: 'line',
							data: allDataAsArray,
							markArea: {
								itemStyle: {
									color: 'rgba(255, 173, 177, 0.1)'
								},
								data: [
									[
										{
											name: 'Current Growth',
											xAxis: firstMonthFromAllMonths
										},
										{
											xAxis: lastMonthFromAllMonths
										}
									],
									[
										{
											name: 'Forecast',
											xAxis: moment().format('YYYY-MM')
										},
										{
											xAxis: lastMonthFromForecast
										}
									]
								]
							}
						}
					],
					tooltip: {
						trigger: 'axis',
						formatter: (params: any) => {
							const { name, seriesName, value } = params[0];
							return `${moment(value[0]).format('MMM-YY')}: ${formatNumberWithCommas(value[1])}`;
						},
						backgroundColor: '#053367',
						textStyle: {
							color: '#A7ADCB'
						}
					}
				};
				setGrafanaTableauUserOptions(option);
				setGrafanaTableauUserLoading(false);
			})
			.catch((error) => {
				console.error(error);
			});
		// setGrafanaPowerBiLoading(true);
		fetchTrends('datasetgrowth', 'powerbi', sector, month).then((res) => {
			if(res.data) {
				let allDataAsArray = Object.entries(res.data);
				const allMonths = Object.keys(res.data);

				const currentYear = new Date().getFullYear();
				let currentMonth = new Date().getMonth();
				if (currentMonth == 0)
					currentMonth = 12
				// Filter the data to get only entries before the current month
				const filteredData = allDataAsArray.filter(item => {
					const [year, month] = item[0].split('-').map(Number);
					return year < currentYear || (year === currentYear && month < (currentMonth + 1));
				});

				const firstMonthFromAllMonths = allMonths[0]
				const lastMonth = moment().subtract(1, 'months').format('MM');
				const indexValue = moment().year() + '-' + lastMonth;
				const lastIndex = allMonths.indexOf(indexValue);
				const lastMonthFromAllMonths = allMonths[lastIndex];
				const lastMonthFromForecast = allMonths[allMonths.length - 1];

				const isNoData = Object.keys(allDataAsArray).length == 0 ? true : false;
				const IsInSuffData = (Object.keys(allDataAsArray).length >= 0  && filteredData.length < 5) ? true : false;
     			setIsInSuffBiDataset(IsInSuffData);

				const pastMonths = Object.entries(allDataAsArray).filter(
					([date]) => new Date(date).getMonth() + 1 <= currentMonth
				);
				const lastMonthString = (currentMonth).toString().padStart(2, '0');
				const filterMonth = `${currentYear-1}-${lastMonthString}` 
				const filterDateEntry = Object.entries(res.data).filter(
					x => x[0] === filterMonth
					);
				if(filterDateEntry.length) {
					let count: any = 0;
					count = filterDateEntry[0][1];
					const lastMonthDataIndex = `${currentYear}-${lastMonthString}`;
					const lastMonthEntry = Object.entries(res.data).filter(
							x => x[0] === lastMonthDataIndex
					);
					if (lastMonthEntry.length){
						setIsNoAnnualBiDataset(false);	
						const lastmonthvalue = lastMonthEntry[0][1] as number;
						SetPowerBiDatasetCurrent(lastmonthvalue)
						SetPowerBiDatasetLastDec(count)
						SetPowerBiBubbleValue(getPercentageOfTwoNumbers(count, lastmonthvalue));
					}
					// if (pastMonths.length) {
					// 	let count: any = 0;
					// 	count = filterDateEntry[0][1];
					// 	SetPowerBiBubbleValue(getPercentageOfTwoNumbers(count, pastMonths[pastMonths.length - 1][1][1]));
					// }
				}
				else {
					const annualMonth = moment(firstMonthFromAllMonths, 'YYYY-MM').format('MMMM YYYY');
					setBiDsStartMonth('Data available from ' + annualMonth);
					setIsNoAnnualBiDataset(true);	
				}

				const option = {
					title: {
						show: (isNoData || IsInSuffData) ? true : false,
						textStyle: {
							color: '#bcbcbc',
							fontWeight: 'normal',
							fontSize: 16
						},
						text: isNoData ? 'No data for the selected filters' : '',
						left: 'center',
						top: 'center'
					},
					backgroundColor: '#053367',
					grid: {
						top: '13%',
						left: '10%',
						right: '2%',
						bottom: '12%'
					},
					xAxis: {
						type: 'category',
						splitLine: {
							show: true,
							lineStyle: {
								type: 'dashed',
								color: '#004477'
							}
						},
						data: IsInSuffData ? null : allMonths,
						axisLabel: {
							rotate: 30,
							color: '#A7ADCB',
							formatter: function (value: any) {
								return `${moment(value).format('MMM-YY')}`;
							}
						}
					},
					yAxis: {
						type: 'value',
						splitLine: {
							show: true,
							lineStyle: {
								type: 'dashed',
								color: '#004477'
							}
						},
						axisLabel: { color: '#A7ADCB' }
					},
					visualMap: {
						show: false,
						dimension: 0,
						pieces: [
							{
								lte: lastIndex,
								color: '#009BD8'
							},
							{
								gt: lastIndex,
								lte: allMonths.length - 1,
								color: '#FD783B'
							}
						]
					},
					series: [
						{
							name: 'Current Growth',
							type: 'line',
							data: IsInSuffData ? null : allDataAsArray,
							markArea: {
								itemStyle: {
									color: 'rgba(255, 173, 177, 0.1)'
								},
								data: [
									[
										{
											name: firstMonthFromAllMonths ? 'Current Growth' : '',
											xAxis: firstMonthFromAllMonths
										},
										{
											xAxis: lastMonthFromAllMonths
										}
									],
									[
										{
											name: 'Forecast',
											xAxis: moment().format('YYYY-MM')
										},
										{
											xAxis: lastMonthFromForecast
										}
									]
								]
							}
						}
					],
					tooltip: {
						trigger: 'axis',
						formatter: (params: any) => {
							const { name, seriesName, value } = params[0];
							return `${moment(value[0]).format('MMM-YY')}: ${formatNumberWithCommas(value[1])}`;
						},
						backgroundColor: '#053367',
						textStyle: {
							color: '#A7ADCB'
						}
					}
				};
				setGrafanaPowerBiOptions(option);
				setGrafanaPowerBiLoading(false);
			}
		})
		.catch((error) => {
			console.error(error);
		});
		fetchTrends('usergrowth', 'powerbi', sector, month).then((res) => {
			if(res.data) {
				let allDataAsArray = Object.entries(res.data);
				const allMonths = Object.keys(res.data);
     			
				const currentYear = new Date().getFullYear();
				let currentMonth = new Date().getMonth();
				if (currentMonth == 0)
					currentMonth = 12

				// Filter the data to get only entries before the current month
				const filteredData = allDataAsArray.filter(item => {
					const [year, month] = item[0].split('-').map(Number);
					return year < currentYear || (year === currentYear && month < (currentMonth + 1));
				});

				const firstMonthFromAllMonths = allMonths[0]
				const lastMonth = moment().subtract(1, 'months').format('MM');
				const indexValue = moment().year() + '-' + lastMonth;
				const lastIndex = allMonths.indexOf(indexValue);
				const lastMonthFromAllMonths = allMonths[lastIndex];
				const lastMonthFromForecast = allMonths[allMonths.length - 1];

				const isNoData = Object.keys(allDataAsArray).length == 0 ? true : false;
				const IsInSuffData = (Object.keys(allDataAsArray).length >= 0  && filteredData.length < 5) ? true : false;
				setIsInSuffBiUser(IsInSuffData);

				const pastMonths = Object.entries(allDataAsArray).filter(
					([date]) => new Date(date).getMonth() + 1 <= currentMonth
				);
				const lastMonthString = (currentMonth).toString().padStart(2, '0');
				const filterMonth = `${currentYear-1}-${lastMonthString}` 
				const filterDateEntry = Object.entries(res.data).filter(
					x => x[0] === filterMonth
				);
				if(filterDateEntry.length) {
					setIsNoAnnualBiUser(false);	
					let count: any = 0;
					count = filterDateEntry[0][1];
					const lastMonthDataIndex = `${currentYear}-${lastMonthString}`;
					const lastMonthEntry = Object.entries(res.data).filter(
							x => x[0] === lastMonthDataIndex
					);
					if (lastMonthEntry.length){
						const lastmonthvalue = lastMonthEntry[0][1] as number;
						SetPowerBiUserCurrent(lastmonthvalue)
						SetPowerBiUserLastYear(count)
						SetPowerBiUserBubbleValue(getPercentageOfTwoNumbers(count, lastmonthvalue));
					}
				}
				else {
					const annualMonth = moment(firstMonthFromAllMonths, 'YYYY-MM').format('MMMM YYYY');
					setBiUserStartMonth('Data available from ' + annualMonth);
					setIsNoAnnualBiUser(true);	
				}

				const option = {
					title: {
						show: (isNoData || IsInSuffData) ? true : false,
						textStyle: {
							color: '#bcbcbc',
							fontWeight: 'normal',
							fontSize: 16
						},
						text: isNoData ? 'No data for the selected filters' : '',
						left: 'center',
						top: 'center'
					},
					backgroundColor: '#053367',
					grid: {
						top: '13%',
						left: '10%',
						right: '2%',
						bottom: '12%'
					},
					xAxis: {
						type: 'category',
						splitLine: {
							show: true,
							lineStyle: {
								type: 'dashed',
								color: '#004477'
							}
						},
						data: IsInSuffData ? null : allMonths,
						axisLabel: {
							rotate: 30,
							color: '#A7ADCB',
							formatter: function (value: any) {
								return `${moment(value).format('MMM-YY')}`;
							}
						}
					},
					yAxis: {
						type: 'value',
						splitLine: {
							show: true,
							lineStyle: {
								type: 'dashed',
								color: '#004477'
							}
						},
						axisLabel: { color: '#A7ADCB' }
					},
					visualMap: {
						show: false,
						dimension: 0,
						pieces: [
							{
								lte: lastIndex,
								color: '#009BD8'
							},
							{
								gt: lastIndex,
								lte: allMonths.length - 1,
								color: '#FD783B'
							}
						]
					},
					series: [
						{
							name: 'Current Growth',
							type: 'line',
							data: IsInSuffData ? null : allDataAsArray,
							markArea: {
								itemStyle: {
									color: 'rgba(255, 173, 177, 0.1)'
								},
								data: [
									[
										{
											name: firstMonthFromAllMonths ? 'Current Growth' : '',
											xAxis: firstMonthFromAllMonths
										},
										{
											xAxis: lastMonthFromAllMonths
										}
									],
									[
										{
											name: 'Forecast',
											xAxis: moment().format('YYYY-MM')
										},
										{
											xAxis: lastMonthFromForecast
										}
									]
								]
							}
						}
					],
					tooltip: {
						trigger: 'axis',
						formatter: (params: any) => {
							const { name, seriesName, value } = params[0];
							return `${moment(value[0]).format('MMM-YY')}: ${formatNumberWithCommas(value[1])}`;
						},
						backgroundColor: '#053367',
						textStyle: {
							color: '#A7ADCB'
						}
					}
				};
				setGrafanaPowerBiUserOptions(option);
				setGrafanaPowerBiUserLoading(false);
			}
		})
		.catch((error) => {
			console.error(error);
		});
		fetchBIApplicationSummary('powerbi-reports', sector, month)
			.then((res) => {
				let count = res?.data?.result[0]?.PowerBIReportsCount;
				SetPowerBIReports((count / 1000).toFixed(2).toString());
			})
			.catch((error) => {
				console.error(error);
			});
		fetchBIApplicationSummary('powerbi-dashboards', sector, month)
			.then((res) => {
				let count = res?.data?.result[0]?.PowerBIDashboardsCount;
				SetPowerBIDashboard((count / 1000).toFixed(2).toString());
			})
			.catch((error) => {
				console.error(error);
			});
		fetchBIApplicationSummary('powerbi-datasets', sector, month)
			.then((res) => {
				let count = res?.data?.result[0]?.PowerBIDatasetsCount;
				SetPowerBIDataSets((count / 1000).toFixed(2).toString());
			})
			.catch((error) => {
				console.error(error);
			});
		fetchBIApplicationSummary('powerbi-users', sector, month)
			.then((res) => {
				let count = res?.data?.result[0]?.PowerBIUsersCount;
				SetPowerBIUsers((count/1000).toFixed(2).toString());
			})
			.catch((error) => {
				console.error(error);
			});

		// fetchCrossEnvData('tableau_reports', 'Tableau', month)
		// 	.then((res) => {
		// 		let count = res?.data[0]?.KPI_VALUE + res?.data[1]?.KPI_VALUE;
		// 		SetTableauReports((count / 1000).toFixed(2).toString());
		// 	})
		// 	.catch((error) => {
		// 		console.error(error);
		// 	});
		// fetchCrossEnvData('tableau_datasets', 'Tableau', '0M')
		// 	.then((res) => {
		// 		let count = res?.data[0]?.KPI_VALUE + res?.data[1]?.KPI_VALUE;
		// 		SetTableauDatasets((count / 1000).toFixed(2).toString());
		// 	})
		// 	.catch((error) => {
		// 		console.error(error);
		// 	});
		// fetchCrossEnvData('tableau_users', 'Tableau', month)
		// 	.then((res) => {
		// 		debugger;
		// 		let cockpitUsers = res?.data.filter((obj: any) => obj['VENDOR'] === 'cockpit');
		// 		let globalUsers = res?.data.filter((obj: any) => obj['VENDOR'] === 'global');
		// 		SetTableauCockpitUsers((cockpitUsers[0].KPI_VALUE / 1000).toFixed(2).toString());
		// 		SetTableauNonCockpitUsers((globalUsers[0].KPI_VALUE / 1000).toFixed(2).toString());
		// 	})
		// 	.catch((error) => {
		// 		console.error(error);
		// 	});
		fetchBIApplicationSummary('tableau-reports', sector, month).then(res => {
			let count = res?.data?.result[0]?.tableauReports
			SetTableauReports((count/1000).toFixed(2).toString());
		}).catch(error => {
			console.error(error);
		})
		fetchBIApplicationSummary('tableau-datasets', sector, month)
			.then((res) => {
				let count = res?.data?.result[0]?.tableauDatasets;
				SetTableauDatasets((count / 1000).toFixed(2).toString());
			})
			.catch((error) => {
				console.error(error);
			});
		fetchBIApplicationSummary('tableau-cockpit-users', sector, month, moment().format())
			.then((res) => {
				let count = res?.data?.result[0]?.TotalRecords;
				SetTableauCockpitUsers((count / 1000).toFixed(2).toString());
			})
			.catch((error) => {
				console.error(error);
			});
		fetchBIApplicationSummary('tableau-noncockpit-users', sector, month, moment().format())
			.then((res) => {
				let count = res?.data?.result[0]?.TotalRecords;
				SetTableauNonCockpitUsers((count / 1000).toFixed(2).toString());
			})
			.catch((error) => {
				console.error(error);
			});
		fetchBIApplicationSummary('tableau-license', sector, month, moment().format()).then(res => {
			let count = res?.data?.result[0]?.TotalRecords
			SetTableauLicenses((count/1000).toFixed(2).toString());
		}).catch(error => {
			console.error(error);
		})
	}

	useEffect(() => {
		if (hasDrillDown) {
			const selectedSector = localStorage.getItem('selectedSector');
			if (selectedSector) {
				const selectedSec = JSON.parse(selectedSector).length ? JSON.parse(selectedSector) : '';
				setCheckedList(selectedSec);
				setCurrSectorFilter(selectedSec);
			}
		} else {
			localStorage.removeItem('selectedSector');
		}

		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		if (userSector === 'all') fetchSectorOptionsData();
		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/dataestate/biApplicationSummary') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				document.body.classList.add('custom-class');
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('datefilter-align-sector');
				document.body.classList.add('sector-label');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				document.body.classList.add('dashboard-panel-dataestate');
				document.body.classList.add('datefilter-align-sector');
			}
		}
		getBIApplicationSummaryDetails(sector, filterMonth);
	}, []);
	let fromDate = moment(propsFromDateTime).format('YYYY-MM-DD');
	return (
		<div className="dashboard-panel-dataestate job-schedules-wrap">
			{/* <div className="service-date-filter date-picker-summary"> */}
			<div className="service-date-filter date-picker-summary data-estate-right">
				{/* Code for Date filter */}
				<Stack>
					<div className="date-select">
						<FormControl fullWidth>
							<CalendarIcon className="date-icon" />
							<Select
								name="date-select"
								labelId="demo-simple-select-label"
								id="single-date-select"
								value={filterMonth}
								label="Date"
								onChange={(e) => {
									handleDateFilter('dateFilter', e);
								}}
								classes={{ select: classes.select }}
								IconComponent={() => null}
								open={isDateOpen}
								onClick={(e) => handleCustomOnclick(e)}
								onOpen={() => setIsDateOpen(true)}
								onClose={() => setIsDateOpen(false)}
							>
								<MenuItem value={'0M'}>This Month</MenuItem>
								<MenuItem value={'1M'}>Last Month</MenuItem>
								<MenuItem value={'3M'}>Last 3 Months</MenuItem>
								<MenuItem value={'6M'}>Last 6 Months</MenuItem>
								<MenuItem value={'9M'}>Last 9 Months</MenuItem>
								<MenuItem value={'0Y'}>This Year</MenuItem>
								<MenuItem value={'1Y'}>Last Year</MenuItem>
								<MenuItem value={'2Y'}>Last 2 Years</MenuItem>
								<MenuItem value={'2023-01'}>Custom Month</MenuItem>
							</Select>
						</FormControl>
						{isCustomMonth.current && (
							<Popover
							open={dateFilterMenu !== null}
							onClose={handleDateFilterMenuClose}
							className="custom-date-filter-menu"
							anchorReference="anchorPosition"
							anchorPosition={dateFilterMenu !== null ? { top: dateFilterMenu.mouseY, left: dateFilterMenu.mouseX } : undefined}
						>
							<div className="custom-class-date date-custom">
								<div className="custom-dis">
									<InputLabel>{customYear}</InputLabel>
									<div style={{ marginLeft: '60px' }}>
										<Downarrow className="up-arrow" onClick={handleYearAdd} />
										<Downarrow className="year-arrow" onClick={handleYearMinus} />
									</div>
								</div>
								<div className="custom-dis">
									<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick('01')}>
										January
									</Button>
									<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick('02')}>
										February
									</Button>
								</div>
								<div className="custom-dis">
									<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick('03')}>
										March
									</Button>
									<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick('04')}>
										April
									</Button>
								</div>
								<div className="custom-dis">
									<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick('05')}>
										May
									</Button>
									<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick('06')}>
										Jun
									</Button>
								</div>
								<div className="custom-dis">
									<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick('07')}>
										July
									</Button>
									<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick('08')}>
										August
									</Button>
								</div>
								<div className="custom-dis">
									<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick('09')}>
										September
									</Button>
									<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick('10')}>
										October
									</Button>
								</div>
								<div className="custom-dis">
									<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick('11')}>
										November
									</Button>
									<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick('12')}>
										December
									</Button>
								</div>
							</div>
						</Popover>)}
					</div>
				</Stack>
			</div>
			{/* <div className="service-sector-filter-cost service-sector-filter"> */}
				{/* Code for sector filter */}
				{/* <Stack className="sector-filter-dataestate">
					<>
						<span className="sector-cta" onClick={sectorFilterClick}>
							<span className="sector-label">Sectors</span> <FilterIcon />
						</span>
					</>
					<Popover
						open={filterMenu !== null}
						onClose={handleClose}
						className="filterContainer"
						anchorReference="anchorPosition"
						anchorPosition={filterMenu !== null ? { top: filterMenu.mouseY, left: filterMenu.mouseX } : undefined}
					>
						<div className="text-filter-row">
							<div className="selectAll-sec">
								<MenuItem className="selectAll">
									<FormControlLabel
										className={classes.checkbox}
										label="All"
										control={
											<Checkbox
												color="success"
												value="all"
												checked={itemsChecked}
												onChange={(e) => {
													selectAllCheck(e);
												}}
											/>
										}
									/>
								</MenuItem>
								<div className="checkbox-sec">
									{sectorOptionsList &&
										sectorOptionsList.map((filter: any) => (
											<MenuItem key={filter}>
												<FormControlLabel
													className={classes.checkbox}
													label={filter === true ? 'Yes' : filter === false ? 'No' : filter}
													control={
														<Checkbox
															color="success"
															value={filter}
															checked={
																(filter === true && checkedList.includes('true')) ||
																(filter === false && checkedList.includes('false')) ||
																checkedList.includes(filter)
															}
															onChange={(e) => {
																handleChange(e);
															}}
														/>
													}
												/>
											</MenuItem>
										))}
								</div>
							</div>
							<div className="buttonSec">
								<Button variant="contained" className={classes.cancelBtn} onClick={handleClose}>
									cancel
								</Button>
								<Button variant="contained" className={classes.okBtn} onClick={handleOkClick}>
									ok
								</Button>
							</div>
						</div>
					</Popover>
				</Stack>
			</div> */}
			<div className="page-table jobs-tabledata page-table-ibp-monitor">
				<div className="ibp-dashboard-wrapper">
					<Grid container spacing={0} className="headerIPB containerBI">
						<Grid item xs={6} className="headerIPB-title subheaderIPB-title no-ml">
							<Grid container spacing={1}>
								<Grid item xs={4} className="dataestate-title bgcolor-blue cursor" onClick={() =>{ !isEnvTypePROD ? handleMetricsOnclick('BIREPORTS') : '' } }>
									<div className="monitor-head">PowerBI Reports</div>
									<div className="dataestate-subheader">
										{PowerBIReports == '-' ? (
											<span className="loading">Loading...</span>
										) : (
											<>
												{formatNumberWithCommas(PowerBIReports)} <span className="subheader-text">k</span>
											</>
										)}
									</div>
								</Grid>
								<Grid item xs={4} className="dataestate-title bgcolor-blue cursor" onClick={() =>{ !isEnvTypePROD ? handleMetricsOnclick('BIDASHBOARDS') : '' } }>
									<div className="monitor-head">PowerBI Workspaces</div>
									<div className="dataestate-subheader">
										{PowerBIDashboard == '-' ? (
											<span className="loading">Loading...</span>
										) : (
											<>
												{PowerBIDashboard} <span className="subheader-text">k</span>
											</>
										)}
									</div>
								</Grid>
								<Grid item xs={4} className="dataestate-title bgcolor-blue cursor" onClick={() =>{ !isEnvTypePROD ? handleMetricsOnclick('BIDATASETS') : '' } }>
									<div className="monitor-head">PowerBI Datasets</div>
									<div className="dataestate-subheader">
										{PowerBIDataSets == '-' ? (
											<span className="loading">Loading...</span>
										) : (
											<>
												{formatNumberWithCommas(PowerBIDataSets)} <span className="subheader-text">k</span>
											</>
										)}
									</div>
								</Grid>
							</Grid>

							<Grid container spacing={1}>
								<Grid item xs={12} className="dataestate-title bgcolor-blue cursor" onClick={() =>{ !isEnvTypePROD ? handleMetricsOnclick('BIUSERS') : '' } }>
									<div className="monitor-head">PowerBI Users</div>
									<div className="dataestate-subheader">
										{PowerBIUsers == '-' ? (
											<span className="loading">Loading...</span>
										) : (
											<>
												{PowerBIUsers !== undefined && PowerBIUsers !== null && PowerBIUsers !== 0 ? (
													<div className="panel-box--count-summary">
														{PowerBIUsers} <span className="subheader-text">k</span>
													</div>
												) : (
													''
												)}
											</>
										)}
									</div>
								</Grid>
								<Grid item xs={6} className="dataestate-title bgcolor-blue cursor" onClick={() =>{ !isEnvTypePROD ? handleMetricsOnclick('TABLEAUNONCOCKPITUSERS') : '' } }>
									<div className="monitor-head">Tableau Global Users</div>
									<div className="subheader-right">
										{TableauNonCockpitUsers == '-' ? (
											<span className="loading">Loading...</span>
										) : (
											<>
												{formatNumberWithCommas(TableauNonCockpitUsers)} <span className="subheader-text">k</span>
											</>
										)}
									</div>
								</Grid>
							</Grid>
							<Grid container spacing={1}>
								<Grid item xs={12} className="dataestate-title chart-widget bgcolor-blue bi-application-charts bi-application-charts">
									<div className="monitor-head">
										PowerBI Dataset Growth With Projections <i>(Trend)</i>
										{(isInSuffBiDataset || isNoAnnualBiDataset) && <span className="badge bg-no">
											<Tooltip title={(isInSuffBiDataset && isNoAnnualBiDataset) ? 'Insufficient Data' : (isNoAnnualBiDataset ? biDsStartMonth : 'Insufficient Data')} arrow>
												<img src={ToolTipIcon} />
											</Tooltip>
										</span>}
										{(!grafanaPowerBiLoading && (!isInSuffBiDataset || !isNoAnnualBiDataset)) && (
											<span className="badge" title={`Last Year: ${biDatasetLastDec} - Previous Month: ${biDatasetCurrent}`}>
												{Math.sign(PowerBiBubbleValue) == -1 && (
													<>
														<img
															src={ArrowUpBlackIcon}
															className="arrow-icon-styles arrow-icon-styles-up-black down-arrow"
														/>{' '}
														{Math.abs(Math.round(PowerBiBubbleValue))}% /Yr
													</>
												)}
												{Math.sign(PowerBiBubbleValue) != -1 && (
													<>
														<img src={ArrowUpBlackIcon} className="arrow-icon-styles arrow-icon-styles-up-black" />{' '}
														{Math.abs(Math.round(PowerBiBubbleValue))}% /Yr
													</>
												)}
											</span>
										)}
									</div>
									{grafanaPowerBiLoading ? (
										'Loading...'
									) : (
										<ReactECharts
											option={grafanaPowerBiOptions}
											theme={{
												backgroundColor: '#1b1e35'
											}}
										/>
									)}
								</Grid>
							</Grid>
							<Grid container spacing={1}>
								{/* <Grid item xs={12} className="dataestate-title chart-widget bgcolor-blue"> */}
								<Grid item xs={12} className="dataestate-title chart-widget bgcolor-blue mh-398">
									<div className="monitor-head">
										PowerBI User Growth With Projections <i>(MoM)</i>
										{(isInSuffBiUser || isNoAnnualBiUser) && <span className="badge bg-no">
											<Tooltip title={(isInSuffBiUser && isNoAnnualBiUser) ? 'Insufficient Data' : (isNoAnnualBiUser ? biUserStartMonth : 'Insufficient Data')} arrow>
												<img src={ToolTipIcon} />
											</Tooltip>
										</span>}
										{(!grafanaPowerBiUserLoading && (!isInSuffBiUser || !isNoAnnualBiUser)) && (
											<span className="badge" title={`Last Year: ${biUserLastDec} - Previous Month: ${biUserCurrent}`}>
												{Math.sign(PowerBiUserBubbleValue) == -1 && (
													<>
														<img
															src={ArrowUpBlackIcon}
															className="arrow-icon-styles arrow-icon-styles-up-black down-arrow"
														/>{' '}
														{Math.abs(Math.round(PowerBiUserBubbleValue))}% /Yr
													</>
												)}
												{Math.sign(PowerBiUserBubbleValue) != -1 && (
													<>
														<img src={ArrowUpBlackIcon} className="arrow-icon-styles arrow-icon-styles-up-black" />{' '}
														{Math.abs(Math.round(PowerBiUserBubbleValue))}% /Yr
													</>
												)}
											</span>
										)}
									</div>
									{grafanaPowerBiUserLoading ? (
										'Loading...'
									) : (
										<ReactECharts
											option={grafanaPowerBiUserOptions}
											theme={{
												backgroundColor: '#1b1e35'
											}}
										/>
									)}
								</Grid>
							</Grid>
							{/* <Grid container spacing={1}>
								<Grid item xs={12} className="dataestate-title chart-widget bgcolor-blue">
									<div className="monitor-head">PowerBI User Growth With Projections <i>(MOM)</i> 
									{!grafanaPowerBiLoading && <span className="badge">
										{Math.sign(PowerBiBubbleValue) == -1 && <><img src={ArrowUpBlackIcon} className="arrow-icon-styles arrow-icon-styles-up-black down-arrow" /> {Math.abs(Math.round(PowerBiBubbleValue))}% /Yr</> }
										{Math.sign(PowerBiBubbleValue) != -1 && <><img src={ArrowUpBlackIcon} className="arrow-icon-styles arrow-icon-styles-up-black" /> {Math.abs(Math.round(PowerBiBubbleValue))}% /Yr</> }
									</span>
									}</div>
									{
									grafanaPowerBiLoading ? 'Loading...' : <ReactECharts
										option={grafanaPowerBiOptions}
										theme={{
											backgroundColor: '#1b1e35'
										}}
									/>}
								</Grid>
							</Grid> */}
						</Grid>
						{/* right side */}

						<Grid item xs={6} className="headerIPB-title subheaderIPB-title no-ml">
							{/* <Grid container spacing={1}> */}
							<Grid container spacing={1} className='mh-138'>
								<Grid item xs={6} className="dataestate-title bgcolor-blue cursor" onClick={() =>{ !isEnvTypePROD ? handleMetricsOnclick('TABLEAUREPORTS') : '' }}>
									<div className="monitor-head">Tableau Reports</div>
									<div className="subheader-right">
										{TableauReports == '-' ? (
											<span className="loading">Loading...</span>
										) : (
											<>
												{formatNumberWithCommas(TableauReports)} <span className="subheader-text">k</span>
											</>
										)}
									</div>
								</Grid>
								<Grid item xs={6} className="dataestate-title bgcolor-blue cursor" onClick={() =>{ !isEnvTypePROD ? handleMetricsOnclick('TABLEAUDATASETS') : '' }}>
									<div className="monitor-head">Tableau Datasets</div>
									<div className="subheader-right">
										{TableauDatasets == '-' ? (
											<span className="loading">Loading...</span>
										) : (
											<>
												{formatNumberWithCommas(TableauDatasets)} <span className="subheader-text">k</span>
											</>
										)}
									</div>
								</Grid>
							</Grid>

							<Grid container spacing={1}>
								<Grid item xs={6} className="dataestate-title bgcolor-blue cursor" onClick={() =>{ !isEnvTypePROD ? handleMetricsOnclick('TABLEAUCOCKPITUSERS') : '' }}>
									<div className="monitor-head">Tableau Cockpit Users</div>
									<div className="subheader-right">
										{TableauCockpitUsers == '-' ? (
											<span className="loading">Loading...</span>
										) : (
											<>
												{formatNumberWithCommas(TableauCockpitUsers)} <span className="subheader-text">k</span>
											</>
										)}
									</div>
								</Grid>
								<Grid item xs={6} className="dataestate-title bgcolor-blue">
									<div className="monitor-head">Total Tableau Licenses</div>
									{/* <div className="subheader-right">{TableauLicenses == '-' ? <span className="loading">Loading...</span> : <>{TableauLicenses} <span className="subheader-text">k</span></>}</div> */}
									<div className="subheader-right">
										{TableauLicenses == '-' ? <span className="loading">Loading...</span> : <>{formatNumberWithCommas(TableauLicenses)} <span className="subheader-text">k</span></>}
									</div>
								</Grid>
							</Grid>
							<Grid container spacing={1}>
								{/* <Grid item xs={12} className="dataestate-title chart-widget bgcolor-blue bi-application-charts"> */}
								<Grid item xs={12} className="dataestate-title chart-widget bgcolor-blue bi-application-charts mh-398">
									<div className="monitor-head">
										Tableau Dataset Growth With Projections <i>(Trend)</i>
										{!grafanaTableauLoading && (
											<span className="badge" title={`Last Year: ${tableauDatasetLastDec} - Previous Month: ${tableauDatasetCurrent}`}>
												{Math.sign(TableauBubbleValue) == -1 && (
													<>
														<img
															src={ArrowUpBlackIcon}
															className="arrow-icon-styles arrow-icon-styles-up-black down-arrow"
														/>{' '}
														{Math.abs(Math.round(TableauBubbleValue))}% /Yr
													</>
												)}
												{Math.sign(TableauBubbleValue) != -1 && (
													<>
														<img src={ArrowUpBlackIcon} className="arrow-icon-styles arrow-icon-styles-up-black" />{' '}
														{Math.abs(Math.round(TableauBubbleValue))}% /Yr
													</>
												)}
											</span>
										)}
									</div>
									{grafanaTableauLoading ? (
										'Loading...'
									) : (
										<ReactECharts
											option={grafanaTableauOptions}
											theme={{
												backgroundColor: '#1b1e35'
											}}
										/>
									)}
								</Grid>
							</Grid>
							<Grid container spacing={1}>
								<Grid item xs={12} className="dataestate-title chart-widget bgcolor-blue bi-application-charts">
									<div className="monitor-head">
										Tableau User Growth With Projections <i>(MoM)</i>
										{(isNoAnnualTableauUser) && <span className="badge bg-no">
											<Tooltip title={isNoAnnualTableauUser ? tableauUserStartMonth : 'Insufficient Data'} arrow>
												<img src={ToolTipIcon} />
											</Tooltip>
										</span>}
							
										{!grafanaTableauUserLoading && !isNoAnnualTableauUser && (
											<span className="badge" title={`Last Year: ${tableauUserLastDec} - Previous Month: ${tableauUserCurrent}`}>
												{Math.sign(TableauUserBubbleValue) == -1 && (
													<>
														<img
															src={ArrowUpBlackIcon}
															className="arrow-icon-styles arrow-icon-styles-up-black down-arrow"
														/>{' '}
														{Math.abs(Math.round(TableauUserBubbleValue))}% /Yr
													</>
												)}
												{Math.sign(TableauUserBubbleValue) != -1 && (
													<>
														<img src={ArrowUpBlackIcon} className="arrow-icon-styles arrow-icon-styles-up-black" />{' '}
														{Math.abs(Math.round(TableauUserBubbleValue))}% /Yr
													</>
												)}
											</span>
										)}
									</div>
									{grafanaTableauUserLoading ? (
										'Loading...'
									) : (
										<ReactECharts
											option={grafanaTableauUserOptions}
											theme={{
												backgroundColor: '#1b1e35'
											}}
										/>
									)}
								</Grid>
							</Grid>
							{/* <Grid container spacing={1}>
								<Grid item xs={12} className="dataestate-title chart-widget bgcolor-blue">
									<div className="monitor-head">Tableau Users Growth With Projections <i>(MOM)</i> 
									{!grafanaTableauLoading && <span className="badge">
										{Math.sign(TableauBubbleValue) == -1 && <><img src={ArrowUpBlackIcon} className="arrow-icon-styles arrow-icon-styles-up-black down-arrow" /> {Math.abs(Math.round(TableauBubbleValue))}% /Yr</> }
										{Math.sign(TableauBubbleValue) != -1 && <><img src={ArrowUpBlackIcon} className="arrow-icon-styles arrow-icon-styles-up-black" /> {Math.abs(Math.round(TableauBubbleValue))}% /Yr</> }
									</span>
									}</div>
									{
									grafanaTableauLoading ? 'Loading...' : <ReactECharts
										option={grafanaTableauOptions}
										theme={{
											backgroundColor: '#1b1e35'
										}}
									/>}
								</Grid>
							</Grid> */}
						</Grid>
					</Grid>
					{/* detail part */}
					{isDetailVisible && (
						<>
							<h2 className="panel-legend--title mt-35 ">{tableName}</h2>
							<div className=" dataestate_tabledata" ref={tableRef}>
								<Grid>
									{!loading ? (
										<CustomizedDataGrid
											tableColumns={getColumns()}
											tableData={tableData}
											tableType="sector"
											defaultColumnNames={defaultColumnNames}
											onSorting={handleSorting}
											currSorting={sortingInfo}
											page={page}
											pageSize={pageSize}
											paginationData={paginationData}
											listLength={tableData.length}
											handlepageSizeChange={handlepageSizeChange}
											handlePageChange={handlePageChange}
											isDateRangeFilter={false}
											isDisabled={false}
										/>
									) : (
										<Loader />
									)}
								</Grid>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default BIApplicationSummary;
