import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CloseIcon from '../../assets/close-panel.svg';
import Emailicon from '../../assets/email-panel.svg';
import envIcon from '../../assets/env-panel.svg';
import { ReactComponent as TableauIcon } from '../../assets/tableau-filled.svg';
import { ReactComponent as TeradataIcon } from '../../assets/teradata.svg';
import { ReactComponent as DollarcircleIcon } from '../../assets/dollar-circle.svg';
import { ReactComponent as ChartIcon } from '../../assets/chart.svg';
import { ReactComponent as DatabricksIcon } from '../../assets/databricks.svg';
import { ReactComponent as PrestoIcon } from '../../assets/simple-icons_presto.svg';
import { ReactComponent as PowerBIIcon } from '../../assets/simple-icon_powerbi.svg';
import { ReactComponent as VerificationIcon } from '../../assets/simple-icons_vv.svg';
import { ReactComponent as ControlsIcon } from '../../assets/controls-icons.svg';
import sideArrow from '../../assets/side-arrow.svg';
import downArrow from '../../assets/down-arrow.svg';
import quickLink from '../../assets/quill_link-out.svg';
import moment from 'moment-timezone';
import './RulesEngine.css';
import { ReactComponent as AlteryxIcon } from '../../assets/Alteryx.svg';
import ActiveAlteryxIcon from '../../assets/Alteryx_Selected.svg';
import { ReactComponent as DenodoIcon } from '../../assets/Denodo.svg';
import { ReactComponent as ActiveDenodoIcon } from '../../assets/Denodo_Selected.svg';
import { ReactComponent as  TrifactaIcon} from '../../assets/Trifacta.svg';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


interface Props {
	close: () => void;
	ruledata: any;
}

const RulesEngine: React.FC<Props> = ({ close, ruledata }) => {
	const [filteredData, setFilteredData] = useState<any>([]);
	const [originalData, setOriginalData] = useState<any>(ruledata);

	const [expandedRule, setExpandedRule] = useState<any>(null);
	const [selectedRule, setSelectedRule] = useState<any>('Teradata Jobs Observability');
	const [selectedRuleItem, setSelectedRuleItem] = useState<any>('Teradata');
	const [activeButton, setActiveButton] = useState('Teradata');
	const [isRuleHasCount, setIsRuleHasCount] = useState<string[]>([]);

	useEffect(() => {
		setOriginalData(ruledata);
	}, [ruledata]);

	useEffect(() => {
		originalData[0]?.observations.map((item: any) => {
			if (!isRuleHasCount.includes(item.capability) && item.totalObservation) {
				setIsRuleHasCount((prevArray) => [...prevArray, item.capability]);
			}
		});
		setFilteredData(originalData);
	}, [originalData]);

	useEffect(() => {
		if (originalData && originalData.length > 0 && selectedRuleItem) {
			const filtered = originalData[0].observations.filter((item: any) => {
				if (item.capability === selectedRuleItem && item.totalObservation) {
					return item;
				}
			});
			setFilteredData(filtered); // Filter initial data with 'tableau' capability
		}
	}, [originalData]);

	const handleToggle = (index: number) => {
		setExpandedRule(expandedRule === index ? null : index);
	};

	const handleChange = (event: any, newValue: any) => {
		setActiveButton(newValue);
	};

	const filterByCapability = (capability: string, isVVTabHighlight?: boolean) => {
		if (capability === 'Tableau') {
			setSelectedRule('Tableau Observability');
		} else if (capability === 'DataBricks') {
			setSelectedRule('Databricks Observability');
		} else if (capability === 'Teradata') {
			setSelectedRule('Teradata Jobs Observability');
		} else if (capability === 'Platformcost') {
			setSelectedRule('Platform Cost Observability');
		} else if (capability === 'Datagrowth') {
			setSelectedRule('Data Growth Observability');
		} else if (capability === 'Presto') {
			setSelectedRule('Presto Observability');
		} else if (capability === 'PowerBI') {
			setSelectedRule('Power BI Observability');
		} else if (capability === 'VV - PowerBI' || capability === 'VV - Tableau') {
			setSelectedRule('Verification & Validation Observability');
		} else if (capability === 'Denodo' || capability === 'Trifacta' || capability === 'Alteryx') {
			setSelectedRule('Controls & Compliance Observability');
		}
		setSelectedRuleItem(capability);
		setActiveButton(capability);

		// const filtered = originalData[0].observations.filter((item: any) => {
		// 	console.log(item.capability, 'capability test');

		// 	if (item.totalObservation && item.capability === capability) {
		// 		return item;
		// 	}
		// });

		let filtered: any[] = [];
		filtered = originalData[0].observations.filter((item: any) => {
			return item.totalObservation && item.capability === capability;
		});

		// Set the active button

		setFilteredData(filtered);
	};

	function dataConvertFunc(lastRefreshedAt: any) {
		const lastRefresh: any = new Date(lastRefreshedAt);
		const today: any = new Date();
		const diffDate = lastRefresh - today;
		const diffCount = diffDate / (1000 * 60 * 60 * 24);
		const staleday = Math.abs(diffCount).toFixed(0);
		return staleday;
	}

	// powerbi alert
	const getObservationTitle = (rule: string): string => {
		switch (rule) {
			case 'CPU Threshold Breach':
				return 'Utilization %';
			case 'Orphaned Users':
				return 'Orphaned\nUser Count';
			case 'Orphaned Datasets':
				return 'Orphaned\nDataset Count';
			case 'Capacity Threshold Breach':
				return 'Count';
			case 'PBI Gateway Version':
				return 'Count';
			case 'Datasets Refresh Failure':
			case 'Total Dataset Refresh Failure':
			case 'Inactive Users':
			case 'Unlicensed Users':
			case 'Users Never Logged In':
			case 'Patch Upgrade has Valid Approval and CR':
			// case 'Patch Upgrade Has Valid Approval & CR':
			case 'Access Not Approved By Process Owner':
			case 'Access Approved Prior To Granting Of Access':
			// case 'Is SSO Enabled?':
			case 'Access Granted Without Proper Request':
			// case 'Patch Monitoring Check':
			case 'Dormant Users Check':
			case 'Details Of CR Raised Or Not For Key System Config Changes':
			case 'Access Grantor Different From Approver':
			case 'Privileged Access Was Not Reviewed And Approved':
			case 'Reviewer Authorization Check':
			case 'Key System Configuration Check Approval':
			case 'Inappropriate Login Activities Risk Assessment':
			case 'Terminated Users Active Access Check':
			// case 'Key System Configuration Check':
				return 'Count';
			default:
				return '';
		}
	};
	// end of powerbi alert
	function MillisecondsToMinutes(count: any) {
		// Convert milliseconds to hours and minutes
		const hours = Math.floor(count / (1000 * 60 * 60));
		const minutes = Math.round((count % (1000 * 60 * 60)) / (1000 * 60));
		// Format the result
		const formattedResult = `${hours}H ${minutes}M`;
		return formattedResult;
	}
	function CostRound(cost: any) {
		const cost1 = parseFloat(cost);
		const roundedCost = parseFloat(cost1.toFixed(2));
		return roundedCost;
	}

	return (
		<div className="panel">
			<div className="container">
				<h2 className="panel-title">Alerts</h2>
				<div className="icons">
					<img src={envIcon} alt="Icon 3" />
					<img src={Emailicon} alt="Icon 2" />
					<img className="icon-close" title="Close" src={CloseIcon} alt="Icon 1" onClick={close} />
				</div>
			</div>
			<div className="icon-container">
				<div className="icons icons-list">
					<div>
						<span className={'icon-group ' + (activeButton === 'Teradata' ? 'active' : '')}>
							<TeradataIcon
								className="rule-engine-icon"
								onClick={() => filterByCapability('Teradata')}
								fill="#333"
								stroke="#333"
							></TeradataIcon>
							{isRuleHasCount.includes('Teradata') && <span className="inner-circle"></span>}
						</span>
					</div>
					<div>
						<span className={'icon-group ' + (activeButton === 'Platformcost' ? 'active' : '')}>
							<DollarcircleIcon
								className="rule-engine-icon"
								onClick={() => filterByCapability('Platformcost')}
							></DollarcircleIcon>
							{isRuleHasCount.includes('Platformcost') && <span className="inner-circle"></span>}
						</span>
					</div>
					<div>
						<span className={'icon-group ' + (activeButton === 'Datagrowth' ? 'active' : '')}>
							<ChartIcon className="rule-engine-icon" onClick={() => filterByCapability('Datagrowth')}></ChartIcon>
							{isRuleHasCount.includes('Datagrowth') && <span className="inner-circle"></span>}
						</span>
					</div>
					<div>
						<span className={'icon-group ' + (activeButton === 'Tableau' ? 'active' : '')}>
							<TableauIcon className="rule-engine-icon" onClick={() => filterByCapability('Tableau')}></TableauIcon>
							{isRuleHasCount.includes('Tableau') && <span className="inner-circle"></span>}
						</span>
					</div>

					<div>
						<span className={'icon-group ' + (activeButton === 'DataBricks' ? 'active' : '')}>
							<DatabricksIcon
								className="rule-engine-icon"
								onClick={() => filterByCapability('DataBricks')}
							></DatabricksIcon>
							{isRuleHasCount.includes('DataBricks') && <span className="inner-circle"></span>}
						</span>
					</div>

					<div>
						<span className={'icon-group ' + (activeButton === 'Presto' ? 'active' : '')}>
							<PrestoIcon className="rule-engine-icon" onClick={() => filterByCapability('Presto')}></PrestoIcon>
							{isRuleHasCount.includes('Presto') && <span className="inner-circle"></span>}
						</span>
					</div>
					<div>
						<span className={'icon-group ' + (activeButton === 'PowerBI' ? 'active' : '')}>
							<PowerBIIcon className="rule-engine-icon" onClick={() => filterByCapability('PowerBI')}></PowerBIIcon>
							{isRuleHasCount.includes('PowerBI') && <span className="inner-circle"></span>}
						</span>
					</div>
					<div>
						<span className={'icon-group ' + (activeButton === 'VV - Tableau' || activeButton === 'VV - PowerBI'  ? 'active' : '')}>
							<VerificationIcon
								className="rule-engine-icon"
								onClick={() => filterByCapability('VV - Tableau')}
							></VerificationIcon>
							{isRuleHasCount.includes('VV - Tableau') && <span className="inner-circle"></span>}
						</span>
					</div>
					<div>
						<span className={'icon-group ' + (activeButton === 'Denodo' ||activeButton === 'Alteryx' ||activeButton === 'Trifacta' ? 'active' : '')}>
							<ControlsIcon className="rule-engine-icon" onClick={() => filterByCapability('Denodo')}></ControlsIcon>
							{isRuleHasCount.includes('Denodo') && <span className="inner-circle"></span>}
						</span>
					</div>
				</div>
			</div>
			<div className="selectedRule">{selectedRule}</div>

			{selectedRule === 'Verification & Validation Observability' && (
				<div className="controls_tools">
					{/* VV - Tableau Button */}
					<span className={'icon-group1 ' + (activeButton === 'VV - Tableau' ? 'active' : '')}>
						<TableauIcon
							className="rule-engine-icon VV-iconStyle"
							onClick={() => filterByCapability('VV - Tableau')}
						></TableauIcon>
						<span className="tool_title" onClick={() => filterByCapability('VV - Tableau')}>
							TABLEAU
							{isRuleHasCount.includes('VV - Tableau') && <span className="inner-circle"></span>}
						</span>
					</span>
					{/* PowerBIIcon Button */}

					<span className={'icon-group1 ' + (activeButton === 'VV - PowerBI' ? 'active' : '')}>
						<PowerBIIcon
							className="rule-engine-icon VV-iconStyle"
							onClick={() => filterByCapability('VV - PowerBI')}
						></PowerBIIcon>
						<span className="tool_title" onClick={() => filterByCapability('VV - PowerBI')}>
							POWER BI
							{isRuleHasCount.includes('VV - PowerBI') && <span className="inner-circle"></span>}
						</span>
					</span>
				</div>
			)}
			{selectedRule === 'Controls & Compliance Observability' && (
				<div className="controls_tools">
					{/* Denodo Button */}
					<span className={'icon-group1 ' + (activeButton === 'Denodo' ? 'active' : '')}>
						{activeButton === 'Denodo' ? (
							<ActiveDenodoIcon className="rule-engine-icon" onClick={() => filterByCapability('Denodo')} />
						) : (
							<DenodoIcon className="rule-engine-icon" onClick={() => filterByCapability('Denodo')} />
						)}
						<span className="tool_title" onClick={() => filterByCapability('Denodo')}>
						DENODO
							{isRuleHasCount.includes('Denodo') && <span className="inner-circle"></span>}
						</span>
					</span>

					{/* Alteryx Button */}
					<span className={'icon-group1 ' + (activeButton === 'Alteryx' ? 'active' : '')}>
						<AlteryxIcon className="rule-engine-icon" onClick={() => filterByCapability('Alteryx')}></AlteryxIcon>
						<span className="tool_title" onClick={() => filterByCapability('Alteryx')}>
							ALTERYX
							{isRuleHasCount.includes('Alteryx') && <span className="inner-circle"></span>}
						</span>
					</span>

					{/* Trifacta Button */}
					<span className={'icon-group1 ' + (activeButton === 'Trifacta' ? 'active' : '')}>
						<TrifactaIcon className="rule-engine-icon" onClick={() => filterByCapability('Trifacta')}></TrifactaIcon>
						<span className="tool_title" onClick={() => filterByCapability('Trifacta')}>
						TRIFACTA
							{isRuleHasCount.includes('Trifacta') && <span className="inner-circle"></span>}
						</span>
					</span>
				</div>
			)}

			{filteredData?.map((innerArray: any, index: number) => (
				<div key={index} style={{ marginBottom: '5px' }} className="data-container">
					<div className="rule-title-banner" onClick={() => handleToggle(index)}>
						<span className="rule-title">
							{expandedRule === index ? <img alt="down" src={downArrow} /> : <img src={sideArrow} alt="side" />}
							<span>{innerArray.rule === 'Inactive Users' ? 'Not logged in last 90 days' : innerArray.rule}</span>
						</span>
						<span className="observation-count">
							<span className="rule-circle-count">{innerArray.totalObservation}</span>
						</span>
					</div>
					{expandedRule === index && (
						<div style={{ padding: '0px 15px 0px 25px' }}>
							<ul className="innerrules">
								{console.log(innerArray, 'data1')}
								<li className="inner-observation">
									<span className="inner-observations-name title">
										{innerArray.rule == 'Extract Failures'
											? 'Extracts'
											: '' || innerArray.rule == 'Orphan Extracts'
											? 'Extracts'
											: '' || innerArray.rule == 'Orphan Workbooks'
											? 'Workbooks'
											: ''}
										{innerArray.rule == 'Tableau Growth' ? 'Extracts(Environment)' : ''}
										{innerArray.rule == 'Databricks Growth' ? 'Storage A/C Name' : ''}
										{innerArray.rule == 'Powerbi Growth' ? 'Capability' : ''}
									</span>
									{/* <span className="inner-observations-count title">
										{innerArray.rule == 'Databricks Growth' ? 'Location' : ''}
									</span> */}
									<span className="inner-observations-count title">
										{innerArray.rule == 'Tableau Growth' || innerArray.rule == 'Databricks Growth'
											? 'Size(This Month)'
											: ''}
									</span>
									<span className="inner-observations-count title">
										{innerArray.rule == 'Extract Failures'
											? 'Occurrences'
											: '' || innerArray.rule == 'Orphan Extracts'
											? 'Stale Days'
											: '' || innerArray.rule == 'Orphan Workbooks'
											? 'Stale Days'
											: ''}
										{innerArray.rule == 'Tableau Growth'
											? 'Size(Last Month)'
											: innerArray.rule == 'Databricks Growth'
											? 'Size(Past 3 Months)'
											: innerArray.rule == 'Powerbi Growth'
											? 'Count'
											: ''}
									</span>
								</li>

								<li className="inner-observation">
									<span className="inner-observations-name title">
										{innerArray.rule == 'Query Failures'
											? 'Query ID/Query Name'
											: '' || innerArray.rule == 'Long running Batch Id queries'
											? 'Batch ID'
											: ''}
									</span>

									<span className="inner-observations-count title"></span>
									<span className="inner-observations-count title">
										{innerArray.rule == 'Query Failures'
											? 'Status'
											: '' || innerArray.rule == 'Long running Batch Id queries'
											? 'Duration'
											: ''}
									</span>
								</li>

								{/* PowerBI Alerts */}
								<li className="inner-observation">
									<span className="inner-observations-name title">
										{innerArray.rule === 'CPU Threshold Breach' || innerArray.rule === 'Datasets Refresh Failure'
											? 'Capacity Name'
											: innerArray.rule === 'Orphaned Datasets' ||
											  innerArray.rule === 'Orphaned Users' ||
											  innerArray.rule === 'Inactive Users' ||
											  innerArray.rule === 'Unlicensed Users' ||
											  innerArray.rule === 'Users Never Logged In' ||
											  innerArray.rule === 'Data Sources Not Using Service Account' ||
											  innerArray.rule === 'Total Dataset Refresh Failure' ||
											  innerArray.rule === 'Capacity Threshold Breach' ||
											  innerArray.rule === 'PBI Gateway Version'
											? 'Capability'
											: innerArray.rule === 'Tenant Settings (TS) Mismatch'
											? 'Tenant Name'
											: innerArray.rule === 'Patch Upgrade has Valid Approval and CR' ||
											  innerArray.rule === 'Patch Upgrade Has Valid Approval & CR' ||
											  innerArray.rule === 'Access Not Approved By Process Owner' ||
											  innerArray.rule === 'Access Approved Prior To Granting Of Access' ||
											  innerArray.rule === 'Is SSO Enabled?' ||
											  innerArray.rule === 'Access Granted Without Proper Request' ||
											  innerArray.rule === 'Patch Monitoring Check' ||
											  innerArray.rule === 'Dormant Users Check' ||
											  innerArray.rule === 'Details Of CR Raised Or Not For Key System Config Changes' ||
											  innerArray.rule === 'Access Grantor Different From Approver' ||
											  innerArray.rule === 'Privileged Access Was Not Reviewed And Approved' ||
											  innerArray.rule === 'Reviewer Authorization Check' ||
											  innerArray.rule === 'Key System Configuration Check Approval' ||
											  innerArray.rule === 'Inappropriate Login Activities Risk Assessment' ||
											  innerArray.rule === 'Terminated Users Active Access Check' ||
											  innerArray.rule === 'Key System Configuration Check'
											? 'Tool'
											: ''}
									</span>
									<span className="inner-observations-name title1">
										{innerArray.rule === 'Datasets Refresh Failure' ? 'Dataset Name' : ''}
									</span>
									<span className="inner-observations-name title1">{getObservationTitle(innerArray.rule)}</span>
								</li>
								{/* powerBI code end here for title */}

								{/* Databricks Alerts */}
								<li className="inner-observation">
									<span className="inner-observations-name title">
										{innerArray.rule == 'Long Running Clusters'
											? 'Cluster'
											: '' || innerArray.rule == 'Job Failures' || innerArray.rule == 'Long Running Jobs'
											? 'Job'
											: '' || innerArray.rule == 'Spend Threshold Breach'
											? 'Workspace'
											: ''}
									</span>

									<span className="inner-observations-count title">
										{innerArray.rule == 'Long Running Clusters' ||
										innerArray.rule == 'Job Failures' ||
										innerArray.rule == 'Long Running Jobs'
											? 'Duration'
											: '' || innerArray.rule == 'Spend Threshold Breach'
											? 'Total DBU Cost'
											: ''}
									</span>
								</li>
								{/* Databricks code end here for title */}

								{innerArray.observations.map(
									(obs: any, obsIndex: number) =>
										obs && (
											<React.Fragment key={obsIndex}>
												<li className="inner-observation">
													{innerArray.rule !== 'Datasets Refresh Failure' && (
														<span
															className={
																innerArray.rule === 'Tableau Growth'
																	? 'inner-observations-name1'
																	: 'inner-observations-name'
															}
															title={obs?.name}
														>
															{innerArray.rule === 'Databricks Growth' && (
																<span className="inner-observations-count" title={obs?.Storage_Account}>
																	{obs?.Storage_Account && obs.Storage_Account.length > 13
																		? `${obs.Storage_Account.slice(0, 13)}...`
																		: obs?.Storage_Account}
																</span>
															)}
															{/* name key value not there for Databricks Growth rule from api so put this condition here */}
															{innerArray.rule !== 'Databricks Growth' && (
																<span className="" title={obs?.name}>
																	{obs?.name
																		? obs.name.length > 20
																			? obs.name.substring(0, 16) + '...'
																			: obs.name
																		: 'Untitled'}
																</span>
															)}
														</span>
													)}
													{/* {innerArray.rule === 'Databricks Growth' && (
														<span className="inner-observations-count" title={obs?.Database_Location}>
															{obs.Database_Location.split('windows.net')[1].trim().substring(0, 16) + '...'}
														</span>
													)} */}

													{/* powerbi alert */}
													{innerArray.rule === 'Datasets Refresh Failure' && (
														<>
															<span className="inner-observations-name" title={obs?.CapacityName}>
																{obs?.CapacityName && obs.CapacityName.length > 20
																	? obs.CapacityName.substring(0, 22) + '...'
																	: obs.CapacityName}
															</span>
															<span className="inner-observations-name" title={obs?.DatasetName}>
																{obs?.DatasetName && obs.DatasetName.length > 20
																	? obs.DatasetName.substring(0, 24) + '...'
																	: obs.DatasetName}
															</span>
														</>
													)}
													{/* end of powerbi alert */}

													{innerArray.rule === 'Tableau Growth' && (
														<span className="inner-observations-count">{obs.currentSizeInMB} MB</span>
													)}
													{innerArray.rule === 'Databricks Growth' && (
														<span className="inner-observations-count">{obs.size_in_current_month !== null ? obs.size_in_current_month.toFixed(2) + ' GB' : '--' }</span>
													)}
													{(innerArray.rule === 'Orphan Extracts' || innerArray.rule === 'Orphan Workbooks') && (
														<span className="inner-observations-count">
															{dataConvertFunc(obs.lastRefreshedAt)} days
														</span>
													)}
													{innerArray.rule === 'Tableau Growth' && (
														<span className="inner-observations-count">{obs.lastmonthSizeInMB} MB</span>
													)}
													{innerArray.rule === 'Databricks Growth' && (
														<span className="inner-observations-count">{obs.avg_size_past_3_months !== null ? obs.avg_size_past_3_months.toFixed(2) + ' GB' : '--'}</span>
													)}
													{!(
														innerArray.rule === 'Tableau Growth' ||
														innerArray.rule === 'Orphan Extracts' ||
														innerArray.rule === 'Orphan Workbooks' ||
														innerArray.rule === 'Spend Threshold Breach' ||
														innerArray.rule === 'Long Running Clusters' ||
														innerArray.rule === 'Job Failures' ||
														innerArray.rule === 'Long Running Jobs' ||
														innerArray.rule === 'Data Sources Not Using Service Account' ||
														innerArray.rule === 'Tenant Settings (TS) Mismatch' ||
														innerArray.rule === 'Databricks Growth' ||
														innerArray.rule === 'Patch Upgrade Has Valid Approval & CR' ||
														innerArray.rule === 'Is SSO Enabled?' ||
														innerArray.rule === 'Patch Monitoring Check' ||
														innerArray.rule === 'Key System Configuration Check'
													) && <span className="inner-observations-count">{obs.count}</span>}

													{innerArray.rule === 'Spend Threshold Breach' && (
														<span className="inner-observations-count">$ {CostRound(obs.AverageCost)}</span>
													)}

													{innerArray.rule === 'Long Running Clusters' && (
														<span className="inner-observations-count">{MillisecondsToMinutes(obs.count)}</span>
													)}
													{innerArray.rule === 'Job Failures' && (
														<span className="inner-observations-count">{MillisecondsToMinutes(obs.runTime)}</span>
													)}
													{innerArray.rule === 'Long Running Jobs' && (
														<span className="inner-observations-count">{MillisecondsToMinutes(obs.count)}</span>
													)}
												</li>
											</React.Fragment>
										)
								)}
							</ul>
							<div className="view-details">
								<div className="date">
									{moment(innerArray.lastProcess).format('DD-MM-YYYY')}
									<div className="link">
										{selectedRule === 'Tableau Observability' ? (
											<a href={`/tableau/observation?ruleName=${innerArray.rule}`} onClick={close}>
												View Details
											</a>
										) : selectedRule === 'Presto Observability' ? (
											<a href={`/presto/observation?ruleName=${innerArray.rule}`} onClick={close}>
												View Details
											</a>
										) : selectedRule === 'Databricks Observability' ? (
											<a href={`/databricks/observation?ruleName=${innerArray.rule}`} onClick={close}>
												View Details
											</a>
										) : innerArray.rule == 'Databricks Growth' || innerArray.rule == 'Tableau Growth' ? (
											<a href={`/dataGrowth/observation?ruleName=${innerArray.rule}`} onClick={close}>
												View Details
											</a>
										) : (
											<div>
												{innerArray.rule === 'CPU Threshold Breach' ||
												innerArray.rule === 'Orphaned Users' ||
												innerArray.rule === 'Orphaned Datasets' ||
												innerArray.rule === 'Datasets Refresh Failure' ||
												innerArray.rule === 'Databricks Growth' ||
												innerArray.rule === 'Capacity Threshold Breach' ||
												innerArray.rule === 'Data Sources Not Using Service Account' ||
												innerArray.rule === 'PBI Gateway Version' ||
												innerArray.rule === 'Tenant Settings (TS) Mismatch' ||
												innerArray.rule === 'Total Dataset Refresh Failure' ||
												innerArray.rule === 'Inactive Users' ||
												innerArray.rule === 'Unlicensed Users' ||
												innerArray.rule === 'Users Never Logged In' ||
												innerArray.rule === 'Powerbi Growth' ||
												innerArray.rule === 'Patch Upgrade has Valid Approval and CR' ||
												innerArray.rule === 'Patch Upgrade Has Valid Approval & CR' ||
												innerArray.rule === 'Access Not Approved By Process Owner' ||
												innerArray.rule === 'Access Approved Prior To Granting Of Access' ||
												innerArray.rule === 'Is SSO Enabled?' ||
												innerArray.rule === 'Access Granted Without Proper Request' ||
												innerArray.rule === 'Patch Monitoring Check' ||
												innerArray.rule === 'Dormant Users Check' ||
												innerArray.rule === 'Details Of CR Raised Or Not For Key System Config Changes' ||
												innerArray.rule === 'Access Grantor Different From Approver' ||
												innerArray.rule === 'Privileged Access Was Not Reviewed And Approved' ||
												innerArray.rule === 'Reviewer Authorization Check' ||
												innerArray.rule === 'Key System Configuration Check Approval' ||
												innerArray.rule === 'Inappropriate Login Activities Risk Assessment' ||
												innerArray.rule === 'Terminated Users Active Access Check' ||
												innerArray.rule === 'Key System Configuration Check' ? (
													<a
														href={`${innerArray.clickActionUrl}`}
														onClick={close}
													>
														View Details
													</a>
												) : (
													<a href="/LandingPage" onClick={close}>
														View Details
													</a>
												)}
											</div>
										)}
									</div>

									<div className="icon">
										{selectedRule === 'Tableau Observability' ? (
											<a href={`/tableau/observation?ruleName=${innerArray.rule}`} onClick={close}>
												<img src={quickLink} alt="link" />
											</a>
										) : selectedRule === 'Presto Observability' ? (
											<a href={`/presto/observation?ruleName=${innerArray.rule}`} onClick={close}>
												<img src={quickLink} alt="link" />
											</a>
										) : selectedRule === 'Databricks Observability' ? (
											<a href={`/databricks/observation?ruleName=${innerArray.rule}`} onClick={close}>
												<img src={quickLink} alt="link" />
											</a>
										) : innerArray.rule == 'Databricks Growth' || innerArray.rule == 'Tableau Growth' ? (
											<a href={`/dataGrowth/observation?ruleName=${innerArray.rule}`} onClick={close}>
												<img src={quickLink} alt="link" />
											</a>
										) : (
											<div>
												{innerArray.rule === 'CPU Threshold Breach' ||
												innerArray.rule === 'Orphaned Users' ||
												innerArray.rule === 'Orphaned Datasets' ||
												innerArray.rule === 'Datasets Refresh Failure' ||
												innerArray.rule === 'Databricks Growth' ||
												innerArray.rule === 'Capacity Threshold Breach' ||
												innerArray.rule === 'Data Sources Not Using Service Account' ||
												innerArray.rule === 'PBI Gateway Version' ||
												innerArray.rule === 'Tenant Settings (TS) Mismatch' ||
												innerArray.rule === 'Total Dataset Refresh Failure' ||
												innerArray.rule === 'Inactive Users' ||
												innerArray.rule === 'Unlicensed Users' ||
												innerArray.rule === 'Users Never Logged In' ||
												innerArray.rule === 'Patch Upgrade has Valid Approval and CR' ||
												innerArray.rule === 'Patch Upgrade Has Valid Approval & CR' ||
												innerArray.rule === 'Access Not Approved By Process Owner' ||
												innerArray.rule === 'Access Approved Prior To Granting Of Access' ||
												innerArray.rule === 'Is SSO Enabled?' ||
												innerArray.rule === 'Access Granted Without Proper Request' ||
												innerArray.rule === 'Patch Monitoring Check' ||
												innerArray.rule === 'Dormant Users Check' ||
												innerArray.rule === 'Details Of CR Raised Or Not For Key System Config Changes' ||
												innerArray.rule === 'Access Grantor Different From Approver' ||
												innerArray.rule === 'Privileged Access Was Not Reviewed And Approved' ||
												innerArray.rule === 'Reviewer Authorization Check' ||
												innerArray.rule === 'Key System Configuration Check Approval' ||
												innerArray.rule === 'Inappropriate Login Activities Risk Assessment' ||
												innerArray.rule === 'Terminated Users Active Access Check' ||
												innerArray.rule === 'Key System Configuration Check' ||
												innerArray.rule === 'Powerbi Growth' ? (
													<a
														href={`${innerArray.clickActionUrl}`}
														onClick={close}
													>
														<img src={quickLink} alt="link" />
													</a>
												) : (
													<a href="/LandingPage" onClick={close}>
														<img src={quickLink} alt="link" />
													</a>
												)}
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			))}
		</div>
	);
};

export default RulesEngine;
