import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { getSectorSql } from '../../service/service';

import type { Column } from 'react-data-grid';
import { CustomizedDataGrid } from '../customized_data_grid';
import '../sectors/sectors.css';
import Loader from '../Loader/loader';

export default function sectorsListing() {
	interface Row {
		id: string;
		name: string;
		description: string;
	}
	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'name',
				name: 'Sector',
				minWidth: 150
				// editor: textEditor
			},
			{
				key: 'description',
				name: 'Description',
				minWidth: 170
			}
		];
	}
	const defaultColumnNames = ['Sector', 'Description'];

	const [sectorList, setSectorList] = useState<Row[]>([]);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		sectorListingFunc(value, pageSize, sortingInfo);
	};

	const handlepageSizeChange = (event: any) => {
		sectorListingFunc(page, event.target.value, sortingInfo);
	};

	useEffect(() => {
		sectorListingFunc(page, pageSize, sortingInfo);
	}, []);

	async function sectorListingFunc(pageNumber: any, size: any, sortingInfo: any) {
		const res = await getSectorSql(pageNumber.toString(), size.toString(), sortingInfo);
		if (res.success) {
			setSectorList(res.data.sector);
			setPaginationData(res.data.page);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
		}
	}
	const handleSorting = (sortingInfo: any) => {
		sectorListingFunc(page, pageSize, sortingInfo);
	};

	return (
		<div className="page-table sectors_tabledata">
			<Grid>
				{sectorList.length > 0 ? (
					<CustomizedDataGrid
						tableColumns={getColumns()}
						tableData={sectorList}
						tableType="sector"
						defaultColumnNames={defaultColumnNames}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={sectorList.length}
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isDateRangeFilter={false}
						isDisabled={false}
					/>
				) : (
					<Loader />
				)}
			</Grid>
		</div>
	);
}
