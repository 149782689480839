import { useState, useEffect, useRef } from 'react';
import AnalyticsVisualization from '../../framework/analyticsVisualization';
import { dashboardUrl } from '../../../service/config';
import useStyles from '../../jobs/jobs_styles';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, Button, Stack } from '@mui/material';
import { ReactComponent as FilterIcon } from '../../../assets/ml_summary_filter.svg';
import { useSearchParams } from 'react-router-dom';
import { getDecodedToken, getSector } from '../../../service/service';

interface ModelSummaryProps {
	sharedValue: any;
	updateSharedValue: (value: any) => void;
}
const ModelSummary: React.FC<ModelSummaryProps> = ({ sharedValue, updateSharedValue }) => {
	const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
	const classes = useStyles();
	const dataFetchedRef = useRef(false);
	const [sectorOptionsList, setSectorOptionsList] = useState<any[]>([]);
	const [filterMenu, setFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	const [itemsChecked, setItemsChecked] = useState(false);
	const [checkedList, setCheckedList] = useState<any[]>([]);
	const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);

	const [searchParams, setSearchParams] = useSearchParams();
	const hasDrillDown = searchParams.has('isDrillDowned');

	const filterClick = (event: any) => {
		event.preventDefault();
		setFilterMenu(
			filterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 20,
						mouseY: parseInt(event.clientY) - 6
				  }
				: null
		);
	};
	const handleClose = (event: any) => {
		setFilterMenu(null);
	};
	const selectAllCheck = (e: any) => {
		const { checked } = e.target;
		const collection: any[] = [];
		if (checked) setCheckedList(sectorOptionsList);
		else setCheckedList([]);
		setItemsChecked(checked);
	};
	const handleChange = (e: any) => {
		const { value, checked } = e.target;
		const newlyChecked = [];
		if (checked && e.target.value) {
			newlyChecked.push(e.target.value);
		}
		if (checked) {
			setCheckedList([...checkedList, ...newlyChecked]);
		} else {
			setCheckedList(checkedList.filter((item) => item !== value));
		}
	};

	const handleOkClick = (event: any) => {
		// Create a new list with "S&T" replaced by "SNT"
		const updatedCheckedList = checkedList.map((value: string) => {
			if (value.includes('S&T')) {
				return value.replace('S&T', 'SNT');
			}
			return value;
		});
	
		if (updatedCheckedList.length > 0) {
			setCurrSectorFilter(updatedCheckedList);
		} else {
			setCurrSectorFilter([]);
		}
		updateSharedValue({ sectorFilter: updatedCheckedList });
		console.log(updatedCheckedList);
		localStorage.setItem('ml-summary-sector-filter', JSON.stringify(updatedCheckedList));
		handleClose(event);
	};
	async function fetchSectorOptionsData() {
		const sectorRes = await getSector('1', '100', '');
		if (sectorRes.success) {
			setSectorOptionsList(sectorRes.data.sector.map((x: any) => x.name));
		}
	}

	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;

		if (userSector === 'all') fetchSectorOptionsData();
		let storedSectorFilter = localStorage.getItem('ml-summary-sector-filter')
			? JSON.parse(localStorage.getItem('ml-summary-sector-filter') ?? '')
			: null;
		if (storedSectorFilter) {
			setCheckedList(storedSectorFilter);
			setCurrSectorFilter(storedSectorFilter);
		}
	});
	return (
		<Stack>
			{userSector === 'all' ? (
				<FilterIcon onClick={filterClick} className="sector-filter" />
			) : (
				<div className="sector-filter-text-ml">Sector: {getDecodedToken()?.sector?.name}</div>
			)}

			<Popover
				open={filterMenu !== null}
				onClose={handleClose}
				className="filterContainer"
				anchorReference="anchorPosition"
				anchorPosition={filterMenu !== null ? { top: filterMenu.mouseY, left: filterMenu.mouseX } : undefined}
			>
				<div className="text-filter-row">
					<div className="selectAll-sec">
						<MenuItem className="selectAll">
							<FormControlLabel
								className={classes.checkbox}
								label="All"
								control={
									<Checkbox
										color="success"
										value="all"
										checked={itemsChecked}
										onChange={(e) => {
											selectAllCheck(e);
										}}
									/>
								}
							/>
						</MenuItem>
						<div className="checkbox-sec">
							{sectorOptionsList &&
								sectorOptionsList.map((filter: any) => (
									<MenuItem key={filter}>
										<FormControlLabel
											className={classes.checkbox}
											label={filter === true ? 'Yes' : filter === false ? 'No' : filter}
											control={
												<Checkbox
													color="success"
													value={filter}
													checked={
														(filter === true && checkedList.includes('true')) ||
														(filter === false && checkedList.includes('false')) ||
														checkedList.includes(filter)
													}
													onChange={(e) => {
														handleChange(e);
													}}
												/>
											}
										/>
									</MenuItem>
								))}
						</div>
					</div>
					<div className="buttonSec">
						<Button variant="contained" className={classes.cancelBtn} onClick={handleClose}>
							cancel
						</Button>
						<Button variant="contained" className={classes.okBtn} onClick={handleOkClick}>
							ok
						</Button>
					</div>
				</div>
			</Popover>
			<AnalyticsVisualization
				params={{
					dashboardUrl: dashboardUrl.mlobsSummary,
					className: 'mlobsSummaryIframeContainer',
					otherParams: {
						from: 'now-6h',
						to: 'now',
						'var-sectors': currSectorFilter,
						'var-drillDownStatus': hasDrillDown ? searchParams.get('status') : ''
					},
					seamless: true
				}}
			/>
		</Stack>
	);
};

export default ModelSummary;
