import PrivateRoute from 'utils/privateRoute';
import IBPDashboard from 'components/ibp/ibp-dash';
import IBPCostPage from 'components/ibp/ibp-cost';
import IBPExecutions from 'components/ibp/ibp-jobs';
import IBPMonitor from 'components/ibp/ibp-monitor';
import IBPPlatform from 'components/ibp/ibp-platform';
import IBPDataQuality from 'components/ibp/ibp-dq';
import IBPExecutive from 'components/ibp/ibp-executive';
import BDRPowerPI from 'components/ibp/ibp-bdr-pbi-dash';

const IBPCapabilitiesRoutes = [
	{
		key: 'ibp_dash',
		route: '/ibp/dashboard',
		component: (
			<PrivateRoute
				Component={IBPDashboard}
				restrictedRoles={[
					'Platform Operations Admin/SRE',
					'Data Operations Admin',
					'Data Operations User',
					'D&AI Executive User'
				]}
			/>
		)
	},
	{
		key: 'ibp_cost',
		route: '/ibp/cost',
		component: (
			<PrivateRoute
				Component={IBPCostPage}
				restrictedRoles={[
					'Platform Operations Admin/SRE',
					'Data Operations Admin',
					'Data Operations User',
					'D&AI Executive User'
				]}
			/>
		)
	},
	// {
	// 	key: 'ibp_pla',
	// 	route: '/ibp/ibpla',
	// 	component: (
	// 		<PrivateRoute
	// 			Component={IBPDashboardLA}
	// 			restrictedRoles={[
	//                 'Platform Operations Admin/SRE',
	//                 'Data Operations Admin',
	//                 'Data Operations User',
	//                 'D&AI Executive User'
	//             ]}
	// 		/>
	// 	)
	// },
	{
		key: 'ibp_exec',
		route: '/ibp/executions',
		component: (
			<PrivateRoute
				Component={IBPExecutions}
				restrictedRoles={[
					'Platform Operations Admin/SRE',
					'Data Operations Admin',
					'Data Operations User',
					'D&AI Executive User'
				]}
			/>
		)
	},
	{
		key: 'ibp_mon',
		route: '/ibp/monitor',
		component: (
			<PrivateRoute
				Component={IBPMonitor}
				restrictedRoles={[
					'Platform Operations Admin/SRE',
					'Data Operations Admin',
					'Data Operations User',
					'D&AI Executive User'
				]}
			/>
		)
	},
	{
		key: 'ibp_plat',
		route: '/ibp/platform',
		component: (
			<PrivateRoute
				Component={IBPPlatform}
				restrictedRoles={[
					'Platform Operations Admin/SRE',
					'Data Operations Admin',
					'Data Operations User',
					'D&AI Executive User'
				]}
			/>
		)
	},
	{
		key: 'ibp_data_qual',
		route: '/ibp/data-quality',
		component: (
			<PrivateRoute
				Component={IBPDataQuality}
				restrictedRoles={[
					'Platform Operations Admin/SRE',
					'Data Operations Admin',
					'Data Operations User',
					'D&AI Executive User'
				]}
			/>
		)
	},
	{
		key: 'ibp_exec',
		route: '/ibp/executive',
		component: (
			<PrivateRoute
				Component={IBPExecutive}
				restrictedRoles={[
					'Platform Operations Admin/SRE',
					'Data Operations Admin',
					'Data Operations User',
					'D&AI Executive User'
				]}
			/>
		)
	},
	{
		key: 'ibp_bdr_pbi',
		route: '/ibp/bdr-pbi',
		component: (
			<PrivateRoute
				Component={BDRPowerPI}
				restrictedRoles={[
					'Platform Operations Admin/SRE',
					'Data Operations Admin',
					'Data Operations User',
					'D&AI Executive User'
				]}
			/>
		)
	}
];

export default IBPCapabilitiesRoutes;
