import { lazy } from 'react';
import PrivateRoute from 'utils/privateRoute';
import TableauJobsDetails from 'components/TableauObs/Performance/tableauJobsDetails';
import TableauObsRules from 'components/TableauObs/TableauObsRules/tableauObsRules';
import TableauSummary from 'components/TableauObs/Summary/Summary';
import TableauPerformance from 'components/TableauObs/Performance/tableauJobs';
import TableauGovernance from 'components/TableauObs/Governance/Governance';
import TableauInfrastructure from 'components/TableauObs/Infrastructure';

// const TableauSummary = lazy(() => import('components/TableauObs/Summary/Summary'));
// const TableauPerformance = lazy(() => import('components/TableauObs/Performance/tableauJobs'));
// const TableauGovernance = lazy(() => import('components/TableauObs/Governance/Governance'));
// const TableauInfrastructure = lazy(() => import('components/TableauObs/Infrastructure'));

const TableauObsRoutes = [
	{
		key: 'tab_obs',
		route: '/tableau/observation/',
		component: <PrivateRoute Component={TableauObsRules} restrictedRoles={[]} />
	},
	{
		key: 'tab_sum_dash',
		route: '/tableau/summary/dashboard',
		component: (
			<PrivateRoute
				Component={TableauSummary}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'tab_perfm_dash',
		route: '/tableau/performance/dashboard',
		component: (
			<PrivateRoute
				Component={TableauPerformance}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	// {
	// 	key: 'tab_perfm_dash_job',
	// 	route: '/tableau/performance/dashboard/:jobId',
	// 	component: <PrivateRoute Component={JobDetails} restrictedRoles={[]} />
	// },
	{
		key: 'tab_gov_dash',
		route: '/tableau/governance/dashboard',
		component: (
			<PrivateRoute
				Component={TableauGovernance}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'tab_infra_dash',
		route: '/tableau/infrastructure/dashboard',
		component: (
			<PrivateRoute
				Component={TableauInfrastructure}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'tab_job_det_id',
		route: '/tableau/jobdetails/:jobId',
		component: (
			<PrivateRoute
				Component={TableauJobsDetails}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	}
];

export default TableauObsRoutes;
