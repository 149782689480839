import React, { useState, useEffect, useRef } from 'react';
import type { Column } from 'react-data-grid';
import { CustomizedDataGrid } from '../customized_data_grid';
import { Grid, Box, TextField, Button, Menu } from '@mui/material';
import { Link, useSearchParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { ReactComponent as DatePickerIcon } from '../../assets/calendar-outline_white.svg';
import './jobSchedules.css';
import '../jobs/jobs.css';
import Loader from '../Loader/loader';
import { CellExpanderFormatter } from '../cell_expander_formatter';

import { getJobFutureExecutions,getJobFutureExecutionsFilter, jobSearchForSchedules, getBoxRunsList } from '../../service/service';

export default function jobExecutions() {
	interface Row {
		runStatus: string;
		commonStatus: string;
		reasonForDelay: string;
		actionTaken: string;
		userStatus: string;
		downstream: string;
		slaStatus: string;
		_id: string;
		name: string;
		jobId: string;
		avgRunTime: string;
		status: string;
		exitCode: string;
		runNum: string;
		application: string;
		subApplication: string;
		runStartTime: string;
		runEndTime: string;
		scheduleDays: string;
		scheduleTime: string;
		scheduleDate: string;
		slaEndTime: string;
		criticalFlag: boolean;
		machine: string;
		createdAt: string;
		updatedAt: string;
		updatedBy: string;
		boxName: string;
		jobType: string;
		children?: Row[];
		ischildren?: boolean;
		isExpanded?: boolean;
		actualDuration: string;
		overRun: string;
		thresholdPercent: string;
		durationDelay: string;
	}

	function toggleSubRow(name: string, runStartTime: string) {
		const boxRows = localStorage.getItem('boxRows');
		const rows = boxRows ? JSON.parse(boxRows) : executionsList;
		const rowIndex = rows.findIndex((r: any) => r.name === name && r.runStartTime === runStartTime);
		const row = rows[rowIndex];
		const newRows = [...rows];
		let children: Row[] = [];
		let childRows: Row[] = [];
		const time = row.scheduleTime;
		if (!row.isExpanded) {
			const boxPromise = getBoxRunsList(name, fromDateTime, toDateTime, time);
			boxPromise.then((res) => {
				if (res.success) {
					childRows = res.data;
				}
				children = childRows.map((x: any) => {
					return { ...x, isChildren: true };
				});
				newRows.splice(Number(rowIndex) + 1, 0, ...children);
				newRows[rowIndex] = { ...row, isExpanded: !row.isExpanded, children };
				localStorage.setItem('boxRows', JSON.stringify(newRows));
				setExecutionsList(newRows);
			});
		} else {
			children = row.children;
			newRows.splice(Number(rowIndex) + 1, children.length);
			newRows[rowIndex] = { ...row, isExpanded: !row.isExpanded };
			localStorage.setItem('boxRows', JSON.stringify(newRows));
			setExecutionsList(newRows);
		}
	}

	const [searchParams, setSearchParams] = useSearchParams();
	const [executionsList, setExecutionsList] = useState<any[]>([]);
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [loading, setLoading] = useState(false);
	const hasDrillDowned = searchParams.has('isDrillDowned');
	const minJobScheduleDate = '2023-03-01';
	const dateFrom: string = searchParams.get('fromDate')?.substring(0, 10) as string;
	const dateTo: string = searchParams.get('toDate')?.substring(0, 10) as string;
	const startDt = moment(dateFrom).startOf('day').subtract(1, 'hours').format('YYYY-MM-DD HH:mm');
	const endDt = moment(dateTo).endOf('day').subtract(1, 'hours').format('YYYY-MM-DD HH:mm');
	const [fromDateTime, setFromDateTime] = useState<any>(
		hasDrillDowned && searchParams.has('fromDate')
			? startDt
			: moment().tz('America/New_York').hour(23).minute(0).second(0)
	);
	const [toDateTime, setToDateTime] = useState<any>(
		hasDrillDowned && searchParams.has('toDate')
			? endDt
			: moment(fromDateTime).tz('America/New_York').startOf('hour').add(24, 'hours')
	);

	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });

	const [filterData, setFilterData] = useState<Row[]>([]);
	const [filterInfo, setFilterInfo] = useState([]);
	const [searchDetails, setFilterSearchInfo] = useState([]);
	const dataFetchedRef = useRef(false);
	moment.updateLocale(moment.locale(), { invalidDate: '' });
	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'name',
				name: 'Scheduled Job',
				width: 308,
				minWidth: 150,
				formatter({ row, isCellSelected }) {
					const hasChildren = false; // row.jobType === 'BOX';
					return (
						<>
							{hasChildren && (
								<CellExpanderFormatter
									isCellSelected={isCellSelected}
									expanded={row.isExpanded === true}
									onCellExpand={() => {
										toggleSubRow(row.name, row.runStartTime);
									}}
								/>
							)}
							{'   '}
							<Link
								className="jobsNameList"
								to={`/jobs/${row.jobId}`}
								state={row}
								style={{ paddingLeft: hasChildren ? '5px' : '0px' }}
							>
								{row.name}
							</Link>
						</>
					);
				}
			},
			{
				key: 'boxName',
				name: 'Box Name',
				width: 200,
				minWidth: 150
			},
			{
				key: 'sector',
				name: 'Sector',
				width: 120,
				minWidth: 120
			},
			{
				key: 'system',
				name: 'System',
				width: 120,
				minWidth: 120
			},
			{
				key: 'process',
				name: 'Process',
				width: 120,
				minWidth: 120
			},
			{
				key: 'schedulerName',
				name: 'Scheduler',
				width: 120,
				minWidth: 120
			},
			{
				key: 'criticalFlag',
				name: 'Critical',
				formatter(props) {
					return props.row.criticalFlag ? 'Yes' : 'No';
				},
				minWidth: 105
				// maxWidth: 130
			},
			{
				key: 'scheduleDate',
				name: 'Schedule Date',
				width: 150,
				minWidth: 160,
				formatter(props) {
					const scheduleDate = props.row.scheduleDate;
					if (scheduleDate) {
						return moment.utc(scheduleDate).format('MM-DD-YYYY');
					}
					return '';
				}
			},
			{
				key: 'scheduleTime',
				name: 'Schedule Time',
				width: 150,
				minWidth: 160,
				formatter(props) {
					const scheduleTime = props.row.scheduleTime;
			
					if (typeof scheduleTime === 'string') {
						if (scheduleTime.includes(':')) {
							const parts = scheduleTime.split(':');
							switch (parts.length) {
								case 1: 
									return `${parts[0]}:00:00`;
								case 2:
									return `${parts[0]}:${parts[1]}:00`;
								case 3: 
									return scheduleTime;
								default:
									return scheduleTime; 
							}
						} else {
							const timeFormat = scheduleTime.length;
							switch (timeFormat) {
								case 6:
									return `${scheduleTime.slice(0, 2)}:${scheduleTime.slice(2, 4)}:${scheduleTime.slice(4, 6)}`;
								case 4: 
									return `${scheduleTime.slice(0, 2)}:${scheduleTime.slice(2, 4)}:00`;
								case 2: 
									return `${scheduleTime.slice(0, 2)}:00:00`;
								default:
									return scheduleTime; 
							}
						}
					}
			
					return scheduleTime; // Return as is if it's not a string
				}
			},
			{
				key: 'jobType',
				name: 'Job Type',
				minWidth: 120
				// maxWidth: 160
			},
			{
				key: 'cyclicInterval',
				name: 'Cyclic Interval',
				minWidth: 120,
				maxWidth: 120
			},
			{
				key: 'fromTime',
				name: 'From',
				width: 120,
				minWidth: 120
			},
			{
				key: 'until',
				name: 'Until',
				width: 120,
				minWidth: 120
			}
		];
	}

	const defaultColumnNames = [
		'Scheduled Job',
		'Box Name',
		'Sector',
		'System',
		'Process',
		'Scheduler',
		'Critical',
		'Schedule Date',
		'Schedule Time',
		'Cyclic Interval',
		'From',
		'Until'
	];

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		// document.body.classList.add('loading-indicator');
		setLoading(true);
		const promise1 = fetchJobFutureExecutions(
			value,
			pageSize,
			sortingInfo,
			fromDateTime,
			toDateTime,
			parseFilter(filterInfo)
		);
		Promise.all([promise1]).then((values) => {
			// document.body.classList.remove('loading-indicator');
			// setLoading(false);
		});
	};

	const handlepageSizeChange = (event: any) => {
		// document.body.classList.add('loading-indicator');
		setLoading(true);
		const promise1 = fetchJobFutureExecutions(
			'1',
			event.target.value,
			sortingInfo,
			fromDateTime,
			toDateTime,
			parseFilter(filterInfo)
		);
		Promise.all([promise1]).then((values) => {
			// document.body.classList.remove('loading-indicator');
			// setLoading(false);
		});
	};

	const handleSorting = (sortingInfo: any) => {
		// document.body.classList.add('loading-indicator');
		setLoading(true);
		const promise1 = fetchJobFutureExecutions(
			page,
			pageSize,
			sortingInfo,
			fromDateTime,
			toDateTime,
			parseFilter(filterInfo)
		);
		Promise.all([promise1]).then((values) => {
			// document.body.classList.remove('loading-indicator');
			// setLoading(false);
		});
	};

	const resetSearchParams = () => {
		if (searchParams.has('fromDate') || searchParams.has('status') || searchParams.has('toDate')) {
			searchParams.delete('status');
			searchParams.delete('fromDate');
			searchParams.delete('toDate');
			setSearchParams(searchParams);
		}
	};

	const handleDateTimeChange = (field: any, dateTime: any) => {
		if (field === 'fromDateTime') setFromDateTime(dateTime);
		else setToDateTime(dateTime);
	};

	const applyDateTimeRange = (e: any) => {
		// document.body.classList.add('loading-indicator');
		setLoading(true);
		const promise1 = fetchJobFutureExecutions(
			page,
			pageSize,
			sortingInfo,
			fromDateTime,
			toDateTime,
			parseFilter(filterInfo)
		);
		setDateRangeMenuOpen(null);
		Promise.all([promise1]).then((values) => {
			// document.body.classList.remove('loading-indicator');
			// setLoading(false);
		});
	};

	const handleFilter = (filterInfo: any) => {
		resetSearchParams();
		setFilterInfo(filterInfo);
		// document.body.classList.add('loading-indicator');
		setLoading(true);
		const promise1 = fetchJobFutureExecutions(
			1,
			pageSize,
			sortingInfo,
			fromDateTime,
			toDateTime,
			parseFilter(filterInfo)
		);
		Promise.all([promise1]).then((values) => {
			// document.body.classList.remove('loading-indicator');
			// setLoading(false);
		});
	};

	const handleSearch = (searchDetails: any) => {
		jobSearchFunc(searchDetails, parseFilter(filterInfo, 'name'));
	};

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		// document.body.classList.add('loading-indicator');
		setLoading(true);
		let promise1: any;
		const filterQuerySkeleton = {} as any;
		getColumns().forEach((column: any) => {
			filterQuerySkeleton[column.key] = [];
		});
		const _status = searchParams.get('status');
		if (hasDrillDowned) {
			switch (_status) {
				case 'Executions Schedules':
					filterQuerySkeleton.criticalFlag = ['true'];
					break;
				case 'Non Critical Executions Schedules':
					filterQuerySkeleton.criticalFlag = ['false'];
					break;
				default:
					break;
			}
		} else {
			getColumns().forEach((column: any) => {
				filterQuerySkeleton[column.key] = [];
			});
		}

		let storedJobExecutionsState = localStorage.getItem('jobFutureExecutionsState')
			? JSON.parse(localStorage.getItem('jobFutureExecutionsState') ?? '')
			: null;
		if (localStorage.getItem('selectedAlert')) {
			storedJobExecutionsState = localStorage.getItem('alertJobExecutionsState')
				? JSON.parse(localStorage.getItem('alertJobExecutionsState') ?? '')
				: null;
			localStorage.removeItem('alertJobExecutionsState');
		}
		if (hasDrillDowned) {
			fetchJobFutureExecutions(page, pageSize, sortingInfo, fromDateTime, toDateTime, parseFilter(filterQuerySkeleton));
			localStorage.setItem('drill-down-schedules', 'drill-true');
		} else if (
			searchParams.has('scheduleDate') ||
			searchParams.has('fromDate') ||
			searchParams.has('status') ||
			searchParams.has('toDate')
		) {
			promise1 = fetchJobFutureExecutions(
				page,
				pageSize,
				sortingInfo,
				fromDateTime,
				toDateTime,
				parseFilter(filterQuerySkeleton)
			);
			const isDrillDownCondition = localStorage.getItem('drill-down-schedules');
				if (isDrillDownCondition === 'drill-true') {
					localStorage.setItem('drill-down-schedules', 'false');
				} else {
					localStorage.setItem('drill-down-schedules', 'true');
				}
		} else if (storedJobExecutionsState && storedJobExecutionsState.pageNumber && storedJobExecutionsState.pageSize) {
			promise1 = fetchJobFutureExecutions(
				1,
				storedJobExecutionsState.pageSize,
				storedJobExecutionsState.sortingInfo,
				fromDateTime,
				toDateTime,
				parseFilter(storedJobExecutionsState.filterInfo)
			);
			setPaginationData(storedJobExecutionsState.page);
			setFilterData(storedJobExecutionsState.filterOptions);
			setPage(1);
			setPageSize(storedJobExecutionsState.pageSize);
			setSortingInfo(storedJobExecutionsState.sortingInfo);
			setFilterInfo(storedJobExecutionsState.filterInfo);
			const isDrillDownCondition = localStorage.getItem('drill-down-schedules');
				if (isDrillDownCondition === 'drill-true') {
					localStorage.setItem('drill-down-schedules', 'false');
				} else {
					localStorage.setItem('drill-down-schedules', 'true');
				}
		} else {
			promise1 = fetchJobFutureExecutions(
				page,
				pageSize,
				sortingInfo,
				fromDateTime,
				toDateTime,
				parseFilter(filterQuerySkeleton)
			);
			const isDrillDownCondition = localStorage.getItem('drill-down-schedules');
				if (isDrillDownCondition === 'drill-true') {
					localStorage.setItem('drill-down-schedules', 'false');
				} else {
					localStorage.setItem('drill-down-schedules', 'true');
				}
		}
		setFilterInfo(filterQuerySkeleton);
		Promise.all([promise1]).then((values) => {
			// document.body.classList.remove('loading-indicator');
			// setLoading(false);
		});
		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		const jobName = url.substring(url.lastIndexOf('/') + 1);
		if (window.location.pathname === '/jobSchedules') {
			if (activeId) {
				activeId.classList.add('jobs-schedule-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.add('datefilter-align');
			}
		} else if (jobName !== window.location.pathname && window.location.pathname === '/jobExecutions/' + jobName) {
			if (activeId) {
				activeId.classList.add('jobs-list');
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.remove('jobs-schedule-table');
				activeId.classList.remove('root');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-exe-datas');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				activeId.classList.remove('jobs-schedule-table');
				activeId.classList.remove('jobs-list');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-exe-datas');
			}
		}
	}, []);

	async function jobSearchFunc(searchDetails: any, jobFilterInfo: any) {
		const res = await jobSearchForSchedules(searchDetails, jobFilterInfo, fromDateTime, toDateTime);
		if (res.success) {
			setFilterSearchInfo(res.data);
		}
	}

	async function fetchJobFutureExecutions(
		pageNumber: any,
		size: any,
		sortingInfo: any,
		fromDateTime: any,
		toDateTime: any,
		jobFilterInfo: any
	) {
		setLoading(true);
		const res = await getJobFutureExecutions(
			pageNumber.toString(),
			size.toString(),
			sortingInfo,
			jobFilterInfo,
			fromDateTime,
			toDateTime
		);
		if (res.success) {
			setExecutionsList(res.data.jobs);
			setPaginationData(res.data.page);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
			setFromDateTime(setFromDateTime);
			setToDateTime(setToDateTime);
			setFilterInfo(jobFilterInfo);
			setLoading(false);
			if (!localStorage.getItem('selectedAlert')) {
				const jobFutureExecutionsState = {
					page: res.data.page,
					filterOptions: res.data.filterOptions,
					pageSize: size,
					pageNumber,
					sortingInfo,
					filterInfo: jobFilterInfo
				};
				localStorage.setItem('jobFutureExecutionsState', JSON.stringify(jobFutureExecutionsState));
				localStorage.setItem('boxRows', JSON.stringify(res.data.jobs));
			}
			localStorage.removeItem('selectedAlert');
		}
		setLoading(false);
		const res1 = await getJobFutureExecutionsFilter(
			pageNumber.toString(),
			size.toString(),
			sortingInfo,
			jobFilterInfo,
			fromDateTime,
			toDateTime
		);
		if(res1.success){
			setFilterData(res1.data.filterOptions);

		}
	}
	// [Date Range Menu]
	const [dateRangeMenuOpen, setDateRangeMenuOpen] = React.useState<null | HTMLElement>(null);

	const handleDateClick = (event: React.MouseEvent<HTMLElement>) => {
		setDateRangeMenuOpen(event.currentTarget);
	};

	const handleDateRangeMenu = () => {
		setDateRangeMenuOpen(null);
	};

	const open = Boolean(dateRangeMenuOpen);

	return (
		<div className="job-schedules-wrap">
			<div className="job-exexutions-dateFilter-div">
				{/* [Date Range Menu] */}
				<div style={{ display: 'flex', alignItems: 'center', padding: '6px 0px', marginTop: '2px' }}>
					{moment(fromDateTime).format('DD MM YYYY, HH:mm') ===
						moment().startOf('day').subtract(1, 'hours').format('DD MM YYYY, HH:mm') &&
					moment(toDateTime).format('DD MM YYYY, HH:mm') ===
						moment().endOf('day').subtract(1, 'hours').format('DD MM YYYY, HH:mm') ? (
						<div style={{ marginRight: '80px' }}>Today</div>
					) : (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								marginRight: '5px'
							}}
						>
							<div>{fromDateTime && moment(fromDateTime).tz('America/New_York').format('MM-DD-YYYY HH:mm')}</div>
							<div style={{ marginLeft: '4px', marginRight: '4px' }}>to</div>
							<div>{toDateTime && moment(toDateTime).tz('America/New_York').format('MM-DD-YYYY HH:mm')}</div>
						</div>
					)}

					<div onClick={handleDateClick} style={{ cursor: 'pointer' }}>
						<DatePickerIcon />
					</div>
				</div>

				<Menu
					id="date-range-menu"
					MenuListProps={{
						'aria-labelledby': 'date-range-button'
					}}
					anchorEl={dateRangeMenuOpen}
					open={open}
					onClose={handleDateRangeMenu}
					PaperProps={{
						style: {
							width: '30ch'
						}
					}}
				>
					{/* From date */}
					<span className="range_menu_title">Absolute time range</span>
					<div key="defaultView">
						<div className="label">From</div>
						<LocalizationProvider className="outerDateContainer" dateAdapter={AdapterMoment}>
							<DateTimePicker
								showToolbar={true}
								components={{
									OpenPickerIcon: DatePickerIcon
								}}
								PopperProps={{ placement: 'auto' }}
								toolbarTitle={
									<>
										Select Date Time{' '}
										<div className="time-picker-zone">{`Timezone: ${
											moment().isDST() ? 'EDT(UTC-4)' : 'EST(UTC-5)'
										}`}</div>
									</>
								}
								className="dateFilter"
								ampm={false}
								value={moment(fromDateTime).tz('America/New_York')}
								minDateTime={moment(minJobScheduleDate).tz('America/New_York').startOf('hour').hour(23)}
								maxDateTime={moment().tz('America/New_York').endOf('day').add(6, 'day')}
								onChange={(dateTime) => {
									handleDateTimeChange('fromDateTime', dateTime);
								}}
								renderInput={(params: any) => <TextField name="startDateTime" {...params} />}
							/>
						</LocalizationProvider>
					</div>

					{/* To date */}
					<div>
						<div className="label">To</div>
						<LocalizationProvider className="outerDateContainer" dateAdapter={AdapterMoment}>
							<DateTimePicker
								showToolbar={true}
								components={{
									OpenPickerIcon: DatePickerIcon
								}}
								PopperProps={{
									placement: 'auto'
								}}
								toolbarTitle={
									<>
										Select Date Time{' '}
										<div className="time-picker-zone">{`Timezone: ${
											moment().isDST() ? 'EDT(UTC-4)' : 'EST(UTC-5)'
										}`}</div>
									</>
								}
								className="dateFilter"
								ampm={false}
								value={moment(toDateTime).tz('America/New_York')}
								minDateTime={moment(fromDateTime).tz('America/New_York')}
								maxDateTime={moment(fromDateTime).tz('America/New_York').endOf('day').add(6, 'day')}
								onChange={(dateTime) => {
									handleDateTimeChange('toDateTime', dateTime);
								}}
								renderInput={(params: any) => <TextField name="endDateTime" {...params} />}
							/>
						</LocalizationProvider>
					</div>
					<div className="date_range_button">
						<Button
							variant="contained"
							color="primary"
							style={{ width: '50%' }}
							onClick={() => {
								setFromDateTime(moment().startOf('day').subtract(1, 'hours'));
								setToDateTime(moment().endOf('day').subtract(1, 'hours'));
								setDateRangeMenuOpen(null);
							}}
						>
							Clear
						</Button>

						<Button variant="contained" onClick={applyDateTimeRange} style={{ width: '50%', color: '#00d08d' }}>
							Apply
						</Button>
					</div>
				</Menu>
				{/* <LocalizationProvider className="outerDateContainer" dateAdapter={AdapterMoment}>
					<FormControl className="dateFilter-formControl" sx={{ m: 1 }}>
						<DateTimePicker
							label="From Date"
							showToolbar={true}
							components={{
								OpenPickerIcon: DatePickerIcon
							}}
							PopperProps={{ placement: 'auto' }}
							toolbarTitle={
								<>
									Select Date Time{' '}
									<div className="time-picker-zone">{`Timezone: ${
										moment().isDST() ? 'EDT(UTC-4)' : 'EST(UTC-5)'
									}`}</div>
								</>
							}
							className="dateFilter"
							ampm={false}
							value={moment(fromDateTime).tz('America/New_York')}
							minDateTime={moment().tz('America/New_York').startOf('day').subtract(1, 'hours')}
							maxDateTime={moment().tz('America/New_York').endOf('day').add(9, 'day').subtract(1, 'hours')}
							onChange={(dateTime) => {
								handleDateTimeChange('fromDateTime', dateTime);
							}}
							renderInput={(params: any) => <TextField name="startDateTime" {...params} />}
						/>
					</FormControl>
					<FormControl className="dateFilter-formControl" style={{ marginBottom: '0px' }} sx={{ m: 1 }}>
						<DateTimePicker
							label="To Date"
							showToolbar={true}
							components={{
								OpenPickerIcon: DatePickerIcon
							}}
							PopperProps={{
								placement: 'auto'
							}}
							toolbarTitle={
								<>
									Select Date Time{' '}
									<div className="time-picker-zone">{`Timezone: ${
										moment().isDST() ? 'EDT(UTC-4)' : 'EST(UTC-5)'
									}`}</div>
								</>
							}
							className="dateFilter"
							ampm={false}
							value={moment(toDateTime).tz('America/New_York')}
							minDateTime={moment(fromDateTime).tz('America/New_York')}
							maxDateTime={moment().tz('America/New_York').endOf('day').add(9, 'day').subtract(1, 'hours')}
							onChange={(dateTime) => {
								handleDateTimeChange('toDateTime', dateTime);
							}}
							renderInput={(params: any) => <TextField name="endDateTime" {...params} />}
						/>
					</FormControl>
				</LocalizationProvider> */}
				{/* <Button variant="contained" color="primary" className="dateRangeApplyBtn" onClick={applyDateTimeRange}>
					Apply
				</Button> */}
			</div>
			<Box>
				<div className="job-schedule-table">
					<Grid container item xs={12}>
						<Grid item xs={5}></Grid>
						<Grid item xs={4} />
					</Grid>
					<Grid className="job_schedules_tabledata">
						{!loading ? (
							<CustomizedDataGrid
								tableColumns={getColumns()}
								tableData={executionsList}
								tableType="Schedule"
								defaultColumnNames={defaultColumnNames}
								onSorting={handleSorting}
								currSorting={sortingInfo}
								filterDataOptions={filterData}
								onFilterCheck={handleFilter}
								currFilter={filterInfo}
								showExport={true}
								fileName={`Job Schedules-${moment(toDateTime).format()}.xlsx`}
								searchDataValues={searchDetails}
								onFilterSearch={handleSearch}
								page={page}
								pageSize={pageSize}
								paginationData={paginationData}
								listLength={executionsList.length}
								handlepageSizeChange={handlepageSizeChange}
								handlePageChange={handlePageChange}
								isDateRangeFilter={true}
								isDisabled={false}
								isFetching={loading}
							/>
						) : (
							<Loader />
						)}
					</Grid>
				</div>
			</Box>
		</div>
	);
}
