import React from 'react';
import UnderDevelopment from '../../assets/under-construction.svg';
import AnalyticsVisualization from '../framework/analyticsVisualization';
import { dashboardUrl } from '../../service/config';

const Infrastructure = () => {


	return (
		<div>
			{dashboardUrl.powerbiInfrastructure !== undefined ? (
				<div style={{ position: 'relative', top: '140px' }}>
					<AnalyticsVisualization
						params={{
							dashboardUrl: dashboardUrl.powerbiInfrastructure,
							className: 'powerBiInfrastructureIframeContainer',

							otherParams: {
								from: 'now-6h',
								to: 'now'
							},
							seamless: true
						}}
					/>
				</div>
			) : (
				<div style={{ position: 'absolute', left: '45%', top: '50%', color: 'darkgray' }}>
					<img src={UnderDevelopment} />
					<p style={{ position: 'relative', right: '25%', margin: '0' }}>Dashboard under development!</p>
				</div>
			)}
		</div>
	);
	
	
	// <div style={{ position: 'absolute', left: '45%', top: '50%', color: 'darkgray' }}>
	// 	<img src={UnderDevelopment}  />
	// <p style={{ position: 'relative', right: '25%', margin: '0'}}>Dashboard under development!</p>

	// </div>;
};

export default Infrastructure;


