import moment from 'moment-timezone';
import { Chip } from '@mui/material';
export const getDefaultRuleData = (statusParam: string | null) => {
	let title = 'Tableau Growth',
		status = {} as any,
		column: any = [],
		columnNames = [];
	function formatDuration(duration: any) {
		const durationInMinutes = parseInt(duration); // Assuming duration is in minutes
		const hours = Math.floor(durationInMinutes / 60);
		const minutes = durationInMinutes % 60;

		let formattedDuration = '';
		if (hours > 0) {
			formattedDuration += hours === 1 ? '1 Hour ' : `${hours} Hours `;
		}
		if (minutes > 0 || formattedDuration === '') {
			formattedDuration += minutes === 0 ? '0 Minute' : `${minutes} Minute${minutes === 1 ? '' : 's'}`;
		}
		return formattedDuration.trim();
	}
	const renderStatus = (status: any) => {
		switch (status) {
			case 'SUCCESS':
			case 'Successful':
				return <Chip label={status} color="success" />;
			case 'Failed':
			case 'Failure':
				return <Chip label={status} color="secondary" />;
			case 'NOT STARTED':
			case 'Not Started':
				return <Chip label={status} style={{ backgroundColor: '#1B1E35CC', color: '#71758F' }} />;
			default:
				return <Chip label={status} style={{ backgroundColor: '#299BFF26', color: '#299BFF' }} />;
		}
	};
	switch (statusParam) {
	
		case 'Tableau Growth':
			title = 'Tableau Growth';
			status = 'Tableau Growth';
			columnNames = [
				'Extract Name',
				'Sector',
				'Project',
				'Environment',
				'Size in MB(This Month)',
				'Size in MB(Last Month)',
				'Increase in % from Avg.Size(This Month)'
			];
			column = [
				{ key: 'extract', name: 'Extract Name', minWidth: 150 },
				{ key: 'sector', name: 'Sector', minWidth: 100 },
				{ key: 'project', name: 'Project', minWidth: 100 },
				{ key: 'instance', name: 'Environment', minWidth: 100 },
				{
					key: 'currentSizeInMB',
					name: 'Size in MB(This Month)',
					minWidth: 100,
					formatter(props: any) {
						const currentSizeInMB = props.row.currentSizeInMB;
						if (typeof currentSizeInMB !== 'undefined') {
							return currentSizeInMB + ' MB';
						}
						return '';
					}
				},
				{
					key: 'lastmonthSizeInMB',
					name: 'Size in MB(Last Month)',
					minWidth: 100,
					formatter(props: any) {
						const lastmonthSizeInMB = props.row.lastmonthSizeInMB;
						if (typeof lastmonthSizeInMB !== 'undefined') {
							return lastmonthSizeInMB + ' MB';
						}
						return '';
					}
				},
				{
					key: 'avgPercent',
					name: 'Increase in % from Avg.Size(This Month)',
					minWidth: 150,
					formatter(props: any) {
						const avgPercent = props.row.avgPercent;
						if (typeof avgPercent !== 'undefined') {
							return avgPercent + '%';
						}
						return '';
					}
				}
			];
			break;
			case 'Databricks Growth':
				title = 'Databricks Growth';
				status = 'Databricks Growth';
				columnNames = [
					'Storage Account Name',
					// 'Location',
					'Sector (Specific/Shared)',
					'Size in GB (This Month)',
					'Avg Size in GB (Past 3 Months)',
					'Increase in % from Avg.Size (This Month)'
				];
				column = [
					{ key: 'Storage_Account', name: 'Storage Account Name', minWidth: 150 },
					// { key: 'Database_Location', name: 'Location', minWidth: 250 },
					{ key: 'sector', name: 'Sector (Specific/Shared)', minWidth: 250 },
					{
						key: 'size_in_current_month',
						name: 'Size in GB (This Month)',
						minWidth: 250,
						formatter(props: any) {
							const currentSize = props.row.size_in_current_month;							;
							if (currentSize !== null) {
								return currentSize.toFixed(2) + ' GB';
							}
							return '--';
						}
					},
					{
						key: 'avg_size_past_3_months',
						name: 'Avg Size in GB (Past 3 Months)',
						minWidth: 300,
						formatter(props: any) {
							const pastSize = props.row.avg_size_past_3_months;
							if (pastSize !== null) {
								return pastSize.toFixed(2) + ' GB';
							}
							return '--';
						}
					},
					{
						key: 'count',
						name: 'Increase in % from Avg.Size (This Month)',
						minWidth: 300,
						formatter(props: any) {
							const percent = props.row.count;
							if (percent !== 0) {
								return percent.toFixed(2) + ' %';
							}
							return percent;
						}
					}
				];
				break;
		
		default:
			title = 'Tableau Growth';
			status = 'Tableau Growth';
			columnNames = [
				'Extract Name',
				'Sector',
				'Project',
				'Environment',
				'Size in MB(This Month)',
				'Size in MB(Last Month)',
				'Increase in % from Avg.Size(This Month)'
			];
			column = [
				{ key: 'extract', name: 'Extract Name', minWidth: 150 },
				{ key: 'sector', name: 'Sector', minWidth: 100 },
				{ key: 'project', name: 'Project', minWidth: 100 },
				{ key: 'instance', name: 'Environment', minWidth: 100 },
				{
					key: 'currentSizeInMB',
					name: 'Size in MB(This Month)',
					minWidth: 100,
					formatter(props: any) {
						const currentSizeInMB = props.row.currentSizeInMB;
						if (typeof currentSizeInMB !== 'undefined') {
							return currentSizeInMB + ' MB';
						}
						return '';
					}
				},
				{
					key: 'lastmonthSizeInMB',
					name: 'Size in MB(Last Month)',
					minWidth: 100,
					formatter(props: any) {
						const lastmonthSizeInMB = props.row.lastmonthSizeInMB;
						if (typeof lastmonthSizeInMB !== 'undefined') {
							return lastmonthSizeInMB + ' MB';
						}
						return '';
					}
				},
				{
					key: 'avgPercent',
					name: 'Increase in % from Avg.Size(This Month)',
					minWidth: 150,
					formatter(props: any) {
						const avgPercent = props.row.avgPercent;
						if (typeof avgPercent !== 'undefined') {
							return avgPercent + '%';
						}
						return '';
					}
				}
			];
			break;
	}
	return { title, status, columnNames, column };
};
