import React, { useEffect, useRef, useState } from 'react';
import {
	Button,
	Chip,
	Grid,
	Menu,
	Stack,
	TextField,
	List,
	ListItem,
	ListItemText,
	FormControlLabel,
	Select,
	InputLabel,
	FormControl
} from '@mui/material';
import { dashboardUrl, isEnvTypePROD } from '../../service/config';
import AnalyticsVisualization from '../framework/analyticsVisualization';
import '../TableauObs/Summary/Summary.scss';
// import Workbooks from './Workbooks';
// import TableauUsers from '../../TableauObs/Summary/Users';
import moment from 'moment';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as DatePickerIcon } from '../../assets/calendar-outline_white.svg';
import { ReactComponent as FilterIcon } from '../../assets/ml_summary_filter.svg';
import { ReactComponent as ServerIcon } from '../../assets/solar_server-outline.svg';
import { getCostSummaryChart, getDecodedToken, getSector, fetchDECostTabel } from '../../service/service';
import useStyles from '../jobs/jobs_styles';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import '../DataEstate/data-estate.scss';
import { fetchDECostSummary, fetchDECostTrends } from '../../service/service';
import ReactECharts from 'echarts-for-react';
import '../DataEstate/data-estate-dashboard.scss';
import { ReactComponent as CalendarIcon } from '../../assets/calendar-outline_white.svg';
import ArrowUpBlackIcon  from '../../assets/up-arrow-black.svg';
import {getPercentageOfTwoNumbers, formatNumberWithCommas} from '../../utils/common-util';
import { ReactComponent as Downarrow } from '../../assets/down-arrow.svg';
import { CustomizedDataGrid } from '../customized_data_grid';
import Loader from '../Loader/loader';
import { Column } from 'react-data-grid';
import NoPrgramFound from '../../assets/no-program-found.svg';

const CostSummary = () => {	
	const [searchParams, setSearchParams] = useSearchParams();
	const hasDrillDown = searchParams.has('isDrillDowned');
	const dateFrom: string = searchParams.get('fromDate')?.substring(0, 10) as string;
	const startDt = moment(dateFrom).startOf('day').subtract(1, 'hours').format('YYYY-MM-DD');
	const [fromDateTime, setFromDateTime] = useState<any>(
		searchParams.has('fromDate') ? startDt : moment().startOf('day').subtract(1, 'hours')
	);
	const [DatabricksCost, SetDatabricksCost] = useState('_')
	const [TeraDataCost, SetTeraDataCost] = useState('_')
	const [AzureCost, SetAzureCost] = useState('_')
	const [ReservedInsSaving, SetReservedInsSaving] = useState('_')
	const [DatabricksQueryCost, SetDatabricksQueryCost] = useState('_')
	const [TeradataQueryCost, SetTeradataQueryCost] = useState('_')
	const [PrestoQueryCost, SetPrestoQueryCost] = useState('_')

	const [grafanaDatabricksLoading, setGrafanaDatabricksLoading] = useState(true);
	const [grafanaDatabricksOptions, setGrafanaDatabricksOptions] = useState<any>()
	const [grafanaTeradataLoading, setGrafanaTeradataLoading] = useState(true);
    const [grafanaTeradataOptions, setGrafanaTeradataOptions] = useState<any>()
	const [grafanaAzureLoading, setGrafanaAzureLoading] = useState(true);
    const [grafanaAzureOptions, setGrafanaAzureOptions] = useState<any>()
	
	const [teradataBubbleValue, SetTeradataBubbleValue] = useState(0)
	const [azureBubbleValue, SetAzureBubbleValue] = useState(0)
	const [databricksBubbleValue, SetDatabricksBubbleValue] = useState(0)
	const [customMonth, setCustomMonth] = useState('');
	
	const [propsFromDateTime, setPropsFromDateTime] = useState<any>(
		searchParams.has('fromDate') ? startDt : moment().startOf('day').subtract(1, 'hours')
	);
	
		// sector filter
		const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
		const [filterMenu, setFilterMenu] = useState<{
			mouseX: number;
			mouseY: number;
		} | null>(null);
		
		const [dateFilterMenu, setDateFilterMenu] = useState<{
			mouseX: number;
			mouseY: number;
		} | null>(null);
		
		const classes = useStyles();
		const [itemsChecked, setItemsChecked] = useState(false);
		const [sectorOptionsList, setSectorOptionsList] = useState<any[]>([]);
		const [checkedList, setCheckedList] = useState<any[]>([]);
		const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);
		const [sector, setCurrSector] = useState<any[]>([]);
		// const [filterMonth, setFilterMonth] = useState("0M");
		const [filterMonth, setFilterMonth] = useState("0Y");
		// const [filterDefaultMonth, setFilterDefaultMonth] = useState("0M");
		const [filterDefaultMonth, setFilterDefaultMonth] = useState("0Y");
		
		const dataFetchedRef = useRef(false);
		const [customYear, setCustomYear] = useState(moment().format("YYYY"));
        const [isDateOpen, setIsDateOpen] = useState(false);
		const isCustomMonth = useRef(false);
			
	// TODO START
		// table
		const [tableName, setTableName] = useState('');
		const [typeName, setTypeName] = useState('');

		interface DBCLUSTERS {
			workspace: string;
			clusterType: string;
			clusterName: string;
			totalDBU: string;
			totalDBUCost: string;
			totalComputeCost: string;
		}
		interface TERADATA {
			workload: string;
			totalTCoreHours: string;
			tCoreDailyAverage: string;
			totalCost: string;
		}
		interface AZURE {
			subscription: string;
			resourceGroup: string;
			resource: string;
			location: string;
			totalCost: string;
		}
		interface YTD {
			programName: string;
			total_cost: string;
		}
		interface AVERAGECOST {
			workload: string;
			tCoreUsagePerQuery: string;
			totalQueries: string;
			totalCostPerQuery: string;
		}
		interface Row{};
		function getColumns(): Array<Column<Row>> {
			if(typeName === 'DBCLUSTERS') {
				return [
					{
						key: 'workspace',
						name: 'Workspace',
						minWidth: 150
					},
					{
						key: 'clusterType',
						name: 'Cluster type',
						minWidth: 170,
					},
					{
						key: 'clusterName',
						name: 'Cluster name',
						minWidth: 170,
					},
					{
						key: 'totalDBU',
						name: 'Total DBU',
						minWidth: 170,
					},
					{
						key: 'totalDBUCost',
						name: 'Total DBU Cost',
						minWidth: 170,
					},
					{
						key: 'totalComputeCost',
						name: 'Total compute cost',
						minWidth: 170,
					}
				];
			}
			else if(typeName === 'TERADATA') {
				return [
					{
						key: 'workload',
						name: 'Workload',
						minWidth: 150
					},
					{
						key: 'totalTCoreHours',
						name: 'Total T-Core hours',
						minWidth: 170,
					},
					{
						key: 'tCoreDailyAverage',
						name: 'T-Core daily average',
						minWidth: 170,
					},
					{
						key: 'totalCost',
						name: 'Total cost',
						minWidth: 170,
					}
				];
			}
			else if(typeName === 'AZURE') {
				return [
					{
						key: 'subscription',
						name: 'Subscription',
						minWidth: 150
					},
					{
						key: 'resourceGroup',
						name: 'Resource group',
						minWidth: 170,
					},
					{
						key: 'resource',
						name: 'Resource',
						minWidth: 170,
					},
					{
						key: 'location',
						name: 'Location',
						minWidth: 170,
					},
					{
						key: 'totalCost',
						name: 'Total cost',
						minWidth: 170,
					}
				];
			}
			else if(typeName === 'YTD') {
				return [
					{
						key: 'programName',
						name: 'Year',
						minWidth: 150
					},
					{
						key: 'total_cost',
						name: 'YTD Reserved instance savings',
						minWidth: 170,
					}
				];
			}
			else if(typeName === 'AVERAGECOST') {
				return [
					{
						key: 'workload',
						name: 'Workload',
						minWidth: 150
					},
					{
						key: 'tCoreUsagePerQuery',
						name: 'T-Core usage per query',
						minWidth: 170,
					},
					{
						key: 'totalQueries',
						name: 'Total queries',
						minWidth: 170,
					},
					{
						key: 'totalCostPerQuery',
						name: 'Total cost per query',
						minWidth: 170,
					}
				];
			}
			else {
				return [];
			}
		}
		const DatabricksClustersColumnNames = ['Workspace', 'Cluster type', 'Cluster name', 'Total DBU', 'Total DBU Cost', 'Total compute cost'];
		const TeradataColumnNames = ['Workload', 'Total T-Core hours', 'T-Core daily average', 'Total cost'];
		const AzureColumnNames = ['Subscription', 'Resource group', 'Resource', 'Location', 'Total cost'];
		const YTDColumnNames = ['Year', 'YTD Reserved instance savings'];
		const AverageCostColumnNames = ['Workload', 'T-Core usage per query', 'Total queries', 'Total cost per query'];
		const [defaultColumnNames, setDynamicValue] = useState(['']);
		const [tableData, setTableData] = useState<Row[]>([]);
		const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
		const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
		const [page, setPage] = useState(1);
		const [pageSize, setPageSize] = useState(100);
		const [isDetailVisible, setIsDetailVisible] = useState(false);
		const dimension = useRef('');
		const kpiName = useRef('');
		const [loading, setLoading] = useState(false);
		const tableRef = useRef<HTMLDivElement>(null);
		
		const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
			summaryListingFunc(value, pageSize, sortingInfo, kpiName, dimension);
		};

		const handlepageSizeChange = (event: any) => {
			summaryListingFunc(page, event.target.value, sortingInfo, kpiName, dimension);
		};

		async function summaryListingFunc(pageNumber: any, size: any, sortingInfo: any, kpiName: any, dimension: any ) {
			setLoading(true);
			let month = customMonth ? customMonth : filterMonth;
			const res = await fetchDECostTabel(kpiName.current, dimension.current, sector, month);
			if (res.success) {
				setLoading(false);
				setTableData(res.data.summary);
				setPaginationData(res.data.page);
				setPage(pageNumber);
				setPageSize(size);
				setSortingInfo(sortingInfo);
			}
		}

		const handleSorting = (sortingInfo: any) => {
			summaryListingFunc(page, pageSize, sortingInfo, kpiName, dimension);
		};

		const handleDataEstateOnclick = (value: any) => {
			setDynamicValue(['']);
			setTableData([]);
			setIsDetailVisible(true);
				setTimeout(() => {
      				if (tableRef.current) {
     					tableRef.current.scrollIntoView({ behavior: 'smooth' });
      	 			}
    	 		}, 1000);
			setTypeName(value);
			if(value === 'DBCLUSTERS') {
				setDynamicValue(DatabricksClustersColumnNames);
				type Row = keyof DBCLUSTERS;
				setTableName("Cost of Databricks Clusters");
				kpiName. current = 'platformcost',
				dimension.current = 'databricks'
			}
			else if(value === 'TERADATA') {
				setDynamicValue(TeradataColumnNames);
				type Row = keyof TERADATA;
				setTableName("Cost of Teradata");
				kpiName. current = 'platformcost',
				dimension.current = 'teradata'
			}
			else if(value === 'AZURE') {
				setDynamicValue(AzureColumnNames);
				type Row = keyof AZURE;
				setTableName("Cost of Azure");
				kpiName. current = 'platformcost',
				dimension.current = 'azure'
			}
			else if(value === 'YTD') {
				setDynamicValue(YTDColumnNames);
				type Row = keyof AZURE;
				setTableName("YTD Reserved Instance Savings");
			}
			else if(value === 'AVERAGECOST') {
				setDynamicValue(AverageCostColumnNames);
				type Row = keyof AVERAGECOST;
				setTableName("Cost of a Query on Teradata");
				kpiName. current = 'querycost',
				dimension.current = 'teradata'
			}
			summaryListingFunc(page, pageSize, sortingInfo, kpiName, dimension)	
		
		}
		const clearMetricsSummary = () => {
			setTableData([]);
			setIsDetailVisible(false);
		}

		const dateFilterMenuClick = (event: any) => {
			setDateFilterMenu(
				dateFilterMenu === null
					? {
							mouseX: parseInt(event.clientX) - 260,
							mouseY: parseInt(event.clientY) - 50
					  }
					: null
			);
		};
		const handleDateFilterMenuClose = (event: any) => {
			setDateFilterMenu(null);
		};
	
		const sectorFilterClick = (event: any) => {
		event.preventDefault();
		setFilterMenu(
			filterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 20,
						mouseY: parseInt(event.clientY) - 6
				  }
				: null
		);
	};

	const handleClose = (event: any) => {
		setFilterMenu(null);
	};

	const selectAllCheck = (e: any) => {
		const { checked } = e.target;
		const collection: any[] = [];
		if (checked) setCheckedList(sectorOptionsList);
		else setCheckedList([]);
		setItemsChecked(checked);
	};

	const handleChange = (e: any) => {
		const { value, checked } = e.target;
		const newlyChecked = [];
		if (checked && e.target.value) {
			newlyChecked.push(e.target.value);
		}
		if (checked) {
			setCheckedList([...checkedList, ...newlyChecked]);
			if(sectorOptionsList.length === (checkedList.length + newlyChecked.length)){
				setItemsChecked(true);
			}
		} else {
			setCheckedList(checkedList.filter((item) => item !== value));
			setItemsChecked(false);
		}
	};
	const handleOkClick = (event: any) => {
		clearMetricsSummary();
		localStorage.setItem('selectedSector', JSON.stringify(checkedList)); // Tableau drilldown filter changes
		if (checkedList.length > 0) {
			setCurrSectorFilter(checkedList);
		} else {
			setCurrSectorFilter([]);
		}
		setCurrSector(checkedList);
		getCostSummaryDetails(checkedList, filterMonth);
		handleClose(event);
	};

	async function fetchSectorOptionsData() {
		const sectorRes = await getSector('1', '100', '');
		if (sectorRes.success) {
			const filteredSectorOptions = sectorRes.data.sector.filter((x: any) => x.name !== 'S&T').map((x: any) => x.name);
			filteredSectorOptions.push('Blank');
        	setSectorOptionsList(filteredSectorOptions);
		}
	}

	async function getCostSummaryDetails(sector: any, month: any) {
		// Trend APIs
		SetDatabricksCost('-')
		SetTeraDataCost('-')
		SetAzureCost('-')
		SetReservedInsSaving('-')
		SetDatabricksQueryCost('-')
		SetTeradataQueryCost('-')
		SetPrestoQueryCost('-')
		// setGrafanaDatabricksLoading(true);
		// DATABRICKS
		fetchDECostTrends('costprojection', 'databricks', sector, filterMonth).then(res => {
			if(res.data) {
				let allDataAsArray = Object.entries(res.data);
				const allMonths = Object.keys(res.data);
				
				const currentMonth = new Date().getMonth();
				const pastMonths = Object.entries(allDataAsArray).filter(([date]) => new Date(date).getMonth()+1 <= currentMonth);
				
				if (pastMonths.length) {
					let count: any = 0;
					Object.values(res.data).filter((c) => { if (c) { count = c; } });
					SetDatabricksBubbleValue(getPercentageOfTwoNumbers(count, pastMonths[pastMonths.length - 1][1][1]));
				}
				
				const option = {
					backgroundColor: '#053367',
					grid: {
						top: '11%',
						left: '12%',
						right: '2%',
						bottom: '12%'
					},
					xAxis: {
						type: 'category',
						splitLine: { 
						show: true, 
						lineStyle: { 
							type: 'dashed',
							color: '#004477'
						}
					},
					data: allMonths,
						axisLabel: {
							rotate: 30,
							color: '#A7ADCB',
							formatter: function (value: any) {
								return `${moment(value).format('MMM-YY')}`;
							}
						},
					},
					yAxis: {
						type: 'value',
						splitLine: { 
							show: true, 
							lineStyle: { 
								type: 'dashed',
								color: '#004477'
							}
						},
						axisLabel: {
							color: '#A7ADCB',
							formatter: function (value: any) {
								return `${(value/1000).toFixed(0)}k`;
							}
						},
					},			
					visualMap: {
						show: false,
						dimension: 0,
						pieces: [
						{
							lte: 5,
							color: '#009BD8'
						},
						{
							gt: 5,
							lte: 8,
							color: '#FD783B'
						}
						]
					},
					series: [
						{
						name: 'Current Growth',
						type: 'line',
						data: allDataAsArray,
						markArea: {
							itemStyle: {
							color: 'rgba(255, 173, 177, 0.1)'
							},
							data: [
							[
								{
								name: 'Current Growth',
								xAxis: '2024-01'
								},
								{
								xAxis: '2024-06'
								}
							],
							[
								{
								name: 'Forecast',
								xAxis: '2024-07'
								},
								{
								xAxis: '2024-08'
								}
							]
							]
						}
						}
					],
					tooltip: {
						trigger: 'axis',
						formatter: (params: any) => {
						const { name, seriesName, value } = params[0]; 
						return `${moment(value[0]).format('MMM-YY')}: ${formatNumberWithCommas(parseFloat(value[1]).toFixed(2),true)}`; 
						},
						backgroundColor: '#053367',
						textStyle: {
							color: '#A7ADCB' 
						}
					}
				};
				setGrafanaDatabricksOptions(option);
				setGrafanaDatabricksLoading(false);
			}
		}).catch((error: any) => { 
			console.error(error);
		})
		// TERADATA
		fetchDECostTrends('costprojection', 'teradata', sector, filterMonth).then(res => {
			if(res.data) {
				let allDataAsArray = Object.entries(res.data);
				const allMonths = Object.keys(res.data);
				
				const currentMonth = new Date().getMonth();
				const pastMonths = Object.entries(allDataAsArray).filter(([date]) => new Date(date).getMonth()+1 <= currentMonth);
				
				if (pastMonths.length) {
					let count: any = 0;
					Object.values(res.data).filter((c) => { if (c && !count) { count = c; } });
					SetTeradataBubbleValue(getPercentageOfTwoNumbers(count, pastMonths[pastMonths.length - 1][1][1]));
				}

				const option = {
					backgroundColor: '#053367',
					grid: {
						top: '11%',
						left: '10%',
						right: '2%',
						bottom: '12%'
					},
					xAxis: {
						type: 'category',
						splitLine: { 
							show: true, 
							lineStyle: { 
								type: 'dashed',
								color: '#004477'
							}
						},
					data: allMonths,
						axisLabel: {
							rotate: 30,
							color: '#A7ADCB',
							formatter: function (value: any) {
								return `${moment(value).format('MMM-YY')}`;
							}
						},
					},
					yAxis: {
						type: 'value',
						splitLine: { 
							show: true, 
							lineStyle: { 
								type: 'dashed',
								color: '#004477'
							}
						},
						axisLabel: {
							color: '#A7ADCB',
							formatter: function (value: any) {
								return `${(value/1000).toFixed(0)}k`;
							}
					},
					},		
					visualMap: {
						show: false,
						dimension: 0,
						pieces: [
						{
							lte: 5,
							color: '#009BD8'
						},
						{
							gt: 5,
							lte: 8,
							color: '#FD783B'
						}
						]
					},
					series: [
						{
						name: 'Current Growth',
						type: 'line',
						data: allDataAsArray,
						markArea: {
							itemStyle: {
							color: 'rgba(255, 173, 177, 0.1)'
							},
							data: [
							[
								{
								name: 'Current Growth',
								xAxis: '2024-01'
								},
								{
								xAxis: '2024-06'
								}
							],
							[
								{
								name: 'Forecast',
								xAxis: '2024-07'
								},
								{
								xAxis: '2024-08'
								}
							]
							]
						}
						}
					],
					tooltip: {
						trigger: 'axis',
						formatter: (params: any) => {
						const { name, seriesName, value } = params[0]; 
						return `${moment(value[0]).format('MMM-YY')}: ${formatNumberWithCommas(parseFloat(value[1]).toFixed(2),true)}`; 
						},
						backgroundColor: '#053367',
						textStyle: {
							color: '#A7ADCB' 
						}
					}
				};
				setGrafanaTeradataOptions(option);
				setGrafanaTeradataLoading(false);
			}
		}).catch((error: any) => { 
			console.error(error);
		})
		// AZURE
		fetchDECostTrends('costprojection', 'azure', sector, filterMonth).then(res => {
			if(res.data) {
				let allDataAsArray = Object.entries(res.data);
				const allMonths = Object.keys(res.data);
				
				const currentMonth = new Date().getMonth();
				const pastMonths = Object.entries(allDataAsArray).filter(([date]) => new Date(date).getMonth()+1 <= currentMonth);
				
				if (pastMonths.length) {
					let count: any = 0;
					Object.values(res.data).filter((c) => { if (c && !count) { count = c; } });
					SetAzureBubbleValue(getPercentageOfTwoNumbers(count, pastMonths[pastMonths.length - 1][1][1]));
				}

				const option = {
					backgroundColor: '#053367',
					grid: {
						top: '11%',
						left: '14%',
						right: '2%',
						bottom: '12%'
					},
					xAxis: {
						type: 'category',
						splitLine: { 
							show: true, 
							lineStyle: { 
								type: 'dashed',
								color: '#004477'
							}
						},
					data: allMonths,
						axisLabel: {
							rotate: 30,
							color: '#A7ADCB',
							formatter: function (value: any) {
								return `${moment(value).format('MMM-YY')}`;
							}
						},
					},
					yAxis: {
						type: 'value',
						splitLine: { 
							show: true, 
							lineStyle: { 
								type: 'dashed',
								color: '#004477'
							}
						},
						axisLabel: {
							color: '#A7ADCB',
							formatter: function (value: any) {
								return `${(value/1000).toFixed(0)}k`;
							}
					},
					},			
					visualMap: {
						show: false,
						dimension: 0,
						pieces: [
						{
							lte: 5,
							color: '#009BD8'
						},
						{
							gt: 5,
							lte: 8,
							color: '#FD783B'
						}
						]
					},
					series: [
						{
						name: 'Current Growth',
						type: 'line',
						data: allDataAsArray,
						markArea: {
							itemStyle: {
							color: 'rgba(255, 173, 177, 0.1)'
							},
							data: [
							[
								{
								name: 'Current Growth',
								xAxis: '2024-01'
								},
								{
								xAxis: '2024-06'
								}
							],
							[
								{
								name: 'Forecast',
								xAxis: '2024-07'
								},
								{
								xAxis: '2024-08'
								}
							]
							]
						}
						}
					],
					tooltip: {
						trigger: 'axis',
						formatter: (params: any) => {
						const { name, seriesName, value } = params[0]; 
						return `${moment(value[0]).format('MMM-YY')}: ${formatNumberWithCommas(parseFloat(value[1]).toFixed(2),true)}`; 
						},
						backgroundColor: '#053367',
						textStyle: {
							color: '#A7ADCB' 
						}
					}
				};
				setGrafanaAzureOptions(option);
				setGrafanaAzureLoading(false);
			}
		}).catch(error => { 
			console.error(error);
		})

		// Summary APIs
		fetchDECostSummary('platformcost', 'databricks', sector, month).then(res => {
			let cost = res?.data?.result[0]?.cost 
			SetDatabricksCost((cost/1000000).toFixed(2).toString());
		}).catch(error => { 
			console.error(error);
		})
		fetchDECostSummary('platformcost', 'teradata', sector, month).then(res => {
			let cost = res?.data?.result[0]?.cost 
			SetTeraDataCost((cost/1000000).toFixed(2).toString());
		}).catch(error => { 
			console.error(error);
		})
		fetchDECostSummary('platformcost', 'azure', sector, month).then(res => {
			let cost = res?.data?.result[0]?.cost 
			SetAzureCost((cost/1000000).toFixed(2).toString());
		}).catch(error => { 
			console.error(error);
		})
		// fetchDECostSummary('platformcost', 'reservedinstancesavings', sector, month).then(res => {
		// 	let cost = res?.data?.result[0]?.reservedinstancesavings 
		// 	SetReservedInsSaving(cost);
		// }).catch(error => { 
		// 	console.error(error);
		// })
		// fetchDECostSummary('querycost', 'teradata', sector, month).then(res => {
		// 	let cost = res?.data?.result[0]?.cost 
		// 	SetTeradataQueryCost(cost?.toFixed(2).toString());
		// }).catch(error => { 
		// 	console.error(error);
		// })
	}
	const handleDateFilter = (field: any, selectedDate: any) => {
		clearMetricsSummary();
		if (field === 'dateFilter') {
			setIsDateOpen(false);
			isCustomMonth.current = false;
			setCustomMonth('');
			setFilterMonth(selectedDate.target.value);
			if(selectedDate.target.value === "2023-01") {
				isCustomMonth.current = true;
				setIsDateOpen(true);
				return;
			}
		}
		selectedDate = selectedDate.target.value
		setFilterDefaultMonth(selectedDate);
		setFilterMonth(selectedDate);
		getCostSummaryDetails(sector, selectedDate)
	};
	const handleMonthOnclick = (value:any) => {
		isCustomMonth.current = false;	
		setIsDateOpen(false);
		const selectedDate = customYear + "-" + value;
		setCustomMonth(selectedDate);
		getCostSummaryDetails(sector, selectedDate);
		setDateFilterMenu(null);
	}
	const handleYearAdd = () => {
		let selectedYear = Number(customYear);
		selectedYear++;
		setCustomYear(selectedYear.toString());
	}
	const handleYearMinus = () => {
		let selectedYear = Number(customYear);
		selectedYear--;
		setCustomYear(selectedYear.toString());
	}
	const handleCustomOnclick = (e:any) => {
		if (e.target.textContent == 'Custom Month') {
			isCustomMonth.current = true;
			dateFilterMenuClick(e);
		}
		if(isCustomMonth.current)
			setIsDateOpen(true);
		else
			setIsDateOpen(false);

		if (e.type == 'click' && e.target.className.includes('MuiModal-backdrop')) {
			isCustomMonth.current = false;
			setDateFilterMenu(null);
			setIsDateOpen(false);
			if (customMonth == '') {
				setFilterMonth(filterDefaultMonth)
			}
		}
	}
	useEffect(() => {
		
		if (hasDrillDown) {
			
			const selectedSector = localStorage.getItem('selectedSector')
			if (selectedSector) {
				const selectedSec = JSON.parse(selectedSector).length ? JSON.parse(selectedSector) : ''
				setCheckedList(selectedSec)
				setCurrSectorFilter(selectedSec)
			}
		} else {
			localStorage.removeItem('selectedSector')
		}
		
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		if (userSector === 'all') fetchSectorOptionsData();
		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/dataestate/costSummary') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				document.body.classList.add('custom-class');
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('datefilter-align-sector');
				document.body.classList.add('sector-label');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				document.body.classList.add('dashboard-panel-dataestate');
				document.body.classList.add('datefilter-align-sector');
			}
		}
		getCostSummaryDetails(sector, filterMonth);
	}, []);
	let fromDate = moment(propsFromDateTime).format('YYYY-MM-DD');
	return (
		<div className="dashboard-panel-dataestate job-schedules-wrap">
			{/* <div className="service-date-filter date-picker-summary"> */}
			<div className="service-date-filter date-picker-summary data-estate-right">
				{/* Code for Date filter */}
				<Stack>
					<div className="date-select">
						<FormControl fullWidth>
							<CalendarIcon className="date-icon custom-calendar-icon"/>
							<Select
								disabled
								className='custom-disabled-select'
								name="date-select"
								labelId="demo-simple-select-label"
								id="single-date-select"
								value={filterMonth}
								label="Date"
								onChange={(e) => {
									handleDateFilter('dateFilter', e);
								}}
								classes={{ select: classes.select }}
								IconComponent={() => null} 
								open={isDateOpen} 
								onClick={(e) =>handleCustomOnclick(e)}
								onOpen={() => setIsDateOpen(true)}
								onClose={() => setIsDateOpen(false)}
							>
								<MenuItem value={"0M"}>This Month</MenuItem>
								<MenuItem value={"1M"}>Last Month</MenuItem>
								<MenuItem value={"3M"}>Last 3 Months</MenuItem>
								<MenuItem value={"6M"}>Last 6 Months</MenuItem>
								<MenuItem value={"9M"}>Last 9 Months</MenuItem>
								<MenuItem value={"0Y"}>This Year</MenuItem>
								<MenuItem value={"1Y"}>Last Year</MenuItem>
								<MenuItem value={"2Y"}>Last 2 Years</MenuItem>
								<MenuItem value={"2023-01"}>Custom Month</MenuItem>
							</Select>
						</FormControl>
						{isCustomMonth.current && <Popover
								open={dateFilterMenu !== null}
								onClose={handleDateFilterMenuClose}
								className="custom-date-filter-menu"
								anchorReference="anchorPosition"
								anchorPosition={dateFilterMenu !== null ? { top: dateFilterMenu.mouseY, left: dateFilterMenu.mouseX } : undefined}
							><div className="custom-class-date date-custom">
							<div className='custom-dis'>
								<InputLabel>{customYear}</InputLabel>
								<div style={{marginLeft:'60px'}}>
									<Downarrow className='up-arrow' onClick={handleYearAdd}/>
									<Downarrow className='year-arrow' onClick={handleYearMinus}/>
								</div>
							</div>
							<div className='custom-dis'>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("01")}>January</Button>
								<Button  variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("02")}>February</Button>
							</div>
							<div className='custom-dis'>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("03")}>March</Button>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("04")}>April</Button>
							</div>
							<div className='custom-dis'>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("05")}>May</Button>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("06")}>Jun</Button>
							</div>
							<div className='custom-dis'>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("07")}>July</Button>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("08")}>August</Button>
							</div>
							<div className='custom-dis'>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("09")}>September</Button>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("10")}>October</Button>
							</div>
							<div className='custom-dis'>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("11")}>November</Button>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("12")}>December</Button>
							</div>
						</div></Popover>}
					</div>
				</Stack>
			</div>
			{/* <div className="service-sector-filter-cost service-sector-filter"> */}
				{/* Code for sector filter */}
				{/* <Stack className="sector-filter-dataestate">
					{userSector === 'all' ? (
						<><span className='sector-cta' onClick={sectorFilterClick}><span className='sector-label'>Sectors</span> <FilterIcon /></span></>
					) : (
						<div className="separeat_sector">Sector: {getDecodedToken()?.sector?.name}</div>
					)}
					<Popover
						open={filterMenu !== null}
						onClose={handleClose}
						className="filterContainer"
						anchorReference="anchorPosition"
						anchorPosition={filterMenu !== null ? { top: filterMenu.mouseY, left: filterMenu.mouseX } : undefined}
					>
						<div className="text-filter-row">
							<div className="selectAll-sec">
								<MenuItem className="selectAll">
									<FormControlLabel
										className={classes.checkbox}
										label="All"
										control={
											<Checkbox
												color="success"
												value="all"
												checked={itemsChecked}
												onChange={(e) => {
													selectAllCheck(e);
												}}
											/>
										}
									/>
								</MenuItem>
								<div className="checkbox-sec">
									{sectorOptionsList &&
										sectorOptionsList.map((filter: any) => (
											<MenuItem key={filter}>
												<FormControlLabel
													className={classes.checkbox}
													label={filter === true ? 'Yes' : filter === false ? 'No' : filter}
													control={
														<Checkbox
															color="success"
															value={filter}
															checked={
																(filter === true && checkedList.includes('true')) ||
																(filter === false && checkedList.includes('false')) ||
																checkedList.includes(filter)
															}
															onChange={(e) => {
																handleChange(e);
															}}
														/>
													}
												/>
											</MenuItem>
										))}
								</div>
							</div>
							<div className="buttonSec">
								<Button variant="contained" className={classes.cancelBtn} onClick={handleClose}>
									cancel
								</Button>
								<Button variant="contained" className={classes.okBtn} onClick={handleOkClick}>
									ok
								</Button>
							</div>
						</div>
					</Popover>
				</Stack>
			</div> */}
			<div className="page-table jobs-tabledata page-table-ibp-monitor">
				<div className="ibp-dashboard-wrapper">
					<Grid container spacing={0} className="headerIPB ">
					<Grid item container xs={12}>
					<Grid item xs={12} lg={12}>
							<div className="panel-legend mr-10 mb-10">
								<h2 className="panel-legend--title">Platform Cost</h2>
								<Grid container spacing={1}>
								<Grid item xs={4} className="dataestate-title bgcolor-blue cursor" onClick = {() =>{ !isEnvTypePROD ? handleDataEstateOnclick('AZURE') : '' } }>
										<div className="monitor-head">Azure Cost</div>
										{/* <div className="dataestate-subheader">{AzureCost == '-' ? <span className="loading">Loading...</span> : <>${AzureCost} <span className="subheader-text">MM</span></>}</div> */}
										<div className="dataestate-subheader">
											{AzureCost == '-' ? <span className="loading">Loading...</span> : <>${formatNumberWithCommas(AzureCost)} <span className="subheader-text">MM</span></>}
										</div>
									</Grid>
									<Grid item xs={4} className="dataestate-title bgcolor-blue cursor" onClick = {() =>{ !isEnvTypePROD ? handleDataEstateOnclick('DBCLUSTERS') : '' }}>
										<div className="monitor-head">Databricks Cost</div>
										{/* <div className="dataestate-subheader">{DatabricksCost == '-' ? <span className="loading">Loading...</span> : <>${DatabricksCost} <span className="subheader-text">MM</span></>}</div> */}
										<div className="dataestate-subheader">
											{DatabricksCost == '-' ? <span className="loading">Loading...</span> : <>${formatNumberWithCommas(DatabricksCost)} <span className="subheader-text">MM</span></>}
										</div>
									</Grid>
									<Grid item xs={4} className="dataestate-title bgcolor-blue cursor" onClick = {() =>{ !isEnvTypePROD ? handleDataEstateOnclick('TERADATA') : '' } }>
										<div className="monitor-head">Teradata Cost</div>
										<div className="dataestate-subheader">
											{TeraDataCost == '-' ? <span className="loading">Loading...</span> : <>${TeraDataCost} <span className="subheader-text">MM</span></>}
										</div>
									</Grid>										
									{/* <Grid item xs={12} className="dataestate-title bgcolor-blue cursor" onClick = {() =>{ !isEnvTypePROD ? handleDataEstateOnclick('YTD') : '' } }>
										<div className="monitor-head">YTD Reserved Instance Savings</div>
										<div className="dataestate-subheader">{ReservedInsSaving == '-' ? <span className="loading">Loading...</span> : <>${formatNumberWithCommas(ReservedInsSaving)} <span className="subheader-text">MM</span></>}</div>
									</Grid> */}
								</Grid>
							</div>	
						</Grid>
						
						{/* <Grid item xs={12} md={6} lg={3} className="headerIPB-title subheaderIPB-title no-ml">
							<div className="panel-legend mb-10">
								<h2 className="panel-legend--title">Average Cost Per Query</h2>
								<Grid container spacing={1}>
									//  <Grid item xs={4} className="dataestate-title bgcolor-blue">
									// 	<div className="monitor-head">Cost of a Query on Databricks</div>
									// 	<div className="dataestate-subheader">${DatabricksQueryCost == '-' ? <span className="loading">Loading...</span> : <>{DatabricksQueryCost} <span className="subheader-text">k</span></>}</div>
									// </Grid> 
									<Grid item xs={12} className="dataestate-title bgcolor-blue cursor" onClick = {() =>{ !isEnvTypePROD ? handleDataEstateOnclick('AVERAGECOST') : '' } }>
										<div className="monitor-head">Cost of a Query on Teradata</div>
										<div className="dataestate-subheader">
											{TeradataQueryCost == '-' ? <span className="loading">Loading...</span> : <>${formatNumberWithCommas(TeradataQueryCost)} <span className="subheader-text"></span></>}
										</div>
									</Grid>
									//  <Grid item xs={4} className="dataestate-title bgcolor-blue">
									// 	<div className="monitor-head">Cost of a Query on Presto</div>
									// 	<div className="dataestate-subheader">${PrestoQueryCost == '-' ? <span className="loading">Loading...</span> : <>{PrestoQueryCost} <span className="subheader-text">k</span></>}</div>
									// </Grid> 
								</Grid>
							</div>	
						</Grid> */}
					</Grid>
						<Grid item xs={12} className="headerIPB-title subheaderIPB-title no-ml cost-trends">
							<div className="panel-legend mb-10">
								<h2 className="panel-legend--title">Cost Trends</h2>
								<Grid container gap={1}>
									{/* <Grid item xs={4} className="dataestate-title chart-widget bgcolor-blue"> */}
									<Grid item xs={12} md={5.9} lg={4} p={2} className="chart-widget bgcolor-blue mh-404">
										<div className="monitor-head">Azure Cost with Projections <br/><i>(Trend)</i>
										<span className="badge">
											{Math.sign(azureBubbleValue) == -1 && <><img src={ArrowUpBlackIcon} className="arrow-icon-styles arrow-icon-styles-up-black down-arrow" /> {Math.abs(Math.round(azureBubbleValue))}% /Yr</> }
											{Math.sign(azureBubbleValue) != -1 && <><img src={ArrowUpBlackIcon} className="arrow-icon-styles arrow-icon-styles-up-black" /> {Math.abs(Math.round(azureBubbleValue))}% /Yr</> }
										</span>
										</div>
												{
													grafanaAzureLoading ? 'Loading...' : <ReactECharts
													option={grafanaAzureOptions}
													theme={{
														backgroundColor: '#1b1e35'
													}}
												/>}
									</Grid>
									{/* <Grid item xs={4} className="dataestate-title chart-widget bgcolor-blue ml-15"> */}
									<Grid item xs={12} md={5.9} lg={3.9} p={2} className="chart-widget bgcolor-blue mh-404">
										<div className="monitor-head">Databricks Cost with Projections <br/><i>(Trend)</i>
										<span className="badge">
											{Math.sign(databricksBubbleValue) == -1 && <><img src={ArrowUpBlackIcon} className="arrow-icon-styles arrow-icon-styles-up-black down-arrow" /> {Math.abs(Math.round(databricksBubbleValue))}% /Yr</> }
											{Math.sign(databricksBubbleValue) != -1 && <><img src={ArrowUpBlackIcon} className="arrow-icon-styles arrow-icon-styles-up-black" /> {Math.abs(Math.round(databricksBubbleValue))}% /Yr</> }
										</span>
										</div>
											{
												grafanaDatabricksLoading ? 'Loading...' : <ReactECharts
												option={grafanaDatabricksOptions}
												theme={{
													backgroundColor: '#1b1e35'
												}}
											/>}
									</Grid>
									{/* <Grid item xs={4} className="dataestate-title chart-widget bgcolor-blue"> */}
									<Grid item xs={12} lg={3.9} p={2} className="chart-widget bgcolor-blue mh-404">
										<div className="monitor-head">Teradata Cost with Projections <br/><i>(Trend)</i>
										<span className="badge">
											{Math.sign(teradataBubbleValue) == -1 && <><img src={ArrowUpBlackIcon} className="arrow-icon-styles arrow-icon-styles-up-black down-arrow" /> {Math.abs(Math.round(teradataBubbleValue))}% /Yr</> }
											{Math.sign(teradataBubbleValue) != -1 && <><img src={ArrowUpBlackIcon} className="arrow-icon-styles arrow-icon-styles-up-black" /> {Math.abs(Math.round(teradataBubbleValue))}% /Yr</> }
										</span>
										</div>
												{
													grafanaTeradataLoading ? 'Loading...' : <ReactECharts
													option={grafanaTeradataOptions}
													theme={{
														backgroundColor: '#1b1e35'
													}}
												/>}
									</Grid>									
								</Grid>
							</div>	
						</Grid>
					</Grid>
					{/* TODO START */}
					{/* detail part */}
					{isDetailVisible && <><h2 className="panel-legend--title mt-35 ">{tableName}</h2>
					<div className=" dataestate_tabledata" ref={tableRef}>
					<Grid>
					{tableData && tableData.length > 0 ? (
						<CustomizedDataGrid
							tableColumns={getColumns()}
							tableData={tableData}
							tableType="sector"
							defaultColumnNames={defaultColumnNames}
							onSorting={handleSorting}
							currSorting={sortingInfo}
							page={page}
							pageSize={pageSize}
							paginationData={paginationData}
							listLength={tableData.length}
							handlepageSizeChange={handlepageSizeChange}
							handlePageChange={handlePageChange}
							isDateRangeFilter={false}
							isDisabled={false}
							isFetching={true}
							/>
									 
								
							 ):(
								tableData ? <Loader /> : <span  style={{display: 'flex', justifyContent: 'center', }}>No Records Found</span>
							 )
							}
						</Grid>
					</div>
					</>}
					{/* TODO END */}
				</div>
			</div>
		</div>
	);
};

export default CostSummary;
