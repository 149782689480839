import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { getSystem } from '../../service/service';

import { CustomizedDataGrid } from '../customized_data_grid';
import type { Column } from 'react-data-grid';
import '../systems/systems.css';
import Loader from '../Loader/loader';
export default function systemsListing() {
	interface Row {
		id: string;
		name: string;
		description: string;
	}
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'name',
				name: 'System',
				minWidth: 150
				// editor: textEditor
			},
			{
				key: 'description',
				name: 'Description',
				minWidth: 170
			}
		];
	}

	const defaultColumnNames = ['System', 'Description'];

	const [systemList, setSystemList] = useState<any[]>([]);
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		systemListingFunc(value, pageSize, sortingInfo);
	};

	const handlepageSizeChange = (event: any) => {
		systemListingFunc(page, event.target.value, sortingInfo);
	};

	useEffect(() => {
		systemListingFunc(page, pageSize, sortingInfo);
	}, []);

	async function systemListingFunc(pageNumber: any, size: any, sortingInfo: any) {
		const res = await getSystem(pageNumber.toString(), size.toString(), sortingInfo);
		if (res.success) {
			setSystemList(res.data.system);
			setPaginationData(res.data.page);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
		}
	}
	const handleSorting = (sortingInfo: any) => {
		systemListingFunc(page, pageSize, sortingInfo);
	};

	return (
		<div className="page-table systems_tabledata">
			<Grid>
				{systemList.length > 0 ? (
					<CustomizedDataGrid
						tableColumns={getColumns()}
						tableData={systemList}
						tableType="System"
						defaultColumnNames={defaultColumnNames}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={systemList.length}
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isDateRangeFilter={false}
						isDisabled={false}
					/>
				) : (
					<Loader />
				)}
			</Grid>
		</div>
	);
}
