import { createTheme, ThemeOptions } from '@mui/material/styles';
import colors from 'assets/theme/base/colors';
import { pepWiseTypography } from './typographyUtil';

// Create a new theme by extending the default theme
const themeOptions: ThemeOptions = {
	palette: { ...colors },
	typography: { ...pepWiseTypography },
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1285,
			xl: 1536
		}
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '8px',
					textTransform: 'none'
				}
			}
		},
		MuiTypography: {
			styleOverrides: {
				caption: {
					color: '#e5e5e5',
					fontSize: '11px',
					lineHeight: 'normal'
				}
			}
		},
		MuiCalendarOrClockPicker: {
			styleOverrides: {
				root: {
					color: '#E5E5E5'
				}
			}
		},
		MuiClockNumber: {
			styleOverrides: {
				root: {
					color: '#E5E5E5'
				}
			}
		},
		PrivatePickersToolbarText: {
			styleOverrides: {
				root: {
					color: '#E5E5E5'
				}
			}
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					fontSize: '12px',
					color: '#E5E5E5',
					backgroundColor: '#1B1E35 !important'
				},
				arrow: {
					color: '#1B1E35  !important'
				}
			}
		},
		MuiChip: {
			styleOverrides: {
				label: {
					fontSize: '12px',
					fontWeight: 600,
					color: '#001E3E !important'
				},
				icon: {
					color: '#001E3E !important',
					fontSize: '18px',
					marginRight: '-8px'
				},
				root: {
					backgroundColor: '#009BD8 !important',
					width: 'fit-content !important'
				}
			}
		},
		MuiDrawer: {
			styleOverrides: {
				paper: {
					margin: '0px !important'
				}
			}
		}
	}
};

const theme = createTheme(themeOptions);

export default theme;
