// import React, { useRef, useState, useEffect } from 'react';
// import type { MouseEvent } from 'react';
import Tooltip from '@mui/material/Tooltip';
// import type { TooltipProps } from '@mui/material/Tooltip';

export function OverflowTooltip({ children, customTooltip }: any) {
	// const textElementRef = useRef(null);
	// const checkOverflow = () => {
	// 	if (!textElementRef.current) return;
	//
	// 	// Using getBoundingClientRect, instead of scrollWidth and clientWidth, to get width with fractional accuracy
	//
	// 	// const currentEl = textElementRef.current as HTMLElement;
	// 	// const clientWidth = currentEl.getBoundingClientRect().width;
	// 	//
	// 	// currentEl.style.overflow = 'visible';
	// 	// const contentWidth = currentEl.getBoundingClientRect().width;
	// 	// currentEl.style.overflow = 'hidden';
	// 	// console.log(contentWidth, clientWidth);
	// 	console.log(isOverflowed);
	// 	// setIsOverflow(contentWidth > clientWidth);
	// };

	// const handleShouldShow = (e: any) => {
	// 	const currentTarget = e.target;
	// 	console.log(currentTarget.offsetWidth, currentTarget.scrollWidth, currentTarget.clientWidth, 1111);
	// 	if (currentTarget.scrollWidth > currentTarget.clientWidth) {
	// 		setIsOverflow(true);
	// 	}
	// };

	// useEffect(() => {
	// 	if (!textElementRef.current) return;
	// 	checkOverflow();
	// 	window.addEventListener('resize', checkOverflow);
	// 	return () => {
	// 		window.removeEventListener('resize', checkOverflow);
	// 	};
	// }, []);

	// const hideTooltip = () => {
	// 	setTooltipEnabled(false);
	// };

	// const [isOverflowed, setIsOverflow] = useState(false);

	return (
		<Tooltip
			title={customTooltip || children}
			followCursor={true}
			// onMouseEnter={handleShouldShow}
			// onMouseLeave={hideTooltip}
			// disableHoverListener={!isOverflowed}
			arrow
		>
			<span
			// ref={textElementRef}
			>
				{children}
			</span>
		</Tooltip>
	);
}
