import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useStyles from './loginStyles';
import { Grid, TextField, Typography, InputAdornment, Button, FormControlLabel, Checkbox } from '@mui/material';
import PepsicoLogo from '../../assets/Pepwise logo_LoginPage.svg';
import ErrorTriangle from '../../assets/error-triangle.svg';
import User from '../../assets/ph_user-filll.svg';
import Lock from '../../assets/ic_baseline-lock-white.svg';
import { login, getDecodedToken, role, landingPage } from '../../service/service';
import '../loginScreen/login.css';
import Footer from '../Footer/footer';
import Loader from '../Loader/loader';
import {
	usePepwiseAuthController,
	setAuthType,
	setAuthToken,
	setAuthenticated,
	setRole,
	setCapabilities
  } from "Context/contexts/AuthContext";
import { useRedirectLogin } from 'hooks/useRedirectHooks';

export default function loginScreen() {
	const navigate = useNavigate()
	const {redirectAfterLogin} = useRedirectLogin()
	const [controller, dispatch] = usePepwiseAuthController();
	const {isAuthenticated, accessToken} = controller;

	useEffect(() => {
		let authenticated = false;
		if (isAuthenticated) {
			if(accessToken?.exp){
				const currentTime = Math.floor(Date.now() / 1000);
				//Check access token is expired
				 if(accessToken.exp >= currentTime) {
					authenticated = true;
				 }
				 setAuthenticated(dispatch, false)
				 setAuthToken(dispatch, null)
			}
		}
		if(authenticated===false){
			const auth = getDecodedToken();
			if (auth){
				const currentTime = Math.floor(Date.now() / 1000);
				if(auth.exp >= currentTime) {
					setAuthType(dispatch, "local")
					setAuthToken(dispatch, auth)
					setRole(dispatch, auth.role?.name)
					setCapabilities(dispatch, auth.role?.capabilities)
					setAuthenticated(dispatch, true)
					navigate(landingPage[role] || '/');
				}
			}			
		}
	}, []);

	const classes = useStyles();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [emailError, setEmailError] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const [pageLoad, setPageLoad] = useState(false);

	async function loginFunc(event: any) {
		setPageLoad(true);
		event.preventDefault();
		const err = validate();
		if (!err) {
			const res = await login({ email, password });
			if (res.success) {
				localStorage.setItem('sso-login', 'false');
				const auth = getDecodedToken();
				if (auth){
					setAuthType(dispatch, "local")
					setAuthToken(dispatch, auth)
					setRole(dispatch, auth.role?.name)
					setCapabilities(dispatch, auth.role?.capabilities)
					setAuthenticated(dispatch, true)
					redirectAfterLogin();
				}
			} else if (res.response.status === 404 || res.response.status === 401) {
				const data = res.response.data.data;
				if (data && data.message && data.message.email) {
					setEmailError('Invalid Email');
				} else if (data && data.message && data.message.msg) {
					setPasswordError(data.message.msg);
				}
			}
		}
		setPageLoad(false);
	}

	const validate = () => {
		let isError = false;
		const errors = {
			emailError: '',
			passwordError: ''
		};

		if (!email) {
			isError = true;
			errors.emailError = 'Email is required!';
		} else if (!/\S+@\S+\.\S+/.test(email)) {
			isError = true;
			errors.emailError = 'Email is invalid!';
		}

		if (!password) {
			isError = true;
			errors.passwordError = 'Password is required!';
		}

		setEmailError(errors.emailError);
		setPasswordError(errors.passwordError);

		return isError;
	};

	return (
		<Grid container className="outerLoginContainer">
			<Grid item xs={7} className={classes.firstGrid}></Grid>
			<Grid item xs={5} className={classes.loginForm}>
				<form onSubmit={loginFunc}>
					<Grid className={classes.center}>
						<img id="logoGridContainer1" src={PepsicoLogo} className={classes.logo} />
						<Typography className="login-para">Observability and Monitoring Framework</Typography>
						<Typography className="login-continue">Login to continue</Typography>
						<TextField
							id="searchFieldTextUser"
							variant="standard"
							autoComplete="off"
							placeholder="Email / User Id"
							className={emailError ? 'ErrorTxt' : classes.textfield}
							value={email}
							onChange={(e) => {
								setEmail(e.target.value.trim());
							}}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<img className={classes.img} src={User} />
									</InputAdornment>
								)
							}}
							inputProps={{
								style: {
									height: '35px',
									paddingRight: '20px'
								}
							}}
						/>
						<TextField
							id="searchFieldpassword"
							autoComplete="off"
							type="password"
							variant="standard"
							placeholder="Password"
							value={password}
							onChange={(e) => {
								setPassword(e.target.value.trim());
							}}
							className={passwordError ? 'ErrorTxt' : classes.textfield}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<img className={classes.img} src={Lock} />
									</InputAdornment>
								)
							}}
							inputProps={{
								style: {
									height: '35px',
									paddingRight: '20px'
								}
							}}
						/>
						{(emailError || passwordError) && (
							<div className={classes.pwdErr}>
								<img src={ErrorTriangle} />
								Invalid credentials. Please try again.
							</div>
						)}
						<div className="login-below-text">
							<FormControlLabel
								className={classes.rememberTxt}
								label="Remember me"
								control={
									<Checkbox
										sx={{
											color: '#00D08D',
											'&.Mui-checked': {
												color: '#00D08D'
											}
										}}
									/>
								}
							/>
							<a href="javascript:void(0)" className={classes.forgotTxt}>
								Forgot password?
							</a>
						</div>
						<Button
							id="#loginbutton"
							variant="contained"
							className={classes.loginBtn}
							type="submit"
							disabled={pageLoad}
						>
							CONTINUE
						</Button>
					</Grid>
				</form>
			</Grid>
			<Footer />
			{pageLoad && <Loader />}
		</Grid>
	)
}
