import { Grid } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import useStyles from './jobs/jobs_styles';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import './paginationDetails.css';

export function PaginationDetails({
	page,
	pageSize,
	paginationData,
	listLength,
	tableType,
	handlepageSizeChange,
	handlePageChange
}: any) {
	const classes = useStyles();


	return (
		<Grid className="table-footer" container item xs={12}>
			<Grid
				item
				xs={tableType === 'ML_ModelFeatureDrifts' ? 4 : 3}
				className={tableType === 'ML_ModelFeatureDrifts' ? `${classes.jobText} customPaginationText` : tableType === 'project-list' ? `${classes.jobTextProject}` : classes.jobText}
			>
				Showing {(page * parseInt(pageSize) - parseInt(pageSize) + 1).toLocaleString()} -{' '}
				{page * parseInt(pageSize) > paginationData.totalElements
					? paginationData.totalElements.toLocaleString()
					: (page * parseInt(pageSize)).toLocaleString()}{' '}
				of {listLength ? paginationData.totalElements.toLocaleString() : 0} results
			</Grid>
			<Grid item xs={tableType === 'ML_ModelFeatureDrifts' ? 0 : 2} />
			{tableType !== 'ML_ModelFeatureDrifts' ? (
				<Grid item xs={2} className={tableType === 'project-list' ? classes.jobTextProject1 : classes.jobText1}>
					Rows per Page:
					<Select labelId="pageSize" id="pageSize" value={pageSize} onChange={handlepageSizeChange}>
						<MenuItem value={100}>100</MenuItem>
						<MenuItem value={200}>200</MenuItem>
						<MenuItem value={500}>500</MenuItem>
						<MenuItem value={1000}>1000</MenuItem>
						<MenuItem value={1000}>{paginationData.totalPages}</MenuItem>
					</Select>
				</Grid>
			) : (
				''
			)}
			<Grid item xs={tableType === 'ML_ModelFeatureDrifts' ? 7 : 5}>
				<Grid className="darkPagination">
					<Stack direction="row" spacing={2}>
						<Pagination
							count={paginationData.totalPages}
							page={Number(page)}
							onChange={handlePageChange}
							shape="rounded"
							showFirstButton
							showLastButton
						/>
					</Stack>
				</Grid>
			</Grid>
		</Grid>
	);
}
