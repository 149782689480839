import React, { useEffect, useState, useRef } from 'react';
import {
	Grid,
	Stack,
	Button,
	Menu,
	TextField,
	FormControlLabel,
	List,
	ListItem,
	ListItemText,
	Tooltip
} from '@mui/material';
import { getDatabricksJobs, databricksJobSearch, getDecodedToken, getDatabricksSectors, getDatabricksJobsDate, getWorkspaceList } from '../../../service/service';
import { dashboardUrl,databricksurl } from '../../../service/config';
import AnalyticsVisualization from '../../framework/analyticsVisualization';
import type { Column } from 'react-data-grid';
import { CustomizedDataGrid } from '../../customized_data_grid';
import { useSearchParams } from 'react-router-dom';
import './DatabricksJobs.scss';
import Loader from '../../Loader/loader';
import moment from 'moment-timezone';
import { ReactComponent as DatePickerIcon } from '../../../assets/calendar-outline_white.svg';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { getDatabricksJobsChart } from '../../../service/service';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import useStyles from '../../jobs/jobs_styles';
import { ReactComponent as FilterIcon } from '../../../assets/ml_summary_filter.svg';
import { ReactComponent as Downarrow } from '../../../assets/down-arrow.svg';
import axios from 'axios';
const DatabrickJobs: React.FC = () => {

	const messagesEndRef = useRef<null | HTMLDivElement>(null);
	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
	}
	interface Row {
		project_name: string;
		workspace_name: string;
		cluster_name: string;
		job_name: string;
		runTimeM: number;
		status: string;
	//	executionTimeInMIn: number;
		job_trigger_type: string;
		job_startDate: string;
	}
	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'project_name',
				name: 'Project Name',
				minWidth: 200,
				formatter(props) {
					return <div title={props.row.project_name} className='has-ellipsis'>{props.row.project_name ? props.row.project_name : '--'}</div>
				},
				width: 200
			},
			{
				key: 'workspace_name',
				name: 'Workspace',
				formatter(props) {
					return <div title={props.row.workspace_name} className='has-ellipsis'>{props.row.workspace_name ? props.row.workspace_name : '--'}</div>
				},
				minWidth: 170,
				width: 100
			},
			{
				key: 'cluster_name',
				name: 'Cluster Name',
				formatter(props) {
					return <div title={props.row.cluster_name} className='has-ellipsis'>{props.row.cluster_name ? props.row.cluster_name : '--'}</div>
				},
				minWidth: 170,
				width: 100
			},
			{
				key: 'job_name',
				name: 'Job Name',
				formatter(props) {
					return <div title={props.row.job_name} className='has-ellipsis'>{props.row.job_name ? props.row.job_name : '--'}</div>
				},
				minWidth: 170,
				width: 250
			},
			{
				key: 'runTimeM',
				name: 'Job Run Time',
				minWidth: 150,
				width: 200
			},
			{
				key: 'status',
				name: 'Job Status',
				formatter(props) {
					return <div title={props.row.status} className='has-ellipsis'>{props.row.status ? props.row.status : 'In Progress'}</div>
				},
				minWidth: 150,
				width: 200
			},
			// {
			// 	key: 'executionTimeInMIn',
			// 	name: 'Execution Time',
			// 	minWidth: 150,
			// 	width: 200
			// },
			{
				key: 'job_trigger_type',
				name: 'Trigger Type',
				minWidth: 150,
				width: 200
			},
			{
				key: 'job_startDate',
				name: 'Start Time',
				minWidth: 150,
				width: 200,
				formatter(props) {
					const startDate = props.row.job_startDate;
					if (startDate) {
						return moment(startDate).utc().format('MM-DD-YY HH:mm:ss');
					}
					return '';
				}
			}
		];
	}
	const defaultColumnNames = [
		'Project Name',
		'Workspace',
		'Cluster Name',
		'Job Name',
		'Job Run Time',
		'Job Status',
		'Execution Time',
		'Trigger Type',
		'Start Time'
	];
	const dataFetchedRef = useRef(false);
	const [databricksJobList, setDatabricksJobList] = useState<Row[]>([]);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);

	const [filterData, setFilterData] = useState<Row[]>([]);
	const [searchDetails, setFilterSearchInfo] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [filterInfo, setFilterInfo] = useState([]);
	const [drillDownStatusfilterInfo, setDrilldownStatusFilterInfo] = useState([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const hasDrillDowned = searchParams.has('isDrillDowned');
	const minJobExecDate = '2023-03-01';
	const dateFrom: string = searchParams.get('fromDate')?.substring(0, 10) as string;
	const dateTo: string = searchParams.get('toDate')?.substring(0, 10) as string;
	const startDt = moment(dateFrom).startOf('day').subtract(1, 'hours').format('YYYY-MM-DD HH:mm');
	const endDt = moment(dateTo).endOf('day').subtract(1, 'hours').format('YYYY-MM-DD HH:mm');
	const [fromDateTime, setFromDateTime] = useState<any>(
		searchParams.has('fromDate') ? startDt : null
	);
	const [toDateTime, setToDateTime] = useState<any>(
		searchParams.has('toDate') ? endDt : null
	);

	// sector filter
	const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
	const classes = useStyles();
	const [sectorOptionsList, setSectorOptionsList] = useState<any[]>([]);
	const [filterMenu, setFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	const [itemsChecked, setItemsChecked] = useState(false);
	const [checkedList, setCheckedList] = useState<any[]>([]);
	const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);
	const [sharedValue, setSharedValue] = useState('');
	const [pastMonths, setPastMonths] = useState<{ label: string; value: string; }[]>([]);
	const generatePastMonths = () => {
		const currentDate = moment();
		const months = [];
		months.push({ label: 'This Month', value: currentDate.format('MMM') });
		setMonthFilter(currentDate.format('MMM'));
		for (let i = 1; i < 7; i++) {
			const pastMonth = currentDate.clone().subtract(i, 'months');
			months.push({ label: pastMonth.format('MMM \'YY'), value: pastMonth.format('MMM') });
    	}
    	setPastMonths(months);
  		};
		useEffect(() => {
			generatePastMonths();
		}, []);
	
	const [monthFilter, setMonthFilter] = useState('');
	const updateSharedValue = (newValue: any) => {
		setSharedValue(newValue);
	};

	const filterClick = (event: any) => {
		event.preventDefault();
		setFilterMenu(
			filterMenu === null
				? {
					mouseX: parseInt(event.clientX) - 20,
					mouseY: parseInt(event.clientY) - 6
				}
				: null
		);
	};
	const handleClose = (event: any) => {
		setFilterMenu(null);
	};
	const selectAllCheck = (e: any) => {
		const { checked } = e.target;
		const collection: any[] = [];
		if (checked) setCheckedList(sectorOptionsList);
		else setCheckedList([]);
		setItemsChecked(checked);
	};
	const handleChange = (e: any) => {
		const { value, checked } = e.target;
		const newlyChecked = [];
		if (checked && e.target.value) {
			newlyChecked.push(e.target.value);
		}
		if (checked) {
			setCheckedList([...checkedList, ...newlyChecked]);
			if(sectorOptionsList.length === (checkedList.length + newlyChecked.length)){
				setItemsChecked(true);
			}
		} else {
			setCheckedList(checkedList.filter((item) => item !== value));
			setItemsChecked(false);
		}
	};

	const handleOkClick = (event: any) => {		
	
		if (sectorOptionsList.length == checkedList.length) {
			setCheckedList([])
			localStorage.setItem('dj-summary-sector-filter', JSON.stringify([]));
		} else {
			localStorage.setItem('dj-summary-sector-filter', JSON.stringify(checkedList));
		}
		
		if (checkedList.length > 0) {
			setCurrSectorFilter(checkedList);
		} else {
			setCurrSectorFilter([]);
		}
		fetchWorkspaceList();
		databricksJobListingFunc(
			page,
			pageSize,
			sortingInfo,
			fromDateTime,
			fromDateTime,
			[],
			checkedList
		);
		// updateSharedValue({ sectorFilter: checkedList });
		handleClose(event);
	};
	async function fetchSectorOptionsData() {
		const sectorRes = await getDatabricksSectors('1', '100', '');
		if (sectorRes.success) {
			setSectorOptionsList(
				sectorRes.data.sector.map((x: any) => {
					if (x.name === 'S&T') {
						return 'SNT';
					}
					else if (x.name === 'Europe') {
						return 'EUROPE';
					}
					else if (x.name === 'Global') {
						return 'GLOBAL';
					}
					else {
						return x.name;
					}
				})
			);
		}
	}

	const handlePageChange = (event: any, value: number) => {
		setLoading(true);
		let nextPage = value.toString(); 
		if(event.target.dataset.testid == "LastPageIcon" || event.target.dataset.testid == "FirstPageIcon"){
			nextPage = value.toString(); 
		}
		else if(event.target.dataset.testid == "NavigateBeforeIcon"){
			nextPage = (Number(page) - 1).toString();
		}
		else if (nextPage === event.target.textContent) { 
			nextPage = event.target.textContent;
		} else {
			nextPage = (Number(page) + 1).toString();
		}
		const promise1 = databricksJobListingFunc(
			parseInt(nextPage),
			pageSize,
			sortingInfo,
			fromDateTime,
			fromDateTime,
			parseFilter(filterInfo),
			checkedList
		).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

	const handlepageSizeChange = (event: any) => {
		setLoading(true);
		const promise1 = databricksJobListingFunc(
			'1',
			event.target.value,
			sortingInfo,
			fromDateTime,
			fromDateTime,
			parseFilter(filterInfo),
			checkedList
		).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

	const handleSorting = (sortingInfo: any) => {
		setLoading(true);
		const promise1 = databricksJobListingFunc(
			page,
			pageSize,
			sortingInfo,
			fromDateTime,
			fromDateTime,
			parseFilter(filterInfo),
			checkedList
		).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

	// [Date Range Menu]
	const [dateRangeMenuOpen, setDateRangeMenuOpen] = React.useState<null | HTMLElement>(null);
	const [jobClusterFailure, setJobClusterFailure] = useState<String[]>([]);

	const [selectedValueCluster, setSelectedValueCluster] = useState('');
	const [filterValueCluster, setFilterValueCluster] = useState('');
	const [jobClusterFailureAvailable, setJobClusterFailureAvailable] = useState(false);

	const handleFilterChangeCluster = (event: any) => {
		setFilterValueCluster(event.target.value);
	};

	const handleSelectCluster = (workspace: any) => {
		setSelectedValueCluster(workspace);
		setFilterMenuRight(null);
	};

	const filteredCluster = jobClusterFailure.filter((name) =>
		name.toLowerCase().includes(filterValueCluster.toLowerCase())
	);

	const [filterMenuRight, setFilterMenuRight] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);

	const filterClickRight = (event: any) => {
		event.preventDefault();
		setFilterMenuRight(
			filterMenuRight === null
				? {
					mouseX: parseInt(event.clientX) - 20,
					mouseY: parseInt(event.clientY) - 7
				}
				: null
		);
	};

	const handleCloseRight = (event: any) => {
		setFilterMenuRight(null);
	};

	const handleDateClick = (event: React.MouseEvent<HTMLElement>) => {
		setDateRangeMenuOpen(event.currentTarget);
	};

	const handleDateRangeMenu = () => {
		setDateRangeMenuOpen(null);
	};

	const open = Boolean(dateRangeMenuOpen);

	const resetSearchParams = () => {

		if (searchParams.has('fromDate') || searchParams.has('fromDate')) {
			searchParams.delete('status');
			searchParams.delete('scheduleDate');
			searchParams.delete('fromDate');
			searchParams.delete('fromDate');
			setSearchParams(searchParams);
		} else if (hasDrillDowned) {
			console.log(searchParams, 'searchParams=');
			// searchParams.delete('status');
			// searchParams.delete('isDrillDowned');
			setSearchParams(searchParams);
		}
	};

	const handleDateTimeChange = (field: any, dateTime: any) => {
		
		if (field === 'fromDateTime') setFromDateTime(dateTime);
		// else setFromDateTime(dateTime);
		localStorage.setItem('processedDate', moment(dateTime).format('YYYY-MM-DD'));
		applyDateTimeRange(dateTime);
	};
	const applyDateTimeRange = (fromDateTime: any) => {
		resetSearchParams();
		setLoading(true);
		fetchWorkspaceList();
		// reset column filter when date is applied
		const promise1 = databricksJobListingDateFunc(
			"1",
			pageSize,
			sortingInfo,
			fromDateTime,
			fromDateTime,
			[],
			checkedList,
			drillDownStatusfilterInfo
		).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		setDateRangeMenuOpen(null);
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};
	const handleFilter = (filterInfo: any) => {
		// resetSearchParams();
		setFilterInfo(filterInfo);
		setLoading(true);
		const promise1 = databricksJobListingFunc(1, pageSize, sortingInfo, fromDateTime, fromDateTime, parseFilter(filterInfo), checkedList, drillDownStatusfilterInfo)
			.catch((error) => {
				setLoading(false);
				setError(error); // Set error state when API call fails
			});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
			console.log('filter', promise1);
		});
	};

	async function jobSearchForDatabrcksFunc(searchDetails: any, columnKey: any) {
		const res = await databricksJobSearch(searchDetails, columnKey, fromDateTime, parseFilter(filterInfo), checkedList, drillDownStatusfilterInfo);
		if (res.success) {
			setFilterSearchInfo(res.data);
		}
	}

	const handleSearch = (searchDetails: any, columnKey: any) => {
		console.log(searchDetails, columnKey)
		if(searchDetails?.length >= 3)
		jobSearchForDatabrcksFunc(searchDetails, columnKey);
	};

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

	// initial load API call
	async function databricksJobListingFunc(
		pageNumber: any,
		size: any,
		sortingInfo: any,
		fromDateTime: any,
		toDateTime: any,
		jobFilterInfo: any,
		sector: any,
		drillDownStatusFilter?: any
	) {
		setLoading(true);
	
		try {
			// Check if there's a stored sector filter in localStorage
			let storedSectorFilter = localStorage.getItem('dj-summary-sector-filter')
				? JSON.parse(localStorage.getItem('dj-summary-sector-filter') ?? '')
				: null;
			if (storedSectorFilter) {
				sector = storedSectorFilter;
			}
	
			// Make a call to Databricks
			const databricksresponse = await axios.get(`${databricksurl}`);
		
			console.log('Databricks Call initiated', databricksresponse);
	
			if (databricksresponse && databricksresponse.status) {
			const res = await getDatabricksJobs(
				pageNumber.toString(),
				size.toString(),
				sortingInfo,
				jobFilterInfo,
				fromDateTime,
				fromDateTime,
				sector,
				drillDownStatusFilter
			);

			if (res.success) {
				setTimeout(() => {
					console.log('scroll to table - ', hasDrillDowned)
					if (hasDrillDowned) {
						scrollToBottom()
					}
				}, 1000);
				if (res.data.data !== null) {
					const addDollar = res.data.data.map((item: any) => ({
						...item,
						runTimeM: item.runTimeM !== null && item.runTimeM !== undefined
								? formatRunTime(item.runTimeM)
								: '0',
						executionTimeInMIn: item.executionTimeInMIn !== null && item.executionTimeInMIn !== undefined
							? `${item.executionTimeInMIn.toFixed(2)}`
							: null
					}));

					setDatabricksJobList(addDollar);
					setPaginationData(res.data.page);
					setFilterData(res.data.filterOptions);
					setFilterInfo(jobFilterInfo);
					setPage(pageNumber);
					setPageSize(size);
					setFromDateTime(localStorage.getItem("processedDate"));
					setSortingInfo(sortingInfo);
						setError(null);
					}
				} else {
					setError('Failed to fetch data'); // Set the error state if API call fails
				}
			} else {
				setError('Cluster Inactive');
			}
		} catch (error) {
			setError('Failed to fetch data'); // Set the error state if API call fails
		}
		setLoading(false);
	}
	

	// User select specific date API call
	async function databricksJobListingDateFunc(
		pageNumber: any,
		size: any,
		sortingInfo: any,
		fromDateTime: any,
		toDateTime: any,
		jobFilterInfo: any,
		sector: any,
		drillDownStatusfilterInfo?: any
	) {
		setLoading(true);
		try {
			const res = await getDatabricksJobsDate(
				pageNumber.toString(),
				size.toString(),
				sortingInfo,
				jobFilterInfo,
				fromDateTime,
				fromDateTime,
				sector,
				drillDownStatusfilterInfo
			);


			if (res.success) {
				console.log('this function will trigger from date selection only');
				if (res.data.data !== null) {
					const addDollar = res.data.data.map((item: any) => ({
						...item,
						runTimeM: `${item.runTimeM !== null && item.runTimeM !== undefined ? formatRunTime(item.runTimeM) : 0}`,
						executionTimeInMIn: `${item.executionTimeInMIn !== null && item.executionTimeInMIn !== undefined
							? `${item.executionTimeInMIn.toFixed(2)}`
							: null
							}`
					}));
					console.log('jobsres', res)
					setDatabricksJobList(addDollar);
					setPaginationData(res.data.page);
					setFilterData(res.data.filterOptions);
					setFilterInfo(jobFilterInfo);
					setPage(pageNumber);
					setPageSize(size);
					setSortingInfo(sortingInfo);
				}
			} else {
				setError('Failed to fetch data'); // Set the error state if API call fails
			}
		} catch (error) {
			setError('Failed to fetch data'); // Set the error state if API call fails
		}
		setLoading(false);
	}

	console.log("databricksJobList", databricksJobList)

	function formatRunTime(num: any) {
		let hours: any = Math.floor(num / 60);
		let minutes: any = num % 60;
		if (num > 0) {
			return (hours ? parseInt(hours) > 1 ? hours + ' hrs ' : hours + ' hr' : '') + ' ' + (minutes ? parseInt(minutes) > 1 ? minutes.toFixed(1) + ' mins ' : minutes.toFixed(1) + ' min' : '');
		} else {
			return 0
		}
	}
	
	async function fetchClusterFailure() {
		const res = await getDatabricksJobsChart(fromDateTime);
		if (res.success) {
			if (!localStorage.getItem('processedDate')) {
				setFromDateTime(moment(res.data.processedDate));
				localStorage.setItem('processedDate', res.data.processedDate);
			} else {
				setFromDateTime(moment(localStorage.getItem('processedDate')));
			}
			console.log("data", res.data.processedDate)
		}
	}

	async function fetchWorkspaceList() {
		const fromDate = localStorage.getItem('processedDate') ? localStorage.getItem('processedDate') : moment(fromDateTime).format('YYYY-MM-DD');
		let storedSectorFilter = localStorage.getItem('dj-summary-sector-filter')
			? JSON.parse(localStorage.getItem('dj-summary-sector-filter') ?? '')
			: currSectorFilter;
		const res = await getWorkspaceList(fromDate, storedSectorFilter);
		const workspace_name = res.data.workspace_name;
		const workspaceList = ['All Workspaces', ...workspace_name];
		setJobClusterFailure(workspaceList);
		if (workspaceList.length > 0) {
			setJobClusterFailureAvailable(true);
		} else {
			setJobClusterFailureAvailable(false);
		}
	}

	useEffect(() => {
		fetchClusterFailure();
		fetchWorkspaceList();
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		if (userSector === 'all') fetchSectorOptionsData();
		let storedSectorFilter = localStorage.getItem('dj-summary-sector-filter')
			? JSON.parse(localStorage.getItem('dj-summary-sector-filter') ?? '')
			: null;
		if (storedSectorFilter) {
			setCheckedList(storedSectorFilter);
			setCurrSectorFilter(storedSectorFilter);
		}
		//Grafana DrillDown
		let filterQuerySkeleton = {} as any;
		const _status = searchParams.get('status');
		console.log(_status, "_status==");
		console.log(hasDrillDowned, 'hasDrillDown==')
		if (hasDrillDowned) {
			switch (_status) {
				case 'SuccessfulJobs':
					filterQuerySkeleton.successfulJobs = 'true';
					break;
				case 'FailedJobs':
					console.log("failedJobs=")
					filterQuerySkeleton.failedJobs = 'true';
					break;
				case 'CanceledJobs':
					filterQuerySkeleton.canceledJobs = 'true';
					break;
				case 'NotExecutedJobs':
					filterQuerySkeleton.notExecutedJobs = 'true';
					break;
				case 'ExecutedJobs':
					filterQuerySkeleton.executedJobs = 'true';
					break;
				case 'InprogressJobs':
					filterQuerySkeleton.inprogressJobs = 'true';
					break;
				case 'LongRunningCompletedJobs':
					filterQuerySkeleton.completedJobs = 'true';
					break;
				case 'LongRunningInProgressJobs':
					filterQuerySkeleton.progressJobs = 'true';
					break;
				default:
					break;
			}
		} else {
			// getColumns().forEach((column: any) => {
				filterQuerySkeleton = {};
			// });
		}
		console.log(filterQuerySkeleton, 'filterQuerySkeleton')
		setDrilldownStatusFilterInfo(filterQuerySkeleton);
		if (hasDrillDowned) {
			console.log(filterQuerySkeleton, 'filterQuerySkeletonDrilll')
			databricksJobListingFunc(page, pageSize, sortingInfo, fromDateTime, fromDateTime, parseFilter(filterInfo), checkedList, parseFilter(filterQuerySkeleton));
		} else {
			databricksJobListingFunc(page, pageSize, sortingInfo, fromDateTime, fromDateTime, parseFilter(filterInfo), checkedList, {}).catch(
				(error) => {
					setError(error); // Set error state when API call fails during initial load
				}
			);
		}
		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/databricks/jobs/dashboard') {
			if (activeId) {
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('databricks-jobs');
				document.body.classList.add('datefilter-align-sector');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				activeId.classList.remove('jobs-list-table');
				activeId.classList.remove('jobs-list');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.remove('jobs-exe-datas');
			}
		}
	}, []);

	const truncateText = (text: string, maxLength: number) => {
		if (text.length > maxLength && text !== "All Workspaces") {
			return text.slice(0, maxLength) + '...';
		}
		return text;
	};

	let fromDate = moment(fromDateTime).format('YYYY-MM-DD');
	let toDate = moment(toDateTime).format('YYYY-MM-DD');
	return (
		<div className="dashboard-panel job-schedules-wrap">
			<div className="job-exexutions-dateFilter-div">
				{/* [Date Range Menu] */}
				<div style={{ display: 'flex', alignItems: 'center', padding: '-12px', marginTop: '-22px' }}>
					{/* condition for default Today  */}
					{/* {moment(fromDateTime).format('DD MM YYYY') ===
						moment().startOf('day').subtract(1, 'hours').format('DD MM YYYY') &&
						 <div style={{ marginRight: '10px' }}>Today</div>} */}
					{<LocalizationProvider className="outerDateContainer" dateAdapter={AdapterMoment}>
						<DatePicker
							showToolbar={true}
							components={{
								OpenPickerIcon: DatePickerIcon
							}}
							maxDate={new Date()}
							PopperProps={{ placement: 'auto' }}

							className="dateFilterTD"

							value={moment(fromDateTime).tz('America/New_York')}

							onChange={(dateTime) => {
								handleDateTimeChange('fromDateTime', dateTime);
							}}
							renderInput={(params: any) => <TextField name="startDateTime" {...params} />}
						/>
					</LocalizationProvider>
					}
				</div>

				{/* Code for sector filter */}
				<Stack>
					{userSector === 'all' ? (
						<FilterIcon onClick={filterClick} className="sector-filter1" />
					) : (
						<div className="separeat_sector">Sector: {getDecodedToken()?.sector?.name}</div>
					)}
					<Popover
						open={filterMenu !== null}
						onClose={handleClose}
						className="filterContainer"
						anchorReference="anchorPosition"
						anchorPosition={filterMenu !== null ? { top: filterMenu.mouseY, left: filterMenu.mouseX } : undefined}
					>
						<div className="text-filter-row">
							<div className="selectAll-sec custom-select-options">
								<MenuItem className="selectAll">
									<FormControlLabel
										className={classes.checkbox}
										label="All"
										control={
											<Checkbox
												color="success"
												value="all"
												checked={itemsChecked}
												onChange={(e) => {
													selectAllCheck(e);
												}}
											/>
										}
									/>
								</MenuItem>
								<div className="checkbox-sec">
									{sectorOptionsList &&
										sectorOptionsList.map((filter: any) => (
											<MenuItem key={filter}>
												<FormControlLabel
													className={classes.checkbox}
													label={filter === true ? 'Yes' : filter === false ? 'No' : filter}
													control={
														<Checkbox
															color="success"
															value={filter}
															checked={
																(filter === true && checkedList.includes('true')) ||
																(filter === false && checkedList.includes('false')) ||
																checkedList.includes(filter)
															}
															onChange={(e) => {
																handleChange(e);
															}}
														/>
													}
												/>
											</MenuItem>
										))}
								</div>
							</div>
							<div className="buttonSec">
								<Button variant="contained" className={classes.cancelBtn} onClick={handleClose}>
									cancel
								</Button>
								<Button variant="contained" className={classes.okBtn} onClick={handleOkClick}>
									ok
								</Button>
							</div>
						</div>
					</Popover>
				</Stack>
			</div>
			{/* <div className="dashboard-panel"> */}
			{location.pathname === '/databricks/jobs/dashboard' && (
				// Grafana
				<>
					<Stack>
						<AnalyticsVisualization
							params={{
								dashboardUrl: dashboardUrl.dataBricksjobs,
								className: 'dataBricksJobsIframeContainer',
								otherParams: {
									'var-fromDate': fromDate,
									'var-toDate': toDate,
									'var-sector': currSectorFilter
								},
								seamless: true
							}}
						/>
					</Stack>
					<div className="iframe-wrapper">
						<div className="left-iframe-div">
							<Stack>
							<div className="month-dropdown-wrap">
								<select className='month-dropdown' onChange={(e) => { console.log(e.target.value); setMonthFilter(e.target.value); }}>
									{pastMonths.map(month => (
										<option key={month.value} value={month.value}>{month.label}</option>
										))}
								</select>
							</div>
								<AnalyticsVisualization
									params={{
										dashboardUrl: dashboardUrl.dataBricksjobsWorkspaceFailure,
										className: 'dataBricksJobsFailureIframeContainer',
										otherParams: {
											'var-fromDate': fromDate,
											'var-toDate': toDate,
											'var-sector': currSectorFilter,
											'var-month': monthFilter
										},
										seamless: true
									}}
								/>
							</Stack>
						</div>
						<div className="right-iframe-div">
							<Stack>
								<div>
									{jobClusterFailureAvailable && jobClusterFailure.length > 0 && (
										<Tooltip title={selectedValueCluster || 'All Workspaces'} placement="top">
											<div onClick={filterClickRight} className="sectors-filterRight">
												{truncateText(selectedValueCluster || 'All Workspaces', 10)}
												<span>
													<Downarrow className='right-down-arrow' />
												</span>
											</div>
										</Tooltip>
									)}
									<Popover
										open={filterMenuRight !== null}
										onClose={handleCloseRight}
										className="clusterFailureFilter"
										anchorReference="anchorPosition"
										anchorPosition={
											filterMenuRight !== null ? { top: filterMenuRight.mouseY, left: filterMenuRight.mouseX } : undefined
										}
									>
										<div className="text-filter-row">
											<div className="selectAll-sec custom-select-options">
												<div>
													<input
														value={filterValueCluster}
														onChange={handleFilterChangeCluster}
														placeholder="Search..."
														className="selectAll-sec-input" // Add the class name to the input
													/>
												</div>
												<div className="checkbox-sec">
													<List>
														{filteredCluster.map((workspace, index) => (
															<ListItem
																key={index}
																onClick={() => handleSelectCluster(workspace)}
																className="Popover-listItem" // Add the class name to the ListItem component
															>
																<ListItemText primary={workspace} />
															</ListItem>
														))}
													</List>
												</div>
											</div>
										</div>
									</Popover>
								</div>
								<AnalyticsVisualization
									params={{
										dashboardUrl: dashboardUrl.dataBricksjobsClusterFailure,
										className: 'dataBricksJobsFailureIframeContainer',
										otherParams: {
											'var-filterCluster': selectedValueCluster,
											'var-fromDate': fromDate,
											'var-toDate': toDate,
											'var-sector': currSectorFilter
										},
										seamless: true
									}}
								/>
							</Stack>
						</div>
					</div>
				</>
			)}

			<div className="react-divJobs jobs-table-wrap">
				<div ref={messagesEndRef} />
				<p className="databricks_title">Job Details</p>
				{error == 'Cluster Inactive' ? (
					// Display error message or handle error condition in UI
					<div
						style={{
							color: '#e5e5e5',
							marginLeft: '25%',
							padding: '10px',
							fontWeight: 500,
							gridColumn: '1 / -1'
						}}
					>
						Cluster is Inactive, it will take Max 2mins to start, Please try again later
					</div>
				) :error? (
					// Display error message or handle error condition in UI
					<div
						style={{
							color: '#e5e5e5',
							marginLeft: '25%',
							padding: '10px',
							fontWeight: 500,
							gridColumn: '1 / -1'
						}}
					>
						Error occurred while fetching data. Please try again.
					</div>
				) : (
					<Grid className="databricks_jobs_tabledata">
						{!loading ? (<>
							<CustomizedDataGrid
								tableColumns={getColumns()}
								tableData={databricksJobList}
								tableType="DBJobs"
								defaultColumnNames={defaultColumnNames}
								onSorting={handleSorting}
								currSorting={sortingInfo}
								filterDataOptions={filterData}
								onFilterCheck={handleFilter}
								currFilter={filterInfo}
								searchDataValues={searchDetails}
								onFilterSearch={handleSearch}
								page={page}
								pageSize={pageSize}
								paginationData={paginationData}
								listLength={databricksJobList?.length}
								handlepageSizeChange={handlepageSizeChange}
								handlePageChange={handlePageChange}
								isDateRangeFilter={true}
								isDisabled={false}
							/>
							{databricksJobList?.length ? <p className="disclaimer">Note: Double hyphens (--) indicate that the field has no data.  Job Run Time field - hr or min will display where number is less than 2 and hrs or mins will display where number is greater than or equal to 2.</p> : ''}
							</>
						) : (
							<Loader />	
						)}
					</Grid>
				)}
			</div>
		</div>
	);
};
export default DatabrickJobs;
