import { Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Popover, Select, Stack, Tooltip } from '@mui/material';

import ReactECharts from 'echarts-for-react';

import '../DataEstate/data-estate-dashboard.scss';
import { useEffect, useRef, useState } from 'react';
import { fetchAnnualSpendDataSourceIncidents, fetchDataProcessingTrends, fetchDatabricksClusters, fetchDataprocessingSummary, fetchPerformanceData, getDecodedToken, getSector, getDBDatabricks, dataProcessingTable, fetchDatabricksClustersCount } from '../../service/service';
import moment from 'moment';
import useStyles from '../jobs/jobs_styles';
import { ReactComponent as CalendarIcon } from '../../assets/calendar-outline_white.svg';
import { ReactComponent as FilterIcon } from '../../assets/ml_summary_filter.svg';
import Loader from '../Loader/loader';

import ArrowUpBlackIcon from '../../assets/up-arrow-black.svg';
import FilterGreenDot from '../../assets/filter_applied_green_dot.svg';
import { getPercentageOfTwoNumbers, formatNumberWithCommas } from '../../utils/common-util';
import { ReactComponent as Downarrow } from '../../assets/down-arrow.svg';
import '../DataEstate/data-estate.scss';
import { CustomizedDataGrid } from '../customized_data_grid';
import { Row, type Column } from 'react-data-grid';
import { getSectorSql } from '../../service/service';
import { isEnvTypePROD } from '../../service/config';
import NoPrgramFound from '../../assets/no-program-found.svg';
import ToolTipIcon from "../../assets/info-icon.svg";

export default function dataProcessingSummary() {

	const [ADFPipeline, SetADFPipeline] = useState(0);
	const [azureSqlUptime, SetAzureSqlUptime] = useState('-');
	const [synapseUptime, SetSynapseUptime] = useState('-');
	const [teradataJobs, SetTeradataJobs] = useState(0);
	const [teradataJobsLastExecution, SetTeradataJobsLastDec] = useState(0);
	const [teradataJobsCurrent, SetTeradataJobsCurrent] = useState(0);


	const [databricksClusters, SetDatabricksClusters] = useState(0);
	const [databricksClustersLastExecution, SetDatabricksClustersLastExecution] = useState(0);
	const [databricksClustersCurrent, SetDatabricksClustersCurrent] = useState(0);
	const [databricksJobExecutionTimeLoader, SetDatabricksJobExecutionTimeLoader] = useState(true);
	const [databricksJobExecutionTimeChartOptions, SetDatabricksJobExecutionTimeChartOptions] = useState({});
	const [teradataJobExecutionTimeLoader, SetTeradataJobExecutionTimeLoader] = useState(true);
	const [teradataJobExecutionTimeChartOptions, SetSetTeradataJobExecutionTimeChartOptions] = useState({});
  
	const [pipelineGrowthLoading, setPipelineGrowthLoading] = useState(true);
	const [pipelineGrowthOptions, setPipelineGrowthOptions] = useState<any>()
	const [clusterGrowthLoading, setClusterGrowthLoading] = useState(true);
  	const [clusterGrowthOptions, setClusterGrowthOptions] = useState<any>()
	const [teradataJobsLoading, setTeradataJobsLoading] = useState(true);
	const [teradataJobsOptions, setTeradataJobsOptions] = useState<any>()

	// sector filter
	const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
	const [filterMenu, setFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	
	const [dateFilterMenu, setDateFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	
	const classes = useStyles();
	const [itemsChecked, setItemsChecked] = useState(false);
	const [sectorOptionsList, setSectorOptionsList] = useState<any[]>([]);
	const [checkedList, setCheckedList] = useState<any[]>([]);
	const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);
	const [sector, setCurrSector] = useState<any[]>([]);
	const [filterMonth, setFilterMonth] = useState("0M");
	const [filterDefaultMonth, setFilterDefaultMonth] = useState("0M");
	const [customYear, setCustomYear] = useState(moment().format("YYYY"));
	const [isDateOpen, setIsDateOpen] = useState(false);
	const isCustomMonth = useRef(false);
	const dataFetchedRef = useRef(false);
	const [loading, setLoading] = useState(false);
	// server filter
	const [dateFilter, setDateFilter] = useState<any>('Apr 2024');

	const [adfpipelineBubbleValue, SetAdfpipelineBubbleValue] = useState(0)
	const [teradataBubbleValue, SetTeradataBubbleValue] = useState(0)
	const [databricksBubbleValue, SetDatabricksBubbleValue] = useState(0)

	const [adfpipelineLastDec, SetAdfpipelineLastDec] = useState(0);
	const [adfpipelineCurrent, SetAdfpipelineCurrent] = useState(0);
	const [adfpipelineStartMonth, setAdfpipelineStartMonth] = useState('');
	const [isNoAnnualAdfpipeline, setIsNoAnnualAdfpipeline] = useState(false);

	const [teradataJobStartMonth, setTeradataJobStartMonth] = useState('');
	const [isNoAnnualTeradataJob, setIsNoAnnualTeradataJob] = useState(false);

	let dimension: any = '';
	const [customMonth, setCustomMonth] = useState('');
	const [isDetailVisible, setIsDetailVisible] = useState(false);

	// Server filter code
	const handleDateFilter = (field: any, selectedDate: any) => {		
		selectedDate = selectedDate.target.value
		if (field === 'dateFilter') {
			isCustomMonth.current = false;
			setCustomMonth('');
			setFilterMonth(selectedDate);
			if (selectedDate === "2023-01") {
				isCustomMonth.current = true;
				setIsDateOpen(true)
				return;
			}
			setFilterDefaultMonth(selectedDate);
		}
		applyDateFilter(selectedDate);
	};

	const handleMonthOnclick = (value:any) => {
		isCustomMonth.current = false;
		setIsDateOpen(false);
		const selectedDate = customYear + "-" + value;
		setCustomMonth(selectedDate);
		applyDateFilter(selectedDate);
		setDateFilterMenu(null);
	}
	const handleYearAdd = () => {
		let selectedYear = Number(customYear);
		selectedYear++;
		setCustomYear(selectedYear.toString());
	}
	const handleYearMinus = () => {
		let selectedYear = Number(customYear);
		selectedYear--;
		setCustomYear(selectedYear.toString());
	}
	const handleCustomOnclick = (e:any) => {
		if (e.target.textContent == 'Custom Month') {
			isCustomMonth.current = true;
			dateFilterMenuClick(e);
		}
		if(isCustomMonth.current)
			setIsDateOpen(true);
		else
			setIsDateOpen(false);

		if (e.type == 'click' && e.target.className.includes('MuiModal-backdrop')) {
			isCustomMonth.current = false;
			setDateFilterMenu(null);
			setIsDateOpen(false);
			if (customMonth == '') {
				setFilterMonth(filterDefaultMonth)
			}
		}
	}

	const applyDateFilter = (selectedDate: any) => {
    	getDataProcessingSummary(selectedDate, checkedList);
		loadDatabricksClustersCount(selectedDate)
		setDateFilter(selectedDate);
	};
	const dateFilterMenuClick = (event: any) => {
		setDateFilterMenu(
			dateFilterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 260,
						mouseY: parseInt(event.clientY) - 50
				  }
				: null
		);
	};
	const handleDateFilterMenuClose = (event: any) => {
		setDateFilterMenu(null);
	};
	const sectorFilterClick = (event: any) => {
		event.preventDefault();
		setFilterMenu(
			filterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 20,
						mouseY: parseInt(event.clientY) - 6
				  }
				: null
		);
	};

	const handleSectorClose = (event: any) => {
		setFilterMenu(null);
	};

	const selectAllSectorCheck = (e: any) => {
		const { checked } = e.target;
		const collection: any[] = [];
		if (checked) setCheckedList(sectorOptionsList);
		else setCheckedList([]);
		setItemsChecked(checked);
	};

	const handleSectorChange = (e: any) => {
		const { value, checked } = e.target;
		const newlyChecked = [];
		if (checked && e.target.value) {
			newlyChecked.push(e.target.value);
		}
		if (checked) {
			setCheckedList([...checkedList, ...newlyChecked]);
			if(sectorOptionsList.length === (checkedList.length + newlyChecked.length)){
				setItemsChecked(true);
			}
		} else {
			setCheckedList(checkedList.filter((item) => item !== value));
			setItemsChecked(false);
		}
	};
	const handleSectorOkClick = (event: any) => {
		localStorage.setItem('selectedSector', JSON.stringify(checkedList)); // Tableau drilldown filter changes
		if (checkedList.length > 0) {
			setCurrSectorFilter(checkedList);
		} else {
			setCurrSectorFilter([]);
		}
    console.log(checkedList)
		setCurrSector(checkedList);
		getDataProcessingSummary(filterMonth, checkedList);
		handleSectorClose(event);
	};

	async function fetchSectorOptionsData() {
		const sectorRes = await getSector('1', '100', '');
		if (sectorRes.success) {
			const filteredSectorOptions = sectorRes.data.sector.filter((x: any) => x.name !== 'S&T').map((x: any) => x.name);
			filteredSectorOptions.push('Blank');
        	setSectorOptionsList(filteredSectorOptions);
		}
	}

	async function getDataProcessingSummary(selectedDate?: string, sectors?: any) {
		let sector = sectors.length == sectorOptionsList.length ? [] : sectors;
		setLoading(true);
		SetDatabricksJobExecutionTimeLoader(true);
		SetTeradataJobExecutionTimeLoader(true);
		fetchDataprocessingSummary(selectedDate, sector).then(res => {
			SetADFPipeline(res?.data?.dataPipelineCount);
			SetAzureSqlUptime(res?.data?.azureSqlUptimeCount[0]?.VALUE);
			SetSynapseUptime(res?.data?.synapseUptimeCount[0]?.VALUE);
      		setLoading(false);
		}).catch(error => { 
			console.error(error);
		});
    
		fetchAnnualSpendDataSourceIncidents('teradata-jobs', selectedDate, sector).then(res => {
			console.log("RESS", res)
			SetTeradataJobs(res?.data?.result[0]?.count);
		}).catch(error => { 
			console.error(error);
		});
    
		const month = filterMonth?.split('/')[0];
		const year = '20'+filterMonth?.split('/')[1];
		const date = year+'-'+month+'-'+'01';
		const months = [moment(date).startOf('month').format('YYYY-MM-DD')]
		
		// fetchDatabricksClusters('databricks-clusters', months, sector, selectedDate).then(res => {
		// 	SetDatabricksClusters((res?.data?.totalClustersArray[0]?.count/1000).toFixed(2).toString());
		// }).catch(error => { 
		// 	console.error(error);
		// });
    
		fetchPerformanceData('teradata', selectedDate, sector).then(res => {
			SetTeradataJobExecutionTimeLoader(false);
			let teradataOption = {
				backgroundColor: '#003771',
				series: [
				{
					type: 'gauge',
					center: ['50%', '45%'],
					min: 0,
					max: Math.floor(((res?.data?.result[0]?.avg_execution)) * 1.1),
					splitNumber: 5,
					itemStyle: {
					color: '#e5e5e5'
					},
					progress: {
					show: false
					},
					pointer: {
					show: false
					},
					axisTick: {
					distance: -45,
					splitNumber: 8,
					lineStyle: {
						width: 0,
						color: '#999'
					}
					},
					splitLine: {
					distance: -30,
					length: 14,
					lineStyle: {
						width: 0,
						color: '#999'
					}
					},
					axisLabel: {
					distance: -15,
					color: '#999',
					fontSize: 11,
					formatter: (value: any) => {
						return `${Math.floor(value)}`
					}
					},
					anchor: {
					show: false
					},
					title: {
					show: false
					},
					detail: {
					valueAnimation: true,
					width: '60%',
					lineHeight: 40,
					borderRadius: 8,
					offsetCenter: [0, 30],
					fontSize: 30,
					formatter: `${Math.floor(res?.data?.result[0]?.avg_execution)}`,
					color: '#e5e5e5'
					},
					data: [
					{
						value: res?.data?.result[0]?.avg_execution
					}
					]
				},
				{
					type: 'gauge',
					center: ['50%', '45%'],
					min: 0,
					max: Math.floor(res?.data?.result[0]?.avg_execution * 1.1),
					itemStyle: {
					color: '#FD7347'
					},
					progress: {
					show: true,
					width: 10
					},
					pointer: {
					show: false
					},
					axisLine: {
					show: false
					},
					axisTick: {
					show: false
					},
					splitLine: {
					show: false
					},
					axisLabel: {
					show: false
					},
					detail: {
					valueAnimation: true,
					width: '60%',
					lineHeight: 40,
					borderRadius: 8,
					offsetCenter: [0, '-30%'],
					fontSize: 14,
					fontWeight: 'normal',
					formatter: 'Avg Execution',
					color: '#e5e5e5'
					},
					data: [
					{
						value: res?.data?.result[0]?.avg_execution
					}
					]
				}
				]
			};
			SetSetTeradataJobExecutionTimeChartOptions(teradataOption);
		}).catch(error => { 
			console.error(error);
		});    
    
		fetchDataProcessingTrends('adfpipelinegrowth', selectedDate, sector).then(res => {
			if(res.data) {
				let allDataAsArray = Object.entries(res.data);
				const allMonths = Object.keys(res.data);

				const firstMonthFromAllMonths = allMonths[0];
				const lastMonth = moment().subtract(1, 'months').format('MM');
				const indexValue = moment().year() + '-' + lastMonth;
				const lastIndex = allMonths.indexOf(indexValue);
				const lastMonthFromAllMonths = allMonths[lastIndex];
				const lastMonthFromForecast = allMonths[allMonths.length - 1];

				let currentMonth = new Date().getMonth();
				if (currentMonth == 0)
					currentMonth = 12
				const currentYear = new Date().getFullYear();
				const lastMonthString = (currentMonth).toString().padStart(2, '0');
				const filterMonth = `${currentYear-1}-${lastMonthString}` 
				const filterDateEntry = Object.entries(res.data).filter(
					x => x[0] === filterMonth
				);
				if(filterDateEntry.length) {
					let count: any = 0;
					count = filterDateEntry[0][1];
					const lastMonthDataIndex = `${currentYear}-${lastMonthString}`;
					const lastMonthEntry = Object.entries(res.data).filter(
							x => x[0] === lastMonthDataIndex
					);
					if (lastMonthEntry.length){
						setIsNoAnnualAdfpipeline(false);	
						const lastmonthvalue = lastMonthEntry[0][1] as number;
						SetAdfpipelineCurrent(lastmonthvalue)
						SetAdfpipelineLastDec(count)
						SetAdfpipelineBubbleValue(getPercentageOfTwoNumbers(count, lastmonthvalue));
					}
				}
				else
				{
					const annualMonth = moment(firstMonthFromAllMonths, 'YYYY-MM').format('MMMM YYYY');
					setAdfpipelineStartMonth('Data available from ' + annualMonth);
					setIsNoAnnualAdfpipeline(true);	
				}


				const pastMonths = Object.entries(allDataAsArray).filter(([date]) => new Date(date).getMonth()+1 <= currentMonth).splice(4,moment().month()-3);
				
				// if (pastMonths.length) {
				// 	let count: any = 0;
				// 	Object.values(res.data).filter((c) => { if (c && !count) { count = c; } });
				// 	SetAdfpipelineBubbleValue(getPercentageOfTwoNumbers(count, pastMonths[pastMonths.length - 1][1][1]));
				// }

				const option = {
					title: {
						show: Object.keys(allDataAsArray).length == 0 ? true : false,
						textStyle:{
						color:'#bcbcbc',
						fontWeight: 'normal',
						fontSize: 16
						},
						text: 'No data for the selected filters',
						left: 'center',
						top: 'center'
					},
					grid: {
						top: '13%',
						left: '10%',
						right: '2%',
						bottom: '12%'
					},
					backgroundColor: '#003771',
					xAxis: {
						type: 'category',
						boundaryGap: false,
						splitLine: { 
							show: true, 
							lineStyle: { 
								type: 'dashed',
								color: '#004477'
							}
						},
					data: allMonths,
						axisLabel: {
							rotate: 30,
							color: '#A7ADCB',
							formatter: function (value: any) {
								return `${moment(value).format('MMM-YY')}`;
							}
						},
					},
					yAxis: {
						type: 'value',
						splitLine: { 
							show: true, 
							lineStyle: { 
								type: 'dashed',
								color: '#004477'
							}
						},
						axisLabel: {
							color: '#A7ADCB',
							formatter: function (value: any) {
								return `${(value/1000).toFixed(0)}k`;
							}
							
						},
					},		
					visualMap: {
						show: false,
						dimension: 0,
						pieces: [
						{
							lte: lastIndex,
							color: '#009BD8'
						},
						{
							gt: lastIndex,
							lte: allMonths.length - 1,
							color: '#FD783B'
						}
						]
					},
					series: [
						{
						name: 'Current Growth',
						type: 'line',
						data: allDataAsArray,
						markArea: {
							itemStyle: {
							color: 'rgba(255, 173, 177, 0.1)'
							},
							data: [
							[
								{
								name: 'Current Growth',
								xAxis: firstMonthFromAllMonths
								},
								{
								xAxis: lastMonthFromAllMonths
								}
							],
							[
								{
								name: 'Forecast',
								xAxis: moment().format('YYYY-MM')
								},
								{
								xAxis: lastMonthFromForecast
								}
							]
							]
						}
						}
					],
					tooltip: {
						trigger: 'axis',
						formatter: (params: { name: any; seriesName: any; value: any; }[]) => {
							const { name, seriesName, value } = params[0]; 
							return `${moment(value[0]).format('MMM-YY')}: ${formatNumberWithCommas((parseFloat(value[1]) / 1000).toFixed(2),true)}K`; 
						},
						backgroundColor: '#053367',
						textStyle: {
							color: '#A7ADCB' 
						}
					},
				};
				setPipelineGrowthOptions(option);
				setPipelineGrowthLoading(false);
			}
		}).catch(error => { 
			console.error(error);
		});   
    
		fetchDataProcessingTrends('teradataJobsgrowth', selectedDate, sector).then(res => {
			if(res.data) {
				let allDataAsArray = Object.entries(res.data);
				const allMonths = Object.keys(res.data);

				const firstMonthFromAllMonths = allMonths[0];
				const lastMonth = moment().subtract(1, 'months').format('MM');
				const indexValue = moment().year() + '-' + lastMonth;
				const lastIndex = allMonths.indexOf(indexValue);
				const lastMonthFromAllMonths = allMonths[lastIndex];
				const lastMonthFromForecast = allMonths[allMonths.length - 1];

				let currentMonth = new Date().getMonth();
				if (currentMonth == 0)
					currentMonth = 12
				const currentYear = new Date().getFullYear();
				const pastMonths = Object.entries(allDataAsArray).filter(
					([date]) => new Date(date).getMonth() + 1 <= currentMonth
				);
				const lastMonthString = (currentMonth).toString().padStart(2, '0');
				const filterMonth = `${currentYear-1}-${lastMonthString}` 				
				const filterDateEntry = Object.entries(res.data).filter(
					x => x[0] === filterMonth
				);
				if (filterDateEntry.length) {
					let count: any = 0;
					count = filterDateEntry[0][1];
					const lastMonthDataIndex = `${currentYear}-${lastMonthString}`;
					const lastMonthEntry = Object.entries(res.data).filter(
						x => x[0] === lastMonthDataIndex
					);
					if (lastMonthEntry.length){
						setIsNoAnnualTeradataJob(false);
						const lastmonthvalue = lastMonthEntry[0][1] as number;
						SetTeradataJobsCurrent(lastmonthvalue)
						SetTeradataJobsLastDec(count)
						SetTeradataBubbleValue(getPercentageOfTwoNumbers(count, lastmonthvalue));
					}
				}
				else
				{
					const annualMonth = moment(firstMonthFromAllMonths, 'YYYY-MM').format('MMMM YYYY');
					setTeradataJobStartMonth('Data available from ' + annualMonth);
					setIsNoAnnualTeradataJob(true);	
				}

				const option = {
					title: {
						show: Object.keys(allDataAsArray).length == 0 ? true : false,
						textStyle:{
						color:'#bcbcbc',
						fontWeight: 'normal',
						fontSize: 16
						},
						text: 'No data for the selected filters',
						left: 'center',
						top: 'center'
					},
					grid: {
						top: '13%',
						left: '10%',
						right: '2%',
						bottom: '12%'
					},
					backgroundColor: '#003771',
					xAxis: {
						type: 'category',
						boundaryGap: false,
						splitLine: { 
							show: true, 
							lineStyle: { 
								type: 'dashed',
								color: '#004477'
							}
						},
					data: allMonths,
						axisLabel: {
							rotate: 30,
							color: '#A7ADCB',
							formatter: function (value: any) {
								return `${moment(value).format('MMM-YY')}`;
							}
						},
					},
					yAxis: {
						type: 'value',
						splitLine: { 
							show: true, 
							lineStyle: { 
								type: 'dashed',
								color: '#004477'
							}
						},
						axisLabel: {
							color: '#A7ADCB',
							formatter: function (value: any) {
								return `${(value/1000).toFixed(0)}k`;
							}
							
					},
					},		
					visualMap: {
						show: false,
						dimension: 0,
						pieces: [
						{
							lte: lastIndex,
							color: '#009BD8'
						},
						{
							gt: lastIndex,
							lte: allMonths.length - 1,
							color: '#FD783B'
						}
						]
					},
					series: [
						{
						name: 'Current Growth',
						type: 'line',
						data: allDataAsArray,
						markArea: {
							itemStyle: {
							color: 'rgba(255, 173, 177, 0.1)'
							},
							data: [
							[
								{
								name: 'Current Growth',
								xAxis: firstMonthFromAllMonths
								},
								{
								xAxis: lastMonthFromAllMonths
								}
							],
							[
								{
									name: 'Forecast',
									xAxis: moment().format('YYYY-MM')
								},
								{
									xAxis: lastMonthFromForecast
								}
							]
							]
						}
						}
					],
					tooltip: {
						trigger: 'axis',
						formatter: (params: { name: any; seriesName: any; value: any; }[]) => {
							const { name, seriesName, value } = params[0]; 
							return `${moment(value[0]).format('MMM-YY')}: ${formatNumberWithCommas((parseFloat(value[1]) / 1000).toFixed(2),true)}K`; 
						},
						backgroundColor: '#053367',
						textStyle: {
							color: '#A7ADCB' 
						}
					},
				};
				setTeradataJobsOptions(option);
				setTeradataJobsLoading(false);
			}
		}).catch(error => { 
			console.error(error);
		}); 
    
		// const monthList = ["2024-01-30", "2024-02-28", "2024-03-31", "2024-04-30", "2024-05-30", "2024-06-09"];

		// Databricks Cluster
		fetchDataProcessingTrends('clustergrowth', selectedDate, sector).then(res => {	
			if(res.data) {
				let allDataAsArray = Object.entries(res.data);
				const allMonths = Object.keys(res.data);

				const firstMonthFromAllMonths = allMonths[0];
				const lastMonth = moment().subtract(1, 'months').format('MM');
				const indexValue = moment().year() + '-' + lastMonth;
				const lastIndex = allMonths.indexOf(indexValue);
				const lastMonthFromAllMonths = allMonths[lastIndex];
				const lastMonthFromForecast = allMonths[allMonths.length - 1];
				
				// let allMonths = data.map((clust: any) => moment(clust.Inst_Date).format('yyyy-MM'));
				// let allDataAsArray: any = [];
				// data.filter((cluster: any) => {
				// 	allDataAsArray.push([moment(cluster.Inst_Date).format('yyyy-MM'), (cluster.Job+cluster.Interactive+cluster.SqlWarehouse)])
				// });
				// let mayMonth = 0, juneMonth = 0, kpiValue = 0;
				// for (let i = 0; i < data.length; i++){
				// 	kpiValue += data[i].count;
				// }
				// mayMonth = kpiValue / 4;
				// data.push({month: '2024-07-30', count: mayMonth});
				// kpiValue = 0;
				// for (let i = 1; i < data.length; i++){
				// 	kpiValue += data[i].count;
				// }
				// juneMonth = (kpiValue + mayMonth) / 4;
				// data.push({month: '2024-08-30', count: juneMonth});
				// // Transform data into echarts series format
				// let chartData: any = []
				// data.filter((d: any) => {
				// 	chartData.push([d.month, d.count])
				// });
				
				// const currentMonth = new Date().getMonth();
				// const pastMonths = data.filter((d: any) => new Date(d.month).getMonth()+1 <= currentMonth);
				// if (pastMonths.length) {
				// 	let count: any = 0;
				// 	pastMonths.filter((c: any) => { if (c.count && !count) { count = c.count; } });
				// 	SetDatabricksBubbleValue(getPercentageOfTwoNumbers(count, pastMonths[pastMonths.length - 1].count));
				// }

				const option = {
					title: {
						show: Object.keys(allDataAsArray).length == 0 ? true : false,
						textStyle:{
						color:'#bcbcbc',
						fontWeight: 'normal',
						fontSize: 16
						},
						text: 'No data for the selected filters',
						left: 'center',
						top: 'center'
					},
					backgroundColor: '#003771',
					grid: {
						top: '13%',
						left: '10%',
						right: '2%',
						bottom: '12%'
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						splitLine: { 
							show: true, 
							lineStyle: { 
								type: 'dashed',
								color: '#004477'
							}
						},
					data: allMonths,
						axisLabel: {
							rotate: 30,
							color: '#A7ADCB',
							formatter: function (value: any) {
								return `${moment(value).utc().format('MMM-YY')}`;
							}
						},
					},
					yAxis: {
						type: 'value',
						splitLine: { 
							show: true, 
							lineStyle: { 
								type: 'dashed',
								color: '#004477'
							}
						},
						axisLabel: {
							color: '#A7ADCB',
							formatter: function (value: any) {
								return `${(value/1000).toFixed(0)}k`;
							}
					},
					},			
					visualMap: {
						show: false,
						dimension: 0,
						pieces: [
						{
							lte: lastIndex,
							color: '#009BD8'
						},
						{
							gt: lastIndex,
							lte: allMonths.length - 1,
							color: '#FD783B'
						}
						]
					},
					series: [
						{
						name: 'Current Growth',
						type: 'line',
						data: allDataAsArray,
						markArea: {
							itemStyle: {
							color: 'rgba(255, 173, 177, 0.1)'
							},
							data: [
							[
								{
								name: 'Current Growth',
								xAxis: firstMonthFromAllMonths
								},
								{
									xAxis: lastMonthFromAllMonths
								}
							],
							[
								{
								name: 'Forecast',
								xAxis: moment().format('YYYY-MM')
								},
								{
								xAxis: lastMonthFromForecast
								}
							]
							]
						}
						}
					],
					tooltip: {
						trigger: 'axis',
						formatter: (params: { name: any; seriesName: any; value: any; }[]) => {
							const { name, seriesName, value } = params[0]; 
							return `${moment(value[0]).format('MMM-YY')}: ${formatNumberWithCommas((parseFloat(value[1]) / 1000).toFixed(2),true)}K`; 
						},
						backgroundColor: '#053367',
						textStyle: {
							color: '#A7ADCB' 
						}
					}
				};
				setClusterGrowthOptions(option);
				setClusterGrowthLoading(false);
			}
		}).catch(error => { 
			console.error(error);
		});
	}

	// fetchPerformanceData('databricks', selectedDate, sector).then(res => {
	async function getDatabricksExecutionData(exeCount: number) {
		SetDatabricksJobExecutionTimeLoader(false);
		let databricksOption = {
			backgroundColor: '#003771',
			series: [
			{
				type: 'gauge',
				center: ['50%', '45%'],
				min: 0,
				max: Math.floor(exeCount * 1.1),
				splitNumber: 5,
				itemStyle: {
				color: '#e5e5e5'
				},
				progress: {
				show: false
				},
				pointer: {
				show: false
				},
				axisTick: {
				distance: -55,
				splitNumber: 8,
				lineStyle: {
					width: 0,
					color: '#999'
				}
				},
				splitLine: {
				distance: -30,
				length: 14,
				lineStyle: {
					width: 0,
					color: '#999'
				}
				},
				axisLabel: {
				distance: -13,
				color: '#999',
				fontSize: 12,
				formatter: (value: any) => {
					return Math.floor(value)
				}
				},
				anchor: {
				show: false
				},
				title: {
				show: false
				},
				detail: {
				valueAnimation: true,
				width: '60%',
				lineHeight: 40,
				borderRadius: 8,
				offsetCenter: [0, 30],
				fontSize: 30,
				formatter: Math.floor(exeCount),
				color: '#e5e5e5'
				},
				data: [
				{
					value: Math.floor(exeCount)
				}
				]
			},
			{
				type: 'gauge',
				center: ['50%', '45%'],
				min: 0,
				max: Math.floor(exeCount * 1.1),
				itemStyle: {
				color: '#FD7347'
				},
				progress: {
				show: true,
				width: 10
				},
				pointer: {
				show: false
				},
				axisLine: {
				show: false
				},
				axisTick: {
				show: false
				},
				splitLine: {
				show: false
				},
				axisLabel: {
				show: false
				},
				detail: {
				valueAnimation: true,
				width: '60%',
				lineHeight: 40,
				borderRadius: 8,
				offsetCenter: [0, '-30%'],
				fontSize: 14,
				fontWeight: 'normal',
				formatter: 'Avg Execution',
				color: '#e5e5e5'
				},
				data: [
				{
					value: exeCount
				}
				]
			}
			]
		};
		SetDatabricksJobExecutionTimeChartOptions(databricksOption);
	// }).catch(error => { 
	// 	console.error(error);
	// });
	};

	async function loadDatabricksAnnualIndicator() {
		let startDate = moment().subtract(1, 'months').startOf('month').format('yyyy-MM-DD');
		let endDate = moment().subtract(1, 'months').endOf('month').format('yyyy-MM-DD');
		
		try {
			const res = await fetchDatabricksClustersCount(startDate, endDate);
			if (res.success) {
				if (res.data.databricksClustersCount.length) {
					let currentYearCount = res.data.databricksClustersCount.reduce((count: any, item: any) => count + item.SqlWarehouse + item.Job + item.Interactive, 0);
					loadDatabricksLastYearData(currentYearCount);
					SetDatabricksClustersCurrent(currentYearCount);
				}
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	}

	async function loadDatabricksLastYearData(currentYearCount: number) {
		let lastyearStartDate = moment().subtract(1, 'year').subtract(1, 'months').startOf('month').format('yyyy-MM-DD');
		let lastYearEndDate = moment().subtract(1, 'year').subtract(1, 'months').format('yyyy-MM-DD');

		// let lastyearStartDate = moment().subtract(1, 'year').startOf('year').format('yyyy-MM-DD');
		// let lastYearEndDate = moment().subtract(1, 'year').endOf('year').format('yyyy-MM-DD');

		try {
			const res = await fetchDatabricksClustersCount(lastyearStartDate, lastYearEndDate);
			if (res.success) {
				if (res.data.databricksClustersCount.length) {
					let lastYearCount = res.data.databricksClustersCount.reduce((count: any, item: any) => count + item.SqlWarehouse + item.Job + item.Interactive, 0);
					SetDatabricksClustersLastExecution(lastYearCount);
					SetDatabricksBubbleValue(getPercentageOfTwoNumbers(lastYearCount, currentYearCount));
				}
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	}
	
	async function loadDatabricksClustersCount(selectedDateFilter?: string) {
		console.log(selectedDateFilter)
		let startDate: any;
		let endDate: any;
		if (selectedDateFilter === '0M') {
			startDate = moment().startOf('month').format('yyyy-MM-DD');
			endDate = moment().format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '1M') {
			startDate = moment().subtract(1, 'months').startOf('month').format('yyyy-MM-DD');
			endDate = moment().subtract(1, 'months').endOf('month').format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '3M') {
			startDate = moment().subtract(2, 'months').startOf('month').format('yyyy-MM-DD');
			endDate = moment().endOf('month').format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '6M') {
			startDate = moment().subtract(5, 'months').startOf('month').format('yyyy-MM-DD');
			endDate = moment().endOf('month').format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '9M') {
			startDate = moment().subtract(8, 'months').startOf('month').format('yyyy-MM-DD');
			endDate = moment().endOf('month').format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '0Y') {
			startDate = moment().subtract(0, 'year').startOf('year').format('yyyy-MM-DD');
			endDate = moment().endOf('year').format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '1Y') {
			startDate = moment().subtract(1, 'year').startOf('year').format('yyyy-MM-DD');
			endDate = moment().subtract(1, 'year').endOf('year').format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '2Y') {
			startDate = moment().subtract(1, 'year').startOf('year').format('yyyy-MM-DD');
			endDate = moment().endOf('year').format('yyyy-MM-DD');
		}
		setLoading(true);
		try {
			const res = await fetchDatabricksClustersCount(startDate, endDate);
			if (res.success) {
				if (res.data.databricksClustersCount.length) {
					let count = res.data.databricksClustersCount.reduce((count: any, item: any) => count + item.SqlWarehouse + item.Job + item.Interactive, 0);
					SetDatabricksClusters(count);
					let exeCount = res.data.databricksClustersCount.reduce((count: any, item: any) => count + item.job_avg_exe + item.sql_avg_exe + item.interactive_avg_exe, 0);
					getDatabricksExecutionData(exeCount)
				} else {
					SetDatabricksClusters(0);
				}
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	}

  useEffect(() => {
	
	const activeId = document.getElementById('root');
	if (window.location.pathname === '/dataestate/data-processing') {
		if (activeId) {
			document.head.classList.add('theme-bg-blue');
			document.body.classList.add('theme-bg-blue');
			document.body.classList.add('custom-class');
			activeId.classList.add('jobs-list-table');
			activeId.classList.remove('jobs-list');
			activeId.classList.remove('root');
			document.body.classList.add('jobs-exe-datas');
			document.body.classList.remove('jobs-run-table');
			document.body.classList.add('jobs-list-datas');
			document.body.classList.add('datefilter-align-sector');
			document.body.classList.add('sector-label');
		}
	} else {
		if (activeId) {
			document.body.classList.remove('jobs-list-datas');
			activeId.classList.add('root');
			document.body.classList.add('dashboard-panel-dataestate');
			document.body.classList.add('datefilter-align-sector');
		}
	}
		if (userSector === 'all') fetchSectorOptionsData();
		getDataProcessingSummary(filterMonth, sector);
		loadDatabricksClustersCount();
		loadDatabricksAnnualIndicator();
	}, []);
	const [tableName, setTableName] = useState('');
	interface ADF {
		azureSubscription: string;
		resourceGroup: string;
		pipelineName: string;
		pipelineStatus: string;
		runStart: string;
		runEnd: string;
		durationInMs: string;
		lastUpdated: string;
	}
	interface teraDataJobs {
		jobName: string;
		boxName: string;
		sector: string;
		system: string;
		process: string;
		critical: string;
		lastSuccessfulRun: string;
		normalCompletionTime: string;
		observedSince: string;
	}
	interface dataBricks {
		workspace_name: string;
		cluster_name: string;
		cluster_category: string;
		total_dbus: string;
		project_name: string;
		job_name: string;
		timestamp_state_start: string;
		timestamp_state_end: string;
		sector_name: string;
	}
	interface Row { };
	function getColumns(): Array<Column<Row>> {
		if (typeName == 'ADF') {
			return [
				{
					key: 'azureSubscription',
					name: 'Azure Subscription',
					minWidth: 250
				},
				{
					key: 'resourceGroup',
					name: 'Resource Group',
					minWidth: 200
				},
				{
					key: 'pipelineName',
					name: 'Pipeline Name',
					minWidth: 200
				},
				{
					key: 'pipelineStatus',
					name: 'Pipeline Status',
					minWidth: 250
				},
				{
					key: 'runStart',
					name: 'Run Start',
					minWidth: 200
				},
				{
					key: 'runEnd',
					name: 'Run End',
					minWidth: 200
				},
				{
					key: 'durationInMs',
					name: 'Duration In MS',
					minWidth: 250
				},
				{
					key: 'lastUpdated',
					name: 'Last Updated',
					minWidth: 250
				},
			];
		}
		else if (typeName == 'TeraData') {
			return [
				{
					key: 'jobName',
					name: 'Job Name',
					minWidth: 180
				},
				{
					key: 'boxName',
					name: 'Box Name',
					minWidth: 180
				},
				{
					key: 'sector',
					name: 'Sector',
					minWidth: 170
				},
				{
					key: 'system',
					name: 'System',
					minWidth: 180
				},
				{
					key: 'process',
					name: 'Process',
					minWidth: 180
				},
				{
					key: 'critical',
					name: 'Critical',
					minWidth: 180
				},
				{
					key: 'lastSuccessfulRun',
					name: 'Last successful run',
					minWidth: 250
				},
				{
					key: 'normalCompletionTime',
					name: 'Normal completion Time',
					minWidth: 250
				},
				{
					key: 'observedSince',
					name: 'Observed since',
					minWidth: 250
				},
			];
		}
		else if (typeName == 'dataBricks') {
			return [
				{
					key: 'workspace_name',
					name: 'Project name',
					minWidth: 250
				},
				{
					key: 'cluster_name',
					name: 'Workspace',
					minWidth: 250
				},
				{
					key: 'cluster_category',
					name: 'Cluster name',
					minWidth: 250
				},
				{
					key: 'total_dbus',
					name: 'Job Name',
					minWidth: 250
				},
				{
					key: 'project_name',
					name: 'Job Run Time',
					minWidth: 250
				},
				{
					key: 'job_name',
					name: 'Job Status',
					minWidth: 250
				},
				{
					key: 'timestamp_state_start',
					name: 'Timestamp State Start',
					minWidth: 170,
				},
				{
					key: 'timestamp_state_end',
					name: 'Timestamp State End',
					minWidth: 170,
				},
				{
					key: 'sector_name',
					name: 'Start Time',
					minWidth: 170,
				},
			];
		}
		else {
			return [];
		}
	}
	const ADFPipelinesColumns = ['Azure Subscription', 'Resource Group', 'Pipeline Name', 'Pipeline Status', 'Run Start', 'Run End', 'Duration In MS', 'Last Updated']
	const teraDataJobsColumns = ['Job Name', 'Box Name', 'Sector', 'System', 'Process', 'Critical', 'Last successful run', 'Normal completion Time', 'Observed since']
	const dataBricksColumns = ['Project name', 'Workspace', 'Cluster name', 'Job Name', 'Job Run Time', 'Job Status', 'Timestamp State End', 'Timestamp State End', 'Start Time'];
	const [defaultColumnNames, setDynamicValue] = useState(['']);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [isTableVisible, setIsTableVisible] = useState(false);

	const [tableData, setTableData] = useState<Row[]>([]);
	const [tableHeading, setTableHeading] = useState('');
	const [typeName, setTypeName] = useState('');

	async function processingListingFunc(pageNumber: any, size: any, sortingInfo: any) {
		let month = customMonth ? customMonth : filterMonth;
		console.log(dimension,'dimension');
		const res = await dataProcessingTable('dataProcessing', dimension, sector, month);
		if (res.success) {
			console.log(res.data, 'resdata')
			setTableData(res.data.summary);
			if (res.data.page) {
				setPaginationData(res.data.page);
			}
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
		}
	}

	async function getDBDatabricksData(pageNumber: any, size: any, sortingInfo: any) {
		let startDate: any;
		let endDate: any;
		if (filterMonth === '0M') {
			startDate = moment();
			endDate = moment();
		}
		if (filterMonth === '1M') {
			startDate = moment().subtract(1, 'months').startOf('month');
			endDate = moment().endOf('month');
		}
		if (filterMonth === '3M') {
			startDate = moment().subtract(3, 'months').startOf('month');
			endDate = moment().endOf('month');
		}
		if (filterMonth === '6M') {
			startDate = moment().subtract(6, 'months').startOf('month');
			endDate = moment().endOf('month');
		}
		if (filterMonth === '9M') {
			startDate = moment().subtract(9, 'months').startOf('month');
			endDate = moment().endOf('month');
		}
		if (filterMonth === '0Y') {
			startDate = moment().subtract(0, 'year').startOf('year');
			endDate = moment().endOf('year');
		}
		if (filterMonth === '1Y') {
			startDate = moment().subtract(1, 'year').startOf('year');
			endDate = moment().endOf('year');
		}
		if (filterMonth === '2Y') {
			startDate = moment().subtract(2, 'year').startOf('year');
			endDate = moment().endOf('year');
		}
		console.log(endDate, 'endDate')
		console.log(startDate, 'startDate')
		let sector: any = [];
		let filterInfo: any = [];

		const res = await getDBDatabricks(pageNumber, size, sortingInfo, filterInfo, startDate, endDate, sector);

		if (res.success) {
			console.log(res.data, 'resdata')
			setTableData(res.data.result);
			if (res.data.page) {
				setPaginationData(res.data.page);
			}

			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
		}
	}

	const handleSorting = (sortingInfo: any) => {
		processingListingFunc(page, pageSize, sortingInfo);
	};
	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		processingListingFunc(value, pageSize, sortingInfo);
	};
	const handlepageSizeChange = (event: any) => {
		processingListingFunc(page, event.target.value, sortingInfo);
	};
	const handleClick = (typeName: any) => {
		setDynamicValue([]);
		setTableData([]);
		setIsTableVisible(true);
		if (typeName == 'ADF') {
			console.log("default before", defaultColumnNames)
			setDynamicValue(ADFPipelinesColumns);
			setTableHeading("ADF Pipelines");
			type Row = keyof ADF;
			setTypeName('ADF')
			dimension = 'adfpipeline'
			console.log("default", defaultColumnNames)
			processingListingFunc(page, pageSize, sortingInfo)
		}
		if (typeName === 'Teradata') {
			console.log("default before", defaultColumnNames)
			setDynamicValue(teraDataJobsColumns);
			setTableHeading("TeraData Jobs");
			type Row = keyof teraDataJobs;
			setTypeName('TeraData')
			dimension = 'teradataJobs'
			console.log("default", defaultColumnNames)
			processingListingFunc(page, pageSize, sortingInfo)
		}
		if (typeName === 'dataBricks') {
			console.log("default before", defaultColumnNames)
			setDynamicValue(dataBricksColumns);
			setTableHeading("Databricks clusters");
			type Row = keyof teraDataJobs;
			setTypeName('dataBricks')
			console.log("default", defaultColumnNames)
			getDBDatabricksData(page, pageSize, sortingInfo)
		}

	}

	const clearMetricsSummary = () => {
		// setDynamicValue(['']);
		setTableData([]);
		setIsDetailVisible(false);
	}

  return (
    <div className="dataestate-dashboard-wrap">
      { loading && <Loader /> }
      {/* <div className="service-date-filter date-picker-summary"> */}
      <div className="service-date-filter date-picker-summary data-estate-right">
				{/* Code for Date filter */}
				<Stack>
					<div className="date-select">
						<FormControl fullWidth>
							<CalendarIcon className="date-icon" />
							<Select
								name="date-select"
								labelId="demo-simple-select-label"
								id="single-date-select"
								value={filterMonth}
								label="Date"
								onChange={(e) => {
									handleDateFilter('dateFilter', e);
								}}
								classes={{ select: classes.select }}
								IconComponent={() => null}
								open={isDateOpen} 
								onClick={(e) =>handleCustomOnclick(e)}
								onOpen={() => setIsDateOpen(true)}
								onClose={() => setIsDateOpen(false)} 
							>
								<MenuItem value={"0M"}>This Month</MenuItem>
								<MenuItem value={"1M"}>Last Month</MenuItem>
								<MenuItem value={"3M"}>Last 3 Months</MenuItem>
								<MenuItem value={"6M"}>Last 6 Months</MenuItem>
								<MenuItem value={"9M"}>Last 9 Months</MenuItem>
								<MenuItem value={"0Y"}>This Year</MenuItem>
								<MenuItem value={"1Y"}>Last Year</MenuItem>
								<MenuItem value={"2Y"}>Last 2 Years</MenuItem>
								<MenuItem value={"2023-01"}>Custom Month</MenuItem>
							</Select>
						</FormControl>
						{isCustomMonth.current && <Popover
								open={dateFilterMenu !== null}
								onClose={handleDateFilterMenuClose}
								className="custom-date-filter-menu"
								anchorReference="anchorPosition"
								anchorPosition={dateFilterMenu !== null ? { top: dateFilterMenu.mouseY, left: dateFilterMenu.mouseX } : undefined}
							><div className="custom-class-date date-custom">
							<div className='custom-dis'>
								<InputLabel>{customYear}</InputLabel>
								<div style={{marginLeft:'60px'}}>
									<Downarrow className='up-arrow' onClick={handleYearAdd}/>
									<Downarrow className='year-arrow' onClick={handleYearMinus}/>
								</div>
							</div>
							<div className='custom-dis'>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("01")}>January</Button>
								<Button  variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("02")}>February</Button>
							</div>
							<div className='custom-dis'>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("03")}>March</Button>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("04")}>April</Button>
							</div>
							<div className='custom-dis'>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("05")}>May</Button>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("06")}>Jun</Button>
							</div>
							<div className='custom-dis'>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("07")}>July</Button>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("08")}>August</Button>
							</div>
							<div className='custom-dis'>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("09")}>September</Button>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("10")}>October</Button>
							</div>
							<div className='custom-dis'>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("11")}>November</Button>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("12")}>December</Button>
							</div>
						</div></Popover>}
					</div>
				</Stack>
			</div>
			{/* <div className="service-sector-filter"> */}
				{/* Code for sector filter */}
				{/* <Stack className="sector-filter-dataestate">
					{userSector === 'all' ? (
						<><span className='sector-cta' onClick={sectorFilterClick}><span className='sector-label'>Sectors</span> <FilterIcon /></span></>
					) : (
						<div className="separeat_sector">Sector: {getDecodedToken()?.sector?.name}</div>
					)}
					<Popover
						open={filterMenu !== null}
						onClose={handleSectorClose}
						className="filterContainer"
						anchorReference="anchorPosition"
						anchorPosition={filterMenu !== null ? { top: filterMenu.mouseY, left: filterMenu.mouseX } : undefined}
					>
						<div className="text-filter-row">
							<div className="selectAll-sec">
								<MenuItem className="selectAll">
									<FormControlLabel
										className={classes.checkbox}
										label="All"
										control={
											<Checkbox
												color="success"
												value="all"
												checked={itemsChecked}
												onChange={(e) => {
													selectAllSectorCheck(e);
												}}
											/>
										}
									/>
								</MenuItem>
								<div className="checkbox-sec">
									{sectorOptionsList &&
										sectorOptionsList.map((filter: any) => (
											<MenuItem key={filter}>
												<FormControlLabel
													className={classes.checkbox}
													label={filter === true ? 'Yes' : filter === false ? 'No' : filter}
													control={
														<Checkbox
															color="success"
															value={filter}
															checked={
																(filter === true && checkedList.includes('true')) ||
																(filter === false && checkedList.includes('false')) ||
																checkedList.includes(filter)
															}
															onChange={(e) => {
																handleSectorChange(e);
															}}
														/>
													}
												/>
											</MenuItem>
										))}
								</div>
							</div>
							<div className="buttonSec">
								<Button variant="contained" className={classes.cancelBtn} onClick={handleSectorClose}>
									cancel
								</Button>
								<Button variant="contained" className={classes.okBtn} onClick={handleSectorOkClick}>
									ok
								</Button>
							</div>
						</div>
					</Popover>
				</Stack>
			</div> */}
      
      <Grid container spacing={0} className="headerIPB mb-10">
        <Grid item xs={8} className="">
          <div className="panel-legend mr-10">
            <h2 className="panel-legend--title">Data Processing</h2>
            <Grid container spacing={0} className="inner-grid-parent">
              <Grid item xs={6} onClick={() => { !isEnvTypePROD ? handleClick('ADF') : '' } }>
                <div className="panel-box mr-10 mb-10">
                  <h2 className="panel-box--title">ADF Pipelines</h2>
                  <div className="panel-box--count" title={`${ADFPipeline}`}>{ ADFPipeline ? <>{formatNumberWithCommas(((ADFPipeline) / 1000).toFixed(2),true)} <span className="conversion">k</span></> : <span className="loading">Loading...</span>}</div>
				</div>
              </Grid>
              
              <Grid item xs={6} className="" onClick={() => { !isEnvTypePROD ? handleClick('Teradata') : '' } }>
                {/* <div className="panel-box mb-10"> */}
                <div className="panel-box mb-10 mh-88">
                  <h2 className="panel-box--title">Teradata Jobs</h2>
                  <div className="panel-box--count" title={`${teradataJobs}`}>{ teradataJobs ?
				   <>{formatNumberWithCommas(((teradataJobs) / 1000).toFixed(2),true)} <span className="conversion">k</span></> :
				   <span className="loading">Loading...</span>}
				   </div>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={0} className="headerIPB">
              <Grid item xs={12} className="">
                <Grid container spacing={0} className="inner-grid-parent">
                  <Grid item xs={6}>
                    <div className="panel-box mr-10 monitor-head">
                      <h2 className="panel-box--title">
						ADF Pipeline Growth with Projections <br /><i>(Trend)</i>
					  		{(isNoAnnualAdfpipeline) && 
								<span className="badge bg-no badge-sec">
									<Tooltip title={isNoAnnualAdfpipeline ? adfpipelineStartMonth : 'Insufficient Data'} arrow>
										<img src={ToolTipIcon} />
									</Tooltip>
								</span>
							}
							{!pipelineGrowthLoading && !isNoAnnualAdfpipeline && (
								<span className="badge badge-sec" title={`Last Year: ${formatNumberWithCommas(adfpipelineLastDec)} - Previous Month: ${formatNumberWithCommas(adfpipelineCurrent)}`}>
									{Math.sign(adfpipelineBubbleValue) == -1 && (
									<>
									<img
									src={ArrowUpBlackIcon}
									className="arrow-icon-styles arrow-icon-styles-up-black down-arrow"
									/>{' '}
									{Math.abs(Math.round(adfpipelineBubbleValue))}% /Yr
									</>
									)}
									{Math.sign(adfpipelineBubbleValue) != -1 && (
									<>
									<img src={ArrowUpBlackIcon} className="arrow-icon-styles arrow-icon-styles-up-black" />{' '}
									{Math.abs(Math.round(adfpipelineBubbleValue))}% /Yr
									</>
									)}
								</span>
						    )}
						</h2>
                      <div className="panel-box--count">
                      {
					  	pipelineGrowthLoading ? <span className="loading">Loading...</span> : <ReactECharts
                        option={pipelineGrowthOptions}
                        theme={{
                          backgroundColor: '#1b1e35'
                        }}
                      />}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} className="">
                    <div className="panel-box monitor-head">
                      <h2 className="panel-box--title">Teradata Jobs Growth with Projections <br /> <i>(Trend)</i>
					  {(isNoAnnualTeradataJob) && 
								<span className="badge bg-no badge-sec">
									<Tooltip title={isNoAnnualTeradataJob ? teradataJobStartMonth : 'Insufficient Data'} arrow>
										<img src={ToolTipIcon} />
									</Tooltip>
								</span>
							}
					  { !teradataJobsLoading && !isNoAnnualTeradataJob && (
						// <span className="badge badge-sec" title={`Last Year: ${teradataJobsLastExecution} - Previous Month: ${teradataJobsCurrent}`}>
						// 	{Math.sign(teradataBubbleValue) == -1 && <><img src={ArrowUpBlackIcon} className="arrow-icon-styles arrow-icon-styles-up-black down-arrow" /> {Math.abs(Math.round(teradataBubbleValue))}% /Yr</> }
						// 	{Math.sign(teradataBubbleValue) != -1 && <><img src={ArrowUpBlackIcon} className="arrow-icon-styles arrow-icon-styles-up-black" /> {Math.abs(Math.round(teradataBubbleValue))}% /Yr</> }
						// </span>
							<span className="badge badge-sec" title={`Last Year: ${formatNumberWithCommas(teradataJobsLastExecution)} - Previous Month: ${formatNumberWithCommas(teradataJobsCurrent)}`}>
									{Math.sign(teradataBubbleValue) == -1 && (
									<>
									<img
									src={ArrowUpBlackIcon}
									className="arrow-icon-styles arrow-icon-styles-up-black down-arrow"
									/>{' '}
									{Math.abs(Math.round(teradataBubbleValue))}% /Yr
									</>
									)}
									{Math.sign(teradataBubbleValue) != -1 && (
									<>
									<img src={ArrowUpBlackIcon} className="arrow-icon-styles arrow-icon-styles-up-black" />{' '}
									{Math.abs(Math.round(teradataBubbleValue))}% /Yr
									</>
									)}
							</span>
					  )}
					  </h2>
                      <div className="panel-box--count">
                      {
					  	teradataJobsLoading ? <span className="loading">Loading...</span> : <ReactECharts
                        option={teradataJobsOptions}
                        theme={{
                          backgroundColor: '#1b1e35'
                        }}
                      />}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </div>
        </Grid>
        <Grid item xs={4} className="">
          <div className="panel-legend">
            <h2 className="panel-legend--title">Compute</h2>
            
            <Grid container spacing={0} className="inner-grid-parent">
              <Grid item xs={12} className="" onClick={() =>{ !isEnvTypePROD ? handleClick('dataBricks') : '' } }>
                {/* <div className="panel-box mb-10"> */}
                <div className="panel-box mb-10 mh-88">
                  <h2 className="panel-box--title">Databricks Clusters</h2>
                  <div className="panel-box--count" title={databricksClusters.toString()}>
				  	{databricksClusters ? <>{formatNumberWithCommas((databricksClusters/ 1000).toFixed(2),true)} <span className="conversion">k</span> </> : 0} {''}
				  </div>
                </div>
              </Grid>
            </Grid>
            
            <Grid container spacing={0} className="inner-grid-parent">
              <Grid item xs={12} className="">
                <div className="panel-box monitor-head">
                  <h2 className="panel-box--title">Databricks Cluster Growth with Projections <br /> <i>(Trend)</i>
				  {!clusterGrowthLoading && 
					<span className="badge badge-sec" title={`Last Year: ${formatNumberWithCommas(databricksClustersLastExecution)} - Previous Month: ${formatNumberWithCommas(databricksClustersCurrent)}`}>
					 	{Math.sign(databricksBubbleValue) == -1 && <><img src={ArrowUpBlackIcon} className="arrow-icon-styles arrow-icon-styles-up-black down-arrow" /> {Math.abs(Math.round(databricksBubbleValue))}% /Yr</> }
					 	{Math.sign(databricksBubbleValue) != -1 && <><img src={ArrowUpBlackIcon} className="arrow-icon-styles arrow-icon-styles-up-black" /> {Math.abs(Math.round(databricksBubbleValue))}% /Yr</> }
					 	</span>
					}
				  </h2>
                  <div className="panel-box--count">
                  {
				  		clusterGrowthLoading ? <span className="loading">Loading...</span> : <ReactECharts
                        option={clusterGrowthOptions}
                        theme={{
                          backgroundColor: '#1b1e35'
                        }}
                      />}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      
      <Grid container spacing={0} className="headerIPB mb-30">
        <Grid item xs={7} className="">
          <div className="panel-legend mr-10">
            <h2 className="panel-legend--title">Performance</h2>
            <Grid container spacing={0} className="inner-grid-parent">
              <Grid item xs={6}>
                <div className="panel-box mr-10 mb-10">
                  <h2 className="panel-box--title mb-0">Databricks Job Execution Time (Sec)</h2>
                  <div className="panel-box--count height-230">
                    { 
					databricksJobExecutionTimeLoader ? <span className="loading">Loading...</span> : <ReactECharts
                      option={databricksJobExecutionTimeChartOptions}
                      theme={{
                          backgroundColor: '#1b1e35'
                      }}
                      />
                    }
                  </div>
                </div>
              </Grid>
              
              <Grid item xs={6} className="">
                <div className="panel-box mb-10">
                  <h2 className="panel-box--title mb-0">Teradata Job Execution Time (Sec)</h2>
                  <div className="panel-box--count height-230">
                    { 
						teradataJobExecutionTimeLoader ? <span className="loading">Loading...</span> : 
                      <ReactECharts
                      option={teradataJobExecutionTimeChartOptions}
                      theme={{
                          backgroundColor: '#1b1e35'
                      }}
                      />
                    }
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={5} className="">
          <div className="panel-legend">
            <h2 className="panel-legend--title">Reliablity</h2>
            
            <Grid container spacing={0} className="inner-grid-parent">
              {/* <Grid item xs={6} className=""> */}
              <Grid item xs={6} className="mh-324">
                {/* <div className="panel-box mb-10 mr-10"> */}
                <div className="panel-box mb-10 mr-10 mh-294">
                  <h2 className="panel-box--title mb-0">Azure SQL Uptime</h2>                  
                  <div className="panel-box--count height-230"><br /> <br />{ azureSqlUptime == '-' ? <span className="loading">Loading...</span> : <>-</>}</div>
				  {/* {(Number(azureSqlUptime)).toFixed(2)} <span className="conversion">%</span> */}
				</div>
              </Grid>
              <Grid item xs={6} className="">
                {/* <div className="panel-box"> */}
                <div className="panel-box mh-294">
                  <h2 className="panel-box--title mb-0">Synapse Uptime</h2>
                  <div className="panel-box--count height-230"><br /> <br />{ synapseUptime == '-' ? <span className="loading">Loading...</span> : <>-</>}</div>
				  {/* {(Number(synapseUptime)).toFixed(2)} <span className="conversion">%</span> */}
				</div>
              </Grid>
            </Grid>
          </div>
        </Grid>
		{isTableVisible && (
					<>
						<h2 className="panel-legend--title">{tableHeading}</h2>
						<div className=" finop_tabledata detail_sum_tbl">
							<Grid>
								{tableData && tableData.length > 0 ? (
									<CustomizedDataGrid
										tableColumns={getColumns()}
										tableData={tableData}
										tableType="sector"
										defaultColumnNames={defaultColumnNames}
										onSorting={handleSorting}
										currSorting={sortingInfo}
										page={page}
										pageSize={pageSize}
										paginationData={paginationData}
										listLength={tableData.length}
										handlepageSizeChange={handlepageSizeChange}
										handlePageChange={handlePageChange}
										isDateRangeFilter={false}
										isDisabled={false}
										isFetching={true}
									/>
								) : (
									<span>No data found </span>
								)
								}
							</Grid>
						</div>
					</>
				)}
      </Grid>
    </div>
  );
}
