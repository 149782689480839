import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
	firstGrid: {
		display: 'block',
		alignItems: 'center',
		height: '100vh',
		maxHeight: '100vh',
		textAlign: 'center',
		verticalAlign: 'middle',
		padding: '16% 0px'
	},
	logo: {
		marginTop: '0px',
		marginLeft: '0px',
		height: '19vh',
		width: '70%'
	},
	text: {
		color: 'white',
		textTransform: 'uppercase',
		fontFamily: 'Montserrat',
		marginTop: '20px !important'
	},
	loginForm: {
		height: '100%',
		backgroundColor: 'transparent',
		textAlign: 'center',
		maxHeight: '100%',
		overflow: 'hidden'
	},
	center: {
		justifyContent: 'left',
		textAlign: 'left',
		display: 'block',
		verticalAlign: 'middle',
		padding: '15% 0px 20% 0px'
	},
	userlogo: {
		height: '9vh',
		position: 'relative',
		margin: 'auto',
		display: 'block'
	},
	textfield: {
		top: '20px',
		width: '67%',
		'& input::placeholder': {
			fontSize: '12px',
			fontFamily: 'Montserrat !important',
			fontStyle: 'italic'
		},
		'& input:active': {
			background: 'transparent'
		},
		'& input:focus': {
			background: 'transparent'
		},
		'& input:hover': {
			background: 'transparent'
		},
		'& input': {
			paddingLeft: '10px',
			background: 'transparent'
		},
		background: 'rgba(113, 117, 143, 0.4)',
		backdropFilter: 'blur(10px)',
		borderRadius: '4px',
		marginLeft: '14% !important',
		marginBottom: '10px !important',
		fontFamily: 'Montserrat !important'
	},
	textfield1: {
		top: '20px',
		width: '67%',
		'& input::placeholder': {
			fontSize: '12px',
			fontFamily: 'Montserrat !important',
			fontStyle: 'italic'
		},
		'& input:active': {
			background: 'transparent'
		},
		'& input:focus': {
			background: 'transparent'
		},
		'& input:hover': {
			background: 'transparent'
		},
		'& input': {
			paddingLeft: '10px',
			background: 'transparent'
		},
		background: 'rgba(113, 117, 143, 0.4)',
		backdropFilter: 'blur(10px)',
		borderRadius: '4px',
		marginLeft: '14% !important',
		marginBottom: '10px !important',
		fontFamily: 'Montserrat !important',
		paddingLeft: '10px'
	},
	loginBtn: {
		top: '30px',
		display: 'block !important',
		position: 'relative',
		width: '67%',
		background: '#00D08D !important',
		marginLeft: '14% !important',
		fontFamily: 'Montserrat !important',
		fontWeight: 'bolder !important'
	},
	img: {
		paddingLeft: '10px',
		width: '70%'
	},
	pwdErr: {
		top: '13px',
		position: 'relative',
		color: 'red',
		paddingLeft: '16%',
		paddingTop: '10px',
		paddingBottom: '10px',
		fontFamily: 'Montserrat !important',
		fontSize: '12px',
		fontWeight: '600',
		textAlign: 'left',
		paddingRight: '20%',
		verticalAlign: 'middle',
		'& img': {
			paddingRight: '5px'
		}
	},
	emailErr: {
		top: '13px',
		position: 'relative',
		color: 'red',
		paddingLeft: '20%',
		fontFamily: 'Montserrat !important',
		fontSize: '12px',
		textAlign: 'left',
		paddingRight: '20%'
	},
	field: {
		height: '35px'
	},
	rememberTxt: {
		color: '#E5E5E5',
		fontWeight: '600',
		fontSize: '12px',
		fontFamily: 'Montserrat !important',
		'& span': {
			fontSize: '12px !important'
		},
		width: '45%',
		float: 'left'
	},
	forgotTxt: {
		width: '50%',
		float: 'right',
		color: '#00D08D',
		fontFamily: 'Montserrat !important',
		fontSize: '12px !important',
		textDecoration: 'none',
		marginTop: '12px',
		fontWeight: '600',
		textAlign: 'right'
	}
});

export default useStyles;
