import React, { useEffect, useRef, useState } from 'react';
import {Grid} from '@mui/material';
import '../TableauObs/Summary/Summary.scss';
import moment from 'moment';
import { FetchFinOpsOverviewCloudCost, fetchCostBreakdown, FetchFinOpsOverviewCloudGrowth } from '../../service/service';
import useStyles from '../jobs/jobs_styles';
import '../FineOps/finops.scss';
import { fetchBIApplicationSummary, fetchCloudCostTrends } from '../../service/service';
import ReactECharts from 'echarts-for-react';
import ArrowUpBlackIcon  from '../../assets/up-arrow-black.svg';
import '../DataEstate/data-estate-dashboard.scss';
import ArrowIcon from '../../assets/Arrow 253.svg';
import ArrowIconRed from '../../assets/growth arrow down.svg';
import { color } from 'echarts';
import { getPercentageOfTwoNumbers } from '../../utils/common-util';

const Overview = () => {	
	
	const [CloudCostYTD, SetCloudCostYTD] = useState('-')
	const [CloudCostMTD, SetCloudCostMTD] = useState('-')
	const [CostChange, SetCostChange] = useState('-')
	const [CloudGrowth, SetCloudGrowth] = useState('-')

	const [grafanaCloudCostTrendLoading, setGrafanaCloudCostTrendLoading] = useState(true);
    const [grafanaCloudCostTrendOptions, setGrafanaCloudCostTrendOptions] = useState<any>()
	const [grafanaCostBreakdownBySectorLoading, setGrafanaCostBreakdownBySectorLoading] = useState(true);
	const [grafanaCostBreakdownBySectorOptions, setGrafanaCostBreakdownBySectorOptions] = useState<any>()
	const [grafanaProgramByCostLoading, setGrafanaProgramByCostLoading] = useState(true);
	const [grafanaProgramByCostOptions, setGrafanaProgramByCostOptions] = useState<any>()

	const [cloudCostBubbleValue, SetCloudCostBubbleValue] = useState(0)

	async function getOverViewSummaryDetails() {
		SetCloudCostYTD('-');
		SetCloudCostMTD('-');
		SetCostChange('-');
		SetCloudGrowth('-');

		// setGrafanaCloudCostTrendLoading(true);
		fetchCloudCostTrends('trend')
			.then((res) => {
			
			const allMonths = Object.keys(res.data);
			
			let allDataAsArray = Object.entries(res.data);
			
			const firstMonthFromLastYear = allMonths[0]
			const currentMonth = allMonths[12]
			const firstMonthFromForecast = allMonths[13]
			const lastMonthFromForecast = allMonths[allMonths.length - 1]
			
			if (allDataAsArray.length) {
				SetCloudCostBubbleValue(getPercentageOfTwoNumbers(allDataAsArray[0][1], allDataAsArray[12][1]));
			}
			
			const option = {
				title: {
					show: Object.keys(allDataAsArray).length == 0 ? true : false,
					textStyle:{
					  color:'#bcbcbc',
					  fontWeight: 'normal',
					  fontSize: 16
					},
					text: 'No data for the selected filters',
					left: 'center',
					top: 'center'
				  },
				backgroundColor: '#053367',
				grid: {
					top: '11%',
					left: '12%',
					right: '2%',
					bottom: '12%'
				},
				xAxis: {
					type: 'category',
					splitLine: { 
					show: true, 
					lineStyle: { 
						type: 'dashed',
						color: '#004477'
					}
				},
				data: allMonths,
					axisLabel: {
						rotate: 30,
						color: '#A7ADCB',
						formatter: function (value: any) {
							return `${moment(value).format('MMM-YY')}`;
						  }
					 },
				},
				yAxis: {
					type: 'value',
					splitLine: { 
						show: true, 
						lineStyle: { 
							type: 'dashed',
							color: '#004477'
						}
					},
					axisLabel: {
						color: '#A7ADCB',
						formatter: function (value: any) {
							return `${(value/1000).toFixed(2)}k`;
						  }
					},
				},			
				visualMap: {
					show: false,
					dimension: 0,
					pieces: [
					  {
						lte: 12,
						color: 'green'
					  },
					  {
						gt: 12,
						lte: 24,
						color: 'red'
					  }
					]
				  },
				series: [
					{
					  name: 'Current Growth',
					  type: 'line',
					  data: allDataAsArray,
					  markArea: {
						itemStyle: {
						  color: 'rgba(255, 173, 177, 0.1)'
						},
						data: [
						  [
							{
							  name: 'Current Growth',
							  xAxis: firstMonthFromLastYear
							},
							{
							  xAxis: currentMonth
							}
						  ],
						  [
							{
							  name: 'Forecast',
							  xAxis: firstMonthFromForecast
							},
							{
							  xAxis: lastMonthFromForecast
							}
						  ]
						]
					  }
					}
				],
				tooltip: {
					trigger: 'axis',
					formatter: (params: any) => {
					  const { name, seriesName, value } = params[0]; 
					  return `${moment(value[0]).format('MMM-YY')}: $${parseFloat(value[1]).toFixed(2)}`; 
					},
					backgroundColor: '#053367',
					textStyle: {
						color: '#A7ADCB' 
					  }
				  }
			};
				setGrafanaCloudCostTrendOptions(option);
				setGrafanaCloudCostTrendLoading(false);
			})
			.catch((error) => {
				console.error(error);
			});

			
		// setGrafanaCostBreakdownBySectorLoading(true);
		fetchCostBreakdown('bysector')
		.then((res) => {
			let data = res.data.result;
			// Transform data into echarts series format
			const seriesData = data.map((item: any) => ({
				name: item.Sector,
				value: item.total_cost
			})).reverse();

			const yAxisData = seriesData.map((item: any) => item.name);
			const xAxisData = seriesData.map((item: any) => item.value);

			const optionBar = {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					}
				},
				legend: {},
				grid: {
					left: '1%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: {
					type: 'value',
					axisLabel: { color: '#A7ADCB', formatter: (value: number) => `$${value / 1000}k` },					
					splitLine: { 
						show: true, 
						lineStyle: { 
							type: 'dashed',
							color: '#004477'
						}
					}
				},
				yAxis: {
					type: 'category',
					data: yAxisData,
					axisLabel: { color: '#A7ADCB' }
				},
				series: [
					{
						type: 'bar',
						showBackground: true,
						color: '#009BD8',
						data: xAxisData,
						label: {
							show: true,
							position: 'right',
							color: '#F5F5F5',
							formatter: (params: any) => `$${(params.value / 1000).toFixed(1)}k`
						}
					}
				]
			};

			setGrafanaCostBreakdownBySectorOptions(optionBar);
			setGrafanaCostBreakdownBySectorLoading(false);
		})
		.catch((error) => {
			console.error(error);
		});


			
		// setGrafanaProgramByCostLoading(true);
		fetchCostBreakdown('program')
		.then((res) => {
			let data = res.data.result;
			// Transform data into echarts series format
			const seriesData = data.map((item: any) => ({
				name: item.programName,
				value: item.total_cost
			})).reverse();

			const yAxisData = seriesData.map((item: any) => item.name);
			const xAxisData = seriesData.map((item: any) => item.value);

			const optionBar = {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					},
					formatter: (params: any) => {
					  return `${params[0].name} <br> $${(params[0].value / 1000).toFixed(2)}k`; 
					},
				},
				legend: {},
				grid: {
					left: '1%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: {
					type: 'value',
					axisLabel: { color: '#A7ADCB', formatter: (value: number) => `$${value / 1000}k` },					
					splitLine: { 
						show: true, 
						lineStyle: { 
							type: 'dashed',
							color: '#004477'
						}
					}
				},
				yAxis: {
					type: 'category',
					data: yAxisData,
					axisLabel: { color: '#A7ADCB' }
				},
				series: [
					{
						type: 'bar',
						showBackground: true,
						color: '#009BD8',
						data: xAxisData,
						label: {
							show: true,
							position: 'right',
							color: '#F5F5F5',
							formatter: (params: any) => `$${(params.value / 1000).toFixed(1)}k`
						}
					}
				]
			};

			setGrafanaProgramByCostOptions(optionBar);
			setGrafanaProgramByCostLoading(false);
		})
		.catch((error) => {
			console.error(error);
		});


		FetchFinOpsOverviewCloudCost('ytd')
			.then((res) => {
				let count = res?.data?.result[0]?.total_cost;
				SetCloudCostYTD((count / 1000).toFixed(2).toString());
			})
			.catch((error) => {
				console.error(error);
			});
		FetchFinOpsOverviewCloudCost('mtd')
			.then((res) => {
				let count = res?.data?.result[0]?.total_cost;
				SetCloudCostMTD((count / 1000).toFixed(2).toString());
			})
			.catch((error) => {
				console.error(error);
			});
			FetchFinOpsOverviewCloudGrowth('growth')
			.then((res) => {
				let count = res?.data?.costChange;
				SetCostChange(count.toFixed(2).toString());
				
				let countCG = res?.data?.cloudGrowth;
				SetCloudGrowth(countCG.toFixed(2).toString());
			})
			.catch((error) => {
				console.error(error);
			});
	}
	
	
	useEffect(() => {
		
		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/finops/overview' || window.location.pathname === '/dataestate/financialOverview') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('datefilter-align-sector');
				document.body.classList.add('sector-label');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				document.body.classList.add('dashboard-panel-dataestate');
				document.body.classList.add('datefilter-align-sector');
			}
		}
		getOverViewSummaryDetails();
	}, []);
	return (
		<div className="dashboard-panel-dataestate job-schedules-wrap">
			<div className="page-table jobs-tabledata page-table-ibp-monitor">
				<div className="ibp-dashboard-wrapper">
					<h2 className="panel-legend--title">Cost Summary</h2>

					<Grid container spacing={0} className="headerIPB containerBI">
						<Grid item xs={6} className="headerIPB-title subheaderIPB-title no-ml">
							<Grid container spacing={1}>
								<Grid item xs={6} className="dataestate-title-fn bgcolor-blue cost-finOps">
									<div className="monitor-head">Azure Cloud Cost (YTD)</div>
									<div className="dataestate-subheader">
										{CloudCostYTD == '-' ? (
											<span className="loading">Loading...</span>
										) : (
											<>
												${CloudCostYTD} <span className="subheader-text">k</span>
											</>
										)}
									</div>
								</Grid>
								<Grid item xs={6} className="dataestate-title-fn bgcolor-blue cost-finOps">
									<div className="monitor-head">Azure Cloud Cost (MTD)</div>
									<div className="dataestate-subheader">
										{CloudCostMTD == '-' ? (
											<span className="loading">Loading...</span>
										) : (
											<>
												${CloudCostMTD} <span className="subheader-text">k</span>
											</>
										)}
									</div>
								</Grid>
							</Grid>

							<Grid container spacing={1}>
								<Grid item xs={6} className="dataestate-title-fn bgcolor-blue cost-finOps">
									<div className="monitor-head">Azure Cloud Growth (MoM)</div>
									<div className={`dataestate-subheader ${parseFloat(CloudGrowth) < 0 ? 'text--red' : 'text--green'}`}>
										{CloudGrowth === '-' ? (
											<span className="loading">Loading...</span>
										) : (
											<>
												{CloudGrowth}
												<span className="subheader-text">%</span>
												<img src={parseFloat(CloudGrowth) < 0 ? ArrowIconRed : ArrowIcon} className="arrow-icon-styles" />
											</>
										)}
									</div>
								</Grid>
								<Grid item xs={6} className="dataestate-title-fn bgcolor-blue cost-finOps">
									<div className="monitor-head">Cost Change Compared to Last Month</div>
									<div className={`dataestate-subheader ${parseFloat(CostChange) < 0 ? 'text--red' : 'text--green'}`}>
										{CostChange === '-' ? (
											<span className="loading">Loading...</span>
										) : (
											<>
												{CostChange}
												<span className="subheader-text">%</span>
												<img src={parseFloat(CostChange) < 0 ? ArrowIconRed : ArrowIcon} className="arrow-icon-styles" />
											</>
										)}
									</div>
								</Grid>
							</Grid>
						</Grid>
						{/* right side */}

						<Grid item xs={6} className="headerIPB-title subheaderIPB-title no-ml">
							<Grid container spacing={1}>
								<Grid item xs={12} className="dataestate-title chart-widget-finop bgcolor-blue">
									<div className="monitor-head">Azure Cloud Cost Trend (MoM)
										
									{!grafanaCloudCostTrendLoading && 
										<span className="badge">
											{Math.sign(cloudCostBubbleValue) == -1 && <><img src={ArrowUpBlackIcon} className="arrow-icon-styles arrow-icon-styles-up-black down-arrow" /> {Math.abs(Math.round(cloudCostBubbleValue))}% /Yr</> }
											{Math.sign(cloudCostBubbleValue) != -1 && <><img src={ArrowUpBlackIcon} className="arrow-icon-styles arrow-icon-styles-up-black" /> {Math.abs(Math.round(cloudCostBubbleValue))}% /Yr</> }
										</span>
									}
									</div>
									{grafanaCloudCostTrendLoading ? (
										'Loading...'
									) : (
										<ReactECharts
											option={grafanaCloudCostTrendOptions}
											theme={{
												backgroundColor: '#1b1e35'
											}}
										/>
									)}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					{/* second part */}
					<h2 className="panel-legend--title mt-10 ">Cost Breakdown</h2>

					<Grid container spacing={0} className="headerIPB containerBI">
						<Grid item xs={6} className="headerIPB-title subheaderIPB-title no-ml">
							<Grid container spacing={1}>
								<Grid item xs={12} className="dataestate-title chart-widget bgcolor-blue">
									<div className="monitor-head">Azure Cost Breakdown by Sector (YTD)</div>
									{grafanaCostBreakdownBySectorLoading ? (
										'Loading...'
									) : (
										<ReactECharts
											option={grafanaCostBreakdownBySectorOptions}
											theme={
												{
													// backgroundColor: '#1b1e35'
												}
											}
										/>
									)}
								</Grid>
							</Grid>
						</Grid>
						{/* right side */}

						<Grid item xs={6} className="headerIPB-title subheaderIPB-title no-ml">
							<Grid container spacing={1}>
								<Grid item xs={12} className="dataestate-title chart-widget bgcolor-blue">
									<div className="monitor-head">Top 10 Programs by Cost (YTD)</div>
									{grafanaProgramByCostLoading ? (
										'Loading...'
									) : (
										<ReactECharts
											option={grafanaProgramByCostOptions}
											theme={
												{
													// backgroundColor: '#1b1e35'
												}
											}
										/>
									)}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</div>
			</div>
		</div>
	);
};

export default Overview;
