import React, { useEffect, useState, useRef } from 'react';
import { Grid, Paper } from '@mui/material';
import { getModelfeatureDriftMetrics } from '../../../../service/service';
import moment from 'moment-timezone';
import type { Column } from 'react-data-grid';
import { CustomizedDataGrid } from '../../../customized_data_grid';
import '../../models/model.css';
import Loader from '../../../Loader/loader';
import { Link, useSearchParams, useParams } from 'react-router-dom';
import AnalyticsVisualization from '../../../framework/analyticsVisualization';
import { dashboardUrl } from '../../../../service/config';

export default function FeatureDrifts({ inferenceId }: any) {
	interface Row {
		id: string;
		column_name: string;
		column_type: string;
		stattest_name: string;
		stattest_threshold: string;
		drift_score: number;
		drift_detected: boolean;
		current_distribution: any;
		reference_distribution: any;
	}
	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'column_name',
				name: 'Feature',
				minWidth: 150
			},
			{
				key: 'column_type',
				name: 'Type',
				minWidth: 100,
				width: 100
			},
			{
				key: 'drift_detected',
				name: 'Data Drift',
				formatter(props) {
					return props.row.drift_detected ? 'Yes' : 'No';
				},
				minWidth: 105
				// maxWidth: 130
			},
			{
				key: 'stattest_name',
				name: 'Stat Test',
				minWidth: 170
			},
			{
				key: 'drift_score',
				name: 'Drift Score',
				formatter(props) {
					return <div title={props.row.drift_score.toString()}>{props.row.drift_score?.toExponential(2)}</div>;
				},
				minWidth: 100,
				width: 100
			}
		];
	}
	const params = useParams();
	const defaultColumnNames = ['id', 'Feature', 'Type', 'Data Drift', 'Stat Test', 'Drift Score'];
	const dataFetchedRef = useRef(false);
	const [featureDrifts, setFeatureDrifts] = useState<Row[]>([]);
	const [loading, setLoading] = useState(false);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(6);

	const [searchParams, setSearchParams] = useSearchParams();
	const hasDrillDown = searchParams.has('isDrillDowned');

	const [filterData, setFilterData] = useState<Row[]>([]);
	const [filterInfo, setFilterInfo] = useState({} as any);
	const [selectedRowId, setSelectedRowId] = useState('');

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		listFeatureDrifts(value, pageSize, sortingInfo, parseFilter(filterInfo));
	};

	const handlepageSizeChange = (event: any) => {
		listFeatureDrifts(page, event.target.value, sortingInfo, parseFilter(filterInfo));
	};

	const resetSearchParams = () => {
		if (hasDrillDown) {
			searchParams.delete('status');
			searchParams.delete('isDrillDowned');
			setSearchParams(searchParams);
		}
	};

	const handleFilter = (filterInfo: any) => {
		setFilterInfo(filterInfo);
		resetSearchParams();
		listFeatureDrifts(1, pageSize, sortingInfo, parseFilter(filterInfo));
	};

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;

		let storedFeatureDriftsState = localStorage.getItem('featureDriftsState')
			? JSON.parse(localStorage.getItem('featureDriftsState') ?? '')
			: null;
		if (storedFeatureDriftsState && storedFeatureDriftsState.pageNumber && storedFeatureDriftsState.pageSize) {
			listFeatureDrifts(
				1,
				storedFeatureDriftsState.pageSize,
				storedFeatureDriftsState.sortingInfo,
				parseFilter(storedFeatureDriftsState.filterInfo)
			);
		} else {
			listFeatureDrifts(page, pageSize, sortingInfo, parseFilter(filterInfo));
		}

		const activeId = document.getElementById('root');
		if (activeId) {
			const mainContent = document.getElementsByClassName('main-content')[0];

			if (mainContent) {
				// Use type assertion to let TypeScript know that myElement has a style property
				(mainContent as HTMLElement).style.overflow = 'scroll';
				(mainContent as HTMLElement).style.height = 'calc(100vh - 35px)';
			}

			activeId.classList.add('jobs-list-table');
			activeId.classList.remove('root');
			document.body.classList.add('jobs-exe-datas');
			document.body.classList.add('jobs-list-datas');
		}
	}, [selectedRowId, inferenceId]);

	async function listFeatureDrifts(pageNumber: any, size: any, sortingInfo: any, featureDriftFilterInfo: any) {
		setLoading(true);
		const drillDownStatus = hasDrillDown ? searchParams.get('status') : '';
		const res = await getModelfeatureDriftMetrics(
			pageNumber.toString(),
			size.toString(),
			sortingInfo,
			featureDriftFilterInfo,
			params.modelId, inferenceId
		);
		if (res.success) {
			setFeatureDrifts(res.data.feature_drifts);
			if (res.data?.feature_drifts?.length) setSelectedRowId(res.data.feature_drifts[0].id);
			setPaginationData(res.data.page);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
			setFilterData(res.data.filterOptions);
			setFilterInfo(featureDriftFilterInfo);
			setLoading(false);
			const featureDriftsState = {
				page: res.data.page,
				filterOptions: res.data.filterOptions,
				pageSize: size,
				pageNumber,
				sortingInfo,
				filterInfo: featureDriftFilterInfo
			};
			localStorage.setItem('featureDriftsState', JSON.stringify(featureDriftsState));
		}
	}
	const handleSorting = (sortingInfo: any) => {
		listFeatureDrifts(page, pageSize, sortingInfo, parseFilter(filterInfo));
	};
	const setSelectedCellRow = (row: any) => {
		setSelectedRowId(row.id);
	};

	return (
		<>
			<Paper elevation={8} className="width-75">
				<div className="page-table feature-drift-table models_tabledata">
					<Grid>
						{
							<CustomizedDataGrid
								tableColumns={getColumns()}
								tableData={featureDrifts}
								tableType="ML_ModelFeatureDrifts"
								defaultColumnNames={defaultColumnNames}
								onSorting={handleSorting}
								currSorting={sortingInfo}
								filterDataOptions={filterData}
								onFilterCheck={handleFilter}
								currFilter={filterInfo}
								page={page}
								pageSize={pageSize}
								paginationData={paginationData}
								listLength={featureDrifts.length}
								handlepageSizeChange={handlepageSizeChange}
								handlePageChange={handlePageChange}
								isDateRangeFilter={false}
								isDisabled={false}
								isFetching={loading}
								onCellClick={setSelectedCellRow}
								selectedRowId={selectedRowId}
							/>
						}
						{loading ? <Loader /> : ''}
					</Grid>
				</div>
			</Paper>
			<Paper elevation={8} className="w-full ml-5 feature-drift-visualization">
				{selectedRowId ? (
					<AnalyticsVisualization
						params={{
							dashboardUrl: dashboardUrl.mlobsFeatureDistribution,
							className: 'mlobsFeatureDriftIframeContainer',
							otherParams: {
								from: 'now-6h',
								to: 'now',
								'var-kpiMetricId': selectedRowId
							},
							seamless: true
						}}
					/>
				) : (
					'No Feature Drift'
				)}
			</Paper>
		</>
	);
}
