import React, { useState, useEffect, useRef } from 'react';
import { Button, MenuItem, Popover, Stack, ListItemText, FormControlLabel, Checkbox } from '@mui/material';
import { getDecodedToken, getStorage } from '../../service/service';
import useStyles from '../jobs/jobs_styles';
import AnalyticsVisualization from '../framework/analyticsVisualization';
import { dashboardUrl } from '../../service/config';
import { ReactComponent as FilterIcon } from '../../assets/ml_summary_filter.svg';
import './dataGrowth.scss';

const databricksDataGrowth = () => {
    interface CheckedList {
        storage: string[];
    }
    const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
    const classes = useStyles();
    const dataFetchedRef = useRef(false);
    const [storageOptionsList, setStorageOptionsList] = useState<string[]>([]);
    const [filterMenu, setFilterMenu] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);
    const [itemsChecked, setItemsChecked] = useState(false);
    const [checkedList, setCheckedList] = useState<CheckedList>({
        storage: [],
    });
    const [selectedFilter, setSelectedFilter] = useState<string>('Storage Account');
    const [selectedStorage, setSelectedStorage] = useState<string[]>([]);

    const filterClick = (event: any) => {
        event.preventDefault();
        setFilterMenu(
            filterMenu === null
                ? {
                    mouseX: parseInt(event.clientX) - 20,
                    mouseY: parseInt(event.clientY) - 6
                }
                : null
        );
    };

    const handleClose = () => {
        localStorage.removeItem('presto-summary-sector-filter');
        setCheckedList({
            storage: [],
        });
        setItemsChecked(false);
    };

    const handleMenuItemClick = (filter: string) => {
        setSelectedFilter(filter);
        if (filter === 'Storage Account') {
            setItemsChecked(false);
        }
    };

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;

        if (userSector === 'all') fetchStorageOptionsData();
        let storedStorageFilter = localStorage.getItem('presto-summary-sector-filter')
            ? JSON.parse(localStorage.getItem('presto-summary-sector-filter') ?? '')
            : null;
        if (storedStorageFilter) {
            setCheckedList(storedStorageFilter);
            setSelectedStorage(storedStorageFilter.storage);
        }
    }, []);

    async function fetchStorageOptionsData() {
        const storageRes = await getStorage('1', '100', '');
        console.log(storageRes)
        if (storageRes.success) {
			setStorageOptionsList(storageRes.data.data.map((x: any) => x));
		}
    }

    const handleOkClick = () => {
        setSelectedStorage(checkedList.storage);
        console.log(checkedList.storage, 'checked list');
        handleViewClose();
    };

    const handleViewClose = () => {
        setFilterMenu(null);
    };

    const handleStorageChange = (updatedSelection: string[]) => {
        setCheckedList((prevState) => ({
            ...prevState,
            storage: updatedSelection
        }));
    };

    const selectAllCheck = (e: any) => {
        const { checked } = e.target;
        setCheckedList((prevState) => ({
            ...prevState,
            storage: checked ? storageOptionsList : []
        }));
        setItemsChecked(checked);
    };

  

    return (
        <div className="datagrowth-panel job-schedules-wrap">
            <div className="datagrowth-exexutions-dateFilter-div">
                <div className="datagrowth-filter-wrapper">
                    <Stack>
                        {userSector === 'all' ? (
                            <FilterIcon onClick={filterClick} className="sector-filter" />
                        ) : (
                            <div className="sector-filter-text">Sector: {getDecodedToken()?.sector?.name}</div>
                        )}

                        <Popover
                            open={filterMenu !== null}
                            onClose={handleViewClose}
                            className="filterContainers filter-base"
                            anchorReference="anchorPosition"
                            anchorPosition={filterMenu !== null ? { top: filterMenu.mouseY, left: filterMenu.mouseX } : undefined}
                        >
                            <div className="text-filter-rows">
                                <div className="filter-header">
                                    <div className="filters-text"> Filters</div>
                                    <div className="buttonsSec1">
                                        <Button variant="contained" className={classes.cancelBtn} onClick={handleClose}>
                                            CLEAR
                                        </Button>
                                        <Button variant="contained" className={classes.okBtn} onClick={handleOkClick}>
                                            APPLY
                                        </Button>
                                    </div>
                                </div>
                                <div className="dataGrowthselectAll-sec1">
                                    <div>
                                        <table>
                                            <ul className="list-items">
                                                {['Storage Account'].map((filter: string) => (
                                                    <ul key={filter}>
                                                        <MenuItem
                                                            className="list-items"
                                                            onClick={() => handleMenuItemClick(filter)}
                                                            style={{ background: filter === selectedFilter ? '#676a88' : '' }}
                                                        >
                                                            <ListItemText className="selected">{filter}</ListItemText>
                                                        </MenuItem>
                                                    </ul>
                                                ))}
                                            </ul>
                                        </table>
                                    </div>
                                </div>
                                <ul className="filterContainers MuiPaper-root sector storage-list">
										<MenuItem className="dataGrowthselectAll-sec2">
											<FormControlLabel
												className="checkbox"
												label="All"
												control={
													<Checkbox
														className="data-check"
														color="success"
														value="all"
														checked={itemsChecked}
														onChange={(e) => {
															selectAllCheck(e);
														}}
													/>
												}
											/>
										</MenuItem>
										<ul className="checkbox-sec-datagrowth">
											{storageOptionsList &&
												storageOptionsList.map((filter: any) => (
													<MenuItem key={filter} className="all-sector">
														<FormControlLabel
															className={classes.checkbox}
															label={filter}
															control={
																<Checkbox
																	className="data-check"
																	color="success"
																	value={filter}
																	checked={checkedList.storage.includes(filter)}
																	onChange={() => {
																		const updatedSelection = checkedList.storage.includes(filter)
																			? checkedList.storage.filter((item) => item !== filter)
																			: [...checkedList.storage, filter];
																		handleStorageChange(updatedSelection);
																	}}
																/>
															}
														/>
													</MenuItem>
												))}
										</ul>
									</ul>
                            </div>
                        </Popover>
                    </Stack>
                </div>

                <AnalyticsVisualization
                    params={{
                        dashboardUrl: dashboardUrl.dataGrowthDataBricksTotalCountOfTables,
                        className: 'dataGrowthDatabricksframeContainer',
                        otherParams: {
                            'var-storageAccount': selectedStorage,
                        },
                        seamless: true
                    }}
                />
				<AnalyticsVisualization
                    params={{
                        dashboardUrl: dashboardUrl.dataGrowthDataBricksTotalSizeOfTables,
                        className: 'dataGrowthDatabricksTableSizeframeContainer',
                        otherParams: {
                            'var-storageAccount': selectedStorage,
                        },
                        seamless: true
                    }}
                />
            </div>
        </div>
    );
};

export default databricksDataGrowth;
 