import React, { useEffect, useState, useRef } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { jobListing,jobListingFilter, jobSearch } from '../../service/service';

import { CustomizedDataGrid } from '../customized_data_grid';
import { OverflowTooltip } from '../overflow_tooltip';
import moment from 'moment-timezone';

import type { Column } from 'react-data-grid';
import '../jobs/jobs.css';
import Loader from '../Loader/loader';

export default function jobsListing() {
	interface Row {
		otherScheduleInfo: any;
		schedulerName: string;
		machine: string;
		lastSuccessRun: string;
		criticalFlag: boolean;
		executionFrequency: string;
		application: string;
		subApplication: string;
		id: string;
		name: string;
		friendlyName: string;
		system: string;
		sector: string;
		process: string;
		slaEndTime: string;
		Created_DT: string;
		scheduleTime: string;
		scheduleDays: string;
		deletedAt: string;
	}

	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'name',
				name: 'Job Name',
				minWidth: 150,
				width: 308,
				formatter(props) {
					return (
						<Link to={`/jobs/${props.row.id}`} state={props.row} className="jobsNameList">
							{props.row.name}
						</Link>
					);
				}
			},
			{
				key: 'boxName',
				name: 'Box Name',
				width: 200,
				minWidth: 150
			},
			{
				key: 'sector',
				name: 'Sector',
				minWidth: 95
				// maxWidth: 160
			},
			{
				key: 'system',
				name: 'System',
				formatter(props) {
					return props.row.system || ' ';
				},
				minWidth: 95
				// maxWidth: 160
			},
			{
				key: 'process',
				name: 'Process',
				formatter(props) {
					return props.row.process || ' ';
				},
				minWidth: 125,
				width: 125
				// maxWidth: 220
			},
			{
				key: 'criticalFlag',
				name: 'Critical',
				formatter(props) {
					return props.row.criticalFlag ? 'Yes' : 'No';
				},
				minWidth: 105
				// maxWidth: 130
			},
			{
				key: 'lastSuccessRun',
				name: 'Last Successful Run',
				minWidth: 190,
				// maxWidth: 220,
				formatter(props) {
					return props.row.lastSuccessRun && moment(props.row.lastSuccessRun).format();
				}
			},
			{
				key: 'slaEndTime',
				name: 'Normal Completion Time',
				minWidth: 200,
				// maxWidth: 250,
				formatter(props) {
					return (
						<OverflowTooltip customTooltip={`Timezone: ${moment().isDST() ? 'EDT(UTC-4)' : 'EST(UTC-5)'}`}>
							{props.row.slaEndTime}
						</OverflowTooltip>
					);
				}
			},
			{
				key: 'Created_DT',
				name: 'Observed Since',
				minWidth: 140,
				width: 140,
				// maxWidth: 200,
				formatter(props) {
					return props.row.Created_DT&& moment(props.row.Created_DT).format();
				}
			},
			{
				key: 'application',
				name: 'Application',
				formatter(props) {
					return props.row.application || ' ';
				},
				minWidth: 120,
				width: 120
				// maxWidth: 160
			},
			{
				key: 'subApplication',
				name: 'Sub Application',
				formatter(props) {
					return props.row.subApplication || ' ';
				},
				minWidth: 135,
				width: 135
				// maxWidth: 180
			},
			{
				key: 'friendlyName',
				name: 'Job Alias',
				minWidth: 135,
				width: 135
				// maxWidth: 180
			},
			{
				key: 'jobType',
				name: 'Job Type',
				minWidth: 95
				// maxWidth: 160
			},
			{
				key: 'machine',
				name: 'Machine',
				minWidth: 125,
				width: 125
				// maxWidth: 220
			},
			{
				key: 'otherScheduleInfo',
				name: 'Schedule Days',
				minWidth: 255,
				width: 255,
				formatter(props) {
					const scheduleDays = props.row.scheduleDays || ' ';
					let otherSchedule = '';
					if (props.row.otherScheduleInfo) {
						const parsedInfo = JSON.parse(props.row.otherScheduleInfo);
						if (parsedInfo.day) { 
							otherSchedule = parsedInfo.day.replace(/[\[\]"]/g, '') || '';
							return `${scheduleDays}; ${otherSchedule}`.trim();
						}
					}
					return scheduleDays; 
				}
			},			
			{
				key: 'scheduleTime',
				name: 'Schedule Time',
				minWidth: 250,
				width: 250,
				formatter(props) {
					const scheduleTime = props.row.scheduleTime || '';
					let additionalTime = '';
					if (props.row.otherScheduleInfo) {
						const parsedInfo = JSON.parse(props.row.otherScheduleInfo);
						if (parsedInfo.time) { 
							additionalTime = parsedInfo.time || '';
							return `${scheduleTime}; ${additionalTime}`.trim();
						}
					}
					return scheduleTime; 
				}
			},
			{
				key: 'schedulerName',
				name: 'Scheduler',
				minWidth: 105
				// maxWidth: 120
			},
			{
				key: 'deletedAt',
				name: 'Deleted At',
				minWidth: 140,
				width: 140,
				// maxWidth: 160,
				formatter(props) {
					return props.row.deletedAt && moment(props.row.deletedAt).format();
				}
			}
		];
	}
	const dataFetchedRef = useRef(false);
	const [jobsList, setJobList] = useState<Row[]>([]);
	const [loading, setLoading] = useState(false);
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);

	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [filterData, setFilterData] = useState<Row[]>([]);
	const [filterInfo, setFilterInfo] = useState([]);
	const [searchDetails, setFilterSearchInfo] = useState([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const hasDrillDowned = searchParams.has('isDrillDowned');

	const defaultColumnNames = [
		'Job Name',
		'Box Name',
		'Sector',
		'System',
		'Process',
		'Critical',
		'Last Successful Run',
		'Normal Completion Time',
		'Observed Since'
	];
	moment.updateLocale(moment.locale(), { invalidDate: '' });

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		jobListingFunc(value, pageSize, sortingInfo, parseFilter(filterInfo));
	};

	const handlepageSizeChange = (event: any) => {
		jobListingFunc('1', event.target.value, sortingInfo, parseFilter(filterInfo));
	};
	const handleSorting = (sortingInfo: any) => {
		jobListingFunc(page, pageSize, sortingInfo, parseFilter(filterInfo));
	};
	const resetSearchParams = () => {
		if (hasDrillDowned) {
			searchParams.delete('status');
			searchParams.delete('isDrillDowned');
			setSearchParams(searchParams);
		}
	};

	const handleFilter = (filterInfo: any) => {
		console.log(filterInfo)
		resetSearchParams();
		setFilterInfo(filterInfo);
		jobListingFunc(1, pageSize, sortingInfo, parseFilter(filterInfo));
	};
	const handleSearch = (searchDetails: any, columnKey: any) => {
		jobSearchFunc(searchDetails, parseFilter(filterInfo, columnKey), columnKey);
	};

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		let storedJobState = localStorage.getItem('jobState') ? JSON.parse(localStorage.getItem('jobState') ?? '') : null;
		const filterQuerySkeleton = {} as any;
		const _status = searchParams.get('status');
		const _startDaily = moment(new Date()).startOf('day').subtract(1, 'hours').format('YYYY-MM-DD HH:mm');
		const _endDaily = moment(new Date()).startOf('day').endOf('day').subtract(1, 'hours').format('YYYY-MM-DD HH:mm');
		const _scheduleDate = searchParams.get('fromDate');
		const _toDate = searchParams.get('toDate');
		const startDt = moment(_scheduleDate).startOf('day').subtract(1, 'hours').format('YYYY-MM-DD HH:mm');
		const endDt = moment(_toDate).endOf('day').subtract(1, 'hours').format('YYYY-MM-DD HH:mm');
		if (hasDrillDowned) {
			const _sector = searchParams.get('sector');
			const _schedler = searchParams.get('scheduler');
			if (_sector) filterQuerySkeleton.sector = [_sector];
			if (_schedler) filterQuerySkeleton.schedulerName = [_schedler];
			switch (_status) {
				case 'Critical':
					filterQuerySkeleton.criticalFlag = ['true'];
					break;
				case 'Non Critical':
					filterQuerySkeleton.criticalFlag = ['false'];
					break;
				case 'New Jobs':
					filterQuerySkeleton.Created_DT = [`${_startDaily}`, `${_endDaily}`];
					filterQuerySkeleton.criticalFlag = ['true'];
					break;
				case 'Non Critical New Jobs':
					filterQuerySkeleton.Created_DT = [`${_startDaily}`, `${_endDaily}`];
					filterQuerySkeleton.criticalFlag = ['false'];
					break;
				case 'Critical Exceed Estimated Duration Trend':
					filterQuerySkeleton.criticalFlag = ['true'];
					filterQuerySkeleton.execExceedEstimatedDurationTrend = ['Yes'];
					break;
				case 'Critical Exceed Threshold Trend':
					filterQuerySkeleton.criticalFlag = ['true'];
					filterQuerySkeleton.execExceedThresholdTrend = ['Yes'];
					break;
				case 'Critical Failed Trend':
					filterQuerySkeleton.criticalFlag = ['true'];
					filterQuerySkeleton.execFailedTrend = ['Yes'];
					break;
				case 'Non Critical Exceed Estimated Duration Trend':
					filterQuerySkeleton.criticalFlag = ['false'];
					filterQuerySkeleton.execExceedEstimatedDurationTrend = ['Yes'];
					break;
				case 'Non Critical Exceed Threshold Trend':
					filterQuerySkeleton.criticalFlag = ['false'];
					filterQuerySkeleton.execExceedThresholdTrend = ['Yes'];
					break;
				case 'Non Critical Failed Trend':
					filterQuerySkeleton.criticalFlag = ['false'];
					filterQuerySkeleton.execFailedTrend = ['Yes'];
					break;
				case 'Non Critical New Jobs Graph':
					filterQuerySkeleton.Created_DT = [`${startDt}`, `${endDt}`];
					filterQuerySkeleton.criticalFlag = ['false'];
					break;
				case 'New Jobs Graph':
					filterQuerySkeleton.Created_DT = [`${startDt}`, `${endDt}`];
					filterQuerySkeleton.criticalFlag = ['true'];
					break;
				default:
					break;
			}
		} else {
			getColumns().forEach((column: any) => {
				filterQuerySkeleton[column.key] = [];
			});
		}
		setFilterInfo(filterQuerySkeleton);
		if (localStorage.getItem('selectedAlert')) {
			storedJobState = localStorage.getItem('alertJobState')
				? JSON.parse(localStorage.getItem('alertJobState') ?? '')
				: null;
			localStorage.removeItem('alertJobState');
		}
		if (hasDrillDowned) {
			localStorage.setItem('drill-down-jobs', 'drill-true');
			jobListingFunc(page, pageSize, sortingInfo, parseFilter(filterQuerySkeleton));
		} else if (storedJobState && storedJobState.pageNumber && storedJobState.pageSize) {
			if (localStorage.getItem('overviewPageHit') && storedJobState.jobs) {
				localStorage.removeItem('overviewPageHit');
				setJobList(storedJobState.jobs);
			} else {
				jobListingFunc(1, storedJobState.pageSize, storedJobState.sortingInfo, parseFilter(storedJobState.filterInfo));
			}
			const isDrillDownCondition = localStorage.getItem('drill-down-jobs');
				if (isDrillDownCondition === 'drill-true') {
					localStorage.setItem('drill-down-jobs', 'false');
				} else {
					localStorage.setItem('drill-down-jobs', 'true');
				}
			setPaginationData(storedJobState.page);
			setFilterData(storedJobState.filterOptions);
			setPage(1);
			setPageSize(storedJobState.pageSize);
			setSortingInfo(storedJobState.sortingInfo);
			setFilterInfo(storedJobState.filterInfo);
		} else {
			jobListingFunc(page, pageSize, sortingInfo, parseFilter(filterQuerySkeleton));
			const isDrillDownCondition = localStorage.getItem('drill-down-jobs');
				if (isDrillDownCondition === 'drill-true') {
					localStorage.setItem('drill-down-jobs', 'false');
				} else {
					localStorage.setItem('drill-down-jobs', 'true');
				}
		}

		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		const jobName = url.substring(url.lastIndexOf('/') + 1);
		if (url === '/jobs' || (jobName !== url && url === '/jobs/' + jobName && activeId)) {
			document.body.classList.add('jobs-list-datas');
			document.body.classList.remove('jobs-exe-datas');
		}
	}, []);

	async function jobSearchFunc(searchDetails: any, jobFilterInfo: any, columnKey: any) {
		const res = await jobSearch(searchDetails, jobFilterInfo, columnKey);
		if (res.success) {
			setFilterSearchInfo(res.data);
		}
	}

	async function jobListingFunc(pageNumber: any, size: any, sortingInfo: any, jobFilterInfo: any) {
		setLoading(true);
		if (pageSize !== size) {
			pageNumber = 1;
		}
		const res = await jobListing(pageNumber.toString(), size.toString(), sortingInfo, jobFilterInfo);
		if (res.success) {
			setJobList(res.data.jobs);
			setPaginationData(res.data.page);
			setFilterData(res.data.filterOptions);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
			setLoading(false);
			if (!localStorage.getItem('selectedAlert')) {
				const jobState = {
					jobs: res.data.jobs,
					page: res.data.page,
					filterOptions: res.data.filterOptions,
					pageSize: size,
					pageNumber,
					sortingInfo,
					filterInfo: jobFilterInfo
				};
				localStorage.setItem('jobState', JSON.stringify(jobState));
			}
			localStorage.removeItem('selectedAlert');
		}
		const resF = await jobListingFilter(pageNumber.toString(), size.toString(), sortingInfo, jobFilterInfo);
		setLoading(false);
		if (resF.success) {
			setFilterData(resF.data.filterOptions);
			if (!localStorage.getItem('selectedAlert')) {
				const jobState = {
					jobs: res.data.jobs,
					page: res.data.page,
					filterOptions: resF.data.filterOptions,
					pageSize: size,
					pageNumber,
					sortingInfo,
					filterInfo: jobFilterInfo
				};
				localStorage.setItem('jobState', JSON.stringify(jobState));
			}
		}
	}

	return (
		<div className="page-table jobs_tabledata">
			<Grid>
				{!loading ? (
					<CustomizedDataGrid
						tableColumns={getColumns()}
						tableData={jobsList}
						tableType="Job"
						defaultColumnNames={defaultColumnNames}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						filterDataOptions={filterData}
						searchDataValues={searchDetails}
						onFilterCheck={handleFilter}
						currFilter={filterInfo}
						onFilterSearch={handleSearch}
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={jobsList.length}
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isFetching={loading}
						showExport={true}
						fileName={`Jobs.xlsx`}
						isDateRangeFilter={false}
						isDisabled={false}
					/>
				) : (
					<Loader />
				)}
			</Grid>
		</div>
	);
}
