import { Palette } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import colors from './base/colors';

export const pepWiseTypography: TypographyOptions | ((palette: Palette) => TypographyOptions) = {
	fontFamily: '"Montserrat", "lato", "Helvetica", "Arial", "sans-serif"',
	subtitle1: {
		fontSize: '12px',
		fontWeight: 600
	},
	subtitle2: {
		fontSize: '14px',
		fontWeight: 500,
		fontStyle: 'italic'
	},
	navMenu: {
		fontSize: '16px',
		color: colors.white.main
	},
	// widgetTitle: {
	// 	fontSize: '16px',
	// 	fontWeight: 700,
  //   fontFamily: 'Montserrat',
	// 	// restrict line breaks to 2
	// 	display: '-webkit-box',
	// 	WebkitBoxOrient: 'vertical',
	// 	WebkitLineClamp: 2,
	// 	overflow: 'hidden',
	// 	textOverflow: 'ellipsis',
	// 	WebkitBoxAlign: 'start',
	// },
	// widgetValue: {
	// 	fontSize: '50px',
	// 	fontWeight: 500,
  //   fontFamily: 'Montserrat',
	// },
	// widgetUnit: {
	// 	fontSize: '28px',
	// 	fontWeight: 500,
  //   marginLeft: '10px',
  //   fontFamily: 'Montserrat',
	// },
	widgetTitle: {
		fontSize: '14px',
		fontWeight: 700,
		lineHeight: 'normal',
		// restrict line breaks to 2
		display: '-webkit-box',
		WebkitBoxOrient: 'vertical',
		WebkitLineClamp: 2,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		WebkitBoxAlign: 'start',
	},
	widgetValue: {
		fontSize: '25px',
		fontWeight: 600,
		lineHeight: 'normal'
	},
	widgetUnit: {
		fontSize: '13px',
		fontWeight: 600,
		lineHeight: 'normal',
	  marginLeft: '10px',
	},
	overlayWidgetTitle: {
		fontSize: '16px',
		fontWeight: 700,
		lineHeight: '19.5px',
    fontFamily: 'Montserrat',
	},
	overlayWidgetValue: {
		fontSize: '32px',
		fontWeight: 700,
		lineHeight: '22.4px',
    fontFamily: 'Montserrat',
	},
	overlayWidgetUnit: {
		fontSize: '20px',
		fontWeight: 700,
		lineHeight: '14px',
    marginLeft: '10px',
    fontFamily: 'Montserrat',
	}
};
