import { Link, useLocation, useNavigate } from 'react-router-dom';
import './sidebar.scss';
import { Sidebar as SidebarWrapper, Menu, MenuItem, SubMenu, useProSidebar } from 'react-pro-sidebar';
import DashboardIcon from '../../assets/material-symbols_dashboard.svg';
import CustomDashboardIcon from '../../assets/Custom_Dashboard.svg';
import MasterIcon from '../../assets/material-symbols_table-chart-view-sharp.svg';
import JobsIcon from '../../assets/ri_file-list-fill.svg';
import SectorsIcon from '../../assets/mingcute_building-2-fill.svg';
import SystemIcon from '../../assets/ri_computer-fill.svg';
import ProcessIcon from '../../assets/ion_sync-circle.svg';
import DollarIcon from '../../assets/dollar-circle.svg';
import DataEstateLogo from '../../assets/dataEstate.svg';
import FinopsLogo from '../../assets/FinOps_small.svg';
import PcobsLogo from '../../assets/ri_money-dollar-circle-fill.svg';
import IBPLogo from '../../assets/ibp.svg';
// // import UsersIcon from '../../assets/fa-solid_users-cog.svg';
import ActiveDashIcon from '../../assets/material-symbols_dashboard_green.svg';
import ActiveBlueDashIcon from '../../assets/dashboard_blue.svg';
import ActiveBlueCustomDashIcon from '../../assets/Custom Dashboard_Selected.svg';
import ResourceMappingIcon from '../../assets/resourceMapping.svg';
import ActiveResourceMappingIcon from '../../assets/activeResourceMapping.svg';
import ActiveMasterIcon from '../../assets/material-symbols_table-chart-view-sharp_green.svg';
import ActiveJobsIcon from '../../assets/ri_file-list-fillgreen.svg';
import ActiveSectorIcon from '../../assets/mingcute_building-2-fillgreen.svg';
import ActiveProcessIcon from '../../assets/ion_sync-circle_green.svg';
import ActiveSystemIcon from '../../assets/ri_computer-fillgreen.svg';
// // import ActiveUsersIcon from '../../assets/fa-solid_users-cog_green.svg';
import Jobschedule from '../../assets/material-symbols_schedules_white.svg';
import ActiveJobschedule from '../../assets/material-symbols_schedules_green.svg';
import JobExecution from '../../assets/material-symbols_jobschedule.svg';
import ActiveJobExecution from '../../assets/material-symbols_jobschedule-green.svg';
// import AdministrativeIcon from '../../assets/administrative_icon.png';
import OperationalIcon from '../../assets/material-symbols_Operationdashboard_unselected.svg';
import ActiveAdministrativeIcon from '../../assets/material-symbols_Admindashboard_selected.svg';
import ActiveOperationalIcon from '../../assets/material-symbols_Operationdashboard_selected.svg';
import HomeIcon from '../../assets/bxs_home.svg';
import ResourceOptIcon from '../../assets/Resource Optimization.svg';
import ActiveResourceOptIcon from '../../assets/Resource Optimization_Selected.svg';
import CostSummaryIcon from '../../assets/Cost Summary.svg';
import ActiveCostSummaryIcon from '../../assets/Cost Summary_Selected.svg';
import GovernanceIcon from '../../assets/Governance.svg';
import ActiveGovernanceIcon from '../../assets/Governance_Selected.svg';
import PerformanceIcon from '../../assets/Performance.svg';
import ActivePerformanceIcon from '../../assets/Performance_Selected.svg';
import CapacityLogo from '../../assets/material-symbols_capacities.svg';
import ActiveCapacityLogo from '../../assets/Capacity_Performance_Selected.svg';
import DatasetLogo from '../../assets/powerbi_dataset.svg';
import ActiveDatasetLogo from '../../assets/Dataset_Performance_Selected.svg';
import UsageLogo from '../../assets/powerbi_usage.svg';
import ActiveUsageLogo from '../../assets/Governance_Selected.svg';
import PowerBILogo from '../../assets/PowerBI.svg';
import ActivePowerBILogo from '../../assets/PowerBI_selected.svg';
import InfrastructureLogo from '../../assets/powerbi_infrastructure.svg';
import CapacityObservabilityLogo from '../../assets/powerbi_capacity_obs.svg';
import ActiveCapacityObservabilityLogo from '../../assets/powerbi_capacity_obs_selected.svg';
import ActiveInfrastructureLogo from '../../assets/Infrastructure_Selected.svg';
import CatalogueLogo from '../../assets/Catalogue&Quality.svg';
import ActiveCatalogueLogo from '../../assets/Catalogue&Quality_Selected.svg';
import VVPowerBILogo from '../../assets/PowerBI.svg';
import ActiveVVPowerBILogo from '../../assets/PowerBI_selected.svg';
import VVTableauLogo from '../../assets/Tableau.svg';
import ActiveVVTableauBILogo from '../../assets/Tableau_selected.svg';
import PrestoUsageIcon from '../../assets/Presto_Usage.svg';
import ActivePrestoUsageIcon from '../../assets/Presto_Usage_Selected.svg';
import PrestoCriticalIssuesIcon from '../../assets/Critical_Issues.svg';
import ActivePrestoCriticalIssuesIcon from '../../assets/Critical_Issues_Selected.svg';
import DataGrowthDatabricksIcon from '../../assets/datagrowth-databricks-symbols.svg';
import DataGrowthDatabricksActiveIcon from '../../assets/datagrowth-databricks-active-symbols.svg';
import DataGrowthPrestoIcon from '../../assets/datagrowth-presto-symbols.svg';
import DataGrowthPrestoActiveIcon from '../../assets/datagrowth-presto-active-symbols.svg';
import DataGrowthTableauIcon from '../../assets/datagrowth-tableau-symbols.svg';
import DataGrowthTableauActiveIcon from '../../assets/datagrowth-tableau-active-symbols.svg';
import IBPMonitoring from '../../assets/eos-icons_monitoring.svg';
import IBPDataQuality from '../../assets/DQ.svg';
import IBPDataQualityActive from '../../assets/DQ_selected.svg';
import ActiveQueryIcon from '../../assets/query_summary_blue.svg';
import QueryIcon from '../../assets/query.svg';
import DetailsIcon from '../../assets/finop-detail.svg';
import ActiveDetailsIcon from '../../assets/finop-detail-active.svg';

// import HomeIconPowerBI from '../../assets/home-icon.svg';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { AccountCircle } from '@mui/icons-material';
import useStyles from './headerStyles';
import logoutImg from '../../assets/material-symbols_logout.svg';
import { getDecodedToken, role, deleteCookie, capabilities } from '../../service/service';
import { platformCostObsDashboard, DataGrowthDashboard } from '../../service/config';
// import axios from 'axios';
import { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import ControlTestIcon from '../../assets/Control Dashboard_Unselected.svg';
import ActiveControlTestIcon from '../../assets/Control Dashboard_Selected.svg';
import AlteryxIcon from '../../assets/Alteryx.svg';
import ActiveAlteryxIcon from '../../assets/Alteryx_Selected.svg';
import TrifactaIcon from '../../assets/Trifacta.svg';
import ActiveTrifactaIcon from '../../assets/Trifacta_Selected.svg';
import IBPPlatform from '../../assets/ibp_platform.svg';
import IBPDQ from '../../assets/ibp_DQ.svg';
import StorageIcon from '../../assets/storage-summary.svg';
import ActiveBlueStorageIcon from '../../assets/storage_summary_blue.svg';
import ProcessingIcon from '../../assets/data-processing.svg';
import ActiveProcessingIcon from '../../assets/data processing-selected.svg';
import BIApplicationIcon from '../../assets/biApplication.svg';
import ActiveBIApplicationIcon from '../../assets/bi_application_blue.svg';
import serviceRequestIcon from '../../assets/service-request.svg';
import serviceRequestActiveIcon from '../../assets/service-request-active.svg';
import CostIcon from '../../assets/dataestate-cost.svg';
import ActiveCostIcon from '../../assets/cost_summary_blue.svg';
import ProgramIcon from '../../assets/program.svg';
import ActiveProgramIcon from '../../assets/program-actve.svg';
import IncidentIcon from '../../assets/Incident Impact Analysis.svg';
import ActiveIncidentIcon from '../../assets/Incident Impact Analysis_Selected.svg';
import { usePepwiseAuthController, setAuthenticated, clearCache } from 'Context/contexts/AuthContext';
import { clearDataCache, useCacheDataController } from 'Context/contexts/CacheDataContext';
import { Typography } from '@mui/material';

const ssoLoginLocalStore = localStorage.getItem('sso-login');
const ssoLogin = ssoLoginLocalStore ? ssoLoginLocalStore === 'true' : process.env.REACT_APP_SSO_LOGIN === 'true';
const sessionCookie = 'id_token';

const Sidebar = () => {
	const classes = useStyles();
	const { collapseSidebar, collapsed = false } = useProSidebar();
	const [expandedMenu, setExpandedMenu] = useState<number | null>(1);
	const navigate = useNavigate();
	const [, dispatch] = usePepwiseAuthController();
	const [, dataDispatch] = useCacheDataController();

	useEffect(() => {
		const activeId = document.getElementById('root');
		console.log(window.location.pathname);
		if (window.location.pathname.includes('/dataestate') && role !== 'Program Executive') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
			}
		}
		const storedState = localStorage.getItem('expandedMenu');
		if (storedState) {
			const parsedId = parseInt(storedState, 10);
			setExpandedMenu(isNaN(parsedId) ? 1 : parsedId); // Default to 1 if parse fails
		} else {
			setExpandedMenu(1); // Set default expanded menu on initial load
		}
	}, []);

	const toggleMenu = (menuId: number) => {
		setExpandedMenu((prevMenuId) => {
			const newMenuId = prevMenuId === menuId ? null : menuId;
			localStorage.setItem('expandedMenu', newMenuId !== null ? newMenuId.toString() : '');
			return newMenuId;
		});
	};
	const location = useLocation();
	const pathName = location.pathname;
	if (location.pathname === '/' || location.pathname === '/userLogin' || pathName === '/signup') {
		return null;
	}

	// navigate to landing page
	const handleHomeClick = () => {
		// Tableau drilldown filter changes
		localStorage.removeItem('selectedDateTime');
		localStorage.removeItem('selectedSector');
		localStorage.removeItem('prestoSelectedDateTime');
		navigate('/LandingPage',{ replace: true });
	};

	const logout = async () => {
		if (ssoLogin) {
			try {
				// const response = await axios.get('/sso/logout');
				window.location.replace('/sso/logout');
			} catch (error) {
				console.error('Logout error:', error);
			}
		} else {
			deleteCookie(sessionCookie);
			clearCache(dispatch)
			clearDataCache(dataDispatch);
			setAuthenticated(dispatch, false)
		}
	};

	const navigateByUrl = (url: string) => {
		navigate(url);
	}

	const renderSubIcon = (path: any) => {
		switch (path) {
			case '/master/sectors':
				return <img src={ActiveSectorIcon} className="menu-icon1" />;
			case '/master/systems':
				return <img src={ActiveSystemIcon} className="menu-icon1" />;
			case '/master/processes':
				return <img src={ActiveProcessIcon} className="menu-icon1" />;
			// // case '/user/list':
			// // 	return <img src={ActiveUsersIcon} className="menu-icon1" />;
			case '/master/reasonsOfSLA':
				return <img src={ActiveProcessIcon} className="menu-icon1" />;
			case '/ibp/monitor':
				return <img src={IBPMonitoring} className="menu-dashboard-icon1 menu-monitor-icon" />;
			case '/ibp/cost':
				return <img src={DollarIcon} className="menu-dashboard-icon1 menu-monitor-icon" />;
			case '/ibp/platform':
				return <img src={IBPPlatform} className="menu-dashboard-icon1 menu-monitor-icon menu-monitor-icon-dq" />;
			case '/ibp/data-quality':
				return <img src={IBPDQ} className="menu-dashboard-icon1 menu-monitor-icon menu-monitor-icon-dq" />;
			default:
				return <img src={MasterIcon} className="menu-icon" />;
		}
	};

	const renderDashboardSubIcon = (path: any) => {
		switch (path) {
			case '/dashboard/administrative':
				return <img src={ActiveAdministrativeIcon} className="menu-dashboard-icon1" />;
			case '/dashboard/operational':
				return <img src={ActiveOperationalIcon} className="menu-dashboard-icon1" />;
			case '/dashboard/jobPerformance':
				return <img src={ActiveOperationalIcon} className="menu-dashboard-icon1" />;
			case '/dashboard/business':
				return <img src={ActiveOperationalIcon} className="menu-dashboard-icon1" />;
			default:
				return <img src={DashboardIcon} className="menu-icon" />;
		}
	};
	return (
		<aside className="sidebar">
			<SidebarWrapper
				className={`${collapsed ? '' : 'expanded'}`}
				defaultCollapsed={false}
				collapsedWidth="60px"
				transitionDuration={200}
				onMouseOver={() => {
					if (collapsed) {
						collapseSidebar();
					}
				}}
			>
				<div className="home_logo">
					{role === 'D&AI Executive User' ||
					role === 'Program Executive' ||
					role === 'Data Operations Admin' ||
					role === 'Data Operations User' ? (
						''
					) : (
						<div className="home_logo">
							<div className="home_img">
								<img src={HomeIcon} alt="home" onClick={handleHomeClick} />
							</div>
							<div>
								<Link to={'/LandingPage'} className="sidebar_home">
									Home
								</Link>
								{/* </a> */}
							</div>
						</div>
					)}
				</div>

				{/* <img src={collapsed ? PepsicoLogoCollapsed : PepsicoLogo} className="logo" /> */}
				<Menu className="dashboardMenu">
					{!location.pathname.includes('platformCostObs') &&
					location.pathname !== DataGrowthDashboard &&
					location.pathname !== '/databricks/summary/dashboard' &&
					location.pathname !== '/databricks/resource/dashboard' &&
					location.pathname !== '/databricks/jobs/dashboard' &&
					location.pathname !== '/databricks/costSummary/dashboard' &&
					location.pathname !== '/databricks/governance/dashboard' &&
					location.pathname !== '/databricks/infrastructure/dashboard' &&
					location.pathname !== '/powerBI/capacity/dashboard' &&
					location.pathname !== '/powerBI/capacityObservability/dashboard' &&
					location.pathname !== '/powerBI/dataset/dashboard' &&
					location.pathname !== '/powerBI/usage/dashboard' &&
					location.pathname !== '/powerBI/datagrowth/dashboard' &&
					location.pathname !== '/powerBI/infrastructure/dashboard' &&
					location.pathname !== '/dataManagementFramework/healthSummary/dashboard' &&
					location.pathname !== '/dataManagementFramework/Catalogues/dashboard' &&
					location.pathname !== '/verificationValidation/dashboard' &&
					location.pathname !== '/verificationValidation/powerbi/dashboard' &&
					!location.pathname.includes('/tableau') &&
					!location.pathname.includes('/machineLearning') &&
					!location.pathname.includes('/presto') &&
					!location.pathname.includes('/dataGrowth') &&
					!location.pathname.includes('/controlsCompliance') &&
					!location.pathname.includes('/ibp') &&
					!location.pathname.includes('/dataestate') &&
					!location.pathname.includes('/databricks') &&
					!location.pathname.includes('/finops') &&
					!location.pathname.includes('/data-pipeline') &&
					(role === 'Platform Operations Admin/SRE' || role === 'PepWise System User') ? (
						<SubMenu
							label="Dashboard"
							defaultOpen={pathName.includes('dashboard/')}
							className={pathName.includes('dashboard/') ? 'active' : ''}
							icon={
								!collapsed ? (
									<>
										{/* <span className={pathName.includes('dashboard/') ? 'icon-material-symbols_dashboard active-icon' : 'icon-material-symbols_dashboard'}></span> */}
										<img src={DashboardIcon} className="menu-icon" />
										{pathName.includes('dashboard/') && <img src={ActiveDashIcon} className="menu-dashboard-icon1" />}
									</>
								) : (
									renderDashboardSubIcon(pathName)
								)
							}
						>
							{/* <MenuItem
								component={<Link to="/dashboard/administrative" />}
								className={pathName === '/dashboard/administrative' ? 'active' : ''}
								icon={
									pathName === '/dashboard/administrative' ? (
										<img src={ActiveAdministrativeIcon} className="menu-dashboard-icon" />
									) : (
										<img src={AdministrativeIcon} className="menu-dashboard-icon" />
									)
								}
							>
								Administrative
							</MenuItem>
							<MenuItem
								component={<Link to="/dashboard/operational" />}
								className={pathName === '/dashboard/operational' ? 'active' : ''}
								icon={
									pathName === '/dashboard/operational' ? (
										<img src={ActiveOperationalIcon} className="menu-dashboard-icon" />
									) : (
										<img src={OperationalIcon} className="menu-dashboard-icon" />
									)
								}
							>
								Operational
							</MenuItem>
							<MenuItem
								component={<Link to="/dashboard/jobPerformance" />}
								className={pathName === '/dashboard/jobPerformance' ? 'active' : ''}
								icon={
									pathName === '/dashboard/jobPerformance' ? (
										<img src={ActiveOperationalIcon} className="menu-dashboard-icon" />
									) : (
										<img src={OperationalIcon} className="menu-dashboard-icon" />
									)
								}
							>
								Jobs Performance
							</MenuItem> */}
							<MenuItem
								// component={<Link to="/dashboard/business" />} // TEMP FIX
								onClick={() => {
									navigate('/dashboard/business',{ replace: true });
								}}
								className={pathName.includes('/dashboard/business') ? 'active' : ''}
								icon={
									// <span className={pathName.includes('/dashboard/business') ? 'icon-material-symbols_Operationdashboard active-icon' : 'icon-material-symbols_Operationdashboard'}></span>

									pathName === '/dashboard/business' ? (
										<img src={ActiveOperationalIcon} className="menu-dashboard-icon1" />
									) : (
										<img src={OperationalIcon} className="menu-dashboard-icon" />
									)
								}
							>
								Platform Executive
							</MenuItem>
						</SubMenu>
					) : (
						''
					)}

					{location.pathname.includes('platformCostObs') &&
						(role === 'Platform Operations Admin/SRE' || role === 'PepWise System User') && (
							<MenuItem
								className={pathName.includes('/platformCostObs/dashboard') ? 'active' : ''}
								// component={<Link to="/platformCostObs/dashboard" />}
								component={<Link to="/platformCostObs/dashboard"/>}								
								icon={
									<>
										{/* <span className={pathName.includes('/platformCostObs/dashboard') ? 'icon-material-symbols_dashboard active-icon' : 'icon-material-symbols_dashboard'}></span> */}
										<img src={DashboardIcon} className="menu-icon" />
										{pathName.includes('/platformCostObs/dashboard') && (
											<img src={ActiveDashIcon} className="menu-dashboard-icon2" />
										)}
									</>
								}
							>
								Cost Dashboard
							</MenuItem>
						)}
					{location.pathname.includes('platformCostObs') &&
						(role === 'Platform Operations Admin/SRE' || role === 'PepWise System User') && (
							<MenuItem
								className={
									pathName.includes('/platformCostObs/resourceMapping') ||
									pathName.includes('/platformCostObs/resourceGroups')
										? 'active'
										: ''
								}
								component={<Link to="/platformCostObs/resourceMapping" />}
								icon={
									// <span className={pathName.includes(('/platformCostObs/resourceMapping') || ('/platformCostObs/resourceGroups')) ? 'icon-activeResourceMapping active-icon' : 'icon-activeResourceMapping'}></span>
									<>
										{/* <span className={pathName.includes(('/platformCostObs/resourceMapping') || ('/platformCostObs/resourceGroups')) ? 'icon-activeResourceMapping active-icon' : 'icon-activeResourceMapping'}></span> */}
										<img src={ResourceMappingIcon} className="menu-icon" />
										{(pathName.includes('/platformCostObs/resourceMapping') ||
											pathName.includes('/platformCostObs/resourceGroups')) && (
											<img src={ActiveResourceMappingIcon} className="menu-dashboard-icon2" />
										)}
									</>
								}
							>
								Resource Mapping
							</MenuItem>
						)}

					{!location.pathname.includes('platformCostObs') &&
						location.pathname !== DataGrowthDashboard &&
						location.pathname !== '/databricks/summary/dashboard' &&
						location.pathname !== '/databricks/resource/dashboard' &&
						location.pathname !== '/databricks/jobs/dashboard' &&
						location.pathname !== '/databricks/costSummary/dashboard' &&
						location.pathname !== '/databricks/governance/dashboard' &&
						location.pathname !== '/databricks/infrastructure/dashboard' &&
						location.pathname !== '/powerBI/capacity/dashboard' &&
						location.pathname !== '/powerBI/capacityObservability/dashboard' &&
						location.pathname !== '/powerBI/dataset/dashboard' &&
						location.pathname !== '/powerBI/usage/dashboard' &&
						location.pathname !== '/powerBI/datagrowth/dashboard' &&
						location.pathname !== '/powerBI/infrastructure/dashboard' &&
						location.pathname !== '/dataManagementFramework/healthSummary/dashboard' &&
						location.pathname !== '/dataManagementFramework/Catalogues/dashboard' &&
						!location.pathname.includes('/tableau') &&
						!location.pathname.includes('/machineLearning') &&
						!location.pathname.includes('/verificationValidation') &&
						!location.pathname.includes('/presto') &&
						!location.pathname.includes('/dataGrowth') &&
						!location.pathname.includes('/controlsCompliance') &&
						!location.pathname.includes('/ibp') &&
						!location.pathname.includes('/databricks') &&
						!location.pathname.includes('/dataestate') &&
						!location.pathname.includes('/finops') &&
						!location.pathname.includes('/data-pipeline') &&
						(role === 'Platform Operations Admin/SRE' || role === 'PepWise System User') && (
							<MenuItem
								className={pathName.includes('/jobs') ? 'active' : ''}
								component={<Link to="/jobs" />}
								icon={
									<>
										{/* <span className={pathName.includes('/jobs') ? 'icon-ri_file-list active-icon' : 'icon-ri_file-list'}></span> */}
										<img src={JobsIcon} className="menu-icon" />
										{pathName.includes('/jobs') && <img src={ActiveJobsIcon} className="menu-dashboard-icon1" />}
									</>
								}
							>
								Jobs
							</MenuItem>
						)}
					{!location.pathname.includes('platformCostObs') &&
						location.pathname !== DataGrowthDashboard &&
						location.pathname !== '/databricks/summary/dashboard' &&
						location.pathname !== '/databricks/resource/dashboard' &&
						location.pathname !== '/databricks/jobs/dashboard' &&
						location.pathname !== '/databricks/costSummary/dashboard' &&
						location.pathname !== '/databricks/governance/dashboard' &&
						location.pathname !== '/databricks/infrastructure/dashboard' &&
						location.pathname !== '/powerBI/capacity/dashboard' &&
						location.pathname !== '/powerBI/capacityObservability/dashboard' &&
						location.pathname !== '/powerBI/dataset/dashboard' &&
						location.pathname !== '/powerBI/usage/dashboard' &&
						location.pathname !== '/powerBI/datagrowth/dashboard' &&
						location.pathname !== '/powerBI/infrastructure/dashboard' &&
						location.pathname !== '/dataManagementFramework/healthSummary/dashboard' &&
						location.pathname !== '/dataManagementFramework/Catalogues/dashboard' &&
						!location.pathname.includes('/tableau') &&
						!location.pathname.includes('/machineLearning') &&
						!location.pathname.includes('/verificationValidation') &&
						!location.pathname.includes('/presto') &&
						!location.pathname.includes('/controlsCompliance') &&
						!location.pathname.includes('/ibp') &&
						!location.pathname.includes('/dataGrowth') &&
						!location.pathname.includes('/databricks') &&
						!location.pathname.includes('/dataestate') &&
						!location.pathname.includes('/finops') &&
						!location.pathname.includes('/data-pipeline') &&
						(role === 'Platform Operations Admin/SRE' || role === 'PepWise System User') && (
							<MenuItem
								className={location.pathname.includes('/jobExecutions') ? 'active' : ''}
								component={<Link to="/jobExecutions" />}
								icon={
									<>
										{/* <span className={pathName.includes('/jobExecutions') ? 'icon-uim_process active-icon' : 'icon-uim_process'}></span> */}
										<img src={JobExecution} className="menu-icon-schedule" />
										<img src={ActiveJobExecution} className="menu-icon-schedule1" />
									</>
								}
							>
								Job Executions
							</MenuItem>
						)}

					{!location.pathname.includes('platformCostObs') &&
						location.pathname !== DataGrowthDashboard &&
						location.pathname !== '/databricks/summary/dashboard' &&
						location.pathname !== '/databricks/resource/dashboard' &&
						location.pathname !== '/databricks/jobs/dashboard' &&
						location.pathname !== '/databricks/costSummary/dashboard' &&
						location.pathname !== '/databricks/governance/dashboard' &&
						location.pathname !== '/databricks/infrastructure/dashboard' &&
						location.pathname !== '/powerBI/capacity/dashboard' &&
						location.pathname !== '/powerBI/capacityObservability/dashboard' &&
						location.pathname !== '/powerBI/dataset/dashboard' &&
						location.pathname !== '/powerBI/usage/dashboard' &&
						location.pathname !== '/powerBI/datagrowth/dashboard' &&
						location.pathname !== '/powerBI/infrastructure/dashboard' &&
						location.pathname !== '/dataManagementFramework/healthSummary/dashboard' &&
						location.pathname !== '/dataManagementFramework/Catalogues/dashboard' &&
						!location.pathname.includes('/tableau') &&
						!location.pathname.includes('/machineLearning') &&
						!location.pathname.includes('/verificationValidation') &&
						!location.pathname.includes('/presto') &&
						!location.pathname.includes('/dataGrowth') &&
						!location.pathname.includes('/controlsCompliance') &&
						!location.pathname.includes('/ibp') &&
						!location.pathname.includes('/databricks') &&
						!location.pathname.includes('/dataestate') &&
						!location.pathname.includes('/finops') &&
						!location.pathname.includes('/data-pipeline') &&
						(role === 'Platform Operations Admin/SRE' || role === 'PepWise System User') && (
							<MenuItem
								className={location.pathname.includes('/jobSchedules') ? 'active' : ''}
								component={<Link to="/jobSchedules" />}
								icon={
									<>
										{/* <span className={pathName.includes('/jobSchedules') ? 'icon-material-symbols_schedules active-icon' : 'icon-material-symbols_schedules'}></span> */}
										<img src={Jobschedule} className="menu-icon-schedule" />
										<img src={ActiveJobschedule} className="menu-icon-schedule1" />
									</>
								}
							>
								Job Schedules
							</MenuItem>
						)}

					{!location.pathname.includes('platformCostObs') &&
						location.pathname !== DataGrowthDashboard &&
						location.pathname !== '/databricks/summary/dashboard' &&
						location.pathname !== '/databricks/resource/dashboard' &&
						location.pathname !== '/databricks/jobs/dashboard' &&
						location.pathname !== '/databricks/costSummary/dashboard' &&
						location.pathname !== '/databricks/governance/dashboard' &&
						location.pathname !== '/databricks/infrastructure/dashboard' &&
						location.pathname !== '/powerBI/capacity/dashboard' &&
						location.pathname !== '/powerBI/capacityObservability/dashboard' &&
						location.pathname !== '/powerBI/dataset/dashboard' &&
						location.pathname !== '/powerBI/usage/dashboard' &&
						location.pathname !== '/powerBI/datagrowth/dashboard' &&
						location.pathname !== '/powerBI/infrastructure/dashboard' &&
						location.pathname !== '/dataManagementFramework/healthSummary/dashboard' &&
						location.pathname !== '/dataManagementFramework/Catalogues/dashboard' &&
						!location.pathname.includes('/tableau') &&
						!location.pathname.includes('/machineLearning') &&
						!location.pathname.includes('/verificationValidation') &&
						!location.pathname.includes('/presto') &&
						!location.pathname.includes('/controlsCompliance') &&
						!location.pathname.includes('/ibp') &&
						!location.pathname.includes('/dataGrowth') &&
						!location.pathname.includes('/databricks/observation') &&
						!location.pathname.includes('/dataestate') &&
						!location.pathname.includes('/finops') &&
						!location.pathname.includes('/data-pipeline') &&
						(role === 'Platform Operations Admin/SRE' || role === 'PepWise System User') && (
							<MenuItem
								className={location.pathname.includes('/job/Infrastructure') ? 'active' : ''}
								component={<Link to="/job/Infrastructure" />}
								icon={
									<>
										{/* <span className={pathName.includes('/job/Infrastructure') ? 'icon-Infrastructure_Selected active-icon' : 'icon-Infrastructure_Selected'}></span> */}
										<img src={InfrastructureLogo} className="menu-icon-schedule" />
										<img src={ActiveInfrastructureLogo} className="menu-icon-schedule1" />
									</>
								}
							>
								Infrastructure
							</MenuItem>
						)}

					{/* Databricks Observability Sidebar */}
					{/* 1) Summary */}
					{(location.pathname === '/databricks/summary/dashboard' ||
						location.pathname === '/databricks/resource/dashboard' ||
						location.pathname === '/databricks/jobs/dashboard' ||
						location.pathname === '/databricks/costSummary/dashboard' ||
						location.pathname === '/databricks/governance/dashboard' ||
						location.pathname === '/databricks/infrastructure/dashboard' ||
						location.pathname.includes('/databricks/observation')) && (
						<MenuItem
							className={pathName.includes('/databricks/summary/dashboard') ? 'active' : ''}
							component={<Link to="/databricks/summary/dashboard" />}
							icon={
								<>
									{/* <span className={pathName.includes('/databricks/summary/dashboard') ? 'icon-material-symbols_dashboard active-icon' : 'icon-material-symbols_dashboard'}></span> */}
									<img src={DashboardIcon} className="menu-icon" />
									{pathName.includes('/databricks/summary/dashboard') && (
										<img src={ActiveDashIcon} className="menu-dashboard-icon1" />
									)}
								</>
							}
						>
							Summary
						</MenuItem>
					)}
					{/* 2) Resource Optimiztion */}
					{(location.pathname === '/databricks/summary/dashboard' ||
						location.pathname === '/databricks/resource/dashboard' ||
						location.pathname === '/databricks/jobs/dashboard' ||
						location.pathname === '/databricks/costSummary/dashboard' ||
						location.pathname === '/databricks/governance/dashboard' ||
						location.pathname === '/databricks/infrastructure/dashboard' ||
						location.pathname.includes('/databricks/observation')) && (
						<MenuItem
							className={pathName.includes('/databricks/resource/dashboard') ? 'active' : ''}
							component={<Link to="/databricks/resource/dashboard" />}
							icon={
								<>
									{/* <span className={pathName.includes('/databricks/resource/dashboard') ? 'icon-Resource-Optimization_Selected active-icon' : 'icon-Resource-Optimization_Selected'}></span> */}
									<img src={ResourceOptIcon} className="menu-icon" />
									{pathName.includes('/databricks/resource/dashboard') && (
										<img src={ActiveResourceOptIcon} className="menu-dashboard-icon1" />
									)}
								</>
							}
						>
							Resource Optimization
						</MenuItem>
					)}
					{/* 3) Databricks jobs */}
					{(location.pathname === '/databricks/summary/dashboard' ||
						location.pathname === '/databricks/resource/dashboard' ||
						location.pathname === '/databricks/jobs/dashboard' ||
						location.pathname === '/databricks/costSummary/dashboard' ||
						location.pathname === '/databricks/governance/dashboard' ||
						location.pathname === '/databricks/infrastructure/dashboard' ||
						location.pathname.includes('/databricks/observation')) && (
						<MenuItem
							className={pathName.includes('/databricks/jobs/dashboard') ? 'active' : ''}
							component={<Link to="/databricks/jobs/dashboard" />}
							icon={
								<>
									{/* <span className={pathName.includes('/databricks/jobs/dashboard') ? 'icon-ri_file-list active-icon' : 'icon-ri_file-list'}></span> */}
									<img src={JobsIcon} className="menu-icon" />
									{pathName.includes('/databricks/jobs/dashboard') && (
										<img src={ActiveJobsIcon} className="menu-dashboard-icon1" />
									)}
								</>
							}
						>
							Jobs
						</MenuItem>
					)}
					{/* 4) Cost summary */}
					{(location.pathname === '/databricks/summary/dashboard' ||
						location.pathname === '/databricks/resource/dashboard' ||
						location.pathname === '/databricks/jobs/dashboard' ||
						location.pathname === '/databricks/costSummary/dashboard' ||
						location.pathname === '/databricks/governance/dashboard' ||
						location.pathname === '/databricks/infrastructure/dashboard' ||
						location.pathname.includes('/databricks/observation')) && (
						<MenuItem
							className={pathName.includes('/databricks/costSummary/dashboard') ? 'active' : ''}
							component={<Link to="/databricks/costSummary/dashboard" />}
							icon={
								<>
									{/* <span className={pathName.includes('/databricks/costSummary/dashboard') ? 'icon-Cost-Summary active-icon' : 'icon-Cost-Summary'}></span> */}
									<img src={CostSummaryIcon} className="menu-icon" />
									{pathName.includes('/databricks/costSummary/dashboard') && (
										<img src={ActiveCostSummaryIcon} className="menu-dashboard-icon1" />
									)}
								</>
							}
						>
							Cost Summary
						</MenuItem>
					)}

					{/* 5) Governance */}
					{(location.pathname === '/databricks/summary/dashboard' ||
						location.pathname === '/databricks/resource/dashboard' ||
						location.pathname === '/databricks/jobs/dashboard' ||
						location.pathname === '/databricks/costSummary/dashboard' ||
						location.pathname === '/databricks/governance/dashboard' ||
						location.pathname === '/databricks/infrastructure/dashboard' ||
						location.pathname.includes('/databricks/observation')) && (
						<MenuItem
							className={pathName.includes('/databricks/governance/dashboard') ? 'active' : ''}
							component={<Link to="/databricks/governance/dashboard" />}
							icon={
								<>
									<img src={GovernanceIcon} className="menu-icon" />
									{pathName.includes('/databricks/governance/dashboard') && (
										<img src={ActiveGovernanceIcon} className="menu-dashboard-icon1" />
									)}
								</>
							}
						>
							Governance
						</MenuItem>
					)}

					{/* 6) infrastructure */}
					{(location.pathname === '/databricks/summary/dashboard' ||
						location.pathname === '/databricks/resource/dashboard' ||
						location.pathname === '/databricks/jobs/dashboard' ||
						location.pathname === '/databricks/costSummary/dashboard' ||
						location.pathname === '/databricks/governance/dashboard' ||
						location.pathname === '/databricks/infrastructure/dashboard' ||
						location.pathname.includes('/databricks/observation')) && (
						<MenuItem
							className={pathName.includes('/databricks/infrastructure/dashboard') ? 'active' : ''}
							component={<Link to="/databricks/infrastructure/dashboard" />}
							icon={
								<>
									{/* <span className={pathName.includes('/databricks/infrastructure/dashboard') ? 'icon-Infrastructure_Selected active-icon' : 'icon-Infrastructure_Selected'}></span> */}
									<img src={InfrastructureLogo} className="menu-icon" />
									{pathName.includes('/databricks/infrastructure/dashboard') && (
										<img src={ActiveInfrastructureLogo} className="menu-dashboard-icon1" />
									)}
								</>
							}
						>
							Infrastructure
						</MenuItem>
					)}
					{/* End of Databricks obs sidebar */}

					{/* Tableau obs sidebar */}
					{/* 1) Summary */}
					{location.pathname.includes('/tableau') &&
						(role === 'Platform Operations Admin/SRE' || role === 'PepWise System User') && (
							<MenuItem
								className={location.pathname === '/tableau/summary/dashboard' ? 'active' : ''}
								component={<Link to="/tableau/summary/dashboard" />}
								icon={
									<>
										{/* <span className={pathName.includes('/tableau/summary/dashboard') ? 'icon-material-symbols_dashboard active-icon' : 'icon-material-symbols_dashboard'}></span> */}
										<img src={DashboardIcon} className="menu-icon" />
										{pathName.includes('/tableau/summary/dashboard') && (
											<img src={ActiveDashIcon} className="menu-dashboard-icon1" />
										)}
									</>
								}
							>
								Summary
							</MenuItem>
						)}

					{/* 2) Performance */}
					{location.pathname.includes('/tableau') &&
						(role === 'Platform Operations Admin/SRE' || role === 'PepWise System User') && (
							<MenuItem
								className={
									location.pathname === '/tableau/performance/dashboard' ||
									location.pathname === '/tableau/performance/dashboard'
										? 'active'
										: ''
								}
								component={<Link to="/tableau/performance/dashboard" />}
								icon={
									<>
										{/* <span className={pathName.includes('/tableau/performance/dashboard') ? 'icon-Performance_Selected active-icon' : 'icon-Performance_Selected'}></span> */}
										{location.pathname === '/tableau/performance/dashboard' ||
										location.pathname === '/tableau/governance/dashboard' ||
										location.pathname === '/tableau/summary/dashboard' ||
										location.pathname === '/tableau/infrastructure/dashboard' ||
										pathName.includes('/tableau/observation') ? (
											<img src={PerformanceIcon} className="menu-icon" />
										) : (
											''
										)}
										{(pathName.includes('/tableau/performance/dashboard') ||
											pathName.includes('/tableau/jobdetails')) && (
											<img src={ActivePerformanceIcon} className="menu-dashboard-icon1" />
										)}
									</>
								}
							>
								Performance
							</MenuItem>
						)}

					{/* 3) Governance */}
					{location.pathname.includes('/tableau') &&
						(role === 'Platform Operations Admin/SRE' || role === 'PepWise System User') && (
							<MenuItem
								className={location.pathname === '/tableau/governance/dashboard' ? 'active' : ''}
								component={<Link to="/tableau/governance/dashboard" />}
								icon={
									<>
										<img src={GovernanceIcon} className="menu-icon" />
										{location.pathname === '/tableau/governance/dashboard' && (
											<img src={ActiveGovernanceIcon} className="menu-dashboard-icon1" />
										)}
									</>
								}
							>
								Governance
							</MenuItem>
						)}

					{/* 4) infrastructure */}
					{location.pathname.includes('/tableau') &&
						(role === 'Platform Operations Admin/SRE' || role === 'PepWise System User') && (
							<MenuItem
								className={location.pathname === '/tableau/infrastructure/dashboard' ? 'active' : ''}
								component={<Link to="/tableau/infrastructure/dashboard" />}
								icon={
									<>
										{/* <span className={pathName.includes('/tableau/infrastructure/dashboard') ? 'icon-Infrastructure_Selected active-icon' : 'icon-Infrastructure_Selected'}></span> */}
										<img src={InfrastructureLogo} className="menu-icon" />
										{pathName.includes('/tableau/infrastructure/dashboard') && (
											<img src={ActiveInfrastructureLogo} className="menu-dashboard-icon1" />
										)}
									</>
								}
							>
								Infrastructure
							</MenuItem>
						)}
					{/* End of Tableau obs sidebar */}

					{/* PowerBI obs sidebar */}
					{/* 1) capacity */}
					{(location.pathname === '/powerBI/capacity/dashboard' ||
					    location.pathname === '/powerBI/capacityObservability/dashboard' ||
						location.pathname === '/powerBI/dataset/dashboard' ||
						location.pathname === '/powerBI/usage/dashboard' ||
						location.pathname === '/powerBI/datagrowth/dashboard' ||
						location.pathname === '/powerBI/infrastructure/dashboard') && (
						<MenuItem
							className={pathName.includes('/powerBI/capacity/dashboard') ? 'active' : ''}
							component={<Link to="/powerBI/capacity/dashboard" />}
							icon={
								<>
									{/* <span className={pathName.includes('/powerBI/capacity/dashboard') ? 'icon-Capacity_Performance active-icon' : 'icon-Capacity_Performance'}></span> */}
									<img src={CapacityLogo} className="menu-icon" />
									{pathName.includes('/powerBI/capacity/dashboard') && (
										<img src={ActiveCapacityLogo} className="menu-dashboard-icon1" />
									)}
								</>
							}
						>
							Capacity Performance
						</MenuItem>
					)}

					{/* 2) dataset */}
					{(location.pathname === '/powerBI/capacity/dashboard' ||
					    location.pathname === '/powerBI/capacityObservability/dashboard' ||
						location.pathname === '/powerBI/dataset/dashboard' ||
						location.pathname === '/powerBI/usage/dashboard' ||
						location.pathname === '/powerBI/datagrowth/dashboard' ||
						location.pathname === '/powerBI/infrastructure/dashboard') && (
						<MenuItem
							className={pathName.includes('/powerBI/dataset/dashboard') ? 'active' : ''}
							component={<Link to="/powerBI/dataset/dashboard" />}
							icon={
								<>
									{/* <span className={pathName.includes('/powerBI/dataset/dashboard') ? 'icon-powerbi_dataset active-icon' : 'icon-powerbi_dataset'}></span> */}
									<img src={DatasetLogo} className="menu-icon" />
									{pathName.includes('/powerBI/dataset/dashboard') && (
										<img src={ActiveDatasetLogo} className="menu-dashboard-icon1" />
									)}
								</>
							}
						>
							Dataset Performance
						</MenuItem>
					)}

					{/* 3) usage */}
					{(location.pathname === '/powerBI/capacity/dashboard' ||
					    location.pathname === '/powerBI/capacityObservability/dashboard' ||
						location.pathname === '/powerBI/dataset/dashboard' ||
						location.pathname === '/powerBI/usage/dashboard' ||
						location.pathname === '/powerBI/datagrowth/dashboard' ||
						location.pathname === '/powerBI/infrastructure/dashboard') && (
						<MenuItem
							className={pathName.includes('/powerBI/usage/dashboard') ? 'active' : ''}
							component={<Link to="/powerBI/usage/dashboard" />}
							icon={
								<>
									<img src={UsageLogo} className="menu-icon" />
									{pathName.includes('/powerBI/usage/dashboard') && (
										<img src={ActiveUsageLogo} className="menu-dashboard-icon1" />
									)}
								</>
							}
						>
							Usage & Governance
						</MenuItem>
					)}
					{/* 4) datagrowth */}
					{/*
					{(location.pathname === '/powerBI/capacity/dashboard' ||
						location.pathname === '/powerBI/dataset/dashboard' ||
						location.pathname === '/powerBI/usage/dashboard' ||
						location.pathname === '/powerBI/datagrowth/dashboard' ||
						location.pathname === '/powerBI/infrastructure/dashboard') && (
							<MenuItem
								className={pathName.includes('/powerBI/datagrowth/dashboard') ? 'active' : ''}
								component={<Link to="/powerBI/datagrowth/dashboard" />}
								icon={
									<>
										{/* <span className={pathName.includes('/powerBI/datagrowth/dashboard') ? 'icon-powerbi_datagrowth active-icon' : 'icon-powerbi_datagrowth'}></span> */}

					{/* <img src={DataGrowthLogo} className="menu-icon" />
										{pathName.includes('/powerBI/datagrowth/dashboard') && (
											<img src={ActiveDataGrowthLogo} className="menu-dashboard-icon1" />
										)}
									</>
								}
							>
								DataGrowth
							</MenuItem>
						)}
						 */}
					{/* 5) infrastructure */}
					{(location.pathname === '/powerBI/capacity/dashboard' ||
					    location.pathname === '/powerBI/capacityObservability/dashboard' ||
						location.pathname === '/powerBI/dataset/dashboard' ||
						location.pathname === '/powerBI/usage/dashboard' ||
						location.pathname === '/powerBI/datagrowth/dashboard' ||
						location.pathname === '/powerBI/infrastructure/dashboard') && (
						<MenuItem
							className={pathName.includes('/powerBI/infrastructure/dashboard') ? 'active' : ''}
							component={<Link to="/powerBI/infrastructure/dashboard" />}
							icon={
								<>
									{/* <span className={pathName.includes('/powerBI/infrastructure/dashboard') ? 'icon-Infrastructure_Selected active-icon' : 'icon-Infrastructure_Selected'}></span> */}
									<img src={InfrastructureLogo} className="menu-icon" />
									{pathName.includes('/powerBI/infrastructure/dashboard') && (
										<img src={ActiveInfrastructureLogo} className="menu-dashboard-icon1" />
									)}
								</>
							}
						>
							Infrastructure
						</MenuItem>
					)}
					{/* 6) capacity observability */}
					{(location.pathname === '/powerBI/capacity/dashboard' ||
						location.pathname === '/powerBI/dataset/dashboard' ||
						location.pathname === '/powerBI/usage/dashboard' ||
						location.pathname === '/powerBI/datagrowth/dashboard' ||
						location.pathname === '/powerBI/infrastructure/dashboard' ||
					    location.pathname === '/powerBI/capacityObservability/dashboard') && (
						<MenuItem
							className={pathName.includes('/powerBI/capacityObservability/dashboard') ? 'active' : ''}
							component={<Link to="/powerBI/capacityObservability/dashboard" />}
							icon={
								<>
									{/* <span className={pathName.includes('/powerBI/dataset/dashboard') ? 'icon-powerbi_dataset active-icon' : 'icon-powerbi_dataset'}></span> */}
									<img src={pathName.includes('/powerBI/capacityObservability/dashboard') ? ActiveCapacityObservabilityLogo : CapacityObservabilityLogo} className="menu-dashboard-icon"/>
								</>
							}
						>
							Capacity Observability
						</MenuItem>
					)}


					{/* End of PowerBI obs sidebar */}

					{/* DMF obs sidebar */}
					{/* 1) HealthSummary */}
					{(location.pathname === '/dataManagementFramework/healthSummary/dashboard' ||
						location.pathname === '/dataManagementFramework/Catalogues/dashboard') && (
						<MenuItem
							className={pathName.includes('/dataManagementFramework/healthSummary/dashboard') ? 'active' : ''}
							component={<Link to="/dataManagementFramework/healthSummary/dashboard" />}
							icon={
								<>
									{/* <span className={pathName.includes('/dataManagementFramework/healthSummary/dashboard') ? 'icon-material-symbols_dashboard active-icon' : 'icon-material-symbols_dashboard'}></span> */}
									<img src={DashboardIcon} className="menu-icon" />
									{pathName.includes('/dataManagementFramework/healthSummary/dashboard') && (
										<img src={ActiveDashIcon} className="menu-dashboard-icon1" />
									)}
								</>
							}
						>
							Health Summary
						</MenuItem>
					)}

					{/* 2) Catalogues */}
					{(location.pathname === '/dataManagementFramework/healthSummary/dashboard' ||
						location.pathname === '/dataManagementFramework/Catalogues/dashboard') && (
						<MenuItem
							className={pathName.includes('/dataManagementFramework/Catalogues/dashboard') ? 'active' : ''}
							component={<Link to="/dataManagementFramework/Catalogues/dashboard" />}
							icon={
								<>
									{/* <span className={pathName.includes('/dataManagementFramework/Catalogues/dashboard') ? 'icon-CatalogueQuality_Selected active-icon' : 'icon-CatalogueQuality_Selected'}></span> */}
									<img src={CatalogueLogo} className="menu-icon" />
									{pathName.includes('/dataManagementFramework/Catalogues/dashboard') && (
										<img src={ActiveCatalogueLogo} className="menu-dashboard-icon1" />
									)}
								</>
							}
						>
							Catalogues & Quality
						</MenuItem>
					)}
					{/* End of DMF obs sidebar */}

					{/* VV obs sidebar */}
					{/* 1) Tableau */}

					{location.pathname.includes('/verificationValidation') &&
						(role === 'Platform Operations Admin/SRE' || role === 'PepWise System User') && (
							<MenuItem
								className={pathName.includes('/verificationValidation/dashboard') ? 'active' : ''}
								component={<Link to="/verificationValidation/dashboard" />}
								icon={
									<>
										{/* <span className={pathName.includes('/verificationValidation/dashboard') ? 'icon-Tableau_selected active-icon' : 'icon-Tableau_selected'}></span> */}
										<img src={VVTableauLogo} className="menu-icon" />
										{pathName.includes('/verificationValidation/dashboard') && (
											<img src={ActiveVVTableauBILogo} className="menu-dashboard-icon1" />
										)}
									</>
								}
							>
								Tableau
							</MenuItem>
						)}

					{/* 1) Powerbi */}
					{location.pathname.includes('/verificationValidation') &&
						(role === 'Platform Operations Admin/SRE' || role === 'PepWise System User') && (
							<MenuItem
								className={pathName.includes('/verificationValidation/powerbi/dashboard') ? 'active' : ''}
								component={<Link to="/verificationValidation/powerbi/dashboard" />}
								icon={
									<>
										{/* <span className={pathName.includes('/verificationValidation/powerbi/dashboard') ? 'icon-PowerBI active-icon' : 'icon-PowerBI'}></span> */}
										<img src={VVPowerBILogo} className="menu-icon" />
										{pathName.includes('/verificationValidation/powerbi/dashboard') && (
											<img src={ActiveVVPowerBILogo} className="menu-dashboard-icon1" />
										)}
									</>
								}
							>
								Power BI
							</MenuItem>
						)}
					{/* End of DMF obs sidebar */}

					{/* ML obs sidebar */}
					{/* 1) summary */}
					{location.pathname.includes('/machineLearning') &&
						(role === 'Platform Operations Admin/SRE' || role === 'PepWise System User') && (
							<MenuItem
								className={pathName.includes('/machineLearning') ? 'active' : ''}
								component={<Link to="/machineLearning/dashboard" />}
								icon={
									<>
										{/* <span className={pathName.includes('/machineLearning/dashboard') ? 'icon-material-symbols_dashboard active-icon' : 'icon-material-symbols_dashboard'}></span> */}
										<img src={DashboardIcon} className="menu-icon" />
										{pathName.includes('/machineLearning') && (
											<img src={ActiveDashIcon} className="menu-dashboard-icon1" />
										)}
									</>
								}
							>
								Summary
							</MenuItem>
						)}

					{/* End of ML obs sidebar */}

					{/* Presto obs sidebar */}
					{/* 1) summary */}
					{location.pathname.includes('/presto') &&
						(role === 'Platform Operations Admin/SRE' || role === 'PepWise System User') && (
							<MenuItem
								className={location.pathname === '/presto/dashboard' ? 'active' : ''}
								component={<Link to="/presto/dashboard" />}
								icon={
									<>
										{/* <span className={pathName.includes('/presto/dashboard') ? 'icon-material-symbols_dashboard active-icon' : 'icon-material-symbols_dashboard'}></span> */}
										<img src={DashboardIcon} className="menu-icon" />
										{pathName.includes('/presto/dashboard') && (
											<img src={ActiveDashIcon} className="menu-dashboard-icon1" />
										)}
									</>
								}
							>
								Summary
							</MenuItem>
						)}
					{/* 2) usage & governance */}
					{/* {location.pathname.includes('/presto') && (
						<MenuItem
							className={location.pathname === '/presto/usageGovernance' ? 'active' : ''}
							component={<Link to="/presto/usageGovernance" />}
							icon={
								<>
									<img src={PrestoUsageIcon} className="menu-icon" />
									{location.pathname === '/presto/usageGovernance' && (
										<img src={ActivePrestoUsageIcon} className="menu-dashboard-icon1" />
									)}
								</>
							}
						>
							Usage & Governance
						</MenuItem>
					)} */}

					{/* 3) Critical Issues */}
					{/* {location.pathname.includes('/presto') && (
						<MenuItem
							className={location.pathname === '/presto/criticalIssue' ? 'active' : ''}
							component={<Link to="/presto/criticalIssue" />}
							icon={
								<>
									<img src={PrestoCriticalIssuesIcon} className="menu-icon" />
									{pathName.includes('/presto/criticalIssue') && (
										<img src={ActivePrestoCriticalIssuesIcon} className="menu-dashboard-icon1" />
									)}
								</>
							}
						>
							Critical Issues
						</MenuItem>
					)} */}

					{/* End of Presto obs sidebar */}

					{/* Control&Compliance obs sidebar */}
					{/* 1) Controls Testing Dashboard  */}
					{location.pathname.includes('/controlsCompliance') &&
						(role === 'Platform Operations Admin/SRE' || role === 'PepWise System User') && (
							<MenuItem
								className={pathName.includes('/controlsCompliance/dashboard') ? 'active' : ''}
								component={<Link to="/controlsCompliance/dashboard" />}
								icon={
									<>
										<img src={ControlTestIcon} className="menu-icon" />
										{pathName.includes('/controlsCompliance/dashboard') && (
											<img src={ActiveControlTestIcon} className="menu-dashboard-icon1" />
										)}
									</>
								}
							>
								Controls Testing Dashboard
							</MenuItem>
						)}

					{/* End of Control&Compliance obs sidebar */}

					{/* dataGrowth obs sidebar */}

					{/* 1) Dashboard */}
					{location.pathname.includes('dataGrowth') &&
						(role === 'Platform Operations Admin/SRE' || role === 'PepWise System User') && (
							<MenuItem
								className={pathName.includes('/dataGrowth/dashboard') ? 'active' : ''}
								component={<Link to="/dataGrowth/dashboard" />}
								icon={
									<>
										<img src={DashboardIcon} className="menu-icon" />
										{pathName.includes('/dataGrowth/dashboard') && (
											<img src={ActiveDashIcon} className="menu-dashboard-icon1" />
										)}
									</>
								}
							>
								Dashboard
							</MenuItem>
						)}

					{/*2) data growth*/}

					{location.pathname.includes('/dataGrowth') &&
						(role === 'Platform Operations Admin/SRE' || role === 'PepWise System User') && (
							<MenuItem
								className={location.pathname == '/dataGrowthDataGrowth/dashboard' ? 'active' : ''}
								component={<Link to="/dataGrowthDataGrowth/dashboard" />}
								icon={
									<>
										<img src={PowerBILogo} className="menu-icon" />
										{location.pathname == '/dataGrowthDataGrowth/dashboard' && (
											<img src={ActivePowerBILogo} className="menu-dashboard-icon1" />
										)}
									</>
								}
							>
								Power BI
							</MenuItem>
						)}

					{/* 2) Databricks */}
					{location.pathname.includes('/dataGrowth') &&
						(role === 'Platform Operations Admin/SRE' || role === 'PepWise System User') && (
							<MenuItem
								className={pathName.includes('/dataGrowthDatabricks/dashboard') ? 'active' : ''}
								component={<Link to="/dataGrowthDatabricks/dashboard" />}
								icon={
									<>
										<img src={DataGrowthDatabricksIcon} className="menu-icon" />
										{pathName.includes('/dataGrowthDatabricks/dashboard') && (
											<img src={DataGrowthDatabricksActiveIcon} className="menu-dashboard-icon1" />
										)}
									</>
								}
							>
								Databricks
							</MenuItem>
						)}

					{/* 3) presto */}
					{location.pathname.includes('/dataGrowth') &&
						(role === 'Platform Operations Admin/SRE' || role === 'PepWise System User') && (
							<MenuItem
								className={location.pathname == '/dataGrowthPresto/dashboard' ? 'active' : ''}
								component={<Link to="/dataGrowthPresto/dashboard" />}
								icon={
									<>
										<img src={DataGrowthPrestoIcon} className="menu-icon" />
										{location.pathname == '/dataGrowthPresto/dashboard' && (
											<img src={DataGrowthPrestoActiveIcon} className="menu-dashboard-icon1" />
										)}
									</>
								}
							>
								Presto
							</MenuItem>
						)}

					{/* 3) tableau */}
					{location.pathname.includes('/dataGrowth') &&
						(role === 'Platform Operations Admin/SRE' || role === 'PepWise System User') && (
							<MenuItem
								className={location.pathname == '/dataGrowthTableau/dashboard' ? 'active' : ''}
								component={<Link to="/dataGrowthTableau/dashboard" />}
								icon={
									<>
										<img src={DataGrowthTableauIcon} className="menu-icon" />
										{location.pathname == '/dataGrowthTableau/dashboard' && (
											<img src={DataGrowthTableauActiveIcon} className="menu-dashboard-icon1" />
										)}
									</>
								}
							>
								Tableau
							</MenuItem>
						)}
					{location.pathname.includes('/ibp') && role !== 'Program Executive' && (
						<>
							<MenuItem
								className={pathName.includes('/ibp/dashboard') ? 'active' : ''}
								component={<Link to="/ibp/dashboard" />}
								icon={
									<>
										<img src={DashboardIcon} className="menu-dashboard-icon1" />
									</>
								}
							>
								IBP Jobs Monitoring
							</MenuItem>

							<MenuItem
								className={pathName.includes('/ibp/executive') ? 'active' : ''}
								component={<Link to="/ibp/executive" />}
								icon={
									<>
										<img src={MasterIcon} className="menu-icon" />
										<img src={ActiveMasterIcon} className="menu-icon1" />
									</>
								}
							>
								IBP Executive Dashboard
							</MenuItem>

							<MenuItem
								className={pathName.includes('/ibp/bdr-pbi') ? 'active' : ''}
								component={<Link to="/ibp/bdr-pbi" />}
								icon={
									<>
										<img src={IBPDataQuality} className="menu-icon ibp-dq" />
										<img src={IBPDataQualityActive} className="menu-icon1 ibp-dq" />
									</>
								}
							>
								BDR Dashboard
							</MenuItem>
						</>
					)}
					{/* IBP screen for Program Executive */}
					{role == 'Program Executive' && (
						<>
							<div className="menu-container">
								<MenuItem
									className={expandedMenu === 1 ? 'active' : ''}
									icon={
										<>
											<img src={IBPLogo} alt="logo" className="dataEstate_logo" />
										</>
									}
									onClick={() => toggleMenu(1)}
								>
									<span className="DAIExecutive_title">IBP</span>
								</MenuItem>
								{expandedMenu === 1 && (
									<div className="menu-items">
										<MenuItem
											className={pathName.includes('/ibp/executive') ? 'active' : ''}
											component={<Link to="/ibp/executive" />}
											icon={
												<span className="DAIExecutive_icon">
													<img src={MasterIcon} className="menu-icon" />
													<img src={ActiveMasterIcon} className="menu-icon1" />
												</span>
											}
										>
											<span className="DAIExecutive_menus">IBP Executive Dashboard</span>
										</MenuItem>

										<MenuItem
											className={pathName.includes('/ibp/bdr-pbi') ? 'active' : ''}
											component={<Link to="/ibp/bdr-pbi" />}
											icon={
												<span className="DAIExecutive_icon">
													<img src={IBPDataQuality} className="menu-icon ibp-dq" />
													<img src={IBPDataQualityActive} className="menu-icon1 ibp-dq" />
												</span>
											}
										>
											<span className="DAIExecutive_menus">BDR Dashboard</span>
										</MenuItem>
									</div>
								)}
								<MenuItem
									className={expandedMenu === 2 ? 'active' : ''}
									icon={
										<>
											<img src={PcobsLogo} alt="logo" className="dataEstate_logo" />
										</>
									}
									onClick={() => toggleMenu(2)}
								>
									<span className="DAIExecutive_title">Platform Cost</span>
								</MenuItem>
								{expandedMenu === 2 && (
									<div className="menu-items">
										<MenuItem
											className={pathName.includes('/dataestate/platform/cost') ? 'active' : ''}
											component={<Link to="/dataestate/platform/cost" />}
											icon={
												<span className="DAIExecutive_icon">
													{/* <span className={pathName.includes('/platformCostObs/dashboard') ? 'icon-material-symbols_dashboard active-icon' : 'icon-material-symbols_dashboard'}></span> */}
													<img src={DashboardIcon} className="menu-icon" />
													{pathName.includes('/dataestate/platform/cost') && (
														<img src={ActiveDashIcon} className="menu-dashboard-icon2" />
													)}
												</span>
											}
										>
											<span className="DAIExecutive_menus">Cost Dashboard</span>
										</MenuItem>
										{/* <MenuItem
											className={
												pathName.includes('/dataestate/platform/resourceMapping') ||
												pathName.includes('/dataestate/platform/resourceGroups/:resourceGroup')
													? 'active'
													: ''
											}
											component={<Link to="/dataestate/platform/resourceMapping" />}
											icon={
												<span className="DAIExecutive_icon">
													<img src={ResourceMappingIcon} className="menu-icon" />
													{(pathName.includes('/dataestate/platform/resourceMapping') ||
														pathName.includes('/dataestate/platform/resourceGroups/:resourceGroup')) && (
														<img src={ActiveResourceMappingIcon} className="menu-dashboard-icon2" />
													)}
												</span>
											}
										>
											<span className="DAIExecutive_menus">Resource Mapping</span>
										</MenuItem> */}
									</div>
								)}
								<MenuItem
									className={expandedMenu === 3 ? 'active' : ''}
									icon={
										<>
											<img src={FinopsLogo} alt="logo" className="dataEstate_logo" />
										</>
									}
									onClick={() => toggleMenu(3)}
								>
									<span className="DAIExecutive_title">Fin Ops</span>
								</MenuItem>
								{expandedMenu === 3 && (
									<div className="menu-items">
										<MenuItem
											className={pathName.includes('/dataestate/financialOverview') ? 'active' : ''}
											component="a"
											href="/dataestate/financialOverview"
											icon={
												<span className="DAIExecutive_icon">
													<img src={DashboardIcon} className="menu-icon" />
													<img src={ActiveBlueDashIcon} className="menu-icon1" />
												</span>
											}
										>
											<span className="DAIExecutive_menus">Overview</span>
										</MenuItem>

										<MenuItem
											className={pathName.includes('/dataestate/financialProgram') ? 'active' : ''}
											component={<Link to="/dataestate/financialProgram" />}
											icon={
												<span className="DAIExecutive_icon">
													<img src={ProgramIcon} className="menu-icon" />
													<img src={ActiveProgramIcon} className="menu-icon1" />
												</span>
											}
										>
											<span className="DAIExecutive_menus">Program Summary</span>
										</MenuItem>
										<MenuItem
											className={pathName.includes('/dataestate/financialDetails') ? 'active' : ''}
											component={<Link to="/dataestate/financialDetails" />}
											icon={
												<span className="DAIExecutive_icon">
													<img src={DetailsIcon} className="menu-icon" />
													<img src={ActiveDetailsIcon} className="menu-icon1" />
												</span>
											}
										>
											<span className="DAIExecutive_menus">Details Summary</span>
										</MenuItem>
									</div>
								)}
							</div>
						</>
					)}

					{/* Data Estate Dashboard */}

					{location.pathname.includes('/dataestate') &&
						role !== 'D&AI Executive User' &&
						role !== 'Program Executive' && (
							<>
								<MenuItem
									className={pathName.includes('/dataestate/executive-summary') ? 'active' : ''}
									component={<Link to="/dataestate/executive-summary" />}
									icon={
										<>
											<img src={DashboardIcon} className="menu-icon" />
											<img src={ActiveBlueDashIcon} className="menu-icon1" />
										</>
									}
								>
									Executive Summary
								</MenuItem>

								<MenuItem
									className={pathName.includes('/dataestate/storagesummary') ? 'active' : ''}
									component={<Link to="/dataestate/storagesummary" />}
									icon={
										<>
											<img src={StorageIcon} className="menu-icon" />
											<img src={ActiveBlueStorageIcon} className="menu-icon1" />
										</>
									}
								>
									Storage Summary
								</MenuItem>
								<MenuItem
									className={pathName.includes('/dataestate/queriessummary') ? 'active' : ''}
									component={<Link to="/dataestate/queriessummary" />}
									icon={
										<>
											<img src={QueryIcon} className="menu-icon" />
											<img src={ActiveQueryIcon} className="menu-icon1" />
										</>
									}
								>
									Query Summary
								</MenuItem>

								<MenuItem
									className={pathName.includes('/dataestate/data-processing') ? 'active' : ''}
									component={<Link to="/dataestate/data-processing" />}
									icon={
										<>
											<img src={ProcessingIcon} className="menu-icon" />
											<img src={ActiveProcessingIcon} className="menu-icon1" />
										</>
									}
								>
									Data Processing Summary
								</MenuItem>
								<MenuItem
									className={pathName.includes('/dataestate/biApplicationSummary') ? 'active' : ''}
									component={<Link to="/dataestate/biApplicationSummary" />}
									icon={
										<>
											<img src={BIApplicationIcon} className="menu-icon biAppIcon" />
											<img src={ActiveBIApplicationIcon} className="menu-icon1 biAppIcon-active" />
										</>
									}
								>
									BI Application Summary
								</MenuItem>
								<MenuItem
									className={pathName.includes('/dataestate/costSummary') ? 'active' : ''}
									component={<Link to="/dataestate/costSummary" />}
									icon={
										<>
											<img src={CostIcon} className="menu-icon dataestateCostIcon" />
											<img src={ActiveCostIcon} className="menu-icon1 dataestateCostIcon" />
										</>
									}
								>
									Cost Summary
								</MenuItem>
								<MenuItem
									className={pathName.includes('/dataestate/service-request') ? 'active' : ''}
									component={<Link to="/dataestate/service-request" />}
									icon={
										<>
											<img src={serviceRequestIcon} className="menu-icon service-request" />
											<img src={serviceRequestActiveIcon} className="menu-icon1 service-request" />
										</>
									}
								>
									Major INC Summary
								</MenuItem>

								<MenuItem
									className={pathName.includes('/dataestate/incident') ? 'active' : ''}
									component={<Link to="/dataestate/incident-details" />}
									icon={
										<>
											<img src={IncidentIcon} className="menu-icon" />
											{pathName.includes('/dataestate/incident') && (
												<img src={ActiveIncidentIcon} className="menu-dashboard-icon1" />
											)}
										</>
									}
								>
									Incident Details
								</MenuItem>
							</>
						)}
					{/* End of Data Estate Dashboard */}

					{role === 'D&AI Executive User' && (
						<>
							<div className="menu-container">
								<MenuItem
									className={expandedMenu === 1 ? 'active' : ''}
									icon={
										<>
											<img src={DataEstateLogo} alt="logo" className="dataEstate_logo" />
										</>
									}
									onClick={() => toggleMenu(1)}
								>
									<span className="DAIExecutive_title">Data Estate</span>
								</MenuItem>
								{expandedMenu === 1 && (
									<div className="menu-items">
										<MenuItem
											className={pathName.includes('/dataestate/executive-summary') ? 'active' : ''}
											component={<Link to="/dataestate/executive-summary" />}
											icon={
												<span className="DAIExecutive_icon">
													<img src={DashboardIcon} className="menu-icon" />
													<img src={ActiveBlueDashIcon} className="menu-icon1" />
												</span>
											}
										>
											<span className="DAIExecutive_menus">Executive Summary</span>
										</MenuItem>

										<MenuItem
											className={pathName.includes('/dataestate/storagesummary') ? 'active' : ''}
											component={<Link to="/dataestate/storagesummary" />}
											icon={
												<span className="DAIExecutive_icon">
													<img src={StorageIcon} className="menu-icon" />
													<img src={ActiveBlueStorageIcon} className="menu-icon1" />
												</span>
											}
										>
											<span className="DAIExecutive_menus">Storage Summary</span>
										</MenuItem>
										<MenuItem
											className={pathName.includes('/dataestate/queriessummary') ? 'active' : ''}
											component={<Link to="/dataestate/queriessummary" />}
											icon={
												<span className="DAIExecutive_icon">
													<img src={QueryIcon} className="menu-icon" />
													<img src={ActiveQueryIcon} className="menu-icon1" />
												</span>
											}
										>
											<span className="DAIExecutive_menus">Query Summary</span>
										</MenuItem>

										<MenuItem
											className={pathName.includes('/dataestate/data-processing') ? 'active' : ''}
											component={<Link to="/dataestate/data-processing" />}
											icon={
												<span className="DAIExecutive_icon">
													<img src={ProcessingIcon} className="menu-icon" />
													<img src={ActiveProcessingIcon} className="menu-icon1" />
												</span>
											}
										>
											<span className="DAIExecutive_menus">Data Processing Summary</span>
										</MenuItem>
										<MenuItem
											className={pathName.includes('/dataestate/biApplicationSummary') ? 'active' : ''}
											component={<Link to="/dataestate/biApplicationSummary" />}
											icon={
												<span className="DAIExecutive_icon">
													<img src={BIApplicationIcon} className="menu-icon biAppIcon" />
													<img src={ActiveBIApplicationIcon} className="menu-icon1 biAppIcon-active" />
												</span>
											}
										>
											<span className="DAIExecutive_menus">BI Application Summary</span>
										</MenuItem>
										<MenuItem
											className={pathName.includes('/dataestate/costSummary') ? 'active' : ''}
											component={<Link to="/dataestate/costSummary" />}
											icon={
												<span className="DAIExecutive_icon">
													<img src={CostIcon} className="menu-icon dataestateCostIcon" />
													<img src={ActiveCostIcon} className="menu-icon1 dataestateCostIcon" />
												</span>
											}
										>
											<span className="DAIExecutive_menus">Cost Summary</span>
										</MenuItem>
										<MenuItem
											className={pathName.includes('/dataestate/service-request') ? 'active' : ''}
											component={<Link to="/dataestate/service-request" />}
											icon={
												<span className="DAIExecutive_icon">
													<img src={serviceRequestIcon} className="menu-icon service-request" />
													<img src={serviceRequestActiveIcon} className="menu-icon1 service-request" />
												</span>
											}
										>
											<span className="DAIExecutive_menus">Service Request Summary</span>
										</MenuItem>

										<MenuItem
											className={pathName.includes('/dataestate/incident') ? 'active' : ''}
											component={<Link to="/dataestate/incident-details" />}
											icon={
												<span className="DAIExecutive_icon">
													<img src={IncidentIcon} className="menu-icon" />
													{pathName.includes('/dataestate/incident') && (
														<img src={ActiveIncidentIcon} className="menu-dashboard-icon1" />
													)}
												</span>
											}
										>
											<span className="DAIExecutive_menus">Incident Details</span>
										</MenuItem>
									</div>
								)}
								<MenuItem
									className={expandedMenu === 2 ? 'active' : ''}
									icon={
										<>
											<img src={PcobsLogo} alt="logo" className="dataEstate_logo" />
										</>
									}
									onClick={() => toggleMenu(2)}
								>
									<span className="DAIExecutive_title">Platform Cost</span>
								</MenuItem>
								{expandedMenu === 2 && (
									<div className="menu-items">
										<MenuItem
											className={pathName.includes('/dataestate/platform/cost') ? 'active' : ''}
											component={<Link to="/dataestate/platform/cost" />}
											icon={
												<span className="DAIExecutive_icon">
													{/* <span className={pathName.includes('/platformCostObs/dashboard') ? 'icon-material-symbols_dashboard active-icon' : 'icon-material-symbols_dashboard'}></span> */}
													<img src={DashboardIcon} className="menu-icon" />
													{pathName.includes('/dataestate/platform/cost') && (
														<img src={ActiveDashIcon} className="menu-dashboard-icon2" />
													)}
												</span>
											}
										>
											<span className="DAIExecutive_menus">Cost Dashboard</span>
										</MenuItem>
										{/* <MenuItem
											className={
												pathName.includes('/dataestate/platform/resourceMapping') ||
												pathName.includes('/dataestate/platform/resourceGroups/:resourceGroup')
													? 'active'
													: ''
											}
											component={<Link to="/dataestate/platform/resourceMapping" />}
											icon={
												<span className="DAIExecutive_icon">
													<img src={ResourceMappingIcon} className="menu-icon" />
													{(pathName.includes('/dataestate/platform/resourceMapping') ||
														pathName.includes('/dataestate/platform/resourceGroups/:resourceGroup')) && (
														<img src={ActiveResourceMappingIcon} className="menu-dashboard-icon2" />
													)}
												</span>
											}
										>
											<span className="DAIExecutive_menus">Resource Mapping</span>
										</MenuItem> */}
									</div>
								)}
								<MenuItem
									className={expandedMenu === 3 ? 'active' : ''}
									icon={
										<>
											<img src={FinopsLogo} alt="logo" className="dataEstate_logo" />
										</>
									}
									onClick={() => toggleMenu(3)}
								>
									<span className="DAIExecutive_title">Fin Ops</span>
								</MenuItem>
								{expandedMenu === 3 && (
									<div className="menu-items">
										<MenuItem
											className={pathName.includes('/dataestate/financialOverview') ? 'active' : ''}
											component="a"
											href="/dataestate/financialOverview"
											icon={
												<span className="DAIExecutive_icon">
													<img src={DashboardIcon} className="menu-icon" />
													<img src={ActiveBlueDashIcon} className="menu-icon1" />
												</span>
											}
										>
											<span className="DAIExecutive_menus">Overview</span>
										</MenuItem>

										<MenuItem
											className={pathName.includes('/dataestate/financialProgram') ? 'active' : ''}
											component={<Link to="/dataestate/financialProgram" />}
											icon={
												<span className="DAIExecutive_icon">
													<img src={ProgramIcon} className="menu-icon" />
													<img src={ActiveProgramIcon} className="menu-icon1" />
												</span>
											}
										>
											<span className="DAIExecutive_menus">Program Summary</span>
										</MenuItem>
										<MenuItem
											className={pathName.includes('/dataestate/financialDetails') ? 'active' : ''}
											component={<Link to="/dataestate/financialDetails" />}
											icon={
												<span className="DAIExecutive_icon">
													<img src={DetailsIcon} className="menu-icon" />
													<img src={ActiveDetailsIcon} className="menu-icon1" />
												</span>
											}
										>
											<span className="DAIExecutive_menus">Details Summary</span>
										</MenuItem>
									</div>
								)}
							</div>
						</>
					)}
					{/* End of Data Estate  Dashboard */}

					{/* Data Estate Dashboard */}

					{location.pathname.includes('/finops') && (
						<>
							{/* <MenuItem
								className={pathName.includes('/finops/overview') ? 'active' : ''}
								component={<Link to="/finops/overview" />}
								icon={
									<>
										<img src={DashboardIcon} className="menu-icon" />
										<img src={ActiveBlueDashIcon} className="menu-icon1" />
									</>
								}
							>
								Overview
							</MenuItem> */}

							<MenuItem
								className={pathName.includes('/finops/executive-dashboard') ? 'active' : ''}
								// component={<Link to="/finops/executive-dashboard" />}
								onClick={(e) => navigateByUrl('/finops/executive-dashboard')}
								icon={
									<>
										<img src={DashboardIcon} className="menu-icon" />
										<img src={ActiveBlueDashIcon} className="menu-icon1" />
									</>
								}
							>
								Executive Dashboard
							</MenuItem>
							
							{/* <MenuItem
								className={pathName.includes('/finops/program') ? 'active' : ''}
								component={<Link to="/finops/program" />}
								icon={
									<>
										<img src={ProgramIcon} className="menu-icon" />
										<img src={ActiveProgramIcon} className="menu-icon1" />
									</>
								}
							>
								Program Summary
							</MenuItem> */}
							<MenuItem
								className={pathName.includes('/finops/details') ? 'active' : ''}
								// component={<Link to="/finops/details" />}
								onClick={(e) => navigateByUrl('/finops/details')}
								icon={
									<>
										<img src={DetailsIcon} className="menu-icon" />
										<img src={ActiveDetailsIcon} className="menu-icon1" />
									</>
								}
							>
								Details Summary
							</MenuItem>
						</>
					)}
					{/* End of finops Dashboard */}

					{/* Data pipeline Observability Dashboard */}

					{location.pathname.includes('/data-pipeline') && (
						<>
							<MenuItem
								className={pathName.includes('/data-pipeline/programs') ? 'active' : ''}
								component={<Link to="/data-pipeline/programs" />}
								icon={
									<>
										<img src={DashboardIcon} className="menu-icon" />
										{pathName.includes('/data-pipeline/programs') && (
											<img src={ActiveBlueDashIcon} className="menu-dashboard-icon1" />
										)}
									</>
								}
							>
								Programs
							</MenuItem>
							<MenuItem
								className={pathName.includes('/data-pipeline/customDashboards') ? 'active' : ''}
								component={<Link to="/data-pipeline/customDashboards" />}
								icon={
									<>
										<img src={CustomDashboardIcon} className="menu-icon" />
										{pathName.includes('/data-pipeline/customDashboards') && (
											<img src={ActiveBlueCustomDashIcon} className="menu-dashboard-icon1" />
										)}
									</>
								}
							>
								Custom Dashboards
							</MenuItem>
						</>
					)}

					{/* End of Data pipeline Observability Dashboard */}

					{!location.pathname.includes('platformCostObs') &&
						location.pathname !== DataGrowthDashboard &&
						location.pathname !== '/databricks/summary/dashboard' &&
						location.pathname !== '/databricks/resource/dashboard' &&
						location.pathname !== '/databricks/jobs/dashboard' &&
						location.pathname !== '/databricks/costSummary/dashboard' &&
						location.pathname !== '/databricks/governance/dashboard' &&
						location.pathname !== '/databricks/infrastructure/dashboard' &&
						location.pathname !== '/powerBI/capacity/dashboard' &&
						location.pathname !== '/powerBI/capacityObservability/dashboard' &&
						location.pathname !== '/powerBI/dataset/dashboard' &&
						location.pathname !== '/powerBI/usage/dashboard' &&
						location.pathname !== '/powerBI/datagrowth/dashboard' &&
						location.pathname !== '/powerBI/infrastructure/dashboard' &&
						location.pathname !== '/dataManagementFramework/healthSummary/dashboard' &&
						location.pathname !== '/dataManagementFramework/Catalogues/dashboard' &&
						!location.pathname.includes('/tableau') &&
						!location.pathname.includes('/machineLearning') &&
						!location.pathname.includes('/verificationValidation') &&
						!location.pathname.includes('/presto') &&
						!location.pathname.includes('/controlsCompliance') &&
						!location.pathname.includes('/ibp') &&
						!location.pathname.includes('/dataGrowth') &&
						!location.pathname.includes('/dataestate') &&
						capabilities.includes('Admin') &&
						!location.pathname.includes('/databricks') &&
						!location.pathname.includes('/finops') &&
						!location.pathname.includes('/data-pipeline') &&
						role == 'Admin' && (
							<SubMenu
								label="Master"
								defaultOpen={pathName.includes('master/') || pathName.includes('user')}
								className={`masterSubmenu ${pathName.includes('master/') || pathName.includes('user') ? 'active' : ''}`}
								icon={
									!collapsed ? (
										<>
											{/* <span className={pathName.includes('master/') || pathName.includes('user') ? 'icon-material-symbols_table-chart-view-sharp active-icon' : 'icon-material-symbols_table-chart-view-sharp'}></span> */}
											<img src={MasterIcon} className="menu-icon" />
											<img src={ActiveMasterIcon} className="menu-icon1" />
										</>
									) : (
										renderSubIcon(pathName)
									)
								}
							>
								<MenuItem
									component={<Link to="/master/sectors" />}
									className={pathName === '/master/sectors' ? 'active' : ''}
									// icon={
									// 	<>
									// 		<span className={pathName.includes('/master/sectors') ? 'icon-mingcute_building active-icon' : 'icon-mingcute_building'}></span>

									// 	</>
									// }
									icon={pathName === '/master/sectors' ? <img src={ActiveSectorIcon} /> : <img src={SectorsIcon} />}
								>
									Sectors
								</MenuItem>
								<MenuItem
									component={<Link to="/master/systems" />}
									className={pathName === '/master/systems' ? 'active' : ''}
									// icon={
									// 	<>
									// 		<span className={pathName.includes('/master/systems') ? 'icon-ri_computer active-icon' : 'icon-ri_computer'}></span>

									// 	</>
									// }
									icon={pathName === '/master/systems' ? <img src={ActiveSystemIcon} /> : <img src={SystemIcon} />}
								>
									Systems
								</MenuItem>
								<MenuItem
									component={<Link to="/master/processes" />}
									className={pathName === '/master/processes' ? 'active' : ''}
									// icon={
									// 	<>
									// 		<span className={pathName.includes('/master/processes') ? 'icon-uim_process active-icon' : 'icon-uim_process'}></span>

									// 	</>
									// }
									icon={pathName === '/master/processes' ? <img src={ActiveProcessIcon} /> : <img src={ProcessIcon} />}
								>
									Processes
								</MenuItem>
								{/* {/* {role !== 'Platform Support' ? (
							<MenuItem
								component={<Link to="/user/list" />}
								className={pathName === '/user/list' ? 'active' : ''}
								icon={pathName === '/user/list' ? <img src={ActiveUsersIcon} /> : <img src={UsersIcon} />}
							>
								Users
							</MenuItem>
						) : (
							''
						)} */}
								<MenuItem
									component={<Link to="/master/reasonsOfSLA" />}
									className={pathName === '/master/reasonsOfSLA' ? 'active' : ''}
									// icon={
									// 	<>
									// 		<span className={pathName.includes('/master/reasonsOfSLA') ? 'icon-uim_process active-icon' : 'icon-uim_process'}></span>

									// 	</>
									// }
									icon={
										pathName === '/master/reasonsOfSLA' ? <img src={ActiveProcessIcon} /> : <img src={ProcessIcon} />
									}
								>
									Reasons Of SLA Not Met
								</MenuItem>
							</SubMenu>
						)}

					<br />
				</Menu>
				<Stack direction="row" spacing={2} className={collapsed ? classes.userIconDiv : classes.activeUserIcons}>
					<Avatar className={collapsed ? 'icons-header-collapse' : 'icons-header'}>
						<div className={collapsed ? 'menu-icon-div-collapse' : 'menu-icon-div'}>
							<AccountCircle className="user-icon" sx={{color: 'white.main'}}/>
						</div>
						{/* <a href="javascript:void(0)">Admin</a> */}
						{!collapsed && (
							<div>
								<div className="user_name_details">
									{getDecodedToken()?.firstName} {getDecodedToken()?.lastName}
								</div>
								<Tooltip title={getDecodedToken()?.email} placement="bottom">
									<div className="user_email_details">{`${getDecodedToken()?.email?.slice(0, 25)}...` || '-'}</div>
								</Tooltip>
							</div>
						)}
					</Avatar>
					{/* <Avatar className={collapsed ? 'icons-header-collapse' : 'icons-header'}>
							<div className={collapsed ? 'menu-icon-div-collapse' : 'menu-icon-div'}>
								<img className="user-icon" src={HelpIcon} />
							</div>
							<a href="javascript:void(0)">Help & Support</a>
						</Avatar>  */}

					<Avatar className={collapsed ? 'icons-header-collapse' : 'icons-header'}  onClick={logout} sx={{cursor: 'pointer'}}>
						<div className={collapsed ? 'menu-icon-div-collapse' : 'menu-icon-div'}>
							<img className={classes.logoutImg} src={logoutImg} />
							{/* <span className="icon-material-symbols_logout"></span> */}
						</div>
						{!collapsed && <Typography variant='navMenu' ml={1}>LogOut</Typography>}							
					</Avatar>
				</Stack>
			</SidebarWrapper>
		</aside>
	);
};

export default Sidebar;
