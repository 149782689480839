import React, { useEffect, useRef, useState } from 'react';
import {
	Button,
	Chip,
	Grid,
	Menu,
	Stack,
	TextField,
	List,
	ListItem,
	ListItemText,
	FormControlLabel,
	Select,
	InputLabel,
	FormControl
} from '@mui/material';
import { dashboardUrl } from '../../service/config';
import AnalyticsVisualization from '../framework/analyticsVisualization';
import '../TableauObs/Summary/Summary.scss';
// import Workbooks from './Workbooks';
// import TableauUsers from '../../TableauObs/Summary/Users';
import moment from 'moment';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as DatePickerIcon } from '../../assets/calendar-outline_white.svg';
import { ReactComponent as FilterIcon } from '../../assets/ml_summary_filter.svg';
import { ReactComponent as ServerIcon } from '../../assets/solar_server-outline.svg';
import { getCostSummaryChart, getDecodedToken, getSector } from '../../service/service';
import useStyles from '../jobs/jobs_styles';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import '../FineOps/finops.scss';
import { fetchBIApplicationSummary, fetchProgramDATeams, fetchProgramCloudCost, programSummaryDetail} from '../../service/service';
import ReactECharts from 'echarts-for-react';
import { ReactComponent as CalendarIcon } from '../../assets/calendar-outline_white.svg';

import ArrowUpBlackIcon  from '../../assets/up-arrow-black.svg';
import '../DataEstate/data-estate-dashboard.scss';
import ArrowIcon from '../../assets/Arrow 253.svg';
import ArrowIconRed from '../../assets/growth arrow down.svg';
import { color } from 'echarts';

import type { Column } from 'react-data-grid';
import { CustomizedDataGrid } from '../customized_data_grid';
import Loader from '../Loader/loader';

const ProgramSummary = () => {	



	const [grafanaProgramCloudCostLoading, setGrafanaProgramCloudCostLoading] = useState(true);
    const [grafanaProgramCloudCostOptions, setGrafanaProgramCloudCostOptions] = useState<any>()

	
	const [grafanaDATeamsLoading, setGrafanaDATeamsLoading] = useState(true);
    const [grafanaDATeamsOptions, setGrafanaDATeamsOptions] = useState<any>()
	const [grafanaDAProgramLoading, setGrafanaDAProgramLoading] = useState(true);
    const [grafanaDAProgramOptions, setGrafanaDAProgramOptions] = useState<any>()
	

	async function getProgramSummaryDetails() {

		// setGrafanaDATeamLoading(true);
		fetchProgramDATeams('team')
			.then((res) => {
				let data = res.data.result;

				// Transform data into ECharts series format
				const seriesData = data.map((item: any) => ({
					name: item.streamtype,
					value: item.ytd_cost,
					formattedName: `${item.percentage.toFixed(2)}% ($${item.ytd_cost.toLocaleString()})`
				}));
				const optionBar = {
					tooltip: {
						// trigger: 'item',
						formatter: '{b} : {d}%  (${c}) ',
						position: 'inside'
					},
					legend: {
						bottom: 10,
						left: 'center',
						data: seriesData.map((item: any) => item.name),
						textStyle: {
							color: '#A7ADCB',
							fontWeight: 600,
							fontSize: 12
						}
					},
					series: [
						{
							type: 'pie',
							radius: '35%',
							center: ['50%', '50%'],
							selectedMode: 'single',
							label: {
								show: true,
								formatter: function (params: any) {
									let value = params.value.toLocaleString(); 
									return `{a|${value}}\n{b|(${params.percent.toFixed(2)}%)}`;
								},
								rich: {
									a: {
										color: '#E5E5E5',
										fontSize: 12,
										lineHeight: 20
									},
									b: {
										color: '#E5E5E5',
										fontSize: 12
									}
								},
								color: '#F5F5F5'
							},
							data: seriesData.map((item: any) => ({
								name: item.name,
								value: item.value,
								label: {
									formatter: `${item.formattedName}`
								}
							})),
							emphasis: {
								itemStyle: {
									shadowBlur: 10,
									shadowOffsetX: 0,
									shadowColor: 'rgba(0, 0, 0, 0.5)'
								}
							}
						}
					]
				};

				setGrafanaDATeamsOptions(optionBar);
				setGrafanaDATeamsLoading(false);
			})
			.catch((error) => {
				console.error(error);
			});

				// setGrafanaDAPtogramLoading(true);
		fetchProgramDATeams('program')
		.then((res) => {
			let data = res.data.result;

			// Transform data into ECharts series format
			const seriesData = data.map((item: any) => ({
				name: item.ProgramName,
				value: item.ytd_cost,
				formattedName: `${item.percentage ? item.percentage.toFixed(2) : 0}% ($${item.ytd_cost ? item.ytd_cost.toLocaleString() : 0})`
			}));
			const optionBar = {
				tooltip: {
					// trigger: 'item',
					formatter: '{b} : {d}%  (${c}) ',
					position: 'inside'
				},
				legend: {
					bottom: 10,
					left: 'center',
					data: seriesData.map((item: any) => item.name),
					textStyle: {
						color: '#A7ADCB',
						fontWeight: 600,
						fontSize: 12
					}
				},
				series: [
					{
						type: 'pie',
						radius: '35%',
						center: ['50%', '50%'],
						selectedMode: 'single',
						label: {
							show: true,
							formatter: function (params: any) {
								let value = params.value.toLocaleString(); 
								return `{a|${value}}\n{b|(${params.percent.toFixed(2)}%)}`;
							},
							rich: {
								a: {
									color: '#E5E5E5',
									fontSize: 12,
									lineHeight: 20
								},
								b: {
									color: '#E5E5E5',
									fontSize: 12
								}
							},
							color: '#F5F5F5'
						},
						data: seriesData.map((item: any) => ({
							name: item.name,
							value: item.value,
							label: {
								formatter: `${item.formattedName}`
							}
						})),
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}
				]
			};

			setGrafanaDAProgramOptions(optionBar);
			setGrafanaDAProgramLoading(false);
		})
		.catch((error) => {
			console.error(error);
		});

		// setGrafanaPowerBiLoading(true);
		fetchProgramCloudCost('trend').then(res => {
			
			const allMonths = Object.keys(res.data);
			
			let allDataAsArray = Object.entries(res.data);
			
			const firstMonthFromLastYear = allMonths[0]
			const currentMonth = allMonths[12]
			const firstMonthFromForecast = allMonths[13]
			const lastMonthFromForecast = allMonths[allMonths.length - 1]
			
			const option = {
				title: {
					show: Object.keys(allDataAsArray).length == 0 ? true : false,
					textStyle:{
					  color:'#bcbcbc',
					  fontWeight: 'normal',
					  fontSize: 16
					},
					text: 'No data for the selected filters',
					left: 'center',
					top: 'center'
				  },
				backgroundColor: '#053367',
				grid: {
					top: '11%',
					left: '12%',
					right: '2%',
					bottom: '12%'
				},
				xAxis: {
					type: 'category',
					splitLine: { 
					show: true, 
					lineStyle: { 
						type: 'dashed',
						color: '#004477'
					}
				},
				data: allMonths,
					axisLabel: {
						rotate: 30,
						color: '#A7ADCB',
						formatter: function (value: any) {
							return `${moment(value).format('MMM-YY')}`;
						  }
					 },
				},
				yAxis: {
					type: 'value',
					splitLine: { 
						show: true, 
						lineStyle: { 
							type: 'dashed',
							color: '#004477'
						}
					},
					axisLabel: {
						color: '#A7ADCB',
						formatter: function (value: any) {
							return `${(value/1000).toFixed(2)}k`;
						  }
					},
				},			
				visualMap: {
					show: false,
					dimension: 0,
					pieces: [
					  {
						lte: 12,
						color: 'green'
					  },
					  {
						gt: 12,
						lte: 24,
						color: 'red'
					  }
					]
				  },
				series: [
					{
					  name: 'Current Growth',
					  type: 'line',
					  data: allDataAsArray,
					  markArea: {
						itemStyle: {
						  color: 'rgba(255, 173, 177, 0.1)'
						},
						data: [
						  [
							{
							  name: 'Current Growth',
							  xAxis: firstMonthFromLastYear
							},
							{
							  xAxis: currentMonth
							}
						  ],
						  [
							{
							  name: 'Forecast',
							  xAxis: firstMonthFromForecast
							},
							{
							  xAxis: lastMonthFromForecast
							}
						  ]
						]
					  }
					}
				],
				tooltip: {
					trigger: 'axis',
					formatter: (params: any) => {
					  const { name, seriesName, value } = params[0]; 
					  return `${moment(value[0]).format('MMM-YY')}: $${parseFloat(value[1]).toFixed(2)}`; 
					},
					backgroundColor: '#053367',
					textStyle: {
						color: '#A7ADCB' 
					  }
				  }
			};

			setGrafanaProgramCloudCostOptions(option);
			setGrafanaProgramCloudCostLoading(false);
		}).catch(error => { 
			console.error(error);
		})

		// new chart


	}
	
// table

interface Row {
	programName: string;
	total_cost: string;
	DS_cost: string;
	DE_cost: string;
	Shared_cost: string;
	Other_cost: string;
}
function getColumns(): Array<Column<Row>> {
	return [
		{
			key: 'programName',
			name: 'Program',
			minWidth: 150
		},
		{
			key: 'total_cost',
			name: 'Total Cost (YTD)',
			minWidth: 170,
			formatter(props) {
			  const totalCost: any = props.row.total_cost;
			  const formattedCost = `$ ${(totalCost ).toFixed(2)}`;
			  return formattedCost;
			}
		},
		{
			key: 'DS_cost',
			name: 'DS Cost (YTD)',
			minWidth: 170,
			formatter(props) {
			  const totalCost: any = props.row.DS_cost;
			  const formattedCost = `$ ${(totalCost ).toFixed(2)}`;
			  return formattedCost;
			}
		},
		{
			key: 'DE_cost',
			name: 'DE Cost (YTD)',
			minWidth: 170,
			formatter(props) {
			  const totalCost: any = props.row.DE_cost;
			  const formattedCost = `$ ${(totalCost ).toFixed(2)}`;
			  return formattedCost;
			}
		},
		{
			key: 'Shared_cost',
			name: 'Shared Cost (YTD)',
			minWidth: 170,
			formatter(props) {
			  const totalCost: any = props.row.Shared_cost;
			  const formattedCost = `$ ${(totalCost ).toFixed(2)}`;
			  return formattedCost;
			}
		},
		{
			key: 'Other_cost',
			name: 'Other Cost (YTD)',
			minWidth: 170,
			formatter(props) {
			  const totalCost: any = props.row.Other_cost;
			  const formattedCost = `$ ${(totalCost ).toFixed(2)}`;
			  return formattedCost;
			}
		}
	];
}
const defaultColumnNames = ['Program', 'Total Cost (YTD)','DS Cost (YTD)','DE Cost (YTD)','Shared Cost (YTD)','Other Cost (YTD)',];
const [programList, setProgramList] = useState<Row[]>([]);
const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
const [page, setPage] = useState(1);
const [pageSize, setPageSize] = useState(100);

const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
	programListingFunc(value, pageSize, sortingInfo);
};

const handlepageSizeChange = (event: any) => {
	programListingFunc(page, event.target.value, sortingInfo);
};

	
	useEffect(() => {
		programListingFunc(page, pageSize, sortingInfo);
		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/finops/program' || window.location.pathname === '/dataestate/financialProgram')  {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('datefilter-align-sector');
				document.body.classList.add('sector-label');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				document.body.classList.add('dashboard-panel-dataestate');
				document.body.classList.add('datefilter-align-sector');
			}
		}
		getProgramSummaryDetails();
	}, []);
	async function programListingFunc(pageNumber: any, size: any, sortingInfo: any) {
		const res = await programSummaryDetail('detail', pageNumber.toString(), size.toString(), sortingInfo);
		if (res.success) {
			setProgramList(res.data.result);
			setPaginationData(res.data.page);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
		}
	}
	const handleSorting = (sortingInfo: any) => {
		programListingFunc(page, pageSize, sortingInfo);
	};
	return (
		<div className="dashboard-panel-dataestate job-schedules-wrap">
			
			<div className="page-table jobs-tabledata page-table-ibp-monitor">
				<div className="ibp-dashboard-wrapper">
				<h2 className="panel-legend--title">Cost Breakdown by Program & Teams</h2>

					<Grid container spacing={0} className="headerIPB containerBI">
						<Grid item xs={6} className="headerIPB-title subheaderIPB-title no-ml">
							<Grid container spacing={1}>
							<Grid item xs={12} className="dataestate-title-fn chart-widget-finop bgcolor-blue">
									<div className="monitor-head">YTD Cost % Distribution Across Programs</div>
									{
									grafanaDAProgramLoading ? 'Loading...' : <ReactECharts
										option={grafanaDAProgramOptions}
										theme={{
											// backgroundColor: '#1b1e35'
										}}
									/>}
								</Grid>
								<Grid item xs={12} className="dataestate-title-fn chart-widget-finop bgcolor-blue">
									<div className="monitor-head mb--20">YTD Cost % Distribution Across Teams (DS,DE)</div>
									{
									grafanaDATeamsLoading ? 'Loading...' : <ReactECharts
										option={grafanaDATeamsOptions}
										theme={{
											// backgroundColor: '#1b1e35'
										}}
									/>}
								</Grid>
							</Grid>

						</Grid>
						{/* right side */}

							<Grid item xs={6} className="headerIPB-title subheaderIPB-title no-ml">
							
							<Grid container spacing={1}>
								<Grid item xs={12} className="dataestate-title chart-widget-finop bgcolor-blue">
									<div className="monitor-head">Azure Cloud Cost Trend (YoY)</div>
									{
									grafanaProgramCloudCostLoading ? 'Loading...' : <ReactECharts
										option={grafanaProgramCloudCostOptions}
										theme={{
											backgroundColor: '#1b1e35'
										}}
									/>}
								</Grid>
							</Grid>

							</Grid>
					</Grid>
					{/* second part */}
					<h2 className="panel-legend--title mt-35 ">Detailed View</h2>

					<div className=" finop_tabledata">
			<Grid>
				{programList.length > 0 ? (
					<CustomizedDataGrid
						tableColumns={getColumns()}
						tableData={programList}
						tableType="sector"
						defaultColumnNames={defaultColumnNames}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={programList.length}
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isDateRangeFilter={false}
						isDisabled={false}
					/>
				) : (
					<Loader />
				)}
			</Grid>
		</div>



				</div>
			</div>
		</div>
	);
};

export default ProgramSummary;
