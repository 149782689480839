/* eslint no-eval: 0 */
import React, { useState, useMemo, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DraggableHeaderRenderer } from './HeaderRenderers/DraggableHeaderRenderer';
import DataGrid from 'react-data-grid';
import type { HeaderRendererProps } from 'react-data-grid';
import 'react-data-grid/lib/styles.css';
import './jobs/jobs.css';
import { exportToXlsx } from './exportUtils';
import LoadingButton from '@mui/lab/LoadingButton';
import ExcelIcon from '../assets/file-type-excel.svg';
import Tooltip from '@mui/material/Tooltip';
import { PaginationDetails } from './pagination_details';
import { ReactComponent as ClearAllFilterIcon } from '../assets/clearAllFilter-white.svg';
import { ReactComponent as DefaultViewIcon } from '../assets/default-view.svg';
import { ReactComponent as MoreVertIcon } from '../assets/system-uicons_circle-menu.svg';
import { ReactComponent as ClearSortIcon } from '../assets/clear-sort.svg';
import { ReactComponent as ExportListIcon } from '../assets/export_small.svg';

import { Menu, MenuItem, ListItemIcon, IconButton } from '@mui/material';

export function CustomizedDataGrid({
	tableColumns,
	tableData,
	tableType,
	onCellEdit,
	defaultColumnNames,
	onSorting,
	currSorting,
	filterDataOptions,
	searchDataValues,
	onFilterCheck,
	currFilter,
	onFilterSearch,
	onHeaderSelectAll,
	onFilterClick,
	showExport,
	fileName,
	page,
	pageSize,
	paginationData,
	listLength,
	handlepageSizeChange,
	handlePageChange,
	isFetching,
	isDateRangeFilter,
	isDisabled,
	onCellClick,
	selectedRowId
}: any) {
	const [moreEl, setMoreEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(moreEl);
	const handleMoreClick = (event: React.MouseEvent<HTMLElement>) => {
		setMoreEl(event.currentTarget);
	};
	const handleMoreClose = () => {
		setMoreEl(null);
	};

	type Row = (typeof tableData)[0];
	const [columns, setColumns] = useState(tableColumns);
	const [sortColumns, setSortColumns] = useState<any[]>([]);
	const sortedRows = useMemo((): Row[] => {
		if (sortColumns.length === 0) return tableData;

		return [...tableData].sort((a, b) => {
			for (const sort of sortColumns) {
				const comparator = getComparator(sort.columnKey);
				const compResult = comparator(a, b);
				if (compResult !== 0) {
					return sort.direction === 'ASC' ? compResult : -compResult;
				}
			}
			return 0;
		});
	}, [tableData, sortColumns]);

	// function rowKeyGetter(row: Row) {
	// 	return row.id;
	// }

	// const columns = useMemo(() => tableColumns, []);

	type Comparator = (a: Row, b: Row) => number;
	function getComparator(sortColumn: string): Comparator {
		switch (sortColumn) {
			// case 'criticalFlag':
			// 	return (a, b) => {
			// 		return a[sortColumn] === b[sortColumn] ? 0 : a[sortColumn] ? 1 : -1;
			// 	};
			default:
				return (a: any, b: any) => {
					return a[sortColumn].localeCompare(b[sortColumn]);
				};
		}
	}

	const draggableColumns = useMemo(() => {
		function headerRenderer(props: HeaderRendererProps<Row>) {
			return (
				<DraggableHeaderRenderer
					{...props}
					onColumnsReorder={handleColumnsReorder}
					onContextMenu={handleContextClick}
					onFilterMenu={handleFilterClick}
					filterData={filterDataOptions}
					searchData={searchDataValues}
					onFilterCheck={handleFilterValues}
					onFilterSearch={handleSearchValues}
					onHeaderSelectAll={handleHeaderSelectAll}
					onFilterClick={handleAndGetFilterOptions}
					columnNames={tableColumns.map((col: any) => {
						return col.name;
					})}
					visibleColumns={columns.map((col: any) => {
						return col.name;
					})}
					currSorting={currSorting}
					currFilter={currFilter}
					tableColumnKeys={tableColumns.map((col: any) => {
						return col.key;
					})}
					tableType={tableType}
				/>
			);
		}
		function handleContextClick(selectedColumns: any) {
			const currColKeys = columns.map((col: any) => {
				return selectedColumns.includes(col.name) ? col.name : null;
			});
			const currColumns = columns.filter((col: any) => {
				return selectedColumns.includes(col.name);
			});
			tableColumns.map((col: any) => {
				if (selectedColumns.includes(col.name) && !currColKeys.includes(col.name)) {
					currColumns.push(col);
				}
				return null;
			});
			setColumns(currColumns);
			localStorage.setItem(`${tableType}_user_col_pref`, JSON.stringify(currColumns.map((col: any) => col.name)));
		}
		function handleFilterClick(sortingInfo: any) {
			onSorting(sortingInfo);
		}
		function handleFilterValues(filterInfo: any, columnKey: any) {
			onFilterCheck(filterInfo, columnKey);
		}
		function handleSearchValues(searchDetails: any, columnKey: any) {
			onFilterSearch(searchDetails, columnKey);
		}
		function handleHeaderSelectAll(event: any) {
			onHeaderSelectAll(event);
		}
		function handleAndGetFilterOptions(column: any) {
			onFilterClick(column);
		}
		function handleColumnsReorder(sourceKey: string, targetKey: string) {
			const sourceColumnIndex = columns.findIndex((c: any) => c.key === sourceKey);
			const targetColumnIndex = columns.findIndex((c: any) => c.key === targetKey);
			const reorderedColumns = [...columns];
			reorderedColumns.splice(targetColumnIndex, 0, reorderedColumns.splice(sourceColumnIndex, 1)[0]);
			setColumns(reorderedColumns);
			localStorage.setItem(`${tableType}_user_col_pref`, JSON.stringify(reorderedColumns.map((col: any) => col.name)));
		}
		return columns.map((c: any) => {
			if (c.key === 'id') return c;
			return { ...c, headerRenderer };
		});
	}, [columns, currSorting, filterDataOptions, currFilter, searchDataValues]);

	function handleEditCell(rows: Row[], index: any) {
		onCellEdit(rows, index);
	}
	function ExportButton({ onExport, children }: { onExport: () => Promise<unknown>; children: React.ReactChild }) {
		const [exporting, setExporting] = useState(false);
		return (
			<Tooltip
				title={
					<>
						<img src={ExcelIcon} />
						Download as Excel (.xlsx)
					</>
				}
				arrow
				followCursor={true}
			>
				<LoadingButton
					size="small"
					className="exportButton"
					loading={exporting}
					loadingPosition="start"
					loadingIndicator="Exporting…"
					disabled={exporting}
					onClick={async () => {
						setExporting(true);
						await onExport();
						setExporting(false);
					}}
				>
					Export
				</LoadingButton>
			</Tooltip>
		);
	}

	function EmptyRowsRenderer() {
		return isFetching ? <></> : <div className="no-record-info">No records found</div>;
	}

	useEffect(() => {
		const storedPref: any = localStorage.getItem(`${tableType}_user_col_pref`);
		const storedColumnNames: any = JSON.parse(storedPref);
		const preferredColumnNames = storedColumnNames && storedColumnNames.length ? storedColumnNames : defaultColumnNames;
		// Append filltered columnNames
		if (currFilter && currFilter.atRisk) preferredColumnNames.push('Threshold %');
		currFilter &&
			Object.keys(currFilter).forEach((key: any) => {
				if (currFilter[key] && currFilter[key].length) {
					const filteredColumnName = tableColumns.filter((col: any) => col.key === key)[0]?.name;
					if (!preferredColumnNames.includes(filteredColumnName)) preferredColumnNames.push(filteredColumnName);
				}
			});
		if (preferredColumnNames) {
			const preferredColumns = tableColumns.filter((col: any) => {
				return preferredColumnNames.includes(col.name);
			});
			preferredColumns.sort(
				(a: any, b: any) => preferredColumnNames.indexOf(a.name) - preferredColumnNames.indexOf(b.name)
			);
			setColumns(preferredColumns);

			if (filterDataOptions) filterDataOptions.thresholdPercent = filterDataOptions?.thresholdPercent?.map(String);
		}
	}, []);

	function enableDefaultViewBtn() {
		const setDefaultColumnNames = defaultColumnNames.filter((value: any) => value != null);
		if (setDefaultColumnNames)
			return JSON.stringify(setDefaultColumnNames) !== JSON.stringify(draggableColumns.map((x: any) => x.name));
		else return false;
	}

	const resetView = (event: any) => {
		setMoreEl(null);
		if (defaultColumnNames) {
			const defaultColumns = tableColumns.filter((col: any) => {
				return defaultColumnNames.includes(col.name);
			});
			setColumns(defaultColumns);
			localStorage.removeItem(`${tableType}_user_col_pref`);
		}
	};

	function enableClearFilter() {
		let enableClear = false;
		if (!currFilter) return false;
		Object.keys(currFilter).forEach((key: any) => {
			if (currFilter[key] && currFilter[key].length) {
				enableClear = true;
				return false;
			}
		});
		return enableClear;
	}

	const handleClearAllFilter = (event: any = null) => {
		setMoreEl(null);
		const filterQuerySkeleton = {} as any;
		tableColumns.forEach((col: any) => {
			filterQuerySkeleton[col.key] = [];
		});
		onFilterCheck(filterQuerySkeleton, '');
	};

	useEffect(() => {
		const isDrillDownExecution = localStorage.getItem('drill-down-execution');
		const isDrillDownJobs = localStorage.getItem('drill-down-jobs');
		const isDrillDownSchdeule = localStorage.getItem('drill-down-schedules');
		if (isDrillDownExecution === 'false') {
			handleClearAllFilter();
			localStorage.removeItem('drill-down-execution');
		} else if(isDrillDownJobs === 'false' ){
			handleClearAllFilter();
			localStorage.removeItem('drill-down-jobs');
		} else if(isDrillDownSchdeule === 'false'){
			handleClearAllFilter();
			localStorage.removeItem('drill-down-schedules');
		}
	}, []); 
	function enableClearSoting() {
		return currSorting?.sortBy && currSorting?.sortBy?.length;
	}

	const handleClearSorting = (event: any) => {
		setMoreEl(null);
		onSorting({ sortBy: '', sortDir: '' });
	};

	const handleCellClick = (args: any, event: any) => {
		onCellClick && onCellClick(args.row);
	};

	const displayMoreOptions = () => {
		console.log(window.location.href)
		if (window.location.href.includes('/data-pipeline/programs') || window.location.href.includes('/data-pipeline/customDashboards')
			|| window.location.href.includes('/finops/details')) {
			return false
		} else {
			return true
		}
	}

	return (
		<div id="customGrid">
			<DndProvider backend={HTML5Backend}>
				{/* conditions for three dotted tab */}
				{
					location.pathname !== '/ibp/executions' &&
					location.pathname !== '/tableau/summary/dashboard' &&
					location.pathname !== '/tableau/performance/dashboard' &&
					location.pathname !== '/tableau/governance/dashboard' && 
					!location.pathname.includes('/tableau/observation') && 
					!location.pathname.includes('/presto/dashboard') &&
					!location.pathname.includes('/presto/observation') && 
					!location.pathname.includes('/databricks/observation') && 
					!location.pathname.includes('/dataGrowth/observation') && 
					(
						<div
							style={
								location.pathname === '/jobs' ||
								location.pathname === '/master/systems' ||
								location.pathname === '/master/sectors' ||
								location.pathname === '/master/processes' ||
								location.pathname === '/master/reasonsOfSLA' ||
								location.pathname === '/user/list'
									? {
											position: 'absolute',
											top: 80,
											right: 34,
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											height: '22px',
											width: '22px',
											zIndex: 999
											// border: '2px solid #ffffff'
											// borderRadius: '100px'
									  }
									: location.pathname === '/jobExecutions' || location.pathname === '/jobSchedules'
									? {
											// marginLeft: '95%',
											position: 'absolute',
											top: -78,
											right: 36,
											// marginTop: '5px',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											height: '22px',
											width: '22px',
											zIndex: 999
									  }
									: location.pathname === '/machineLearning/dashboard'
									? {
											position: 'absolute',
											top: 512,
											right: 28,
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											height: '22px',
											width: '22px'
									  }
									  : location.pathname === '/platformCostObs/resourceMapping'
									  ? {
											  position: 'absolute',
											  top: 404,
											  right: 28,
											  display: 'flex',
											  justifyContent: 'center',
											  alignItems: 'center',
											  height: '22px',
											  width: '22px'
										}
									: tableType === 'PCOBS_ResourceGroup'
									? {
											position: 'absolute',
											top: 360,
											right: 28,
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											height: '22px',
											width: '22px'
									  }
									: tableType === 'PCOBS_Resource'
									? {
											position: 'absolute',
											top: 285,
											right: 40,
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											height: '22px',
											width: '22px'
									  }
									: location.pathname === '/databricks/summary/dashboard'
									? {
											position: 'absolute',
											top: '-66px',
											right: '15px',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											height: '42px',
											width: '42px'
									  }
									: location.pathname === '/databricks/resource/dashboard'
									? {
											position: 'absolute',
											top: '-33px',
											right: '15px',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											height: '42px',
											width: '42px'
									  }
									: location.pathname === '/databricks/costSummary/dashboard'
									? {
											position: 'absolute',
											top: '-66px',
											right: '30px',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											height: '42px',
											width: '42px'
									}
									: location.pathname === '/databricks/jobs/dashboard'
									? {
											position: 'absolute',
											top: '-14px',
											right: '20px',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											height: '42px',
											width: '42px'
										}
									: location.pathname === '/databricks/governance/dashboard'
									? {
											position: 'absolute',
											top: '-33px',
											right: '5px',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											height: '42px',
											width: '42px'
										}
									: location.pathname === '/databricks/governance/dashboard'
									? {
											position: 'absolute',
											top: '-33px',
											right: '5px',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											height: '42px',
											width: '42px'
										}
										: location.pathname === '/finops/program'
										? {
												position: 'absolute',
												top: '365px',
												right: '35px',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												height: '42px',
												width: '42px'
											}
											: location.pathname === '/finops/details'
											? {
													position: 'absolute',
													top: '275px',
													right: '35px',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													height: '42px',
													width: '42px'
												}
									  : location.pathname.includes('/dataestate/incident-impact-analysis')
									  ? {
											  position: 'absolute',
											  top: 270,
											  right: 34,
											  display: 'flex',
											  justifyContent: 'center',
											  alignItems: 'center',
											  height: '22px',
											  width: '22px',
											  zIndex: 999,
											  marginTop: '70px',
										}
										: location.pathname === '/dataestate/costSummary'
										? {
												position: 'absolute',
												top: 690,
												right: '35px',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												height: '42px',
												width: '42px',
												marginTop: '20px',
											} : location.pathname === '/dataestate/storagesummary'
											? {
													position: 'absolute',
													top: 390,
													right: '35px',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													height: '42px',
													width: '42px',
													marginTop: '40px',
												} 
												  
										: location.pathname === '/dataestate/biApplicationSummary'
										? {
												position: 'absolute',
												top: 530,
												right: '35px',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												height: '42px',
												width: '42px',
												marginTop: '40px',
										} 	
									  	: {
											// marginLeft: '95%',
											position: 'absolute',
											top: 66,
											right: 34,
											marginTop: '28px',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											height: '22px',
											width: '22px'
									  }
							}
						>
							{/* three dots tab */}
						    {
								displayMoreOptions() && 
								<IconButton
								aria-label="more"
								id="more-button"
								aria-controls={open ? 'more-menu' : undefined}
								aria-expanded={open ? 'true' : undefined}
								aria-haspopup="true"
								onClick={handleMoreClick}
								style={{
									backgroundColor: '#1b1e35',
									padding: '4px 2px',
									borderRadius: 0,
									position: 'absolute',
									top: '20px',
									width: '42px',
									height: '45px',
									margin: '0px 5px',
									display: tableType === 'ML_ModelFeatureDrifts' || tableType == 'incident-details-ver' || tableType == 'add-schedules' || tableType == 'job-schedules-1' ? 'none' : 'block'
								}}
								disabled={isDisabled}
							>
								<MoreVertIcon
									style={{
										height: 28,
										width: 28,
										padding: '5px'
									}}
								/>
							</IconButton>
							}
							<Menu
								id="more-menu"
								MenuListProps={{
									'aria-labelledby': 'more-button'
								}}
								anchorEl={moreEl}
								open={open}
								onClose={handleMoreClose}
								PaperProps={{
									style: {
										width: '13ch'
									}
								}}
							>
								<MenuItem key="defaultView" onClick={resetView} disabled={!enableDefaultViewBtn()}>
									<ListItemIcon>
										<DefaultViewIcon />
									</ListItemIcon>
									<span className="dots_menu_text">Default View</span>
								</MenuItem>
								<MenuItem key="ClearAllFilter" onClick={handleClearAllFilter} disabled={!enableClearFilter()}>
									<ListItemIcon>
										<ClearAllFilterIcon />
									</ListItemIcon>
									<span className="dots_menu_text">Clear All Filter</span>
								</MenuItem>
								<MenuItem key="ClearSorting" onClick={handleClearSorting} disabled={!enableClearSoting()}>
									<ListItemIcon>
										<ClearSortIcon />
									</ListItemIcon>
									<span className="dots_menu_text">Clear Sorting</span>
								</MenuItem>
								{showExport && (
									<MenuItem key="export">
										<ListItemIcon>
											<ExportListIcon />
										</ListItemIcon>
										<ExportButton
											onExport={async () => {
												await exportToXlsx(fileName, tableData, tableColumns);
											}}
										>
											Export to XSLX
										</ExportButton>
									</MenuItem>
								)}
							</Menu>
						</div>
					)}
				<DataGrid
					columns={draggableColumns}
					rows={sortedRows}
					defaultColumnOptions={{
						sortable: false,
						resizable: true
					}}
					sortColumns={sortColumns}
					onSortColumnsChange={setSortColumns}
					onRowsChange={handleEditCell}
					onCellClick={handleCellClick}
					rowClass={(row) =>
						row.isChildren || row.isExpanded
							? 'highlightClass'
							: selectedRowId && row.id === selectedRowId
							? 'selectedClass'
							: row.columns_with_drift > 0
							? 'drifted'
							: ''
					}
					renderers={{ noRowsFallback: <EmptyRowsRenderer /> }}
				/>
				{page && listLength > 0 ? (
					<div className="pagination-div">
						<PaginationDetails
							page={page}
							pageSize={pageSize}
							paginationData={paginationData}
							listLength={listLength}
							tableType={tableType}
							handlepageSizeChange={handlepageSizeChange}
							handlePageChange={handlePageChange}
						/>
					</div>
				) : (
					''
				)}
			</DndProvider>
		</div>
	);
}
