import React, { useState, lazy, Suspense } from 'react';
import { Stack } from '@mui/material';
import { dashboardUrl } from '../../../service/config';
//import AnalyticsVisualization from '../../framework/analyticsVisualization';
import './tableauJobs.scss';
import moment from 'moment';

// Lazy load the AnalyticsVisualization component
const AnalyticsVisualization = lazy(() => import('../../framework/analyticsVisualization'));

interface PerformanceGrafanaProps {
	fromDateTime: any;
	currSectorFilter: any;
	serverFilter: string;
  }

function PerformanceGrafana({ fromDateTime, currSectorFilter, serverFilter }: PerformanceGrafanaProps)  {
	
const [jobExectByStatusSelectedValue, setJobExectByStatusSelectedValue] = useState('monthly');
const [jobSchedulersSelectedValue, setJobSchedulersSelectedValue] = useState('today');
  
  const handleJobExect = (type: string) => {
	  setJobExectByStatusSelectedValue(type);

	  
  };
  const handleJobScheduler = (type: string) => {
	  setJobSchedulersSelectedValue(type);

  };

	let fromDate = moment(fromDateTime).format('YYYY-MM-DD');
	return (
		<Stack>
			<Suspense fallback={<div>Loading...</div>}>
			<AnalyticsVisualization
				params={{
					dashboardUrl: dashboardUrl.tableauPerformance,
					className: 'tableauPerformance',
					otherParams: {
						'var-fromDateTime': fromDate,
						'var-sector': currSectorFilter,
						'var-server': serverFilter
					},
					seamless: true
				}}
			/>
			</Suspense>
			<div className="second-iframe-container">
				<div className="left-iframe-div">
				<Suspense fallback={<div>Loading...</div>}>
					<AnalyticsVisualization
						params={{
							dashboardUrl: dashboardUrl.tableauPerformanceExtract,
							className: 'tableauPerformanceExtract',
							otherParams: {
								'var-fromDateTime': fromDate,
								'var-sector': currSectorFilter,
								'var-server': serverFilter
							},
							seamless: true
						}}
					/>
					</Suspense>
				</div>
				<div className="right-iframe-div">
				<Suspense fallback={<div>Loading...</div>}></Suspense>
					<AnalyticsVisualization
						params={{
							dashboardUrl: dashboardUrl.tableauPerformanceCalendar,
							className: 'tableauPerformanceCalendar',
							otherParams: {
								'var-fromDateTime': fromDate,
								'var-sector': currSectorFilter,
								'var-server': serverFilter
							},
							seamless: true
						}}
					/>
				</div>
			</div>
			<div className="second-iframe-container">
				<div className="left-iframe-div">
					<div className="job-drilldown">
						<span className={"types " + (jobExectByStatusSelectedValue === 'hourly'? 'active': '')} onClick={() => handleJobExect('hourly')}>Hourly</span>
						<span className={"types " + (jobExectByStatusSelectedValue === 'monthly'? 'active': '')} onClick={() => handleJobExect('monthly')}>Daily</span>
						<span className={"types " + (jobExectByStatusSelectedValue === 'weekly'? 'active': '')} onClick={() => handleJobExect('weekly')}>Weekly</span>
					</div>
					<Suspense fallback={<div>Loading...</div>}></Suspense>
					<AnalyticsVisualization
						params={{
							dashboardUrl: dashboardUrl.tableauPerformanceJobsExecution,
							className: 'tableauPerformanceExtract',
							otherParams: {
								'var-fromDateTime': fromDate,
								'var-filterWork': jobExectByStatusSelectedValue,
								'var-sector': currSectorFilter,
								'var-server': serverFilter
							},
							seamless: true
						}}
					/>
				</div>
				<div className="right-iframe-div">
					<div className="job-drilldown right-job-drilldown">
						<span className={"types " + (jobSchedulersSelectedValue === 'today'? 'active': '')} onClick={() => handleJobScheduler('today')}>Today</span>
						<span className={"types " + (jobSchedulersSelectedValue === 'nextDay'? 'active': '')} onClick={() => handleJobScheduler('nextDay')}>Next 24 Hrs</span>
					</div>
					<Suspense fallback={<div>Loading...</div>}></Suspense>
					<AnalyticsVisualization
						params={{
							dashboardUrl: dashboardUrl.tableauPerformanceJobSchedule,
							className: 'tableauPerformanceCalendar',
							otherParams: {
								'var-fromDateTime': fromDate,
								'var-filterWork': jobSchedulersSelectedValue,
								'var-sector': currSectorFilter,
								'var-server': serverFilter
							},
							seamless: true
						}}
					/>
				</div>
			</div>
		</Stack>
	);
}

export default PerformanceGrafana;
