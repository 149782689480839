import { Grid } from '@mui/material';
import useStyles from './jobs/jobs_styles';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import './paginationDetailsJoblineage.css';

export function PaginationDetailsJobLinage({
	succssorPageCountValue,
	predecessorPageCountValue,
	handlePageChangeSuccssor,
	handleCount,
	handlePageChangePredecessor,
	succssorTotal,
	predecessorTotal,
	pageSuccessor,
	pagePredecessor,
	countTot,
	error
}: any) {
	const classes = useStyles();

	return (
		<Grid className="table-footer" container item xs={12}>
			<Grid item xs={2}>
				<Grid container>
					<Grid item xs={8} className={classes.lineage}>
						Lineage per page:
					</Grid>
					<Grid item xs={4}>
						<TextField id="pageSize1" variant="outlined" onChange={handleCount} />
					</Grid>
					{error && <p className={classes.error}>Field should not be empty</p>}
				</Grid>
			</Grid>
			<Grid item xs={5}>
				<Grid container>
					<Grid item xs={5} className={classes.predecessorText}>
						Showing {predecessorTotal === 0 ? 0 : pagePredecessor * parseInt(countTot) - parseInt(countTot) + 1} -{' '}
						{pagePredecessor * parseInt(countTot) > predecessorTotal
							? predecessorTotal
							: pagePredecessor * parseInt(countTot)}{' '}
						of {predecessorTotal} Predecessors
					</Grid>
					{predecessorTotal > 0 ? (
						<Grid item xs={7}>
							<Grid className="darkPagination">
								<Stack direction="row" spacing={2}>
									<Pagination
										page={pagePredecessor}
										shape="rounded"
										count={predecessorPageCountValue}
										showFirstButton
										showLastButton
										onChange={handlePageChangePredecessor}
									/>
								</Stack>
							</Grid>
						</Grid>
					) : (
						''
					)}
				</Grid>
			</Grid>
			<Grid item xs={5}>
				<Grid container>
					<Grid item xs={5} className={classes.predecessorText}>
						Showing {succssorTotal === 0 ? 0 : pageSuccessor * parseInt(countTot) - parseInt(countTot) + 1} -{' '}
						{pageSuccessor * parseInt(countTot) > succssorTotal ? succssorTotal : pageSuccessor * parseInt(countTot)} of{' '}
						{succssorTotal} Successors
					</Grid>
					{succssorTotal > 0 ? (
						<Grid item xs={7}>
							<Grid className="darkPagination">
								<Stack direction="row" spacing={2}>
									<Pagination
										page={pageSuccessor}
										shape="rounded"
										count={succssorPageCountValue}
										onChange={handlePageChangeSuccssor}
										showFirstButton
										showLastButton
									/>
								</Stack>
							</Grid>
						</Grid>
					) : (
						''
					)}
				</Grid>
			</Grid>
		</Grid>
	);
}
