import React, { useEffect, useState, useRef } from 'react';
import { FormControl, Grid, MenuItem, Select, TextField, TextFieldProps } from '@mui/material';
import { getResources, patchResource } from '../../../service/service';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment-timezone';
import { ReactComponent as DatePickerIcon } from '../../../assets/calendar-outline_white.svg';
import { ReactComponent as EditIcon } from '../../../assets/material-symbols_edit-rounded.svg';

import type { Column } from 'react-data-grid';
import { CustomizedDataGrid } from '../../customized_data_grid';
import { textEditor } from 'react-data-grid';
import '../resourceGroups/resourceGroup.scss';
import Loader from '../../Loader/loader';
import { Link, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';

interface ResourceListingProps {
	sharedValue: any;
	updateSharedValue: () => void;
}
const ResourceListing: React.FC<ResourceListingProps> = ({ sharedValue, updateSharedValue }) => {
	const swalOptions = {
		showCancelButton: true,
		confirmButtonText: 'Yes, update it!',
		cancelButtonText: 'No, cancel',
		customClass: {
			container: 'custom-swal-container',
			title: 'custom-swal-title',
			htmlContainer: 'custom-swal-html-container',
			confirmButton: 'custom-swal-confirm-button',
			cancelButton: 'custom-swal-cancel-button'
		}
	};

	interface Row {
		resource_id: string;
		resource: string;
		project: string;
		resource_group: string;
		is_observed: boolean;
		observed_start_date: Date;
		observed_end_date: Date;
		component: string;
		project_code: string;
		project_number: string;
		business_contact: string;
		consumed_service: string;
	}

	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'resource',
				name: 'Resource',
				minWidth: 250,
				width: 250
			},
			{
				key: 'consumed_service',
				name: 'Consumed Service',
				minWidth: 170,
				width: 170
			},
			{
				key: 'component',
				name: 'Component Name',
				minWidth: 250,
				width: 250
			},
			{
				key: 'project',
				name: 'Project',
				minWidth: 140,
				width: 140,
				formatter(props) {
					return (
						<>
							{<span className="">{props.row.project}</span>}
							<EditIcon className="edit-icon" title="Double click to edit project" />
						</>
					);
				},
				cellClass: 'has-editor',
				editor: textEditor
			},
			{
				key: 'project_code',
				name: 'Project Code',
				minWidth: 130,
				width: 130
			},
			{
				key: 'project_number',
				name: 'Project Number',
				minWidth: 140,
				width: 140
			},
			{
				key: 'business_contact',
				name: 'Business Contact',
				minWidth: 200,
				width: 200
			},
			{
				key: 'is_observed',
				name: 'Observed',
				minWidth: 140,
				maxWidth: 140,
				width: 140,
				cellClass: 'has-editor',
				formatter(props) {
					return (
						<>
							{
								<span className="">
									{props.row.is_observed === true ? 'Yes' : props.row.is_observed === false ? 'No' : 'Select'}
								</span>
							}
							<EditIcon className="edit-icon" title="Double click to edit observed status" />
						</>
					);
				},
				editor: (p) => (
					<div>
						<FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} className="row-select-drpdwn">
							<Select
								labelId="Select"
								id="is_observed_dd"
								defaultOpen={true}
								value={p.row.is_observed}
								onChange={(e: any) => {
									p.onRowChange(
										{
											...p.row,
											is_observed: e.target.value === 'Yes' ? true : false,
											resource_group: sharedValue?.resource_group
										},
										true
									);
								}}
								label="Select"
							>
								<MenuItem value="">Select</MenuItem>
								{['Yes', 'No'].map((i) => (
									<MenuItem key={i} value={i}>
										{i}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
				)
			},
			{
				key: 'observed_start_date',
				name: 'Observation Start Date',
				minWidth: 240,
				maxWidth: 240,
				width: 240,
				cellClass: 'has-editor',
				formatter(props) {
					return (
						<>
							{props.row.observed_start_date ? (
								moment(props.row.observed_start_date.toString().split('T')[0]).format('MM-DD-YYYY')
							) : (
								<span className="datepicker_placeholder">Select Date</span>
							)}
							<DatePickerIcon
								className="datepicker_placeholder_icon"
								title="Double click to edit observation start date"
							/>
						</>
					);
				},
				editor: (p) => (
					<div>
						<LocalizationProvider className="outerDateContainer" dateAdapter={AdapterMoment}>
							<FormControl className="dateFilter-formControl" sx={{ m: 1 }}>
								<DatePicker
									showToolbar={true}
									toolbarTitle={<>Select Start Date</>}
									className="dateFilter"
									value={moment(p.row.observed_start_date?.toString().split('T')[0]).format('YYYY-MM-DD')}
									onChange={(date: any) => {
										p.onRowChange(
											{
												...p.row,
												observed_start_date: date.format('YYYY-MM-DD'),
												resource_group: sharedValue?.resource_group
											},
											true
										);
									}}
									renderInput={(params: any) => <TextField name="startDate" {...params} />}
								/>
							</FormControl>
						</LocalizationProvider>
					</div>
				)
			},
			{
				key: 'observed_end_date',
				name: 'Observation End Date',
				minWidth: 240,
				maxWidth: 240,
				width: 240,
				cellClass: 'has-editor',
				formatter(props) {
					return (
						<>
							{props.row.observed_end_date ? (
								moment(props.row.observed_end_date.toString().split('T')[0]).format('MM-DD-YYYY')
							) : (
								<span className="datepicker_placeholder">Select Date</span>
							)}
							<DatePickerIcon
								className="datepicker_placeholder_icon"
								title="Double click to edit observation end date"
							/>
						</>
					);
				},
				editor: (p) => (
					<div>
						<LocalizationProvider className="outerDateContainer" dateAdapter={AdapterMoment}>
							<FormControl className="dateFilter-formControl" sx={{ m: 1 }}>
								<DatePicker
									showToolbar={true}
									toolbarTitle={<>Select End Date</>}
									className="dateFilter"
									minDate={moment(p.row.observed_start_date?.toString().split('T')[0]).format('YYYY-MM-DD')}
									value={moment(p.row.observed_end_date?.toString().split('T')[0]).format('YYYY-MM-DD')}
									onChange={(date: any) => {
										p.onRowChange(
											{
												...p.row,
												observed_end_date: date.format('YYYY-MM-DD'),
												resource_group: sharedValue?.resource_group
											},
											true
										);
									}}
									renderInput={(params: any) => (
										<TextField
											name="EndDate"
											{...params}
											PopperProps={{
												placement: 'top-start',
												anchorOrigin: {
													vertical: 'bottom',
													horizontal: 'left'
												},
												transformOrigin: {
													vertical: 'top',
													horizontal: 'left'
												}
											}}
										/>
									)}
								/>
							</FormControl>
						</LocalizationProvider>
					</div>
				)
			}
		];
	}
	const defaultColumnNames = [
		'Resource',
		'Consumed Service',
		'Component Name',
		'Project',
		'Project Code',
		'Project Number',
		'Business Contact',
		'Observed',
		'Observation Start Date',
		'Observation End Date'
	];
	const dataFetchedRef = useRef(false);
	const [resourceList, setResourceList] = useState<Row[]>([]);
	const [loading, setLoading] = useState(false);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);

	const [searchParams, setSearchParams] = useSearchParams();
	const hasDrillDown = searchParams.has('isDrillDowned');

	const [filterData, setFilterData] = useState<Row[]>([]);
	const [filterInfo, setFilterInfo] = useState({} as any);

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		listResources(value, pageSize, sortingInfo, parseFilter(filterInfo));
	};

	const handlepageSizeChange = (event: any) => {
		listResources(page, event.target.value, sortingInfo, parseFilter(filterInfo));
	};

	const resetSearchParams = () => {
		if (hasDrillDown) {
			searchParams.delete('status');
			searchParams.delete('isDrillDowned');
			setSearchParams(searchParams);
		}
	};

	const handleFilter = (filterInfo: any) => {
		setFilterInfo(filterInfo);
		resetSearchParams();
		listResources(1, pageSize, sortingInfo, parseFilter(filterInfo));
	};

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

	const handleEditCell = (rows: any[], editData: any) => {
		const key = editData.column.key;
		const patchData: any = rows[editData.indexes[0]];
		patchData[key] = rows[editData.indexes[0]][key];
		const editedColName = editData.column.name;
		let editedValue = rows[editData.indexes[0]][key];
		switch (key) {
			case 'is_observed':
				editedValue = editedValue ? 'Yes' : 'No';
				break;
			case 'observed_start_date':
			case 'observed_end_date':
				editedValue = moment(editedValue).format('MM-DD-YYYY');
		}
		Swal.fire({
			title: `${editedColName} - <i>${editedValue}</i>`,
			text: `Do you want to update ${editedColName} of resource ${patchData.resource}?`,
			...swalOptions
		}).then((result: any) => {
			if (result.isConfirmed) {
				updateResource(patchData, rows, key);
			}
		});
	};
	async function updateResource(patchData: any, rows: Row[], key: any) {
		setLoading(true);
		const res = await patchResource(patchData);
		if (res.success) {
			setLoading(false);
			setResourceList(rows);
			if (key === 'project') updateSharedValue();
		}
		setLoading(false);
	}

	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		let storedResourcesState = localStorage.getItem('resourcesState ')
			? JSON.parse(localStorage.getItem('resourcesState ') ?? '')
			: null;
		if (storedResourcesState && storedResourcesState.pageNumber && storedResourcesState.pageSize) {
			listResources(
				1,
				storedResourcesState.pageSize,
				storedResourcesState.sortingInfo,
				parseFilter(storedResourcesState.filterInfo)
			);
		} else {
			listResources(page, pageSize, sortingInfo, parseFilter(filterInfo));
		}

		const activeId = document.getElementById('root');
		if (activeId) {
			const mainContent = document.getElementsByClassName('main-content')[0];

			if (mainContent) {
				// Use type assertion to let TypeScript know that myElement has a style property
				(mainContent as HTMLElement).style.overflow = 'scroll';
				(mainContent as HTMLElement).style.height = 'calc(100vh - 35px)';
			}

			activeId.classList.add('jobs-list-table');
			activeId.classList.remove('root');
			document.body.classList.add('jobs-exe-datas');
			document.body.classList.add('jobs-list-datas');
		}
	}, [sharedValue]);

	async function listResources(pageNumber: any, size: any, sortingInfo: any, resourceFilterInfo: any) {
		setLoading(true);
		const drillDownStatus = hasDrillDown ? searchParams.get('status') : '';
		const res = await getResources(
			sharedValue?.resource_group_id,
			pageNumber.toString(),
			size.toString(),
			sortingInfo,
			resourceFilterInfo,
			drillDownStatus
		);
		if (res.success) {
			setResourceList(res.data.data);
			setPaginationData(res.data.page);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
			// setFilterData(res.data.filterOptions);
			// setFilterInfo(resourceFilterInfo);
			setLoading(false);
			const resourcesState = {
				page: res.data.page,
				// filterOptions: res.data.filterOptions,
				pageSize: size,
				pageNumber,
				sortingInfo,
				filterInfo: resourceFilterInfo
			};
			localStorage.setItem('resourcesState ', JSON.stringify(resourcesState));
		}
	}
	const handleSorting = (sortingInfo: any) => {
		listResources(page, pageSize, sortingInfo, parseFilter(filterInfo));
	};

	return (
		<div className="resources_tabledata resourceGroups_tabledata page-table">
			<h3>Resources</h3>
			<Grid>
				{!loading ? (
					<CustomizedDataGrid
						tableColumns={getColumns()}
						tableData={resourceList}
						tableType="PCOBS_Resource"
						defaultColumnNames={defaultColumnNames}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						filterDataOptions={filterData}
						onFilterCheck={handleFilter}
						currFilter={filterInfo}
						onCellEdit={handleEditCell}
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={resourceList?.length}
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isDateRangeFilter={false}
						isDisabled={false}
						isFetching={loading}
					/>
				) : (
					<Loader />
				)}
			</Grid>
		</div>
	);
};

export default ResourceListing;
