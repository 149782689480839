import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, FormControlLabel, Menu, Popover, Stack, TextField } from '@mui/material';
import { dashboardUrl } from '../../../service/config';
import AnalyticsVisualization from '../../framework/analyticsVisualization';
import '../Summary/Summary.scss';
import './ResourceOpt.scss';
import CPUUtilization from './CPUUtilization';
import MemoryUtilization from './MemoryUtilization';
import moment from 'moment';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as DatePickerIcon } from '../../../assets/calendar-outline_white.svg';
import { ReactComponent as FilterIcon } from '../../../assets/ml_summary_filter.svg';
import { getDatabricksResourceOptimizationChart, getDecodedToken, getDatabricksSectors } from '../../../service/service';
import useStyles from '../../jobs/jobs_styles';
import MenuItem from '@mui/material/MenuItem';

const ResourceOptimization = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const minJobExecDate = '2023-03-01';
	const dateFrom: string = searchParams.get('fromDate')?.substring(0, 10) as string;
	const dateTo: string = searchParams.get('toDate')?.substring(0, 10) as string;
	const startDt = moment(dateFrom).startOf('day').subtract(1, 'hours').format('YYYY-MM-DD');
	const endDt = moment(dateTo).endOf('day').subtract(1, 'hours').format('YYYY-MM-DD');
	const [fromDateTime, setFromDateTime] = useState<any>(searchParams.has('fromDate') ? startDt : null);
	// const [toDateTime, setToDateTime] = useState<any>(
	// 	searchParams.has('toDate') ? endDt : null
	// );
	const [propsFromDateTime, setPropsFromDateTime] = useState<any>(searchParams.has('fromDate') ? startDt : null);
	const [propsToDateTime, setPropsToDateTime] = useState<any>(searchParams.has('toDate') ? endDt : null);

	// sector filter
	const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
	const [filterMenu, setFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	const classes = useStyles();
	const [itemsChecked, setItemsChecked] = useState(false);
	const [sectorOptionsList, setSectorOptionsList] = useState<any[]>([]);
	const [checkedList, setCheckedList] = useState<any[]>([]);
	const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);
	const [sector, setCurrSector] = useState<any[]>([]);
	const dataFetchedRef = useRef(false);
	const [dateSelect, setDateSelect] = useState(0);

	// [Date Range Menu]
	const [dateRangeMenuOpen, setDateRangeMenuOpen] = React.useState<null | HTMLElement>(null);

	const handleDateClick = (event: React.MouseEvent<HTMLElement>) => {
		setDateRangeMenuOpen(event.currentTarget);
	};

	const handleDateRangeMenu = () => {
		setDateRangeMenuOpen(null);
	};

	const open = Boolean(dateRangeMenuOpen);

	const handleDateTimeChange = (field: any, dateTime: any) => {
		if (field === 'fromDateTime') setFromDateTime(dateTime);
		// else setFromDateTime(dateTime);
		localStorage.setItem('processedDate', moment(dateTime).format('YYYY-MM-DD'));
		applyDateTimeRange(dateTime);
		setDateSelect(dateSelect + 1);
	};

	const resetSearchParams = () => {
		if (searchParams.has('fromDate') || searchParams.has('fromDate')) {
			searchParams.delete('status');
			searchParams.delete('scheduleDate');
			searchParams.delete('fromDate');
			searchParams.delete('fromDate');
			setSearchParams(searchParams);
		}
	};
	const applyDateTimeRange = (dateTime: any) => {
		setPropsFromDateTime(dateTime);
		setPropsToDateTime(dateTime);
		resetSearchParams();
		setDateRangeMenuOpen(null);
	};
	const filterClick = (event: any) => {
		event.preventDefault();
		setFilterMenu(
			filterMenu === null
				? {
					mouseX: parseInt(event.clientX) - 20,
					mouseY: parseInt(event.clientY) - 6
				}
				: null
		);
	};

	const handleClose = (event: any) => {
		setFilterMenu(null);
	};

	const selectAllCheck = (e: any) => {
		const { checked } = e.target;
		const collection: any[] = [];
		if (checked) setCheckedList(sectorOptionsList);
		else setCheckedList([]);
		setItemsChecked(checked);
	};

	const handleChange = (e: any) => {
		const { value, checked } = e.target;
		const newlyChecked = [];
		if (checked && e.target.value) {
			newlyChecked.push(e.target.value);
		}
		if (checked) {
			setCheckedList([...checkedList, ...newlyChecked]);
			if(sectorOptionsList.length === (checkedList.length + newlyChecked.length)){
				setItemsChecked(true);
			}
		} else {
			setCheckedList(checkedList.filter((item) => item !== value));
			setItemsChecked(false);
		}
	};
	const handleOkClick = (event: any) => {
		if (checkedList.length > 0) {
			setCurrSectorFilter(checkedList);
		} else {
			setCurrSectorFilter([]);
		}
		setCurrSector(checkedList);
		
		if (sectorOptionsList.length == checkedList.length) {
			setCheckedList([])
			localStorage.setItem('d-resource-sector-filter', JSON.stringify([]));
		} else {
			localStorage.setItem('d-resource-sector-filter', JSON.stringify(checkedList));
		}
		// DBWorkspaceSpentListingFunc(page, pageSize, sortingInfo, fromDateTime, toDateTime, parseFilter(filterInfo), checkedList);
		// updateSharedValue({ sectorFilter: checkedList });
		
		handleClose(event);
	};

	async function fetchSectorOptionsData() {
		const sectorRes = await getDatabricksSectors('1', '100', '');
		if (sectorRes.success) {
			setSectorOptionsList(
				sectorRes.data.sector.map((x: any) => {
					if (x.name === 'S&T') {
						return 'SNT';
					} else if (x.name === 'Europe') {
						return 'EUROPE';
					}
					else if (x.name === 'Global') {
						return 'GLOBAL';
					}
					else {
						return x.name;
					}
				})
			);
		}
	}

	let fromDate = moment(propsFromDateTime).format('YYYY-MM-DD');
	let toDate = moment(propsToDateTime).format('YYYY-MM-DD');
	let otherParams: any = {
		'var-sector': currSectorFilter,
		'var-fromDate': fromDate ? fromDate : ''
	}
	if (propsFromDateTime) {
		otherParams = {
			'var-fromDate': fromDate ? fromDate : '',
			'var-toDate': toDate ? toDate : '',
			'var-sector': currSectorFilter
		}
	}
	async function fetchDate() {
		const res = await getDatabricksResourceOptimizationChart();
		if (res.success) {
			if (!localStorage.getItem('processedDate')) {
				localStorage.setItem('processedDate',res.data.processedDate);
				setFromDateTime(moment(res.data.processedDate));
				setPropsFromDateTime(moment(res.data.processedDate));
			} else {
				setFromDateTime(moment(localStorage.getItem('processedDate')));
				setPropsFromDateTime(moment(localStorage.getItem('processedDate')));
			}
		}
	}

	useEffect(() => {
		fetchDate();
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		if (userSector === 'all') fetchSectorOptionsData();
		let storedSectorFilter = localStorage.getItem('d-resource-sector-filter')
			? JSON.parse(localStorage.getItem('d-resource-sector-filter') ?? '')
			: null;
		if (storedSectorFilter) {
			setCheckedList(storedSectorFilter);
			setCurrSectorFilter(storedSectorFilter);
		}
		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/databricks/resource/dashboard') {
			if (activeId) {
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('databricks-jobs');
				document.body.classList.add('datefilter-align-sector');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				activeId.classList.remove('jobs-list-table');
				activeId.classList.remove('jobs-list');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.remove('jobs-exe-datas');
			}
		}
	}, []);

	return (
		<div className="dashboard-panel job-schedules-wrap">
			<div className="job-exexutions-dateFilter-div">
				{/* [Date Range Menu] */}
				<div style={{ display: 'flex', alignItems: 'center', marginTop: '-12px' }}>
					{/* condition for default Today  */}
					{/* {moment(fromDateTime).format('DD MM YYYY') ===
						moment().startOf('day').subtract(1, 'hours').format('DD MM YYYY') &&
						 <div style={{ marginRight: '10px' }}>Today</div>} */}
					{
						<LocalizationProvider className="outerDateContainer" dateAdapter={AdapterMoment}>
							<DatePicker
								showToolbar={true}
								components={{
									OpenPickerIcon: DatePickerIcon
								}}
								maxDate={new Date()}
								PopperProps={{ placement: 'auto' }}
								className="dateFilterID"
								value={moment(fromDateTime).tz('America/New_York')}
								onChange={(dateTime) => {
									handleDateTimeChange('fromDateTime', dateTime);
								}}
								renderInput={(params: any) => <TextField name="startDateTime" {...params} />}
							/>
						</LocalizationProvider>
					}
				</div>

				{/* Code for sector filter */}
				<Stack>
					{userSector === 'all' ? (
						<FilterIcon onClick={filterClick} className="sector-filter1" />
					) : (
						<div className="separeat_sector">Sector: {getDecodedToken()?.sector?.name}</div>
					)}
					<Popover
						open={filterMenu !== null}
						onClose={handleClose}
						className="filterContainer"
						anchorReference="anchorPosition"
						anchorPosition={filterMenu !== null ? { top: filterMenu.mouseY, left: filterMenu.mouseX } : undefined}
					>
						<div className="text-filter-row">
							<div className="selectAll-sec">
								<MenuItem className="selectAll">
									<FormControlLabel
										className={classes.checkbox}
										label="All"
										control={
											<Checkbox
												color="success"
												value="all"
												checked={itemsChecked}
												onChange={(e) => {
													selectAllCheck(e);
												}}
											/>
										}
									/>
								</MenuItem>
								<div className="checkbox-sec">
									{sectorOptionsList &&
										sectorOptionsList.map((filter: any) => (
											<MenuItem key={filter}>
												<FormControlLabel
													className={classes.checkbox}
													label={filter === true ? 'Yes' : filter === false ? 'No' : filter}
													control={
														<Checkbox
															color="success"
															value={filter}
															checked={
																(filter === true && checkedList.includes('true')) ||
																(filter === false && checkedList.includes('false')) ||
																checkedList.includes(filter)
															}
															onChange={(e) => {
																handleChange(e);
															}}
														/>
													}
												/>
											</MenuItem>
										))}
								</div>
							</div>
							<div className="buttonSec">
								<Button variant="contained" className={classes.cancelBtn} onClick={handleClose}>
									cancel
								</Button>
								<Button variant="contained" className={classes.okBtn} onClick={handleOkClick}>
									ok
								</Button>
							</div>
						</div>
					</Popover>
				</Stack>
			</div>
			<>
				<Stack>
					<AnalyticsVisualization
						params={{
							dashboardUrl: dashboardUrl.dataBricksResourceOptimization,
							className: 'dataBricksResourceOptIframeContainer',
							otherParams: otherParams,
							seamless: true
						}}
					/>
				</Stack>
			</>
			<CPUUtilization
				fromDateTime={propsFromDateTime}
				toDateTime={propsFromDateTime}
				sectorFilter={sector}
				setFromDateTime={setFromDateTime}
				dateSelect={dateSelect}
			/>
			<MemoryUtilization
				fromDateTime={propsFromDateTime}
				toDateTime={propsFromDateTime}
				sectorFilter={sector}
				setFromDateTime={setFromDateTime}
				dateSelect={dateSelect}
			/>
		</div>
	);
};

export default ResourceOptimization;
