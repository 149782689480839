import React, { useEffect, useState, useRef } from 'react';
import {Button, Checkbox, FormControlLabel, Grid, MenuItem, Popover, Stack} from '@mui/material';
import { powerBICapacityMetricsData } from '../../service/service';
import AnalyticsVisualization from '../framework/analyticsVisualization';
import { dashboardUrl } from '../../service/config';
import UnderDevelopment from '../../assets/under-construction.svg';
import { ReactComponent as FilterIcon } from '../../assets/ml_summary_filter.svg';
import '../PowerBIObservability/powerBIObs.scss';
import useStyles from '../jobs/jobs_styles';
import { CustomizedDataGrid } from '../customized_data_grid';
import { Column } from 'react-data-grid';
import { Link, useSearchParams } from 'react-router-dom';
import { getDecodedToken, getSector } from '../../service/service';

const CapacityObservability = () => {

	interface Row {
		capacity: string,
		capacityOwner: string,
		capacityStatus: string,
		workspace: string,
		workspaceOwner: string,
		itemKind: string,
		itemName: string,
		itemStatus: string,
		itemOwner: string,
		operation: string,
		cuPercent: string,
		duration: string,
		throttled: string,
		frozen: string,
	}

	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'capacity',
				name: 'Capacity',
				width: 140,
				minWidth: 140
			},
			{
				key: 'capacityOwner',
				name: 'Capacity Owner',
				width: 190,
				minWidth: 190,
			},
			{
				key: 'capacityStatus',
				name: 'Capacity Status',
				width: 150,
				minWidth: 150,
				cellClass: 'center-text',
				formatter: ({ row }) => {
					let color = ''; let backgroundColor = '';
					if (row.capacityStatus === 'HEALTHY') { color = 'green'; backgroundColor = 'darkgreen'; }
					if (row.capacityStatus === 'THROTTLED') { color = 'red'; backgroundColor = 'darkRed'; }
					if (row.capacityStatus === 'FROZEN') { color = 'blue'; backgroundColor = 'darkBlue'; }
					return (
					  <span style={{ color, backgroundColor, borderRadius: '50px', padding: '2px 10px', width: '100px', display: 'inline-block', lineHeight: '20px', fontWeight: 'bold'}}>
						{row.capacityStatus}
					  </span>
					);
				}
			},
			{
				key: 'workspace',
				name: 'Workspace',
				width: 180,
				minWidth: 180,
			},
			{
				key: 'workspaceOwner',
				name: 'Workspace Owner',
				width: 200,
				minWidth: 200,
			},
			{
				key: 'itemKind',
				name: 'Item Kind',
				minWidth: 150,
			},
			{
				key: 'itemName',
				name: 'Item Name',
				minWidth: 150,
			},
			{
				key: 'itemStatus',
				name: 'Item Status',
				minWidth: 130,
				width: 130,
			},
			{
				key: 'itemOwner',
				name: 'Item Owner',
				width: 190,
				minWidth: 190
			},
			{
				key: 'operation',
				name: 'Operation',
				width: 180,
				minWidth: 180,
			},
			{
				key: 'cuPercent',
				name: 'CU %',
				minWidth: 125,
				width: 125
			},
			{
				key: 'duration',
				name: 'Duration',
				minWidth: 130,
				width: 130,
			},
			{
				key: 'throttled',
				name: 'Throttled',
				minWidth: 100,
				width: 100,
			},
			{
				key: 'frozen',
				name: 'Frozen',
				minWidth: 100,
				width: 100,
			}
		];
	}

	const [incidentDetails, setIncidentDetails] = useState<Row[]>([]);
	const [loading, setLoading] = useState(false);
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const classes = useStyles();

	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [filterData, setFilterData] = useState<Row[]>([]);
	const [filterInfo, setFilterInfo] = useState([]);
	const [searchDetails, setFilterSearchInfo] = useState([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);
	const [sectorOptionsList, setSectorOptionsList] = useState<any[]>([]);
	console.log(sectorOptionsList,'sectorOptionsList');
	const dataFetchedRef = useRef(false);
	const [filterMenu, setFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	const [itemsChecked, setItemsChecked] = useState(false);
	const [checkedList, setCheckedList] = useState<any[]>([]);
	const hasDrillDown = searchParams.has('isDrillDowned');
	const [metricsData, setMetricsData] = useState<any>(null);

	const defaultColumnNames = [
		'Capacity',
		'Capacity Owner',
		'Capacity Status',
		'Workspace',
		'Workspace Owner',
		'Item Kind',
		'Item Name',
		'Item Status',
		'Item Owner',
		'Operation',
		'CU %',
		'Duration',
		'Throttled',
		'Frozen',
	];

	const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
	console.log(userSector,'userSector');

	const filterClick = (event: any) => {
		event.preventDefault();
		setFilterMenu(
			filterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 20,
						mouseY: parseInt(event.clientY) - 6
				  }
				: null
		);
	};

	const handleClose = (event: any) => {
		setFilterMenu(null);
	};

	const selectAllCheck = (e: any) => {
		const { checked } = e.target;
		const collection: any[] = [];
		if (checked) setCheckedList(sectorOptionsList);
		else setCheckedList([]);
		setItemsChecked(checked);
	};
	const handleChange = (e: any) => {
		const { value, checked } = e.target;
		const newlyChecked = [];
		if (checked && e.target.value) {
			newlyChecked.push(e.target.value);
		}
		if (checked) {
			setCheckedList([...checkedList, ...newlyChecked]);
		} else {
			setCheckedList(checkedList.filter((item) => item !== value));
		}
	};

	async function fetchSectorOptionsData() {
		const sectorRes = await getSector('1', '100', '');
		console.log(sectorRes,'sectorRes');
		if (sectorRes.success) {
			setSectorOptionsList(sectorRes.data.sector.map((x: any) => x.name));
		}
	}

	const handleOkClick = (event: any) => {
		// Create a new list with "S&T" replaced by "SNT"
		const updatedCheckedList = checkedList.map((value: string) => {
			if (value.includes('S&T')) {
				return value.replace('S&T', 'SNT');
			}
			return value;
		});
	
		if (updatedCheckedList.length > 0) {
			setCurrSectorFilter(updatedCheckedList);
		} else {
			setCurrSectorFilter([]);
		}
		// updateSharedValue({ sectorFilter: updatedCheckedList });
		console.log(updatedCheckedList);
		localStorage.setItem('ml-summary-sector-filter', JSON.stringify(updatedCheckedList));
		fetchData();
		handleClose(event);
	};

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		capacityDetailFunc(value, pageSize, sortingInfo, parseFilter(filterInfo));
	};

	const handlepageSizeChange = (event: any) => {
		capacityDetailFunc('1', event.target.value, sortingInfo, parseFilter(filterInfo));
	};
	const handleSorting = (sortingInfo: any) => {
		capacityDetailFunc(page, pageSize, sortingInfo, parseFilter(filterInfo));
	};

	const handleFilter = (filterInfo: any) => {
		setFilterInfo(filterInfo);
		capacityDetailFunc(1, pageSize, sortingInfo, parseFilter(filterInfo));
	};
	const handleSearch = (searchDetails: any, columnKey: any) => {
		// incidentSearchFunc(searchDetails, parseFilter(filterInfo, columnKey), columnKey);
	};

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

	async function capacityDetailFunc(pageNumber: any, size: any, sortingInfo: any, jobFilterInfo: any) {
		// setLoading(true);
		if (pageSize !== size) {
			pageNumber = 1;
		}
		const dummyIncidentData =
		[
			{
                capacity: 'Capacity 1',
                capacityOwner: 'John Doe',
                capacityStatus: 'THROTTLED',
                workspace: 'Workspace 1',
                workspaceOwner: 'John Doe',
                itemKind: 'Report',
                itemName: 'Item Name 1',
                itemStatus: 'Normal',
                itemOwner: 'Item Owner 1',
                operation: 'Interactive',
                cuPercent: '1.234556768',
                duration: '-',
                vendorName: 'N/A',
                throttled: 'Yes',
				frozen: 'Yes',
            },
			{
                capacity: 'Capacity 2',
                capacityOwner: 'John Doe',
                capacityStatus: 'FROZEN',
                workspace: 'Workspace 2',
                workspaceOwner: 'John Doe',
                itemKind: 'Report',
                itemName: 'Item Name 2',
                itemStatus: 'Normal',
                itemOwner: 'Item Owner 2',
                operation: 'Interactive',
                cuPercent: '1.234556768',
                duration: '-',
                vendorName: 'N/A',
                throttled: 'Yes',
				frozen: 'Yes',
            },
			{
                capacity: 'Capacity 3',
                capacityOwner: 'John Doe',
                capacityStatus: 'HEALTHY',
                workspace: 'Workspace 3',
                workspaceOwner: 'John Doe',
                itemKind: 'Report',
                itemName: 'Item Name 3',
                itemStatus: 'Normal',
                itemOwner: 'Item Owner 3',
                operation: 'Interactive',
                cuPercent: '1.234556768',
                duration: '-',
                vendorName: 'N/A',
                throttled: 'Yes',
				frozen: 'Yes',
            },
			{
                capacity: 'Capacity 4',
                capacityOwner: 'John Doe',
                capacityStatus: 'FROZEN',
                workspace: 'Workspace 4',
                workspaceOwner: 'John Doe',
                itemKind: 'Report',
                itemName: 'Item Name 4',
                itemStatus: 'Normal',
                itemOwner: 'Item Owner 4',
                operation: 'Interactive',
                cuPercent: '1.234556768',
                duration: '-',
                vendorName: 'N/A',
                throttled: 'Yes',
				frozen: 'Yes',
            },
			{
                capacity: 'Capacity 5',
                capacityOwner: 'John Doe',
                capacityStatus: 'FROZEN',
                workspace: 'Workspace 5',
                workspaceOwner: 'John Doe',
                itemKind: 'Report',
                itemName: 'Item Name 5',
                itemStatus: 'Normal',
                itemOwner: 'Item Owner 5',
                operation: 'Interactive',
                cuPercent: '1.234556768',
                duration: '-',
                vendorName: 'N/A',
                throttled: 'Yes',
				frozen: 'Yes',
            },
			{
                capacity: 'Capacity 6',
                capacityOwner: 'John Doe',
                capacityStatus: 'FROZEN',
                workspace: 'Workspace 6',
                workspaceOwner: 'John Doe',
                itemKind: 'Report',
                itemName: 'Item Name 6',
                itemStatus: 'Normal',
                itemOwner: 'Item Owner 6',
                operation: 'Interactive',
                cuPercent: '1.234556768',
                duration: '-',
                vendorName: 'N/A',
                throttled: 'Yes',
				frozen: 'Yes',
            }
		];
		setIncidentDetails(dummyIncidentData);
		paginationData.totalElements = 1;
		paginationData.totalPages = 1;
		setPage(pageNumber);
		setPageSize(size);
		setSortingInfo(sortingInfo);
		setLoading(false);
	}

	async function fetchData() {
		try {
			let storedSectorFilter = localStorage.getItem('ml-summary-sector-filter')
			? JSON.parse(localStorage.getItem('ml-summary-sector-filter') ?? '')
			: null;
			const response  = await powerBICapacityMetricsData(storedSectorFilter);
			console.log("Fetched Data:", storedSectorFilter );

			// Transform the data to match the expected structure
			if (response && response.data) {
				const transformedData = {
					healthyCount: response.data.data.CapacitiesHealthy,
					throttledCount: response.data.data.CapacitiesThrottled,
					frozenCount: response.data.data.CapacitiesFrozen,
					riskCount: response.data.data.CapacitiesAtRiskOfGettingThrottled,
					frozenRecoveredCount: response.data.data.CapacitiesOutOfFrozen,
					totalCount: response.data.data.latestRecords.length
				};
				setMetricsData(transformedData);
			} else {
				console.error("No data returned from API");
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};


    useEffect(() => {
		
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;

		if (userSector === 'all') fetchSectorOptionsData();
		let storedSectorFilter = localStorage.getItem('ml-summary-sector-filter')
			? JSON.parse(localStorage.getItem('ml-summary-sector-filter') ?? '')
			: null;
			console.log("Fetched Data1:", storedSectorFilter );
		if (storedSectorFilter) {
			setCheckedList(storedSectorFilter);
			setCurrSectorFilter(storedSectorFilter);
		}
		capacityDetailFunc(page, pageSize, sortingInfo, parseFilter(filterInfo));
		const activeId = document.getElementById('root');
		// if (window.location.pathname === '/dataestate/incident-details') {
		// 	if (activeId) {
		// 		document.head.classList.add('theme-bg-blue');
		// 		document.body.classList.add('theme-bg-blue');
		// 		activeId.classList.add('jobs-list-table');
		// 		activeId.classList.remove('jobs-list');
		// 		activeId.classList.remove('root');
		// 		document.body.classList.add('jobs-exe-datas');
		// 		document.body.classList.remove('jobs-run-table');
		// 		document.body.classList.add('jobs-list-datas');
		// 		document.body.classList.add('datefilter-align-sector');
		// 		document.body.classList.add('sector-label');
		// 	}
		// } else {
		// 	if (activeId) {
		// 		document.body.classList.remove('jobs-list-datas');
		// 		activeId.classList.add('root');
		// 		document.body.classList.add('dashboard-panel-dataestate');
		// 		document.body.classList.add('datefilter-align-sector');
		// 	}
		// }

		
		fetchData();
    }, []);

	if (!metricsData) {
        return <div>Loading data...</div>;
    }

	async function incidentSearchFunc(searchDetails: any, jobFilterInfo: any, columnKey: any) {
		// const res = await jobSearch(searchDetails, jobFilterInfo, columnKey);
		// if (res.success) {
		// 	setFilterSearchInfo(res.data);
		// }
	}

	return (
		<div className="dashboard-panel-powerBIObs job-schedules-wrap">
					<div className="powerBI-sector-filter-cost powerBI-sector-filter">
						<Stack className="sector-filter-dataestate">
							<><span className='sector-cta'>
							   <span className='sector-label'>All Sectors</span> 
							   <span>
								{userSector === 'all' ? (
									<FilterIcon onClick={filterClick} />
								) : (
								<div className="sector-filter-text-ml">Sector: {getDecodedToken()?.sector?.name}</div>
								)}
								<Popover
								open={filterMenu !== null}
				                onClose={handleClose}
				                className="filterContainer"
				                anchorReference="anchorPosition"
				                anchorPosition={filterMenu !== null ? { top: filterMenu.mouseY, left: filterMenu.mouseX } : undefined}
								>
									<div className="text-filter-row">
										<div className="selectAll-sec">
											<MenuItem className="selectAll">
											<FormControlLabel
											className={classes.checkbox}
								            label="All"
								            control={
											<Checkbox
											color="success"
										    value="all"
										    checked={itemsChecked}
										    onChange={(e) => {
											selectAllCheck(e);
										}}
										/>
									}
									/>
									        </MenuItem>
											<div className="checkbox-sec">
												{sectorOptionsList && sectorOptionsList.map((filter: any) => (
													<MenuItem key={filter}>
														<FormControlLabel
														className={classes.checkbox}
											            label={filter === true ? 'Yes' : filter === false ? 'No' : filter}
											            control={
														<Checkbox
														color="success"
													    value={filter}
										                checked={
															(filter === true && checkedList.includes('true')) ||
														    (filter === false && checkedList.includes('false')) ||
														    checkedList.includes(filter)
														}
														onChange={(e) => {
														handleChange(e);
													}}
												/>
											}
											/>
											</MenuItem>
										))}
										    </div>
										</div>
										<div className="buttonSec">
											<Button variant="contained" className={classes.cancelBtn} onClick={handleClose}>
												cancel
											</Button>
											<Button variant="contained" className={classes.okBtn} onClick={handleOkClick}>
							                    ok
						                    </Button>
											</div>
									</div>
							</Popover>
							   </span>
							  </span></>
						</Stack>
			        </div>
					<div className="page-table jobs-tabledata page-table-ibp-monitor">
						<div className="ibp-dashboard-wrapper">
							<Grid container spacing={0} className="headerIPB">
								<Grid item xs={12} className="headerIPB-title subheaderIPB-title no-ml custom-grid-data-powerBIObs">
									<Grid container spacing={1}>
										<Grid item xs={3} className="powerBIObs-title">
											<div className="monitor-head">Capacities Healthy</div>
										    <div className="powerBIObs-subheader">{metricsData.healthyCount}<span className="powerBIObs-subheader-span">/{metricsData.totalCount}</span></div>
									    </Grid>
										<Grid item xs={3} className="powerBIObs-title">
											<div className="monitor-head">Capacities Throttled</div>
										    <div className="powerBIObs-subheader">{metricsData.throttledCount}<span className="powerBIObs-subheader-span">/{metricsData.totalCount}</span></div>
									    </Grid>
										<Grid item xs={3} className="powerBIObs-title">
											<div className="monitor-head">Capacities Frozen</div>
										    <div className="powerBIObs-subheader">{metricsData.frozenCount}<span className="powerBIObs-subheader-span">/{metricsData.totalCount}</span></div>
									    </Grid>
										<Grid item xs={3} className="powerBIObs-title">
											<div className="monitor-head">Capacities at risk of Getting Throttled</div>
										    <div className="powerBIObs-subheader">{metricsData.riskCount}<span className="powerBIObs-subheader-span">/{metricsData.totalCount}</span></div>
									    </Grid>
										<Grid item xs={3} className="powerBIObs-title">
											<div className="monitor-head">Capacities out of Frozen (In Last 24 hours)</div>
										    <div className="powerBIObs-subheader">{metricsData.frozenRecoveredCount}<span className="powerBIObs-subheader-span">/{metricsData.totalCount}</span></div>
									    </Grid>
								    </Grid>	
							    </Grid>
							</Grid>	
							<div className="capacityDetails-title">
							Details
						    </div>
						    <div className="capacityDetails_tabledata capacityDetails_tabledata-details">
							<CustomizedDataGrid 
								tableColumns={getColumns()}
								tableData={incidentDetails}
								tableType="incident-details-ver"
								defaultColumnNames={defaultColumnNames}
								onSorting={handleSorting}
								currSorting={sortingInfo}
								filterDataOptions={filterData}
								searchDataValues={searchDetails}
								onFilterCheck={handleFilter}
								currFilter={filterInfo}
								onFilterSearch={handleSearch}
								page={page}
								pageSize={pageSize}
								paginationData={paginationData}
								listLength={incidentDetails.length}
								handlepageSizeChange={handlepageSizeChange}
								handlePageChange={handlePageChange}
								isFetching={loading}
								showExport={false}
								fileName={`Jobs.xlsx`}
								isDateRangeFilter={false}
								isDisabled={true}
								/>
						    </div>
						</div>	
					</div>	
				{/* // <div style={{ position: 'absolute', left: '45%', top: '50%', color: 'darkgray' }}>
				// 	<Grid item xs={12} sm={4} className="full-width no-padl">
				// 	<div className="panel-box-summary mb-10">
				// 		<div className='tooltip-panel'>
				// 			<h2 className="panel-box--title">Capacities Healthy</h2>
				// 	    </div>
					
				//     </div>
				// 	</Grid>
				// 	<img src={UnderDevelopment} />
				// 	<p style={{ position: 'relative', right: '25%', margin: '0' }}>Dashboard under development!</p>
				// </div> */}
		</div>
	);
};


export default CapacityObservability;
