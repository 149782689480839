import React, { useEffect, useRef, useState, lazy, Suspense } from 'react';
import {
	Button,
	Chip,
	Grid,
	Menu,
	Stack,
	TextField,
	List,
	ListItem,
	ListItemText,
	FormControlLabel,
	Select,
	InputLabel,
	FormControl
} from '@mui/material';
import { dashboardUrl } from '../../../service/config';
//import AnalyticsVisualization from '../../framework/analyticsVisualization';
import './Summary.scss';
//import Workbooks from './Workbooks';
//import TableauUsers from './Users';
import moment from 'moment';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as DatePickerIcon } from '../../../assets/calendar-outline_white.svg';
import { ReactComponent as FilterIcon } from '../../../assets/ml_summary_filter.svg';
import { ReactComponent as ServerIcon } from '../../../assets/solar_server-outline.svg';
import { getCostSummaryChart, getDecodedToken, getSector } from '../../../service/service';
import useStyles from '../../jobs/jobs_styles';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';

const AnalyticsVisualization = lazy(() => import('../../framework/analyticsVisualization'));
const Workbooks = lazy(() => import('./Workbooks'));
const TableauUsers = lazy(() => import('./Users'));


const TableauSummary = () => {	
	const [searchParams, setSearchParams] = useSearchParams();
	const hasDrillDown = searchParams.has('isDrillDowned');
	const minJobExecDate = '2023-03-01';
	const dateFrom: string = searchParams.get('fromDate')?.substring(0, 10) as string;
	const startDt = moment(dateFrom).startOf('day').subtract(1, 'hours').format('YYYY-MM-DD');
	const [fromDateTime, setFromDateTime] = useState<any>(
		searchParams.has('fromDate') ? startDt : moment().startOf('day').subtract(1, 'hours')
	);

	
	const [propsFromDateTime, setPropsFromDateTime] = useState<any>(
		searchParams.has('fromDate') ? startDt : moment().startOf('day').subtract(1, 'hours')
	);
	
		// sector filter
		const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
		const [filterMenu, setFilterMenu] = useState<{
			mouseX: number;
			mouseY: number;
		} | null>(null);
		const classes = useStyles();
		const [itemsChecked, setItemsChecked] = useState(false);
		const [sectorOptionsList, setSectorOptionsList] = useState<any[]>([]);
		const [checkedList, setCheckedList] = useState<any[]>([]);
		const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);
		const [sector, setCurrSector] = useState<any[]>([]);
		const dataFetchedRef = useRef(false);

		// server filter
		const [instance, setInstance] = useState<any>('all');
	

	// [Date Range Menu]
	const [dateRangeMenuOpen, setDateRangeMenuOpen] = React.useState<null | HTMLElement>(null);

	const handleDateClick = (event: React.MouseEvent<HTMLElement>) => {
		setDateRangeMenuOpen(event.currentTarget);
	};

	const handleDateRangeMenu = () => {
		setDateRangeMenuOpen(null);
	};

	const open = Boolean(dateRangeMenuOpen);

	const handleDateTimeChange = (field: any, dateTime: any) => {
		localStorage.setItem('selectedDateTime', dateTime);// Tableau drilldown filter changes
		if (field === 'fromDateTime') setFromDateTime(dateTime);
		// else setToDateTime(dateTime);
		applyDateTimeRange(dateTime);
	};

	const resetSearchParams = () => {
		if (searchParams.has('fromDate')) {
			searchParams.delete('status');
			searchParams.delete('scheduleDate');
			searchParams.delete('fromDate');
			setSearchParams(searchParams);
		}
	};
	const applyDateTimeRange = (dateTime: any) => {
		setPropsFromDateTime(dateTime);
		resetSearchParams();
		setDateRangeMenuOpen(null);
	};
	const sectorFilterClick = (event: any) => {
		event.preventDefault();
		setFilterMenu(
			filterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 20,
						mouseY: parseInt(event.clientY) - 6
				  }
				: null
		);
	};

	const handleClose = (event: any) => {
		setFilterMenu(null);
	};

	const selectAllCheck = (e: any) => {
		const { checked } = e.target;
		const collection: any[] = [];
		if (checked) setCheckedList(sectorOptionsList);
		else setCheckedList([]);
		setItemsChecked(checked);
	};

	const handleChange = (e: any) => {
		const { value, checked } = e.target;
		const newlyChecked = [];
		if (checked && e.target.value) {
			newlyChecked.push(e.target.value);
		}
		if (checked) {
			setCheckedList([...checkedList, ...newlyChecked]);
			if(sectorOptionsList.length === (checkedList.length + newlyChecked.length)){
				setItemsChecked(true);
			}
		} else {
			setCheckedList(checkedList.filter((item) => item !== value));
			setItemsChecked(false);
		}
	};
	const handleOkClick = (event: any) => {
		localStorage.setItem('selectedSector', JSON.stringify(checkedList)); // Tableau drilldown filter changes
		if (checkedList.length > 0) {
			setCurrSectorFilter(checkedList);
		} else {
			setCurrSectorFilter([]);
		}
		setCurrSector( checkedList );
		// DBWorkspaceSpentListingFunc(page, pageSize, sortingInfo, fromDateTime, toDateTime, parseFilter(filterInfo), checkedList);
		// updateSharedValue({ sectorFilter: checkedList });
		// localStorage.setItem('dcs-summary-sector-filter', JSON.stringify(checkedList));
		handleClose(event);
	};

	async function fetchSectorOptionsData() {
		const sectorRes = await getSector('1', '100', '');
		if (sectorRes.success) {
			const filteredSectorOptions = sectorRes.data.sector.filter((x: any) => x.name !== 'S&T').map((x: any) => x.name);
			filteredSectorOptions.push('Blank');
        	setSectorOptionsList(filteredSectorOptions);
		}
	}
	const [serverFilter, setServerFilter] = useState<any>('all');

	// Server filter code
	const handleServerChange = (field: any, selectedServer: any) => {
		// Tableau server filter changes
		localStorage.setItem('selectedServer', selectedServer.target.value);
		if (field === 'instance') setInstance(selectedServer.target.value);
		applyServerFilter(selectedServer.target.value, selectedServer.target.value);
	};

	const applyServerFilter = (selectedServer: any, instanceReVal: any) => {
		setInstance(selectedServer);	
		setServerFilter(instanceReVal);
		resetSearchParams();
		setDateRangeMenuOpen(null);
};
	
	useEffect(() => {
		
		if (hasDrillDown) {
			// Tableau drilldown filter changes
			const selectedDateTime = localStorage.getItem('selectedDateTime')
			if (selectedDateTime) {
				setFromDateTime(selectedDateTime)
				setPropsFromDateTime(selectedDateTime);
			}
			const selectedSector = localStorage.getItem('selectedSector')
			if (selectedSector) {
				const selectedSec = JSON.parse(selectedSector).length ? JSON.parse(selectedSector) : ''
				setCheckedList(selectedSec)
				setCurrSectorFilter(selectedSec)
			}
			const selectedServer = localStorage.getItem('selectedServer')
			if (selectedServer) {
				setInstance(selectedServer);
			}
		} else {
			localStorage.removeItem('selectedDateTime')
			localStorage.removeItem('selectedFilter')
			localStorage.removeItem('selectedSector')
			localStorage.removeItem('selectedServer');
		}
		
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		if (userSector === 'all') fetchSectorOptionsData();
		// let storedSectorFilter = localStorage.getItem('dcs-summary-sector-filter')
		// 	? JSON.parse(localStorage.getItem('dcs-summary-sector-filter') ?? '')
		// 	: null;
		// if (storedSectorFilter) {
		// 	setCheckedList(storedSectorFilter);
		// 	setCurrSectorFilter(storedSectorFilter);
		// }
		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/tableau/summary/dashboard') {
			if (activeId) {
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('databricks-jobs');
				document.body.classList.add('datefilter-align-sector');
				document.body.classList.add('sector-label');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				activeId.classList.remove('jobs-list-table');
				activeId.classList.remove('jobs-list');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.remove('jobs-exe-datas');
			}
		}
	}, []);
	console.log(propsFromDateTime, 'propsFromDateSummary')
	let fromDate = moment(propsFromDateTime).format('YYYY-MM-DD');
	console.log(fromDate, 'fromDateSummary')
	return (
		<div className="dashboard-panel job-schedules-wrap">
			<div className="job-exexutions-dateFilter-div">
				{/* [Date Range Menu] */}
				<div className='single-calendar-summary'>
					{/* condition for default Today  */}
					{/* {moment(fromDateTime).format('DD MM YYYY') ===
						moment().startOf('day').subtract(1, 'hours').format('DD MM YYYY') &&
						 <div style={{ marginRight: '10px' }}>Today</div>} */}
						{<LocalizationProvider className="outerDateContainer" dateAdapter={AdapterMoment}>
						<DatePicker
							showToolbar={true}
							components={{
								OpenPickerIcon: DatePickerIcon
							}}
							PopperProps={{ placement: 'auto' }}
						
							className="dateFilterTD"
							value={moment(fromDateTime).tz('America/New_York')}
							onChange={(dateTime) => {
								handleDateTimeChange('fromDateTime', dateTime);
							}}
							renderInput={(params: any) => <TextField name="startDateTime" {...params} />}
						/>
					</LocalizationProvider>
						}
				
				</div>


				{/* Code for sector filter */}
				<Stack>
					{userSector === 'all' ? (
						<FilterIcon onClick={sectorFilterClick} className="sector-filter1" />
					) : (
						<div className="separeat_sector">Sector: {getDecodedToken()?.sector?.name}</div>
					)}
					<Popover
						open={filterMenu !== null}
						onClose={handleClose}
						className="filterContainer"
						anchorReference="anchorPosition"
						anchorPosition={filterMenu !== null ? { top: filterMenu.mouseY, left: filterMenu.mouseX } : undefined}
					>
						<div className="text-filter-row">
							<div className="selectAll-sec">
								<MenuItem className="selectAll">
									<FormControlLabel
										className={classes.checkbox}
										label="All"
										control={
											<Checkbox
												color="success"
												value="all"
												checked={itemsChecked}
												onChange={(e) => {
													selectAllCheck(e);
												}}
											/>
										}
									/>
								</MenuItem>
								<div className="checkbox-sec">
									{sectorOptionsList &&
										sectorOptionsList.map((filter: any) => (
											<MenuItem key={filter}>
												<FormControlLabel
													className={classes.checkbox}
													label={filter === true ? 'Yes' : filter === false ? 'No' : filter}
													control={
														<Checkbox
															color="success"
															value={filter}
															checked={
																(filter === true && checkedList.includes('true')) ||
																(filter === false && checkedList.includes('false')) ||
																checkedList.includes(filter)
															}
															onChange={(e) => {
																handleChange(e);
															}}
														/>
													}
												/>
											</MenuItem>
										))}
								</div>
							</div>
							<div className="buttonSec">
								<Button variant="contained" className={classes.cancelBtn} onClick={handleClose}>
									cancel
								</Button>
								<Button variant="contained" className={classes.okBtn} onClick={handleOkClick}>
									ok
								</Button>
							</div>
						</div>
					</Popover>
				</Stack>
				{/* Code for Server filter */}
				<Stack>
					<div className="server-select">
						<FormControl fullWidth>
							<ServerIcon className="server-icon" />
							<Select
								name="server-select"
								labelId="demo-simple-select-label"
								id="single-server-select"
								value={instance}
								label="Server"
								onChange={(e) => {
									handleServerChange('instance', e);
								}}
								classes={{ select: classes.select }}
								IconComponent={() => null} 
							>
								<div>Servers</div>
								<MenuItem style={instance === 'all' ? {   background: '#676a88', borderRadius: '5px' } : {}} value={'all'}>All Servers</MenuItem>
								<MenuItem style={instance === 'global' ? { background: '#676a88', borderRadius: '5px' } : {}} value={'global'}>Global</MenuItem>
								<MenuItem style={instance === 'cockpit' ? { background: '#676a88', borderRadius: '5px' } : {}} value={'cockpit'}>Cockpit</MenuItem>
							</Select>
						</FormControl>
					</div>
				</Stack>
			</div>
			<Suspense fallback={<div>Loading...</div>}>			
				<Stack>
					<AnalyticsVisualization
						params={{
							dashboardUrl: dashboardUrl.tableausummary,
							className: 'tableauSummaryIframeContainer',
							otherParams: {
								'var-fromDate': fromDate,
								'var-sector': currSectorFilter,
								'var-server': instance
							},
							seamless: true
						}}
					/>
				</Stack>
					<Workbooks fromDateTime={propsFromDateTime} sectorFilter={sector} serverFilter={serverFilter} />
					<TableauUsers fromDateTime={propsFromDateTime}sectorFilter={sector} serverFilter={serverFilter} />
			</Suspense>
		</div>
	);
};

export default TableauSummary;
