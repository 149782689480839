import { Box, CircularProgress } from "@mui/material";

const LoaderComponent = () => (
	<Box
		sx={{
			display: 'inline-block',
			alignItems: 'center',
			alignContent: 'center',
			textAlign: 'center',
			width: '100%',
		}}
		minHeight="40px"
	>
		<CircularProgress size={40} color="info" />
	</Box>
);

export default LoaderComponent;