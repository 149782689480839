import React, { useContext, useEffect, useState, useRef, lazy, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { dashboardUrl } from '../../service/config';
// import { Select, MenuItem, Typography } from '@mui/material';
import './dashboard.scss';
import MetricsDashboard from './metricsDashboard';
import moment from 'moment-timezone';
import { Button, Stack, Select, MenuItem, Grid, Checkbox,  Popover,  ListItemText, FormControlLabel } from '@mui/material';
// import { ReactComponent as ReloadIcon } from '../../assets/reload.svg';
import { ReactComponent as BackwardIcon } from '../../assets/page_backward.svg';
import { ReactComponent as PrevIcon } from '../../assets/page_prev.svg';
import { ReactComponent as NextICon } from '../../assets/page_next.svg';
import { ReactComponent as ForwardIcon } from '../../assets/page_forward.svg';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import isPageNavigationDisabled from './isPageNavigationDisabled';
import { HeaderContext } from '../../Context/HeaderContext';
import Loader from '../Loader/loader';
//import AnalyticsVisualization from '../framework/analyticsVisualization';
import { getReasonChart } from '../../service/service'
import { getDecodedToken, getSector, getSchedulers } from '../../service/service';
import useStyles from '../jobs/jobs_styles';
import { ReactComponent as FilterIcon } from '../../assets/ml_summary_filter.svg';

const AnalyticsVisualization = lazy(() => import('../framework/analyticsVisualization'));

export default function dashboard() {
	const dataFetchedRef = useRef(false);
	const location = useLocation();
	interface CheckedList {
		sectors: string[];
		scheduler: string[];
	}
	// const synced = moment();
	// const [lastSync, setLastSync] = useState(synced.fromNow());
	const [fromDate, setFromDate] = useState(moment().utc().format('YYYY-MM-DD'));
	const [jobsFromDate, setJobsFromDate] = useState(moment().utc().format('YYYY-MM-DD'));
	const [reasonFromDate, setReasonFromDate] = useState(moment().utc().format('YYYY-MM-DD'));
	const [reasonRangeValue, setReasonRangeValue] = useState('daily');
	// const [timeRange, setTimeRange] = useState({ from: 'now-6h', to: 'now' });
	const [rangeValue, setRangeValue] = useState('daily');
	const [jobTimeRange, setJobTimeRange] = useState('daily');
	const [pageLoad, setPageLoad] = useState(false);
	const [disablePaginationReason, setDisablePaginationReason] = useState(true)
	const classes = useStyles();
	const [filterMenu, setFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	const [checkedList, setCheckedList] = useState<CheckedList>({
		sectors: [],
		scheduler: []
	});
    const [selectedSector, setSelectedSector] = useState<any[]>([]);
	const [selectScheduler, setSelectScheduler] = useState<string[]>([]);
	const filterClick = (event: any) => {
		event.preventDefault();
		setFilterMenu(
			filterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 20,
						mouseY: parseInt(event.clientY) - 6
				  }
				: null
		);
	};
	const [selectedFilter, setSelectedFilter] = useState<string>('Sectors');
    const [sectorOptionsList, setSectorOptionsList] = useState<string[]>([]);
	const [schedulerOptionsList, setSchedulerOptionsList] = useState<string[]>([]);
	const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
	const [itemsChecked, setItemsChecked] = useState(false);

	// context
	const { setLastSync } = useContext(HeaderContext);
	const synced = moment();
	const todaysDate = moment().utc().format('YYYY-MM-DD');
	const handleTimeRange = (event: any) => {
		const value = event.target.value;
		setRangeValue(value);
		// if (value === 'daily') setTimeRange({ from: 'now-6h', to: 'now' });
		// else if (value === 'today') setTimeRange({ from: 'now-1d', to: 'now' });
		// else if (value === 'weekly') setTimeRange({ from: 'now-7d', to: 'now' });
		// else if (value === 'monthly') setTimeRange({ from: 'now-1M', to: 'now' });
		// else if (value === 'yearly') setTimeRange({ from: 'now-1y', to: 'now' });
		// console.log('time', timeRange);
	};

	const handleJobTimeRange = (event: any) => {
		const value = event.target.value;
		setJobTimeRange(value);
	};

	const handleReasonTimeRange = async (event: any) => {
		const value = event.target.value;
		setReasonRangeValue(value);
		await fetchClusterFailure(reasonFromDate, value);
	};

	async function fetchClusterFailure(reasonFromDate: string, reasonRangeValue: string) {
		const res = await getReasonChart(reasonFromDate, reasonRangeValue);
		let topRes = res.data.map((i: { topReasonForNotMet: any; })=>i.topReasonForNotMet)
		const allCountsAreZero = topRes.every((subArray: any[]) =>
		subArray.every((obj) => obj.count === 0)
	  );
	  if (allCountsAreZero){
		setDisablePaginationReason(false);
	  } else {
		setDisablePaginationReason(true);
	  }
	}
	useEffect(() => {
		fetchClusterFailure(reasonFromDate, reasonRangeValue)
		const timer = setInterval(() => {
			setLastSync(synced.fromNow());
			setPageLoad(false);
		}, 1000);
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/dashboard/business') {
			if (activeId) {
				// activeId.classList.add('dashboard-business-root');
				setPageLoad(true);
			}
		}
		return function cleanup() {
			if (activeId) {
				document.body.className = '';
				// activeId.classList.remove('dashboard-business-root');
				setPageLoad(false);
			}

			clearInterval(timer);
		};
	}, []);

	const handleDateRange = (event: any) => {
		let targetDate;
		const pageSize = 5;
		if (event === 'prev') {
			if (rangeValue === 'daily') targetDate = moment(fromDate).subtract(1, 'day').format('YYYY-MM-DD');
			else if (rangeValue === 'weekly') targetDate = moment(fromDate).subtract(1, 'week').format('YYYY-MM-DD');
			else if (rangeValue === 'monthly') targetDate = moment(fromDate).subtract(1, 'months').format('YYYY-MM-DD');
			else targetDate = moment(fromDate).subtract(1, 'years').format('YYYY-MM-DD');
			setFromDate(targetDate);
		} else if (event === 'next') {
			if (rangeValue === 'daily') targetDate = moment(fromDate).add(1, 'day').format('YYYY-MM-DD');
			else if (rangeValue === 'weekly') targetDate = moment(fromDate).add(1, 'week').format('YYYY-MM-DD');
			else if (rangeValue === 'monthly') targetDate = moment(fromDate).add(1, 'months').format('YYYY-MM-DD');
			else targetDate = moment(fromDate).add(1, 'years').format('YYYY-MM-DD');
			setFromDate(targetDate);
		} else if (event === 'last') {
			if (rangeValue === 'daily') targetDate = moment(fromDate).add(pageSize, 'day').format('YYYY-MM-DD');
			else if (rangeValue === 'weekly') targetDate = moment(fromDate).add(pageSize, 'week').format('YYYY-MM-DD');
			else if (rangeValue === 'monthly') targetDate = moment(fromDate).add(pageSize, 'months').format('YYYY-MM-DD');
			else targetDate = moment(fromDate).add(pageSize, 'years').format('YYYY-MM-DD');
			setFromDate(targetDate);
		} else if (event === 'first') {
			if (rangeValue === 'daily') targetDate = moment(fromDate).subtract(pageSize, 'day').format('YYYY-MM-DD');
			else if (rangeValue === 'weekly') targetDate = moment(fromDate).subtract(pageSize, 'week').format('YYYY-MM-DD');
			else if (rangeValue === 'monthly')
				targetDate = moment(fromDate).subtract(pageSize, 'months').format('YYYY-MM-DD');
			else targetDate = moment(fromDate).subtract(pageSize, 'years').format('YYYY-MM-DD');
			setFromDate(targetDate);
		} else if (event === 'today') {
			targetDate = moment().utc().format('YYYY-MM-DD');
			setFromDate(targetDate);
		}
	};

	const handleJobsDateRange = (event: any) => {
		let targetDate;
		const pageSize = 5;
		if (event === 'prev') {
			if (jobTimeRange === 'daily') targetDate = moment(jobsFromDate).subtract(1, 'day').format('YYYY-MM-DD');
			else if (jobTimeRange === 'weekly') targetDate = moment(jobsFromDate).subtract(1, 'week').format('YYYY-MM-DD');
			else if (jobTimeRange === 'monthly') targetDate = moment(jobsFromDate).subtract(1, 'months').format('YYYY-MM-DD');
			else targetDate = moment(jobsFromDate).subtract(1, 'years').format('YYYY-MM-DD');
			setJobsFromDate(targetDate);
		} else if (event === 'next') {
			if (jobTimeRange === 'daily') targetDate = moment(jobsFromDate).add(1, 'day').format('YYYY-MM-DD');
			else if (jobTimeRange === 'weekly') targetDate = moment(jobsFromDate).add(1, 'week').format('YYYY-MM-DD');
			else if (jobTimeRange === 'monthly') targetDate = moment(jobsFromDate).add(1, 'months').format('YYYY-MM-DD');
			else targetDate = moment(jobsFromDate).add(1, 'years').format('YYYY-MM-DD');
			setJobsFromDate(targetDate);
		} else if (event === 'last') {
			if (jobTimeRange === 'daily') targetDate = moment(jobsFromDate).add(pageSize, 'day').format('YYYY-MM-DD');
			else if (jobTimeRange === 'weekly') targetDate = moment(jobsFromDate).add(pageSize, 'week').format('YYYY-MM-DD');
			else if (jobTimeRange === 'monthly')
				targetDate = moment(jobsFromDate).add(pageSize, 'months').format('YYYY-MM-DD');
			else targetDate = moment(jobsFromDate).add(pageSize, 'years').format('YYYY-MM-DD');
			setJobsFromDate(targetDate);
		} else if (event === 'first') {
			if (jobTimeRange === 'daily') targetDate = moment(jobsFromDate).subtract(pageSize, 'day').format('YYYY-MM-DD');
			else if (jobTimeRange === 'weekly')
				targetDate = moment(jobsFromDate).subtract(pageSize, 'week').format('YYYY-MM-DD');
			else if (jobTimeRange === 'monthly')
				targetDate = moment(jobsFromDate).subtract(pageSize, 'months').format('YYYY-MM-DD');
			else targetDate = moment(jobsFromDate).subtract(pageSize, 'years').format('YYYY-MM-DD');
			setJobsFromDate(targetDate);
		} else if (event === 'today') {
			targetDate = moment().utc().format('YYYY-MM-DD');
			setJobsFromDate(targetDate);
		}
	};
	const handleReasonDateRange = (event: any) => {
		let targetDate;
		const pageSize = 7;
		if (event === 'prev') {
			if (reasonRangeValue === 'daily') targetDate = moment(reasonFromDate).subtract(1, 'day').format('YYYY-MM-DD');
			else if (reasonRangeValue === 'weekly')
				targetDate = moment(reasonFromDate).subtract(1, 'week').format('YYYY-MM-DD');
			else if (reasonRangeValue === 'monthly')
				targetDate = moment(reasonFromDate).subtract(1, 'months').format('YYYY-MM-DD');
			else targetDate = moment(reasonFromDate).subtract(1, 'years').format('YYYY-MM-DD');
			setReasonFromDate(targetDate);
		} else if (event === 'next') {
			if (reasonRangeValue === 'daily') targetDate = moment(reasonFromDate).add(1, 'day').format('YYYY-MM-DD');
			else if (reasonRangeValue === 'weekly') targetDate = moment(reasonFromDate).add(1, 'week').format('YYYY-MM-DD');
			else if (reasonRangeValue === 'monthly')
				targetDate = moment(reasonFromDate).add(1, 'months').format('YYYY-MM-DD');
			else targetDate = moment(reasonFromDate).add(1, 'years').format('YYYY-MM-DD');
			setReasonFromDate(targetDate);
		} else if (event === 'last') {
			if (reasonRangeValue === 'daily') targetDate = moment(reasonFromDate).add(pageSize, 'day').format('YYYY-MM-DD');
			else if (reasonRangeValue === 'weekly')
				targetDate = moment(reasonFromDate).add(pageSize, 'week').format('YYYY-MM-DD');
			else if (reasonRangeValue === 'monthly')
				targetDate = moment(reasonFromDate).add(pageSize, 'months').format('YYYY-MM-DD');
			else targetDate = moment(reasonFromDate).add(pageSize, 'years').format('YYYY-MM-DD');
			setReasonFromDate(targetDate);
		} else if (event === 'first') {
			if (reasonRangeValue === 'daily')
				targetDate = moment(reasonFromDate).subtract(pageSize, 'day').format('YYYY-MM-DD');
			else if (reasonRangeValue === 'weekly')
				targetDate = moment(reasonFromDate).subtract(pageSize, 'week').format('YYYY-MM-DD');
			else if (reasonRangeValue === 'monthly')
				targetDate = moment(reasonFromDate).subtract(pageSize, 'months').format('YYYY-MM-DD');
			else targetDate = moment(reasonFromDate).subtract(pageSize, 'years').format('YYYY-MM-DD');
			setReasonFromDate(targetDate);
		} else if (event === 'today') {
			targetDate = moment().utc().format('YYYY-MM-DD');
			setReasonFromDate(targetDate);
		}
	};

	const handleOkClick = () => {
		setSelectedSector(checkedList.sectors);
		setSelectScheduler(checkedList.scheduler)
		handleViewClose();
	};

	const handleViewClose = () => {
		setFilterMenu(null);
	};

	const handleSectorChange = (updatedSelection: string[]) => {
		setCheckedList((prevState) => ({
			...prevState,
			sectors: updatedSelection
		}));
	};
	const handleSchedulerChange = (updatedSelection1: string[]) => {
		setCheckedList((prevState) => ({
			...prevState,
			scheduler: updatedSelection1
		}));
	};

	console.log(checkedList, selectScheduler, 'checkedListArray', selectedSector)
	
	const selectAllCheck = (e: any) => {
		const { checked } = e.target;
		 if (selectedFilter === 'Scheduler') {
			setCheckedList((prevState) => ({
				...prevState,
				scheduler: checked ? schedulerOptionsList : []
			}));
		} else {
			setCheckedList((prevState) => ({
				...prevState,
				sectors: checked ? sectorOptionsList : []
			}));
		}
		setItemsChecked(checked);
	};

	const handleMenuItemClick = (filter: string) => {
		setSelectedFilter(filter);
		if (filter === 'Sectors') {
			setItemsChecked(false);
		}
	};
	const handleClose = () => {
		localStorage.removeItem('presto-summary-sector-filter');
		setCheckedList({
			sectors: [],
			scheduler: []
		});
		setItemsChecked(false);
	};

	async function fetchSectorOptionsData() {
		const sectorRes = await getSector('1', '100', '');
		if (sectorRes.success) {
			// console.log(sectorRes.data, 'sectorRes.data')
			const filteredSectorOptions = sectorRes.data.sector.map((x: any) => {
					if (x.name === 'S&T') {
						return 'SNT';
					} else if (x.name === 'Europe') {
						return 'EUROPE';
					} else {
						return x.name;
					}
				})
			filteredSectorOptions.push('Not Mapped')	
			setSectorOptionsList(filteredSectorOptions);
		}
		
	}
	async function fetchSchedulerOptionsData(){
		const schedulerRes = await getSchedulers()
		if (schedulerRes.success){
			setSchedulerOptionsList(
				schedulerRes.data.schedulers
					.map((x: any) => x.name)
			);
		}
	}
	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;

		if (userSector === 'all') {
		fetchSectorOptionsData()
		fetchSchedulerOptionsData()
		}
		let storedSectorFilter = localStorage.getItem('presto-summary-sector-filter')
			? JSON.parse(localStorage.getItem('presto-summary-sector-filter') ?? '')
			: null;
		if (storedSectorFilter) {
			setCheckedList(storedSectorFilter);
			setSelectedSector(storedSectorFilter.sectors);
		}
		const storedSchedulerFilter = localStorage.getItem('presto-summary-scheduler-filter')
		? JSON.parse(localStorage.getItem('presto-summary-scheduler-filter') ?? '')
		: null;

	// Update state variables based on retrieved scheduler filter data
	  if (storedSchedulerFilter) {
		setCheckedList(storedSchedulerFilter);
		setSelectScheduler(storedSchedulerFilter.schedulers);
	  }
	}, []);
   console.log(schedulerOptionsList, "schedulerOptionsList==")

	return (
		<div className="dashboard-panel-teradaschedulerOptionsListta">
			{/* <span className="time-range">
				<Typography className="time-label">Select Time Range:</Typography>
				<Select
					className="time-select"
					size="small"
					label="TimeRange"
					value={rangeValue}
					onChange={handleTimeRange}
				>
					<MenuItem value={'last6hours'}>Last 6 hours</MenuItem>
					<MenuItem value={'today'}>Today</MenuItem>
					<MenuItem value={'teek'}>Week</MenuItem>
					<MenuItem value={'month'}>Month</MenuItem>
					<MenuItem value={'year'}>Year</MenuItem>
				</Select>
			</span> */}
			<div>
				{location.pathname === '/dashboard/business' ? (
					<Stack>
						<div className="datagrowth-filter-wrapper">
							{userSector === 'all' ? (
								<FilterIcon onClick={filterClick} className="sector-filter" />
							) : (
								<div className="sector-filter-text">Sector: {getDecodedToken()?.sector?.name}</div>
							)}

							<Popover
								open={filterMenu !== null}
								onClose={handleViewClose}
								className="filterContainers filter-base"
								anchorReference="anchorPosition"
								anchorPosition={filterMenu !== null ? { top: filterMenu.mouseY, left: filterMenu.mouseX } : undefined}
							>
								<div className="text-filter-rows">
									<div className="filter-header">
										<div className="filters-text"> Filters</div>
										<div className="buttonsSec1">
											<Button variant="contained" className={classes.cancelBtn} onClick={handleClose}>
												CLEAR
											</Button>
											<Button variant="contained" className={classes.okBtn} onClick={handleOkClick}>
												APPLY
											</Button>
										</div>
									</div>
									<div className="dataGrowthselectAll-sec1">
										<div>
											<table>
												<ul className="list-items">
													{['Sectors', 'Scheduler'].map((filter: string) => (
														<ul key={filter}>
															<MenuItem
																className="list-items"
																onClick={() => handleMenuItemClick(filter)}
																style={{ background: filter === selectedFilter ? '#676a88' : '' }}
															>
																<ListItemText className="selected">{filter}</ListItemText>
															</MenuItem>
														</ul>
													))}
												</ul>
											</table>
										</div>
									</div>
									{selectedFilter !== 'Scheduler' ? (
										<ul className="filterContainers MuiPaper-root sector sector-list">
											<MenuItem className="dataGrowthselectAll-sec2">
												<FormControlLabel
													className="checkbox"
													label="All"
													control={
														<Checkbox
															className="data-check"
															color="success"
															value="all"
															checked={itemsChecked}
															onChange={(e) => {
																selectAllCheck(e);
															}}
														/>
													}
												/>
											</MenuItem>
											<ul className="checkbox-sec-datagrowth">
												{sectorOptionsList &&
													sectorOptionsList.map((filter: any) => (
														<MenuItem key={filter} className="all-sector">
															<FormControlLabel
																className={classes.checkbox}
																label={filter}
																control={
																	<Checkbox
																		className="data-check"
																		color="success"
																		value={filter}
																		checked={checkedList.sectors.includes(filter)}
																		onChange={() => {
																			const updatedSelection = checkedList.sectors.includes(filter)
																				? checkedList.sectors.filter((item) => item !== filter)
																				: [...checkedList.sectors, filter];
																			handleSectorChange(updatedSelection);
																		}}
																	/>
																}
															/>
														</MenuItem>
													))}
											</ul>
										</ul>
									) : selectedFilter === 'Scheduler' ? (
										<div className="filterContainers MuiPaper-root env">
											<div className="site-align">
												<MenuItem>
													<FormControlLabel
														className={classes.checkbox}
														label="All"
														control={
															<Checkbox
																className="data-check"
																color="success"
																value="all"
																checked={itemsChecked}
																onChange={(e) => {
																	selectAllCheck(e);
																}}
															/>
														}
													/>
												</MenuItem>
												<ul className="checkbox-sec-datagrowth">
												{schedulerOptionsList &&
													schedulerOptionsList.map((filter: any) => (
														<MenuItem key={filter} className="all-sector">
															<FormControlLabel
																className={classes.checkbox}
																label={filter}
																control={
																	<Checkbox
																		className="data-check"
																		color="success"
																		value={filter}
																		checked={checkedList.scheduler.includes(filter)}
																		onChange={() => {
																			const updatedSelection1 = checkedList.scheduler.includes(filter)
																				? checkedList.scheduler.filter((item) => item !== filter)
																				: [...checkedList.scheduler, filter];
																				handleSchedulerChange(updatedSelection1);
																		}}
																	/>
																}
															/>
														</MenuItem>
													))}
											</ul>
											</div>
										</div>
									) : (
										<div className="separeat_sector"></div>
									)}
								</div>
							</Popover>
						</div>
						<Suspense fallback={<div>Loading...</div>}>
						<AnalyticsVisualization
							params={{
								dashboardUrl: dashboardUrl.executive,
								className: 'executiveIframeContainer',
								otherParams: {
									'var-sector': selectedSector.join(','),
									'var-scheduler': selectScheduler.join(','),
									'var-todaysDate': todaysDate
								},
								seamless: true
							}}
						/>
						</Suspense>
						<Grid item xs={6}>
							<Select
								className="time-select"
								size="small"
								label="TimeRange"
								value={rangeValue}
								onChange={handleTimeRange}
								IconComponent={CalendarMonthIcon}
							>
								<MenuItem value={'daily'}>Daily</MenuItem>
								<MenuItem value={'weekly'}>Weekly</MenuItem>
								<MenuItem value={'monthly'}>Monthly</MenuItem>
								<MenuItem value={'yearly'}>Yearly</MenuItem>
							</Select>
						</Grid>
						<div className="second-iframe-container">
							<div className="sla-iframe-div">
							<Suspense fallback={<div>Loading...</div>}>
								<AnalyticsVisualization
									params={{
										dashboardUrl: dashboardUrl.slaSeries,
										className: 'slaIframeContainer',
										otherParams: {
											'var-sector': selectedSector.join(','),
											'var-scheduler': selectScheduler.join(','),
											'var-forDate': fromDate,
											'var-filter': rangeValue
										},
										seamless: true
									}}
								/>
								</Suspense>
							</div>
							<Grid item xs={6} style={{ float: 'left', position: 'absolute', width: '49%', top: '351px' }}>
								<Stack direction="row" spacing={2} className="paginationStack">
									<Button
										disabled={isPageNavigationDisabled('first', fromDate, rangeValue)}
										onClick={(e) => {
											handleDateRange('first');
										}}
										className="paginationIcon dashboardPageBtn"
									>
										<BackwardIcon />
									</Button>
									<Button
										disabled={isPageNavigationDisabled('prev', fromDate, rangeValue)}
										onClick={(e) => {
											handleDateRange('prev');
										}}
										className="paginationIcon dashboardPageBtn"
									>
										<PrevIcon />
									</Button>
									<Button
										disabled={isPageNavigationDisabled('next', fromDate, rangeValue)}
										onClick={(e) => {
											handleDateRange('next');
										}}
										className="paginationIcon dashboardPageBtn"
									>
										<NextICon />
									</Button>
									<Button
										disabled={isPageNavigationDisabled('last', fromDate, rangeValue)}
										onClick={(e) => {
											handleDateRange('last');
										}}
										className="paginationIcon dashboardPageBtn"
									>
										<ForwardIcon />
									</Button>
									<Button
										disabled={isPageNavigationDisabled('next', fromDate, rangeValue)}
										onClick={(e) => {
											handleDateRange('today');
										}}
										className="paginationIcon dashboardPageBtn"
										style={{ color: '#00D08D' }}
									>
										Today
									</Button>
								</Stack>
							</Grid>
							<Select
								className="time-select1"
								size="small"
								label="TimeRange"
								value={jobTimeRange}
								onChange={handleJobTimeRange}
								IconComponent={CalendarMonthIcon}
							>
								<MenuItem value={'daily'}>Daily</MenuItem>
								<MenuItem value={'weekly'}>Weekly</MenuItem>
								<MenuItem value={'monthly'}>Monthly</MenuItem>
								<MenuItem value={'yearly'}>Yearly</MenuItem>
							</Select>
							<div className="newJob-iframe-div">
							<Suspense fallback={<div>Loading...</div>}>
								<AnalyticsVisualization
									params={{
										dashboardUrl: dashboardUrl.newJobSeries,
										className: 'newJobIframeContainer',
										otherParams: {
											'var-sector': selectedSector.join(','),
											'var-scheduler': selectScheduler.join(','),
											'var-forDate': jobsFromDate,
											'var-filter': jobTimeRange
										},
										seamless: true
									}}
								/>
								</Suspense>
							</div>
							<Grid item xs={6} style={{ float: 'right', position: 'absolute', right: '25px', top: '351px' }}>
								<Stack direction="row" spacing={2} className="paginationStack">
									<Button
										disabled={isPageNavigationDisabled('first', jobsFromDate, jobTimeRange)}
										onClick={(e) => {
											handleJobsDateRange('first');
										}}
										className="paginationIcon dashboardPageBtn"
									>
										<BackwardIcon />
									</Button>
									<Button
										disabled={isPageNavigationDisabled('prev', jobsFromDate, jobTimeRange)}
										onClick={(e) => {
											handleJobsDateRange('prev');
										}}
										className="paginationIcon dashboardPageBtn"
									>
										<PrevIcon />
									</Button>
									<Button
										disabled={isPageNavigationDisabled('next', jobsFromDate, jobTimeRange)}
										onClick={(e) => {
											handleJobsDateRange('next');
										}}
										className="paginationIcon dashboardPageBtn"
									>
										<NextICon />
									</Button>
									<Button
										disabled={isPageNavigationDisabled('last', jobsFromDate, jobTimeRange)}
										onClick={(e) => {
											handleJobsDateRange('last');
										}}
										className="paginationIcon dashboardPageBtn"
									>
										<ForwardIcon />
									</Button>
									<Button
										disabled={isPageNavigationDisabled('next', jobsFromDate, jobTimeRange)}
										onClick={(e) => {
											handleJobsDateRange('today');
										}}
										className="paginationIcon dashboardPageBtn"
										style={{ color: '#00D08D' }}
									>
										Today
									</Button>
								</Stack>
							</Grid>
						</div>
						<MetricsDashboard sectorList={selectedSector.join(',')} schedulerList={selectScheduler.join(',')}/>
						<div>
							<Select
								className="time-select3"
								size="small"
								label="TimeRange"
								value={reasonRangeValue}
								onChange={handleReasonTimeRange}
								IconComponent={CalendarMonthIcon}
							>
								<MenuItem value={'daily'}>Daily</MenuItem>
								<MenuItem value={'weekly'}>Weekly</MenuItem>
								<MenuItem value={'monthly'}>Monthly</MenuItem>
								<MenuItem value={'yearly'}>Yearly</MenuItem>
							</Select>
							<div className="reason-job-execution-iframe-div">
							<Suspense fallback={<div>Loading...</div>}>
								<AnalyticsVisualization
									params={{
										dashboardUrl: dashboardUrl.reasonNotMet,
										className: 'reasonExecutionIframeContainer',
										otherParams: {
											'var-sector': selectedSector.join(','),
											'var-scheduler': selectScheduler.join(','),
											'var-forDate': reasonFromDate,
											'var-filter': reasonRangeValue
										},
										seamless: true
									}}
								/>
								</Suspense>
							</div>
							{disablePaginationReason ? (
								<Grid item xs={6} style={{ float: 'left', position: 'absolute', left: '75%', marginTop: '15px' }}>
									<Stack direction="row" spacing={2} className="paginationStack3">
										<Button
											disabled={isPageNavigationDisabled('first', reasonFromDate, reasonRangeValue)}
											onClick={(e) => {
												handleReasonDateRange('first');
											}}
											className="paginationIcon dashboardPageBtn"
										>
											<BackwardIcon />
										</Button>
										<Button
											disabled={isPageNavigationDisabled('prev', reasonFromDate, reasonRangeValue)}
											onClick={(e) => {
												handleReasonDateRange('prev');
											}}
											className="paginationIcon dashboardPageBtn"
										>
											<PrevIcon />
										</Button>
										<Button
											disabled={isPageNavigationDisabled('next', reasonFromDate, reasonRangeValue)}
											onClick={(e) => {
												handleReasonDateRange('next');
											}}
											className="paginationIcon dashboardPageBtn"
										>
											<NextICon />
										</Button>
										<Button
											disabled={isPageNavigationDisabled('last', reasonFromDate, reasonRangeValue)}
											onClick={(e) => {
												handleReasonDateRange('last');
											}}
											className="paginationIcon dashboardPageBtn"
										>
											<ForwardIcon />
										</Button>
										<Button
											disabled={isPageNavigationDisabled('next', reasonFromDate)}
											onClick={(e) => {
												handleReasonDateRange('today');
											}}
											className="paginationIcon dashboardPageBtn"
										>
											Today
										</Button>
									</Stack>
								</Grid>
							) : (
								' '
							)}
						</div>
					</Stack>
				) : (
					<Stack>
						<Suspense fallback={<div>Loading...</div>}>
						<AnalyticsVisualization
							params={{
								dashboardUrl: dashboardUrl.jobPerformance,
								className: 'iframeContainer',
								otherParams: {
									'var-sector': selectedSector.join(','),
									'var-scheduler': selectScheduler.join(',')
								}
							}}
						/>
						</Suspense>
					</Stack>
				)}
			</div>
			{pageLoad && <Loader />}
		</div>
	);
}
