import type { Column } from 'react-data-grid';
import React, { useEffect, useState, useRef } from 'react';
import { getDBProjectSpent, getDBProjectSpentDate, getDecodedToken, getSector, jobSearchForProjectSpent } from '../../../service/service';
import './CostSummary.scss';
import '../Summary/Summary.scss';
import { CustomizedDataGrid } from '../../customized_data_grid';
import { Grid } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import Loader from '../../Loader/loader';
import { databricksurl } from '../../../service/config';

import axios from 'axios';

const CostSummartProject = ({ fromDateTime, sectorFilter, setFromDateTime, dateSelect }: any) => {
	// project spent Utilization
	interface Row {
		project_name: string;
		workspace_name: string;
		cluster_type: string;
		cluster_name: string;
		total_dbus: string;
		total_dbu_cost: string;
	}

	// project spent Utilization
	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'project_name',
				name: 'Project',
				formatter(props) {
					return <div title={props.row.project_name} className='has-ellipsis'>{props.row.project_name ? props.row.project_name : '--'}</div>
				},
				minWidth: 120,
				width: 250
			},
			{
				key: 'workspace_name',
				name: 'Workspace',
				formatter(props) {
					return <div title={props.row.workspace_name} className='has-ellipsis'>{props.row.workspace_name}</div>
				},
				minWidth: 120,
				width: 250
			},

			{
				key: 'cluster_type',
				name: 'Cluster Type',
				minWidth: 120,
				width: 120,
				formatter(props) {
					return <div title={props.row.cluster_type} className='has-ellipsis'>{props.row.cluster_type ? props.row.cluster_type : '--'}</div>
				},
			},
			{
				key: 'cluster_name',
				name: 'Cluster Name',
				formatter(props) {
					return <div title={props.row.cluster_name} className='has-ellipsis'>{props.row.cluster_name ? props.row.cluster_name : '--'}</div>
				},
				minWidth: 120,
				width: 250
			},

			{
				key: 'total_dbus',
				name: 'Total DBU',
				minWidth: 120
			},
			{
				key: 'total_dbu_cost',
				name: 'Total DBU Cost',
				minWidth: 120
			}
		];
	}

	// project spent utilization
	const defaultColumnNames = ['Project', 'Workspace', 'Cluster Type', 'Cluster Name', 'Total DBU', 'Total DBU Cost'];

	const [projectSpentList, setProjectSpentList] = useState<Row[]>([]);
	const [searchDetails, setFilterSearchInfo] = useState([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [loading, setLoading] = useState(false);
	const [filterInfo, setFilterInfo] = useState([]);
	const [filterData, setFilterData] = useState<Row[]>([]);
	const [error, setError] = useState<string | null>(null);

	// sector filter
	const [sharedValue, setSharedValue] = useState('');
	const updateSharedValue = (newValue: any) => {
		setSharedValue(newValue);
	};
	
	const handlePageChange = (event: any, value: number) => {
		setLoading(true);
		let nextPage = value.toString(); 
		if(event.target.dataset.testid == "LastPageIcon" || event.target.dataset.testid == "FirstPageIcon"){
			nextPage = value.toString(); 
		}
		else if(event.target.dataset.testid == "NavigateBeforeIcon"){
			nextPage = (Number(page) - 1).toString();
		}
		else if (nextPage === event.target.textContent) { 
			nextPage = event.target.textContent;
		} else {
			nextPage = (Number(page) + 1).toString();
		}
		const promise1 = DBWProjectSpentListingFunc(
			parseInt(nextPage),
			pageSize,
			sortingInfo,
			fromDateTime,
			fromDateTime,
			parseFilter(filterInfo),
			sectorFilter
		)
		.catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

	const handlepageSizeChange = (event: any) => {
		setLoading(true);
		const promise1 = DBWProjectSpentListingFunc(
			'1',
			event.target.value,
			sortingInfo,
			fromDateTime,
			fromDateTime,
			parseFilter(filterInfo),
			sectorFilter
		)
		.catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

	const handleSorting = (sortingInfo: any) => {
		setLoading(true);
		const promise1 = DBWProjectSpentListingFunc(
			page,
			pageSize,
			sortingInfo,
			fromDateTime,
			fromDateTime,
			parseFilter(filterInfo),
			sectorFilter
		)
		.catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

	const resetSearchParams = () => {
		if (searchParams.has('fromDate') || searchParams.has('toDate')) {
			searchParams.delete('status');
			searchParams.delete('scheduleDate');
			searchParams.delete('fromDate');
			searchParams.delete('toDate');
			setSearchParams(searchParams);
		}
	};
	const handleFilter = (filterInfo: any) => {
		resetSearchParams();
		setFilterInfo(filterInfo);
		setLoading(true);
		const promise1 = DBWProjectSpentListingFunc(
			1,
			pageSize,
			sortingInfo,
			fromDateTime,
			fromDateTime,
			parseFilter(filterInfo),
			sectorFilter
		)
		.catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

	async function jobSearchForDBSummaryFunc(searchDetails: any, jobFilterInfo: any, searchQuery: any, searchField: any ) {
		const res = await jobSearchForProjectSpent( searchDetails, jobFilterInfo, fromDateTime, fromDateTime, searchField, searchQuery);
		if (res.success) {
			setFilterSearchInfo(res.data);
		}
	}

	const handleSearch = (searchDetails: any, searchField: any, searchQuery:any) => {
		jobSearchForDBSummaryFunc(searchDetails, parseFilter(filterInfo, 'name'), searchQuery, searchField);
	};

	// Initial load API call
	async function DBWProjectSpentListingFunc(
		pageNumber: any,
		size: any,
		sortingInfo: any,
		fromDateTime: any,
		toDateTime: any,
		jobFilterInfo: any,
		sector: any
	) {
		try {
			// Check if there's a stored sector filter in localStorage
			let storedSectorFilter = localStorage.getItem('dcs-summary-sector-filter')
				? JSON.parse(localStorage.getItem('dcs-summary-sector-filter') ?? '')
				: null;
			if (storedSectorFilter) {
				sector = storedSectorFilter;
			}
	
			setLoading(true);
	
			// Make a call to Databricks
			const databricksresponse = await axios.get(`${databricksurl}`);
		
			console.log('Databricks Call initiated', databricksresponse);
	
			if (databricksresponse && databricksresponse.status) {
				// Fetch the project spent data
				const res = await getDBProjectSpent(
					pageNumber.toString(),
					size.toString(),
					sortingInfo,
					jobFilterInfo,
					fromDateTime,
					fromDateTime,
					sector
				);
	
				if (res.success) {
					const addDollar = res.data.result.map((item: any) => ({
						...item,
						total_dbu_cost: formatNumber(item.total_dbu_cost),
						total_dbus: formatNumber(item.total_dbus)
					}));
	
					// Update the state with the formatted data
					setProjectSpentList(addDollar);
					setPaginationData(res.data.page);
					setFilterData(res.data.filterOptions);
					setFilterInfo(jobFilterInfo);
					setPage(pageNumber);
					setPageSize(size);
					setFromDateTime(localStorage.getItem('processedDate'));
					setSortingInfo(sortingInfo);
					setError(null);
				} else {
					setError('Failed to fetch data');
				}
			} else {
				setError('Cluster Inactive');
			}
		} catch (error) {
			setError('Failed to fetch data');
		}
		setLoading(false);
	}
	

		function formatNumber(number:any) {
			if (number != null && !isNaN(number)) {
				return Number(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
			} else {
				return "0.00";
			}
		}


		async function DBWProjectSpentListingDateFunc(
		pageNumber: any,
		size: any,
		sortingInfo: any,
		fromDateTime: any,
		toDateTime: any,
		jobFilterInfo: any,
		sector: any
	) {
		
		let storedSectorFilter = localStorage.getItem('dcs-summary-sector-filter')
		? JSON.parse(localStorage.getItem('dcs-summary-sector-filter') ?? '')
		: null;
		if (storedSectorFilter) {
			sector = storedSectorFilter;
		}
		setLoading(true);
		try {
		const res = await getDBProjectSpentDate(
			pageNumber.toString(),
			size.toString(),
			sortingInfo,
			jobFilterInfo,
			fromDateTime,
			fromDateTime,
			sector
		);
		if (res.success) {
			const addDollar = res.data.result.map((item: any) => ({
			
					...item,
					// total_dbu_cost: `${
					// 	item.total_dbu_cost !== null && item.total_dbu_cost !== undefined
					// 		? `${item.total_dbu_cost.toFixed(2)}`
					// 		: 0.00
					// }`,
					// total_dbus: `${
					// 	item.total_dbus !== null && item.total_dbus !== undefined ? `${item.total_dbus.toFixed(2)}` : 0.00
					// }`
					total_dbu_cost: formatNumber(item.total_dbu_cost),
					total_dbus: formatNumber(item.total_dbus)
				
			}));
			setProjectSpentList(addDollar);
			setPaginationData(res.data.page);
			setFilterData(res.data.filterOptions);
			setFilterInfo(jobFilterInfo);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
		}else {
			setError('Failed to fetch data'); // Set the error state if API call fails
		}
	}
	 catch (error) {
		setError('Failed to fetch data'); // Set the error state if API call fails
	}
			setLoading(false);
		}
	

	useEffect(() => {
		setError(null);
		DBWProjectSpentListingFunc(page, pageSize, sortingInfo, fromDateTime, fromDateTime, [],
			sectorFilter).catch(
			(error) => {
				setError(error); // Set error state when API call fails during initial load
			}
		);
	}, [sectorFilter]);

	useEffect(() => {
		setError(null);
		if (dateSelect != 0) {
			DBWProjectSpentListingDateFunc(
				1,
				pageSize,
				sortingInfo,
				fromDateTime,
				fromDateTime,
				[],
				sectorFilter
			);
		}
	}, [dateSelect]);

	return (
		<div className="spent-div cost-sum-table-wrap">
			<p className="databricks_title">Project Spent Utilization</p>
			{error == 'Cluster Inactive' ? (
					// Display error message or handle error condition in UI
					<div
						style={{
							color: '#e5e5e5',
							marginLeft: '25%',
							padding: '10px',
							fontWeight: 500,
							gridColumn: '1 / -1'
						}}
					>
						Cluster is Inactive, it will take Max 2mins to start, Please try again later
					</div>
				) :error? (
					// Display error message or handle error condition in UI
					<div
						style={{
							color: '#e5e5e5',
							marginLeft: '25%',
							padding: '10px',
							fontWeight: 500,
							gridColumn: '1 / -1'
						}}
					>
						Error occurred while fetching data. Please try again.
					</div>
				) : (
			<Grid className="costSummary_tabledata">
				{!loading ? (<>
					<CustomizedDataGrid
						tableColumns={getColumns()}
						tableData={projectSpentList}
						tableType="DBProject"
						defaultColumnNames={defaultColumnNames}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						filterDataOptions={filterData}
						onFilterCheck={handleFilter}
						currFilter={filterInfo}
						searchDataValues={searchDetails}
						onFilterSearch={handleSearch}
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={projectSpentList.length}
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isDateRangeFilter={false}
						isDisabled={false}
					/>
					{projectSpentList?.length ? <p className="disclaimer disclaimer3">Note: Double hyphens (--) indicate that the field has no data.</p> : ''}
					</>
				) : (
					<Loader />
				)}
			</Grid>
				)}
		</div>
	);
};

export default CostSummartProject;
