import PrivateRoute from 'utils/privateRoute';
import DatabricksSummary from 'components/DatabricksObs/Summary/Summary';
import DatabricksResourceOptimization from 'components/DatabricksObs/ResourceOpt/ResourceOptimization';
import DatabrickJobs from 'components/DatabricksObs/Jobs/DatabrickJobs';
import DatabricksCostSummary from 'components/DatabricksObs/CostSummary/CostSummary';
import DatabricksGovernance from 'components/DatabricksObs/Governance/Governance';
import DatabricksStaleUser from 'components/DatabricksObs/Governance/StaleUsers';
import DatabricksInfrastructure from 'components/DatabricksObs/Infrastructure';
import DatabricksObsRules from 'components/DatabricksObs/DatabricksObsRules/databricksObsRules';

const DatabricksObsRoute = [
	{
		key: 'dbric_obs',
		route: '/databricks/observation/',
		component: <PrivateRoute Component={DatabricksObsRules} restrictedRoles={[]} />
	},
	{
		key: 'dbric_summ_dash',
		route: '/databricks/summary/dashboard',
		component: (
			<PrivateRoute
				Component={DatabricksSummary}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'dbric_res_dash',
		route: '/databricks/resource/dashboard',
		component: (
			<PrivateRoute
				Component={DatabricksResourceOptimization}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'dbric_jobs_dash',
		route: '/databricks/jobs/dashboard',
		component: (
			<PrivateRoute
				Component={DatabrickJobs}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'dbric_cost_dash',
		route: '/databricks/costSummary/dashboard',
		component: (
			<PrivateRoute
				Component={DatabricksCostSummary}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'dbric_gov_dash',
		route: '/databricks/governance/dashboard',
		component: (
			<PrivateRoute
				Component={DatabricksGovernance}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'dbric_gov_stale',
		route: '/databricks/governance/staleusers',
		component: (
			<PrivateRoute
				Component={DatabricksStaleUser}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'dbric_infra_dash',
		route: '/databricks/infrastructure/dashboard',
		component: (
			<PrivateRoute
				Component={DatabricksInfrastructure}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	}
];

export default DatabricksObsRoute;
