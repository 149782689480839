import {
	Box,
	Grid,
	FormControl,
	FormControlLabel,
	Select,
	InputLabel,
	MenuItem,
	Checkbox,
	InputAdornment
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import moment from 'moment-timezone';
import '../jobs/editJob.css';
import { useState, useEffect, useRef } from 'react';
import { getProcess, getSystem, getSectorSql, updateJob } from '../../service/service';

export function EditJob({ jobData, handleEditClose }: any) {
	const dataFetchedRef = useRef(false);
	const [processList, setProcessList] = useState<any[]>([]);
	const [systemList, setSystemList] = useState<any[]>([]);
	const [sectorList, setSectorList] = useState<any[]>([]);
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState({
		jobId: jobData.id,
		systemId: '',
		sectorId: '',
		processId: '',
		friendlyName: jobData.friendlyName,
		// description: jobData.description,
		estimatedDuration: jobData.estimatedDuration,
		executionFrequency: jobData.executionFrequency,
		criticalFlag: jobData.criticalFlag,
		slaEndTime: moment(jobData.slaEndTime, 'HH:mm')
	});
	const [formErrors, setFormErrors] = useState({
		estimatedDurationError: ''
	});

	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		fetchOptionsData();
	});

	async function fetchOptionsData() {
		setLoading(true);
		const processRes = await getProcess('1', '100', '');
		const systemRes = await getSystem('1', '100', '');
		const sectorRes = await getSectorSql('1', '100', '');

		let currentProcess: any = '';
		let currentSystem: any = '';
		let currentSector: any = '';
		if (processRes.success) {
			if (jobData.process) {
				currentProcess = processRes.data.process.filter((process: any) => process.name === jobData.process);
			}
			setProcessList(processRes.data.process);
		}
		if (systemRes.success) {
			if (jobData.system) {
				currentSystem = systemRes.data.system.filter((system: any) => system.name === jobData.system);
			}
			setSystemList(systemRes.data.system);
		}
		if (sectorRes.success) {
			if (jobData.sector) {
				currentSector = sectorRes.data.sector.filter((sector: any) => sector.name === jobData.sector);
			}
			setSectorList(sectorRes.data.sector);
		}
		setLoading(false);
		setFormData({
			...formData,
			systemId: currentSystem ? currentSystem[0].id : '',
			sectorId: currentSector ? currentSector[0].id : '',
			processId: currentProcess ? currentProcess[0].id : ''
		});
	}

	const onSubmit = (event: any) => {
		event.preventDefault();
		updateJobData(formData);
	};

	async function updateJobData(jobData: any) {
		const res = await updateJob({
			...jobData,
			slaEndTime: jobData.slaEndTime.format('HH:mm')
		});
		if (res.success) {
			handleClose();
				}
	}
	const handleBlur = (event: any) => {
		if (event.target.id === 'estimatedDuration' && !event.target.checkValidity()) {
			setFormErrors({ estimatedDurationError: 'Please use the format: 1H 30M' });
		}
	};

	const handleChange = (event: any) => {
		setFormErrors({ estimatedDurationError: '' });
		const { name, value } =
			event && event.target
				? event.target.type === 'checkbox'
					? { name: event.target.name, value: event.target.checked }
					: event.target
				: { name: 'slaEndTime', value: moment(event) };

		setFormData({
			...formData,
			[name]: value
		});
	};

	const handleClose = () => {
		handleEditClose();
	};

	const isSubmitDisabled = (): boolean => {
		const pattern = /^([0-9]+([.][0-9]+)?[WwDdHhMm])([ ][0-9]+([.][0-9]+)?[WwDdHhMm])*$/;
		if (formData.estimatedDuration && !pattern.test(formData.estimatedDuration)) return true;
		if (!formData.criticalFlag && formData.slaEndTime.toISOString() === null) return false;
		if (formData.slaEndTime && !formData.slaEndTime.isValid()) return true;

		if (!formData.criticalFlag) return false;
		if (
			formData.processId &&
			formData.systemId &&
			formData.sectorId &&
			formData.estimatedDuration &&
			pattern.test(formData.estimatedDuration) &&
			formData.slaEndTime &&
			formData.slaEndTime.isValid()
		)
			return false;
		return true;
	};

	return (
		<>
			<DialogTitle>
				<Box display="flex" alignItems="center">
					<Box flexGrow={1}>Update Job Metadata</Box>
					<Box>
						<IconButton onClick={handleClose} color="primary">
							<CloseIcon />
						</IconButton>
					</Box>
				</Box>
			</DialogTitle>
			{!loading ? (
				<form onSubmit={onSubmit} noValidate className="editJobForm" id="editJobForm">
					<DialogContent>
						<Grid container item xs={12}>
							<Grid item xs={4}>
								<div className="attrTitle">Job Name</div>
								<div className="editJobName">{jobData.name}</div>
							</Grid>
							<Grid item xs={3}>
								<div className="attrTitle">Observed Since</div>
								<div>{jobData.createdAt && moment(jobData.createdAt).format()}</div>
							</Grid>
							<Grid item xs={2}>
								<div className="attrTitle">Scheduler</div>
								<div>{jobData.schedulerName}</div>
							</Grid>
							<Grid item xs={3}>
								<div className="attrTitle">Machine</div>
								<div>{jobData.machine}</div>
							</Grid>
						</Grid>
						<br />
						<Grid container item xs={12}>
							<Grid item xs={6}>
								<FormControlLabel
									label="Critical Job"
									className="criticalFlagCheck"
									control={
										<Checkbox
											checked={formData.criticalFlag}
											onChange={handleChange}
											name="criticalFlag"
											sx={{
												color: '#00D08D',
												'&.Mui-checked': {
													color: '#00D08D'
												},
												'&.MuiSvgIcon-root': {
													color: '#00D08D'
												},
												'&.Mui-disabled': {
													color: 'rgba(0, 0, 0, 0.80)'
												}
											}}
										/>
									}
								/>
							</Grid>
						</Grid>
						<Grid container item xs={12}>
							<Grid item xs={6}>
								<FormControl sx={{ m: 1, width: '95%' }}>
									<TextField
										id="alias"
										label="Job Alias"
										name="friendlyName"
										variant="outlined"
										onChange={handleChange}
										value={formData.friendlyName}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<FormControl sx={{ m: 1, width: '95%' }}>
									<InputLabel>{`Sector${formData.criticalFlag ? ' *' : ''}`}</InputLabel>
									<Select
										id="sector"
										name="sectorId"
										value={formData.sectorId}
										onChange={handleChange}
										autoWidth
										required={formData.criticalFlag}
										label={`Sector${formData.criticalFlag ? ' *' : ''}`}
									>
										{[{ id: null, name: '' }, ...sectorList].map((sector) => (
											<MenuItem key={sector.id} value={sector.id}>
												{sector.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
						<Grid container item xs={12}>
							<Grid item xs={6}>
								<FormControl sx={{ m: 1, width: '95%' }} error={true}>
									<InputLabel>{`System${formData.criticalFlag ? ' *' : ''}`}</InputLabel>
									<Select
										id="system"
										name="systemId"
										value={formData.systemId}
										onChange={handleChange}
										autoWidth
										required={formData.criticalFlag}
										label={`System${formData.criticalFlag ? ' *' : ''}`}
									>
										{[{ id: null, name: '' }, ...systemList].map((system) => (
											<MenuItem key={system.id} value={system.id}>
												{system.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<FormControl sx={{ m: 1, width: '95%' }}>
									<InputLabel>{`Process${formData.criticalFlag ? ' *' : ''}`}</InputLabel>
									<Select
										id="process"
										name="processId"
										value={formData.processId}
										onChange={handleChange}
										autoWidth
										required={formData.criticalFlag}
										label={`Process${formData.criticalFlag ? ' *' : ''}`}
									>
										{[{ id: null, name: '' }, ...processList].map((process) => (
											<MenuItem key={process.id} value={process.id}>
												{process.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
						<Grid container item xs={12}>
							<Grid item xs={6}>
								<FormControl sx={{ m: 1, width: '95%' }}>
									<TextField
										id="estimatedDuration"
										label="Estimated Duration"
										name="estimatedDuration"
										variant="outlined"
										InputProps={{
											endAdornment: <InputAdornment position="end">Ex. 1H 30M (H= Hours M= Minutes)</InputAdornment>
										}}
										onChange={handleChange}
										onBlur={handleBlur}
										error={formErrors.estimatedDurationError.length > 0}
										value={formData.estimatedDuration}
										helperText={formErrors.estimatedDurationError}
										required={formData.criticalFlag}
										inputProps={{ pattern: '^([0-9]+([.][0-9]+)?[WwDdHhMm])([ ][0-9]+([.][0-9]+)?[WwDdHhMm])*$' }}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<LocalizationProvider dateAdapter={AdapterMoment}>
									<FormControl sx={{ m: 1, width: '95%' }} className="EditSLATimePicker">
											<TimePicker
												label="Normal Completion Time"
												orientation="landscape"
												showToolbar={true}
												toolbarTitle={
													<>
														Select Time{' '}
														<div className="time-picker-zone">{`Timezone: ${
															moment().isDST() ? 'EDT(UTC-4)' : 'EST(UTC-5)'
														}`}</div>
													</>
												}
												className="slaEndTimePicker"
												ampm={false}
												value={formData.slaEndTime}
												onChange={handleChange}
												renderInput={(params: any) => (
													<TextField {...params} name="slaEndTime" required={formData.criticalFlag} />
												)}
											/>
									</FormControl>
								</LocalizationProvider>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						{formData.criticalFlag && (
							<p className="editFormHelperText">* These fields are mandatory for critical jobs</p>
						)}
						<Button onClick={handleClose} variant="outlined" color="error">
							Cancel
						</Button>
						<Button type="submit" variant="contained" color="success" disabled={isSubmitDisabled()}>
							Save
						</Button>
					</DialogActions>
				</form>
			) : (
				<Box sx={{ padding: '40px 20px', textAlign: 'center', fontSize: '14px', backgroundColor: '#0c0f25' }}>
					Fetching Data...
				</Box>
			)}
		</>
	);
}
