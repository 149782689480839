import { Button, Input, MenuItem, Select, Stack } from "@mui/material"
import { useState } from "react"
import { dashboardUrl } from "../../service/config"
import AnalyticsVisualization from "./analyticsVisualization"

const timeRanges = [
    {label: 'Last 15 minutes', valueFrom: 'now-15m', valueTo: 'now'},
    {label: 'Last 30 minutes', valueFrom: 'now-30m', valueTo: 'now'},
    {label: 'Last 1 hour', valueFrom: 'now-1h', valueTo: 'now'},
    {label: 'Last 3 hours', valueFrom: 'now-3h', valueTo: 'now'},
    {label: 'Last 6 hours', valueFrom: 'now-6h', valueTo: 'now'},
    {label: 'Last 12 hours', valueFrom: 'now-12h', valueTo: 'now'},
    {label: 'Last 24 hours', valueFrom: 'now-24h', valueTo: 'now'},
    {label: 'Last 2 days', valueFrom: 'now-2d', valueTo: 'now'},
    {label: 'Last 7 days', valueFrom: 'now-7d', valueTo: 'now'},
    {label: 'Last 30 days', valueFrom: 'now-30d', valueTo: 'now'},
    {label: 'Last 90 days', valueFrom: 'now-90d', valueTo: 'now'},
    {label: 'Last 6 months', valueFrom: 'now-6M', valueTo: 'now'},
    {label: 'Last 1 year', valueFrom: 'now-1y', valueTo: 'now'},
    {label: 'Last 2 years', valueFrom: 'now-2y', valueTo: 'now'},
    {label: 'Last 5 years', valueFrom: 'now-5y', valueTo: 'now'}
]
const kioskOptions = [
    {label: 'ON', value: 1},
    {label: 'OFF', value: 0},
    {label: 'TV', value: 'tv'},
]

export function GrafanaDashboardIframe(){
    const [iframeSrc, setIframeSrc] = useState('https://grafana.dev.mypepsico.com/d/de097821-4ba3-414a-a7d7-265bb66f283d/tableaugovernance-dev?orgId=1&from=1726203212426&to=1726289612426&refresh=10s')
    const [timeRange, setTimeRange] = useState<any>(timeRanges[0])
    const [kiosk, setKiosk] = useState<any>(kioskOptions[0])
    const [inputSrc, setInputSrc] = useState('')
    
    const iframeUrl = timeRange ? `${iframeSrc}&from=${timeRange.valueFrom}&to=${timeRange.valueTo}&kiosk=${kiosk.value}` : iframeSrc
    console.log(iframeUrl)
    return (
        <Stack sx={{mt: 8}} justifyContent='flex-end'>
            <Stack direction={"row"} gap={4} sx={{alignSelf: 'flex-end'}}>
                <Input sx={{width: 1000}} placeholder="Enter iframe url" value={inputSrc} onChange={(e) => setInputSrc(e.target.value)}/>
                <Button color="secondary" variant="outlined" onClick={() => setIframeSrc(inputSrc)}>Set Iframe</Button>
                <Select color="secondary" size="medium"
                    label='Select kiosk'
                    value={kiosk.label}
                    onChange={(e: any) => {
                        setKiosk(kioskOptions.find(option => option.label === e.target.value))
                    }}
                >
                    {kioskOptions.map(range => <MenuItem key={range.label} value={range.label}>{range.label}</MenuItem>)}
                </Select>
                <Select color="secondary" size="medium"
                    label='Select time range'
                    value={timeRange.label}
                    onChange={(e: any) => {
                        console.log(e.target.value)
                        setTimeRange(timeRanges.find(range => range.label === e.target.value))
                    }}
                >
                    {timeRanges.map(range => <MenuItem key={range.label} value={range.label}>{range.label}</MenuItem>)}
                </Select>
            </Stack>
            <iframe height={window.innerHeight} src={iframeUrl}/>
        </Stack>
    )
}

export function GrafanaDashboardAnalytics(){

    return (
        <AnalyticsVisualization
            params={{
                dashboardUrl: 'https://grafana.dev.mypepsico.com/d/de097821-4ba3-414a-a7d7-265bb66f283d/tableaugovernance-dev?orgId=1&from=1726203212426&to=1726289612426&refresh=10s',
                className: 'powerBiDataGrowthCostContainer',
                otherParams: {},
                seamless: true
            }}
        />
    )
}