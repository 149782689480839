import { useEffect, useState } from 'react';
import {Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Popover, Select, Stack, Tooltip} from '@mui/material';
import '../TableauObs/Summary/Summary.scss';
import moment from 'moment';
import { getDecodedToken, fetchFinOpsExecutiveSummary } from '../../service/service';
import useStyles from '../jobs/jobs_styles';
import '../FineOps/finops.scss';
import ReactECharts from 'echarts-for-react';
import ArrowUpBlackIcon  from '../../assets/up-arrow-black.svg';
import '../DataEstate/data-estate-dashboard.scss';
import ArrowIcon from '../../assets/Arrow 253.svg';
import ArrowIconRed from '../../assets/growth arrow down.svg';
import { getPercentageOfTwoNumbers } from '../../utils/common-util';
import { ReactComponent as FilterIcon } from '../../assets/ml_summary_filter.svg';
import ToolTipIcon from "../../assets/info-icon.svg";
import { useNavigate } from 'react-router-dom';
import { ECElementEvent } from 'echarts';

const ExecutiveSummary = () => {	
	
	const classes = useStyles();
	const [itemsChecked, setItemsChecked] = useState(false);
	const [sntChecked, setSnTChecked] = useState(true);
	const [sector, setCurrSector] = useState('');

	const [programList, setProgramList] = useState<any[]>([]);

	const [programName, setProgramName] = useState('all');

	const [CloudCostYTD, SetCloudCostYTD] = useState('-');
	const [CloudCostMTD, SetCloudCostMTD] = useState('-');
	const [CostChange, SetCostChange] = useState('-');
	const [costGrowth, SetCostGrowth] = useState('-');

	const [grafanaCloudCostTrendLoading, setGrafanaCloudCostTrendLoading] = useState(true);
    const [grafanaCloudCostTrendOptions, setGrafanaCloudCostTrendOptions] = useState<any>();
	const [grafanaProgramByCostByEnvironmentLoading, setProgramByCostByEnvironmentLoading] = useState(true);
	const [grafanaProgramByCostByEnvironmentOptions, setProgramByCostByEnvironmentOptions] = useState<any>();
	const [grafanaDATeamsLoading, setGrafanaDATeamsLoading] = useState(true);
    const [grafanaDATeamsOptions, setGrafanaDATeamsOptions] = useState<any>();
	const [grafanaServicesByCostLoading, setGrafanaServicesByCostLoading] = useState(true);
    const [grafanaServicesByCostOptions, setGrafanaServicesByCostOptions] = useState<any>();

	const [cloudCostTrendBubbleValue, SetCloudCostTrendBubbleValue] = useState(0);
	const [cloudCostTrendLastDec, SetCloudCostTrendLastDec] = useState(0);
	const [cloudCostTrendCurrent, SetCloudCostTrendCurrent] = useState(0);
	const [cloudCostTrendStartMonth, setCloudCostTrendStartMonth] = useState('');
	const [isNoAnnualCloudCostTrend, setIsNoAnnualCloudCostTrend] = useState(false);

	const [ytdUnit, setYtdUnit] = useState('');
	const [mtdUnit, setMtdUnit] = useState('');

	const navigate = useNavigate();

	// sector filter
	const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
	const [filterMenu, setFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	
	const sectorFilterClick = (event: any) => {
		event.preventDefault();
		setFilterMenu(
			filterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 20,
						mouseY: parseInt(event.clientY) - 6
				  }
				: null
		);
	};

	const handleSectorClose = (event: any) => {
		setFilterMenu(null);
	};

	const selectAllSectorCheck = (e: any) => {
		const { checked } = e.target;
		if (checked) setSnTChecked(true);
		else setSnTChecked(false);
		setItemsChecked(checked);
	};

	const handleSectorChange = (e: any) => {
		const { value, checked } = e.target;
		if (checked) {
			setSnTChecked(true);
			setItemsChecked(true);
		} else {
			setSnTChecked(false);
			setItemsChecked(false);
		}
	};
	const handleSectorOkClick = (event: any) => {
		if (sntChecked) {
			setCurrSector('SnT');
		} else {
			setCurrSector('');
		}
		handleSectorClose(event);
	};

	const handleProgramChange = (e: any) => {
		const value = e.target.value;
		setProgramName(value);
		getExecutiveDashboardDetails(value);
	}
	
	const handleNavigateTeamDetails = (e: any) => {
		if (programName == 'EDF') 
			return

		if (programName != 'all') {
			navigate('/finops/details?team='+e.name+'&programName='+programName);
		} else {
			navigate('/finops/details?team='+e.name);
		}
	}
	
	const handleNavigateServiceDetails = (e: any) => {
		if (programName != 'all') {
			navigate('/finops/details?consumedService='+e.name+'&programName='+programName);
		} else {
			navigate('/finops/details?consumedService='+e.name);
		}
	}

	const handleNavigateDetails = (param: any) => {	
		const envName = param.seriesName;
		let environment = 'QA'
		if(envName == 'DEV')
			environment = 'Development';
		else if(envName == 'PROD')
			environment = 'Production';
		else if(envName == 'PREPROD')
			environment = 'Pre Production';
		else if(envName == 'SANDBOX')
			environment = 'Sandbox';
		if(envName == 'DEV' || envName == 'QA' || envName == 'PROD' || envName == 'PREPROD' || envName == 'SANDBOX')
			navigate('/finops/details?programName='+ param.name + '&environmentName=' + environment);
		else
			navigate('/finops/details?programName='+ param.name);
	}

	const handleDefault = () => {
		let path = '';
		if(programName != 'all')
			path = '/finops/details?programName='+ programName;
		else
			path = '/finops/details';
		navigate(path);
	}

	const handleNavigatePrev = (isPrev: any) => {
		const months = getPreviousMonths(isPrev ? 1 : 2);
		let path = '';
		if(programName != 'all')
			path = '/finops/details?programName='+ programName + '&month='+ months;
		else
			path = '/finops/details?month='+ months;
		navigate(path);
	}

	function formatNumber(number:any) {
		if (number != null && !isNaN(number)) {
			return Number(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		} else {
			return "0.00";
		}
	}

	function formatNumberM(number:any) {
		if (number != null && !isNaN(number) && number !== 0) {
			return Number(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		} else {
			return "0";
		}
	}

	function formatCommaNumber(number:any) {
		if (number != null && !isNaN(number)) {
			return Number(number).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
		} else {
			return "0";
		}
	}

	const getPreviousMonths = (numMonths: number) => {
		const currentDate = new Date();
		const monthNames = [
		  "January", "February", "March", "April", "May", "June", 
		  "July", "August", "September", "October", "November", "December"
		];
		const months = [];
		for (let i = 1; i <= numMonths; i++) {
			let date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
			if(numMonths == 1)
				date = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
			const month = date.getMonth();
			const year = date.getFullYear();
		//   months.push(`${monthNames[month]} ${year}`);
			months.push(monthNames[month]);
		}
		return months;
	};
	  
	async function getExecutiveDashboardDetails(prgName: any) {
		SetCloudCostYTD('-');
		SetCloudCostMTD('-');
		SetCostChange('-');
		SetCostGrowth('-');

		const selectedProgram = {
			Program: [prgName]
		};

		fetchFinOpsExecutiveSummary('program')
		.then((res) => {
			setProgramList([...res.data.result].sort()); 
		})
		.catch((error) => {
			console.error(error);
		});

		fetchFinOpsExecutiveSummary('ytd', selectedProgram)
		.then((res) => {
			let count = res?.data?.result[0]?.KPI_VALUE ?? 0;
			if (count >= 1000000) {
				SetCloudCostYTD((count/1000000).toString());
				setYtdUnit('MM');
			} else {
				SetCloudCostYTD((count / 1000).toString());
				setYtdUnit('K');
			}
		})
		.catch((error) => {
			console.error(error);
		});
		fetchFinOpsExecutiveSummary('mtd', selectedProgram)
		.then((res) => {
			let count = res?.data?.result[0]?.KPI_VALUE ?? 0;
			if (count >= 1000000) {
				SetCloudCostMTD((count/1000000).toString());
				setMtdUnit('MM');
			} else {
				SetCloudCostMTD((count / 1000).toString());
				setMtdUnit('K');
			}
		})
		.catch((error) => {
			console.error(error);
		});
		fetchFinOpsExecutiveSummary('mom', selectedProgram)
		.then((res) => {
			SetCostGrowth('');
			if(res.data.result) {
				let countCG = res?.data?.result[0]?.KPI_VALUE ?? 0;
				SetCostGrowth(countCG.toString());
			}
		})
		.catch((error) => {
			console.error(error);
		});
		fetchFinOpsExecutiveSummary('lmcg', selectedProgram)
		.then((res) => {
			SetCostChange('');
			if(res.data.result) {
				let count = res?.data?.result[0]?.KPI_VALUE ?? 0;
				SetCostChange(count.toString());
			}
		})
		.catch((error) => {
			console.error(error);
		});

		fetchFinOpsExecutiveSummary('trend', selectedProgram)
		.then((res) => {
			if(res.data) {
				let allDataAsArray = Object.entries(res.data.result);
				const allMonths = Object.keys(res.data.result);

				const firstMonthFromAllMonths = allMonths[0];
				const lastMonth = moment().subtract(1, 'months').format('MM');
				const indexValue = moment().year() + '-' + lastMonth;
				const lastIndex = allMonths.indexOf(indexValue);
				const lastMonthFromAllMonths = allMonths[lastIndex];
				const lastMonthFromForecast = allMonths[allMonths.length - 1];

				let currentMonth = new Date().getMonth();
				if (currentMonth == 0)
					currentMonth = 12
				const currentYear = new Date().getFullYear();
				const lastMonthString = (currentMonth).toString().padStart(2, '0');
				const filterMonth = `${(currentYear-1)}-${lastMonthString}`
				const filterDateEntry = Object.entries(res.data.result).filter(
					x => x[0] === filterMonth
				);
				if (filterDateEntry.length) {
					let count: any = 0;
					count = filterDateEntry[0][1];
					const lastMonthDataIndex = `${currentYear}-${lastMonthString}`;
					const lastMonthEntry = Object.entries(res.data.result).filter(
						x => x[0] === lastMonthDataIndex
					);
					if (lastMonthEntry.length){
						setIsNoAnnualCloudCostTrend(false);	
						const lastmonthvalue = lastMonthEntry[0][1] as number;
						SetCloudCostTrendCurrent(lastmonthvalue as number)
						SetCloudCostTrendLastDec(count)
						SetCloudCostTrendBubbleValue(getPercentageOfTwoNumbers(count, lastmonthvalue));
					}
				}
				else
				{
					const annualMonth = moment(firstMonthFromAllMonths, 'YYYY-MM').format('MMMM YYYY');
					setCloudCostTrendStartMonth('Data available from ' + annualMonth);
					setIsNoAnnualCloudCostTrend(true);	
				}

				const option = {
					title: {
						show: Object.keys(allDataAsArray).length == 0 ? true : false,
						textStyle:{
						color:'#bcbcbc',
						fontWeight: 'normal',
						fontSize: 16
						},
						text: 'No data for the selected filters',
						left: 'center',
						top: 'center'
					},
					backgroundColor: '#053367',
					grid: {
						top: '11%',
						left: '12%',
						right: '2%',
						bottom: '12%'
					},
					xAxis: {
						type: 'category',
						splitLine: { 
						show: true, 
						lineStyle: { 
							type: 'dashed',
							color: '#004477'
						}
					},
					data: allMonths,
						axisLabel: {
							rotate: 30,
							color: '#A7ADCB',
							formatter: function (value: any) {
								return `${moment(value).format('MMM-YY')}`;
							}
						},
					},
					yAxis: {
						type: 'value',
						splitLine: { 
							show: true, 
							lineStyle: { 
								type: 'dashed',
								color: '#004477'
							}
						},
						axisLabel: {
							color: '#A7ADCB',
							formatter: function (value: any) {
								return `$${formatNumberM((value/1000000))}MM`;
							}
						},
					},			
					visualMap: {
						show: false,
						dimension: 0,
						pieces: [
							{
								lte: lastIndex,
								color: '#009BD8'
							},
							{
								gt: lastIndex,
								lte: allMonths.length - 1,
								color: '#FD783B'
							}
						]
					},
					series: [
						{
						name: 'Current Growth',
						type: 'line',
						data: allDataAsArray,
						markArea: {
							itemStyle: {
							color: 'rgba(255, 173, 177, 0.1)',
							textStyle: {
								fontFamily: "Montserrat",
							}
							},
							data: [
							[
								{
								name: 'Current Growth',
								xAxis: firstMonthFromAllMonths,
								},
								{
								xAxis: lastMonthFromAllMonths
								}
							],
							[
								{
								name: 'Projection',
								xAxis: moment().format('YYYY-MM')
								},
								{
								xAxis: lastMonthFromForecast
								}
							]
							]
						}
						}
					],
					tooltip: {
						trigger: 'axis',
						formatter: (params: any) => {
							const { name, seriesName, value } = params[0]; 
							return `${moment(value[0]).format('MMM-YY')}: $${formatNumber(value[1] / 1000000)}MM`;
						},
						backgroundColor: '#003771', 
						textStyle: {
							color: '#A7ADCB' 
						}
					},
					textStyle: {
						fontFamily: "Montserrat" 
					}
				};
					setGrafanaCloudCostTrendOptions(option);
					setGrafanaCloudCostTrendLoading(false);
			}
		})
		.catch((error) => {
			console.error(error);
		});
			
		fetchFinOpsExecutiveSummary('programcost', selectedProgram)
		.then((res) => {
			let data = res.data.result;

			// Extract unique environment names from the data
			const envNames = Object.keys(data[0]).filter(key => key !== 'programname' && key !== 'total_cost');

			// Create a series for the environment data
			const envSeriesData = envNames.map((env, index) => ({
				name: env.toUpperCase(),
				type: 'bar',
				stack: 'total',
				showBackground: true,
				backgroundStyle: {
					color: '#174377'
				},
				label: {
					show: index === envNames.length - 1, 
					position: 'right',
					color: '#F5F5F5',
					clip: false,
					formatter: (params: any) => {
						const costIdx = data.length - 1 - params.dataIndex;
						const totalCost = data[costIdx].total_cost;
						let formattedValue;
						if (totalCost >= 1000000) {
							formattedValue = `${formatNumber(totalCost / 1000000)}MM`;
						} else {
							formattedValue = `${formatNumber(totalCost / 1000)}K`;
						}
						return `$${formattedValue}`;
					}
				},
				data: data.map((item: any) => item[env]).reverse()
			}));

			const totalCostSeries = {
				name: 'TOTAL_COST',  // Use a recognizable name
				type: 'bar',
				stack: 'total',
				label: {
					show: true,
					position: 'right',
					color: '#F5F5F5',
					formatter: (params: any) => {
						const costIdx = data.length - 1 - params.dataIndex;
						const totalCost = data[costIdx].total_cost;
						let formattedValue;
						if (totalCost >= 1000000) {
							formattedValue = `${formatNumber(totalCost / 1000000)}MM`;
						} else {
							formattedValue = `${formatNumber(totalCost / 1000)}K`;
						}
						return `$${formattedValue}`;
					}
				},
				// Make the bar invisible but clickable
				itemStyle: {
					color: 'transparent' // Invisible bar
				},
				emphasis: {
					itemStyle: {
						color: 'transparent'
					}
				},
				data: data.map((item: any) => 0) // No actual bar, just a label
			};

			const optionBar = {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					},
					backgroundColor: '#003771',
					textStyle: {
						color: '#A9ADCB',
					},
					formatter: (params: any) => {
						let tooltipText = `${params[0].name}<br/>`;
						params.forEach((param: any) => {
							if(param.seriesName !== 'TOTAL_COST') {
								let value = param.value;
								if (value >= 1000000) {
									value = `${formatNumber(value / 1000000)}MM`;
								} else {
									value = `${formatNumber(value / 1000)}K`;
								}
								tooltipText += `${param.marker} ${param.seriesName}: $${value}<br/>`;
							}
						});

						// Add total_cost to the tooltip
						const costIdx = data.length - 1 - params[0].dataIndex;
						const totalCost = data[costIdx].total_cost;
						let formattedTotalCost;
						if (totalCost >= 1000000) {
							formattedTotalCost = `${formatNumber(totalCost / 1000000)}MM`;
						} else {
							formattedTotalCost = `${formatNumber(totalCost / 1000)}K`;
						}
						tooltipText += `Total Cost: $${formattedTotalCost}`;

						return tooltipText;
					}
				},
				legend: {
					data: envNames.map(env => env.toUpperCase()),
					textStyle: { color: '#A7ADCB' },
					bottom: '0%',
					left: '0%',
				},
				grid: {
					left: '1%',
					right: '10%',
					bottom: '10%',
					containLabel: true
				},
				xAxis: {
					type: 'value',
					show: false,
					axisLabel: {
						color: '#A7ADCB',
						formatter: (value: number) => {
							if (value >= 1000000) {
								return `$${formatCommaNumber(value / 1000000)}MM`;
							} else {
								return `$${formatCommaNumber(value / 1000)}K`;
							}
						}
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: 'dashed',
							color: '#004477'
						}
					}
				},
				yAxis: {
					type: 'category',
					data: data.map((item: any) => item.programname).reverse(),
					axisLabel: { color: '#A7ADCB' }
				},
				series: [...envSeriesData, totalCostSeries],
				textStyle: {
					fontFamily: "Montserrat"
				}
			};

			setProgramByCostByEnvironmentOptions(optionBar);
			setProgramByCostByEnvironmentLoading(false);
		})
		.catch((error) => {
			console.error(error);
		});

		fetchFinOpsExecutiveSummary('servicecost', selectedProgram)
		.then((res) => {
			let data = res.data.result;
			// Transform data into ECharts series format
			let seriesData = data.map((item: any) => ({
				name: item.consumedservice,
				value: item.total_cost,
				// formattedName: `${item.percentage ? item.percentage : 0}% ($${item.total_cost ? item.total_cost.toLocaleString() : 0})`
				formattedName: `${item.percentage ? item.percentage : 0}%`
			}));
			const others = seriesData.filter((item: { name: string; }) => item.name === 'Others');
			seriesData = seriesData.filter((item: { name: string; }) => item.name !== 'Others');
			seriesData = seriesData.concat(others);

			const optionBar = {
				tooltip: {
					backgroundColor: '#003771', 
					textStyle: {
						color: '#A9ADCB', 
					},
					formatter: function (params: any) {
						let value = params.value;
						let formattedValue;
						if (value >= 1000000) {
							formattedValue = `${formatNumber(value / 1000000)}MM`;
						} else {
							formattedValue = `${formatNumber(value / 1000)}K`; 
						}
						return `${params.name} : ${params.percent}%  ($${formattedValue})`;
					},
					position: 'inside'
				},
				legend: {
					bottom: 60,
					left: 'center',
					data: seriesData.map((item: any) => item.name),
					textStyle: {
						color: '#A7ADCB',
						fontSize: 12
					}
				},
				series: [
					{
						type: 'pie',
						radius: '55%',
						center: ['50%', '50%'],
						selectedMode: 'single',
						label: {
							show: true,
							position: 'inside',
							textStyle: {
								align: 'center',
								verticalAlign: 'middle',
							},
							formatter: function (params: any) {
								return `${params.percent.toFixed(2)}%`;
							},
							rich: {
								a: {
									color: '#E5E5E5',
									fontSize: 12,
									lineHeight: 20
								},
								b: {
									color: '#E5E5E5',
									fontSize: 12
								}
							},
							color: '#F5F5F5'
						},
						data: seriesData.map((item: any) => ({
							name: item.name,
							value: item.value,
							label: {
								formatter: function (params:any) {
									if (params.percent < 8) {
										return '';
									}
									return `${item.formattedName}`
								}
							}
						})),
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}
				],
				textStyle: {
					fontFamily: "Montserrat" 
				}
			};

			setGrafanaServicesByCostOptions(optionBar);
			setGrafanaServicesByCostLoading(false);
		})
		.catch((error) => {
			console.error(error);
		});

		fetchFinOpsExecutiveSummary('teamcost', selectedProgram)
		.then((res) => {
			let data = res.data.result;
			// Transform data into ECharts series format
			let seriesData = data.map((item: any) => ({
				name: item.team,
				value: item.total_cost,
				// formattedName: `${item.percentage}% ($${item.total_cost.toLocaleString()})`
				// formattedName: `${item.percentage ? Math.round(item.percentage) : 0}%` // without decimal point
				formattedName: `${item.percentage ? item.percentage : 0}%`
			})).sort((a: any, b: any) => a.name.localeCompare(b.name));

			const optionBar = {
				tooltip: {
					backgroundColor: '#003771', 
					textStyle: {
						color: '#A9ADCB', 
					},
					formatter: function (params: any) {
						let value = params.value;
						let formattedValue;
						if (value >= 1000000) {
							formattedValue = `${formatNumber(value / 1000000)}MM`;
						} else {
							formattedValue = `${formatNumber(value / 1000)}K`; 
						}
						return `${params.name} : ${params.percent}%  ($${formattedValue})`;
					},
					position: 'inside'
				},
				legend: {
					bottom: '40',
					left: 'center',
					data: seriesData.map((item: any) => item.name),
					textStyle: {
						color: '#A7ADCB',
						fontSize: 12
					}
				},
				series: [
					{
						type: 'pie',
						radius: '55%',
						center: ['50%', '50%'],
						selectedMode: 'single',
						label: {
							show: true,
							position: 'inside',
							textStyle: {
								align: 'center',
								verticalAlign: 'middle',
							},
							formatter: function (params: any) {
								// let value = params.value.toLocaleString(); 
								// return `{a|${value}}\n{b|(${params.percent.toFixed(2)}%)}`;
								return `${params.percent.toFixed(2)}%`;
							},
							rich: {
								a: {
									color: '#E5E5E5',
									fontSize: 12,
									lineHeight: 20
								},
								b: {
									color: '#E5E5E5',
									fontSize: 12
								}
							},
							color: '#F5F5F5'
						},
						data: seriesData.map((item: any) => ({
							name: item.name,
							value: item.value,
							label: {
								formatter: function (params:any) {
									if (params.percent < 8) {
										return '';
									}
									return `${item.formattedName}`
								}
							}
						})),
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}
				],
				textStyle: {
					fontFamily: "Montserrat" 
				}
			};

			setGrafanaDATeamsOptions(optionBar);
			setGrafanaDATeamsLoading(false);
		})
		.catch((error) => {
			console.error(error);
		});
	}
	
	
	useEffect(() => {
		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/finops/executive-dashboard') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('datefilter-align-sector');
				document.body.classList.add('sector-label');
				document.body.classList.add('program-arrow');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				document.body.classList.add('dashboard-panel-dataestate');
				document.body.classList.add('datefilter-align-sector');
			}
		}
		getExecutiveDashboardDetails(programName);
	}, []);
	return (
		<div className="dashboard-panel-dataestate job-schedules-wrap">
			<div className="page-table jobs-tabledata page-table-ibp-monitor">
				<div className="ibp-dashboard-wrapper">
					{/* Code for Byte filter */}
					<div className="program-filter">
						<Stack>
							<div className="program-select">
								<FormControl fullWidth>
									<Select
										name="byte-select"
										labelId="single-program-select-label"
										id="single-program-select"
										value={programName}
										label="Program"
										onChange={handleProgramChange}
									>
										<MenuItem value="all">{'All Programs'}</MenuItem>
										{programList.map((program, index) => (
											<MenuItem key={program} value={program}>{program}</MenuItem>
										))}
									</Select>
								</FormControl>
							</div>
						</Stack>
					</div>
					<div className="service-sector-filter">
					{/* Code for sector filter */}
						<Stack className="sector-filter-dataestate">
						<span  className='sector-cta'><span className='sector-label'>SnT</span> <FilterIcon /></span>
							{/* {userSector === 'all' ? (
								<><span className='sector-cta' onClick={sectorFilterClick}><span className='sector-label'>All Sectors</span> <FilterIcon /></span></>
							) : (
								<div className="separeat_sector">Sector: {getDecodedToken()?.sector?.name}</div>
							)} */}
							{/* <Popover
								open={filterMenu !== null}
								onClose={handleSectorClose}
								className="filterContainer"
								anchorReference="anchorPosition"
								anchorPosition={filterMenu !== null ? { top: filterMenu.mouseY, left: filterMenu.mouseX } : undefined}
							> */}
								{/* <div className="text-filter-row">
									<div className="selectAll-sec">
										<MenuItem className="selectAll">
											<FormControlLabel
												className={classes.checkbox}
												label="All"
												control={
													<Checkbox
														color="success"
														value="all"
														checked={itemsChecked}
														onChange={(e) => {
															selectAllSectorCheck(e);
														}}
													/>
												}
											/>
										</MenuItem>
										<div className="checkbox-sec">
											<MenuItem>
												<FormControlLabel
													className={classes.checkbox}
													label={'SnT'}
													control={
														<Checkbox
															color="success"
															value={sntChecked}
															checked={
																(sntChecked === true)
															}
															onChange={(e) => {
																handleSectorChange(e);
															}}
														/>
													}
												/>
											</MenuItem>
										</div>
									</div>
									<div className="buttonSec">
										<Button variant="contained" className={classes.cancelBtn} onClick={handleSectorClose}>
											cancel
										</Button>
										<Button variant="contained" className={classes.okBtn} onClick={handleSectorOkClick}>
											ok
										</Button>
									</div>
								</div> */}
							{/* </Popover> */}
						</Stack>
					</div>
					<div className="panel-legend m-legend">
						<h2 className="panel-legend--title">Cost Summary</h2>
						<Grid container spacing={0} className="inner-grid-parent">
							<Grid item xs={6} className="headerIPB-title subheaderIPB-title no-ml">
								<Grid container spacing={1}>
									<Grid item xs={6} className="dataestate-title-fn bgcolor-blue cost-finOps has-cursor"
									onClick={handleDefault}>
										<div className="monitor-head-exe">Azure Cloud Cost (YTD)</div>
										<div className="dataestate-subheader">
											{CloudCostYTD == '-' ? (
												<span className="loading">Loading...</span>
											) : (
												<>
													${formatNumber(CloudCostYTD)} <span className="subheader-text">{ytdUnit}</span>
												</>
											)}
										</div>
									</Grid>
									<Grid item xs={6} className="dataestate-title-fn bgcolor-blue cost-finOps has-cursor"
									onClick={() => handleNavigatePrev(true)}>
										<div className="monitor-head-exe">Azure Cloud Cost (MTD)</div>
										<div className="dataestate-subheader">
											{CloudCostMTD == '-' ? (
												<span className="loading">Loading...</span>
											) : (
												<>
													${formatNumber(CloudCostMTD)} <span className="subheader-text">{mtdUnit}</span>
												</>
											)}
										</div>
									</Grid>
								</Grid>

								<Grid container spacing={1}>
									<Grid item xs={6} className="dataestate-title-fn bgcolor-blue cost-finOps has-cursor"
									onClick={handleDefault}>
										<div className="monitor-head-exe">Average Cost Growth (MoM)</div>
										<div className={`dataestate-subheader ${parseFloat(costGrowth) < 0 ? 'text--red' : 'text--green'}`}>
											{costGrowth === '-' ? (
												<span className="loading">Loading...</span>
											) : (costGrowth ? (
												<>
													{formatNumber(costGrowth)}
													<span className="subheader-text">%</span>
													<img src={parseFloat(costGrowth) < 0 ? ArrowIconRed : ArrowIcon} className="arrow-icon-styles" />
												</>
											) : <span className='no-data'>No Data</span>)}
										</div>
									</Grid>
									<Grid item xs={6} className="dataestate-title-fn bgcolor-blue cost-finOps has-cursor"
									onClick={() => handleNavigatePrev(false)}>
										<div className="monitor-head-exe">Cost Change Compared to Last Month</div>
										<div className={`dataestate-subheader ${parseFloat(CostChange) < 0 ? 'text--red' : 'text--green'}`}>
											{CostChange === '-' ? (
												<span className="loading">Loading...</span>
											) : (CostChange ? (
												<>
													{formatNumber(CostChange)}
													<span className="subheader-text">%</span>
													<img src={parseFloat(CostChange) < 0 ? ArrowIconRed : ArrowIcon} className="arrow-icon-styles" />
												</>
											) : <span className='no-data'>No Data</span>)}
										</div>
									</Grid>
								</Grid>
							</Grid>
							{/* right side */}

							<Grid item xs={6} className="headerIPB-title subheaderIPB-title no-ml">
								<Grid container spacing={1}>
									<Grid item xs={12} className="dataestate-title chart-widget-finop bgcolor-blue">
										<div className="monitor-head-exe">Azure Cloud Cost Trend (MoM)
											{(isNoAnnualCloudCostTrend) && <span className="badge bg-no">
												<Tooltip title={isNoAnnualCloudCostTrend ? cloudCostTrendStartMonth : 'Insufficient Data'} arrow>
													<img src={ToolTipIcon} />
												</Tooltip>
											</span>}
											{!grafanaCloudCostTrendLoading && !isNoAnnualCloudCostTrend && (
												<span className="badge" title={`Last Year: ${cloudCostTrendLastDec} - Previous Month: ${cloudCostTrendCurrent}`}>
													{Math.sign(cloudCostTrendBubbleValue) == -1 && (
														<>
															<img
																src={ArrowUpBlackIcon}
																className="arrow-icon-styles arrow-icon-styles-up-black down-arrow"
															/>{' '}
															{Math.abs(Math.round(cloudCostTrendBubbleValue))}% /Yr
														</>
													)}
													{Math.sign(cloudCostTrendBubbleValue) != -1 && (
														<>
															<img src={ArrowUpBlackIcon} className="arrow-icon-styles arrow-icon-styles-up-black" />{' '}
															{Math.abs(Math.round(cloudCostTrendBubbleValue))}% /Yr
														</>
													)}
												</span>
											)}
										</div>
										{grafanaCloudCostTrendLoading ? (
											'Loading...'
										) : (
											<ReactECharts
												option={grafanaCloudCostTrendOptions}
												onEvents={{
													click: handleDefault, 
												}}
												theme={{
													backgroundColor: '#1b1e35'
												}}
											/>
										)}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</div>

					{/* second part */}
					<div className="panel-legend m-legend">
						<h2 className="panel-legend--title mt-10 ">Cost Breakdown</h2>
						<Grid container spacing={0} className="inner-grid-parent minh-430">
							<Grid item xs={6} className="headerIPB-title subheaderIPB-title no-ml">
								<Grid container spacing={1}>
									<Grid item xs={12} className="dataestate-title-fn chart-widget-finop bgcolor-blue env-echart">
										<div className="monitor-head-exe exec-head">
											{programName == 'all' ? 'Top 10 Programs by Cost (YTD) by Environment' : 'Cost by Environment' }
											</div>
										{grafanaProgramByCostByEnvironmentLoading ? (
											'Loading...'
										) : (
											<ReactECharts
												option={grafanaProgramByCostByEnvironmentOptions}
												onEvents={{
													click: (params: ECElementEvent) => {
														handleNavigateDetails(params);
													  },
												}}
												theme={
													{
														// backgroundColor: '#1b1e35'
													}
												}
											/>
										)}
									</Grid>
								</Grid>
							</Grid>
							{/* right side */}

							<Grid item xs={6} className="headerIPB-title subheaderIPB-title no-ml">
								<Grid container spacing={1}>
										<Grid item xs={12} className="dataestate-title-fn chart-widget-finop bgcolor-blue service-echart">
											<div className="monitor-head-exe">Top Services by Cost (YTD)</div>
											{
											grafanaServicesByCostLoading ? 'Loading...' : <ReactECharts
												option={grafanaServicesByCostOptions}
												onEvents={{
													click: handleNavigateServiceDetails, 
												}}
												theme={{
													// backgroundColor: '#1b1e35'
												}}
											/>}
										</Grid>
								<Grid item xs={12} className="dataestate-title-fn chart-widget-finop bgcolor-blue teams-echart">
									<div className="monitor-head-exe mb--20 head-ytd">
									{programName == 'EDF' ? 'Cost % Distribution Across Sectors (YTD)' : 'Cost % Distribution Across Teams (YTD)' }
									</div>
									{
									grafanaDATeamsLoading ? 'Loading...' : <ReactECharts
										option={grafanaDATeamsOptions}
										onEvents={{
											click: handleNavigateTeamDetails, 
										}}
										theme={{
											// backgroundColor: '#1b1e35'
										}}
									/>}
								</Grid>
								</Grid>
							</Grid>
						</Grid>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ExecutiveSummary;
