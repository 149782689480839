import { Typography, Table, TableBody, TableRow, TableCell, Paper } from '@mui/material';
import moment from 'moment';

const MoreClassficationDetails = ({ classificationPerformance, targetDrift }: any) => {
	return (
		<Paper elevation={8} className="width-65">
			<Typography variant="h6" component="h6">
				More Details
			</Typography>
			<Table className="performance-overview-table">
				<TableBody>
					<TableRow>
						<TableCell className="attrTitle">Accuracy</TableCell>
						<TableCell className="attrValue">{classificationPerformance?.Accuracy?.toFixed(6)}</TableCell> 
						<TableCell className="emptyCell"></TableCell>
						<TableCell className="attrTitle">F1 Score</TableCell>
						<TableCell className="attrValue">
							{classificationPerformance && classificationPerformance['F1-Score']?.toFixed(6)}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell className="attrTitle">Precision</TableCell>
						<TableCell className="attrValue">{classificationPerformance?.Precision?.toFixed(6)}</TableCell> 
						<TableCell className="emptyCell"></TableCell>
						<TableCell className="attrTitle">Classes in Inference</TableCell>
						<TableCell className="attrValue">{targetDrift?.current_distribution?.x?.length}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell className="attrTitle">Recall</TableCell>
						<TableCell className="attrValue">{classificationPerformance?.Recall?.toFixed(6)}</TableCell> 
						<TableCell className="emptyCell"></TableCell>
						<TableCell className="attrTitle">Classes in Reference</TableCell>
						<TableCell className="attrValue">{targetDrift?.reference_distribution?.x?.length}</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</Paper>
	);
};

export default MoreClassficationDetails;
