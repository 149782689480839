import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import './interceptor';
import reportWebVitals from './reportWebVitals';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import theme from 'assets/theme';
import { BrowserRouter } from 'react-router-dom';
import PepwiseAppContextProviders from 'Context';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<ProSidebarProvider>
			<StyledEngineProvider injectFirst>
				<PepwiseAppContextProviders>
					<ThemeProvider theme={theme}>
						<BrowserRouter>
							<App />
						</BrowserRouter>
					</ThemeProvider>
				</PepwiseAppContextProviders>
			</StyledEngineProvider>
		</ProSidebarProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
