function convertSeconds(totalSeconds: any) {
	if (totalSeconds === 0) return '0m';
	const totalMinutes = totalSeconds / 60;
	const seconds = totalSeconds % 60;
	const hours = Math.floor(totalMinutes / 60);
	const minutes = Math.floor(totalMinutes % 60);
	const hoursStr = hours ? `${hours}h ` : '';
	const minutesStr = minutes ? `${minutes}m ` : '';
	const secondsStr = seconds ? `${seconds}s` : '';

	return `${hoursStr}${minutesStr}${secondsStr}`;
}

export default convertSeconds;
