import React, { useEffect, useRef, useState, lazy, Suspense } from 'react';
import {
	Button,
	Menu,
	Stack,
	TextField,
	FormControlLabel,
	Select,
	InputLabel,
	FormControl,
} from '@mui/material';
// import AnalyticsVisualization from '../../framework/analyticsVisualization';
import { dashboardUrl } from '../../../service/config';
import './Governance.scss';
// import TableauDetailView from './DetailsView';
// import TableauGovernanceUsers from './Users';
// import TableauGovernanceOrphaned from './Orphaned';
import { useSearchParams } from 'react-router-dom';
import useStyles from '../../jobs/jobs_styles';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import moment from 'moment';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ReactComponent as DatePickerIcon } from '../../../assets/calendar-outline_white.svg';
import { ReactComponent as FilterIcon } from '../../../assets/ml_summary_filter.svg';
import { getCostSummaryChart, getDecodedToken, getSector } from '../../../service/service';
import { ReactComponent as ServerIcon } from '../../../assets/solar_server-outline.svg';
// import SpaceUtilizedByUnused from './SpaceUtilizedByUnused';

// Lazy load the AnalyticsVisualization component
const AnalyticsVisualization = lazy(() => import('../../framework/analyticsVisualization'));
const TableauDetailView = lazy(() => import('./DetailsView'));
const TableauGovernanceUsers = lazy(() => import('./Users'));
const TableauGovernanceOrphaned = lazy(() => import('./Orphaned'));
const SpaceUtilizedByUnused = lazy(() => import('./SpaceUtilizedByUnused'));


const Governance = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [usedWorkBook, setUsedWorkBook] = useState('30Days');
	const [usedExtracts, setUsedExtracts] = useState('30Days');
	const [usedViews, setUsedViews] = useState('30Days');
	const [unUsedExtracts, setUnUsedExtracts] = useState('');
	const [drillDownStatus, setDrillDownStatus] = useState<string | null>(searchParams.get('drillDownStatus') || 'all_used_workbooks');
	const [unusedExtractdrillDownStatus, setunusedExtractDrillDownStatus] = useState<string | null>(searchParams.get('drillDownStatus') || 'most_unUsed_extracts');
	const [drillDownFilter, setDrillDownFilter] = useState<string | null>(searchParams.get('drillDownFilter') || '30Days');
	const [totalOrphanType, setTotalOrphan] = useState<string | null>(searchParams.get('totalOrphanType') || null);
	const hasDrillDown = searchParams.has('isDrillDowned');
	const hasTotalSpaceUtilized = searchParams.has('isSpaceUtilized');
	const hasTotalOrphan = searchParams.has('isTotalOrphan');
	const dataFetchedRef = useRef(false);
	
	const [shouldScroll, setShouldScroll] = useState(false);

	const setupParamsForDaysFilterDrillDown = (days: string, status: string) => {
		setDrillDownFilter(days);
		setDrillDownStatus(status);
		setunusedExtractDrillDownStatus(status);
		resetSearchParams();
		if(hasTotalOrphan){
			searchParams.delete('isTotalOrphan');
			searchParams.delete('totalOrphanType');	
			setSearchParams(searchParams);

			
		}
		if(hasTotalSpaceUtilized){
			searchParams.delete('isSpaceUtilized');
			searchParams.delete('drillDownStatus');	
			setSearchParams(searchParams);

			
		}
	}
	const handleusedWorkBook = (days: string) => {
		setShouldScroll(true);
		setUsedWorkBook(days);
		setupParamsForDaysFilterDrillDown(days, "all_used_workbooks");
	};
	const handleusedExtracts = (days: string) => {
		setShouldScroll(true);
		setUsedExtracts(days);
		setupParamsForDaysFilterDrillDown(days, "all_used_extracts");
	};
	const handleusedViews = (days: string) => {
		setShouldScroll(true);
		setUsedViews(days);
		setupParamsForDaysFilterDrillDown(days, "all_used_views");
	};

	// const handleUnUsedExtracts = (days: string) => {
	// 	localStorage.setItem('selectedFilter', days);		
	// 	setUnUsedExtracts(days);
	// 	setupParamsForDaysFilterDrillDown(days, "most_unUsed_extracts");
	// 	searchParams.set('filter', days)
	// };

	const handleUnUsedExtracts = (days: string) => {
		setDrillDownFilter(days);
		localStorage.setItem('selectedFilter', days);
		setUnUsedExtracts(days);
		// resetSearchParams();
		setSearchParams(new URLSearchParams({
			...searchParams,
			isSpaceUtilized: 'true',
			drillDownStatus: 'most_unused_extracts', 
			filter: days,
		}))
	};

	const minJobExecDate = '2023-03-01';
	const dateFrom: string = searchParams.get('fromDate')?.substring(0, 10) as string;
	const startDt = moment(dateFrom).startOf('day').subtract(1, 'hours').format('YYYY-MM-DD');
	const [fromDateTime, setFromDateTime] = useState<any>(
		searchParams.has('fromDate') ? startDt : moment().startOf('day').subtract(1, 'hours')
	);

	
	const [propsFromDateTime, setPropsFromDateTime] = useState<any>(
		searchParams.has('fromDate') ? startDt : moment().startOf('day').subtract(1, 'hours')
	);
	
		// sector filter
		const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
		const [filterMenu, setFilterMenu] = useState<{
			mouseX: number;
			mouseY: number;
		} | null>(null);
		const classes = useStyles();
		const [itemsChecked, setItemsChecked] = useState(false);
		const [sectorOptionsList, setSectorOptionsList] = useState<any[]>([]);
		const [checkedList, setCheckedList] = useState<any[]>([]);
		const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);
		const [sector, setCurrSector] = useState<any[]>([]);
	//server filter
		const [instance, setInstance] = useState<any>('all');

	// [Date Range Menu]
	const [dateRangeMenuOpen, setDateRangeMenuOpen] = React.useState<null | HTMLElement>(null);

	const handleDateClick = (event: React.MouseEvent<HTMLElement>) => {
		setDateRangeMenuOpen(event.currentTarget);
	};

	const handleDateRangeMenu = () => {
		setDateRangeMenuOpen(null);
	};

	const open = Boolean(dateRangeMenuOpen);

	const handleDateTimeChange = (field: any, dateTime: any) => {
		localStorage.setItem('selectedDateTime', dateTime);// Tableau drilldown filter changes
		if (field === 'fromDateTime') setFromDateTime(dateTime);
		// else setToDateTime(dateTime);
		console.log(fromDateTime, 'dateTime test');

		applyDateTimeRange(dateTime);
	};

	const resetSearchParams = () => {
		// // searchParams.delete('isDrillDowned');
		// searchParams.delete('drillDownStatus');
		// searchParams.delete('drillDownFilter');
		// searchParams.delete('isTotalOrphan');
		// searchParams.delete('totalOrphanType');
		if (searchParams.has('fromDate')) {
			searchParams.delete('status');
			searchParams.delete('scheduleDate');
			searchParams.delete('fromDate');
		}
		setSearchParams(searchParams);
	};
	const applyDateTimeRange = (dateTime: any) => {
		setPropsFromDateTime(dateTime);
		resetSearchParams();
		setDateRangeMenuOpen(null);
	};
	const sectorFilterClick = (event: any) => {
		setShouldScroll(false);
		event.preventDefault();
		setFilterMenu(
			filterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 20,
						mouseY: parseInt(event.clientY) - 6
				  }
				: null
		);
	};

	const handleClose = (event: any) => {
		setFilterMenu(null);
	};

	const selectAllCheck = (e: any) => {
		const { checked } = e.target;
		const collection: any[] = [];
		if (checked) setCheckedList(sectorOptionsList);
		else setCheckedList([]);
		setItemsChecked(checked);
	};
	const handleChange = (e: any) => {
		const { value, checked } = e.target;
		const newlyChecked = [];
		if (checked && e.target.value) {
			newlyChecked.push(e.target.value);
		}
		if (checked) {
			setCheckedList([...checkedList, ...newlyChecked]);
			if(sectorOptionsList.length === (checkedList.length + newlyChecked.length)){
				setItemsChecked(true);
			}
		} else {
			setCheckedList(checkedList.filter((item) => item !== value));
			setItemsChecked(false);
		}
	};
	const handleOkClick = (event: any) => {
		localStorage.setItem('selectedSector', JSON.stringify(checkedList)); // Tableau drilldown filter changes
		if (checkedList.length > 0) {
			setCurrSectorFilter(checkedList);
		} else {
			setCurrSectorFilter([]);
		}
		setCurrSector( checkedList );
		// DBWorkspaceSpentListingFunc(page, pageSize, sortingInfo, fromDateTime, toDateTime, parseFilter(filterInfo), checkedList);
		// updateSharedValue({ sectorFilter: checkedList });
		// localStorage.setItem('dcs-summary-sector-filter', JSON.stringify(checkedList));
		handleClose(event);
		resetSearchParams();
	};

	async function fetchSectorOptionsData() {
		const sectorRes = await getSector('1', '100', '');
		if (sectorRes.success) {
			const filteredSectorOptions = sectorRes.data.sector.filter((x: any) => x.name !== 'S&T').map((x: any) => x.name);
			filteredSectorOptions.push('Blank');
        	setSectorOptionsList(filteredSectorOptions);
		}
	}
	// Server filter code
	const handleServerChange = (field: any, selectedServer: any) => {
		// Tableau server filter changes
		localStorage.setItem('selectedServer', selectedServer.target.value);
		if (field === 'instance') setInstance(selectedServer.target.value);
		applyServerFilter(selectedServer.target.value);
	};
	const applyServerFilter = (selectedServer: any) => {
		setInstance(selectedServer);
		resetSearchParams();	
		
	};
	useEffect(() => {
			const drillDownStatus = hasDrillDown ? searchParams.get('drillDownStatus') : 'all_used_workbooks';
			const days: any = hasDrillDown ? searchParams.get('drillDownFilter') : '30Days';
			if (hasDrillDown) {
				if (drillDownStatus?.includes('workbooks')) {
					setUsedWorkBook(days);
				} else if (drillDownStatus?.includes('extracts')) {
					setUsedExtracts(days);
				} else if (drillDownStatus?.includes('views')){
					setUsedViews(days);					
				} 
				console.log(drillDownStatus, days, typeof days)
			}
			if (dataFetchedRef.current) return;
			dataFetchedRef.current = true;	
			
			
		// Tableau drilldown filter changes
		if (hasDrillDown || hasTotalOrphan || hasTotalSpaceUtilized) {
			const selectedDateTime = localStorage.getItem('selectedDateTime')
			if (selectedDateTime) {
				setFromDateTime(selectedDateTime);
				setPropsFromDateTime(selectedDateTime);
			}
			const selectedSector = localStorage.getItem('selectedSector')
			if (selectedSector) {
				setCheckedList(JSON.parse(selectedSector));
				setCurrSector(JSON.parse(selectedSector));
				setCurrSectorFilter(JSON.parse(selectedSector));
			}
			const selectedServer = localStorage.getItem('selectedServer')
			if (selectedServer) {
				setInstance(selectedServer);
			}
			const selectedFilter = localStorage.getItem('selectedFilter')
			if (selectedFilter) {
				setUnUsedExtracts(selectedFilter);
			}
		} else {
			localStorage.removeItem('selectedDateTime')
			localStorage.removeItem('selectedFilter')
			localStorage.removeItem('selectedSector')
			localStorage.removeItem('selectedServer');
		}
		
		
		if (userSector === 'all') fetchSectorOptionsData();
		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/tableau/governance/dashboard') {
			if (activeId) {
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('databricks-jobs');
				document.body.classList.add('datefilter-align-sector');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				activeId.classList.remove('jobs-list-table');
				activeId.classList.remove('jobs-list');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.remove('jobs-exe-datas');
			}
		}
	}, [])
	let fromDate = moment(propsFromDateTime).format('YYYY-MM-DD');
	return (
		<div className="dashboard-panel job-schedules-wrap">
			<div className="job-exexutions-dateFilter-div">
				{/* [Date Range Menu] */}
				<div className='single-calendar-summary'>
					{/* condition for default Today  */}
					
						{<LocalizationProvider className="outerDateContainer" dateAdapter={AdapterMoment}>
						<DatePicker
							showToolbar={true}
							components={{
								OpenPickerIcon: DatePickerIcon
							}}
							PopperProps={{ placement: 'auto' }}
						
							className="dateFilterTD"
							value={moment(fromDateTime).tz('America/New_York')}
							onChange={(dateTime) => {
								handleDateTimeChange('fromDateTime', dateTime);
							}}
							renderInput={(params: any) => <TextField name="startDateTime" {...params} />}
						/>
					</LocalizationProvider>
						}
				
				</div>


				{/* Code for sector filter */}
				<Stack>
					{userSector === 'all' ? (
						<FilterIcon onClick={sectorFilterClick} className="sector-filter1" />
					) : (
						<div className="separeat_sector">Sector: {getDecodedToken()?.sector?.name}</div>
					)}
					<Popover
						open={filterMenu !== null}
						onClose={handleClose}
						className="filterContainer"
						anchorReference="anchorPosition"
						anchorPosition={filterMenu !== null ? { top: filterMenu.mouseY, left: filterMenu.mouseX } : undefined}
					>
						<div className="text-filter-row">
							<div className="selectAll-sec">
								<MenuItem className="selectAll">
									<FormControlLabel
										className={classes.checkbox}
										label="All"
										control={
											<Checkbox
												color="success"
												value="all"
												checked={itemsChecked}
												onChange={(e) => {
													selectAllCheck(e);
												}}
											/>
										}
									/>
								</MenuItem>
								<div className="checkbox-sec">
									{sectorOptionsList &&
										sectorOptionsList.map((filter: any) => (
											<MenuItem key={filter}>
												<FormControlLabel
													className={classes.checkbox}
													label={filter === true ? 'Yes' : filter === false ? 'No' : filter}
													control={
														<Checkbox
															color="success"
															value={filter}
															checked={
																(filter === true && checkedList.includes('true')) ||
																(filter === false && checkedList.includes('false')) ||
																checkedList.includes(filter)
															}
															onChange={(e) => {
																handleChange(e);
															}}
														/>
													}
												/>
											</MenuItem>
										))}
								</div>
							</div>
							<div className="buttonSec">
								<Button variant="contained" className={classes.cancelBtn} onClick={handleClose}>
									cancel
								</Button>
								<Button variant="contained" className={classes.okBtn} onClick={handleOkClick}>
									ok
								</Button>
							</div>
						</div>
					</Popover>
				</Stack>
				{/* Code for server filter */}
				<Stack>
					<div className="server-select">
						<FormControl fullWidth>
							<ServerIcon className="server-icon" />
							<Select
								name="server-select"
								labelId="demo-simple-select-label"
								id="single-server-select"
								value={instance}
								label="Server"
								onChange={(instance) => {
									handleServerChange('instance', instance);
								}}
								classes={{ select: classes.select }}
								IconComponent={() => null} 
							>
								<div>Servers</div>
								<MenuItem style={instance === 'all' ? {   background: '#676a88', borderRadius: '5px' } : {}} value={'all'}>All Servers</MenuItem>
								<MenuItem style={instance === 'global' ? { background: '#676a88', borderRadius: '5px' } : {}} value={'global'}>Global</MenuItem>
								<MenuItem style={instance === 'cockpit' ? { background: '#676a88', borderRadius: '5px' } : {}} value={'cockpit'}>Cockpit</MenuItem>
							</Select>
						</FormControl>
					</div>
				</Stack>
			</div>
			<>
				<Stack>
					<div className="governance-container-main">
						<div className="workBook-iframe-div-governance">
							<div className="work-book">
								<span
									className={'types ' + (usedWorkBook === '30Days' ? 'active' : '')}
									onClick={() => handleusedWorkBook('30Days')}
								>
									30 Days
								</span>
								<span
									className={'types ' + (usedWorkBook === '60Days' ? 'active' : '')}
									onClick={() => handleusedWorkBook('60Days')}
								>
									60 Days
								</span>
								<span
									className={'types ' + (usedWorkBook === '90Days' ? 'active' : '')}
									onClick={() => handleusedWorkBook('90Days')}
								>
									90 Days
								</span>
							</div>
							<Suspense fallback={<div>Loading...</div>}>
							<AnalyticsVisualization
								params={{
									dashboardUrl: dashboardUrl.tableauGovernance,
									className: 'tableauGovernanceWorkBookContainer',
									otherParams: {
										'var-filterusedWorkBook': usedWorkBook,
										'var-fromDate': fromDate,
										'var-sector': currSectorFilter,
										'var-server': instance
									},
									seamless: true
								}}								
							/>
							</Suspense>
						</div>
						<div className="extracts-iframe-div-governance">
							<div className="extract-book">
								<span
									className={'types ' + (usedExtracts === '30Days' ? 'active' : '')}
									onClick={() => handleusedExtracts('30Days')}
								>
									30 Days
								</span>
								<span
									className={'types ' + (usedExtracts === '60Days' ? 'active' : '')}
									onClick={() => handleusedExtracts('60Days')}
								>
									60 Days
								</span>
								<span
									className={'types ' + (usedExtracts === '90Days' ? 'active' : '')}
									onClick={() => handleusedExtracts('90Days')}
								>
									90 Days
								</span>
							</div>
							<Suspense fallback={<div>Loading...</div>}>
							<AnalyticsVisualization
								params={{
									dashboardUrl: dashboardUrl.tableauGovernanceMostUsedExtract,
									className: 'tableauGovernanceExtractsContainer',
									otherParams: {
										'var-filterusedExtracts': usedExtracts,
										'var-fromDate': fromDate,
										'var-sector': currSectorFilter,
										'var-server': instance
									},
									seamless: true
								}}
							/>
							</Suspense>
						</div>
						<div className="views-iframe-div-governance">
							<div className="views-book">
								<span
									className={'types ' + (usedViews === '30Days' ? 'active' : '')}
									onClick={() => handleusedViews('30Days')}
								>
									30 Days
								</span>
								<span
									className={'types ' + (usedViews === '60Days' ? 'active' : '')}
									onClick={() => handleusedViews('60Days')}
								>
									60 Days
								</span>
								<span
									className={'types ' + (usedViews === '90Days' ? 'active' : '')}
									onClick={() => handleusedViews('90Days')}
								>
									90 Days
								</span>
							</div>
							<Suspense fallback={<div>Loading...</div>}>
							<AnalyticsVisualization
								params={{
									dashboardUrl: dashboardUrl.tableauGovernanceMostUsedViews,
									className: 'tableauGovernanceViewsContainer',
									otherParams: {
										'var-filterusedViews': usedViews,
										'var-fromDate': fromDate,
										'var-sector': currSectorFilter,
										'var-server': instance
									},
									seamless: true
								}}
							/>
							</Suspense>
						</div>
					</div>
					<div className="governance-container-sub">
						<div className="orphaned-iframe-div-governance">
						<Suspense fallback={<div>Loading...</div>}>
							<AnalyticsVisualization
								params={{
									dashboardUrl: dashboardUrl.tableauGovernanceOrphaned,
									className: 'tableauGovernanceOrphanedIframeContainer',
									otherParams: {
									   'var-sector': currSectorFilter,
									   'var-fromDate': fromDate,
									   'var-server': instance
									},
									seamless: true
								}}
							/>
							</Suspense>
						</div>
						<div className="unusedExtracts-iframe-div-governance">
							<div className="unUsed-extracts">
								<span
									className={'types ' + (unUsedExtracts === '30Days' ? 'active' : '')}
									onClick={() => handleUnUsedExtracts('30Days')}
								>
									30 Days
								</span>
								<span
									className={'types ' + (unUsedExtracts === '60Days' ? 'active' : '')}
									onClick={() => handleUnUsedExtracts('60Days')}
								>
									60 Days
								</span>
							</div>
							<Suspense fallback={<div>Loading...</div>}>
							<AnalyticsVisualization
								params={{
									dashboardUrl: dashboardUrl.tableauGovernanceUnusedExtracts,
									className: 'tableauGovernanceUnusedExtractsIframeContainer',
									otherParams: {
										'var-filter': unUsedExtracts,
										'var-fromDate': fromDate,
										'var-sector': currSectorFilter,
										'var-server': instance
									},
									seamless: true
								}}
							/>
							</Suspense>
						</div>
					</div>
				</Stack>
			</>
			{hasTotalOrphan ? (
				<Suspense fallback={<div>Loading...</div>}>
				<TableauGovernanceOrphaned
					fromDateTime={propsFromDateTime}
					sectorFilter={sector}
					totalOrphanType={totalOrphanType}
					instance={instance}
				/>
				</Suspense>
			) :  (
				<>
					{hasTotalSpaceUtilized ? (
						<Suspense fallback={<div>Loading...</div>}>
						<SpaceUtilizedByUnused fromDateTime={propsFromDateTime}
						sectorFilter={sector}
						drillDownStatus={unusedExtractdrillDownStatus}
						drillDownFilter={drillDownFilter} instance={instance}/>
						</Suspense>
					) :(
						<>
						<Suspense fallback={<div>Loading...</div>}>
							<TableauGovernanceUsers 
								fromDateTime={propsFromDateTime} 
								sectorFilter={sector} 
								instance={instance} 
								drillDownFilter={drillDownFilter} />
								</Suspense>
								<Suspense fallback={<div>Loading...</div>}>	
									
							<TableauDetailView
								fromDateTime={propsFromDateTime}
								sectorFilter={sector}
								drillDownStatus={drillDownStatus}
								drillDownFilter={drillDownFilter}
								instance={instance}
								shouldScroll={shouldScroll}
							/>
							</Suspense>
						</>
					)}
				</>
			)}
		</div>

	);
};

export default Governance;
