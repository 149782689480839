import { lazy } from 'react';
import PrivateRoute from 'utils/privateRoute';
import Dashboard from 'components/dashboard/dashboard';

// const Dashboard = lazy(() => import('components/dashboard/dashboard'));

const DashboardRoutes = [
	{
		key: 'dash_admin',
		route: '/dashboard/administrative',
		component: (
			<PrivateRoute
				Component={Dashboard}
				restrictedRoles={[
					'Platform Support',
					'Data Operations Admin',
					'Data Operations User',
					'D&AI Executive User',
					'Program Executive'
				]}
			/>
		)
	},
	{
		key: 'dash_oper',
		route: '/dashboard/operational',
		component: (
			<PrivateRoute
				Component={Dashboard}
				restrictedRoles={[
					'Platform Support',
					'Data Operations Admin',
					'Data Operations User',
					'D&AI Executive User',
					'Program Executive'
				]}
			/>
		)
	},
	{
		key: 'dash_busi',
		route: '/dashboard/business',
		component: (
			<PrivateRoute
				Component={Dashboard}
				restrictedRoles={[
					'Platform Support',
					'Data Operations Admin',
					'Data Operations User',
					'D&AI Executive User',
					'Program Executive'
				]}
			/>
		)
	},
	{
		key: 'dash_job_perf',
		route: '/dashboard/jobPerformance',
		component: (
			<PrivateRoute
				Component={Dashboard}
				restrictedRoles={[
					'Platform Support',
					'Data Operations Admin',
					'Data Operations User',
					'D&AI Executive User',
					'Program Executive'
				]}
			/>
		)
	}
];

export default DashboardRoutes;
