import React, { useEffect, useState, useRef  } from 'react';
import { Grid } from '@mui/material';
import { getStaleUsers, getStaleUsersDate, StaleUsersSearch } from '../../../service/service';

import type { Column } from 'react-data-grid';
import { CustomizedDataGrid } from '../../customized_data_grid';
import './Governance.css';
import Loader from '../../Loader/loader';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { databricksurl } from '../../../service/config';

import axios from 'axios';

const StaleUser = ({ fromDateTime, sectorFilter, setFromDateTime, dateSelect }: any) => {


	
	interface Row {
		workspace_name: string;
		login_user: string;
		no_of_stale_logindate: number;
		login_date: string;
	}

	function getStaleUserColumns(): Array<Column<Row>> {
		return [
			{
				key: 'workspace_name',
				name: 'Workspace',
				formatter(props) {
					return <div title={props.row.workspace_name} className='has-ellipsis'>{props.row.workspace_name}</div>
				},
				minWidth: 250
			},
			{
				key: 'login_user',
				name: 'User',
				formatter(props) {
					return <div title={props.row.login_user} className='has-ellipsis'>{props.row.login_user}</div>
				},
				minWidth: 250
			},
			{
				key: 'no_of_stale_logindate',
				name: 'Stale Days',
				minWidth: 250
			},
			{
				key: 'login_date',
				name: 'Last Login Date',
				minWidth: 252,
				formatter(props) {
					const loginDate = props.row.login_date;
					if (loginDate) {
						return moment(loginDate).local().format('MM-DD-YY');
					}
					return '';
				}
			}
		];
	}

	const staleUserDefaultColumnNames = ['Workspace', 'User', 'Stale Days', 'Last Login Date'];

	const dataFetchedRef = useRef(false);
	const [staleUsersList, setstaleUsersList] = useState<Row[]>([]);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: 'login_date', sortDir: 'desc' });
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	// Date range
	const [filterData, setFilterData] = useState<Row[]>([]);
	const [searchDetails, setFilterSearchInfo] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [filterInfo, setFilterInfo] = useState([]);
	const [searchParams, setSearchParams] = useSearchParams();

	
	const handlePageChange = (event: any, value: number) => {
		setLoading(true);
		let nextPage = value.toString(); 
		if(event.target.dataset.testid == "LastPageIcon" || event.target.dataset.testid == "FirstPageIcon"){
			nextPage = value.toString(); 
		}
		else if(event.target.dataset.testid == "NavigateBeforeIcon"){
			nextPage = (Number(page) - 1).toString();
		}
		else if (nextPage === event.target.textContent) { 
			nextPage = event.target.textContent;
		} else {
			nextPage = (Number(page) + 1).toString();
		}
		const promise1 = DBStaleUserListingFunc(
			parseInt(nextPage),
			pageSize,
			sortingInfo,
			fromDateTime,
			fromDateTime,
			parseFilter(filterInfo),
			sectorFilter
		).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};


	const handlepageSizeChange = (event: any) => {
		setLoading(true);
		const promise1 = DBStaleUserListingFunc(
			'1',
			event.target.value,
			sortingInfo,
			fromDateTime,
			fromDateTime,
			parseFilter(filterInfo),
			sectorFilter
		).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

	
	const handleSorting = (sortingInfo: any) => {
		setLoading(true);
		const promise1 = DBStaleUserListingFunc(
			page,
			pageSize,
			sortingInfo,
			fromDateTime,
			fromDateTime,
			parseFilter(filterInfo),
			sectorFilter
		).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

	const resetSearchParams = () => {
		if (searchParams.has('fromDate') || searchParams.has('fromDate')) {
			searchParams.delete('status');
			searchParams.delete('scheduleDate');
			searchParams.delete('fromDate');
			searchParams.delete('fromDate');
			setSearchParams(searchParams);
		}
	};

	const handleFilter = (filterInfo: any) => {
		resetSearchParams();
		setFilterInfo(filterInfo);
		setLoading(true);
		const promise1 = DBStaleUserListingFunc(
			1,
			pageSize,
			sortingInfo,
			fromDateTime,
			fromDateTime,
			parseFilter(filterInfo),
			sectorFilter
		).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

	async function jobSearchForDBStaleUserFunc(searchDetails: any, columnKey: any) {
		const res = await StaleUsersSearch(
			'1',
			pageSize,
			parseFilter(filterInfo),
			fromDateTime,
			sectorFilter,
			searchDetails,
			columnKey
			);
		if (res.success) {
			setFilterSearchInfo(res.data);
		}
	}

	const handleSearch = (searchDetails: any, columnKey: any) => {
		console.log(searchDetails)
		if(searchDetails?.length >= 3)
		jobSearchForDBStaleUserFunc(searchDetails, columnKey);
	};
	
	const formatColumnFilterOptions = (apiData: any) => {
		const filterOptions: any = {
			workspace_name: [],
			login_user: [],
			no_of_stale_logindate: []
		}
		apiData.filter((job: any) => {
			Object.entries(job).forEach(([key, value]) => {
				if (key == 'workspace_name' && !filterOptions.workspace_name.includes(value)) {
					filterOptions.workspace_name.push(value);
				} else if (key == 'login_user' && !filterOptions.login_user.includes(value)) {
					filterOptions.login_user.push(value);
				} else if (key == 'no_of_stale_logindate' && !filterOptions.no_of_stale_logindate.includes(value)) {
					filterOptions.no_of_stale_logindate.push(value);
				}
			});
		});
		return filterOptions;
	}

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

	// initial load API call
	async function DBStaleUserListingFunc(
		pageNumber: any,
		size: any,
		sortingInfo: any,
		fromDateTime: any,
		toDateTime: any,
		jobFilterInfo: any,
		sector: any
	) {		
		setLoading(true);
		try {
			// Check if there's a stored sector filter in localStorage
		let storedSectorFilter = localStorage.getItem('d-resource-sector-filter')
			? JSON.parse(localStorage.getItem('d-resource-sector-filter') ?? '')
			: null;
		if (storedSectorFilter) {
			sector = storedSectorFilter;
		}
	
			// Make a call to Databricks
			const databricksresponse = await axios.get(`${databricksurl}`);
		
			console.log('Databricks Call initiated', databricksresponse);
	
			if (databricksresponse && databricksresponse.status) {
		const res = await getStaleUsers(
			pageNumber.toString(),
			size.toString(),
			sortingInfo,
			jobFilterInfo,
			fromDateTime,
			fromDateTime,
			sector
		);
		if (res.success) {
			if(res.data.result !== null){
			setstaleUsersList(res.data.result);
			setPaginationData(res.data.page);
			const filterOptionRes: any = {'workspace_name': [], 'login_user': []};
			if (Object.keys(res.data.filterOptions).length) {
				Object.keys(res.data.filterOptions).forEach((key: any) => {
					filterOptionRes[key] = res.data.filterOptions[key].map((d: any) => d[key]);
				});
			}
			setFilterData(filterOptionRes);
			setFilterInfo(jobFilterInfo);
			setPage(pageNumber);
			setPageSize(size);
			setFromDateTime(localStorage.getItem('processedDate'));
			setSortingInfo(sortingInfo);
			setError(null);
					}
				} else {
					setError('Failed to fetch data'); // Set the error state if API call fails
				}
			} else {
			setError('Cluster Inactive');
		}
	} catch (error) {
		setError('Failed to fetch data'); // Set the error state if API call fails
	}
	setLoading(false);
	}
	

	// User select specific date API call
		async function DBStaleUserListingDateFunc(
		pageNumber: any,
		size: any,
		sortingInfo: any,
		fromDateTime: any,
		toDateTime: any,
		jobFilterInfo: any,
		sector: any
	) {		
		try {	
	
		setLoading(true);
		const res = await getStaleUsersDate(
			pageNumber.toString(),
			size.toString(),
			sortingInfo,
			jobFilterInfo,
			fromDateTime,
			fromDateTime,
			sector
		);
		if (res.success) {
			if(res.data.result !== null){
			setstaleUsersList(res.data.result);
			setPaginationData(res.data.page);
			const filterOptionRes: any = {'workspace_name': [], 'login_user': []};
			if (Object.keys(res.data.filterOptions).length) {
				Object.keys(res.data.filterOptions).forEach((key: any) => {
					filterOptionRes[key] = res.data.filterOptions[key].map((d: any) => d[key]);
				});
			}
			setFilterData(filterOptionRes);
			// setFilterData(formatColumnFilterOptions(res.data.result));
			setFilterInfo(jobFilterInfo);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);			
		}}else {
			setError('Failed to fetch data'); // Set the error state if API call fails
		}
	} catch (error) {
		setError('Failed to fetch data'); // Set the error state if API call fails
	}
	setLoading(false);
	}

	useEffect(() => {	
		setError(null);
		DBStaleUserListingFunc(page, pageSize, sortingInfo, fromDateTime, fromDateTime, [],
			sectorFilter)
		.catch(
			(error) => {
				setError(error); // Set error state when API call fails during initial load
			}
		);
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;	
	}, [ sectorFilter]);

	useEffect(() => {
		setError(null);
		if (dateSelect != 0) {
			DBStaleUserListingDateFunc(
				"1",
				pageSize,
				sortingInfo,
				fromDateTime,
				fromDateTime,
				[],
				sectorFilter
			);
		}
	}, [dateSelect]);

	return (

		
		<div className='governance_tabledata-wrap stale-user'>
				<p className="databricks_title">Stale Users</p>
				{error == 'Cluster Inactive' ? (
					// Display error message or handle error condition in UI
					<div
						style={{
							color: '#e5e5e5',
							marginLeft: '25%',
							padding: '10px',
							fontWeight: 500,
							gridColumn: '1 / -1'
						}}
					>
						Cluster is Inactive, it will take Max 2mins to start, Please try again later
					</div>
				) :error? (
					// Display error message or handle error condition in UI
					<div
						style={{
							color: '#e5e5e5',
							marginLeft: '25%',
							padding: '10px',
							fontWeight: 500,
							gridColumn: '1 / -1'
						}}
					>
						Error occurred while fetching data. Please try again.
					</div>
				) : (
			<Grid className="governance_tabledata">
				{!loading ? (<>
					<CustomizedDataGrid
						tableColumns={getStaleUserColumns()}
						tableData={staleUsersList}
						tableType="DBStaleUsers"
						defaultColumnNames={staleUserDefaultColumnNames}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						filterDataOptions={filterData}
						onFilterCheck={handleFilter}
						currFilter={filterInfo}
						searchDataValues={searchDetails}
						onFilterSearch={handleSearch}
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={staleUsersList?.length}
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isDateRangeFilter={false}
						isDisabled={false}
					/>
					{staleUsersList?.length ? <p className="disclaimer">Note: Double hyphens (--) indicate that the field has no data.</p> : ''}
					</>
					) : (
						<Loader />
					)}
			</Grid>
				
				)}
		</div>

	);
};

export default StaleUser;
