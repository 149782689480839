import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Tooltip, Stack } from '@mui/material';
import ToolTipIcon from '../../assets/info-icon.svg';
import PageNextIcon from '../../assets/page_next.svg';
import NoPrgramFound from '../../assets/no-program-found.svg';
import LoaderComponent from 'components/Loader';

type NumberWidgetProps = {
	color?: string;
	isLoading: boolean;
	isInProgress?: boolean;
	title: string;
	tooltipLabel: string;
	footer?: {
		footerLink: string;
		footerLabel: string;
	};
	number?: number | string | string[] | number[] | null;
	units?: null | string | string[];
	unitIcon?: null | string;
	isCost?: boolean;
	minTitleHeight?: `${number}px`;
	handleEvent?: () => void;
};

const NumberWidget = ({
	color = '#e5e5e5',
	isLoading = false,
	isInProgress = false,
	title = '',
	tooltipLabel = '',
	number = '',
	units = null,
	unitIcon = null,
	footer,
	minTitleHeight = '38px',
	isCost = false,
	handleEvent
}: Readonly<NumberWidgetProps>) => {
	return (
		<Stack
			minHeight="100px"
			p={2}
			bgcolor="primary.main"
			fontFamily="Montserrat"
			borderRadius="5px"
			flexGrow={1}
			{...(handleEvent && { onClick: handleEvent, sx: { cursor: 'pointer' } })}
		>
			<Stack direction="row" gap={2} alignItems="start" minHeight={minTitleHeight}>
				<Typography variant="widgetTitle">{title}</Typography>
				<Tooltip title={tooltipLabel} arrow>
					<img src={ToolTipIcon} />
				</Tooltip>
			</Stack>
			{!isLoading ? (
				<Box minHeight="43px">
					<Stack color={color} direction="row" alignItems="baseline">
						{Array.isArray(number) ? (
							number.map((item, index) =>
								item !== '' && item !== null ? (
									<Stack direction="row" alignItems="baseline" key={`${item}-${index}`} ml={index !== 0 ? '10px' : 0}>
										<Typography variant="widgetValue">{`${isCost ? '$' : ''}${item}`}</Typography>
										{!!units && units[index] ? (
											<Typography variant="widgetUnit" {...(units[index] === '%' && { ml: 0 })}>
												{units[index]}
											</Typography>
										) : (
											''
										)}{' '}
									</Stack>
								) : (
									<React.Fragment key={index} />
								)
							)
						) : number !== '' && number !== null ? (
							<>
								<Typography variant="widgetValue">{`${isCost ? '$' : ''}${number}`} </Typography>
								{units && !Array.isArray(units) && (
									<Typography variant="widgetUnit" {...(units === '%' && { ml: 0 })}>
										{units}
									</Typography>
								)}
							</>
						) : (
							<></>
						)}
						{unitIcon && (
							<img
								src={unitIcon}
								style={{
									height: '24px',
									width: '24px',
									marginLeft: '10px'
								}}
							/>
						)}
					</Stack>
				</Box>
			) : (
				<LoaderComponent />
			)}
			<Stack gap={1} mt='auto'>
				{isInProgress && (
					<Stack alignSelf="flex-end" alignItems="flex-end">
						<Tooltip title="In Progress/Validation">
							<img
								src={NoPrgramFound}
								style={{
									height: '20px',
									width: '20px'
								}}
							/>
						</Tooltip>
					</Stack>
				)}
				{!!footer && (
					<Box
						component={Link}
						sx={{
							textDecoration: 'none'
						}}
						alignSelf="flex-end"
						to={footer.footerLink}
						onClick={(e) => {
							e.stopPropagation();
						}}
					>
						<Stack direction="row" alignItems="center">
							<Typography variant="caption">{footer.footerLabel}</Typography>
							<img
								src={PageNextIcon}
								style={{
									height: '10px',
									width: '10px'
								}}
							/>
						</Stack>
					</Box>
				)}
			</Stack>
		</Stack>
	);
};

export default NumberWidget;
