import { createContext } from 'react';
import moment from 'moment-timezone';
const synced = moment();

export const HeaderContext = createContext({
	isNull: true,
	setIsNull: (value) => {
		this.isNull = value;
	},
	lastSync: synced.fromNow(),
	setLastSync: (value) => {
		this.lastSync = value;
	}
});
