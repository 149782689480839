import React, { useEffect, useState, useRef } from 'react';
import { Grid, Chip, Select, MenuItem, FormControl, Button, Stack } from '@mui/material';
import { getJobRunHistory, updateJobRunHistory, jobSearch, getReasons } from '../../service/service';

import type { Column } from 'react-data-grid';
import { CustomizedDataGrid } from '../customized_data_grid';
import { textEditor } from 'react-data-grid';
import moment from 'moment-timezone';
import { ReactComponent as BackwardIcon } from '../../assets/page_backward.svg';
import { ReactComponent as PrevIcon } from '../../assets/page_prev.svg';
import { ReactComponent as NextICon } from '../../assets/page_next.svg';
import { ReactComponent as ForwardIcon } from '../../assets/page_forward.svg';
import { OverflowTooltip } from '../overflow_tooltip';
import convertSeconds from '../../utils/convertSeconds';
import { dashboardUrl } from '../../service/config';
import '../jobs/runHistory.css';
import Loader from '../Loader/loader';
import AnalyticsVisualization from '../framework/analyticsVisualization';

export function RunHistory({ jobId, jobData, tabValue }: any) {
	interface Row {
		runStatus: string;
		reasonForDelay: string;
		actionTaken: string;
		ticketNumber: string;
		userStatus: string;
		downstream: string;
		slaStatus: string;
		slaEndTime: string;
		estimatedEndTime: string;
		machine: string;
		_id: string;
		jobId: string;
		name: string;
		avgRunTime: number;
		status: string;
		commonStatus: string;
		exitCode: string;
		runNum: string;
		runStartTime: string;
		runEndTime: string;
		predecessor: string;
		successor: string;
		// executionFrequency: string;
		updatedAt: string;
		updatedBy: string;
		actualDuration: string;
		estimatedDuration: string;
		overRun: string;
		thresholdPercent: string;
		durationDelay: string;
	}
	moment.updateLocale(moment.locale(), { invalidDate: '' });

	const renderStatus = (status: any) => {
		switch (status) {
			case 'SUCCESS':
			case 'Successful':
				return <Chip label={status} color="success" />;
			case 'FAILURE':
			case 'Failure':
			case 'TERMINATED':
			case 'Terminated':
			case 'SUSPENDED':
				return <Chip label={status} color="secondary" />;
			case 'NOT STARTED':
			case 'Not Started':
				return <Chip label={status} style={{ backgroundColor: '#1B1E35CC', color: '#71758F' }} />;
			case 'RUNNING':
			case 'ACTIVATED':
			case 'In Progress':
			case 'Hold':
				return <Chip label={status} style={{ backgroundColor: '#299BFF26', color: '#299BFF' }} />;
			default:
				return <Chip label={status} style={{ backgroundColor: '#299BFF26', color: '#299BFF' }} />;
		}
	};
	const renderThreshold = (threshold: any) => {
		let color = '#2DC8A8';
		if (threshold > 80 && threshold <= 100) color = '#FFC258';
		else if (threshold > 100) color = '#FE8083';
		return (
			<span color="success" style={{ color: color }}>
				{threshold} %
			</span>
		);
	};
	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'runNum',
				name: 'Run No',
				minWidth: 140
				// maxWidth: 390
			},
			{
				key: 'runStatus',
				name: 'Status',
				width: 120,
				minWidth: 120,
				// maxWidth: 150,
				cellClass: 'center-text',
				formatter(props) {
					const status = props.row.runStatus;
					return renderStatus(status);
				}
			},
			{
				key: 'commonStatus',
				name: 'Common Status',
				minWidth: 155,
				width: 155,
				// maxWidth: 220,
				cellClass: 'center-text',
				formatter(props) {
					const status = props.row.commonStatus;
					return renderStatus(status);
				}
			},
			{
				key: 'runStartTime',
				name: 'Start Time',
				minWidth: 120,
				// maxWidth: 140,
				formatter(props) {
					const startDate = props.row.runStartTime;
					if (startDate) {
						return moment(startDate).format('MM-DD-YY HH:mm:ss');
					}
					return '';
				}
			},
			{
				key: 'runEndTime',
				name: 'End Time',
				minWidth: 100,
				// maxWidth: 140,
				formatter(props) {
					const endDate = props.row.runEndTime;
					if (endDate) {
						return moment(endDate).format('MM-DD-YY HH:mm:ss');
					}
					return '';
				}
			},
			{
				key: 'slaStatus',
				name: 'SLA Status',
				minWidth: 120,
				// maxWidth: 140,
				cellClass: 'center-text',
				formatter(props) {
					return ['Met', 'Not Met'].includes(props.row.slaStatus) ? (
						<Chip
							label={props.row.slaStatus}
							color={props.row.slaStatus === 'Met' ? 'success' : 'secondary'}
							className="slaChip"
						/>
					) : (
						props.row.slaStatus
					);
				}
			},
			{
				key: 'slaEndTime',
				name: 'Normal Completion Time',
				minWidth: 200,
				// maxWidth: 250,
				formatter(props) {
					return (
						<OverflowTooltip customTooltip={`Timezone: ${moment().isDST() ? 'EDT(UTC-4)' : 'EST(UTC-5)'}`}>
							{props.row.slaEndTime}
						</OverflowTooltip>
					);
				}
			},
			{
				key: 'estimatedEndTime',
				name: 'Estimated End Time',
				formatter(props) {
					return props.row.estimatedEndTime ? moment(props.row.estimatedEndTime).format() : '';
				},
				minWidth: 180
				// maxWidth: 200
			},
			{
				key: 'reasonForDelay',
				name: 'Reason',
				minWidth: 110,
				// maxWidth: 140,
				cellClass: 'has-editor very-long-text',
				formatter(props) {
					if (props.row.reasonForDelay) {
						return props.row.reasonForDelay.length > 15 ? (
							<p className="reasonForDelay">{props.row.reasonForDelay}</p>
						) : (
							<p>{props.row.reasonForDelay}</p>
						);
					}
				},
				editor: (p) => (
					<div>
						<FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} className="row-select-drpdwn">
							<Select
								labelId="Reason for Delay"
								id="reasonForDelay"
								defaultOpen={true}
								value={p.row.reasonForDelay}
								onChange={(e: any) => {
									p.onRowChange({ ...p.row, reasonForDelay: e.target.value }, true);
								}}
								label="Reason for Delay"
							>
								<MenuItem value=""></MenuItem>
								{reasonList.map((reason) => (
									<MenuItem key={reason.id} value={reason.description}>
										{reason.description}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
				)
			},
			{
				key: 'actionTaken',
				name: 'Action Taken',
				minWidth: 130,
				// maxWidth: 140,
				cellClass: 'has-editor',
				editor: textEditor,
				formatter(props) {
					return props.row.actionTaken ? <OverflowTooltip>{props.row.actionTaken}</OverflowTooltip> : 'Action Taken?';
				}
			},

			{
				key: 'ticketNumber',
				name: 'Ticket No',
				width: 120,
				minWidth: 120,
				editor: textEditor,
				cellClass: 'has-editor',
				formatter(props) {
					return props.row.ticketNumber ? <OverflowTooltip>{props.row.ticketNumber}</OverflowTooltip> : 'Ticket No?';
				}
			},
			{
				key: 'downstream',
				name: 'Follow Up',
				minWidth: 110,
				// maxWidth: 140,
				cellClass: 'has-editor',
				editor: textEditor,
				formatter(props) {
					return props.row.downstream ? <OverflowTooltip>{props.row.downstream}</OverflowTooltip> : 'Follow Up?';
				}
			},

			{
				key: 'userName',
				name: 'Last Updated By',
				minWidth: 150,
				width: 150
				// maxWidth: 200
			},
			{
				key: 'updatedAt',
				name: 'Last Updated At',
				minWidth: 150,
				width: 150,
				// maxWidth: 200,
				formatter(props) {
					const updatedAt = props.row.updatedAt;
					if (updatedAt) {
						return moment(updatedAt).format();
					}
					return '';
				}
			},
			{
				key: 'avgRunTime',
				name: 'Avg Exec Duration',
				minWidth: 150,
				width: 180,
				// maxWidth: 200,
				formatter(props) {
					const avgRunTime = props.row.avgRunTime;
					return convertSeconds(avgRunTime);
				}
			},
			{
				key: 'machine',
				name: 'Machine',
				minWidth: 125,
				width: 125,
				// maxWidth: 220,
				formatter(props) {
					return props.row.machine || jobData.machine;
				}
			},
			{
				key: 'estimatedDuration',
				name: 'Estimated Duration',
				minWidth: 180,
				width: 200,
				// maxWidth: 250,
				formatter(props) {
					return props.row.estimatedDuration;
				}
			},
			{
				key: 'actualDuration',
				name: 'Actual Duration',
				minWidth: 150,
				width: 150
				// maxWidth: 200
			},
			{
				key: 'overRun',
				name: 'Over Run',
				minWidth: 95
				// maxWidth: 160
			},
			{
				key: 'thresholdPercent',
				name: 'Threshold %',
				minWidth: 120,
				width: 150,
				// maxWidth: 200,
				cellClass: 'center-text',
				formatter(props) {
					const thresholdPercent = props.row.thresholdPercent;
					return renderThreshold(thresholdPercent);
				}
			},
			{
				key: 'durationDelay',
				name: 'Duration of Delay',
				minWidth: 150,
				width: 180
				// maxWidth: 200
			}
		];
	}

	const defaultColumnNames = [
		'Run No',
		'Status',
		'Common Status',
		'Start Time',
		'End Time',
		'SLA Status',
		'Normal Completion Time',
		'Estimated End Time',
		'Reason',
		'Action Taken',
		'Ticket No',
		'Follow Up',
		'Last Updated By',
		'Last Updated At'
	];

	// const classes = useStyles();
	const dataFetchedRef = useRef(false);
	const [runList, setRunList] = useState<any[]>([]);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [loading, setLoading] = useState(false);
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState('100');

	const [filterData, setFilterData] = useState<Row[]>([]);
	const filterQuerySkeleton = {} as any;
	getColumns().forEach((column: any) => {
		filterQuerySkeleton[column.key] = [];
	});
	const [filterInfo, setFilterInfo] = useState(filterQuerySkeleton);
	const [searchDetails, setFilterSearchInfo] = useState([]);
	const [reasonList, setReasonList] = useState<any[]>([]);
	// scroll issue on initial load
	const [view, setView] = useState(false);
	useEffect(() => {
		setTimeout(() => {
			setView(true);
		}, 5000);
	}, []);

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		fetchJobRunHistory(value, pageSize, sortingInfo, parseFilter(filterInfo));
	};

	const handlepageSizeChange = (event: any) => {
		fetchJobRunHistory(page, event.target.value, sortingInfo, parseFilter(filterInfo));
	};

	const handleSorting = (sortingInfo: any) => {
		fetchJobRunHistory(page, pageSize, sortingInfo, parseFilter(filterInfo));
	};

	const handleFilter = (filterInfo: any) => {
		setFilterInfo(filterInfo);
		fetchJobRunHistory(1, pageSize, sortingInfo, parseFilter(filterInfo));
	};
	const handleSearch = (searchDetails: any) => {
		jobSearchFunc(searchDetails, parseFilter(filterInfo, 'name'));
	};

	async function jobSearchFunc(searchDetails: any, jobFilterInfo: any) {
		const res = await jobSearch(searchDetails, jobFilterInfo, 'name');
		if (res.success) {
			setFilterSearchInfo(res.data);
		}
	}

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		filterInfo &&
			Object.keys(filterInfo).forEach((key: any) => {
				if (key !== name && filterInfo[key] && filterInfo[key].length) {
					parsedFilter[key] = filterInfo[key];
				}
			});
		return parsedFilter;
	};

	const handleEditCell = (rows: any[], editData: any) => {
		const runId = rows[editData.indexes[0]].id;
		const key = editData.column.key;
		const patchData: any = {};
		patchData[key] = rows[editData.indexes[0]][key];
		patchData.runId = runId;
		patchJobRunHistory(patchData, rows);
	};

	useEffect(() => {
		if (tabValue !== 1) return;
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		const storedJobRunsState = localStorage.getItem('jobRunsState')
			? JSON.parse(localStorage.getItem('jobRunsState') ?? '')
			: null;
		if (storedJobRunsState && storedJobRunsState.pageNumber && storedJobRunsState.pageSize) {
			fetchJobRunHistory(
				1,
				storedJobRunsState.pageSize,
				storedJobRunsState.sortingInfo,
				parseFilter(storedJobRunsState.filterInfo)
			);
			setPaginationData(storedJobRunsState.page);
			setFilterData(storedJobRunsState.filterOptions);
			setPage(1);
			setPageSize(storedJobRunsState.pageSize);
			setSortingInfo(storedJobRunsState.sortingInfo);
			setFilterInfo(storedJobRunsState.filterInfo);
		} else {
			fetchJobRunHistory(page, pageSize, sortingInfo, parseFilter(filterInfo));
		}
	}, [jobId, tabValue]);

	async function fetchJobRunHistory(pageNumber: any, size: any, sortingInfo: any, runFilterInfo: any) {
		setLoading(true);
		const res = await getJobRunHistory(pageNumber.toString(), size.toString(), sortingInfo, runFilterInfo, jobId);
		if (res.success) {
			const reasonsRes = await getReasons('1', '500', '');
			setReasonList(reasonsRes.data.slaNotMetReasons);
			setLoading(false);
			const runsData = res.data.runs;
			const processedRuns = runsData.map((run: any) => {
				return {
					...run
				};
			});
			setRunList(processedRuns);
			setPaginationData(res.data.page);
			setFilterData(res.data.filterOptions);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
			const jobRunsState = {
				page: res.data.page,
				pageSize: size,
				pageNumber,
				sortingInfo,
				filterInfo: runFilterInfo
			};
			localStorage.setItem('jobRunsState', JSON.stringify(jobRunsState));
		}
	}

	async function patchJobRunHistory(patchData: any, rows: Row[]) {
		setLoading(true);
		const res = await updateJobRunHistory(patchData);
		if (res.success) {
			setLoading(false);
			setRunList(rows);
		}
		setLoading(false);
	}
	const [jobsFromDate, setJobsFromDate] = useState(moment().utc().format('YYYY-MM-DD'));
	const handleJobsDateRange = (event: any) => {
		let targetDate;
		if (event === 'prev') {
			targetDate = moment(jobsFromDate).subtract(1, 'day').format('YYYY-MM-DD');
			setJobsFromDate(targetDate);
		} else if (event === 'next') {
			targetDate = moment(jobsFromDate).add(1, 'day').format('YYYY-MM-DD');
			setJobsFromDate(targetDate);
		} else if (event === 'last') {
			targetDate = moment(jobsFromDate).add(6, 'day').format('YYYY-MM-DD');
			setJobsFromDate(targetDate);
		} else if (event === 'first') {
			targetDate = moment(jobsFromDate).subtract(6, 'day').format('YYYY-MM-DD');
			setJobsFromDate(targetDate);
		}
	};

	const isPageNavigationDisabled = (navigation: any, fromDate: any) => {
		switch (navigation) {
			case 'next':
				return fromDate === moment().format('YYYY-MM-DD');
			case 'last':
				return moment().diff(moment(fromDate), 'days') < 6;
			case 'prev':
				return moment(fromDate).diff(moment('2023-03-01').add(6, 'days'), 'days') < 0;
			case 'first':
				return moment(fromDate).diff(moment('2023-03-01').add(6, 'days'), 'days') < 6;
		}
	};
	return (
		<div>
			<div className="executionRunHistory">
				{view && (
					<>
						<Stack style={{ width: '100%' }}>
							<AnalyticsVisualization
								params={{
									dashboardUrl: dashboardUrl.jobRunHistory,
									className: 'runhistoryIframeContainer',
									otherParams: {
										'var-jobId': jobId,
										'var-forDate': jobsFromDate,
										from: 'now-6h',
										to: 'now'
									},
									seamless: true
								}}
							/>
						</Stack>
						<Grid item xs={6} style={{ float: 'right', position: 'absolute', right: '0px', bottom: '0px' }}>
							<Stack direction="row" spacing={2} className="paginationStack">
								<Button
									disabled={isPageNavigationDisabled('first', jobsFromDate)}
									onClick={() => {
										handleJobsDateRange('first');
									}}
									className="paginationIcon dashboardPageBtn"
								>
									<BackwardIcon />
								</Button>
								<Button
									disabled={isPageNavigationDisabled('prev', jobsFromDate)}
									onClick={() => {
										handleJobsDateRange('prev');
									}}
									className="paginationIcon dashboardPageBtn"
								>
									<PrevIcon />
								</Button>
								<Button
									disabled={isPageNavigationDisabled('next', jobsFromDate)}
									onClick={() => {
										handleJobsDateRange('next');
									}}
									className="paginationIcon dashboardPageBtn"
								>
									<NextICon />
								</Button>
								<Button
									disabled={isPageNavigationDisabled('last', jobsFromDate)}
									onClick={() => {
										handleJobsDateRange('last');
									}}
									className="paginationIcon dashboardPageBtn"
								>
									<ForwardIcon />
								</Button>
							</Stack>
						</Grid>
					</>
				)}
			</div>
			<div>
				<Grid container item xs={12}>
					<Grid item xs={5}></Grid>
					<Grid item xs={4} />
				</Grid>
				<Grid className="run_history_tabledata">
					{!loading ? (
						<CustomizedDataGrid
							tableColumns={getColumns()}
							tableData={runList}
							tableType="Run"
							defaultColumnNames={defaultColumnNames}
							onCellEdit={handleEditCell}
							onSorting={handleSorting}
							currSorting={sortingInfo}
							filterDataOptions={filterData}
							searchDataValues={searchDetails}
							onFilterCheck={handleFilter}
							currFilter={filterInfo}
							onFilterSearch={handleSearch}
							page={page}
							pageSize={pageSize}
							paginationData={paginationData}
							listLength={runList.length}
							handlepageSizeChange={handlepageSizeChange}
							handlePageChange={handlePageChange}
							isDateRangeFilter={false}
							isDisabled={false}
						/>
					) : (
						<Loader />
					)}
				</Grid>
			</div>
		</div>
	);
}
