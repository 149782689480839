import { Autocomplete, Button, Checkbox, debounce, Dialog, FormControl, Grid, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, Theme } from "@mui/material"
import React, { useEffect, useMemo, useState } from "react"
import TeradataIcon from '../../assets/icon-teradata.svg'
import DatabricksIcon from '../../assets/databricks.svg'
import PowerBiIcon from '../../assets/PowerBI.svg'
import AzureIcon from '../../assets/azure-adf.svg'
import PlusSymbolIcon from '../../assets/close-panel.svg'
import { CustomizedDataGrid } from "../customized_data_grid";
import { Column } from "react-data-grid";
import Loader from "../Loader/loader";
import { getSearchScheduleJobNameList, getTeradataJobNameList, linkAddScheduleToProject, getNormalCompletionTime } from "../../service/service"
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers"
import moment from "moment"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { Link } from "react-router-dom"

import { ReactComponent as EditPencilIcon } from '../../assets/material-symbols_edit-rounded_white.svg';

import { ReactComponent as MaskedInputIcon } from "../../assets/clockIcon.svg"

import MaskedInput from 'react-maskedinput'
import { getHoursList } from "utils/common-util"

  function getStyles(name: string, personName: readonly string[], theme: Theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

    
const AddSchedules = () => {
    interface Row {
        id: number;
        selected: boolean;
        jobName: string;
        schedulerName: string;
        schedule: string;
        normalCompletionTime: string;
        freqExecution: string;
    };

    interface Filter {
        filterType: string;
        keywords: string | Array<string>;
        fromTime?: any;
        fromTimeValue?: string;
        toTime?: any;
        toTimeValue?: string;
        id?: number;
        operation?: string;
        type?: string;
        subType?: string;
    }

	let selectedRowsId: any = [];
        
    const ITEM_HEIGHT = 38;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
    PaperProps: {
        style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
        },
    },
    };

    const platformList = [
        'Teradata'
    ];

    const filterCriteriaList = [
        'Job Name',
        'Days',
        'Start Time'
    ];

    const operationList = [
        'Equals to (=)',
        'Not Equals to (!=)',
        'Like (%)',
        'Not Like (%)'
    ];

    const [timeList, setTimeList] = useState<any>(getHoursList);

    const defaultColumnNames = ['HeaderCheckbox','Job Name','Scheduler','Start Time (CST)','Normal Completion Time (CST)'];
    const [daysList, setDaysList]  = React.useState<any>(['All', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']);
    
    const [selectedRows, setSelectedRows] = useState<Row[]>([]);
    const [tableDataList, setTableDataList] = useState<Row[]>([]);
    const [jobDataList, setJobDataList] = useState<any[]>([]);
    const [jobNameList, setJobNameList] = useState<any[]>([]);
    const [platformName, setPlatformName] = React.useState<string>('Teradata');
    const [criteriaName, setCriteriaName] = React.useState<string>('');
    const [operationName, setOperationName] = React.useState<string>('');
    const [keywordName, setKeywordName] = React.useState<string>('');
    const [addClassFilterHoverEffect, setAddClassFilterHoverEffect] = React.useState<boolean>(false);

    const [openId, setOpenId] = React.useState<number>();

    const [requestBody, setRequestBody] = React.useState<any>();
    const [successMsg, setSuccessMsg] = React.useState<string>('');

    const [hasDaysAdded, setHasDaysAdded] = React.useState<boolean>();
    const [hasTimeAdded, setHasTimeAdded] = React.useState<boolean>();

    const [tableLoading, setTableLoading] = React.useState<boolean>();
    const [addProjectLoading, setAddProjectLoading] = React.useState<boolean>();
    const [openModal, setOpenModal] = useState<any>({isOpen: false})

    const [selectedJobName, setSelectedJobName] = React.useState<boolean>(false);
    const [searchScheduleBtn, setSearchScheduleBtn] = React.useState<boolean>();

    const [searchInputLoading, setSearchInputLoading] = React.useState<boolean>();

    const [filterCriteria, setFilterCriteria]  = React.useState<Filter[]>([]);
    
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState('1');
	const [pageSize, setPageSize] = useState('100');
    let reqRows: Row[] = []

    let isTimeMenuId = 99999999;

    function getColumns(): Array<Column<Row>> {
        return [
			{
                key: 'selected',
                name: 'HeaderCheckbox',
                formatter: ({ row }) => (
                    <input 
                        type="checkbox"
                        className="transparent-checkbox row-checkbox"
                        checked={row.selected}
                        onChange={(e) => {
                            const checked = e.target.checked;
                            const updatedRow = { ...row, selected: checked };
                            console.log(updatedRow)
							const headerCheckboxInput = document.getElementById('HeaderCheckbox') as HTMLInputElement;
                            let tempSelectedRows = selectedRows;
                            console.log(selectedRows)
							if (checked) {
								tempSelectedRows.push(updatedRow)
								selectedRowsId.push(updatedRow.id)
							} else {
								selectedRowsId.splice(selectedRowsId.indexOf(row.id), 1)
								if (headerCheckboxInput) {
									headerCheckboxInput.checked = false;	
								}
							}
					
							tempSelectedRows = tempSelectedRows.filter((eachRow:any) => { 
								return selectedRowsId.includes(eachRow.id)

							})
							if (tempSelectedRows.length == tableDataList.length) {
								if (headerCheckboxInput) {
									headerCheckboxInput.checked = true;	
								}
							}
                            setSelectedRows(tempSelectedRows);
                            setTableDataList(prevRows => prevRows.map(prevRow => prevRow.id === updatedRow.id ? updatedRow : prevRow));
                        }}
                    />
                ),
                minWidth: 40,
                maxWidth: 60
            },
            {
                key: 'jobName',
                name: 'Job Name',
                formatter(props) {
					return <div title={props.row.jobName} className="has-ellipsis">{props.row.jobName ? props.row.jobName : '--'}</div>
				},
                minWidth: 280
            },
            {
                key: 'schedulerName',
                name: 'Scheduler',
                formatter(props) {
					return <div title={props.row.schedulerName}>{props.row.schedulerName == 'AutoSys' ? 'AutoSys' : 'Control-M'}</div>
				},
                minWidth: 170
            },
            {
                key: 'schedule',
                name: 'Start Time (CST)',
                formatter(props) {
					return <div title={props.row.schedule} style={{textTransform: 'capitalize'}}>{props.row.schedule ? props.row.schedule : '--'}</div>
				},
                minWidth: 170
            },
            {
                key: 'normalCompletionTime',
                name: 'Normal Completion Time (CST)',
                formatter: ({row}) => {
                    return <div className="normal-compl-time-wrap">
                        {/* <LocalizationProvider dateAdapter={AdapterMoment}>
                                <ThemeProvider theme={timepickerTheme}>
                                    <TimePicker
                                        orientation="landscape"
                                        showToolbar={true}
                                        className="norTimeFrom"
                                        ampm={false}
                                        value={row.normalCompletionTime}
                                        onChange={(e) => {onChangeNormalTime(e, row)}}
                                        renderInput={(params: any) => (
                                            <TextField {...params} value={row.normalCompletionTime} name={`${'startTimeFrom'+row.id}`} />
                                        )}
                                    />
                                </ThemeProvider>
                            </LocalizationProvider> */}
                            {/* <input title="Enter the time" placeholder="hh:mm" className="custom-time-field" type="time" value={row.normalCompletionTime} onInput={(e) => onChangeNormalComplTime(e, row)} id={`time-input-${row.id}`} /> */}
                            {/* <EditPencilIcon className="pencil-icon" onClick={(e) => onClickOnInput(row.id)} /> */}
                            {/* <input className="custom-time-input" id={`${'start-time-from-'+row.id}`} value={row.normalCompletionTime} placeholder="hh:mm" onInput={ (e) => onChangeNormalComplTime(e, row)} /> */}
                            <div className="custom-time-picker">
                                <MaskedInputIcon className="custom-icon-calc" />
                                <EditPencilIcon className="pencil-icon" />
                                <MaskedInput className={isTimeMenuId == row.id ? 'active custom-time-input-ele' : 'custom-time-input-ele' } mask="11:11" placeholder="hh:mm" name="time" value={row.normalCompletionTime}
                                onBlurCapture={handleTimeMenuPopupClose}  onBlur={handleTimeMenuPopupClose}
                                onFocus={(e) => onClickMaskInput(e, row)} onClickCapture={(e) => onClickMaskInput(e, row)} 
                                onChange={(e) => onChangeNormalComplTime(e, row, true)} />
                                    <div className="custom-timepicker-menu">
                                        {timeList.map((time: string) => (
                                            <div className="list" onClick={(e)=> onChangeNormalComplTime(e, row) } key={time}>{time}</div>
                                        ))}
                                    </div>
                                {/* <ClickAwayListener onClickAway={handleTimeMenuPopupClose}>
                                <Select
                                    id="custom-time-dropdown"
                                    className="custom-time-dropdown"
                                    displayEmpty
                                    value={selectedTime}
                                    onChange={(e) => onChangeNormalComplTime(e, row, true)}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) => {
                                        return selected
                                    }}
                                    open={isTimeMenuId == row.id}
                                    MenuProps={MenuProps}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    > 
                                    {timeList.map((time: string) => (
                                        <MenuItem key={time} value={time}>{time}</MenuItem>
                                    ))}
                                </Select>   
                                </ClickAwayListener> */}
                                {/* {row.id + ' ' + isTimeMenuId} */}
                            </div> 

                        </div>
                },
                minWidth: 170
            }
        ];
	}

    const onClickMaskInput = (e: any, row: any) => {
        isTimeMenuId = row.id;
    }

    const handleTimeMenuPopupClose = () => {
        isTimeMenuId = 9999999;
    }

    const handleTimePickerMenuCloseFromOutside = (event: any) => {
        const inputEleList = document.getElementsByClassName('has-ellipsis') as HTMLCollection
        if (inputEleList.length) {
            const ele = inputEleList[0] as HTMLInputElement
            ele.click();
        }
    }

    const onClickOnInput = (id: number) => {
        const inputEle = document.getElementById('time-input-'+id) as HTMLInputElement
        inputEle.showPicker();
    }

    const onHandleOpenTimePicker = (idx: number) => {
        setOpenId(idx);
    }

    function replaceAt(str: string, index: number, replacement: string) {
        return str.substring(0, index) + replacement + str.substring(index + replacement.length);
        // let formatValue = '';
        // if (val.substring(0,1) > 2) {
        //     formatValue = replaceAt(val, 0, '2')
        // }
        // if (val.substring(0,1) == 2 && val.substring(1, 2) > 3) {
        //     formatValue = replaceAt(val, 1, '3')
        // }
        // if (val.substring(3,4) > 5) {
        //     formatValue = replaceAt(val, 3, '5')
        // }
      }

    const onChangeNormalComplTime = (event: any, row: any, isSelect?: boolean) => {
        let val = event.target.value || event.target.innerText;
        val = val.split('');
        if (val[0] > 2 || val[0] == 2 && val[1] > 3) {
            val = ['2', '3', ':', '5', '9'];
            handleTimePickerMenuCloseFromOutside(event);
        }
        if (val[3] > 5) {
            val[3] = '5';
            val[4] = '9';
            handleTimePickerMenuCloseFromOutside(event);
        }
        val = val.join('');
        const updatedRow = { ...row, normalCompletionTime: val };
        const allSelectedRows = selectedRows.length ? [...selectedRows] : reqRows;
        allSelectedRows.map((eachRow) => {
            if (eachRow.id == row.id) {
                eachRow.normalCompletionTime = val
            }
        })
        const findLenOfEnteredNum = val.replace(/_/g, '').replace(':', '').length;
        if (!isSelect ||  findLenOfEnteredNum == 4) {
            isTimeMenuId = 9999999;
        }
        setSelectedRows(allSelectedRows);
        setTableDataList(prevRows => prevRows.map(prevRow => prevRow.id === updatedRow.id ? updatedRow : prevRow));
    }

    const onChangeNormalTime = (event: any, row: any) => {
        if (event && event._d) {
            let normalCompletionTime = moment(event._d).utc().format('HH:mm')
            const updatedRow = { ...row, normalCompletionTime: normalCompletionTime };
            setSelectedRows(prevRows => prevRows.map(prevRow => prevRow.id === updatedRow.id ? updatedRow : prevRow));
            setTableDataList(prevRows => prevRows.map(prevRow => prevRow.id === updatedRow.id ? updatedRow : prevRow));
        }
    }

    // const onChangeInputNormalTime = (event: any, row: any) => {
    //     console.log(event);
    //     console.log(row)
    //     let normalCompletionTime = event.target.value
    //     console.log(normalCompletionTime)
    //     if (!normalCompletionTime.includes(':')) {
    //         normalCompletionTime = normalCompletionTime.replace(/(..)/g, '$1:').slice(0,-1)
    //     }
    //     const updatedRow = { ...row, normalCompletionTime: normalCompletionTime };
    //     setTableDataList(prevRows => prevRows.map(prevRow => prevRow.id === updatedRow.id ? updatedRow : prevRow));
    // }
    
    const handleHeaderSelectAll = (event: any) => {
		selectedRowsId = [];
		let tempSelectedRows: any = [];
		tableDataList.map((row: any) => {
			row.selected = event.target.checked;
			if (event.target.checked) {
				selectedRowsId.push(row.id);
			}
		});
		if (event.target.checked) {
			tempSelectedRows = tableDataList;
		}
        console.log(tempSelectedRows)
        setSelectedRows(tempSelectedRows);
       
		setTableDataList(tableDataList);
		
        reqRows = tempSelectedRows

		let inputElements: any = document.getElementsByClassName('row-checkbox');
        
		for (let i = 0; i < inputElements.length; i++) {
			if (inputElements[i] instanceof HTMLInputElement) {
				inputElements[i].checked = event.target.checked;
			}
		}
	}

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: string) => {
        searchScheduleFunc(requestBody, value, pageSize);
	};
	const handlepageSizeChange = (event: any) => {
		searchScheduleFunc(requestBody, page, event.target.value);
	};
    
    const handleOnClose  = (event: any, index: number, autoValue?: any) => {
        if (autoValue == 'selectOption') {
            const { target: { textContent } } = event;
            const filterData = [...filterCriteria];
            filterData[index]['keywords'] = textContent;
            if (textContent) {
                jobDataList.filter((job: any) => {
                    if (job.JobName == textContent) {
                        filterData[index]['id'] = job.JobId;
                    }
                })
            }
            setSelectedJobName(false);
            filterData.filter((fil: any) => {
                console.log(fil.filterType, fil.keywords)            
                if (fil.filterType == 'Job Name' && fil.keywords) {
                    setSelectedJobName(true);
                }
            })
            setFilterCriteria(filterData);
        }
    }
    
    const handleChangeDynamicKeywords = (event: any, index: number, autoValue?: any) => {
        const { target: { value } } = event;
        const filterData = [...filterCriteria];
        filterData[index]['keywords'] = autoValue ? autoValue : value;
        if (autoValue) {
            jobDataList.filter((job: any) => {
                if (job.JobName == autoValue) {
                    filterData[index]['id'] = job.JobId;
                }
            })
        }
        setSelectedJobName(false);
        filterData.filter((fil: any) => {
            console.log(fil.filterType, fil.keywords)            
            if (fil.filterType == 'Job Name' && fil.keywords) {
                setSelectedJobName(true);
            }
        })
        setFilterCriteria(filterData);
    };

    const debouncedFetchOptions = useMemo(
        () => debounce(async (keyWord, value) => {
          jobNameListFunc(keyWord, value);
        }, 500),
        []
      );

    const onChangeJobNameInput = (event: any, index: number) => {
        const { target: { value } } = event;
        const valueTrim = value.trim();
        const filterData = [...filterCriteria];
        filterData[index]['keywords'] = valueTrim;
        setFilterCriteria(filterData);
        
        if (valueTrim && valueTrim.length > 0) {
            setSearchInputLoading(true);
            debouncedFetchOptions(filterCriteria[0].keywords, valueTrim);
        }
    }

    const onChangeTime = (event: any, index: number, type: any, subType: any) => {
        let selectedTime = moment(event);
        const filterData: any = [...filterCriteria];
        if (event && event._d)
        filterData[index][subType] = moment(event._d).utc().format('HH:mm');
        filterData[index][type] = selectedTime;
        setFilterCriteria(filterData);
    }

    const handleChangePlatform = (event: SelectChangeEvent<typeof platformName>) => {
      const { target: { value } } = event;
      setPlatformName(value);
    };

    const onHoverFilterEffect = (isOpen: boolean) => {
        setAddClassFilterHoverEffect(isOpen);
    }

    const handleChangeDynamicFilter = (event: any, index: number) => {
        const { target: { value } } = event;
        const filterData = [...filterCriteria];
        filterData[index].filterType = value;
        if (value == 'Days') {
            setHasDaysAdded(true);
            filterData[index].keywords = []
        } else if (value == 'Job Name') {
            filterData[index].id = index;
        } else if (value == 'Start Time') {
            setHasTimeAdded(true);
            filterData[index].fromTime = '';
            filterData[index].toTime = '';
            filterData[index].fromTimeValue = '';
            filterData[index].toTimeValue = '';
        }
        setFilterCriteria(filterData);
    };

    const handleAddFilter = (index: any) => {
        const newFilterData = {filterType: 'Job Name', operation: 'Equals to (=)', keywords: ''};
        filterCriteria.push(newFilterData);
        const insertedFilterCriteria = filterCriteria;
        setFilterCriteria([...insertedFilterCriteria]);
    }

    const handleRemoveFilter = (index: any) => {
        const filterData = [...filterCriteria]
        if (filterData[index].filterType == 'Days') {
            setHasDaysAdded(false);
        } else if (filterData[index].filterType == 'Start Time') {
            setHasTimeAdded(false);
        }
        filterData.splice(index, 1);
        setFilterCriteria([...filterData]);
    }

	const handleClear = () => {
        onHoverFilterEffect(false);
		setSelectedJobName(false);
        setSearchScheduleBtn(false);
        setHasDaysAdded(false);
        setHasTimeAdded(false);
        const filterData = [
            {filterType: 'Scheduler Name*', operation: 'Equals to (=)', keywords: 'All'},
            {filterType: 'Job Name', operation: 'Equals to (=)', keywords: '', id: 1}
        ]
        setFilterCriteria(filterData);
	};

	const handleOnSubmit = () => {
        let hasJobName = false, hasSchedulerType = false;
        
        let reqBody: any = {jobIds: [], days: [], startTime: []};

        filterCriteria.filter((filter: any)=> {
            if (filter.filterType == 'Scheduler Name*' && filter.keywords != '') {
                hasSchedulerType = true;
            } else if (filter.filterType == 'Job Name' && filter.keywords != '') {
                hasJobName = true;
                reqBody.jobIds.push(filter.id)
            } else if (hasSchedulerType && hasJobName && filter.filterType == 'Days' && filter.keywords != '') {
                reqBody.days = filter.keywords.includes('All') ? 'all' : filter.keywords.map((d: string) => d.substring(0,2).toLowerCase())
            } else if (hasSchedulerType && hasJobName && filter.filterType == 'Start Time' && filter.fromTimeValue != '' && filter.toTimeValue != '') {
                reqBody.startTime = [filter.fromTimeValue, filter.toTimeValue]
            }
        });

        if (hasJobName && hasSchedulerType) {
            onHoverFilterEffect(false);
            setSearchScheduleBtn(true);
            setRequestBody(reqBody);
            searchScheduleFunc(reqBody, '1', '100');
        }
	};

    const splitDays = (days: string) => {
        let dayNumber;
        if (days.includes('Sun')) {
            dayNumber = 0
        } else if (days.includes('Mon')) {
            dayNumber = 1
        } else if (days.includes('Tue')) {
            dayNumber = 2
        } else if (days.includes('Wed')) {
            dayNumber = 3
        } else if (days.includes('Thu') || days.includes('Thur')) {
            dayNumber = 4
        } else if (days.includes('Fri')) {
            dayNumber = 5
        } else if (days.includes('Sat')) {
            dayNumber = 6
        }
        return dayNumber;
    } 

    const addSchedulesToProject = () => {
        let reqBodySch: any = [];
        if (selectedRows.length) {
            selectedRows.filter((row: any) => {
                reqBodySch.push({
                    jobName: row.jobName,
                    jobId: row.jobId,
                    schedulerName: row.schedulerName,
                    startTime: Number(row.schedule.split(', ')[1].replace(':', '')),
                    days: splitDays(row.schedule),
                    normalCompletionTime: row.normalCompletionTime ? Number(row.normalCompletionTime.replace(/_/g, '0').replace(':','')) : null,
                    programId: Number(window.location.pathname.split('/')[3]),
                    projectId: Number(window.location.pathname.split('/')[4]),
                    platformId: platformName == 'Teradata' ? 1 : 2
                })
            });
            addScheduleToProjectFunc(reqBodySch);
        }
    }

    async function addScheduleToProjectFunc(reqBody: any) {
        setAddProjectLoading(true);
        setOpenModal({
            isOpen: true
        })
		const res = await linkAddScheduleToProject(reqBody);
        if (res.statusCode == 200) {
            setSuccessMsg(res.data.message);
            setAddProjectLoading(false);
        } else if (res.code == 'ERR_BAD_REQUEST') {
            console.log('else if')
            setSuccessMsg('Schedules already added!');
            setAddProjectLoading(false);
        }
	}

    async function jobNameListFunc(schedulerType: string, jobName?: string) {
		const res = await getTeradataJobNameList(schedulerType, jobName);
		if (res.success) {
            setJobDataList(res.data.jobNames)
            setSearchInputLoading(false);
            setJobNameList(res.data.jobNames.map((jn: any) => jn.JobName));
		}
	}

    function convertToTime(input: any) {
        // Convert the input to a string and pad with leading zeros if necessary
        let timeString = input.toString().padStart(4, '0');
        
        // Extract the hours and minutes
        let hours = timeString.slice(0, 2);
        let minutes = timeString.slice(2, 4);
        
        // Return the formatted time string
        return `${hours}:${minutes}`;
    }
    

    async function searchScheduleFunc(reqBody: any, pageNumber: string, pageSize: string) {
        setTableLoading(true);
		const res = await getSearchScheduleJobNameList(reqBody, pageNumber, pageSize);
		if (res.success) {
            
            let schListData: any = res.data.selectedSchedules.map((row: any, index: number) => ({ ...row, normalCompletionTime: '', selected: false, id: index }));
            const lastData = [schListData[0]];
            let normalCompletionData = await getNormalCompletionTime(res.data.selectedSchedules[0].jobId)
            if (normalCompletionData.data[0]?.Normal_Completion_Time && schListData.length) {
                let normalCompletionList: any = []
                 schListData.map((data: any) => {
                    normalCompletionList.push({
                        ...data,
                        normalCompletionTime: convertToTime(normalCompletionData.data[0].Normal_Completion_Time)
                    })
                })
                schListData = normalCompletionList
            }
            setTableLoading(false);
            setTableDataList(schListData);
            setPaginationData(res.data.page);
            setPage(res.data.page.currentPage);
            setPageSize(res.data.page.size);
		}
	}

    const handleModalClose = (data: any) => { 
        if (searchScheduleBtn) {
            handleAddMore()
        } else {
            setOpenModal({
                isOpen: false,
            })
        }
    }

    const handleModalExit = () => {
        setOpenModal({
            isOpen: false,
        })
    }

    const handleAddMore = () => {
        setSelectedRows([]);
        setOpenModal({
            isOpen: false,
        })
        handleClear();
    }

    useEffect(() => {
        console.log(moment('2024-08-15').format('ddd'))
        const filterData = [
            {filterType: 'Scheduler Name*', operation: 'Equals to (=)', keywords: 'All'},
            {filterType: 'Job Name', operation: 'Equals to (=)', keywords: '', id: 1}
        ]
        setFilterCriteria(filterData);
        const activeId = document.getElementById('root');
		if (window.location.pathname.includes('/data-pipeline/search-jobs')) {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
			}
		}
    }, [openModal])

    return (
        <div className="component-container search-jobs-wrapper">        
            
            <div className={addClassFilterHoverEffect ? 'search-jobs--form has-cursor-hover' : 'search-jobs--form'}
            onClick={(e) => handleTimePickerMenuCloseFromOutside(e)}
            >
                <Grid container spacing={0} className="inner-grid-parent mb-5">
                    <Grid item xs={2}>
                        <div className="label mb-20">Select Platform</div>
                    </Grid>
                    <Grid item xs={10}>
                        <FormControl className="custom-dropdown-menu custom-dropdown-menu--platform">
                            <Select
                            id="custom-dropdown-menu-1"
                            displayEmpty
                            disabled
                            value={platformName}
                            onChange={handleChangePlatform}
                            input={<OutlinedInput />}
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                return <em>Platform</em>;
                                } else if (selected.includes('Teradata')) {
                                    return <><img className="platform-icon" src={TeradataIcon} alt='' /> <span className='dd-title'>{selected}</span></>
                                } else if (selected.includes('Databricks')) {
                                    return <><img className="platform-icon" src={DatabricksIcon} alt='' /> <span className='dd-title'>{selected}</span></>
                                } else if (selected.includes('PowerBI')) {
                                    return <><img className="platform-icon" src={PowerBiIcon} alt='' /> <span className='dd-title'>{selected}</span></>
                                } else if (selected.includes('Azure')) {
                                    return <><img className="platform-icon" src={AzureIcon} alt='' /> <span className='dd-title'>{selected}</span></>
                                }
                            }}
                            MenuProps={MenuProps}
                            inputProps={{ 'aria-label': 'Without label' }}
                            >
                            {platformList.map((name) => (
                                <MenuItem key={name} value={name}> <img className="platform-icon" src={TeradataIcon} alt="" /> 
                                {name}</MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                
                {/* Dynamic filter */}
                <Grid container spacing={0} className="inner-grid-parent">
                    <Grid item xs={2}>
                        <div className="label">Filter Criteria</div>
                    </Grid>

                    <Grid item xs={10}>
                        { filterCriteria && filterCriteria.map((filter: any, idx: number) => (
                            <div key={filter.filterType+idx} className={idx == (filterCriteria.length - 1) ? '' : 'mb-20'}> 
                            { idx == 0 && <div className="form-label">Scheduler Name*</div> } 
                            { idx == 1 && <div className="form-label">Job Name</div> } 
                            { idx > 1 && 
                            <FormControl className="custom-dropdown-menu no-bg">
                                <Select
                                id={'custom-dropdown-menu-' + idx}
                                displayEmpty
                                value={filter.filterType}
                                onOpen={(e) => onHoverFilterEffect(true)}
                                onChange={(e) => {
                                    handleChangeDynamicFilter(e, idx);
                                }}
                                input={<OutlinedInput />}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                    return <em>Filter Criteria</em>;
                                    }

                                    return selected
                                }}
                                MenuProps={MenuProps}
                                inputProps={{ 'aria-label': 'Without label' }}
                                >
                                {filterCriteriaList.map((name) => (
                                    <MenuItem key={name+idx} value={name} 
                                    disabled={
                                        name == 'Days' && hasDaysAdded ? true :
                                        name == 'Start Time' && hasTimeAdded ? true : false
                                    }
                                    className={hasDaysAdded || hasTimeAdded ? 'hide-temp' : ''}
                                    >{name}</MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                            }

                            {/* <FormControl className="custom-dropdown-menu no-bg">
                                <Select
                                id="custom-dropdown-menu-3"
                                displayEmpty
                                value={filter.operation}
                                onChange={(e) => {
                                    handleChangeDynamicOperation(e, idx);
                                }}
                                input={<OutlinedInput />}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                    return <em>Operation</em>;
                                    }

                                    return selected
                                }}
                                MenuProps={MenuProps}
                                inputProps={{ 'aria-label': 'Without label' }}
                                >
                                {operationList.map((name) => (
                                    <MenuItem key={name} value={name}> {name}</MenuItem>
                                ))}
                                </Select>
                            </FormControl> */}
                            
                            { idx == 0 && 
                                <FormControl className="custom-dropdown-menu no-bg">
                                    <Select
                                    id={'custom-dropdown-menu-sch-1'}
                                    displayEmpty
                                    value={filter.keywords}
                                    onChange={(e) => {
                                        handleChangeDynamicKeywords(e, idx);
                                    }}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                        return <em>Select Scheduler</em>;
                                        }

                                        return selected
                                    }}
                                    MenuProps={MenuProps}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                    <MenuItem key='all' value='All'>All</MenuItem>
                                    <MenuItem key='autosys' value='AutoSys'>AutoSys</MenuItem>
                                    <MenuItem key='control-m' value='Control-M'>Control-M</MenuItem>
                                    </Select>
                                </FormControl>
                            }
                            
                            { filter.filterType == 'Job Name' &&  <>
                                <FormControl className="job-name-dropdown">
                                <Autocomplete                 
                                options={jobNameList}
                                noOptionsText={searchInputLoading ? 'Loading...' : searchInputLoading == false ? 'No data' : 'Search Jobs'}
                                value={filter.keywords}
                                onClose={(e, val) => handleOnClose(e, idx, val)}
                                // onInputChange={(e,val) => handleChangeDynamicKeywords(e, idx, val)}
                                onChange={(e,val) => handleChangeDynamicKeywords(e, idx, val)}
                                renderInput={(params) => <TextField {...params} placeholder="Search Job Name" onChange={(e) => onChangeJobNameInput(e, idx)} />}
                                />
                                </FormControl>
                            </>}

                            { filter.filterType == 'Days' &&
                            <FormControl className="custom-dropdown-menu no-bg">
                                <Select
                                id={'custom-dropdown-menu-days-1'}
                                displayEmpty
                                multiple
                                value={filter.keywords}
                                onChange={(e) => {
                                    handleChangeDynamicKeywords(e, idx);
                                }}
                                input={<OutlinedInput />}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                    return <em>All Days</em>;
                                    }

                                    return selected.join(', ')
                                }}
                                MenuProps={MenuProps}
                                inputProps={{ 'aria-label': 'Without label' }}
                                >
                                {daysList.map((name: any) => (
                                    <MenuItem key={name} value={name}>
                                    <Checkbox checked={filter.keywords.indexOf(name) > -1} />
                                        <ListItemText primary={name} />
                                    </MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                            }
                            
                            { filter.filterType == 'Start Time' && <>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <TimePicker
                                        label="From"
                                        orientation="landscape"
                                        showToolbar={true}
                                        toolbarTitle={'From'}
                                        className="startTimeFrom"
                                        ampm={false}
                                        value={filter.fromTime}
                                        onChange={(e) => {onChangeTime(e, idx, 'fromTime', 'fromTimeValue')}}
                                        renderInput={(params: any) => (
                                            <TextField {...params} value={filter.fromTime} name="startTimeFrom" />
                                        )}
                                    />
                                     <TimePicker
                                        label="To"
                                        orientation="landscape"
                                        showToolbar={true}
                                        toolbarTitle={'To'}
                                        className="startTimeTo"
                                        ampm={false}
                                        value={filter.toTime}
                                        onChange={(e) => {onChangeTime(e, idx, 'toTime', 'toTimeValue')}}
                                        renderInput={(params: any) => (
                                            <TextField {...params} value={filter.toTime} name="startTimeTo" />
                                        )}
                                    />
                            </LocalizationProvider>
                            </>}

                            {/* { idx > 1 && <FormControl>
                                <input className="form-input" type="text" placeholder="Type Keywords" onInput={(e) => {
                                    handleChangeDynamicKeywords(e, idx)
                                }} />
                            </FormControl>
                            } */}
                            
                            { idx != 0 && idx != 1 && 
                            <FormControl className="icon-symbol-remove" 
                                onClick={(e) => {
                                    handleRemoveFilter(idx);
                                }}
                            >
                                <img src={PlusSymbolIcon} alt='' />
                            </FormControl>
                            }

                            { idx == 1 && 
                                <FormControl className="icon-symbol-plus"
                                onClick={(e) => {
                                    handleAddFilter(idx);
                                }}
                            >
                                <img src={PlusSymbolIcon} alt='' />
                            </FormControl>
                            }

                            { idx == (filterCriteria.length - 1) && <>
                                <div className="cta-actions">
                                        <Button className="button-clear"  onClick={handleClear}>
                                            Clear
                                        </Button>
                                        <Button className="button-search" disabled={selectedJobName ? false : true} onClick={handleOnSubmit}>
                                            Submit Search
                                        </Button>
                                    </div>
                                </>
                            }
                            </div>
                            ))}
                    </Grid>
                </Grid>
            </div>

            <div className="search-jobs--result">
                <div className="search-jobs--result-header" onClick={(e) => handleTimePickerMenuCloseFromOutside(e)}>
                    <h3 className="title">Select Schedules</h3>
                    { searchScheduleBtn && tableDataList && tableDataList.length > 0 &&
                        <Button className={`${selectedRows.length ? 'add-schedules-btn' : 'add-schedules-btn disabled'}`} variant="contained" onClick={addSchedulesToProject}>Add Schedules to Project</Button>
                    }
                </div>
                { searchScheduleBtn ? (<div className="search-jobs--table">
                    <Grid>
                        {!tableLoading && tableDataList.length ? (
                            <CustomizedDataGrid
                                tableColumns={getColumns()}
                                tableData={tableDataList}
                                tableType="add-schedules"
                                defaultColumnNames={defaultColumnNames}
                                page={page}
                                pageSize={pageSize}
                                paginationData={paginationData}
                                listLength={tableDataList.length}
                                onHeaderSelectAll={handleHeaderSelectAll}
                                handlepageSizeChange={handlepageSizeChange}
                                handlePageChange={handlePageChange}
                                isDateRangeFilter={false}
                                isDisabled={false}
                                isFetching={true}
                                />
                            ) : !tableLoading && tableDataList.length == 0 ? <><p className="text-center">No result found</p></> : ( <Loader /> )
                        }
                    </Grid>
                </div>) : (
                    <><br /><br /><p className="text-center">Data will be displayed based on selected filters.</p></>
                )
                }
                <br />
            </div>
            <Dialog open={openModal.isOpen} onClose={handleModalClose} fullWidth={false} maxWidth="md" className="success-modal">
                <div className="modal-wrapper">
                    <div className="modal-header">Confirmation
                        { !searchScheduleBtn && <span className="close" onClick={handleModalClose}>x</span> }
                    </div>
                    <div className="modal-content">
                        { addProjectLoading && <div className="loading-wrapper">Loading...</div>}
                        { !addProjectLoading && <div className="success-content text-center">
                            <p>{ successMsg }</p>
                            {successMsg != 'Schedules already added!' && <div className="actions">
                                <Button className="close" onClick={handleModalExit}>
                                    <Link to={`/data-pipeline/projects/${window.location.pathname.split('/')[3].replace(/%20/g, " ")}/${window.location.pathname.split('/')[4].replace(/%20/g, " ")}/${window.location.pathname.split('/')[5].replace(/%20/g, " ")}/${window.location.pathname.split('/')[6].replace(/%20/g, " ")}`}>
                                        SAVE & EXIT
                                    </Link>
                                    </Button>
                                <Button className="add-more" onClick={handleAddMore}>ADD MORE</Button>
                            </div>}
                            {successMsg == 'Schedules already added!' && <div className="actions">
                                <Button className="close" onClick={handleModalClose}>Close</Button>
                            </div>}
                        </div>}
                    </div>
                </div>
		    </Dialog>
        </div>
    )
}

export default AddSchedules