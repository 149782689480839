import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Collapse, Dialog, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, MenuProps, Select, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DataPipelineModal } from './modal-component';
import ExpandMoreIcon from '../../assets/down-arrow.svg'
import { ReactComponent as EditIcon } from '../../assets/edit-img.svg'
import { ReactComponent as DeleteIcon } from '../../assets/delete-img.svg'
import { ReactComponent as ViewIcon } from '../../assets/view-detail-img.svg'
import { ReactComponent as ViewHighlightIcon } from '../../assets/icon-view-highlight.svg'
import ExportIcon from '../../assets/export-img.svg'
import NoPrgramFound from '../../assets/no-program-found.svg'
import useStyles from './data-pipeline-styles';
import '../DataPipeline/dataPipeline.scss';
import { getProgramList, getProjectListByProgram, role } from '../../service/service';
import { CustomizedDataGrid } from '../customized_data_grid';
import Loader from '../Loader/loader';
import { CellRendererProps } from 'react-data-grid';
import { Link } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import { ReactComponent as MoreIcon } from '../../assets/more-vertical.svg';

const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));

const ProgramList = () => {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [accordionActiveId, setAccordionActiveId] = useState<any>(null);
  const handleThreeDotsClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    event.preventDefault()
    event.stopPropagation();
    setAccordionActiveId(index);
    setAnchorEl(event.currentTarget);
  };
  const handleThreeDotsClose = (event: any) => {
    event.preventDefault()
    event.stopPropagation();
    setAccordionActiveId(null);
    setAnchorEl(null);
  };

    const classes = useStyles();

    const [programList, setProgramList] = useState<any>([])
    const [projectList, setProjectList] = useState<any>([])
    const [openModal, setOpenModal] = useState<any>({isOpen: false})
    const [loading, setLoading] = useState(false);
    const [filterInfo, setFilterInfo] = useState([]);
    const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
    const [filterData, setFilterData] = useState<any[]>([]);
    const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
    const [searchDetails, setFilterSearchInfo] = useState([]);
    const [currentProgram, setCurrentProgram] = useState<any>({});

    useEffect(() => {
        const activeId = document.getElementById('root');
        console.log(window.location.pathname)
		if (window.location.pathname.includes('/data-pipeline/programs')) {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
			}
		}
		getProgramData()

	}, [openModal]);

    const getProgramData = async () => {
        setLoading(true)
        let data: any = await getProgramList()
        setLoading(false)
        setProgramList(data.data.programData)
    }

    const handleEditClose = useCallback ((data: any) => {
        console.log("CLOSEE", data)
        setOpenModal({
            isOpen: false,
        })
    }, [])


    const onCreateProgramClick = useCallback (() => {
        setOpenModal({
            isOpen: true,
            type: 'Program',
            action:'CREATE',
            data: ''
        })
    }, [])

    const handleAction = (type: string, data: any, e:any) => {
      console.log(data)
      handleThreeDotsClose(e)
        e.preventDefault()
        e.stopPropagation();
        switch (type) {
            case 'edit':
                setOpenModal({
                    isOpen: true,
                    type: 'Program',
                    action: 'EDIT',
                    data
                })
                break;
                case 'delete':
                    setOpenModal({
                        isOpen: true,
                        type: 'Program',
                        action: 'DELETE',
                        data
                    })
                    break;
                 case 'view':
                    window.location.href = `/data-pipeline/programs/${data.Program_ID}/${data.program_Name}`
                    break;
        
            default:
                break;
        }
    }

    const getProjectsByProgram =  async(data: any, e: any, page: any = 1) => {
        e.preventDefault()
        e.stopPropagation();
        setLoading(true)
        setCurrentProgram(data)
        let result = await getProjectListByProgram(data.Program_ID, page, pageSize)
        setTimeout(() => {
            let table = document.getElementById(`project_list_details${data.Program_ID}`)
            if (table) {
                const rdgElement = table.querySelector('.rdg') as HTMLElement | null;
                    if (rdgElement) {
                        let jobsLength = result.data.projectList.length;
                        let height = 80;
                        if (jobsLength === 1) height = jobsLength * 80;
                        else if (jobsLength > 1 && jobsLength <=3) height = jobsLength * 65;
                        else if (jobsLength > 3 && jobsLength <=5) height = jobsLength * 55;
                        else if (jobsLength > 5 && jobsLength <=10) height = jobsLength * 50;
                        else if (jobsLength > 10 && jobsLength <=15) height = jobsLength * 40;
                        else height = jobsLength * 30;
                    rdgElement.style.height = `${height}px`
                    console.log("HEREE", height)
                }
            }
        }, 100);
        setPaginationData(result.data.page);
        setLoading(false)
        setProjectList(result.data.projectList)
    }

    const defaultColumnNames = [
        'Project', 'Project Owner', 'Description', 'Action'
    ]

    const handleTableAction = (params: any, type: string) => {
        switch (type) {
            case 'edit':
                
                break;
            case 'view':
                
            break;
            case 'delete':
                
            break;
            default:
                break;
        }
    }

    const getColums =() => {
        return [{
            key: 'Project_Name',
            name: 'Project',
            formatter(props: any) {
                return <div className='has-ellipsis'  title={props.row.Project_Name}>{props.row.Project_Name ? props.row.Project_Name : '--'}</div>
            },
            minWidth: 240,
            maxWidth: 450
            
        },
        {
            key: 'Project_Owner',
            name: 'Project Owner',
            formatter(props: any) {
                return <div className='has-ellipsis'  title={props.row.Project_Owner}>{props.row.Project_Owner ? props.row.Project_Owner.includes('null') ? props.row.Project_Owner.replace('(null)', '') : props.row.Project_Owner  : '--'}</div>
            },
            minWidth: 240,         
        },
        {
            key: 'Project_Description',
            name: 'Description',
            formatter(props: any) {
                return <div className='has-ellipsis' title={props.row.Project_Description}>{props.row.Project_Description ? props.row.Project_Description : '--'}</div>
            },
            minWidth: 460,
            maxWidth: 760      
        },
        {
            key: 'action',
            name: 'Action',
            resizable: false,
            width: 80,
            formatter: (props: any) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                     <Link to={`/data-pipeline/projects/${props.row.Program_ID}/${props.row.Project_Id}/${selectedProgramName}/${props.row.Project_Name}`} className="titleLink">
                        <ViewIcon title='Click to view' className='mr-5 table-icon' />
                    </Link>
                  {/* <Button className='action-cta-link' onClick={() => handleTableAction(params.row, 'edit')} title='Click to edit'>
                    <EditIcon className='icon-actions' />
                  </Button>
                  <Button className='action-cta-link' onClick={() =>handleTableAction(params.row, 'delete')} title='Click to Delete'>
                    <DeleteIcon className='icon-actions' />
                  </Button> */}
                   {/* <ViewIcon className='action-cta-link icon-actions' /> */}
                  
                </div>
              ),
          }]
    }

    const handleFilter = (e: any) => {
        console.log("FILTER", e)
    }

    const handleSearch = (e: any) => {
        console.log("FILTER", e)
    }

    const handleSorting = (e: any) => {
        console.log("FILTER", e)
    }

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		getProjectListByProgram(currentProgram.Program_ID, value, pageSize);
	};

    const handlepageSizeChange = (e: any) => {
        console.log("FILTER", e)
    }

    const [expanded, setExpanded] = useState<any>(false);
    const [selectedProgramName, setSelectedProgramName] = useState<string>('');

    const handleChange = (panel: any, programName: string) => (event: any, isExpanded: any) => {
        
        event.preventDefault()
        event.stopPropagation();
        setSelectedProgramName(programName);
      setExpanded(isExpanded ? panel : false);
      console.log('change')
    };

return (
 <div className="dashboard-panel-dataestate job-schedules-wrap">    
    {(role === 'Data Operations Admin' || role === 'PepWise System User') && (
			<div className="service-sector-filter w-190">
				{/* Code for sector filter */}
				<Stack className="sector-filter-dataestate">
					<>
						<span className="sector-cta">
                        <Button variant="contained" onClick={onCreateProgramClick} className={classes.createBtn}>
							CREATE PROGRAM
						</Button>
						</span>
					</>
					
				</Stack>
		  </div>
    )}
        <Dialog open={openModal.isOpen} onClose={handleEditClose} fullWidth={true} maxWidth="md" className="edit-job-modal">
				<DataPipelineModal handleEditClose={handleEditClose} programData={openModal}/>
		</Dialog>
       {
        programList.length ?
        <div className='d-flex-main m-7'>
          <div className='w-40 fw700'>Programs</div>
          <div className='w-60 fw700'>Total Projects</div>
        </div> : ''
       }
       
      <div className='program-list-container'>
      {
            
            programList.length ? 
                 programList.map((program: any, index: number) => {
                    return  <div key={index} className='my-10'>
                    <Accordion key={index} className='accordion-list-wrapper' expanded={expanded === index} onChange={handleChange(index, program.program_Name)}>
                       <AccordionSummary
                           className='acc-summ'
                           expandIcon={<img className='icon' src={ExpandMoreIcon} />}
                           aria-controls="panel1-content"
                           onClick={(e) => getProjectsByProgram(program, e)}
                           id={`panel${index}-header`}
                        >
                             <div className='d-flex-main'>
                                <div className='w-40 panel-typo'>
                                  <Typography>{program.program_Name}</Typography>
                                </div>
                                <div className='w-60'>
                                 <div className='justify-btw-items-center'>
                                  <div className='count-box fw500'>
                                      <span>{program.ProjectCount}</span>
                                  </div>
                                  <div className='actions-item'>
                                    {/* <EditIcon title='Click to edit' onClick={(e) => handleAction('edit', program, e)} className='mr-5 icon icon-actions' />
                                    <DeleteIcon title='Click to delete' onClick={(e) => handleAction('delete', program, e)} className='mr-5 icon icon-actions' /> */}
                                    <Link to={`/data-pipeline/programs/${program.Program_ID}/${program.program_Name}`} className="titleLink">
                                        <ViewHighlightIcon title='Click to View' className='mr-5 icon view-icon' />
                                    </Link>
                                    {(role === 'Data Operations Admin' || role === 'PepWise System User') && (<>
                                    <Button
                                        aria-controls={open ? 'demo-customized-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        variant="contained"
                                        disableElevation
                                        onClick={ (e) => handleThreeDotsClick(e, index) }
                                        className='actions-btn'
                                    >
                                        <MoreIcon />
                                    </Button>
                                    <StyledMenu
                                        className="menu-list-wrapper"
                                        MenuListProps={{
                                        'aria-labelledby': 'demo-customized-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={accordionActiveId == index}
                                        onClose={handleThreeDotsClose}
                                    >
                                        <MenuItem title='Click to edit' onClick={(e) => handleAction('edit', program, e)}>
                                        <EditIcon className='mr-5 icon icon-actions' /> <span className="text">Edit</span>
                                        </MenuItem>
                                        <MenuItem title='Click to delete' onClick={(e) => handleAction('delete', program, e)}>
                                        <DeleteIcon className='mr-5 icon icon-actions' /> <span className="text">Delete</span>
                                        </MenuItem>
                                    </StyledMenu>
                                    </>
                                    )}
                                      {/* <span style={{margin: '0px 20px'}}> */}
                                      {/* <img onClick={(e) => handleAction('export', program, e)} className='icon' src={ExportIcon} alt="" /> */}
                                      {/* </span> */}
                                  </div>
                                  </div>
                                </div>
                             </div>
                       </AccordionSummary>
                       {
                        program.ProjectCount > 0 ? <AccordionDetails className='proj-table'>
                        <div className="programlist_tabledata mt20 padl-10" id={`project_list_details${program.Program_ID}`}>
				<Grid>
					{!loading ? (
						<CustomizedDataGrid
							tableColumns={getColums()}
							tableData={projectList}
							tableType="project-list"
							defaultColumnNames={defaultColumnNames}
							onSorting={handleSorting}
							currSorting={sortingInfo}
							filterDataOptions={filterData}
							searchDataValues={searchDetails}
							onFilterCheck={handleFilter}
							currFilter={filterInfo}
							onFilterSearch={handleSearch}
							page={page}
							pageSize={pageSize}
							paginationData={paginationData}
							listLength={projectList.length}
							handlepageSizeChange={handlepageSizeChange}
							handlePageChange={handlePageChange}
							isFetching={loading}
							showExport={false}
							fileName={`Projects.xlsx`}
							isDateRangeFilter={false}
							isDisabled={false}
						/>
					) : (
						<Loader />
					)}
				</Grid>
			</div>
                     </AccordionDetails>: ''
                       }
                    </Accordion>
                  </div>
                 })
             : !loading ?  <div className='flex-center h-80'>
                 <img className='image' src={NoPrgramFound} alt="" />
                 <p className='no-program-txt'>Create Program</p>
             </div> :  <div className='flex-center h-80'>
                
                 <p className='no-program-txt'>Loading...</p>
             </div>
         }
      </div>
      
       
  </div>
)
}

export default ProgramList