import PrivateRoute from 'utils/privateRoute';
import Catalogues from 'components/DMF/Catalogues';
import HealthSummary from 'components/DMF/HealthSummary';

const DataManagementRoutes = [
	{
		key: 'data_mgmt_health_summary',
		route: '/dataManagementFramework/healthSummary/dashboard',
		component: (
			<PrivateRoute
				Component={HealthSummary}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'data_mgmt_catalg_dash',
		route: '/dataManagementFramework/Catalogues/dashboard',
		component: (
			<PrivateRoute
				Component={Catalogues}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	}
];

export default DataManagementRoutes;
