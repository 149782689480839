import moment from 'moment-timezone';
import { Chip } from '@mui/material';
export const getDefaultRuleData = (statusParam: string | null) => {
	let title = 'Extract Failures',
		status = {} as any,
		column: any = [],
		columnNames = [];
	function formatDuration(duration: any) {
		const durationInMinutes = parseInt(duration); // Assuming duration is in minutes
		const hours = Math.floor(durationInMinutes / 60);
		const minutes = durationInMinutes % 60;

		let formattedDuration = '';
		if (hours > 0) {
			formattedDuration += hours === 1 ? '1 Hour ' : `${hours} Hours `;
		}
		if (minutes > 0 || formattedDuration === '') {
			formattedDuration += minutes === 0 ? '0 Minute' : `${minutes} Minute${minutes === 1 ? '' : 's'}`;
		}
		return formattedDuration.trim();
	}
	const renderStatus = (status: any) => {
		switch (status) {
			case 'SUCCESS':
			case 'Successful':
				return <Chip label={status} color="success" />;
			case 'Failed':
			case 'Failure':
				return <Chip label={status} color="secondary" />;
			case 'NOT STARTED':
			case 'Not Started':
				return <Chip label={status} style={{ backgroundColor: '#1B1E35CC', color: '#71758F' }} />;
			default:
				return <Chip label={status} style={{ backgroundColor: '#299BFF26', color: '#299BFF' }} />;
		}
	};
	switch (statusParam) {
		case 'Extract Failures':
			title = 'Extract Failures';
			status = 'Extract Failures';
			columnNames = [
				'Job Name',
				'Job Id',
				'Status',
				'Reason',
				'Type',
				'Content Type',
				'Project Name',
				'Average Execution Time',
				'Last Execution Duration',
				'Instance'
			];
			column = [
				{ key: 'name', name: 'Job Name', minWidth: 150 },
				{ key: 'lastJobId', name: 'Job Id', minWidth: 100 },
				{
					key: 'jobStatus',
					name: 'Status',
					minWidth: 100,
					formatter(props: any) {
						const status = props.row.jobStatus;
						return renderStatus(status);
					}
				},
				{
					key: 'reason',
					name: 'Reason',
					minWidth: 250,
					formatter(props: any) {
						return (
							<div title={props.row.reason} className="has-ellipsis">
								{props.row.reason}
							</div>
						);
					}
				},
				{ key: 'jobType', name: 'Type', minWidth: 100 },
				{ key: 'project', name: 'Project Name', minWidth: 150 },
				{
					key: 'avgTime',
					name: 'Average Execution Time',
					minWidth: 200,
					formatter(props: any) {
						const avgTime = props.row.avgTime;
						return formatDuration(avgTime);
					}
				},
				{
					key: 'lastRunDuration',
					name: 'Last Execution Duration',
					minWidth: 200,
					formatter(props: any) {
						const lastRunDuration = props.row.lastRunDuration;
						return formatDuration(lastRunDuration);
					}
				},
				{ key: 'instance', name: 'Instance', minWidth: 100 }
			];
			break;
		case 'Orphan Extracts':
			title = 'Orphan Extracts';
			status = 'Orphan Extracts';
			columnNames = [
				'Instance',
				'Project',
				'Workbook',
				'Extract',
				'View',
				'Flow',
				'Last Used Date',
				'Last Refereshed Date'
			];
			column = [
				{ key: 'instance', name: 'Instance', minWidth: 150 },
				{ key: 'project', name: 'Project', minWidth: 100 },
				{ key: 'name', name: 'Workbook', minWidth: 100 },
				{ key: 'extract', name: 'Extract', minWidth: 100 },
				{ key: 'view', name: 'View', minWidth: 100 },
				{ key: 'flow', name: 'Flow', minWidth: 100 },
				{
					key: 'lastUpdatedAt',
					name: 'Last Used Date',
					minWidth: 150,
					formatter(props: any) {
						const lastUpdatedAt = props.row.lastUpdatedAt;
						if (lastUpdatedAt !== 'undefined') {
							return moment(lastUpdatedAt).format('MM-DD-YY');
						}
						return '';
					}
				},
				{
					key: 'lastRefreshedAt',
					name: 'Last Refereshed Date',
					minWidth: 180,
					formatter(props: any) {
						const lastRefreshedAt = props.row.lastRefreshedAt;
						if (lastRefreshedAt !== 'undefined') {
							return moment(lastRefreshedAt).format('MM-DD-YY');
						}
						return '';
					}
				}
			];
			break;
		case 'Orphan Workbooks':
			title = 'Orphan Workbooks';
			status = 'Orphan Workbooks';
			columnNames = [
				'Instance',
				'Project',
				'Workbook',
				'Extract',
				'View',
				'Flow',
				'Last Used Date',
				'Last Refereshed Date'
			];
			column = [
				{ key: 'instance', name: 'Instance', minWidth: 150 },
				{ key: 'project', name: 'Project', minWidth: 100 },
				{ key: 'name', name: 'Workbook', minWidth: 100 },
				{ key: 'extract', name: 'Extract', minWidth: 100 },
				{ key: 'view', name: 'View', minWidth: 100 },
				{ key: 'flow', name: 'Flow', minWidth: 100 },
				{
					key: 'lastUpdatedAt',
					name: 'Last Used Date',
					minWidth: 150,
					formatter(props: any) {
						const lastUpdatedAt = props.row.lastUpdatedAt;
						if (lastUpdatedAt !== 'undefined') {
							return moment(lastUpdatedAt).format('MM-DD-YY');
						}
						return '';
					}
				},
				{
					key: 'lastRefreshedAt',
					name: 'Last Refereshed Date',
					minWidth: 180,
					formatter(props: any) {
						const lastRefreshedAt = props.row.lastRefreshedAt;
						if (lastRefreshedAt !== 'undefined') {
							return moment(lastRefreshedAt).format('MM-DD-YY');
						}
						return '';
					}
				}
			];
			break;
		default:
			title = 'Extract Failures';
			status = 'Extract Failures';
			columnNames = [
				'Job Name',
				'Job Id',
				'Status',
				'Reason',
				'Type',
				'Content Type',
				'Project Name',
				'Average Execution Time',
				'Last Execution Duration',
				'Instance'
			];
			column = [
				{ key: 'name', name: 'Job Name', minWidth: 150 },
				{ key: 'lastJobId', name: 'Job Id', minWidth: 100 },
				{ key: 'jobStatus', name: 'Status', minWidth: 100 },
				{ key: 'reason', name: 'Reason', minWidth: 250 },
				{ key: 'jobType', name: 'Type', minWidth: 100 },
				{ key: 'project', name: 'Project Name', minWidth: 100 },
				{ key: 'avgTime', name: 'Average Execution Time', minWidth: 100 },
				{ key: 'lastRunDuration', name: 'Last Execution Duration', minWidth: 100 },
				{ key: 'instance', name: 'Instance', minWidth: 100 }
			];
			break;
	}
	return { title, status, columnNames, column };
};
