import React, { useEffect, useRef, useState } from 'react';
import {
	Button,
	Grid,
	Stack,
	ListItemText,
	FormControlLabel,
	InputAdornment,
	TextField,
} from '@mui/material';
import '../TableauObs/Summary/Summary.scss';
import { ReactComponent as FilterIcon } from '../../assets/ml_summary_filter.svg';
import { FetchFinOpsDetails, getDecodedToken, getSector, FetchFinOpsDetailsSummary, FetchFinOpsResourceSummary, getDetailSummarySector, FetchFinOpsCostKpi } from '../../service/service';
import useStyles from '../jobs/jobs_styles';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import '../FineOps/finops.scss';
import ReactECharts from 'echarts-for-react';
import '../DataEstate/data-estate-dashboard.scss';
import { getSectorSql } from '../../service/service';

import type { Column } from 'react-data-grid';
import { CustomizedDataGrid } from '../customized_data_grid';
import Loader from '../Loader/loader';
import { Rotate90DegreesCcw } from '@mui/icons-material';
import { CellExpanderFormatter } from '../cell_expander_formatter';
import { Link, useSearchParams } from 'react-router-dom';
import searchIcon from '../../assets/akar-icons_search-1.svg';

const DetailsSummary = () => {	
	interface CheckedList {
		sectors: string[];
		program: string[];
		team: string[];
		consumedService: string[];
		resourceType: string[];
		resourceGroup: string[];
		resource: string[];
		project: string[];
		year: string[];
		month: string[];
	}
	const [searchParams, setSearchParams] = useSearchParams();
	const [loading, setLoading] = useState(false);
	const [filterListLoader, setFilterListLoader] = useState(false);
	const [DetailsCloudCost, SetDetailsCloudCost] = useState('-')
    const [grafanaSummaryByResourceType, setGrafanaSummaryByResourceType] = useState<any>()	
	const [grafanaSummaryByResourceTypeLoading, setGrafanaSummaryByResourceTypeLoading] = useState(true);
	const [cloudCost, setCloudCost] = useState('');

	const [programInputText, setProgramInputText] = useState('');
	const [consumedServiceInputText, setConsumedServiceInputText] = useState('');
	const [resourceGroupInputText, setResourceGroupInputText] = useState('');
	const [resourceInputText, setResourceInputText] = useState('');
	const [projectInputText, setProjectInputText] = useState('');

	const [groupingInfo, setGroupingInfo] = useState<any>({});

	const [grafanaAzureCostLoading, setGrafanaAzureCostLoading] = useState(true);
    const [grafanaAzureCostOptions, setGrafanaAzureCostOptions] = useState<any>();

	function formatNumber(number:any) {
		if (number != null && !isNaN(number) && number !== 0) {
			return Number(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		} else {
			return "0";
		}
	}

	function formatNumberOne(number:any) {
		if (number != null && !isNaN(number) && number !== 0) {
			return Number(number).toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
		} else {
			return "0";
		}
	}

	function formaNumberTwo(number:any) {
		if (number != null && !isNaN(number)) {
			return Number(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		} else {
			return "0.00";
		}
	}

	const callFetchFinOpsCostKpi = (groupFilters?: any) => {
		SetDetailsCloudCost('-');

		setGrafanaSummaryByResourceTypeLoading(true);
		FetchFinOpsCostKpi(groupFilters)
			.then((res) => {
				
				// let count = res?.data?.totalCost;
				// if (count >= 1000000) {
				// 	SetDetailsCloudCost(formaNumberTwo((count/1000000)));
				// 	setCloudCost('MM');
				// } else {
				// 	SetDetailsCloudCost(formaNumberTwo((count / 1000)));
				// 	setCloudCost('K');
				// }

				let data = res.data.totalCost.YTDCost;
				let totalSum = res.data.totalCost.totalSum;

				// Transform data into ECharts series format
				let seriesData = Object.keys(data).map((team: string) => ({
				name: team,
				value: data[team],
				percentage: ((data[team] / totalSum) * 100).toFixed(2),
				formattedName: `${((data[team] / totalSum) * 100).toFixed(2)}%`
				})).sort((a: any, b: any) => a.name.localeCompare(b.name));

				const others = seriesData.filter((item: { name: string; }) => item.name === 'Others');
				seriesData = seriesData.filter((item: { name: string; }) => item.name !== 'Others');
				seriesData = seriesData.concat(others);

				const optionBar = {
				tooltip: {
					backgroundColor: '#003771', 
					textStyle: {
					color: '#A9ADCB', 
					},
					formatter: function (params: any) {
					let value = params.value;
					let formattedValue;
					if (value >= 1000000) {
						formattedValue = `${formatNumber(value / 1000000)}MM`;
					} else {
						formattedValue = `${formatNumber(value / 1000)}K`; 
					}
					return `${params.name} : ${params.percent}%  ($${formattedValue})`;
					},
					position: 'inside'
				},
				// legend: {
				// 	bottom: '40',
				// 	left: 'center',
				// 	data: seriesData.map((item: any) => item.name),
				// 	textStyle: {
				// 	color: '#A7ADCB',
				// 	fontSize: 12
				// 	}
				// },
				series: [
					{
					type: 'pie',
					radius: ['70%', '50%'],
					center: ['50%', '50%'],
					label: {
						show: false
					},
					data: seriesData.map((item: any) => ({
						name: item.name,
						value: item.value
					})),
					emphasis: {
						itemStyle: {
						shadowBlur: 10,
						shadowOffsetX: 0,
						shadowColor: 'rgba(0, 0, 0, 0.5)'
						}
					}
					}
				],
				graphic: {
					type: 'text',
					left: 'center',
					top: 'center',
					bottom: 'center',
					right: 'center',
					style: {
					text: `$${(totalSum / 1000000).toFixed(2)} MM`,
					textAlign: 'center',
					fill: '#fff',
					fontSize: 20,
					fontWeight: 'bold'
					}
				},
				// graphic: [
				// 	{
				// 		type: 'text',
				// 		left: 'center',
				// 		top: 'center',
				// 		style: {
				// 			text: 'Total Cost',
				// 			textAlign: 'center',
				// 			fill: '#fff',
				// 			fontSize: 14, 
				// 			fontWeight: 'normal'
				// 		}
				// 	},
				// 	{
				// 		type: 'text',
				// 		left: 'center',
				// 		style: {
				// 			text: `$${(totalSum / 1000000).toFixed(2)} MM`,
				// 			textAlign: 'center',
				// 			fill: '#fff',
				// 			fontSize: 20,  
				// 			fontWeight: 'bold',
				// 			display: 'inline'
				// 		},
				// 		top: 'center',
				// 		bottom: 'center',  
				// 	}
				// ],
				textStyle: {
					fontFamily: "Montserrat"
				}
				};

				setGrafanaAzureCostOptions(optionBar);
				setGrafanaAzureCostLoading(false);

				const costTrendData = res.data.chartData;
				// Extract all the months and teams
				const months = Object.keys(costTrendData);
				// let teams = Object.keys(costTrendData[months[0]]);
				let teams = Object.keys(costTrendData[months[0]]).filter(key => key !== 'TotalCost');

				const othersVal = teams.filter(team => team === 'Others');
				teams = teams.filter(team => team !== 'Others');
				teams = teams.concat(othersVal);

				// Prepare series data for each team
				let seriesChartData = teams.map(team => {
					return {
						name: team,
						type: 'bar',
						stack: 'total', 
						data: months.map(month => costTrendData[month][team] || 0),
						barWidth: '50%'
					};
				});

				const option = {
					title: {
						text: months.length === 0 ? 'No data to display' : '',
						textStyle: {
							color: '#f5f5f5',
							fontWeight: 'normal',
							fontSize: 14
						}
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						},
						backgroundColor: '#003771', 
						textStyle: {
							fontFamily: 'Montserrat',
							fontSize: 14,
							color: '#A7ADCB'
						},
						formatter: (params: any[]) => {
							let tooltipContent = `${params[0].axisValue}<br/>`;
							let totalCost = costTrendData[params[0].axisValue]['TotalCost'];
							if (totalCost) {
								let totalCostValue = formaNumberTwo(totalCost / 1000000) + 'MM';
								tooltipContent += `TotalCost: $${totalCostValue}<br/></div>`;
							}
							params.forEach((item: { value: number; marker: any; seriesName: any; }) => {
								let value = formaNumberTwo(item.value / 1000000) + 'MM';
								tooltipContent += `${item.marker} ${item.seriesName}: $${value} <br/>`;
							});
							return tooltipContent;
						}
					},
					xAxis: {
						type: 'category',
						data: months,
						axisLine: {
							lineStyle: {
								color: '#A7ADCB'
							}
						},
						axisLabel: {
							color: '#F5F5F5',
							fontFamily: 'Montserrat',
							fontSize: 11,
							rotate: 30
						}
					},
					yAxis: {
						type: 'value',
						name: 'Total Cost USD ($ in MM)',
						nameLocation: 'center',
						nameTextStyle: {
							color: '#F5F5F5',
							fontFamily: 'Montserrat',
							fontSize: 13
						},
						nameGap: 70,
						axisLabel: {
							color: '#F5F5F5',
							formatter: (value: number) => {
								return `$${formatNumberOne(value / 1000000)}MM`;
							}
						},
						splitLine: {
							lineStyle: {
								color: '#094a93'
							}
						}
					},
					series: seriesChartData, 
					grid: {
						left: '6%',
						right: '4%',
						bottom: '18%',
						top: '4%',
						containLabel: true
					},
					legend: {
						data: teams, 
						textStyle: {
							color: '#F5F5F5' 
						},
						orient: 'horizontal', 
						bottom: 20, 
						left: 'center' 
					},
					textStyle: {
						fontFamily: "Montserrat"
					}
				};

				setGrafanaSummaryByResourceType(option);
				setGrafanaSummaryByResourceTypeLoading(false);

			})
			.catch((error) => {
				console.error(error);
			});
	};
	
	async function getDetailsSummary(prgName: string, month: any) {
		let groupInfos:any= {};
		if (searchParams.get('programName')) {
			groupInfos['Program'] = [searchParams.get('programName')];
		}
		if (searchParams.get('team')) {
			groupInfos['Team'] = [searchParams.get('team')];
		}
		if (searchParams.get('consumedService')) {
			groupInfos['ConsumedService'] = [searchParams.get('consumedService')];
		}
		if (searchParams.get('environmentName')) {
			groupInfos['Environment'] = [searchParams.get('environmentName')];
		}
		if (searchParams.get('environmentName')) {
			groupInfos['Environment'] = [searchParams.get('environmentName')];
		}
		if (searchParams.get('month')) {
			const monthParam = [searchParams.get('month')];
			const monthData = monthParam[0]?.split(',');
			groupInfos['Month'] = monthData;
		}
		callFetchFinOpsCostKpi(groupInfos);

		// FetchFinOpsDetails('ytd')
		// 	.then((res) => {
		// 		let count = res?.data?.result[0]?.total_cost;
		// 		SetDetailsCloudCost((count / 1000000).toFixed(2).toString());
		// 	})
		// 	.catch((error) => {
		// 		console.error(error);
		// 	});
		setGroupingInfo(groupInfos);
		detailTableFunc(page, pageSize, sortingInfo, groupInfos);
	}
	
// table

interface Row {
	projectName: string;
	totalCost: any,
	isExpanded?: boolean;
	isExpandedChild?: boolean;
	consumedservices:[];
	consumedservice:any,
	children: Row[];
	ischildren?: boolean;
	resource:any;
	year:any;
	2024: string;
	total_cost:any;
}
function getColumns(): Array<Column<Row>> {
	return [
		{
			key: 'projectName',
			name: 'Project',
			minWidth: 180,
			formatter({ row, isCellSelected }) {
				const hasChildren = row.consumedservices;
				const hasChildrenofChild = row.consumedservice;
				return (
					<>
						{hasChildren && (
							<CellExpanderFormatter
								isCellSelected={isCellSelected}
								expanded={row.isExpanded === true}
								onCellExpand={() => {
									toggleSubRow(row.projectName,row.consumedservices);
								}}
							/>
						)}
						{'   '}
						<Link
							className="jobsNameList"
							to=''
							state={row}
							style={{ paddingLeft: hasChildren ? '5px' : '10px', paddingRight: '10px' }}
						>
						     {row.projectName}
						</Link>
						{' '}
						{/* {hasChildrenofChild && (
							<CellExpanderFormatter
								isCellSelected={isCellSelected}
								expanded={row.isExpandedChild === true}
								onCellExpand={() => {
									toggleSubOfSubRow(projectName,row.consumedservice);
								}}
							/>
						)}
						{'   '} */}
						<Link
							className="jobsNameList"
							to=''
							state={row}
							style={{ paddingLeft: hasChildrenofChild ? '5px' : '10px' }}
						>
						     {row.consumedservice}
						</Link>
						{/* {' '}
						<Link
							className="jobsNameList"
							to=''
							state={row}
							style={{ paddingLeft: hasChildrenofChild ? '5px' : '10px' }}
						>
						     {row.resource}
						</Link> */}
					</>
				);
			}
		},
		{
			key: '2024',
			name: '2024',
			minWidth: 170,
			formatter({ row, isCellSelected }) {
				let formattedCost;
				const costValue = parseFloat(row['2024']);
				if (costValue >= 1000000) {
					formattedCost = `${formaNumberTwo((costValue / 1000000))}MM`;
				} else if (costValue >= 1000) {
					formattedCost = `${formaNumberTwo((costValue / 1000))}k`;
				} else {
					formattedCost = formaNumberTwo(costValue);
				}
				return (
					<>
					<div className='cost-tag'>${formattedCost}</div>
					</>
				);
			}
		},
		{
			key: 'totalCost',
			name: 'Total',
			minWidth: 170,
			formatter({ row, isCellSelected }) {
				let formattedCost;
				if (row.totalCost >= 1000000) {
					formattedCost = `${formaNumberTwo((row.totalCost / 1000000))}MM`;
				} else if (row.totalCost >= 1000) {
					formattedCost = `${formaNumberTwo((row.totalCost / 1000))}k`;
				} else {
					formattedCost = formaNumberTwo(row.totalCost);
				}
				return (
					<>
					<div className='cost-tag'>${formattedCost}</div>
					</>
				);
			}
		},
	];
}
let projectName:any='';
function toggleSubRow(name: string,resourceTypes:any) {
	const rows = projectList;
	const rowIndex = rows.findIndex((r: any) => r.projectName === name);
	const row = rows[rowIndex];
	const newRows = [...rows];
	let children: Row[] = [];
	let childRows: Row[] = [];
    projectName = name;
	if (!row.isExpanded) {
		if (resourceTypes.length>0) {
			childRows = resourceTypes;
		}
		children = childRows.map((x: any) => {
			return { ...x, isChildren: true };
		});
		newRows.splice(Number(rowIndex) + 1, 0, ...children);
		newRows[rowIndex] = { ...row, isExpanded: !row.isExpanded, children };
		localStorage.setItem('boxRows', JSON.stringify(newRows));
		setProjectList(newRows);
	}
	else
	{
		children = row.children;
		newRows.splice(Number(rowIndex) + 1, children.length);
		newRows[rowIndex] = { ...row, isExpanded: !row.isExpanded };
		localStorage.setItem('boxRows', JSON.stringify(newRows));
		setProjectList(newRows);
	}
}
function toggleSubOfSubRow(name: string,resourceType:string) {
	const rows = projectList;
	const rowIndex = rows.findIndex((r: any) => r.projectName === name);
	const row = rows[rowIndex];
	const newRows = [...rows];
	let children: Row[] = [];
	let childRows: Row[] = [];
	if (!row.isExpandedChild) {
		setLoading(true);
		const resourceList = FetchFinOpsResourceSummary(page, pageSize, name, resourceType);
		resourceList.then((res) => {
			setLoading(false);
			if (res.success) {
			   childRows = res.data.result;
			}
			children = childRows.map((x: any) => {
			 	return {...x,isChildren: true };
			});
			newRows.splice(Number(rowIndex) + 1, 0, ...children);
			newRows[rowIndex] = { ...row, isExpanded: row.isExpandedChild, isExpandedChild: row.isExpandedChild, consumedservice: resourceType, children };
			localStorage.setItem('boxRows', JSON.stringify(newRows));
			setProjectList(newRows);
		});
	}
	else
	{
		children = row.children;
		newRows.splice(Number(rowIndex) + 1, children.length);
		newRows[rowIndex] = { ...row, isExpanded: row.isExpandedChild, isExpandedChild: row.isExpandedChild };
		localStorage.setItem('boxRows', JSON.stringify(newRows));
		setProjectList(newRows);
	}
	row.isExpandedChild = !row.isExpandedChild;
}
const defaultColumnNames = ['Project','2024','Total'];
const [projectList, setProjectList] = useState<Row[]>([]);
const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
const [page, setPage] = useState(1);
const [pageSize, setPageSize] = useState(100);

const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
	detailTableFunc(value, pageSize, sortingInfo, groupingInfo);
};

const handlepageSizeChange = (event: any) => {
	detailTableFunc(page, event.target.value, sortingInfo, groupingInfo);
};

const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
	const classes = useStyles();
	const dataFetchedRef = useRef(false);
	const [sectorOptionsList, setSectorOptionsList] = useState<string[]>([]);
	const [programOptionsList, setProgramOptionsList] = useState<string[]>([]);
	const [teamOptionsList, setteamOptionsList] = useState<string[]>([]);
	const [csOptionsList, setCSOptionsList] = useState<string[]>([]);
	const [rtOptionsList, setrtOptionsList] = useState<string[]>([]);
	const [rgOptionsList, setrgOptionsList] = useState<string[]>([]);
	const [resourceOptionsList, setresourceOptionsList] = useState<string[]>([]);
	const [projectOptionsList, setProjectOptionsList] = useState<string[]>([]);
	const [yearOptionsList, setYearOptionsList] = useState<string[]>([]);
	const [monthOptionsList, setMonthOptionsList] = useState<string[]>([]);
	const [filterMenu, setFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	const [itemsChecked, setItemsChecked] = useState(false);
	const [checkedList, setCheckedList] = useState<CheckedList>({
		sectors: [],
		program: [],
		team: [],
		consumedService: [],
		resourceType: [],
		resourceGroup: [],
		resource: [],
		project: [],
		year: [],
		month: []
	});
	const [selectedFilter, setSelectedFilter] = useState<string>('Sectors');
	const [selectedSite, setSelectedSite] = useState<string[]>([]);
	const [selectedEnvironment, setSelectedEnvironment] = useState<string>('all');
	const [selectedSector, setSelectedSector] = useState<string[]>([]);

	const filterClick = (event: any) => {
		event.preventDefault();
		setFilterMenu(
			filterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 20,
						mouseY: parseInt(event.clientY) - 6
				  }
				: null
		);
	};

	const handleClose = () => {
		localStorage.removeItem('presto-summary-sector-filter');
		setCheckedList({
			sectors: [],
			program: [],
			team: [],
			consumedService: [],
			resourceType: [],
			resourceGroup: [],
			resource: [],
			project: [],
			year: [],
			month: []
		});
		setItemsChecked(false);
	};

	const handleMenuItemClick = (filter: string) => {
		setFilterListLoader(true);
		setSelectedFilter(filter);
		let searchQuery = (filter === 'Program' ? programInputText : 
			(filter === 'Consumed Service' ? consumedServiceInputText : 
			(filter === 'Resource Group' ? resourceGroupInputText : 
			(filter === 'Resource' ? resourceInputText : (filter === 'Project' ? projectInputText : '')))));
		fetchSectorOptionsData(filter, searchQuery);
		if (filter === 'Sectors') {
			setItemsChecked(false);
		}

	};
	
	const handleFilterSearch = (e: any) => {
		e.stopPropagation();
		const searchKeyword = (e.target as HTMLInputElement).value.toLowerCase();
		if(selectedFilter === 'Program')
			setProgramInputText(searchKeyword);
		else if(selectedFilter === 'Consumed Service')
			setConsumedServiceInputText(searchKeyword);
		else if(selectedFilter === 'Resource Group')
			setResourceGroupInputText(searchKeyword);
		else if(selectedFilter === 'Resource')
			setResourceInputText(searchKeyword);
		else if(selectedFilter === 'Project')
			setProjectInputText(searchKeyword);
		if(searchKeyword?.length >= 3 || !searchKeyword) {
			fetchSectorOptionsData(selectedFilter, searchKeyword)
		}
	};

	useEffect(() => {
		if (searchParams.get('programName')) {
			const param: any = searchParams.get('programName');
			setCheckedList((prevState) => ({
				...prevState,
				program: [param]
			}));
		} else if (searchParams.get('team')) {
			const param: any = searchParams.get('team');
			setCheckedList((prevState) => ({
				...prevState,
				team: [param]
			}));
		} else if (searchParams.get('consumedService')) {
			const param: any = searchParams.get('consumedService');
			setCheckedList((prevState) => ({
				...prevState,
				consumedService: [param]
			}));
		} 
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		if (userSector === 'all') fetchSectorOptionsData("Sectors");
		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/finops/details'|| window.location.pathname === '/dataestate/financialDetails') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				document.body.classList.add('filter-bg');
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('datefilter-align-sector');
				document.body.classList.add('sector-label');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				document.body.classList.add('dashboard-panel-dataestate');
				document.body.classList.add('datefilter-align-sector');
			}
		}
		getDetailsSummary('', '2024-05');
	}, []);

	const clearFilter = () => {
		if(selectedFilter === 'Program')
			setProgramOptionsList([]);
		else if(selectedFilter === 'Consumed Service')
			setCSOptionsList([]);
		else if(selectedFilter === 'Resource Group')
			setrgOptionsList([]);
		else if(selectedFilter === 'Resource')
			setresourceOptionsList([]);
		else if(selectedFilter === 'Project')
			setProjectOptionsList([]);
	}

	async function fetchSectorOptionsData(filter:string, searchQuery?: string) {
		clearFilter();
		if(filter === "Consumed Service"){
			filter="ConsumedService";
		}
		if(filter === "Resource Group"){
			filter="ResourceGroup"
		}
		if(filter === "Resource Type"){
			filter="ResourceType"
		}
		const sectorRes = await getDetailSummarySector(filter, searchQuery);
		if (sectorRes.success) {
			setFilterListLoader(false);
			if(filter === 'Sectors')
			{
			setSectorOptionsList(
				sectorRes.data.result.Sectors.map((x: any) => {
						return x;
				})
			);
			}
			if(filter === 'Program')
			{
				setProgramOptionsList(
					sectorRes.data.result.Program.map((x: any) => {
							return x;
					})
				);
			}
			if(filter=== 'Team')
			{
				setteamOptionsList(
					sectorRes.data.result.Team.map((x: any) => {
						return x;
					})
				);
			}
			if(filter=== 'ConsumedService')
			{
				setCSOptionsList(
					sectorRes.data.result.ConsumedService.map((x: any) => {
						return x;
					})
				);
			}
			if(filter=== 'ResourceType')
			{
				setrtOptionsList(
					sectorRes.data.result.ResourceType.map((x: any) => {
						return x;
					})
				);
			}
			if(filter=== 'Resource')
			{
				setresourceOptionsList(
					sectorRes.data.result.Resource.map((x: any) => {
						return x;
					})
				);
			}
			if(filter=== 'ResourceGroup')
			{
				setrgOptionsList(
					sectorRes.data.result.ResourceGroup.map((x: any) => {
						return x;
					})
				);
			}
			if(filter=== 'Project')
			{
				setProjectOptionsList(
					sectorRes.data.result.Project.filter((x: any) => x)
				);
			}
			if(filter=== 'Year')
			{
				setYearOptionsList(
					sectorRes.data.result.Year.map((x: any) => {
						return x;
					})
				);
			}
			if(filter=== 'Month')
			{
				setMonthOptionsList(
					sectorRes.data.result.Month.map((x: any) => {
						return x;
					})
				);
			}
		}
	}

	const handleOkClick = () => {
		let groupInfos:any= {};
		["sectors","program","team","consumedService","resourceType","resourceGroup","resource","project","year","month"].forEach((key:any)=>{
			let keyName = key[0].toUpperCase() + key.slice(1);
			if(key=="sectors" && checkedList.sectors.length>0){
				groupInfos[keyName] = checkedList.sectors
			}
			if(key=="program" && checkedList.program.length>0){
				groupInfos[keyName] = checkedList.program
			}
			if(key=="team" && checkedList.team.length>0){
				groupInfos[keyName] = checkedList.team
			}
			if(key=="consumedService" && checkedList.consumedService.length>0){
				groupInfos[keyName] = checkedList.consumedService
			}
			if(key=="resourceType" && checkedList.resourceType.length>0){
				groupInfos[keyName] = checkedList.resourceType
			}
			if(key=="resourceGroup" && checkedList.resourceGroup.length>0){
				groupInfos[keyName] = checkedList.resourceGroup
			}
			if(key=="resource" && checkedList.resource.length>0){
				groupInfos[keyName] = checkedList.resource
			}
			if(key=="project" && checkedList.project.length>0){
				groupInfos[keyName] = checkedList.project
			}
			if(key=="year" && checkedList.year.length>0){
				groupInfos[keyName] = checkedList.year
			}
			if(key=="month" && checkedList.month.length>0){
				groupInfos[keyName] = checkedList.month
			}
		})
		callFetchFinOpsCostKpi(groupInfos);
		setLoading(true);
		FetchFinOpsDetailsSummary('','','detail',groupInfos)
			.then((res) => {
				setLoading(false);
				setProjectList(res.data.result)
			})
		setSelectedSector(checkedList.sectors);
		handleViewClose();
	};

	const handleViewClose = () => {
		setFilterMenu(null);
	};

	const handleSectorChange = (updatedSelection: string[]) => {
		setCheckedList((prevState) => ({
			...prevState,
			sectors: updatedSelection
		}));
	};

	const handleProgramChange = (updatedSelection: string[]) => {
		// checkedList.program.includes(filter)
		// 	? checkedList.program.filter((item) => item !== filter)
		// 	: [...checkedList.program, filter];
		setCheckedList((prevState) => ({
			...prevState,
			program: updatedSelection
		}));
	};

	const handleTeamChange = (updatedSelection: string[]) => {
		setCheckedList((prevState) => ({
			...prevState,
			team: updatedSelection
		}));
	};

	const handleCSChange = (updatedSelection: string[]) => {
		setCheckedList((prevState) => ({
			...prevState,
			consumedService: updatedSelection
		}));
	};

	const handleRTChange = (updatedSelection: string[]) => {
		setCheckedList((prevState) => ({
			...prevState,
			resourceType: updatedSelection
		}));
	};
	const handleRGChange = (updatedSelection: string[]) => {
		setCheckedList((prevState) => ({
			...prevState,
			resourceGroup: updatedSelection
		}));
	};
	const handleResourceChange = (updatedSelection: string[]) => {
		setCheckedList((prevState) => ({
			...prevState,
			resource: updatedSelection
		}));
	};
	const handleProjectChange = (updatedSelection: string[]) => {
		setCheckedList((prevState) => ({
			...prevState,
			project: updatedSelection
		}));
	};
	const handleYearChange = (updatedSelection: string[]) => {
		setCheckedList((prevState) => ({
			...prevState,
			year: updatedSelection
		}));
	};
	const handleMonthChange = (updatedSelection: string[]) => {
		setCheckedList((prevState) => ({
			...prevState,
			month: updatedSelection
		}));
	};
	const selectAllCheck = (e: any) => {
		const { checked } = e.target;
		if (selectedFilter === 'Program') {
			setCheckedList((prevState) => ({
				...prevState,
				program: checked ? programOptionsList : []
			}));
		} else if (selectedFilter === 'Team') {
			setCheckedList((prevState) => ({
				...prevState,
				team: checked ? teamOptionsList : []
			}));
		}else if (selectedFilter === 'Consumed Service') {
			setCheckedList((prevState) => ({
				...prevState,
				consumedService: checked ? csOptionsList : []
		})); 
	    }else if (selectedFilter === 'Resource Type') {
			setCheckedList((prevState) => ({
				...prevState,
				resourceType: checked ? rtOptionsList : []
		})); 
	    }else if (selectedFilter === 'Resource Group') {
			setCheckedList((prevState) => ({
				...prevState,
				resourceGroup: checked ? rgOptionsList : []
		})); 
	    }else if (selectedFilter === 'Project') {
			setCheckedList((prevState) => ({
				...prevState,
				project: checked ? projectOptionsList : []
		})); 
	    }else if (selectedFilter === 'Resource') {
			setCheckedList((prevState) => ({
				...prevState,
				resource: checked ? resourceOptionsList : []
		})); 
	    }else if (selectedFilter === 'Year') {
			setCheckedList((prevState) => ({
				...prevState,
				year: checked ? yearOptionsList : []
		})); 
	    }else if (selectedFilter === 'Month') {
			setCheckedList((prevState) => ({
				...prevState,
				month: checked ? monthOptionsList : []
		})); 
	    }else {
			setCheckedList((prevState) => ({
				...prevState,
				sectors: checked ? sectorOptionsList : []
			}));
		}
		setItemsChecked(checked);
	};

	const handleSorting = (sortingInfo: any) => {
		detailTableFunc(page, pageSize, sortingInfo, groupingInfo);
	};

	async function detailTableFunc(pageNumber: any, size: any, sortingInfo: any, groupInfo?: any) {
		setLoading(true);
		if (pageSize !== size) {
			pageNumber = 1;
		}
		setLoading(true);
		const res = await FetchFinOpsDetailsSummary(pageNumber.toString(), size.toString(), 'detail',groupInfo, sortingInfo);
		if (res.success) {
			setLoading(false);
			setProjectList(res.data.result);
			setPaginationData(res.data.page);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
		}
	}

	const [open, setOpen] = React.useState(true);

  const handleProjects = () => {
    setOpen(!open);
  };
	return (
		<div className="dashboard-panel-dataestate job-schedules-wrap">
		<div className="datagrowth-filter-wrapper">
			<Stack className='bgcolor-blue'>
				{userSector === 'all' ? (
					<FilterIcon onClick={filterClick} className="overall-filter" />
				) : (
					<div className="sector-filter-text">Sector: {getDecodedToken()?.sector?.name}</div>
				)}

				<Popover
					open={filterMenu !== null}
					onClose={handleViewClose}
					className="filterContainers filter-base detailsFilter"
					anchorReference="anchorPosition"
					anchorPosition={filterMenu !== null ? { top: filterMenu.mouseY, left: filterMenu.mouseX } : undefined}
				>
					<div className="text-filter-rows text-filter-rows-finops">
						<div className="filter-header">
							<div className="filters-text"> Filters</div>
							<div className="buttonsSec1">
								<Button variant="contained" className={classes.okBtn} onClick={handleOkClick}>
									APPLY
								</Button>
								<Button variant="contained" className={classes.cancelBtn} onClick={handleClose}>
									CLEAR
								</Button>
							</div>
						</div>
						<div className="dataGrowthselectAll-sec1">
							<div>
								<table>
									<ul className="list-items">
										{/* {['Sectors', 'Program', 'Team', 'Consumed Service', 'Resource Group', 'Resource', 'Project', 'Year', 'Month'].map((filter: string) => ( */}
										{['Sectors', 'Program', 'Team', 'Consumed Service', 'Project', 'Year', 'Month'].map((filter: string) => (
											<ul key={filter}>
												<MenuItem
													className="list-items"
													onClick={() => handleMenuItemClick(filter)}
													style={{ background: filter === selectedFilter ? '#0070BD' : '' }}
												>
													<ListItemText className="selected">{filter}</ListItemText>
												</MenuItem>
											</ul>
										))}
									</ul>
								</table>
							</div>
						</div>
						{selectedFilter === 'Sectors' ? (
							<ul className="filterContainers MuiPaper-root sector sector-list">
								<MenuItem>
									<FormControlLabel
										className="checkbox"
										label="All"
										control={
											<Checkbox
												className="data-check"
												color="success"
												value="all"
												checked={itemsChecked}
												onChange={(e) => {
													selectAllCheck(e);
												}}
											/>
										}
									/>
								</MenuItem>
								<ul className="checkbox-sec-datagrowth">
									{sectorOptionsList &&
										sectorOptionsList.map((filter: any) => (
											<MenuItem key={filter} className="all-sector">
												<FormControlLabel
													className={classes.checkbox}
													label={filter}
													title={filter}
													control={
														<Checkbox
															className="data-check"
															color="success"
															value={filter}
															checked={checkedList.sectors.includes(filter)}
															onChange={() => {
																const updatedSelection = checkedList.sectors.includes(filter)
																	? checkedList.sectors.filter((item) => item !== filter)
																	: [...checkedList.sectors, filter];
																handleSectorChange(updatedSelection);
															}}
														/>
													}
												/>
											</MenuItem>
										))}

									{ filterListLoader && !sectorOptionsList.length && <li className='loading'>Loading...</li>}
								</ul>
							</ul>
						) : selectedFilter === 'Program' ? (
							<ul className="filterContainers MuiPaper-root sector sector-list">
								<div className="text-filter-row search-content">
									<TextField
										id="searchField"
										autoComplete="off"
										variant="standard"
										placeholder={`Search Program`}
										className= 'search-filter'
										value={programInputText}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<img className={classes.searchBar} src={searchIcon} />
												</InputAdornment>
											)
										}}
										inputProps={{
											style: {
												height: '25px',
												color: '#F5F5F5'
											}
										}}
										onChange={(e: any) => {
											handleFilterSearch(e);
										}}
									/>
								</div>
								<MenuItem>
									<FormControlLabel
										className="checkbox"
										label="All"
										control={
											<Checkbox
												className="data-check"
												color="success"
												value="all"
												checked={itemsChecked}
												onChange={(e) => {
													selectAllCheck(e);
												}}
											/>
										}
									/>
								</MenuItem>
								<ul className="checkbox-sec-datagrowth">
									{programOptionsList &&
										programOptionsList.map((filter: any) => (
										    <MenuItem key={filter} className="all-sector">
												<FormControlLabel
													className={classes.checkbox}
													label={filter}
													title={filter}
													control={
														<Checkbox
															className="data-check"
															color="success"
															value={filter}
															checked={checkedList.program.includes(filter)}
															onChange={() => {
																const updatedSelection = checkedList.program.includes(filter)
																	? checkedList.program.filter((item) => item !== filter)
																	: [...checkedList.program, filter];
																handleProgramChange(updatedSelection);
															}}
														/>
													}
												/>
											</MenuItem>
										))}
										{ filterListLoader && !programOptionsList.length && <li className='loading'>Loading...</li>}
								</ul>
							</ul>
						) : selectedFilter === 'Team' ? (
							<ul className="filterContainers MuiPaper-root sector sector-list">
								<MenuItem>
									<FormControlLabel
										className="checkbox"
										label="All"
										control={
											<Checkbox
												className="data-check"
												color="success"
												value="all"
												checked={itemsChecked}
												onChange={(e) => {
													selectAllCheck(e);
												}}
											/>
										}
									/>
								</MenuItem>
								<ul className="checkbox-sec-datagrowth">
									{teamOptionsList &&
										teamOptionsList.map((filter: any) => (
											<MenuItem key={filter} className="all-sector">
												<FormControlLabel
													className={classes.checkbox}
													label={filter}
													title={filter}
													control={
														<Checkbox
															className="data-check"
															color="success"
															value={filter}
															checked={checkedList.team.includes(filter)}
															onChange={() => {
																const updatedSelection = checkedList.team.includes(filter)
																	? checkedList.team.filter((item) => item !== filter)
																	: [...checkedList.team, filter];
																handleTeamChange(updatedSelection);
															}}
														/>
													}
												/>
											</MenuItem>
										))}
										{ filterListLoader && !teamOptionsList.length && <li className='loading'>Loading...</li>}
								</ul>
							</ul>
						) : selectedFilter === 'Consumed Service' ? (
							<ul className="filterContainers MuiPaper-root sector sector-list">
								<div className="text-filter-row search-content">
									<TextField
										id="searchField"
										autoComplete="off"
										variant="standard"
										placeholder={`Search Consumed Service`}
										className= 'search-filter'
										value={consumedServiceInputText}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<img className={classes.searchBar} src={searchIcon} />
												</InputAdornment>
											)
										}}
										inputProps={{
											style: {
												height: '25px',
												color: '#F5F5F5'
											}
										}}
										onChange={(e: any) => {
											handleFilterSearch(e);
										}}
									/>
								</div>
								<MenuItem>
									<FormControlLabel
										className="checkbox"
										label="All"
										control={
											<Checkbox
												className="data-check"
												color="success"
												value="all"
												checked={itemsChecked}
												onChange={(e) => {
													selectAllCheck(e);
												}}
											/>
										}
									/>
								</MenuItem>
								<ul className="checkbox-sec-datagrowth">
									{csOptionsList &&
										csOptionsList.map((filter: any) => (
											<MenuItem key={filter} className="all-sector">
												<FormControlLabel
													className={classes.checkbox}
													label={filter}
													title={filter}
													control={
														<Checkbox
															className="data-check"
															color="success"
															value={filter}
															checked={checkedList.consumedService.includes(filter)}
															onChange={() => {
																const updatedSelection = checkedList.consumedService.includes(filter)
																	? checkedList.consumedService.filter((item) => item !== filter)
																	: [...checkedList.consumedService, filter];
																handleCSChange(updatedSelection);
															}}
														/>
													}
												/>
											</MenuItem>
										))}
										{ filterListLoader && !csOptionsList.length && <li className='loading'>Loading...</li>}
								</ul>
							</ul>
						) : 
						// selectedFilter === 'Resource Type' ? (
						// 	<ul className="filterContainers MuiPaper-root sector sector-list">
						// 		<MenuItem className="dataGrowthselectAll-sec2">
						// 			<FormControlLabel
						// 				className="checkbox"
						// 				label="All"
						// 				control={
						// 					<Checkbox
						// 						className="data-check"
						// 						color="success"
						// 						value="all"
						// 						checked={itemsChecked}
						// 						onChange={(e) => {
						// 							selectAllCheck(e);
						// 						}}
						// 					/>
						// 				}
						// 			/>
						// 		</MenuItem>
						// 		<ul className="checkbox-sec-datagrowth">
						// 			{rtOptionsList &&
						// 				rtOptionsList.map((filter: any) => (
						// 					<MenuItem key={filter} className="all-sector">
						// 						<FormControlLabel
						// 							className={classes.checkbox}
						// 							label={filter}
						// 							title={filter}
						// 							control={
						// 								<Checkbox
						// 									className="data-check"
						// 									color="success"
						// 									value={filter}
						// 									checked={checkedList.resourceType.includes(filter)}
						// 									onChange={() => {
						// 										const updatedSelection = checkedList.resourceType.includes(filter)
						// 											? checkedList.resourceType.filter((item) => item !== filter)
						// 											: [...checkedList.resourceType, filter];
						// 										handleRTChange(updatedSelection);
						// 									}}
						// 								/>
						// 							}
						// 						/>
						// 					</MenuItem>
						// 				))}
						// 				{ filterListLoader && !rtOptionsList.length && <li className='loading'>Loading...</li>}
						// 		</ul>
						// 	</ul>
						// ) :
						// selectedFilter === 'Resource Group' ? (
						// 	<ul className="filterContainers MuiPaper-root sector sector-list">
						// 		<div className="text-filter-row search-content">
						// 			<TextField
						// 				id="searchField"
						// 				autoComplete="off"
						// 				variant="standard"
						// 				placeholder={`Search Resource Group`}
						// 				className= 'search-filter'
						// 				value={resourceGroupInputText}
						// 				InputProps={{
						// 					startAdornment: (
						// 						<InputAdornment position="start">
						// 							<img className={classes.searchBar} src={searchIcon} />
						// 						</InputAdornment>
						// 					)
						// 				}}
						// 				inputProps={{
						// 					style: {
						// 						height: '25px',
						// 						color: '#F5F5F5'
						// 					}
						// 				}}
						// 				onChange={(e: any) => {
						// 					handleFilterSearch(e);
						// 				}}
						// 			/>
						// 		</div>
						// 		<MenuItem>
						// 			<FormControlLabel
						// 				className="checkbox"
						// 				label="All"
						// 				control={
						// 					<Checkbox
						// 						className="data-check"
						// 						color="success"
						// 						value="all"
						// 						checked={itemsChecked}
						// 						onChange={(e) => {
						// 							selectAllCheck(e);
						// 						}}
						// 					/>
						// 				}
						// 			/>
						// 		</MenuItem>
						// 		<ul className="checkbox-sec-datagrowth">
						// 			{rgOptionsList &&
						// 				rgOptionsList.map((filter: any) => (
						// 					<MenuItem key={filter} className="all-sector">
						// 						<FormControlLabel
						// 							className={classes.checkbox}
						// 							label={filter}
						// 							title={filter}
						// 							control={
						// 								<Checkbox
						// 									className="data-check"
						// 									color="success"
						// 									value={filter}
						// 									checked={checkedList.resourceGroup.includes(filter)}
						// 									onChange={() => {
						// 										const updatedSelection = checkedList.resourceGroup.includes(filter)
						// 											? checkedList.resourceGroup.filter((item) => item !== filter)
						// 											: [...checkedList.resourceGroup, filter];
						// 										handleRGChange(updatedSelection);
						// 									}}
						// 								/>
						// 							}
						// 						/>
						// 					</MenuItem>
						// 				))}
						// 				{ filterListLoader && !rgOptionsList.length && <li className='loading'>Loading...</li>}
						// 		</ul>
						// 	</ul>
						// ) : selectedFilter === 'Resource' ? (
						// 	<ul className="filterContainers MuiPaper-root sector sector-list">
						// 		<div className="text-filter-row search-content">
						// 			<TextField
						// 				id="searchField"
						// 				autoComplete="off"
						// 				variant="standard"
						// 				placeholder={`Search Resource`}
						// 				className= 'search-filter'
						// 				value={resourceInputText}
						// 				InputProps={{
						// 					startAdornment: (
						// 						<InputAdornment position="start">
						// 							<img className={classes.searchBar} src={searchIcon} />
						// 						</InputAdornment>
						// 					)
						// 				}}
						// 				inputProps={{
						// 					style: {
						// 						height: '25px',
						// 						color: '#F5F5F5'
						// 					}
						// 				}}
						// 				onChange={(e: any) => {
						// 					handleFilterSearch(e);
						// 				}}
						// 			/>
						// 		</div>
						// 		<MenuItem>
						// 			<FormControlLabel
						// 				className="checkbox"
						// 				label="All"
						// 				control={
						// 					<Checkbox
						// 						className="data-check"
						// 						color="success"
						// 						value="all"
						// 						checked={itemsChecked}
						// 						onChange={(e) => {
						// 							selectAllCheck(e);
						// 						}}
						// 					/>
						// 				}
						// 			/>
						// 		</MenuItem>
						// 		<ul className="checkbox-sec-datagrowth">
						// 			{resourceOptionsList &&
						// 				resourceOptionsList.map((filter: any) => (
						// 					<MenuItem key={filter} className="all-sector">
						// 						<FormControlLabel
						// 							className={classes.checkbox}
						// 							label={filter}
						// 							title={filter}
						// 							control={
						// 								<Checkbox
						// 									className="data-check"
						// 									color="success"
						// 									value={filter}
						// 									checked={checkedList.resource.includes(filter)}
						// 									onChange={() => {
						// 										const updatedSelection = checkedList.resource.includes(filter)
						// 											? checkedList.resource.filter((item) => item !== filter)
						// 											: [...checkedList.resource, filter];
						// 										handleResourceChange(updatedSelection);
						// 									}}
						// 								/>
						// 							}
						// 						/>
						// 					</MenuItem>
						// 				))}
						// 				{ filterListLoader && !resourceOptionsList.length && <li className='loading'>Loading...</li>}
						// 		</ul>
						// 	</ul>
						// ) : 
						selectedFilter === 'Project' ? (
							<ul className="filterContainers MuiPaper-root sector sector-list">
								<div className="text-filter-row search-content">
									<TextField
										id="searchField"
										autoComplete="off"
										variant="standard"
										placeholder={`Search Project`}
										className= 'search-filter'
										value={projectInputText}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<img className={classes.searchBar} src={searchIcon} />
												</InputAdornment>
											)
										}}
										inputProps={{
											style: {
												height: '25px',
												color: '#F5F5F5'
											}
										}}
										onChange={(e: any) => {
											handleFilterSearch(e);
										}}
									/>
								</div>
								<MenuItem>
									<FormControlLabel
										className="checkbox"
										label="All"
										control={
											<Checkbox
												className="data-check"
												color="success"
												value="all"
												checked={itemsChecked}
												onChange={(e) => {
													selectAllCheck(e);
												}}
											/>
										}
									/>
								</MenuItem>
								<ul className="checkbox-sec-datagrowth">
									{projectOptionsList &&
										projectOptionsList.map((filter: any) => (
											<MenuItem key={filter} className="all-sector">
												<FormControlLabel
													className={classes.checkbox}
													title={filter}
													label={filter}
													control={
														<Checkbox
															className="data-check"
															color="success"
															value={filter}
															checked={checkedList.project.includes(filter)}
															onChange={() => {
																const updatedSelection = checkedList.project.includes(filter)
																	? checkedList.project.filter((item) => item !== filter)
																	: [...checkedList.project, filter];
																handleProjectChange(updatedSelection);
															}}
														/>
													}
												/>
											</MenuItem>
										))}
										{ filterListLoader && !projectOptionsList.length && <li className='loading'>Loading...</li>}
								</ul>
							</ul>
						) : selectedFilter === 'Year' ? (
							<ul className="filterContainers MuiPaper-root sector sector-list">
								<MenuItem>
									<FormControlLabel
										className="checkbox"
										label="All"
										control={
											<Checkbox
												className="data-check"
												color="success"
												value="all"
												checked={itemsChecked}
												onChange={(e) => {
													selectAllCheck(e);
												}}
											/>
										}
									/>
								</MenuItem>
								<ul className="checkbox-sec-datagrowth">
									{yearOptionsList &&
										yearOptionsList.map((filter: any) => (
											<MenuItem key='2024' className="all-sector">
												<FormControlLabel
													className={classes.checkbox}
													label='2024'
													control={
														<Checkbox
															className="data-check"
															color="success"
															value='2024'
															checked={checkedList.year.includes('2024')}
															onChange={() => {
																const updatedSelection = checkedList.year.includes('2024')
																	? checkedList.year.filter((item) => item !== '2024')
																	: [...checkedList.year, '2024'];
																handleYearChange(updatedSelection);
															}}
														/>
													}
												/>
											</MenuItem>
										))}
										{ filterListLoader && !yearOptionsList.length && <li className='loading'>Loading...</li>}
								</ul>
							</ul>
						) : selectedFilter === 'Month' ? (
							<ul className="filterContainers MuiPaper-root sector sector-list">
								<MenuItem>
									<FormControlLabel
										className="checkbox"
										label="All"
										control={
											<Checkbox
												className="data-check"
												color="success"
												value="all"
												checked={itemsChecked}
												onChange={(e) => {
													selectAllCheck(e);
												}}
											/>
										}
									/>
								</MenuItem>
								<ul className="checkbox-sec-datagrowth">
									{monthOptionsList &&
										monthOptionsList.map((filter: any) => (
											<MenuItem key={filter} className="all-sector">
												<FormControlLabel
													className={classes.checkbox}
													label={filter}
													control={
														<Checkbox
															className="data-check"
															color="success"
															value={filter}
															checked={checkedList.month.includes(filter)}
															onChange={() => {
																const updatedSelection = checkedList.month.includes(filter)
																	? checkedList.month.filter((item) => item !== filter)
																	: [...checkedList.month, filter];
																handleMonthChange(updatedSelection);
															}}
														/>
													}
												/>
											</MenuItem>
										))}
										{ filterListLoader && !monthOptionsList.length && <li className='loading'>Loading...</li>}
								</ul>
							</ul>
						) : (
							<div className="separeat_sector"></div>
						)}
					</div>
				</Popover>
			</Stack>
		</div>
			<div className="page-table page-table-finops">
				<div className="ibp-dashboard-wrapper">
					<Grid container spacing={0} className="headerIPB containerBI">
						<Grid item xs={4} className="headerIPB-title subheaderIPB-title no-ml">
							<Grid container spacing={1}>
								<Grid item xs={12} className="dataestate-title-fn chart-widget-finop-details bgcolor-blue pie-echart">
									<div className="monitor-head">Azure Cloud Cost (YTD)</div>
										{/* {DetailsCloudCost == '-' ? (
											<span className="loading">Loading...</span>
										) : (
											<>
												${DetailsCloudCost} <span className="subheader-text">{cloudCost}</span>
											</>
										 )} */}
									{grafanaAzureCostLoading ? 'Loading...' : <ReactECharts
										option={grafanaAzureCostOptions}
										theme={{
											// backgroundColor: '#1b1e35'
										}}
									/>}
								</Grid>
							</Grid>
						</Grid>
						{/* right side */}

						<Grid item xs={8} className="headerIPB-title subheaderIPB-title no-ml">
							<Grid container spacing={1}>
								<Grid item xs={12} className="dataestate-title chart-widget-finop-details bgcolor-blue bar-echart">
									<div className="monitor-head">Cost Summary (MoM) </div>

									{/* <span className='title-italic'>Current Year is (YTD)</span> */}
									{grafanaSummaryByResourceTypeLoading ? (
										'Loading...'
									) : (
										<ReactECharts
										    
											option={grafanaSummaryByResourceType}
											style ={{height:'90%'}}
											theme={
												{
													// backgroundColor: '#1b1e35'
												}
											}
										/>
									)}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					{/* second part */}
					<h2 className="panel-legend--title mt-35 ">Detailed View</h2>

					<div className=" finop_tabledata detail_sum_tbl">
						<Grid>
							{projectList.length > 0 ? (
								<CustomizedDataGrid
									tableColumns={getColumns()}
									tableData={projectList}
									tableType="finops-detail"
									defaultColumnNames={defaultColumnNames}
									onSorting={handleSorting}
									currSorting={sortingInfo}
									page={page}
									pageSize={pageSize}
									paginationData={paginationData}
									listLength={projectList.length}
									handlepageSizeChange={handlepageSizeChange}
									handlePageChange={handlePageChange}
									isDateRangeFilter={false}
									isDisabled={false}
								/>
							) : (
							     <div className='mt-30 text-center'>No records found</div>
								// <Loader />
							)}
							
							{ loading && <Loader />}

						</Grid>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DetailsSummary;
function team(value: string, index: number, array: string[]): value is string {
	throw new Error('Function not implemented.');
}

