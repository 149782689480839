import { lazy } from 'react';
import PrivateRoute from 'utils/privateRoute';
import Jobs from 'components/jobs/jobs';
import JobDetails from 'components/jobs/job_details';
import JobSchedules from 'components/jobSchedules/jobSchedules';
import JobExecutions from 'components/jobExecutions/jobExecutions';
import JobRunSchedules from 'components/jobRunSchedules/jobRunSchedules';
import JobsInfrastructure from 'components/JobsInfrastructure/Infrastructure';

// const Jobs = lazy(() => import('components/jobs/jobs'));
// const JobDetails = lazy(() => import('components/jobs/job_details'));
// const JobSchedules = lazy(() => import('components/jobSchedules/jobSchedules'));
// const JobExecutions = lazy(() => import('components/jobExecutions/jobExecutions'));
// const JobRunSchedules = lazy(() => import('components/jobRunSchedules/jobRunSchedules'));

const JobRoutes = [
	{
		key: 'jobs',
		route: '/jobs',
		component: (
			<PrivateRoute
				Component={Jobs}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'jobs_id',
		route: '/jobs/:jobId',
		component: (
			<PrivateRoute
				Component={JobDetails}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'jobs_id_overview',
		route: '/jobs/:jobId/overview',
		component: (
			<PrivateRoute
				Component={JobDetails}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'jobs_id_history',
		route: '/jobs/:jobId/run_history',
		component: (
			<PrivateRoute
				Component={JobDetails}
				restrictedRoles={[
					'Platform Support',
					'Data Operations Admin',
					'Data Operations User',
					'D&AI Executive User',
					'Program Executive'
				]}
			/>
		)
	},
	{
		key: 'dash_admin',
		route: '/jobs/:jobId/job_dependencies',
		component: (
			<PrivateRoute
				Component={JobDetails}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'job_sched',
		route: '/jobSchedules',
		component: (
			<PrivateRoute
				Component={JobSchedules}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'job_exec',
		route: '/jobExecutions',
		component: (
			<PrivateRoute
				Component={JobExecutions}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'job_run_sched',
		route: '/jobRunSchedules',
		component: (
			<PrivateRoute
				Component={JobRunSchedules}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'jobs_infra',
		route: '/job/Infrastructure',
		component: (
			<PrivateRoute
				Component={JobsInfrastructure}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	}
];

export default JobRoutes;
