import React, { useEffect, useState } from 'react';
import { Stack, Box } from '@mui/material';
import './header.scss';
import { ReactComponent as NewJobIcon } from '../../assets/new_job.svg';
import { ReactComponent as NoMetaIcon } from '../../assets/no_meta.svg';
import { ReactComponent as FailedRunIcon } from '../../assets/failed_run.svg';
import { ReactComponent as SlaNotMetIcon } from '../../assets/sla_not_met.svg';
import { ReactComponent as OverRunIcon } from '../../assets/over_run.svg';
import { ReactComponent as AtRiskIcon } from '../../assets/at_risk.svg';
import Badge from '@mui/material/Badge';
import { getCookie } from '../../service/service';
import moment from 'moment-timezone';
import { url, endpoints, platformCostObsDashboard, DataGrowthDashboard } from '../../service/config';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

const ssoLoginLocalStore = localStorage.getItem('sso-login');
const ssoLogin = ssoLoginLocalStore ? ssoLoginLocalStore === 'true' : process.env.REACT_APP_SSO_LOGIN === 'true';
const sessionCookie = 'id_token';

const NotificationBar = (notificationData: any) => {
	const [alerts, setAlerts] = useState<any>('');
	function handleAlertClick(alertType: any): any {
		switch (alertType) {
			case 'newJob': {
				localStorage.setItem('selectedAlert', 'newJob');
				localStorage.removeItem('jobState');
				const jobState = {
					pageSize: 100,
					pageNumber: 1,
					sortingInfo: { sortBy: 'Created_DT', sortDir: 'desc' },
					filterInfo: {
						Created_DT: [moment().startOf('day'), moment().endOf('day')]
					}
				};
				localStorage.setItem('alertJobState', JSON.stringify(jobState));
				window.location.replace('/jobs');
				break;
			}
			case 'noMeta': {
				localStorage.setItem('selectedAlert', 'noMeta');
				localStorage.removeItem('jobState');
				const jobState = {
					pageSize: 100,
					pageNumber: 1,
					sortingInfo: { sortBy: 'Created_DT', sortDir: 'desc' },
					filterInfo: {
						noMeta: [true]
					}
				};
				localStorage.setItem('alertJobState', JSON.stringify(jobState));
				window.location.replace('/jobs');
				break;
			}
			case 'failedRuns': {
				localStorage.setItem('selectedAlert', 'failedRuns');
				localStorage.removeItem('failedBlink');
				localStorage.removeItem('jobExecutionsState');
				const jobExecutionsState = {
					pageSize: 100,
					pageNumber: 1,
					sortingInfo: { sortBy: '', sortDir: '' },
					filterInfo: {
						runStatus: ['Terminated', 'Failure']
					}
				};
				localStorage.setItem('alertJobExecutionsState', JSON.stringify(jobExecutionsState));
				window.location.replace('/jobExecutions');
				break;
			}
			case 'slaNotMet': {
				localStorage.setItem('selectedAlert', 'slaNotMet');
				localStorage.removeItem('slaNotMetBlink');
				localStorage.removeItem('jobExecutionsState');
				const jobExecutionsState = {
					pageSize: 100,
					pageNumber: 1,
					sortingInfo: { sortBy: '', sortDir: '' },
					filterInfo: {
						slaStatus: ['Not Met']
					}
				};
				localStorage.setItem('alertJobExecutionsState', JSON.stringify(jobExecutionsState));
				window.location.replace('/jobExecutions');
				break;
			}
			case 'overRun': {
				localStorage.setItem('selectedAlert', 'overRun');
				localStorage.removeItem('jobExecutionsState');
				const jobExecutionsState = {
					pageSize: 100,
					pageNumber: 1,
					sortingInfo: { sortBy: '', sortDir: '' },
					filterInfo: {
						overRun: ['1'],
						runStatus: ['Successful']
					}
				};
				localStorage.setItem('alertJobExecutionsState', JSON.stringify(jobExecutionsState));
				window.location.replace('/jobExecutions');
				break;
			}
			case 'atRisk': {
				localStorage.setItem('selectedAlert', 'atRisk');
				localStorage.removeItem('atRiskBlink');
				localStorage.removeItem('jobExecutionsState');
				const jobExecutionsState = {
					pageSize: 100,
					pageNumber: 1,
					sortingInfo: { sortBy: '', sortDir: '' },
					filterInfo: {
						atRisk: ['Yes'],
						runStatus: ['Successful']
					}
				};
				localStorage.setItem('alertJobExecutionsState', JSON.stringify(jobExecutionsState));
				window.location.replace('/jobExecutions');
				break;
			}
			default:
				break;
		}
	}

	function isBlink(blinkKey: any): any {
		return localStorage.getItem(blinkKey);
	}

	useEffect(() => {
		if (getCookie(sessionCookie)) {
			if (notificationData?.notificationData?.length) {
				const data = notificationData?.notificationData;
				const currAlertsData: any = data[data.length - 1];
				const storedAlertData = localStorage.getItem('prevAlertsData');
				const prevAlertsData = storedAlertData && JSON.parse(storedAlertData);
				if (prevAlertsData && currAlertsData) {
					if (Number(currAlertsData.failedRuns) > Number(prevAlertsData.failedRuns))
						localStorage.setItem('failedBlink', '1');
					if (Number(currAlertsData.slaNotMet) > Number(prevAlertsData.slaNotMet))
						localStorage.setItem('slaNotMetBlink', '1');
					if (Number(currAlertsData.atRisk) > Number(prevAlertsData.atRisk)) localStorage.setItem('atRiskBlink', '1');
				}
				localStorage.setItem('prevAlertsData', JSON.stringify(currAlertsData));
				setAlerts(currAlertsData);
			}
		}
	}, [notificationData]);
	return (
		<>
			{location.pathname !== platformCostObsDashboard && location.pathname !== DataGrowthDashboard &&
			location.pathname === ('/dashboard/business') && (
				<Stack direction="row" spacing={0} className="badgeContainer">
					<Box component="span" sx={{ p: 2 }} 
					onClick={(event) => handleAlertClick('newJob')} className="badge_box">
						<Tooltip arrow title="New jobs" TransitionComponent={Zoom}>
							<Badge
								badgeContent={alerts.newJob || 0}
								max={9999}
								showZero
								className={Number(alerts.newJob) > 0 ? 'new_job_badge' : 'zero_badge'}
							>
								<NewJobIcon className="badge_icon" />
							</Badge>
						</Tooltip>
					</Box>
					<Box component="span" sx={{ p: 2 }} onClick={(event) => handleAlertClick('noMeta')} className="badge_box">
						<Tooltip arrow title="No metadata" TransitionComponent={Zoom}>
							<Badge
								badgeContent={alerts.noMeta || 0}
								max={9999}
								showZero
								className={Number(alerts.noMeta) > 0 ? 'warning_badge' : 'zero_badge'}
							>
								<NoMetaIcon className="badge_icon" />
							</Badge>
						</Tooltip>
					</Box>
					<Box
						component="span"
						sx={{ p: 2 }}
						onClick={(event) => {
							//return false;
							 handleAlertClick('failedRuns');
						}}
						className="badge_box"
					>
						<Tooltip arrow title="Runs failed" TransitionComponent={Zoom}>
							<Badge
								badgeContent={alerts.failedRuns || 0}
								max={9999}
								showZero
								className={`${Number(alerts.failedRuns) > 0 ? 'alert_badge' : 'zero_badge'}
					 ${isBlink('failedBlink') && Number(alerts.failedRuns) && 'blink'}`}
							>
								<FailedRunIcon className="badge_icon" />
							</Badge>
						</Tooltip>
					</Box>
					<Box
						component="span"
						sx={{ p: 2 }}
						onClick={(event) => {
							//return false;
							 handleAlertClick('slaNotMet');
						}}
						className="badge_box"
					>
						<Tooltip arrow title="SLA not met" TransitionComponent={Zoom}>
							<Badge
								badgeContent={alerts.slaNotMet || 0}
								max={9999}
								showZero
								className={`${Number(alerts.slaNotMet) > 0 ? 'alert_badge' : 'zero_badge'} ${
									isBlink('slaNotMetBlink') && Number(alerts.slaNotMet) && 'blink'
								}`}
							>
								<SlaNotMetIcon className="badge_icon" />
							</Badge>
						</Tooltip>
					</Box>
					<Box
						component="span"
						sx={{ p: 2 }}
						onClick={(event) => {
							//return false;
							handleAlertClick('overRun');
						}}
						className="badge_box"
					>
						<Tooltip arrow title="Runs overrun" TransitionComponent={Zoom}>
							<Badge
								badgeContent={alerts.overRun || 0}
								max={9999}
								showZero
								className={Number(alerts.overRun) > 0 ? 'warning_badge' : 'zero_badge'}
							>
								<OverRunIcon className="badge_icon" />
							</Badge>
						</Tooltip>
					</Box>
					<Box
						component="span"
						sx={{ p: 2 }}
						onClick={(event) => {
							//return false;
							 handleAlertClick('atRisk');
						}}
						className="badge_box"
					>
						<Tooltip arrow title="Runs at risk" TransitionComponent={Zoom}>
							<Badge
								badgeContent={alerts.atRisk || 0}
								max={9999}
								showZero
								className={`${Number(alerts.atRisk) > 0 ? 'alert_badge' : 'zero_badge'} ${
									isBlink('atRiskBlink') && Number(alerts.atRisk) && 'blink'
								}`}
							>
								<AtRiskIcon className="badge_icon" />
							</Badge>
						</Tooltip>
					</Box>
				</Stack>
			)}
		</>
	);
};

export default NotificationBar;
