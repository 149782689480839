import { makeStyles } from '@mui/styles';
import { getCookie, getDecodedToken } from '../../service/service';
import { ctid, isLocalEnv } from '../../service/config';
import { relative } from 'path';

const ssoLoginLocalStore = localStorage.getItem('sso-login');
const ssoLogin = ssoLoginLocalStore ? ssoLoginLocalStore === 'true' : process.env.REACT_APP_SSO_LOGIN === 'true';
const sessionCookie = 'id_token';

const useStyles = makeStyles({
	iframeContainer: {
		display: 'block',
		border: 'none',
		overflow: 'hidden'
	},
	reasonExecutionIframeContainer: {
		width: '94.6vw',
		height: '410px',
		border: 'none',
		position: 'relative'
	},	
	executiveIframeContainer: {
		border: 'none',
		width: '100%',
		height: '895px',
		marginTop: '130px'
	},
	ibpIframeContainer: {
		border: 'none',
		width: '100%',
		height: '300px',
		marginTop: '10px',
		marginBottom: '10px'
	},
	slaIframeContainer: {
		width: '48.6vw',
		height: '410px',
		border: 'none',
		position: 'relative',
		padding: 0
	},
	newJobIframeContainer: {
		width: '48.4vw',
		height: '410px',
		border: 'none',
		padding: 0,
		position: 'relative'
	},
	jobDependencyIframeContainer: {
		height: 'calc(100vh - 225px)',
		width: '100%',
		display: 'block',
		border: 'none'
	},
	jobExecutionIframeContainer: {
		position: 'absolute',
		width: '38.6vw',
		height: '100vh',
		border: 'none',
		marginLeft: '-15px'
	},
	runhistoryIframeContainer: {
		width: '100%',
		height: '270px'
	},
	powerBiIframeContainer: {
		width: '100%',
		height: '700px'
	},
	'@media screen and (max-width: 1100px)': {
		slaIframeContainer: {
			height: '440px'
		},
		newJobIframeContainer: {
			height: '440px'
		},
		reasonExecutionIframeContainer: {
			height: '350px',
			width: '100%'
		},
		timeSelect: {
			left: '38%',
			top: '8px'
		}
	},
	'@media screen and (min-width: 1000px)': {
		powerBiIframeContainer: {
			width: '100%',
			height: '115vh'
		}
	},
	'@media screen and (max-width: 1000px)': {
		powerBiIframeContainer: {
			width: '100%',
			height: '110vh'
		}
	},
	dataBricksIframeContainer: {
		border: 'none',
		height: '320px',
		marginTop: '120px'
	},
	dataBricksCostByWorkspace: {
		border: 'none',
		height: '320px',
		marginTop: '120px'
	},
	dataBricksLeftIframeContainer: {
		border: 'none',
		width: '100%',
		height: '350px'
	},
	dataBricksRightIframeContainer: {
		border: 'none',
		width: '100%',
		height: '350px'
	},
	dataBricksSummaryIframeContainer: {
		border: 'none',
		width: '100%',
		height: '400px',
		marginTop: '150px'
	},
	tableauGovernanceIframeContainer: {
		border: 'none',
		width: '50%',
		height: '160px',
		marginTop: '350px'
	},
	dataBricksGovernanceIframeContainer: {
		border: 'none',
		width: '100%',
		height: '168px',
		marginTop: '100px'		
	},
	powerBiCapacityIframeContainer: {
		width: '100%',
		height: '100vh',
		Position: 'relative',
		top: '140px',
		border: 'none'
	},
	mlobsSummaryIframeContainer: {
		width: '100%',
		height: '365px',
		position: 'relative',
		top: '100px',
		border: 'none'
	},
	mlobsTargetDriftIframeContainer: {
		width: '100%',
		height: '282px',
		position: 'relative',
		border: 'none'
	},
	mlobsDataDriftIframeContainer: {
		width: '100%',
		height: '130px',
		position: 'relative',
		border: 'none'
	},
	mlobsFeatureDriftIframeContainer: {
		width: '100%',
		height: '285px',
		position: 'relative',
		border: 'none'
	},
	dataBricksResourceOptIframeContainer: {
		border: 'none',
		width: '100%',
		height: '320px',
		marginTop: '145px'
	},
	tableauPerformance: {
		border: 'none',
		width: '100%',
		height: '340px',
		marginTop: '9.5%'
	},
	tableauPerformanceExtract: {
		width: '49.6vw',
		height: '327px',
		border: 'none',
		position: 'relative',
		padding: 0
	},
	tableauPerformanceCalendar: {
		width: '47.4vw',
		height: '327px',
		border: 'none',
		padding: 0,
		position: 'relative'
	},
	pcobsSummaryIframeContainer: {
		top: '70px',
		width: '100%',
		border: 'none',
		height: '275px',
		position: 'relative'
	},
	pcobsResourceGroupDetailsIframeContainer: {
		width: '100%',
		border: 'none',
		position: 'relative',
		height: '170px',
		marginTop: '-12px',
		marginLeft: '-15px'
	},
	dataBricksJobsIframeContainer: {
		border: 'none',
		width: '100%',
		height: '250px',
		marginTop: '135px'
	},
	dataBricksJobsFailureIframeContainer: {
		border: 'none',
		width: '100%',
		height: '370px',
		marginTop: '10px'
	},
	tableauPerformanceJobDetailViewIframeContainer: {
		border: 'none',
		width: '100%',
		marginTop: '140px',
		height: '500px'
	},
	powerBiVVIframeContainer: {
		width: '100%',
		height: '100vh',
		Position: 'relative',
		top: '140px',
		border: 'none'
	},
	tableauGovernanceContainer: {
		border: 'none',
		width: '100%',
		height: '500px',
		marginTop: '130px'
	},
	powerBiUsageGovernanceContainer: {
		width: '100%',
		height: '100vh',
		Position: 'relative',
		top: '140px',
		border: 'none'
	},
	powerBiDataGrowthCostContainer: {
		width: '100%',
		height: '100vh',
		Position: 'relative',
		top: '140px',
		border: 'none'
	},
	tableauGovernanceWorkBookContainer: {
		border: 'none',
		width: '110%',
		height: '286px',
		marginTop: '130px'
	},
	tableauGovernanceExtractsContainer: {
		border: 'none',
		width: '110%',
		height: '286px',
		marginTop: '130px'
	},
	tableauGovernanceViewsContainer: {
		border: 'none',
		width: '100%',
		height: '286px',
		marginTop: '130px'
	},
	tableauGovernanceOrphanedIframeContainer: {
		border: 'none',
		width: '100%',
		height: '286px'
	},
	tableauGovernanceUnusedExtractsIframeContainer: {
		border: 'none',
		width: '100%',
		height: '286px',
		marginTop: '7px'
	},
	dataGrowthLeftframeContainer: {
		width: '49.6vw',
		height: '327px',
		border: 'none',
		position: 'relative',
		padding: 0
	},
	tableauInfraIframeContainer: {
		width: '100%',
		height: '100vh',
		Position: 'relative',
		top: '140px',
		border: 'none'
	},
	datagrowthTableauTotalSizeOfExtract: {
		width: '45vw',
		height: '327px',
		border: 'none',
		padding: 0,
		position: 'relative'
	},
	dataGrowthframeContainer: {
		border: 'none',
		width: '100%',
		height: '300px',
		marginTop: '110px'
	},
	tableauSummaryIframeContainer: {
		border: 'none',
		width: '99%',
		height: '160px',
		marginTop: '130px'
	},
	prestoDataGrowthofQueriesContainer: {
		border: 'none',
		width: '100%',
		height: '74vh',
		marginTop: '140px'
	},
	prestoSummaryPanelsContainer: {
		border: 'none',
		width: '100%',
		height: '350px',
		marginTop: '130px'
	},
	prestoSummarySecondPanelsContainer: {
		border: 'none',
		width: '100%',
		height: '300px',
		marginTop: '10px'
	},
	powerBiInfrastructureIframeContainer: {
		width: '100%',
		height: '100vh',
		Position: 'relative',
		top: '140px',
		border: 'none'
	},
	dataGrowthDatabricksframeContainer: {
		border: 'none',
		width: '100%',
		height: '300px',
		marginTop: '110px'
	},
	dataGrowthDatabricksTableSizeframeContainer: {
		border: 'none',
		width: '100%',
		height: '300px',
		marginTop: '-30px'
	},
	controlsTestingIframeContainer: {
		width: '100%',
		height: '100vh',
		Position: 'relative',
		top: '140px',
		border: 'none'
	},
	bdrPowerBiIframeContainer: {
		width: '100%',
		height: '130vh'
	},
});

interface AnalyticsVisualizationProps {
	params: {
		dashboardUrl: string;
		className:
		| 'powerBiIframeContainer'
		| 'iframeContainer'
		| 'reasonExecutionIframeContainer'
		| 'executiveIframeContainer'
		| 'ibpIframeContainer'
		| 'slaIframeContainer'
		| 'newJobIframeContainer'
		| 'jobDependencyIframeContainer'
		| 'jobExecutionIframeContainer'
		| 'runhistoryIframeContainer'
		| 'dataBricksIframeContainer'
		| 'dataBricksCostByWorkspace'
		| 'dataBricksSummaryIframeContainer'
		| 'tableauGovernanceIframeContainer'
		| 'dataBricksGovernanceIframeContainer'
		| 'powerBiCapacityIframeContainer'
		| 'mlobsSummaryIframeContainer'
		| 'dataBricksResourceOptIframeContainer'
		| 'tableauPerformance'
		| 'tableauPerformanceExtract'
		| 'tableauPerformanceCalendar'
		| 'mlobsTargetDriftIframeContainer'
		| 'mlobsDataDriftIframeContainer'
		| 'mlobsFeatureDriftIframeContainer'
		| 'dataBricksResourceOptIframeContainer'
		| 'pcobsSummaryIframeContainer'
		| 'pcobsResourceGroupDetailsIframeContainer'
		| 'dataBricksJobsIframeContainer'
		| 'dataBricksJobsFailureIframeContainer'
		| 'dataBricksLeftIframeContainer'
		| 'dataBricksRightIframeContainer'
		| 'tableauPerformanceJobDetailViewIframeContainer'
		| 'powerBiVVIframeContainer'
		| 'tableauGovernanceContainer'
		| 'powerBiUsageGovernanceContainer'
		| 'powerBiDataGrowthCostContainer'
		| 'tableauGovernanceWorkBookContainer'
		| 'tableauGovernanceExtractsContainer'
		| 'tableauGovernanceViewsContainer'
		| 'tableauGovernanceOrphanedIframeContainer'
		| 'tableauGovernanceUnusedExtractsIframeContainer'
		| 'tableauInfraIframeContainer'
		| 'datagrowthTableauTotalSizeOfExtract'
		| 'dataGrowthframeContainer'
		| 'dataGrowthLeftframeContainer'
		| 'tableauSummaryIframeContainer'
		| 'prestoDataGrowthofQueriesContainer'
		| 'prestoSummaryPanelsContainer'
		| 'prestoSummarySecondPanelsContainer'
		| 'powerBiInfrastructureIframeContainer'
		| 'dataGrowthDatabricksframeContainer'
		| 'dataGrowthDatabricksTableSizeframeContainer'
		| 'controlsTestingIframeContainer'
		| 'bdrPowerBiIframeContainer';

		otherParams: any;
		seamless?: boolean;
	};
}

export default function AnalyticsVisualization({ params }: AnalyticsVisualizationProps) {
	const classes = useStyles();
	// const jwtToken = getCookie(sessionCookie);
	// console.log(jwtToken, 'jwtToken=');
	// const encodedJwt = encodeURIComponent(jwtToken);
	// console.log(encodedJwt, 'encodedJwt');
	const userSector = getDecodedToken()?.sector.id;
	const { dashboardUrl, className, otherParams, seamless } = params;
	const otherSrc = otherParams
		? Object.entries(otherParams)
			.map(([paramName, paramValue]) => `${paramName}=${paramValue}`)
			.join('&')
		: '';

	const commonSrc = otherParams.name === 'PowerBI' ? `${dashboardUrl}&autoAuth=true&${ctid}` : `${dashboardUrl}`;
	const src = `${commonSrc}&${otherSrc}&var-userSector=${userSector}`;
	if (!isLocalEnv) {
		console.log(src, 'src');
	}

	return <iframe className={classes[className]} src={src} seamless={seamless} />;
}
