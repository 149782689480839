import React from 'react';
import UnderDevelopment from '../../assets/under-construction.svg';

const DatabricksInfrastructure = () => {
	return <div style={{ position: 'absolute', left: '45%', top: '50%', color: 'darkgray' }}>
		
		<img src={UnderDevelopment}  />
		<p style={{ position: 'relative', right: '25%', margin: '0'}}>Dashboard under development!</p>
	</div>;
};

export default DatabricksInfrastructure;
