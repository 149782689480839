import React, { useEffect, useRef, useState } from 'react';
import {
	Button,
	Chip,
	Grid,
	Menu,
	Stack,
	TextField,
	List,
	ListItem,
	ListItemText,
	FormControlLabel,
	Select,
	InputLabel,
	FormControl,
	Box
} from '@mui/material';
import AnalyticsVisualization from '../framework/analyticsVisualization';
import '../TableauObs/Summary/Summary.scss';
// import Workbooks from './Workbooks';
// import TableauUsers from '../../TableauObs/Summary/Users';
import moment from 'moment';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as DatePickerIcon } from '../../assets/calendar-outline_white.svg';
import { ReactComponent as FilterIcon } from '../../assets/ml_summary_filter.svg';
import { ReactComponent as ServerIcon } from '../../assets/solar_server-outline.svg';
import { getCostSummaryChart, getDecodedToken, getSector, QuerySummaryTable } from '../../service/service';
import useStyles from '../jobs/jobs_styles';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import '../DataEstate/data-estate.scss';
import GaugeChart from 'react-gauge-chart';
import ReactECharts from 'echarts-for-react';
import { fetchQuerySummary, fetchDEQueryTrends } from '../../service/service';
import { ReactComponent as CalendarIcon } from '../../assets/calendar-outline_white.svg';

import ArrowUpBlackIcon  from '../../assets/up-arrow-black.svg';
import { getPercentageOfTwoNumbers, formatNumberWithCommas } from '../../utils/common-util';
import { ReactComponent as Downarrow } from '../../assets/down-arrow.svg';
import { CustomizedDataGrid } from '../customized_data_grid';
import { Column } from 'react-data-grid';
import Loader from '../Loader/loader';
import { isEnvTypePROD } from '../../service/config';
import NoPrgramFound from '../../assets/no-program-found.svg';

const QueriesSummary = () => {	
	const [searchParams, setSearchParams] = useSearchParams();
	const hasDrillDown = searchParams.has('isDrillDowned');
	const minJobExecDate = '2023-03-01';
	const dateFrom: string = searchParams.get('fromDate')?.substring(0, 10) as string;
	const startDt = moment(dateFrom).startOf('day').subtract(1, 'hours').format('YYYY-MM-DD');
	const [fromDateTime, setFromDateTime] = useState<any>(
		searchParams.has('fromDate') ? startDt : moment().startOf('day').subtract(1, 'hours')
	);

	const [prestoBubbleValue, SetPrestoBubbleValue] = useState(0)
	const [prestoQueryLastDec, SetPrestoQueryLastDec] = useState(0);
	const [prestoQueryCurrent, SetPrestoQueryCurrent] = useState(0);

	const [teradataBubbleValue, SetTeradataBubbleValue] = useState(0)
	const [teradataQueryLastDec, SetTeradataQueryLastDec] = useState(0);
	const [teradataQueryCurrent, SetTeradataQueryCurrent] = useState(0);
	
	const [propsFromDateTime, setPropsFromDateTime] = useState<any>(
		searchParams.has('fromDate') ? startDt : moment().startOf('day').subtract(1, 'hours')
	);
	
		// sector filter
		const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
		const [filterMenu, setFilterMenu] = useState<{
			mouseX: number;
			mouseY: number;
		} | null>(null);
		const [dateFilterMenu, setDateFilterMenu] = useState<{
			mouseX: number;
			mouseY: number;
		} | null>(null);
		
		const classes = useStyles();
		const [itemsChecked, setItemsChecked] = useState(false);
		const [sectorOptionsList, setSectorOptionsList] = useState<any[]>([]);
		const [checkedList, setCheckedList] = useState<any[]>([]);
		const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);
		const [sector, setCurrSector] = useState<any[]>([]);
		const dataFetchedRef = useRef(false);
        const [TeradataSize, SetTeradataSize] = useState('-')
		const [PrestoSize, SetPrestoSize] = useState('-')
		const [dimension, setDimension] = useState<any>()	

		const [grafanaPrestoLoading, setGrafanaPrestoLoading] = useState(true);
		const [grafanaPrestoOptions, setGrafanaPrestoOptions] = useState<any>()
		const [grafanaTeradataLoading, setGrafanaTeradataLoading] = useState(true);
		const [grafanaTeradataOptions, setGrafanaTeradataOptions] = useState<any>()
		const [filterMonth, setFilterMonth] = useState("0M");
		const [filterDefaultMonth, setFilterDefaultMonth] = useState("0M");
        const [filterYear, setFilterYear] = useState("");
        const [customYear, setCustomYear] = useState(moment().format("YYYY"));
        const [isDateOpen, setIsDateOpen] = useState(false);
		const isCustomMonth = useRef(false);
		const [customMonth, setCustomMonth] = useState('');

		const dateFilterMenuClick = (event: any) => {
			setDateFilterMenu(
				dateFilterMenu === null
					? {
							mouseX: parseInt(event.clientX) - 260,
							mouseY: parseInt(event.clientY) - 50
					  }
					: null
			);
		};
		const handleDateFilterMenuClose = (event: any) => {
			setDateFilterMenu(null);
		};
	
	const sectorFilterClick = (event: any) => {
		event.preventDefault();
		setFilterMenu(
			filterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 20,
						mouseY: parseInt(event.clientY) - 6
				  }
				: null
		);
	};

	const handleClose = (event: any) => {
		setFilterMenu(null);
	};

	const selectAllCheck = (e: any) => {
		const { checked } = e.target;
		const collection: any[] = [];
		if (checked) setCheckedList(sectorOptionsList);
		else setCheckedList([]);
		setItemsChecked(checked);
	};

	const handleChange = (e: any) => {
		const { value, checked } = e.target;
		const newlyChecked = [];
		if (checked && e.target.value) {
			newlyChecked.push(e.target.value);
		}
		if (checked) {
			setCheckedList([...checkedList, ...newlyChecked]);
			if(sectorOptionsList.length === (checkedList.length + newlyChecked.length)){
				setItemsChecked(true);
			}
		} else {
			setCheckedList(checkedList.filter((item) => item !== value));
			setItemsChecked(false);
		}
	};
	const handleOkClick = (event: any) => {
		localStorage.setItem('selectedSector', JSON.stringify(checkedList)); // Tableau drilldown filter changes
		if (checkedList.length > 0) {
			setCurrSectorFilter(checkedList);
		} else {
			setCurrSectorFilter([]);
		}
        getQuerySummaryDetails(checkedList, filterMonth)
		setCurrSector( checkedList );
		handleClose(event);
	};

	async function fetchSectorOptionsData() {
		const sectorRes = await getSector('1', '100', '');
		if (sectorRes.success) {
			const filteredSectorOptions = sectorRes.data.sector.filter((x: any) => x.name !== 'S&T').map((x: any) => x.name);
			filteredSectorOptions.push('Blank');
        	setSectorOptionsList(filteredSectorOptions);
		}
	}
	
	async function getQuerySummaryDetails(sectors: any, month: any) {
		let sector = sectors.length == sectorOptionsList.length ? [] : sectors;
		// Trend APIs
		SetTeradataSize('-')
		SetPrestoSize('-')
		
		// setGrafanaTeradataLoading(true);
		// Teradata
		fetchDEQueryTrends('query', 'presto', sector, month).then(res => {
			let allDataAsArray = Object.entries(res.data);
				const allMonths = Object.keys(res.data);

				const firstMonthFromAllMonths = allMonths[0]
				const lastMonth = moment().subtract(1, 'months').format('MM');
				const indexValue = moment().year() + '-' + lastMonth;
				const lastIndex = allMonths.indexOf(indexValue);
				const lastMonthFromAllMonths = allMonths[lastIndex];
				const lastMonthFromForecast = allMonths[allMonths.length - 1];

				var currentMonth = new Date().getMonth();
				if (currentMonth == 0)
					currentMonth = 12
				const currentYear = new Date().getFullYear();
				const pastMonths = Object.entries(allDataAsArray).filter(([date]) => new Date(date).getMonth()+1 <= currentMonth).splice(2,moment().month()-1);
				const lastMonthString = (currentMonth).toString().padStart(2, '0');
				const filterMonth = `${(currentYear-1)}-${lastMonthString}`
				const filterDateEntry = Object.entries(res.data).filter(
					x => x[0] === filterMonth
				);
				if (filterDateEntry.length) {
					let count: any = 0;
					count = filterDateEntry[0][1];
					const lastMonthDataIndex = `${currentYear}-${lastMonthString}`;
					const lastMonthEntry = Object.entries(res.data).filter(
						x => x[0] === lastMonthDataIndex
					);
					if (lastMonthEntry.length){
						const lastmonthvalue = lastMonthEntry[0][1] as number;
						SetPrestoBubbleValue(getPercentageOfTwoNumbers(count, lastmonthvalue));
						SetPrestoQueryCurrent(lastmonthvalue)
						SetPrestoQueryLastDec(count)

						// SetTeradataQueryLastDec(count)	
						// SetTeradataBubbleValue(getPercentageOfTwoNumbers(count, lastmonthvalue));
					}
				}
				
			// if (res.data) {
			// 	type DataPoint = {
			// 		year_month: string;
			// 		count: string;
			// 	};
		
			// 	const allDataAsArray = res.data.res.map(({ year_month, count }: DataPoint) => [year_month, parseInt(count)]);
			// 	const allMonths = res.data.res.map(({ year_month }: DataPoint) => year_month);
		
			// 	console.log(res.data);
			// 	console.log(allDataAsArray, 'trendBy');
			// 	console.log(allMonths);
		
			// 	const initialChartValue = allDataAsArray[0];
			// 	const currentMonth = allDataAsArray[allDataAsArray.length - 1];
		
			// 	if (res.data.currentYearCount && res.data.previousYearCount) {
			// 		SetPrestoBubbleValue(getPercentageOfTwoNumbers(res.data.currentYearCount, res.data.previousYearCount));
			// 	}
		
				const option = {
					title: {
						show: allDataAsArray.length === 0,
						textStyle: {
							color: '#bcbcbc',
							fontWeight: 'normal',
							fontSize: 16
						},
						text: 'No data for the selected filters',
						left: 'center',
						top: 'center'
					},
					backgroundColor: '#053367',
					grid: {
						top: '13%',
						left: '10%',
						right: '2%',
						bottom: '12%'
					},
					xAxis: {
						type: 'category',
						splitLine: { 
							show: true,
							lineStyle: { 
								type: 'dashed',
								color: '#004477'
							}
						},
						data: allMonths,
						axisLabel: {
							rotate: 30,
							color: '#A7ADCB',
							formatter: (value: moment.MomentInput) => `${moment(value).format('MMM-YY')}`
						},
					},
					yAxis: {
						type: 'value',
						splitLine: { 
							show: true,
							lineStyle: { 
								type: 'dashed',
								color: '#004477'
							}
						},
						axisLabel: {
							color: '#A7ADCB',
							formatter: (value: number) => `${(value / 1000).toFixed(0)}k`
						}
					},
					visualMap: {
						show: false,
						dimension: 0,
						pieces: [
							{
								lte: lastIndex,
								color: '#009BD8'
							},
							{
								gt: lastIndex,
								lte: allMonths.length - 1,
								color: '#FD783B'
							}
						]
					},
					series: [
						{
							name: 'Current Growth',
							type: 'line',
							smooth: false,
							data: allDataAsArray,
							markArea: {
								itemStyle: {
									color: 'rgba(255, 173, 177, 0.1)'
								},
								data: [
									[
										{
											name: 'Current Growth',
											xAxis: firstMonthFromAllMonths
										},
										{
											xAxis: lastMonthFromAllMonths
										}
									],
									[
										{
											name: 'Forecast',
											xAxis: moment().format('YYYY-MM')
										},
										{
											xAxis: lastMonthFromForecast
										}
									]
								]
							}
						}
					],
					tooltip: {
						trigger: 'axis',
						formatter: (params: { name: any; seriesName: any; value: any; }[]) => {
							const { name, seriesName, value } = params[0]; 
							return `${moment(value[0]).format('MMM-YY')}: ${formatNumberWithCommas((parseFloat(value[1]) / 1000).toFixed(2),true)}K`; 
						},
						backgroundColor: '#053367',
						textStyle: {
							color: '#A7ADCB' 
						}
					}
				};
				
				setGrafanaPrestoOptions(option);
				setGrafanaPrestoLoading(false);
			}
		).catch((error: any) => { 
			console.error(error);
		})
		
		// Teradata
		fetchDEQueryTrends('query', 'teradata', sector, month).then(res => {			
				let allDataAsArray = Object.entries(res.data);
				const allMonths = Object.keys(res.data);

				const firstMonthFromAllMonths = allMonths[0]
				const lastMonth = moment().subtract(1, 'months').format('MM');
				const indexValue = moment().year() + '-' + lastMonth;
				const lastIndex = allMonths.indexOf(indexValue);
				const lastMonthFromAllMonths = allMonths[lastIndex];
				const lastMonthFromForecast = allMonths[allMonths.length - 1];

				var currentMonth = new Date().getMonth();
				if (currentMonth == 0)
					currentMonth = 12
				const currentYear = new Date().getFullYear();
				const pastMonths = Object.entries(allDataAsArray).filter(([date]) => new Date(date).getMonth()+1 <= currentMonth).splice(2,moment().month()-1);
				const lastMonthString = (currentMonth).toString().padStart(2, '0');
				const filterMonth = `${(currentYear-1)}-${lastMonthString}`
				const filterDateEntry = Object.entries(res.data).filter(
					x => x[0] === filterMonth
				);
				if (filterDateEntry.length) {
					let count: any = 0;
					count = filterDateEntry[0][1];
					const lastMonthDataIndex = `${currentYear}-${lastMonthString}`;
					const lastMonthEntry = Object.entries(res.data).filter(
						x => x[0] === lastMonthDataIndex
					);
					if (lastMonthEntry.length){
						const lastmonthvalue = lastMonthEntry[0][1] as number;
						SetTeradataQueryCurrent(lastmonthvalue)
						SetTeradataQueryLastDec(count)	
						SetTeradataBubbleValue(getPercentageOfTwoNumbers(count, lastmonthvalue));
					}
				}
				// if (pastMonths.length) {
				// 	let count: any = 0;
				// 	Object.values(res.data).filter((c) => { if (c && !count) { count = c; } });
				// 	const lastmonthvalue = lastMonthEntry[0][1] as number;

				// 					SetTeradataBubbleValue(getPercentageOfTwoNumbers(count, pastMonths[pastMonths.length - 1][1][1]));
				// }
				const option = {
					title: {
						show: Object.keys(allDataAsArray).length == 0 ? true : false,
						textStyle:{
						color:'#bcbcbc',
						fontWeight: 'normal',
						fontSize: 16
						},
						text: 'No data for the selected filters',
						left: 'center',
						top: 'center'
					},
					backgroundColor: '#053367',
					grid: {
						top: '13%',
						left: '10%',
						right: '2%',
						bottom: '12%'
					},
					xAxis: {
						type: 'category',
						splitLine: { 
							show: true,
							lineStyle: { 
								type: 'dashed',
								color: '#004477'
							}
						},
					data: allMonths,
						axisLabel: {
							rotate: 30,
							color: '#A7ADCB',
							formatter: function (value: any) {
								return `${moment(value).format('MMM-YY')}`;
							}
						},
					},
					yAxis: {
						type: 'value',
						splitLine: { 
							show: true,
							lineStyle: { 
								type: 'dashed',
								color: '#004477'
							}
						},
						axisLabel: {
							color: '#A7ADCB',
							formatter: function (value: any) {
								return `${(value/1000).toFixed(0)}k`;
							}
					},
					},			
					visualMap: {
						show: false,
						dimension: 0,
						pieces: [
						{
							lte: lastIndex,
							color: '#009BD8'
						},
						{
							gt: lastIndex,
							lte: allMonths.length - 1,
							color: '#FD783B'
						}
						]
					},
					series: [
						{
						name: 'Current Growth',
						type: 'line',
						data: allDataAsArray,
						markArea: {
							itemStyle: {
							color: 'rgba(255, 173, 177, 0.1)'
							},
							data: [
							[
								{
									name: 'Current Growth',
									xAxis: firstMonthFromAllMonths
								},
								{
									xAxis: lastMonthFromAllMonths
								}
							],
							[
								{
									name: 'Forecast',
									xAxis: moment().format('YYYY-MM')
								},
								{
									xAxis: lastMonthFromForecast
								}
							]
							]
						}
						}
					],
					tooltip: {
						trigger: 'axis',
						formatter: (params: any) => {
						const { name, seriesName, value } = params[0]; 
						return `${moment(value[0]).format('MMM-YY')}:  ${formatNumberWithCommas((value[1]/1000).toFixed(2),true)}K`; 
						},
						backgroundColor: '#053367',
						textStyle: {
							color: '#A7ADCB' 
						}
					}
				};
				setGrafanaTeradataOptions(option);
				setGrafanaTeradataLoading(false);			
		}).catch((error: any) => { 
			console.error(error);
		})

		
        // Summary APIs
		fetchQuerySummary('presto', sector, month).then(res => {
			let prestoSize = 0;
			if (res?.data?.result[0]?.prestoQueries) {
				prestoSize = res?.data?.result[0]?.prestoQueries;
				SetPrestoSize((prestoSize/1000).toFixed(2).toString());
			} else {
				SetPrestoSize('0');
			}
		}).catch(error => { 
			console.error(error);
		})

		fetchQuerySummary('teradata', sector, month).then(res => {
			let teradataSize = 0;
			if(res?.data?.result[0]?.teradataQueries) {
				teradataSize = res?.data?.result[0]?.teradataQueries;
				SetTeradataSize((teradataSize/1000).toFixed(2).toString());
			} else {
				SetTeradataSize('0');
			}
		}).catch(error => { 
			console.error(error);
		})
	}
	
	useEffect(() => {
		
		if (hasDrillDown) {
			
			const selectedSector = localStorage.getItem('selectedSector')
			if (selectedSector) {
				const selectedSec = JSON.parse(selectedSector).length ? JSON.parse(selectedSector) : ''
				setCheckedList(selectedSec)
				setCurrSectorFilter(selectedSec)
			}
		} else {
			localStorage.removeItem('selectedSector')
		}
		
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		if (userSector === 'all') fetchSectorOptionsData();
		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/dataestate/queriessummary') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				document.body.classList.add('custom-class');
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('datefilter-align-sector');
				document.body.classList.add('sector-label');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				document.body.classList.add('dashboard-panel-dataestate');
				document.body.classList.add('datefilter-align-sector');
			}
		}
        getQuerySummaryDetails(sector, filterMonth);
	}, []);

	// table
	const [tableName, setTableName] = useState('');
	const [typeName, setTypeName] = useState('');
	interface PRESTO {
		batchId_UsedId: string;
		queryDuration: string;
		greaterThanTenMin: string;
		queryStartTime: string;
		queryEndTime: string;
	}
	interface TERADATA {
		batchId_UsedId: string;
		queryDuration: string;
		queryStartTime: string;
		ueryEndTime: string;
	}
	interface Row{};
	function getColumns(): Array<Column<Row>> {
		if(typeName === 'PRESTO') {
			return [
				{
					key: 'batchId_UsedId',
					name: 'Batch Id/Used Id',
					minWidth: 150
				},
				{
					key: 'queryDuration',
					name: 'Query duration',
					minWidth: 170,
				},
				{
					key: 'queryDetails',
					name: 'Query details',
					minWidth: 170,
				},
				{
					key: 'greaterThanTenMin',
					name: '>10 Mins?',
					minWidth: 170,
				},
				{
					key: 'queryStartTime',
					name: 'Query start time',
					minWidth: 170,
				},
				{
					key: 'queryEndTime',
					name: 'Query end time',
					minWidth: 170,
				}
			];
		}
		else if(typeName === 'TERADATA') {
			return [
				{
					key: 'batchId_UsedId',
					name: 'Batch Id/Used Id',
					minWidth: 150
				},
				{
					key: 'queryDuration',
					name: 'Query duration',
					minWidth: 170,
				},
				{
					key: 'queryStartTime',
					name: 'Query start time',
					minWidth: 170,
				},
				{
					key: 'ueryEndTime',
					name: 'Query end time',
					minWidth: 170,
				},
			];
		}
		else {
			return [];
		}
	}
	const PrestoColumns =['Batch Id/Used Id','Query duration','Query details','>10 Mins?','Query start time','Query end time']
	const teradataColumns =['Batch Id/Used Id','Query duration','Query start time','Query end time']
	const [defaultColumnNames, setDynamicValue] = useState(['']);
	const [tableData, setTableData] = useState<Row[]>([]);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [isDetailVisible, setIsDetailVisible] = useState(false);
	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		summaryListingFunc(value, pageSize, sortingInfo, dimension);
	};
	const handlepageSizeChange = (event: any) => {
		summaryListingFunc(page, event.target.value, sortingInfo, dimension);
	};
	async function summaryListingFunc(pageNumber: any, size: any, sortingInfo: any, dimension: any) {
		const res = await QuerySummaryTable('query', dimension, sector, '1M');
		if (res.success) {
			console.log(res.data,'resdata')
			setTableData(res.data);
			if (res.data.page){
				setPaginationData(res.data.page);
			}
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
		}
	}
	const handleSorting = (sortingInfo: any) => {
		summaryListingFunc(page, pageSize, sortingInfo, dimension);
	};
	const handleOnclick = (value: any) => {
		setDynamicValue(['']);
		setTableData([]);
		setIsDetailVisible(true);
		setTypeName(value);
		if(value === 'PRESTO') {
			setDimension('presto')
			setDynamicValue(PrestoColumns);
			type Row = keyof PRESTO;
			setTableName("Presto Queries");
		}
		if(value === 'TERADATA') {
			setDynamicValue(teradataColumns);
			type Row = keyof TERADATA;
			setTableName("Teradata Queries");
			setDimension('teradata')

		}
		summaryListingFunc(page, pageSize, sortingInfo, dimension);
	}
   
	const clearMetricsSummary = () => {
		setTableData([]);
		setIsDetailVisible(false);
	}

	const handleDateFilter = (field: any, selectedDate: any) => {
		clearMetricsSummary();
		if (field === 'dateFilter') 
		{
			setIsDateOpen(false);
			isCustomMonth.current = false;
			setCustomMonth('');
			setFilterMonth(selectedDate.target.value);
			if(selectedDate.target.value === "2023-01") {
				isCustomMonth.current = true;
				setIsDateOpen(true);
				return;
			}
		}
		selectedDate = selectedDate.target.value
		setFilterDefaultMonth(selectedDate)
		setFilterMonth(selectedDate);
		getQuerySummaryDetails(sector, selectedDate)
	};
	const handleMonthOnclick = (value:any) => {
		isCustomMonth.current = false;
		setIsDateOpen(false);
		const selectedDate = customYear + "-" + value;
		setCustomMonth(selectedDate);
		getQuerySummaryDetails(sector, selectedDate);
		setDateFilterMenu(null);
	}
	const handleYearAdd = () => {
		let selectedYear = Number(customYear);
		selectedYear++;
		setCustomYear(selectedYear.toString());
	}
	const handleYearMinus = () => {
		let selectedYear = Number(customYear);
		selectedYear--;
		setCustomYear(selectedYear.toString());
	}
	const handleCustomOnclick = (e:any) => {
		if (e.target.textContent == 'Custom Month') {
			isCustomMonth.current = true;
			dateFilterMenuClick(e);
		}
		if(isCustomMonth.current)
			setIsDateOpen(true);
		else
			setIsDateOpen(false);

		if (e.type == 'click' && e.target.className.includes('MuiModal-backdrop')) {
			isCustomMonth.current = false;
			setDateFilterMenu(null);
			setIsDateOpen(false);
			if (customMonth == '') {
				setFilterMonth(filterDefaultMonth)
			}
		}
	}
	return (
		<div className="dashboard-panel-dataestate job-schedules-wrap storage-base">
			<div className="job-exexutions-dateFilter-div">
            {/* <div className="service-date-filter date-picker-summary"> */}
            <div className="service-date-filter date-picker-summary data-estate-right">
				{/* Code for Date filter */}
				<Stack>
					<div className="date-select">
						<FormControl fullWidth>
							<CalendarIcon className="date-icon" />
							<Select
								name="date-select"
								labelId="demo-simple-select-label"
								id="single-date-select"
								value={filterMonth}
								label="Date"
								onChange={(e) => {
										handleDateFilter('dateFilter', e);
								}}
								classes={{ select: classes.select }}
								IconComponent={() => null} 
								open={isDateOpen} 
								onClick={(e) =>handleCustomOnclick(e)}
								onOpen={() => setIsDateOpen(true)}
								onClose={() => setIsDateOpen(false)}
								
							>
								<MenuItem value={"0M"}>This Month</MenuItem>
								<MenuItem value={"1M"}>Last Month</MenuItem>
								<MenuItem value={"3M"}>Last 3 Months</MenuItem>
								<MenuItem value={"6M"}>Last 6 Months</MenuItem>
								<MenuItem value={"9M"}>Last 9 Months</MenuItem>
								<MenuItem value={"0Y"}>This Year</MenuItem>
								<MenuItem value={"1Y"}>Last Year</MenuItem>
								<MenuItem value={"2Y"}>Last 2 Years</MenuItem>
								<MenuItem value={"2023-01"}>Custom Month</MenuItem>
							</Select>
						</FormControl>
						{isCustomMonth.current && <Popover
								open={dateFilterMenu !== null}
								onClose={handleDateFilterMenuClose}
								className="custom-date-filter-menu"
								anchorReference="anchorPosition"
								anchorPosition={dateFilterMenu !== null ? { top: dateFilterMenu.mouseY, left: dateFilterMenu.mouseX } : undefined}
							><div className="custom-class-date date-custom">
							<div className='custom-dis'>
								<InputLabel>{customYear}</InputLabel>
								<div style={{marginLeft:'60px'}}>
									<Downarrow className='up-arrow' onClick={handleYearAdd}/>
									<Downarrow className='year-arrow' onClick={handleYearMinus}/>
								</div>
							</div>
							<div className='custom-dis'>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("01")}>January</Button>
								<Button  variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("02")}>February</Button>
							</div>
							<div className='custom-dis'>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("03")}>March</Button>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("04")}>April</Button>
							</div>
							<div className='custom-dis'>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("05")}>May</Button>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("06")}>Jun</Button>
							</div>
							<div className='custom-dis'>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("07")}>July</Button>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("08")}>August</Button>
							</div>
							<div className='custom-dis'>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("09")}>September</Button>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("10")}>October</Button>
							</div>
							<div className='custom-dis'>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("11")}>November</Button>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("12")}>December</Button>
							</div>
						</div></Popover>}
					</div>
				</Stack>
				</div>
				{/* Code for sector filter */}
                {/* <div className="service-sector-filter-cost service-sector-filter">
				<Stack className="sector-filter-dataestate">
					{userSector === 'all' ? (
						<FilterIcon onClick={sectorFilterClick} />
					) : (
						<div className="separeat_sector">Sector: {getDecodedToken()?.sector?.name}</div>
					)}
                    <><span className='sector-cta' onClick={sectorFilterClick}><span className='sector-label'>Sectors</span> <FilterIcon /></span></>
					<Popover
						open={filterMenu !== null}
						onClose={handleClose}
						className="filterContainer"
						anchorReference="anchorPosition"
						anchorPosition={filterMenu !== null ? { top: filterMenu.mouseY, left: filterMenu.mouseX } : undefined}
					>
						<div className="text-filter-row">
							<div className="selectAll-sec">
								<MenuItem className="selectAll">
									<FormControlLabel
										className={classes.checkbox}
										label="All"
										control={
											<Checkbox
												color="success"
												value="all"
												checked={itemsChecked}
												onChange={(e) => {
													selectAllCheck(e);
												}}
											/>
										}
									/>
								</MenuItem>
								<div className="checkbox-sec">
									{sectorOptionsList &&
										sectorOptionsList.map((filter: any) => (
											<MenuItem key={filter}>
												<FormControlLabel
													className={classes.checkbox}
													label={filter === true ? 'Yes' : filter === false ? 'No' : filter}
													control={
														<Checkbox
															color="success"
															value={filter}
															checked={
																(filter === true && checkedList.includes('true')) ||
																(filter === false && checkedList.includes('false')) ||
																checkedList.includes(filter)
															}
															onChange={(e) => {
																handleChange(e);
															}}
														/>
													}
												/>
											</MenuItem>
										))}
								</div>
							</div>
							<div className="buttonSec">
								<Button variant="contained" className={classes.cancelBtn} onClick={handleClose}>
									cancel
								</Button>
								<Button variant="contained" className={classes.okBtn} onClick={handleOkClick}>
									ok
								</Button>
							</div>
						</div>
					</Popover>
				</Stack>
                </div> */}
			</div>
			<div>
				<div className="page-table jobs-tabledata page-table-ibp-monitor">
					<div className="ibp-dashboard-wrapper">
					<Grid container gap={1}>
						<Grid item xs={12} p={2}
							className="dataestate-title m-0 bgcolor-blue col6" 
							onClick = {() =>{ !isEnvTypePROD ? handleOnclick('PRESTO') : '' } }
						>
							<div className="monitor-head">Presto Queries</div>
							<div className="dataestate-subheader">{PrestoSize == '-' ? <span className="loading">Loading...</span> : PrestoSize == '0' ? '0' : <>{formatNumberWithCommas(PrestoSize)} <span className="subheader-text">k</span></>}</div>
						</Grid>
						<Grid item xs={12} p={2}
							className="dataestate-title m-0 bgcolor-blue col6"
							onClick = {() =>{ !isEnvTypePROD ? handleOnclick('TERADATA') : '' } }
						>
							<div className="monitor-head">Teradata Queries</div>
							<div className="dataestate-subheader">{TeradataSize == '-' ? <span className="loading">Loading...</span> : TeradataSize == '0' ? '0' : <>{formatNumberWithCommas(TeradataSize)} <span className="subheader-text">k</span></>}</div>
						</Grid>
					</Grid>

					<Grid container gap={1} mt={2}>
						<Grid item xs={12} md={6} p={2} className="dataestate-title m-0 chart-widget bgcolor-blue">
								<div className="monitor-head ml-10">Presto Query Growth with Projections <i>(Trend)</i>  
								{!grafanaPrestoLoading && <span className="badge" title={`Last Year: ${formatNumberWithCommas((prestoQueryLastDec/1000).toFixed(2),true)}K - Previous Month: ${formatNumberWithCommas((prestoQueryCurrent/1000).toFixed(2))}K`}>
									{Math.sign(prestoBubbleValue) == -1 && <><img src={ArrowUpBlackIcon} className="arrow-icon-styles arrow-icon-styles-up-black down-arrow" /> {Math.abs(Math.round(prestoBubbleValue))}% /Yr</> }
									{Math.sign(prestoBubbleValue) != -1 && <><img src={ArrowUpBlackIcon} className="arrow-icon-styles arrow-icon-styles-up-black" /> {Math.abs(Math.round(prestoBubbleValue))}% /Yr</> }
									</span>
								}</div>
								{
									grafanaPrestoLoading ? 'Loading...' : <ReactECharts
									option={grafanaPrestoOptions}
									theme={{
										backgroundColor: '#1b1e35'
									}}
								/>}
						</Grid>
						{/* <Grid item xs={6} className="dataestate-title chart-widget chart-widget-query bgcolor-blue col6"> */}
						<Grid item xs={12} md={6} p={2} className="dataestate-title m-0 chart-widget bgcolor-blue mh-404">
							<div className="monitor-head ml-10">Teradata Query Growth with Projections <i>(Trend)</i>
							{!grafanaTeradataLoading && <span className="badge" title={`Last Year: ${formatNumberWithCommas((teradataQueryLastDec/1000).toFixed(2),true)}K - Previous Month: ${formatNumberWithCommas((teradataQueryCurrent/1000).toFixed(2))}K`}>
									{Math.sign(teradataBubbleValue) == -1 && <><img src={ArrowUpBlackIcon} className="arrow-icon-styles arrow-icon-styles-up-black down-arrow" /> {Math.abs(Math.round(teradataBubbleValue))}% /Yr</> }
									{Math.sign(teradataBubbleValue) != -1 && <><img src={ArrowUpBlackIcon} className="arrow-icon-styles arrow-icon-styles-up-black" /> {Math.abs(Math.round(teradataBubbleValue))}% /Yr</> }
									</span>
							}</div>
							{
								grafanaTeradataLoading ? 'Loading...' : <ReactECharts
								option={grafanaTeradataOptions}
								theme={{
									backgroundColor: '#1b1e35'
								}}
							/>}
						</Grid>
					</Grid>
						{isDetailVisible && <><h2 className="panel-legend--title mt-35 ">{tableName}</h2>
						<div className=" dataestate_tabledata">
							<Grid>
								{tableData.length > 0 ? (
									<CustomizedDataGrid
										tableColumns={getColumns()}
										tableData={tableData}
										tableType="sector"
										defaultColumnNames={defaultColumnNames}
										onSorting={handleSorting}
										currSorting={sortingInfo}
										page={page}
										pageSize={pageSize}
										paginationData={paginationData}
										listLength={tableData.length}
										handlepageSizeChange={handlepageSizeChange}
										handlePageChange={handlePageChange}
										isDateRangeFilter={false}
										isDisabled={false}
									/>
								) : (
									<Loader />
								)}
							</Grid>
						</div>
					</>}
					</div>
							
						
					</div>
				</div>							
			</div>		
		
	);
};

export default QueriesSummary;
