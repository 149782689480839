import { env } from '../env';
export const isLocalEnv = env.REACT_APP_IS_LOCAL && env.REACT_APP_IS_LOCAL === 'true';
export const url = env.REACT_APP_API_URL;
export const databricksurl = env.REACT_APP_API_URL + '/databricks/loginDatabricks';
export const portalUrl = env.REACT_APP_PORTAL_URL;
export const grafanaUrl = env.REACT_APP_GRAFANA_URL;
export const powerbiUrl = env.REACT_APP_POWERBIURL;
export const ctid = '42cc3295-cd0e-449c-b98e-5ce5b560c1d3';
export const platformCostObsDashboard = '/platformCostObs/dashboard';
export const teraDataDashboard = '/dashboard/business';
export const DataGrowthDashboard = '/dataGrowth/dashboard';
export const isEnvTypeLOCAL = env.REACT_APP_IS_LOCAL;
export const isEnvTypeDEV = env.REACT_APP_IS_DEV;
export const isEnvTypeUAT = env.REACT_APP_IS_QA;
export const isEnvTypePROD = env.REACT_APP_IS_PROD;

export const dashboardUrl = {
	administrator: `${grafanaUrl}/d/${env.REACT_APP_ADMINISTRATOR}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	operational: `${grafanaUrl}/d/${env.REACT_APP_OPERATIONAL}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	jobLineage: `${grafanaUrl}/d/${env.REACT_APP_JOBLINEAGE}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	jobExecAndSla: `${grafanaUrl}/d/${env.REACT_APP_JOBEXECANDSLA}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	jobPerformance: `${grafanaUrl}/d/${env.REACT_APP_JOBPERFORMANCE}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	executive: `${grafanaUrl}/d/${env.REACT_APP_EXECUTIVE}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	slaSeries: `${grafanaUrl}/d/${env.REACT_APP_SLASERIES}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	newJobSeries: `${grafanaUrl}/d/${env.REACT_APP_NEWJOBSERIES}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	reasonNotMet: `${grafanaUrl}/d/${env.REACT_APP_REASONNOTMET}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	jobRunHistory: `${grafanaUrl}/d/${env.REACT_APP_JOBRUNHISTORY}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	databricksSummary: `${grafanaUrl}/d/${env.REACT_APP_DATABRICKSSUMMARY}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	databricksgovernance: `${grafanaUrl}/d/${env.REACT_APP_DATABRICKSGOVERNANCE}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	dataBricksjobs: `${grafanaUrl}/d/${env.REACT_APP_DATABRICKSJOBS}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	dataBricksResourceOptimization: `${grafanaUrl}/d/${env.REACT_APP_DATABRICKSRESOURCEOPTIMIZATION}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	dataBricksCostSummary: `${grafanaUrl}/d/${env.REACT_APP_DATABRICKSCOSTSUMMARY}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	dataBricksTotalCostByWorkspace: `${grafanaUrl}/d/${env.REACT_APP_DATABRICKSTOTALCOSTBYWORKSPACE}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	grafanaFix: `${grafanaUrl}/d/${env.REACT_APP_GRAFANAFIX}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	tableausummary: `${grafanaUrl}/d/${env.REACT_APP_TABLEAUSUMMARY}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	powerbiCapability: env.REACT_APP_Capacity ? `${grafanaUrl}/d/${env.REACT_APP_Capacity}?orgId=1` : undefined,
	mlobsSummary: `${grafanaUrl}/d/${env.REACT_APP_ML_SUMMARY_DASHBOARD}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	mlobsFeatureDistribution: `${grafanaUrl}/d/${env.REACT_APP_ML_FEATURE_DISTRIBUTION}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	mlobsModelTargetDrift: `${grafanaUrl}/d/${env.REACT_APP_ML_TARGET_DRIFT}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	mlobsModelDataDrift: `${grafanaUrl}/d/${env.REACT_APP_ML_DATA_DRIFT}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	powerbiDataset: env.REACT_APP_DATASET ? `${grafanaUrl}/d/${env.REACT_APP_DATASET}?orgId=1` : undefined,
	controlMJobsInfrastructure: env.REACT_APP_CONTROLMJOBSINFRASTRUCTURE
		? `${grafanaUrl}/d/${env.REACT_APP_CONTROLMJOBSINFRASTRUCTURE}?orgId=1&kiosk`
		: undefined,
	autoSysJobsInfrastructure: env.REACT_APP_AUTOSYSJOBSINFRASTRUCTURE
		? `${grafanaUrl}/d/${env.REACT_APP_AUTOSYSJOBSINFRASTRUCTURE}?orgId=1&kiosk`
		: undefined,
	dmfHealthSummary: env.REACT_APP_HEALTHSUMMARY
		? `${grafanaUrl}/d/${env.REACT_APP_HEALTHSUMMARY}?orgId=1&kiosk`
		: undefined,
	dmfCatalogues: env.REACT_APP_CATALOGUES ? `${grafanaUrl}/d/${env.REACT_APP_CATALOGUES}?orgId=1&kiosk` : undefined,
	verificationValidationTableau: env.REACT_APP_TABLEAU ? `${grafanaUrl}/d/${env.REACT_APP_TABLEAU}?orgId=1` : undefined,
	tableauPerformance: `${grafanaUrl}/d/${env.REACT_APP_TABLEAUPERFORMANCE}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	tableauPerformanceExtract: `${grafanaUrl}/d/${env.REACT_APP_TABLEAUPERFORMANCEEXTRACT}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	tableauPerformanceCalendar: `${grafanaUrl}/d/${env.REACT_APP_TABLEAUPERFORMANCECALENDAR}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	tableauPerformanceJobsExecution: `${grafanaUrl}/d/${env.REACT_APP_TABLEAUPERFORMANCEJOBEXECUTION}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	tableauPerformanceJobSchedule: `${grafanaUrl}/d/${env.REACT_APP_TABLEAUPERFORMANCEJOBSCHEDULE}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	tableauPerformanceJobDetailView: `${grafanaUrl}/d/${env.REACT_APP_TABLEAUPERFORMANCEJOBDETAILSVIEW}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	tableauInfrastructure: env.REACT_APP_TABLEAUINFRASTRUCTURE
		? `${grafanaUrl}/d/${env.REACT_APP_TABLEAUINFRASTRUCTURE}?orgId=1&kiosk`
		: undefined,
	dataBricksjobsWorkspaceFailure: `${grafanaUrl}/d/${env.REACT_APP_DATABRICKSJOBSWORKSPACEFAILURE}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	dataBricksjobsClusterFailure: `${grafanaUrl}/d/${env.REACT_APP_DATABRICKSJOBSCLUSTERFAILURE}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	pcobsResourceMappingUrl: `${grafanaUrl}/d/${env.REACT_APP_PCOBS_RESOURCE_MAPPING}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	dataBricksCostSummaryWorkSpace: `${grafanaUrl}/d/${env.REACT_APP_DATABRICKSCOSTSUMMARYWORKSPACE}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	dataBricksCostSummaryCluster: `${grafanaUrl}/d/${env.REACT_APP_DATABRICKSCOSTSUMMARYCLUSTER}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	verificationValidationPowerBi: env.REACT_APP_VV_POWERBI
		? `${grafanaUrl}/d/${env.REACT_APP_VV_POWERBI}?orgId=1`
		: undefined,
	tableauGovernance: `${grafanaUrl}/d/${env.REACT_APP_TABLEAUGOVERNANCE}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	powerbiUsageGovernance: env.REACT_APP_POWERBI_USAGE_GOVERNANCE
		? `${grafanaUrl}/d/${env.REACT_APP_POWERBI_USAGE_GOVERNANCE}?orgId=1`
		: undefined,
	powerbiDataGrowthCost: env.REACT_APP_POWERBI_DATAGROWTH_COST
		? `${grafanaUrl}/d/${env.REACT_APP_POWERBI_DATAGROWTH_COST}?orgId=1`
		: undefined,
	tableauGovernanceMostUsedExtract: `${grafanaUrl}/d/${env.REACT_APP_TABLEAUGOVERNANCEMOSTUSEDEXTRACTS}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	tableauGovernanceMostUsedViews: `${grafanaUrl}/d/${env.REACT_APP_TABLEAUGOVERNANCEMOSTUSEDVIEWS}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	tableauGovernanceOrphaned: `${grafanaUrl}/d/${env.REACT_APP_TABLEAUGOVERNANCEORPHANED}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	tableauGovernanceUnusedExtracts: `${grafanaUrl}/d/${env.REACT_APP_TABLEAUGOVERNANCEUNUSEDEXTRACTS}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	dataGrowthTableauTotalCountOfExtracts: `${grafanaUrl}/d/${env.REACT_APP_DATAGROWTHTABLEAUTOTALCOUNTOFEXTRACTS}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	dataGrowthTableauTotalSizeOfExtract: `${grafanaUrl}/d/${env.REACT_APP_DATAGROWTHTABLEAUTOTALSIZEOFEXTRACT}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	dataGrowthTableauTotalCountOfDataSource: `${grafanaUrl}/d/${env.REACT_APP_DATAGROWTHTABLEAUTOTALCOUNTOFDATASOURCES}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	prestoDataGrowthofQueries: `${grafanaUrl}/d/${env.REACT_APP_PRESTODATAGROWTHOFQUERIES}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	prestoSummaryPanels: `${grafanaUrl}/d/${env.REACT_APP_PRESTOSUMMARYPANELS}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	prestoSummarySecondPanels: `${grafanaUrl}/d/${env.REACT_APP_PRESTOSUMMARYSECONDPANELS}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	powerbiInfrastructure: env.REACT_APP_POWERBIINFRASTRUCTURE
		? `${grafanaUrl}/d/${env.REACT_APP_POWERBIINFRASTRUCTURE}?orgId=1&kiosk`
		: undefined,
	dataGrowthDataBricksTotalCountOfTables: `${grafanaUrl}/d/${env.REACT_APP_DATAGROWTHDATABRICKSTOTALCOUNTOFTABLES}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	dataGrowthDataBricksTotalSizeOfTables: `${grafanaUrl}/d/${env.REACT_APP_DATAGROWTHDATABRICKSTOTALSIZEOFTABLES}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	controlsTestingDashboard: env.REACT_APP_CONTROLSTESTINGDASHBOARD
		? `${grafanaUrl}/d/${env.REACT_APP_CONTROLSTESTINGDASHBOARD}?orgId=1`
		: undefined,
	IBPDashboard: `${grafanaUrl}/d/${env.REACT_APP_IBP_TURKEY}?orgId=1&kiosk`,
	IBPCostService: `${grafanaUrl}/d/${env.REACT_APP_IBP_COST}?orgId=1&kiosk`
};

export const pcobsDashboardUrl = {
	resourceMappingUrl: `${grafanaUrl}/d/${env.REACT_APP_PCOBS_RESOURCE_MAPPING_NEW}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`,
	resourceGroupDetailsUrl: `${grafanaUrl}/d/${env.REACT_APP_PCOBS_RESOURCE_GROUP_DETAILS}?orgId=1&kiosk&var-pepsicoUrl=${portalUrl}`
};
export const powerBIdashboardUrl = {
	PBNADashboard: `${powerbiUrl}/reportEmbed?reportId=${env.REACT_APP_PBNA_DASHBOARD}`,
	LATAMDashboard: `${powerbiUrl}/reportEmbed?reportId=${env.REACT_APP_LATAM_DASHBOARD}`,
	PFNADashboard: `${powerbiUrl}/reportEmbed?reportId=${env.REACT_APP_PFNA_DASHBOARD}`,
	CGFDashboard: `${powerbiUrl}/reportEmbed?reportId=${env.REACT_APP_CGF_DASHBOARD}`,
	AMESADashboard: `${powerbiUrl}/reportEmbed?reportId=${env.REACT_APP_AMESA_DASHBOARD}`,
	EUROPEDashboard: `${powerbiUrl}/reportEmbed?reportId=${env.REACT_APP_EUROPE_DASHBOARD}`,
	IBPBDRPowerBIDashboard: `${powerbiUrl}/reportEmbed?reportId=${env.REACT_APP_IBP_BDR_PBI}`
};
export const DataGrowthDashboardUrl = {
	AvgdatabasegrowthDashboard: `${powerbiUrl}/reportEmbed?reportId=${env.REACT_APP_AVG_DATABASE_GROWTH_DASHBOARD}`,
	TeradataProductionDashboard: `${powerbiUrl}/reportEmbed?reportId=${env.REACT_APP_TERADATA_PRODUCTION_DASHBOARD}`
};
export const endpoints = {
	login: '/user/login',
	jobMapping: '/job/mapping',
	jobListing: '/job/list',
	jobListingFilter: '/job/listFilter',
	jobSearch: '/job/search',
	jobFilter: '/job/filter',
	jobOverview: '/job',
	jobUpdate: '/job/update',
	jobRunHistory: '/job/executions',
	jobDependencies: '/job/jobDependencies',
	jobRunUpdate: '/job/execution/update',
	jobExecutions: '/job/schedules',
	jobExecutionsFilter: '/job/schedulesFilter',
	jobFutureExecutions: '/job/futureSchedules',
	jobFutureExecutionsFilter: '/job/futureSchedulesFilter',
	jobExeActionTaken: '/dashboard/actionTakenData',
	jobRunSchedules: '/job/run/schedules',
	boxRunsList: '/job/box_list',
	jobExecutionsMetrices: '/job/schedules/metrics',
	sector: '/master/sectors',
	databricksSectors: '/databricks/sectorsLookup',
	workspaceList: '/databricks/workspacesLookup',
	system: '/master/systems',
	process: '/master/process',
	machines: '/master/machines',
	reports: '/job/report',
	// users: '/user/list',
	notificationAlerts: '/notification/alerts',
	business: '/dashboard/business',
	businessPiechart: '/dashboard/business/piechart',
	reasonsOfSla: '/master/slaNotMetReasons',
	processRuns: '/dashboard/business/processRuns',
	dailyCritical: '/dashboard/business/dailyCriticalPie',
	jobDependencyChart: '/job/jobDependencyChart',
	databricksSummary: '/databricks/summary/workspace',
	databricksWorkspaceSpent: '/databricks/costSummary/workspaceSpent',
	databricksProjectSpent: '/databricks/costSummary/projectSpent',
	databricksCPUUtilization: '/databricks/resourceOptimization/cpuUtil',
	databricksMemoryUtilization: '/databricks/resourceOptimization/memoryUtil',
	governanceStaleUsers: '/databricks/governance/staleUsers',
	governanceRunningJobs: '/databricks/governance/runningJobs',
	databricksJobs: '/databricks/jobs/runs',
	tableauWorkbooks: '/tableau/summary/workbooks',
	tableauUsers: '/tableau/summary/users',
	models: '/mlobs/models',
	model: '/mlobs/model',
	modelPerformanceMetric: '/mlobs/model/performanceMetric',
	modelfeatureDriftMetrics: '/mlobs/model/featureDriftMetric',
	tableauPerformance: '/tableau/performance/jobs',
	costSummaryGrafana: '/databricks/costSummary/cost',
	resourceGroups: '/pcobs/mapping/list',
	resourceGroup: '/pcobs/mapping',
	resourceGroupSectors: '/pcobs/sectors/list',
	resource: '/pcobs/resource',
	jobsGrafana: '/databricks/jobs/jobsChart',
	reasonChart: '/dashboard/business/slaNotMetReasonsChart',
	tableauPerformanceDrilldown: '/tableau/performance/extractTrendDetails',
	summaryGrafana: '/databricks/summary/summaryChart',
	resourceOptimizationGrafana: '/databricks/resourceOptimization/resourceOptimizationChart',
	governanceGrafana: '/databricks/governance/governanceChart',
	tableauGovernanceDetailView: '/tableau/governance/detailView',
	tableauGovernanceUsers: '/tableau/governance/users',
	tableauGovernanceOrphanUsers: '/tableau/governance/orphanUsersDetails',
	tableauGovernanceOrphanExtract: '/tableau/governance/orphanExtractsDetails',
	tableauGovernanceDrilldown: '/tableau/governance/unusedExtracts',
	tableauExtractFailureRuleDrilldown: '/rules/observations',
	prestoSummaryDrilldown: '/presto/tableDetails',
	sectorSql: '/master/sql_sectors',
	datagrowthDatabricksStorage: '/databricks/datagrowth/storageAccount',
	schedulers: '/master/schedulers',
	ibpJobListing: '/ibp/jobs',
	ibpJobRunsListing: '/ibp/ibpjobruns',
	ibpMonitorMetrics: '/ibp/metrics',
	ibpCostByService: '/ibpcost/costByService',
	ibpCostGrafana: '/ibpcost/detailedReactChart',
	biApplicationSummary: '/dataestate/biAppSummary',
	serviceRequestSummary: '/dataestate/serviceRequestSummary',
	annualSpendDataSourceIncidents: '/dataestate/annualSpendDataSourceIncidents',
	dataProcessingTrend: '/dataestate/getTrends',
	dataEstatePerformance: '/dataestate/getPerformance ',
	dataEstateSummary: '/dataestate/summary',
	biApplicationTrend: '/dataestate/getTrends',
	deCostSummary: '/dataestate/costSummary',
	dataEstateOverallSummary: '/dataestate/overviewSummary',
	dataEstateExecutiveSummary: '/dataestate/executiveSummary',
	azureMonthlyCost: '/dataestate/azureMonthlyCost',
	deStorageSummary: '/dataestate/summary',
	deStorageTrends: '/dataestate/getTrends',
	queriesSummary: '/dataestate/queries',
	queriesTrends: '/dataestate/getTrends',
	chartData: '/dataestate/generateSLAChartData',
	incidentCriticalJobReport: '/dataestate/incidentCriticalJobsReport',
	incidentCriticalSlaReport: '/dataestate/incidentSlaReport',
	overviewSummaryMetrics: '/dataestate/overviewSummaryMetrics',
	summaryTable: '/dataestate/getKpiDetails',
	databricksClusters: '/databricks/databricksClusters',
	finOpsOverviewSummary: '/finops/overviewSummary',
	finOpsProgramSummary: '/finops/programSummary',
	finOpsDetailSummary: '/finops/detailSummary',
	finOpsResourceDetail: '/finops/resourceDetail',
	finOpsCostKpi: '/finops/costKpi',
	lastSyncDate: '/dataestate/lastSyncDate',
	filterDetail: '/finops/filterDetail',
	crossEnvKPI: '/dataestate/crossEnvTableauKpis',
	dataPipelineSectorList: '/dataPipeline/sectors/list',
	dataPipelineProgramList: '/dataPipeline/program',
	dataPipelineProgramById: '/dataPipeline/program/viewProgam',
	createProgram: '/dataPipeline/program/create',
	editProgram: '/dataPipeline/program/update',
	deleteProgram: '/dataPipeline/program/delete',
	createProject: '/dataPipeline/project/create',
	deleteProject: '/dataPipeline/project/delete',
	deleteSchedule: '/dataPipeline/schedules/delete',
	editProject: '/dataPipeline/project/update',
	projectListByProgram: '/dataPipeline/program/projectList',
	majorIncidentDetail: '/dataestate/majorIncidentLastMonth',
	teradataJobNameList: '/dataPipeline/schedules/jobNameList',
	searchScheduleList: '/dataPipeline/schedules/selectSchedule',
	getScheduleList: '/dataPipeline/schedules/getSchedules',
	getScheduleExecutionsList: '/dataPipeline/schedules/getSchedulesExecutions',
	getOwnerList: '/dataPipeline/project/searchUser',
	getClarityProject: '/dataPipeline/project/loadClarityProject',
	dataPipelineProjectById: '/dataPipeline/project/viewProject',
	addScheduleToProject: '/dataPipeline/schedules/addToProject',
	customDashboard: '/dataPipeline/customDashboards',
	databricksClustersCount: '/dataestate/databricksClustersCount',
	dataPipelineProjectSlaMetrics: '/dataPipeline/schedules/getSlaMetrics',
	slaCountDetails: '/dataPipeline/schedules/slaCount',
	deleteScheduleFromProject: '/dataPipeline/schedules/deleteSchedules',
	finOpsExecutiveSummary: '/finops/executiveSummary',
	powerBICapacityMetrics:'/powerbi/capacities',
	dpProjectKpiCount: '/dataPipeline/schedules/kpiData',
	getNormalCompletion: '/dataPipeline/schedules/normalCompletion',
};
