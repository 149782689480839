import React from 'react';
import UnderDevelopment from '../../assets/under-construction.svg';
import AnalyticsVisualization from '../framework/analyticsVisualization';
import { dashboardUrl } from '../../service/config';

const DataGrowthObs = () => {
    return (
        <div>
            {dashboardUrl.powerbiDataGrowthCost !== undefined ? (
                <div style={{ marginTop: '-102px' }}>
                    <AnalyticsVisualization
                        params={{
                            dashboardUrl: dashboardUrl.powerbiDataGrowthCost,
                            className: 'powerBiDataGrowthCostContainer',

                            otherParams: {
                                from: 'now-6h',
                                to: 'now'
                            },
                            seamless: true
                        }}
                    />
                </div>
            ) : (
                <div className='under-development-container'>
                    <img src={UnderDevelopment} />
                    <p className='under-development-text'>Dashboard under development!</p>
                </div>
            )}
        </div>
    );
};

export default DataGrowthObs;
