import './loader.scss';
import CircularProgress from '@mui/material/CircularProgress';

const Loader = () => {
	return (
		<div className="loader">
			<CircularProgress
				style={{ color: '#ffffff', height: 80, width: 80, position: 'fixed', top: '50%', left: '50%', zIndex: 9999 }}
			/>
		</div>
	);
};

export default Loader;
