import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
	jobText: {
		fontFamily: 'Montserrat',
		fontStyle: 'italic',
		fontSize: '13px',
		paddingTop: '6px !important',
		lineHeight: '10px',
		color: '#71758F',
		marginTop: '15px !important',
		paddingLeft: '10px !important',
	},
	jobTextProject: {
		fontFamily: 'Montserrat',
		fontStyle: 'italic',
		fontSize: '13px',
		paddingTop: '6px !important',
		lineHeight: '10px',
		color: '#71758F',
		marginTop: '16px !important',
		paddingLeft: '10px !important',
	},
	jobTextProject1: {
		fontFamily: 'Montserrat',
		fontStyle: 'italic',
		fontSize: '13px',
		float: 'right',
		textAlign: 'right',
		color: '#71758F',
		lineHeight: '10px',
		marginTop: '7px !important'
	},
	jobText1: {
		fontFamily: 'Montserrat',
		fontStyle: 'italic',
		fontSize: '13px',
		float: 'right',
		textAlign: 'right',
		color: '#71758F',
		lineHeight: '10px',
		marginTop: '5px !important'
	},
	filterContainer: {
		background: '#1B1E35',
		borderRadius: '8px',
		height: 'max-content',
		width: '300px',
		position: 'absolute',
		top: '32%'
	},
	textfield1: {
		top: '0px',
		width: '97%',
		'& input::placeholder': {
			fontSize: '12px',
			fontFamily: 'Montserrat',
			textTransform: 'capitalize',
			color: '#E5E5E5'
		},
		'& input:active': {
			background: 'transparent'
		},
		'& input:focus': {
			background: 'transparent'
		},
		'& input:hover': {
			background: 'transparent'
		},
		'& input': {
			paddingLeft: '0px',
			background: 'transparent'
		},
		'& input:placeholder-shown': {
			background: 'transparent',
			backgroundColor: 'transparent'
		},
		background: 'rgba(113, 117, 143, 0.4)',
		backdropFilter: 'blur(10px)',
		borderRadius: '4px',
		marginLeft: '0px !important',
		marginBottom: '10px !important',
		fontFamily: 'Montserrat',
		paddingLeft: '10px !important',
		color: 'white'
	},
	searchBar: {
		float: 'left'
	},
	checkbox: {
		color: '#3D3A50'
	},
	cancelBtn: {
		background: '#1B1E35 !important',
		backgroundColor: '#1B1E35 !important',
		borderRadius: '4px 0px 0px 4px !important',
		width: '50%',
		color: '#00D08D !important',
		fontWeight: '600 !important',
		textTransform: 'capitalize',
		fontFamily: 'Montserrat !important'
	},
	okBtn: {
		background: '#00D08D !important',
		backgroundColor: '#00D08D !important',
		borderRadius: '4px 0px 0px 4px !important',
		width: '50%',
		color: 'white !important',
		fontWeight: '600 !important',
		textTransform: 'capitalize',
		fontFamily: 'Montserrat !important'
	},
	lineage: {
		paddingLeft: '8%',
		paddingTop: '9%',
		fontFamily: 'Montserrat',
		fontStyle: 'italic',
		fontSize: '13px',
		color: '#71758F'
	},
	predecessorText: {
		fontFamily: 'Montserrat',
		fontStyle: 'italic',
		fontSize: '13px',
		float: 'right',
		textAlign: 'right',
		color: '#71758F',
		lineHeight: '10px',
		marginTop: '7px !important',
		paddingTop: '16px'
	},
	secessorText: {
		fontFamily: 'Montserrat',
		fontStyle: 'italic',
		fontSize: '13px',
		float: 'right',
		textAlign: 'right',
		color: '#71758F',
		lineHeight: '10px',
		marginTop: '7px !important',
		paddingTop: '16px'
	},
	lineageBox: {
		paddingLeft: '42px'
	},
	error: {
		color: 'red',
		paddingLeft: '8%',
		fontFamily: 'Montserrat',
		fontStyle: 'italic',
		fontSize: '13px'
	},
	select: {
		'&:before': {
			display: 'none',
		},
		'&:after': {
			display: 'none',
		},
	},
	root: {
		'& .MuiInputBase-root': {
			border: 'none',
		},
	},
});

export default useStyles;
