import React, { useEffect, useState, useRef } from 'react';
import { Grid } from '@mui/material';
import moment from 'moment-timezone';

import type { Column } from 'react-data-grid';
import '../resourceGroups/resourceGroup.scss';
import '../resourceMapping.scss';
import Loader from '../../Loader/loader';
import { Link, useSearchParams, useParams, useLocation } from 'react-router-dom';
import ResourceMappingIcon from '../../../assets/resourceMapping.svg';
import { Stack, Paper, Typography, Table, TableBody, TableCell, TableRow, Chip } from '@mui/material';
import CommonDetails from './commonDetails';
import ResourcesListing from './resourcesListing';
import { getResourceGroups } from '../../../service/service';

import AnalyticsVisualization from '../../framework/analyticsVisualization';
import { pcobsDashboardUrl } from '../../../service/config';

export default function ResourceGroupDetails({ sharedValue }: any) {
	const location = useLocation();
	const params = useParams();

	const dataFetchedRef = useRef(false);
	const [commonDetails, setCommonDetails] = useState(location.state);

	const [searchParams, setSearchParams] = useSearchParams();
	const hasDrillDown = searchParams.has('isDrillDowned');

	const resetSearchParams = () => {
		if (hasDrillDown) {
			searchParams.delete('status');
			searchParams.delete('isDrillDowned');
			setSearchParams(searchParams);
		}
	};

	async function getResourceGroupDetails() {
		const res = await getResourceGroups('1', '1', {}, {}, null, null, null, null,commonDetails.resource_group_id);
		if (res.success) {
			setCommonDetails(res.data.data[0]);
		}
	}

	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		getResourceGroupDetails();
		const activeId = document.getElementById('root');
		if (activeId) {
			const mainContent = document.getElementsByClassName('main-content')[0];

			if (mainContent) {
				// Use type assertion to let TypeScript know that myElement has a style property
				(mainContent as HTMLElement).style.overflow = 'scroll';
				(mainContent as HTMLElement).style.height = 'calc(100vh - 35px)';
			}

			activeId.classList.add('jobs-list-table');
			activeId.classList.remove('root');
			document.body.classList.add('jobs-exe-datas');
			document.body.classList.add('jobs-list-datas');
		}
	}, []);

	return (
		<>
			<Stack className='details-view'>
				<Link to={`/platformCostObs/resourceMapping`} className="titleLink">
					<span className="pcops-resourceGroup-menu ">
						<img src={ResourceMappingIcon} className="pcobs-details-icon" />
						<span className="pcobs-details-header-name">Resource Mapping</span>
					</span>
				</Link>
				<span className="pcobs-symbol">&gt;</span>{' '}
				<span className="pcobs-page-title">{commonDetails?.resource_group}</span>
			</Stack>
			<Stack direction="row" alignItems="flexStart" spacing={2} className="resourceGroup-details-panel-stack">
				{commonDetails && <CommonDetails sharedValue={commonDetails} />}
				<Stack className="w-40">
					<AnalyticsVisualization
						params={{
							dashboardUrl: pcobsDashboardUrl.resourceGroupDetailsUrl,
							className: 'pcobsResourceGroupDetailsIframeContainer',
							otherParams: {
								from: 'now-6h',
								to: 'now',
								'var-totRes': commonDetails?.resource_count,
								'var-notTagged': commonDetails?.resources_not_tagged_count
							},
							seamless: true
						}}
					/>
				</Stack>
			</Stack>
			{commonDetails && <ResourcesListing sharedValue={commonDetails} updateSharedValue={getResourceGroupDetails} />}
		</>
	);
}
