import React, { useState, useEffect, useRef } from 'react';
import { Button, Checkbox, MenuItem, Popover, Stack, ListItemText, FormControlLabel } from '@mui/material';
import { getDecodedToken, getSector } from '../../service/service';
import useStyles from '../jobs/jobs_styles';
import AnalyticsVisualization from '../framework/analyticsVisualization';
import { dashboardUrl } from '../../service/config';
import { ReactComponent as FilterIcon } from '../../assets/ml_summary_filter.svg';
import './dataGrowth.scss';

const TableauDataGrowth = () => {
	interface CheckedList {
		sectors: string[];
		site: string[];
		environment: string[];
	}
	const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
	const classes = useStyles();
	const dataFetchedRef = useRef(false);
	const [sectorOptionsList, setSectorOptionsList] = useState<string[]>([]);
	const [filterMenu, setFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	const [itemsChecked, setItemsChecked] = useState(false);
	const [checkedList, setCheckedList] = useState<CheckedList>({
		sectors: [],
		site: [],
		environment: []
	});
	const [selectedFilter, setSelectedFilter] = useState<string>('Sectors');
	const [selectedSite, setSelectedSite] = useState<string[]>([]);
	const [selectedEnvironment, setSelectedEnvironment] = useState<string>('all');
	const [selectedSector, setSelectedSector] = useState<string[]>([]);

	const filterClick = (event: any) => {
		event.preventDefault();
		setFilterMenu(
			filterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 20,
						mouseY: parseInt(event.clientY) - 6
				  }
				: null
		);
	};

	const handleClose = () => {
		localStorage.removeItem('presto-summary-sector-filter');
		setCheckedList({
			sectors: [],
			site: [],
			environment: []
		});
		setItemsChecked(false);
	};

	const handleMenuItemClick = (filter: string) => {
		setSelectedFilter(filter);
		if (filter === 'Sectors') {
			setItemsChecked(false);
		}
	};

	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;

		if (userSector === 'all') fetchSectorOptionsData();
		let storedSectorFilter = localStorage.getItem('presto-summary-sector-filter')
			? JSON.parse(localStorage.getItem('presto-summary-sector-filter') ?? '')
			: null;
		if (storedSectorFilter) {
			setCheckedList(storedSectorFilter);
			setSelectedSector(storedSectorFilter.sectors);
		}
	}, []);

	async function fetchSectorOptionsData() {
		const sectorRes = await getSector('1', '100', '');
		if (sectorRes.success) {
			setSectorOptionsList(
				sectorRes.data.sector.map((x: any) => {
					if (x.name === 'S&T') {
						return 'SNT';
					} else if (x.name === 'Europe') {
						return 'EUROPE';
					} else {
						return x.name;
					}
				})
			);
		}
	}

	const handleOkClick = () => {
		let updatedSiteList: string[] = [];
		let updatedEnvironmentList: string;

		if (checkedList.site.includes('All')) {
			updatedSiteList = ['Default'];
		} else {
			updatedSiteList = checkedList.site;
		}

		if (checkedList.environment.includes('Cockpit') && checkedList.environment.includes('Global')) {
			updatedEnvironmentList = 'all';
		} else if (checkedList.environment.includes('Global')) {
			updatedEnvironmentList = 'Global';
		} else if (checkedList.environment.includes('Cockpit')) {
			updatedEnvironmentList = 'Cockpit';
		} else {
			updatedEnvironmentList = '';
		}

		setSelectedEnvironment(updatedEnvironmentList);
		setSelectedSector(checkedList.sectors);
		setSelectedSite(updatedSiteList);
		console.log(selectedSite, selectedEnvironment, selectedSite, 'checked list');
		handleViewClose();
	};

	const handleViewClose = () => {
		setFilterMenu(null);
	};

	const handleSectorChange = (updatedSelection: string[]) => {
		setCheckedList((prevState) => ({
			...prevState,
			sectors: updatedSelection
		}));
	};

	const selectAllCheck = (e: any) => {
		const { checked } = e.target;
		if (selectedFilter === 'Site') {
			if (checked) {
				setCheckedList((prevState) => ({
					...prevState,
					site: ['All', 'Default']
				}));
			} else {
				setCheckedList((prevState) => ({
					...prevState,
					site: []
				}));
			}
		} else if (selectedFilter === 'Environment') {
			const allOptions = ['All', 'Global', 'Cockpit'];
			setCheckedList((prevState) => ({
				...prevState,
				environment: checked ? allOptions : []
			}));
		} else {
			setCheckedList((prevState) => ({
				...prevState,
				sectors: checked ? sectorOptionsList : []
			}));
		}
		setItemsChecked(checked);
	};

	const handleSiteChange = (value: string) => {
		let updatedList: string[];

		if (value === 'All') {
			updatedList = checkedList.site.includes('All') ? [] : ['All', 'Default'];
		} else {
			if (checkedList.site.includes('All')) {
				updatedList = [];
			} else {
				updatedList = checkedList.site.includes(value)
					? checkedList.site.filter((item) => item !== value)
					: [...checkedList.site, value];
			}
		}

		setCheckedList((prevState) => ({
			...prevState,
			site: updatedList
		}));
	};

	const handleEnvironmentChange = (value: string) => {
		let updatedList: string[];

		if (value === 'All') {
			updatedList = ['Cockpit', 'Global'];
		} else {
			updatedList = checkedList.environment.includes(value)
				? checkedList.environment.filter((item) => item !== value)
				: [...checkedList.environment, value];
		}

		setCheckedList((prevState) => ({
			...prevState,
			environment: updatedList
		}));
	};

	return (
		<div className="datagrowth-panel job-schedules-wrap">
			<div className="datagrowth-exexutions-dateFilter-div">
				<div className="datagrowth-filter-wrapper">
					<Stack>
						{userSector === 'all' ? (
							<FilterIcon onClick={filterClick} className="sector-filter" />
						) : (
							<div className="sector-filter-text">Sector: {getDecodedToken()?.sector?.name}</div>
						)}

						<Popover
							open={filterMenu !== null}
							onClose={handleViewClose}
							className="filterContainers filter-base"
							anchorReference="anchorPosition"
							anchorPosition={filterMenu !== null ? { top: filterMenu.mouseY, left: filterMenu.mouseX } : undefined}
						>
							<div className="text-filter-rows">
								<div className="filter-header">
									<div className="filters-text"> Filters</div>
									<div className="buttonsSec1">
										<Button variant="contained" className={classes.cancelBtn} onClick={handleClose}>
											CLEAR
										</Button>
										<Button variant="contained" className={classes.okBtn} onClick={handleOkClick}>
											APPLY
										</Button>
									</div>
								</div>
								<div className="dataGrowthselectAll-sec1">
									<div>
										<table>
											<ul className="list-items">
												{['Sectors', 'Site', 'Environment'].map((filter: string) => (
													<ul key={filter}>
														<MenuItem
															className="list-items"
															onClick={() => handleMenuItemClick(filter)}
															style={{ background: filter === selectedFilter ? '#676a88' : '' }}
														>
															<ListItemText className="selected">{filter}</ListItemText>
														</MenuItem>
													</ul>
												))}
											</ul>
										</table>
									</div>
								</div>
								{selectedFilter !== 'Site' && selectedFilter !== 'Environment' ? (
									<ul className="filterContainers MuiPaper-root sector sector-list">
										<MenuItem className="dataGrowthselectAll-sec2">
											<FormControlLabel
												className="checkbox"
												label="All"
												control={
													<Checkbox
														className="data-check"
														color="success"
														value="all"
														checked={itemsChecked}
														onChange={(e) => {
															selectAllCheck(e);
														}}
													/>
												}
											/>
										</MenuItem>
										<ul className="checkbox-sec-datagrowth">
											{sectorOptionsList &&
												sectorOptionsList.map((filter: any) => (
													<MenuItem key={filter} className="all-sector">
														<FormControlLabel
															className={classes.checkbox}
															label={filter}
															control={
																<Checkbox
																	className="data-check"
																	color="success"
																	value={filter}
																	checked={checkedList.sectors.includes(filter)}
																	onChange={() => {
																		const updatedSelection = checkedList.sectors.includes(filter)
																			? checkedList.sectors.filter((item) => item !== filter)
																			: [...checkedList.sectors, filter];
																		handleSectorChange(updatedSelection);
																	}}
																/>
															}
														/>
													</MenuItem>
												))}
										</ul>
									</ul>
								) : selectedFilter === 'Site' ? (
									<div className="filterContainers MuiPaper-root site">
										<div className="site-align">
											<MenuItem className="">
												<FormControlLabel
													className={classes.checkbox}
													label="All"
													control={
														<Checkbox
															className="data-check"
															color="success"
															value="All"
															checked={checkedList.site.includes('All')}
															onChange={() => handleSiteChange('All')}
														/>
													}
												/>
											</MenuItem>
											<MenuItem className="">
												<FormControlLabel
													className={classes.checkbox}
													label="Default"
													control={
														<Checkbox
															className="data-check"
															color="success"
															value="Default"
															checked={checkedList.site.includes('Default')}
															onChange={() => handleSiteChange('Default')}
														/>
													}
												/>
											</MenuItem>
										</div>
									</div>
								) : selectedFilter === 'Environment' ? (
									<div className="filterContainers MuiPaper-root env">
										<div className="site-align">
											<MenuItem>
												<FormControlLabel
													className={classes.checkbox}
													label="All"
													control={
														<Checkbox
															className="data-check"
															color="success"
															value="all"
															checked={itemsChecked}
															onChange={(e) => {
																selectAllCheck(e);
															}}
														/>
													}
												/>
											</MenuItem>
											<MenuItem key="Global">
												<FormControlLabel
													className={classes.checkbox}
													label="Global"
													control={
														<Checkbox
															className="data-check"
															color="success"
															value="Global"
															checked={checkedList.environment.includes('Global')}
															onChange={() => handleEnvironmentChange('Global')}
														/>
													}
												/>
											</MenuItem>
											<MenuItem key="Cockpit">
												<FormControlLabel
													className={classes.checkbox}
													label="Cockpit"
													control={
														<Checkbox
															className="data-check"
															color="success"
															value="Cockpit"
															checked={checkedList.environment.includes('Cockpit')}
															onChange={() => handleEnvironmentChange('Cockpit')}
														/>
													}
												/>
											</MenuItem>
										</div>
									</div>
								) : (
									<div className="separeat_sector"></div>
								)}
							</div>
						</Popover>
					</Stack>
				</div>

				<AnalyticsVisualization
					params={{
						dashboardUrl: dashboardUrl.dataGrowthTableauTotalCountOfDataSource,
						className: 'dataGrowthframeContainer',
						otherParams: {
							'var-sector': selectedSector.join(','),
							'var-instance': selectedEnvironment.split(','),
							'var-site': selectedSite
						},
						seamless: true
					}}
				/>

				<div className="second-iframe-container-tableau">
					<div className="left-iframe-div-tableau">
						<AnalyticsVisualization
							params={{
								dashboardUrl: dashboardUrl.dataGrowthTableauTotalCountOfExtracts,
								className: 'dataGrowthLeftframeContainer',
								otherParams: {
									'var-sector': selectedSector.join(','),
									'var-instance': selectedEnvironment.split(','),
									'var-site': selectedSite
								},
								seamless: true
							}}
						/>
					</div>
					<div className="right-iframe-div-tableau">
						<AnalyticsVisualization
							params={{
								dashboardUrl: dashboardUrl.dataGrowthTableauTotalSizeOfExtract,
								className: 'datagrowthTableauTotalSizeOfExtract',
								otherParams: {
									'var-sector': selectedSector.join(','),
									'var-instance': selectedEnvironment.split(','),
									'var-site': selectedSite
								},
								seamless: true
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TableauDataGrowth;
