import React, { useState, useEffect, useRef } from 'react';
import type { Column } from 'react-data-grid';
import { CustomizedDataGrid } from '../customized_data_grid';
import { Grid, Box, Chip, TextField, FormControl, Button } from '@mui/material';
import { Link, useSearchParams } from 'react-router-dom';
import { ReactComponent as DatePickerIcon } from '../../assets/calendar-outline_white.svg';

import { textEditor } from 'react-data-grid';

import { OverflowTooltip } from '../overflow_tooltip';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment-timezone';

// import useStyles from './jobExecutionsstyles';
import '../jobExecutions/jobExecutions.css';
import '../jobs/jobs.css';
import '../jobRunSchedules/jobRunSchedules.css';
import Loader from '../Loader/loader';

import { CellExpanderFormatter } from '../cell_expander_formatter';

import {
	getjobRunSchedules,
	jobSearchForRunSchedules,
	updateJobRunHistory,
	getBoxRunsList,
	getActionTakenData
} from '../../service/service';

import convertSeconds from '../../utils/convertSeconds';

export default function jobRunSchedules() {
	interface Row {
		runStatus: string;
		commonStatus: string;
		reasonForDelay: string;
		actionTaken: string;
		ticketNumber: string;
		userStatus: string;
		downstream: string;
		slaStatus: string;
		_id: string;
		name: string;
		jobId: string;
		status: string;
		exitCode: string;
		runNum: string;
		application: string;
		subApplication: string;
		runStartTime: string;
		runEndTime: string;
		scheduleDays: string;
		scheduleTime: string;
		scheduleDate: string;
		slaEndTime: string;
		criticalFlag: boolean;
		machine: string;
		createdAt: string;
		updatedAt: string;
		updatedBy: string;
		boxName: string;
		jobType: string;
		children?: Row[];
		ischildren?: boolean;
		isExpanded?: boolean;
		actualDuration: string;
		overRun: string;
		thresholdPercent: string;
		durationDelay: string;
		estimatedDuration: string;
		avgRunTime: number;
		estimatedEndTime: string;
	}

	function toggleSubRow(name: string, runStartTime: string, scheduleDate: any) {
		const boxRows = localStorage.getItem('boxRows');
		const rows = boxRows ? JSON.parse(boxRows) : executionsList;
		const rowIndex = rows.findIndex((r: any) => r.name === name && r.runStartTime === runStartTime);
		const row = rows[rowIndex];
		const newRows = [...rows];
		let children: Row[] = [];
		let childRows: Row[] = [];
		if (!row.isExpanded) {
			const start = moment(scheduleDate).startOf('day').subtract(1, 'hour');
			const end = moment(scheduleDate).endOf('day').subtract(1, 'hour');
			const time = row.scheduleTime;
			const boxPromise = getBoxRunsList(name, start, end, time);
			boxPromise.then((res) => {
				if (res.success) {
					childRows = res.data;
				}
				children = childRows.map((x: any) => {
					return { ...x, isChildren: true };
				});
				newRows.splice(Number(rowIndex) + 1, 0, ...children);
				newRows[rowIndex] = { ...row, isExpanded: !row.isExpanded, children };
				localStorage.setItem('boxRows', JSON.stringify(newRows));
				setExecutionsList(newRows);
			});
		} else {
			children = row.children;
			newRows.splice(Number(rowIndex) + 1, children.length);
			newRows[rowIndex] = { ...row, isExpanded: !row.isExpanded };
			localStorage.setItem('boxRows', JSON.stringify(newRows));
			setExecutionsList(newRows);
		}
	}

	// const classes = useStyles();
	const [searchParams, setSearchParams] = useSearchParams();
	const hasDrillDowned = searchParams.has('isDrillDowned');
	const [executionsList, setExecutionsList] = useState<any[]>([]);
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [loading, setLoading] = useState(false);
	// const [metricLoading, setMetricLoading] = useState(false);
	const minJobExecDate = '2023-03-01';
	const [fromDateTime, setFromDateTime] = useState<any>(
		moment(searchParams.get('scheduleDate') ?? new Date())
			.startOf('day')
			.subtract(1, 'hours')
	);
	const [toDateTime, setToDateTime] = useState<any>(
		moment(searchParams.get('scheduleDate') ?? new Date())
			.endOf('day')
			.subtract(1, 'hours')
	);

	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });

	const [filterData, setFilterData] = useState<Row[]>([]);
	const [filterInfo, setFilterInfo] = useState([]);
	const [searchDetails, setFilterSearchInfo] = useState([]);

	const dataFetchedRef = useRef(false);
	moment.updateLocale(moment.locale(), { invalidDate: '' });
	const renderStatus = (status: any) => {
		switch (status) {
			case 'SUCCESS':
			case 'Successful':
				return <Chip label={status} color="success" />;
			case 'FAILURE':
			case 'Failure':
			case 'TERMINATED':
			case 'Terminated':
			case 'SUSPENDED':
				return <Chip label={status} color="secondary" />;
			case 'NOT STARTED':
			case 'Not Started':
				return <Chip label={status} style={{ backgroundColor: '#1B1E35CC', color: '#71758F' }} />;
			case 'RUNNING':
			case 'ACTIVATED':
			case 'In Progress':
			case 'Hold':
				return <Chip label={status} style={{ backgroundColor: '#299BFF26', color: '#299BFF' }} />;
			default:
				return <Chip label={status} style={{ backgroundColor: '#299BFF26', color: '#299BFF' }} />;
		}
	};

	const renderThreshold = (threshold: any) => {
		let color = '#2DC8A8';
		if (threshold > 80 && threshold <= 100) color = '#FFC258';
		else if (threshold > 100) color = '#FE8083';
		return (
			<span color="success" style={{ color: color }}>
				{threshold} %
			</span>
		);
	};

	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'name',
				name: 'Scheduled Job',
				width: 308,
				minWidth: 150,
				formatter({ row, isCellSelected }) {
					const hasChildren = row.jobType === 'BOX';
					return (
						<>
							{hasChildren && (
								<CellExpanderFormatter
									isCellSelected={isCellSelected}
									expanded={row.isExpanded === true}
									onCellExpand={() => {
										toggleSubRow(row.name, row.runStartTime, row.scheduleDate);
									}}
								/>
							)}
							{'   '}
							<Link
								className="jobsNameList"
								to={`/jobs/${row.jobId}`}
								state={row}
								style={{ paddingLeft: hasChildren ? '5px' : '20px' }}
							>
								{row.name}
							</Link>
						</>
					);
				}
			},
			{
				key: 'boxName',
				name: 'Box Name',
				width: 200,
				minWidth: 150
			},
			{
				key: 'sector',
				name: 'Sector',
				width: 100,
				minWidth: 100
			},
			{
				key: 'system',
				name: 'System',
				width: 100,
				minWidth: 100
			},
			{
				key: 'process',
				name: 'Process',
				width: 120,
				minWidth: 120
			},
			{
				key: 'runStatus',
				name: 'Run Status',
				width: 130,
				minWidth: 130,
				cellClass: 'center-text',
				formatter(props) {
					const status = props.row.runStatus;
					return renderStatus(status);
				}
			},
			{
				key: 'runStartTime',
				name: 'Start Time',
				width: 140,
				minWidth: 140,
				formatter(props) {
					const startDate = props.row.runStartTime;
					if (startDate) {
						return moment(startDate).format('MM-DD-YY HH:mm:ss');
					}
					return '';
				}
			},
			{
				key: 'runEndTime',
				name: 'End Time',
				width: 140,
				minWidth: 140,
				formatter(props) {
					const endDate = props.row.runEndTime;
					if (endDate) {
						return moment(endDate).format('MM-DD-YY HH:mm:ss');
					}
					return '';
				}
			},
			{
				key: 'slaStatus',
				name: 'SLA Status',
				width: 100,
				minWidth: 100,
				cellClass: 'center-text',
				// editor: (p) => (
				// 	<div>
				// 		<FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} className="row-select-drpdwn">
				// 			<Select
				// 				labelId="SLAMet"
				// 				id="SLAMet"
				// 				defaultOpen={true}
				// 				value={p.row.slaStatus}
				// 				onChange={(e: any) => {
				// 					p.onRowChange({ ...p.row, slaStatus: e.target.value }, true);
				// 				}}
				// 				label="SLA Met"
				// 			>
				// 				{[
				// 					{ key: 'Met', name: 'Met' },
				// 					{ key: 'Not Met', name: 'Not Met' },
				// 					{ key: 'NA', name: 'NA' }
				// 				].map((slaStatus) => (
				// 					<MenuItem key={slaStatus.key} value={slaStatus.key}>
				// 						{slaStatus.name}
				// 					</MenuItem>
				// 				))}
				// 			</Select>
				// 		</FormControl>
				// 	</div>
				// ),
				formatter(props) {
					return ['Met', 'Not Met'].includes(props.row.slaStatus) ? (
						<Chip
							label={props.row.slaStatus}
							color={props.row.slaStatus === 'Met' ? 'success' : 'secondary'}
							className="slaChip"
						/>
					) : (
						props.row.slaStatus
					);
				}
			},
			{
				key: 'slaEndTime',
				name: 'Normal Completion Time',
				minWidth: 200,
				width: 200,
				// maxWidth: 200,
				formatter(props) {
					return (
						<OverflowTooltip customTooltip={`Timezone: ${moment().isDST() ? 'EDT(UTC-4)' : 'EST(UTC-5)'}`}>
							{props.row.slaEndTime}
						</OverflowTooltip>
					);
				}
			},
			{
				key: 'estimatedEndTime',
				name: 'Estimated End Time',
				formatter(props) {
					return props.row.estimatedEndTime ? moment(props.row.estimatedEndTime).format() : '';
				},
				minWidth: 180
				// maxWidth: 200
			},
			{
				key: 'criticalFlag',
				name: 'Critical',
				formatter(props) {
					return props.row.criticalFlag ? 'Yes' : 'No';
				},
				minWidth: 105
				// maxWidth: 130
			},
			// {
			// 	key: 'scheduleDays',
			// 	name: 'Schedule Days',
			// 	width: 150,
			// 	minWidth: 150
			// },
			{
				key: 'scheduleTime',
				name: 'Schedule Time',
				width: 150,
				minWidth: 160
			},
			{
				key: 'scheduleDate',
				name: 'Schedule Date',
				width: 150,
				minWidth: 160,
				formatter(props) {
					const scheduleDate = props.row.scheduleDate;
					if (scheduleDate) {
						return moment(scheduleDate).format('MM-DD-YYYY');
					}
					return '';
				}
			},
			{
				key: 'reasonForDelay',
				name: 'Reason',
				width: 120,
				minWidth: 120,
				editor: textEditor,
				cellClass: 'has-editor',
				formatter(props) {
					return props.row.reasonForDelay ? <OverflowTooltip>{props.row.reasonForDelay}</OverflowTooltip> : 'Reason?';
				}
			},
			{
				key: 'actionTaken',
				name: 'Action Taken',
				width: 120,
				minWidth: 120,
				editor: textEditor,
				cellClass: 'has-editor',
				formatter(props) {
					return props.row.actionTaken ? <OverflowTooltip>{props.row.actionTaken}</OverflowTooltip> : 'Action Taken?';
				}
			},

			{
				key: 'ticketNumber',
				name: 'Ticket No',
				width: 120,
				minWidth: 120,
				editor: textEditor,
				cellClass: 'has-editor',
				formatter(props) {
					return props.row.ticketNumber ? <OverflowTooltip>{props.row.ticketNumber}</OverflowTooltip> : 'Ticket No?';
				}
			},
			{
				key: 'downstream',
				name: 'Follow Up',
				width: 120,
				minWidth: 120,
				editor: textEditor,
				cellClass: 'has-editor',
				formatter(props) {
					return props.row.downstream ? <OverflowTooltip>{props.row.downstream}</OverflowTooltip> : 'Follow Up?';
				}
			},
			{
				key: 'userName',
				name: 'Last Updated By',
				minWidth: 150,
				width: 150
				// maxWidth: 150
			},
			{
				key: 'updatedAt',
				name: 'Last Updated At',
				minWidth: 150,
				width: 150,
				// maxWidth: 150,
				formatter(props) {
					const updatedAt = props.row.updatedAt;
					if (updatedAt) {
						return moment(updatedAt).format();
					}
					return '';
				}
			},
			{
				key: 'schedulerName',
				name: 'Scheduler',
				width: 100,
				minWidth: 100
			},
			{
				key: 'application',
				name: 'Application',
				formatter(props) {
					return props.row.application || ' ';
				},
				minWidth: 105,
				width: 105
				// maxWidth: 130
			},
			{
				key: 'subApplication',
				name: 'Sub Application',
				formatter(props) {
					return props.row.subApplication || ' ';
				},
				minWidth: 135,
				width: 135
				// maxWidth: 180
			},
			{
				key: 'commonStatus',
				name: 'Common Status',
				minWidth: 155,
				width: 155,
				// maxWidth: 220,
				cellClass: 'center-text',
				formatter(props) {
					const status = props.row.commonStatus;
					return renderStatus(status);
				}
			},
			{
				key: 'machine',
				name: 'Machine',
				minWidth: 125,
				width: 125,
				// maxWidth: 220,
				formatter(props) {
					return props.row.machine;
				}
			},
			{
				key: 'jobType',
				name: 'Job Type',
				minWidth: 95
				// maxWidth: 160
			},
			{
				key: 'estimatedDuration',
				name: 'Estimated Duration',
				minWidth: 180,
				width: 200
				// maxWidth: 250
			},
			{
				key: 'actualDuration',
				name: 'Actual Duration',
				minWidth: 120,
				width: 150
				// maxWidth: 200
			},
			{
				key: 'overRun',
				name: 'Over Run',
				minWidth: 95
				// maxWidth: 160
			},
			{
				key: 'thresholdPercent',
				name: 'Threshold %',
				minWidth: 120,
				width: 150,
				// maxWidth: 200,
				cellClass: 'center-text',
				formatter(props) {
					const thresholdPercent = props.row.thresholdPercent;
					return renderThreshold(thresholdPercent);
				}
			},
			{
				key: 'avgRunTime',
				name: 'Avg Exec Duration',
				minWidth: 150,
				width: 180,
				// maxWidth: 200,
				formatter(props) {
					const avgRunTime = props.row.avgRunTime;
					return convertSeconds(avgRunTime);
				}
			},
			{
				key: 'durationDelay',
				name: 'Duration of Delay',
				minWidth: 150,
				width: 180
				// maxWidth: 200
			}
		];
	}

	const defaultColumnNames = [
		'Scheduled Job',
		'Box Name',
		'Sector',
		'System',
		'Process',
		'Run Status',
		'Start Time',
		'End Time',
		'SLA Status',
		'Normal Completion Time',
		'Estimated End Time',
		'Critical',
		'Schedule Time',
		'Schedule Date',
		'Reason',
		'Action Taken',
		'Ticket No',
		'Follow Up',
		'Last Updated By',
		'Last Updated At'
	];

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		// document.body.classList.add('loading-indicator');
		setLoading(true);
		const promise1 = fetchRunSchedules(value, pageSize, sortingInfo, fromDateTime, toDateTime, parseFilter(filterInfo));
		Promise.all([promise1]).then((values) => {
			// document.body.classList.remove('loading-indicator');
			setLoading(false);
		});
	};

	const handlepageSizeChange = (event: any) => {
		// document.body.classList.add('loading-indicator');
		setLoading(true);
		const promise1 = fetchRunSchedules(
			page,
			event.target.value,
			sortingInfo,
			fromDateTime,
			toDateTime,
			parseFilter(filterInfo)
		);
		Promise.all([promise1]).then((values) => {
			// document.body.classList.remove('loading-indicator');
			setLoading(false);
		});
	};

	const handleSorting = (sortingInfo: any) => {
		// document.body.classList.add('loading-indicator');
		setLoading(true);
		const promise1 = fetchRunSchedules(page, pageSize, sortingInfo, fromDateTime, toDateTime, parseFilter(filterInfo));
		Promise.all([promise1]).then((values) => {
			// document.body.classList.remove('loading-indicator');
			setLoading(false);
		});
	};

	const resetSearchParams = () => {
		if (hasDrillDowned) {
			searchParams.delete('status');
			searchParams.delete('scheduleDate');
			searchParams.delete('isDrillDowned');
			setSearchParams(searchParams);
		}
	};

	const handleDateTimeChange = (field: any, dateTime: any) => {
		if (field === 'fromDateTime') setFromDateTime(dateTime);
		else setToDateTime(dateTime);
	};
	const applyDateTimeRange = (e: any) => {
		resetSearchParams();
		// document.body.classList.add('loading-indicator');
		setLoading(true);
		const promise1 = fetchRunSchedules(page, pageSize, sortingInfo, fromDateTime, toDateTime, parseFilter(filterInfo));
		// const promise2 = fetchJobMetrices(fromDateTime, toDateTime);

		Promise.all([promise1]).then((values) => {
			// document.body.classList.remove('loading-indicator');
			setLoading(false);
		});
	};
	const handleFilter = (filterInfo: any) => {
		resetSearchParams();
		setFilterInfo(filterInfo);
		// document.body.classList.add('loading-indicator');
		setLoading(true);
		const promise1 = fetchRunSchedules(1, pageSize, sortingInfo, fromDateTime, toDateTime, parseFilter(filterInfo));
		Promise.all([promise1]).then((values) => {
			// document.body.classList.remove('loading-indicator');
			setLoading(false);
		});
	};

	const handleSearch = (searchDetails: any) => {
		jobSearchForExecutionsFunc(searchDetails, parseFilter(filterInfo, 'name'));
	};

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		// document.body.classList.add('loading-indicator');
		setLoading(true);
		let promise1: any;
		const filterQuerySkeleton = {} as any;
		const _status = searchParams.get('status');
		if (_status && hasDrillDowned) {
			switch (_status) {
				case 'SLA met':
					filterQuerySkeleton.slaStatus = ['Met'];
					filterQuerySkeleton.criticalFlag = ['true'];
					break;
				case 'SLA not met':
					filterQuerySkeleton.slaStatus = ['Not Met'];
					filterQuerySkeleton.criticalFlag = ['true'];
					break;
				case 'SLA not applicable':
					filterQuerySkeleton.slaStatus = ['NA'];
					filterQuerySkeleton.criticalFlag = ['true'];
					filterQuerySkeleton.commonStatus = ['Hold', 'In Progress', 'Terminated', 'Failure'];
					break;
				case 'SLA action taken':
					filterQuerySkeleton.slaStatus = ['Not Met'];
					filterQuerySkeleton.criticalFlag = ['true'];
					filterQuerySkeleton.slaActionTaken = ['Yes'];
					break;

				case 'Total Critical Executions':
					filterQuerySkeleton.criticalFlag = ['true'];
					break;
				case 'Successful':
					filterQuerySkeleton.commonStatus = ['Successful'];
					break;
				case 'Hold/ In Progress':
					filterQuerySkeleton.commonStatus = ['Hold', 'In Progress'];
					break;
				case 'Failed/ Terminated':
					filterQuerySkeleton.commonStatus = ['Failure', 'Terminated'];
					break;
				case 'Not Started':
					filterQuerySkeleton.commonStatus = ['Not Started'];
					break;
				case 'Stable (Below 80% threshold)':
					filterQuerySkeleton.stable = ['Yes'];
					break;
				case 'Attention required(Est.duration exceeded)':
					filterQuerySkeleton.overRun = ['Yes'];
					break;
				case 'At risk (80% threshold exceeded)':
					filterQuerySkeleton.atRisk = ['Yes'];
					filterQuerySkeleton.criticalFlag = ['true'];
					break;
				case 'Exec. success':
					filterQuerySkeleton.commonStatus = ['Successful'];
					filterQuerySkeleton.criticalFlag = ['false'];
					break;
				case 'Exec. failed':
					filterQuerySkeleton.commonStatus = ['Failure'];
					filterQuerySkeleton.criticalFlag = ['false'];
					break;
				case 'Not started':
					filterQuerySkeleton.commonStatus = ['Not Started'];
					filterQuerySkeleton.criticalFlag = ['false'];
					break;
				case 'Others':
					filterQuerySkeleton.commonStatus = ['Hold', 'In Progress', 'Terminated'];
					filterQuerySkeleton.criticalFlag = ['false'];
					break;
				case 'At Risk':
					filterQuerySkeleton.atRisk = ['Yes'];
					filterQuerySkeleton.runStatus = ['SUCCESS'];
					break;
				case 'Action Taken':
					filterQuerySkeleton.criticalFlag = ['false'];
					filterQuerySkeleton.slaStatus = ['Not Met'];
					filterQuerySkeleton.actionTaken = ['NA'];
					break;
				case 'Exec Failed':
					filterQuerySkeleton.criticalFlag = ['true'];
					filterQuerySkeleton.commonStatus = ['Failure'];
					break;

				case 'Non Critical Execution Failed':
					filterQuerySkeleton.criticalFlag = ['false'];
					filterQuerySkeleton.commonStatus = ['Failure'];
					break;
				case 'SLAs Not Met':
					filterQuerySkeleton.slaStatus = ['Not Met'];
					break;
				default:
					break;
			}
		} else {
			getColumns().forEach((column: any) => {
				filterQuerySkeleton[column.key] = [];
			});
		}
		let storedRunScheduleState = localStorage.getItem('jobRunScheduleState')
			? JSON.parse(localStorage.getItem('jobRunScheduleState') ?? '')
			: null;
		if (localStorage.getItem('selectedAlert')) {
			storedRunScheduleState = localStorage.getItem('alertJobExecutionsState')
				? JSON.parse(localStorage.getItem('alertJobExecutionsState') ?? '')
				: null;
			localStorage.removeItem('alertJobExecutionsState');
		}
		if (hasDrillDowned && _status === 'SLA action taken') {
			const drillDownPromise = fetchActionTakenData();
			Promise.all([drillDownPromise]).then((values) => {
				filterQuerySkeleton.actionTaken = values[0];
				promise1 = fetchRunSchedules(
					page,
					pageSize,
					sortingInfo,
					fromDateTime,
					toDateTime,
					parseFilter(filterQuerySkeleton)
				);
			});
		} else if (hasDrillDowned) {
			promise1 = fetchRunSchedules(
				page,
				pageSize,
				sortingInfo,
				fromDateTime,
				toDateTime,
				parseFilter(filterQuerySkeleton)
			);
		} else if (storedRunScheduleState && storedRunScheduleState.pageNumber && storedRunScheduleState.pageSize) {
			promise1 = fetchRunSchedules(
				1,
				storedRunScheduleState.pageSize,
				storedRunScheduleState.sortingInfo,
				storedRunScheduleState.selectedDate
					? moment(storedRunScheduleState.selectedDate).startOf('day').subtract(1, 'hours')
					: fromDateTime,
				storedRunScheduleState.selectedDate
					? moment(storedRunScheduleState.selectedDate).endOf('day').subtract(1, 'hours')
					: toDateTime,
				parseFilter(storedRunScheduleState.filterInfo)
			);
			setPaginationData(storedRunScheduleState.page);
			setFilterData(storedRunScheduleState.filterOptions);
			setPage(1);
			setPageSize(storedRunScheduleState.pageSize);
			setSortingInfo(storedRunScheduleState.sortingInfo);
			setFilterInfo(storedRunScheduleState.filterInfo);
		} else {
			promise1 = fetchRunSchedules(
				page,
				pageSize,
				sortingInfo,
				fromDateTime,
				toDateTime,
				parseFilter(filterQuerySkeleton)
			);
		}
		Promise.all([promise1]).then((values) => {
			// document.body.classList.remove('loading-indicator');
			setLoading(false);
		});
		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		const jobName = url.substring(url.lastIndexOf('/') + 1);
		if (window.location.pathname === '/jobRunSchedules') {
			if (activeId) {
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
			}
		} else if (jobName !== window.location.pathname && window.location.pathname === '/jobExecutions/' + jobName) {
			if (activeId) {
				activeId.classList.add('jobs-list');
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.remove('jobs-list-table');
				activeId.classList.remove('root');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.remove('jobs-exe-datas');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				activeId.classList.remove('jobs-list-table');
				activeId.classList.remove('jobs-list');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.remove('jobs-exe-datas');
			}
		}
	}, []);

	async function jobSearchForExecutionsFunc(searchDetails: any, jobFilterInfo: any) {
		const res = await jobSearchForRunSchedules(searchDetails, jobFilterInfo, fromDateTime, toDateTime);
		if (res.success) {
			setFilterSearchInfo(res.data);
		}
	}

	const handleEditCell = (rows: any[], editData: any) => {
		const runId = rows[editData.indexes[0]].id;
		const key = editData.column.key;
		const patchData: any = {};
		patchData[key] = rows[editData.indexes[0]][key];
		patchData.runId = runId;
		patchJobSchedule(patchData, rows);
	};

	async function fetchRunSchedules(
		pageNumber: any,
		size: any,
		sortingInfo: any,
		fromDateTime: any,
		toDateTime: any,
		jobFilterInfo: any
	) {
		setLoading(true);
		setFromDateTime(fromDateTime);
		setToDateTime(toDateTime);
		const res = await getjobRunSchedules(
			pageNumber.toString(),
			size.toString(),
			sortingInfo,
			jobFilterInfo,
			fromDateTime,
			toDateTime
		);
		if (res.success) {
			setExecutionsList(res.data.jobs);
			setPaginationData(res.data.page);
			setFilterData(res.data.filterOptions);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
			setFilterInfo(jobFilterInfo);
			setLoading(false);
			const jobRunScheduleState = {
				page: res.data.page,
				filterOptions: res.data.filterOptions,
				pageSize: size,
				pageNumber,
				sortingInfo,
				filterInfo: jobFilterInfo
			};
			localStorage.setItem('jobRunScheduleState', JSON.stringify(jobRunScheduleState));
			localStorage.setItem('boxRows', JSON.stringify(res.data.jobs));
		}
		setLoading(false);
	}

	async function fetchActionTakenData() {
		setLoading(true);
		const res = await getActionTakenData(fromDateTime, toDateTime);
		if (res.success) {
			return res.data;
		}
		setLoading(false);
	}

	async function patchJobSchedule(patchData: any, rows: Row[]) {
		setLoading(true);
		const res = await updateJobRunHistory(patchData);
		if (res.success) {
			setLoading(false);
			setExecutionsList(rows);
		}
	}

	return (
		<div className="job-schedules-wrap">
			<div className="job-exexutions-dateFilter-div">
				<LocalizationProvider className="outerDateContainer" dateAdapter={AdapterMoment}>
					<FormControl className="dateFilter-formControl" sx={{ m: 1 }}>
						<DateTimePicker
							label="From Date"
							showToolbar={true}
							components={{
								OpenPickerIcon: DatePickerIcon
							}}
							PopperProps={{ placement: 'auto' }}
							toolbarTitle={
								<>
									Select Date Time{' '}
									<div className="time-picker-zone">{`Timezone: ${
										moment().isDST() ? 'EDT(UTC-4)' : 'EST(UTC-5)'
									}`}</div>
								</>
							}
							className="dateFilter"
							ampm={false}
							value={moment(fromDateTime).tz('America/New_York')}
							minDateTime={moment(minJobExecDate).tz('America/New_York').startOf('day').subtract(1, 'hour')}
							maxDateTime={moment().tz('America/New_York').endOf('day').subtract(1, 'hours')}
							onChange={(dateTime) => {
								handleDateTimeChange('fromDateTime', dateTime);
							}}
							renderInput={(params: any) => <TextField name="startDateTime" {...params} />}
						/>
					</FormControl>
					<FormControl className="dateFilter-formControl" style={{ marginBottom: '0px' }} sx={{ m: 1 }}>
						<DateTimePicker
							label="To Date"
							showToolbar={true}
							components={{
								OpenPickerIcon: DatePickerIcon
							}}
							PopperProps={{
								placement: 'auto'
							}}
							toolbarTitle={
								<>
									Select Date Time{' '}
									<div className="time-picker-zone">{`Timezone: ${
										moment().isDST() ? 'EDT(UTC-4)' : 'EST(UTC-5)'
									}`}</div>
								</>
							}
							className="dateFilter"
							ampm={false}
							value={moment(toDateTime).tz('America/New_York')}
							minDateTime={moment(fromDateTime).tz('America/New_York')}
							maxDateTime={moment().tz('America/New_York').endOf('day').subtract(1, 'hour')}
							onChange={(dateTime) => {
								handleDateTimeChange('toDateTime', dateTime);
							}}
							renderInput={(params: any) => <TextField name="endDateTime" {...params} />}
						/>
					</FormControl>
				</LocalizationProvider>
				<Button variant="contained" color="primary" className="dateRangeApplyBtn" onClick={applyDateTimeRange}>
					Apply
				</Button>
			</div>
			<Box>
				{/* <Grid container className={classes.metricContainer} spacing={2}>
					{!metricLoading &&
						scheduleMetrices.map((metric) => (
							<Grid item xs={2} key={metric.name} className="job-widgets">
								<Paper elevation={2} className={classes.paperForMetric}>
									<Box sx={{ marginBottom: '10px', marginLeft: '10px' }}>
										{metric.name}
										<Stack direction="row" alignItems="flextStart" spacing={15} sx={{ marginBottom: '10px' }}>
											<p className={metric.className}>{metric.value}</p>
											<span className={classes.metricImageSpan}>
												{/* <img src={metric.imgSrc} className={classes.metricImgSrc}></img> */}
				{/* {metric.arrowSrc ? (
													<>
														<img src={metric.arrowSrc} className={classes.metricArrowImg} />
														<b>{Math.abs(metric.percent)}%</b>
													</>
												) : (
													''
												)}
											</span>
										</Stack>
									</Box>
								</Paper>
							</Grid>
						))}
				</Grid> */}
				<div className="job-schedule-table">
					<Grid container item xs={12}>
						<Grid item xs={5}></Grid>
						<Grid item xs={4} />
					</Grid>
					<Grid className="job_runSchedules_tabledata">
						{!loading ? (
							<CustomizedDataGrid
								tableColumns={getColumns()}
								tableData={executionsList}
								tableType="RunSchedule"
								defaultColumnNames={defaultColumnNames}
								onSorting={handleSorting}
								currSorting={sortingInfo}
								filterDataOptions={filterData}
								onFilterCheck={handleFilter}
								currFilter={filterInfo}
								showExport={true}
								fileName={`Job Executions-${moment(fromDateTime).format()}.xlsx`}
								searchDataValues={searchDetails}
								onFilterSearch={handleSearch}
								onCellEdit={handleEditCell}
								page={page}
								pageSize={pageSize}
								paginationData={paginationData}
								listLength={executionsList.length}
								handlepageSizeChange={handlepageSizeChange}
								handlePageChange={handlePageChange}
								isDateRangeFilter={false}
							/>
						) : (
							<Loader />
						)}
					</Grid>
				</div>
			</Box>
		</div>
	);
}
