import { Dispatch } from 'react';
import { CacheDataActions, CacheDataState } from '../types/CacheDataContext';
import { DataEstateStateType } from './type';

export const dataEstateState: DataEstateStateType = {
	metricDetails: null,
	executiveMetricDetails: null,
	databricksClusters: null,
	conversionUnit: 'PB'
};

export const dataEstateReducer = (state: CacheDataState, action: CacheDataActions): CacheDataState => {
	switch (action.type) {
		case 'METRIC_DETAILS':
			return { ...state, metricDetails: action.value };
		case 'EXEC_METRIC_DETAILS':
			return { ...state, executiveMetricDetails: action.value };
		case 'DB_CLUSTERS':
			return { ...state, databricksClusters: action.value };
		case 'CONV_UNIT':
			return { ...state, conversionUnit: action.value };
		default:
			return state;
	}
};

// Action creator
export const setMetricDetails = (dispatch: Dispatch<CacheDataActions>, value: CacheDataState['metricDetails']) =>
	dispatch({ type: 'METRIC_DETAILS', value });

export const setExecutiveMetricDetails = (
	dispatch: Dispatch<CacheDataActions>,
	value: CacheDataState['executiveMetricDetails']
) => dispatch({ type: 'EXEC_METRIC_DETAILS', value });

export const setDataBricksClusters = (
	dispatch: Dispatch<CacheDataActions>,
	value: CacheDataState['databricksClusters']
) => dispatch({ type: 'DB_CLUSTERS', value });

export const setCurrentConversionUnit = (
	dispatch: Dispatch<CacheDataActions>,
	value: CacheDataState['conversionUnit']
) => dispatch({ type: 'CONV_UNIT', value });
