import { useState, useEffect, useRef } from 'react';
import ModelListing from './models/modelListing';
import ModelSummary from './widgets/modelSummary';
import './dashboard.css';
import DashboardIcon from '../../assets/material-symbols_dashboard.svg';
import { Stack } from '@mui/material';

const MLOBSDashboard = () => {
	const dataFetchedRef = useRef(false);
	const [sharedValue, setSharedValue] = useState('');
	const updateSharedValue = (newValue: any) => {
		setSharedValue(newValue);
	};
	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
	});
	return (
		<div className="ml-summary-panel">
			<Stack>
				<span className="ml-model-menu">
					<img src={DashboardIcon} className="ml-icon" />
					<span className="ml-header-name">Summary</span>
				</span>
			</Stack>
			<ModelSummary sharedValue={sharedValue} updateSharedValue={updateSharedValue}></ModelSummary>
			<ModelListing sharedValue={sharedValue} />
		</div>
	);
};

export default MLOBSDashboard;
