import { useEffect, useRef } from 'react';
import moment from 'moment-timezone';

import './ibp-obs.scss';
import AnalyticsVisualization from '../framework/analyticsVisualization';
import { dashboardUrl } from '../../service/config';

export default function jobsListing() {
	const dataFetchedRef = useRef(false);

	moment.updateLocale(moment.locale(), { invalidDate: '' });

	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
	}, []);
    
	return (
		<div className="page-table jobs_tabledata">
			<AnalyticsVisualization
				params={{
					dashboardUrl: dashboardUrl.IBPDashboard,
					className: 'controlsTestingIframeContainer',
					otherParams: {
						from: 'now-10d',
						to: 'now',
						'var-fromDate': moment().subtract(10, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
						'var-toDate': moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
					},
					seamless: true
				}}
			/>
		</div>
	);
}
