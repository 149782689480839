import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    jobText: {
        fontFamily: 'Montserrat',
        fontStyle: 'italic',
        fontSize: '13px',
        paddingTop: '6px !important',
        lineHeight: '10px',
        color: '#71758F',
        marginTop: '32px !important'
    },
    jobText1: {
        fontFamily: 'Montserrat',
        fontStyle: 'italic',
        fontSize: '13px',
        float: 'right',
        textAlign: 'right',
        color: '#71758F',
        lineHeight: '10px',
        marginTop: '25px !important'
    },
    filterContainer: {
        background: '#1B1E35',
        borderRadius: '8px',
        height: 'max-content',
        width: '300px',
        position: 'absolute',
        top: '32%'
    },
    textfield1: {
        top: '0px',
        width: '97%',
        '& input::placeholder': {
            fontSize: '12px',
            fontFamily: 'Montserrat',
            textTransform: 'capitalize',
            color: '#E5E5E5'
        },
        '& input:active': {
            background: 'transparent'
        },
        '& input:focus': {
            background: 'transparent'
        },
        '& input:hover': {
            background: 'transparent'
        },
        '& input': {
            paddingLeft: '0px',
            background: 'transparent'
        },
        '& input:placeholder-shown': {
            background: 'transparent',
            backgroundColor: 'transparent'
        },
        background: 'rgba(113, 117, 143, 0.4)',
        backdropFilter: 'blur(10px)',
        borderRadius: '4px',
        marginLeft: '0px !important',
        marginBottom: '10px !important',
        fontFamily: 'Montserrat',
        paddingLeft: '10px !important',
        color: 'white'
    },
    searchBar: {
        float: 'left'
    },
    checkbox: {
        color: '#3D3A50'
    },

    modalCancelBtn: {
        width: '100px',
        height: '42px !important',
        color: '#F5F5F5 !important',
        fontWeight: '600 !important',
        textTransform: 'capitalize',
        fontFamily: 'Montserrat !important',
        border: '0px !important'
    },

    createProjBtn: {
        background: '#009BD8 !important',
        backgroundColor: '#009BD8 !important',
        borderRadius: '4px !important',
        width: '250px',
        height: '42px !important',
        color: 'white !important',
        fontWeight: '700 !important',
        textTransform: 'capitalize',
        fontFamily: 'Montserrat !important',
        marginLeft: '5px',
        fontSize: '16px'
    },


    noOptions: {
        color: 'white',
    },

    createBtn: {
        background: '#009BD8 !important',
        backgroundColor: '#009BD8 !important',
        borderRadius: '4px !important',
        width: '100%',
        height: '42px !important',
        color: 'white !important',
        fontWeight: '700 !important',
        textTransform: 'capitalize',
        fontFamily: 'Montserrat !important'
    },
    modalCreateBtn: {
        background: '#009BD8 !important',
        backgroundColor: '#009BD8 !important',
        borderRadius: '4px !important',
        width: '100px',
        height: '42px !important',
        color: 'white !important',
        fontWeight: '700 !important',
        textTransform: 'capitalize',
        fontFamily: 'Montserrat !important'
    },
    modalEditBtn: {
        background: '#009BD8 !important',
        backgroundColor: '#009BD8 !important',
        borderRadius: '4px !important',
        width: '160px',
        height: '42px !important',
        color: 'white !important',
        fontWeight: '700 !important',
        textTransform: 'capitalize',
        fontFamily: 'Montserrat !important'
    },
    modalDeleteBtn: {
        background: '#FE2626 !important',
        backgroundColor: '#FE2626 !important',
        borderRadius: '4px !important',
        width: '100px',
        height: '42px !important',
        color: 'white !important',
        fontWeight: '700 !important',
        textTransform: 'capitalize',
        fontFamily: 'Montserrat !important'
    },
    cancelDeleteBtn: {

        border: '0px !important',
        width: '150px',
        height: '42px !important',
        color: '#009BD8 !important',
        fontWeight: '700 !important',
        textTransform: 'capitalize',
        fontFamily: 'Montserrat !important'
    },
    lineage: {
        paddingLeft: '8%',
        paddingTop: '9%',
        fontFamily: 'Montserrat',
        fontStyle: 'italic',
        fontSize: '13px',
        color: '#71758F'
    },
    predecessorText: {
        fontFamily: 'Montserrat',
        fontStyle: 'italic',
        fontSize: '13px',
        float: 'right',
        textAlign: 'right',
        color: '#71758F',
        lineHeight: '10px',
        marginTop: '7px !important',
        paddingTop: '16px'
    },
    secessorText: {
        fontFamily: 'Montserrat',
        fontStyle: 'italic',
        fontSize: '13px',
        float: 'right',
        textAlign: 'right',
        color: '#71758F',
        lineHeight: '10px',
        marginTop: '7px !important',
        paddingTop: '16px'
    },
    lineageBox: {
        paddingLeft: '42px'
    },
    error: {
        color: 'red',
        paddingLeft: '8%',
        fontFamily: 'Montserrat',
        fontStyle: 'italic',
        fontSize: '13px'
    },
    select: {
        '&:before': {
            display: 'none',
        },
        '&:after': {
            display: 'none',
        },
    },
    root: {
        '& .MuiInputBase-root': {
            border: 'none',
        },
    },
});

export default useStyles;
