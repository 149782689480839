import moment from 'moment-timezone';
function isPageNavigationDisabled(navigation: any, fromDate: any, filter?: any) {
	const size = 5;
	let unit: moment.unitOfTime.StartOf = 'day';
	let minDate = '2023-03-01';
	if (filter === 'weekly') {
		minDate = '2022-03-01';
		unit = 'week';
	} else if (filter === 'monthly') {
		minDate = '2020-03-01';
		unit = 'month';
	} else if (filter === 'yearly') {
		minDate = '2000-03-01';
		unit = 'year';
	}

	switch (navigation) {
		case 'next':
			return moment(fromDate).startOf(unit).format('YYYY-MM-DD') === moment().startOf(unit).format('YYYY-MM-DD');
		case 'last':
			return moment().startOf(unit).diff(moment(fromDate).startOf(unit), unit) < size;
		case 'prev':
			return moment(fromDate).startOf(unit).diff(moment(minDate).startOf(unit).add(size, unit), unit) < 0;
		case 'first':
			return moment(fromDate).startOf(unit).diff(moment(minDate).startOf(unit).add(size, unit), unit) < size;
	}
}

export default isPageNavigationDisabled;
