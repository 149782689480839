import React, { useEffect, useState, useRef } from 'react';
import { Grid, Tooltip, Zoom } from '@mui/material';
import { getModels, getModel, getModelPerformanceMetrics } from '../../../service/service';
import moment from 'moment-timezone';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';

import type { Column } from 'react-data-grid';
import { CustomizedDataGrid } from '../../customized_data_grid';
import '../models/model.css';
import Loader from '../../Loader/loader';
import { Link, useSearchParams, useParams, useLocation } from 'react-router-dom';
import DashboardIcon from '../../../assets/material-symbols_dashboard.svg';
import { Stack, Typography, FormControlLabel, Button } from '@mui/material';
import CommonDetails from './widgets/commonDetails';
import MoreClassficationDetails from './widgets/moreClassficationDetails';
import MoreRegressionDetails from './widgets/moreRegressionDetails';
import FeatureDrifts from './widgets/featureDriftMetrics';
// import { ReactComponent as DatePickerIcon } from '../../../calendar-outline_white.svg';

import { ReactComponent as DatePickerIcon } from '../../../assets/calendar-outline_white.svg';

import AnalyticsVisualization from '../../framework/analyticsVisualization';
import { dashboardUrl } from '../../../service/config';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactComponent as InfoIcon } from '../../../assets/lucide_info.svg';

export default function ModelDetails({ sharedValue }: any) {
	const location = useLocation();
	const params = useParams();

	const dataFetchedRef = useRef(false);
	const [model, setModel] = useState(location.state);
	const [classificationPerformance, setClassificationPerformance] = useState({} as any);
	const [regressionPerformance, setRegressionPerformance] = useState({} as any);
	const [targetCorrelation, setTargetCorrelation] = useState({} as any);
	const [targetDrift, setTargetDrift] = useState({} as any);
	const [inferences, setInferences] = useState(model?.inference_date || [])
	const [selectedInference, setSelectedInference] = useState(model?.inference_ids ? model?.inference_ids[0] : null)
	const [selectedInferenceDate, setSelectedInferenceDate] = useState(model?.inference_date ? moment(model?.inference_date[0]).format("MM-DD-YYYY") : null)
	const [searchParams, setSearchParams] = useSearchParams();
	const hasDrillDown = searchParams.has('isDrillDowned');

	const [filterMenu, setFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);

	const resetSearchParams = () => {
		if (hasDrillDown) {
			searchParams.delete('status');
			searchParams.delete('isDrillDowned');
			setSearchParams(searchParams);
		}
	};

	const filterClick = (event: any) => {
		event.preventDefault();
		setFilterMenu(
			filterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 20,
						mouseY: parseInt(event.clientY) - 6
				  }
				: null
		);
	};

	const handleClose = (event: any) => {
		setFilterMenu(null);
	};
	const changeInference = (idx: any) => {
		setSelectedInference(model.inference_ids[idx]);
		setSelectedInferenceDate(moment(model.inference_date[idx]).format("MM-DD-YYYY"))
		fetchModelPerformanceMetrics(params.modelId, model.inference_ids[idx])
		setFilterMenu(null);
	};

	const fetchAndSetModel = async (modelId: any) => {
		const res = await getModel(modelId);
		setModel(res.data);
		setInferences(res.data.inference_date);
		setSelectedInference(res.data.inference_ids[0]);
	};
	const fetchModelPerformanceMetrics = async (modelId: any, inferenceId: any) => {
		const res = await getModelPerformanceMetrics(modelId, inferenceId);
		const performanceMetrics = res.data;
		if (performanceMetrics['classification_performance'] && performanceMetrics['classification_performance'].length) {
			setClassificationPerformance(performanceMetrics['classification_performance'][0]);
		}
		if (performanceMetrics['regression_performance'] && performanceMetrics['regression_performance'].length) {
			setRegressionPerformance(performanceMetrics['regression_performance'][0]);
		}
		if (performanceMetrics['target_Correlation'] && performanceMetrics['target_Correlation'].length) {
			setTargetCorrelation(performanceMetrics['target_Correlation'][0]);
		}
		if (performanceMetrics['target_drift'] && performanceMetrics['target_drift'].length) {
			setTargetDrift(performanceMetrics['target_drift'][0]);
		}
	};

	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		if (!model) {
			fetchAndSetModel(params.modelId);
		}
		fetchModelPerformanceMetrics(params.modelId, selectedInference);
		const activeId = document.getElementById('root');
		if (activeId) {
			const mainContent = document.getElementsByClassName('main-content')[0];

			if (mainContent) {
				// Use type assertion to let TypeScript know that myElement has a style property
				(mainContent as HTMLElement).style.overflow = 'scroll';
				(mainContent as HTMLElement).style.height = 'calc(100vh - 35px)';
			}

			activeId.classList.add('jobs-list-table');
			activeId.classList.remove('root');
			document.body.classList.add('jobs-exe-datas');
			document.body.classList.add('jobs-list-datas');
		}
	}, [model, inferences, selectedInference]);

	return (
		<>
			<Stack>
				<Link to={`/machineLearning/dashboard`} className="titleLink">
					<span className="ml-model-menu ">
						<img src={DashboardIcon} className="ml-icon" />
						<span className="ml-header-name">Summary</span>
					</span>
				</Link>
				<span className="ml-symbol">&gt;</span> <span className="ml-page-title">{model?.name}</span>
			</Stack>
			<Stack direction="row" alignItems="flexStart" spacing={2} className="model-details-panel-stack mt-95">
				{model && <CommonDetails sharedValue={model} />}
				{model?.model_type === 'classification' && (
					<MoreClassficationDetails classificationPerformance={classificationPerformance} targetDrift={targetDrift} />
				)}
				{model?.model_type === 'regression' && <MoreRegressionDetails regressionPerformance={regressionPerformance} />}
			</Stack>

			<div className="inference-filter-text">{selectedInferenceDate}</div>
			<DatePickerIcon onClick={filterClick} className="inference-filter" />
			
			<Popover
				open={filterMenu !== null}
				onClose={handleClose}
				className="filterContainerml"
				anchorReference="anchorPosition"
				anchorPosition={filterMenu !== null ? { top: filterMenu.mouseY, left: filterMenu.mouseX } : undefined}
				
			>
				<div className="text-filter-row">
					<div className="selectAll-sec">
						<div className="selectDate">Select Date</div>
						<div className="checkbox-sec">
							{inferences &&
								inferences.map((inference: any, idx: any) => (
									<MenuItem key={inference} onClick={(e) => {changeInference(idx)}}>
										{moment(inference).format("MM-DD-YYYY")} {moment(inference).format("MM-DD-YYYY") === moment().format("MM-DD-YYYY") && idx === 0 ? '(Today)' : ''}
									</MenuItem>
								))}
						</div>
					</div>
					
				</div>
			</Popover>
			
			<Stack direction="row" alignItems="flexStart" spacing={2} className="model-details-panel-stack">
				<Accordion className="w-full">
					<AccordionSummary
						expandIcon={<ExpandMoreIcon className="according-expand-icon" />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography className="accordin-title">Target Drift Detected</Typography>
						{ targetCorrelation?.pvalue && 
						<>
							<Typography className="accordin-subtitle">
								P Value = {targetCorrelation?.pvalue?.toExponential(2)}
							</Typography>
							<Tooltip arrow title={targetCorrelation?.pvalue} TransitionComponent={Zoom}>
								<InfoIcon className="info-icon" />
							</Tooltip>
						</>
						}
					</AccordionSummary>
					<AccordionDetails>
						<AnalyticsVisualization
							params={{
								dashboardUrl: dashboardUrl.mlobsModelTargetDrift,
								className: 'mlobsTargetDriftIframeContainer',
								otherParams: {
									from: 'now-6h',
									to: 'now',
									'var-modelId': params.modelId,
									'var-inferenceId': selectedInference
								},
								seamless: true
							}}
						/>
					</AccordionDetails>
				</Accordion>
			</Stack>
			<Stack direction="row" alignItems="flexStart" spacing={2} className="model-details-panel-stack">
				<Accordion className="w-full">
					<AccordionSummary
						expandIcon={<ExpandMoreIcon className="according-expand-icon" />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography className="accordin-title">Data Drift Detected</Typography>
						<Typography className="accordin-subtitle"></Typography>
					</AccordionSummary>
					<AccordionDetails>
						<AnalyticsVisualization
							params={{
								dashboardUrl: dashboardUrl.mlobsModelDataDrift,
								className: 'mlobsDataDriftIframeContainer',
								otherParams: {
									from: 'now-6h',
									to: 'now',
									'var-modelId': params.modelId,
									'var-inferenceId': selectedInference
								},
								seamless: true
							}}
						/>
						<Stack direction="row" alignItems="flexStart" spacing={2} className="mt-15">
							<FeatureDrifts inferenceId={selectedInference} key={selectedInference} />
						</Stack>
					</AccordionDetails>
				</Accordion>
			</Stack>
		</>
	);
}
