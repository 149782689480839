import React, { useEffect, useRef, useState } from 'react';
import { Button, Menu, Stack, TextField, List, ListItem, ListItemText, FormControlLabel, Tooltip } from '@mui/material';
import { dashboardUrl } from '../../../service/config';
import AnalyticsVisualization from '../../framework/analyticsVisualization';
import '../Summary/Summary.scss';
import './CostSummary.scss';
import CostSummartProject from './CostSummartProject';
import Workspace from './Workspace';
import moment from 'moment';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as DatePickerIcon } from '../../../assets/calendar-outline_white.svg';
import { ReactComponent as FilterIcon } from '../../../assets/ml_summary_filter.svg';
import { ReactComponent as Downarrow } from '../../../assets/down-arrow.svg';
import { getCostSummaryChart, getDecodedToken, getDatabricksSectors, getWorkspaceList } from '../../../service/service';
import useStyles from '../../jobs/jobs_styles';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';

const CostSummary = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const minJobExecDate = '2023-03-01';
	const dateFrom: string = searchParams.get('fromDate')?.substring(0, 10) as string;
	const dateTo: string = searchParams.get('toDate')?.substring(0, 10) as string;
	const startDt = moment(dateFrom).startOf('day').subtract(1, 'hours').format('YYYY-MM-DD');
	const endDt = moment(dateTo).endOf('day').subtract(1, 'hours').format('YYYY-MM-DD');
	const [fromDateTime, setFromDateTime] = useState<any>(
		searchParams.has('fromDate') ? startDt : null
	);
	// const [toDateTime, setToDateTime] = useState<any>(
	// 	searchParams.has('toDate') ? endDt : moment().endOf('day').subtract(1, 'hours')
	// );
	const [propsFromDateTime, setPropsFromDateTime] = useState<any>(
		searchParams.has('fromDate') ? startDt : moment().startOf('day').subtract(1, 'hours')
	);
	const [propsToDateTime, setPropsToDateTime] = useState<any>(
		searchParams.has('toDate') ? endDt : moment().endOf('day').subtract(1, 'hours')
	);
	const [topExpClusterData, setTopExpClusterData] = useState<String[]>([]);
	const [clusterSpentData, setClusterSpentData] = useState<String[]>([]);
	const [dateSelect, setDateSelect] = useState(0);

	// sector filter
	const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
	const [filterMenu, setFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	const classes = useStyles();
	const [itemsChecked, setItemsChecked] = useState(false);
	const [sectorOptionsList, setSectorOptionsList] = useState<any[]>([]);
	const [checkedList, setCheckedList] = useState<any[]>([]);
	const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);
	const [sector, setCurrSector] = useState<any[]>([]);
	const dataFetchedRef = useRef(false);
	const shortenMonths: any = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	// [Date Range Menu]
	const [dateRangeMenuOpen, setDateRangeMenuOpen] = React.useState<null | HTMLElement>(null);
	const [pastMonths, setPastMonths] = useState<{ label: string; value: string; }[]>([]);
	const [monthFilter, setMonthFilter] = useState('');
	const [yearFilter, setYearFilter] = useState('');
	const [monthNumberFilter, setMonthNumberFilter] = useState('');
	const generatePastMonths = () => {
		const currentDate = moment();
		const months = [];
		months.push({ label: 'This Month', value: currentDate.format('MMM') });
		setMonthFilter(currentDate.format('MMM'));
		setYearFilter(currentDate.format('YYYY'))
		setMonthNumberFilter(shortenMonths.indexOf(currentDate.format('MMM')) + 1);
		for (let i = 1; i < 12; i++) {
			const pastMonth = currentDate.clone().subtract(i, 'months');
			months.push({ label: pastMonth.format('MMM \'YY'), value: pastMonth.format('MMM') + '-' + pastMonth.format('YYYY') });
    	}
    	setPastMonths(months);
  		};
		useEffect(() => {
			generatePastMonths();
		}, []);
	

	const getMonthsFilter = (dateValue: string) => {
		setMonthFilter(dateValue.split('-')[0]);
		setYearFilter(dateValue.split('-')[1]);
		setMonthNumberFilter(shortenMonths.indexOf(dateValue.split('-')[0]) + 1);
	};

	const handleDateClick = (event: React.MouseEvent<HTMLElement>) => {
		setDateRangeMenuOpen(event.currentTarget);
	};

	const handleDateRangeMenu = () => {
		setDateRangeMenuOpen(null);
	};

	const open = Boolean(dateRangeMenuOpen);

	const handleDateTimeChange = (field: any, dateTime: any) => {
		if (field === 'fromDateTime') setFromDateTime(dateTime);
		localStorage.setItem('processedDate', moment(dateTime).format('YYYY-MM-DD'));
		applyDateTimeRange(dateTime);
		setDateSelect(dateSelect + 1);
	};

	const resetSearchParams = () => {
		if (searchParams.has('fromDate') || searchParams.has('fromDate')) {
			searchParams.delete('status');
			searchParams.delete('scheduleDate');
			searchParams.delete('fromDate');
			searchParams.delete('fromDate');
			setSearchParams(searchParams);
		}
	};
	const applyDateTimeRange = (dateTime: any) => {
		setPropsFromDateTime(dateTime);
		// setPropsFromDateTime(fromDateTime);
		fetchWorkspaceList();
		resetSearchParams();
		setDateRangeMenuOpen(null);
	};

	const sectorFilterClick = (event: any) => {
		event.preventDefault();
		setFilterMenu(
			filterMenu === null
				? {
					mouseX: parseInt(event.clientX) - 20,
					mouseY: parseInt(event.clientY) - 6
				}
				: null
		);
	};

	const handleClose = (event: any) => {
		setFilterMenu(null);
	};

	const selectAllCheck = (e: any) => {
		const { checked } = e.target;
		const collection: any[] = [];
		if (checked) setCheckedList(sectorOptionsList);
		else setCheckedList([]);
		setItemsChecked(checked);
	};

	const handleChange = (e: any) => {
		const { value, checked } = e.target;
		const newlyChecked = [];
		if (checked && e.target.value) {
			newlyChecked.push(e.target.value);
		}
		if (checked) {
			setCheckedList([...checkedList, ...newlyChecked]);
			if(sectorOptionsList.length === (checkedList.length + newlyChecked.length)){
				setItemsChecked(true);
			}
		} else {
			setCheckedList(checkedList.filter((item) => item !== value));
			setItemsChecked(false);
		}
	};
	const handleOkClick = (event: any) => {
		console.log(checkedList)
		if (checkedList.length > 0) {
			setCurrSectorFilter(checkedList);
		} else {
			setCurrSectorFilter([]);
		}
		if (sectorOptionsList.length == checkedList.length) {
			setCurrSector([]);
			localStorage.setItem('dcs-summary-sector-filter', JSON.stringify([]));
		} else {
			setCurrSector(checkedList);
			localStorage.setItem('dcs-summary-sector-filter', JSON.stringify(checkedList));
		}
		fetchWorkspaceList();
		// DBWorkspaceSpentListingFunc(page, pageSize, sortingInfo, fromDateTime, toDateTime, parseFilter(filterInfo), checkedList);
		// updateSharedValue({ sectorFilter: checkedList });
		handleClose(event);
	};

	async function fetchSectorOptionsData() {
		const sectorRes = await getDatabricksSectors('1', '100', '');
		if (sectorRes.success) {
			setSectorOptionsList(
				sectorRes.data.sector.map((x: any) => {
					if (x.name === 'S&T') {
						return 'SNT';
					} else if (x.name === 'Europe') {
						return 'EUROPE';
					}
					else if (x.name === 'Global') {
						return 'GLOBAL';
					}
					else {
						return x.name;
					}
				})
			);
		}
	}

	async function fetchWorkspaceList() {
		const fromDate = localStorage.getItem('processedDate') ? localStorage.getItem('processedDate') : moment(fromDateTime).format('YYYY-MM-DD');
		let storedSectorFilter = localStorage.getItem('dcs-summary-sector-filter')
			? JSON.parse(localStorage.getItem('dcs-summary-sector-filter') ?? '')
			: currSectorFilter;
		const res = await getWorkspaceList(fromDate, storedSectorFilter);
		const workspace_name = res.data.workspace_name;
		if (!clusterSpentData.includes('All Workspaces') && workspace_name.length) {
			setTopExpClusterData(['All Workspaces', ...workspace_name]);
			setClusterSpentData(['All Workspaces', ...workspace_name]);
		} else {
			setTopExpClusterData(workspace_name);
			setClusterSpentData(workspace_name);
		}

	}

	async function fetchCostData() {
		const res = await getCostSummaryChart();
		res.data.topExpensiveClustersWorkspace.sort(
			(a: { Total_Cost: number }, b: { Total_Cost: number }) => b.Total_Cost - a.Total_Cost
		);
		const top10Clusters: any = res.data.topExpensiveClustersWorkspace.slice(0, 10);
		console.log(top10Clusters + ' topExpensiveClustersWorkspace')
		const top10WorkspaceNames: any = top10Clusters.map((cluster: any) => cluster);
		const workspaceNames: string[] = top10WorkspaceNames.map((item: { workspace_name: any }) => item.workspace_name);
		const uniqueWorkspaceNames: any = Array.from(new Set(workspaceNames));
		if (!topExpClusterData.includes('All Workspaces') && top10Clusters.length) {
			setTopExpClusterData(['All Workspaces', ...uniqueWorkspaceNames]);
		} else {
			setTopExpClusterData(uniqueWorkspaceNames);
		}
		console.log(uniqueWorkspaceNames + ' uniqueWorkspaceNames ')
		const dailyData: any = res.data.dailyClusterSpent;
		console.log(dailyData + ' dailyClusterSpent')
		const dates = Object.keys(dailyData);
		const latestDate: any = dates.reduce((a, b) => (a > b ? a : b));
		const latestWorkspaceNames: any = dailyData[latestDate].map((item: { workspace_name: any }) => item.workspace_name);
		const latestuniqueWorkspaceNames: any = Array.from(new Set(latestWorkspaceNames));
		if (!clusterSpentData.includes('All Workspaces') && latestWorkspaceNames.length) {
			setClusterSpentData(['All Workspaces', ...latestuniqueWorkspaceNames]);
		} else {
			setClusterSpentData(latestuniqueWorkspaceNames);
		}
		console.log(latestuniqueWorkspaceNames + ' latestuniqueWorkspaceNames')
	}

	async function fetchDate() {
		const res = await getCostSummaryChart();
		if (res.success) {
			if (!localStorage.getItem('processedDate')) {
				localStorage.setItem('processedDate',res.data.processedDate);
				setFromDateTime(moment(res.data.processedDate));
				setPropsFromDateTime(moment(res.data.processedDate));
			} else {
				setFromDateTime(moment(localStorage.getItem('processedDate')));
				setPropsFromDateTime(moment(localStorage.getItem('processedDate')));
			}
		}
	}

	useEffect(() => {
		fetchDate();
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		if (userSector === 'all') fetchSectorOptionsData();
		let storedSectorFilter = localStorage.getItem('dcs-summary-sector-filter')
			? JSON.parse(localStorage.getItem('dcs-summary-sector-filter') ?? '')
			: null;
		if (storedSectorFilter) {
			setCheckedList(storedSectorFilter);
			setCurrSectorFilter(storedSectorFilter);
		}
		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/databricks/costSummary/dashboard') {
			if (activeId) {
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('databricks-jobs');
				document.body.classList.add('datefilter-align-sector');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				activeId.classList.remove('jobs-list-table');
				activeId.classList.remove('jobs-list');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.remove('jobs-exe-datas');
			}
		}
		// fetchCostData();
		fetchWorkspaceList();
	}, []);

	const [selectedTopExpClusterValue, setSelectedTopExpClusterValue] = useState('');
	const [selectedClusterSpentValue, setSelectedClusterSpentValue] = useState('');
	console.log(selectedClusterSpentValue, 'selectedClusterSpentValue');
	const [filterTopExpClsValue, setFilterTopExpClsValue] = useState('');
	const [filterClusterSpentValue, setFilterClusterSpentValue] = useState('');

	const handleFilterChangeWorkspace = (event: any) => {
		setFilterTopExpClsValue(event.target.value);
	};
	const handleFilterChangeCluster = (event: any) => {
		setFilterClusterSpentValue(event.target.value);
	};

	const handleSelectCluster = (workspace: any, isTopExpensive: boolean) => {

		if (isTopExpensive) {
			setSelectedTopExpClusterValue(workspace);
			setFilterMenuLeft(null);
		} else {
			setSelectedClusterSpentValue(workspace);
			setFilterMenuRight(null);
		}
	};

 
	let filteredTopExpCluster = filterTopExpClsValue ? topExpClusterData.filter((name) => name.toLowerCase().includes(filterTopExpClsValue.toLowerCase())) : topExpClusterData;
	let filteredClusterSpent = filterClusterSpentValue ? clusterSpentData.filter((name) => name.toLowerCase().includes(filterClusterSpentValue.toLowerCase())) : clusterSpentData;


	const [filterMenuLeft, setFilterMenuLeft] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);

	const [filterMenuRight, setFilterMenuRight] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);

	const filterClickLeft = (event: any) => {
		event.preventDefault();
		setFilterMenuLeft(
			filterMenuLeft === null
				? {
					mouseX: parseInt(event.clientX) - 20,
					mouseY: parseInt(event.clientY) - 7
				}
				: null
		);
	};
	const filterClickRight = (event: any) => {
		console.log(event.value, 'eventcheck');
		event.preventDefault();
		setFilterMenuRight(
			filterMenuRight === null
				? {
					mouseX: parseInt(event.clientX) - 20,
					mouseY: parseInt(event.clientY) - 7
				}
				: null
		);
	};
	const handleCloseLeft = (event: any) => {
		setFilterMenuLeft(null);
	};

	const handleCloseRight = (event: any) => {
		setFilterMenuRight(null);
	};

	const truncateText = (text: string, maxLength: number) => {
		if (text.length > maxLength && text !== "All Workspaces") {
			return text.slice(0, maxLength) + '...';
		}
		return text;
	};

	let fromDate = moment(propsFromDateTime).format('YYYY-MM-DD');
	let toDate = moment(propsToDateTime).format('YYYY-MM-DD');
	return (
		<div className="dashboard-panel job-schedules-wrap">
			<div className="job-exexutions-dateFilter-div">
				{/* [Date Range Menu] */}
				<div style={{ display: 'flex', alignItems: 'center', padding: '-12px', marginTop: '-22px' }}>
					{/* condition for default Today  */}
					{/* {moment(fromDateTime).format('DD MM YYYY') ===
						moment().startOf('day').subtract(1, 'hours').format('DD MM YYYY') &&
						 <div style={{ marginRight: '10px' }}>Today</div>} */}
					{
						<LocalizationProvider className="outerDateContainer" dateAdapter={AdapterMoment}>
							<DatePicker
								showToolbar={true}
								components={{
									OpenPickerIcon: DatePickerIcon
								}}
								maxDate={new Date()}
								PopperProps={{ placement: 'auto' }}
								className="dateFilterID"
								value={moment(fromDateTime).tz('America/New_York')}
								onChange={(dateTime) => {
									handleDateTimeChange('fromDateTime', dateTime);
								}}
								renderInput={(params: any) => <TextField name="startDateTime" {...params} />}
							/>
						</LocalizationProvider>
					}
				</div>

				{/* Code for sector filter */}
				<Stack>
					{userSector === 'all' ? (
						<FilterIcon onClick={sectorFilterClick} className="sector-filter1" />
					) : (
						<div className="separeat_sector">Sector: {getDecodedToken()?.sector?.name}</div>
					)}
					<Popover
						open={filterMenu !== null}
						onClose={handleClose}
						className="filterContainer"
						anchorReference="anchorPosition"
						anchorPosition={filterMenu !== null ? { top: filterMenu.mouseY, left: filterMenu.mouseX } : undefined}
					>
						<div className="text-filter-row">
							<div className="selectAll-sec">
								<MenuItem className="selectAll">
									<FormControlLabel
										className={classes.checkbox}
										label="All"
										control={
											<Checkbox
												color="success"
												value="all"
												checked={itemsChecked}
												onChange={(e) => {
													selectAllCheck(e);
												}}
											/>
										}
									/>
								</MenuItem>
								<div className="checkbox-sec">
									{sectorOptionsList &&
										sectorOptionsList.map((filter: any) => (
											<MenuItem key={filter}>
												<FormControlLabel
													className={classes.checkbox}
													label={filter === true ? 'Yes' : filter === false ? 'No' : filter}
													control={
														<Checkbox
															color="success"
															value={filter}
															checked={
																(filter === true && checkedList.includes('true')) ||
																(filter === false && checkedList.includes('false')) ||
																checkedList.includes(filter)
															}
															onChange={(e) => {
																handleChange(e);
															}}
														/>
													}
												/>
											</MenuItem>
										))}
								</div>
							</div>
							<div className="buttonSec">
								<Button variant="contained" className={classes.cancelBtn} onClick={handleClose}>
									cancel
								</Button>
								<Button variant="contained" className={classes.okBtn} onClick={handleOkClick}>
									ok
								</Button>
							</div>
						</div>
					</Popover>
				</Stack>
			</div>
			<>
				<div>
					<div className='clear-both'>
						<div className="left-iframe-div-costsummary width-55">
							<Stack>
								<AnalyticsVisualization
									params={{
										dashboardUrl: dashboardUrl.dataBricksCostSummary,
										className: 'dataBricksIframeContainer',
										otherParams: {
											'var-fromDate': fromDate,
											'var-toDate': toDate,
											'var-sector': currSectorFilter
										},
										seamless: true
									}}
								/>
							</Stack>
						</div>
						<div className="right-iframe-div-costsummary width-45">
						<Stack>
							<div className="month-dropdown-wrap">
								<select className='month-dropdown' onChange={(e) => { getMonthsFilter(e.target.value); }}>
									{pastMonths.map(month => (
										<option key={month.value} value={month.value}>{month.label}</option>
										))}
								</select>
							</div>
							<AnalyticsVisualization
									params={{
										dashboardUrl: dashboardUrl.dataBricksTotalCostByWorkspace,
										className: 'dataBricksCostByWorkspace',
										otherParams: {
											'var-sector': currSectorFilter,
											'var-year': yearFilter,
											'var-monthNumber': monthNumberFilter
										},
										seamless: true
									}}
								/>
						</Stack>
						</div>
					</div>
					<div className='clear-both'>
						<div className="left-iframe-div-costsummary width-45">
							<Stack>
								<div>
									{topExpClusterData.length > 0 && (

										<Tooltip title={selectedTopExpClusterValue || 'All Workspaces'} placement="top">
											<div onClick={(event) => filterClickLeft(event)} className="sector-filterLeft">
												{truncateText(selectedTopExpClusterValue || 'All Workspaces', 10)}
												<span>
													<Downarrow className='left-down-arrow' />
												</span>
											</div>
										</Tooltip>

										)}
									<Popover
										open={filterMenuLeft !== null}
										onClose={handleCloseLeft}
										className="costSummaryFilter"
										anchorReference="anchorPosition"
										anchorPosition={
											filterMenuLeft !== null ? { top: filterMenuLeft.mouseY, left: filterMenuLeft.mouseX - 220 } : undefined
										}
									>
										<div className="text-filter-row">
											<div className="selectAll-sec custom-select-options">
												<div>
													<input
														value={filterTopExpClsValue}
														onChange={handleFilterChangeWorkspace}
														placeholder="Search..."
														className="selectAll-sec-input"
													/>
												</div>
												<div className="checkbox-sec">
													<List>
														{filteredTopExpCluster.map((workspace, index) => (

															<ListItem
																key={index}
																onClick={() => handleSelectCluster(workspace, true)}
																className="Popover-listItem"
															>
																<ListItemText primary={workspace} />
															</ListItem>
														))}
													</List>
												</div>
											</div>
										</div>
									</Popover>
								</div>
								<AnalyticsVisualization
									params={{
										dashboardUrl: dashboardUrl.dataBricksCostSummaryWorkSpace,
										className: 'dataBricksLeftIframeContainer',
										otherParams: {
											'var-fromDate': fromDate,
											'var-toDate': toDate,
											'var-filterWorkSpace': selectedTopExpClusterValue,
											'var-sector': currSectorFilter
										},
										seamless: true
									}}
								/>
							</Stack>
						</div>
						<div className="right-iframe-div-costsummary width-55">
							<Stack>
								<div>
									{clusterSpentData.length > 0 && (
										
										<Tooltip title={selectedClusterSpentValue || 'All Workspaces'} placement="top">
											<div onClick={(event) => filterClickRight(event)} className="sector-filterRight">
												{truncateText(selectedClusterSpentValue || 'All Workspaces', 10)}
												<span>
													<Downarrow className='right-down-arrow' />
												</span>
											</div>
										</Tooltip>
									)}
									<Popover
										open={filterMenuRight !== null}
										onClose={handleCloseRight}
										className="costSummaryFilter"
										anchorReference="anchorPosition"
										anchorPosition={
											filterMenuRight !== null ? { top: filterMenuRight.mouseY, left: filterMenuRight.mouseX - 180 } : undefined
										}
									>
										<div className="text-filter-row">
											<div className="selectAll-sec custom-select-options">
												<div>
													<input
														value={filterClusterSpentValue}
														onChange={handleFilterChangeCluster}
														placeholder="Search..."
														className="selectAll-sec-input" // Add the class name to the input
													/>
												</div>
												<div className="checkbox-sec">
													<List>
														{filteredClusterSpent.map((workspace, index) => (

															<ListItem
																key={index}
																onClick={() => handleSelectCluster(workspace, false)}
																className="Popover-listItem"
															>
																<ListItemText primary={workspace} />
															</ListItem>
														))}
													</List>
												</div>
											</div>
										</div>
									</Popover>
								</div>
								<AnalyticsVisualization
									params={{
										dashboardUrl: dashboardUrl.dataBricksCostSummaryCluster,
										className: 'dataBricksRightIframeContainer',
										otherParams: {
											'var-fromDate': fromDate,
											'var-toDate': toDate,
											'var-filterWork': selectedClusterSpentValue,
											'var-sector': currSectorFilter
										},
										seamless: true
									}}
								/>
							</Stack>
						</div>
					</div>
				</div>
			</>
			<Workspace fromDateTime={propsFromDateTime} toDateTime={propsFromDateTime} sectorFilter={sector} setFromDateTime={setFromDateTime}
				dateSelect={dateSelect} />
			<CostSummartProject fromDateTime={propsFromDateTime} toDateTime={propsFromDateTime} sectorFilter={sector} setFromDateTime={setFromDateTime}
				dateSelect={dateSelect} />
		</div>
	);
};

export default CostSummary;
