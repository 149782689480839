import Copyright from '../../assets/copyright.svg';
import './footer.css';

const footer = () => {
	return (
		<p className="login-footer-text">
			<img src={Copyright} /> PepsiCo {new Date().getFullYear()}
		</p>
	);
};

export default footer;
