import { Link, useParams, useLocation } from 'react-router-dom';
import React, { lazy, Suspense } from 'react';
import { Stack, Button } from '@mui/material';
import './tableauJobs.scss';
import PerformanceIcon from '../../../assets/Performance.svg';
// import AnalyticsVisualization from '../../framework/analyticsVisualization';
import { dashboardUrl } from '../../../service/config';
import { ReactComponent as LeftArrowIcon } from '../../../assets/arrow_left.svg';


// Lazy load the AnalyticsVisualization component
const AnalyticsVisualization = lazy(() => import('../../framework/analyticsVisualization'));

const TableauJobsDetails = () => {
	const location = useLocation();
			// navigate to Performance Page
			const handleGoBack = () => {
				window.location.href = '/tableau/performance/dashboard';
			};
		
	return (
		<>
			<Stack>
				<Link to={`/tableau/performance/dashboard`} className="titleLink">
					<span className="performance-model-menu">
						<img src={PerformanceIcon} className="ml-icon" />
						<span className="performance-header-name">Performance</span>
					</span>
				</Link>
				<span className="performance-details-symbol">&gt;</span>{' '}
				<span className="performance-details-title">
					{location.state.jobName || location.state.jobId}
				</span>
			</Stack>
			<Stack>		
			<Button  className='goBackButton' variant="contained" onClick={handleGoBack} startIcon={<LeftArrowIcon  />}>Go Back</Button>
			</Stack>
			<Stack>
			<Suspense fallback={<div>Loading...</div>}>
				<AnalyticsVisualization
					params={{
						dashboardUrl: dashboardUrl.tableauPerformanceJobDetailView,
						className: 'tableauPerformanceJobDetailViewIframeContainer',
						otherParams: {
							'var-jobId': location.state.jobId
						},
						seamless: true
					}}
				/>
				</Suspense>
			</Stack>
		</>
	);
};
export default TableauJobsDetails;
