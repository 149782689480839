import React, { useEffect, useState, useRef } from 'react';
import { Grid } from '@mui/material';

import type { Column } from 'react-data-grid';
import './Governance.scss';
import { CustomizedDataGrid } from '../../customized_data_grid';
import Loader from '../../Loader/loader';
import moment from 'moment-timezone';
import { getGovernanceRunningJobs, getGovernanceRunningJobsDate, GovernanceRunningjobSearch } from '../../../service/service';
import { useSearchParams } from 'react-router-dom';
import { databricksurl } from '../../../service/config';

import axios from 'axios';
const GovernanceJobs =  ({ fromDateTime, sectorFilter, setFromDateTime, dateSelect }: any) => {

	const messagesEndRef = useRef<null | HTMLDivElement>(null);

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block:"end" })
	}

	interface Row {
		sector_name: string;
		job_name: string;
		project_name: string;
		workspace_name: string;
		cluster_name: string;
		notebook_path: string;
		job_start_date: string;
		// job_runTimeInMin: number;
		// cluster_count: number;
		cluster_version: string;
	}
	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'sector_name',
				name: 'Sector Name',
				formatter(props) {
					return <div title={props.row.sector_name} className='has-ellipsis'>{props.row.sector_name}</div>
				},
				minWidth: 130
			},
			{
				key: 'workspace_name',
				name: 'Workspace',
				formatter(props) {
					return <div title={props.row.workspace_name} className='has-ellipsis'>{props.row.workspace_name}</div>
				},
				minWidth: 130
			},
			{
				key: 'cluster_name',
				name: 'Cluster',
				formatter(props) {
					return <div title={props.row.cluster_name} className='has-ellipsis'>{props.row.cluster_name}</div>
				},
				minWidth: 130
			},
			{
				key: 'job_start_date',
				name: 'Start Date',
				minWidth: 140,
				formatter(props) {
					const startDate = props.row.job_start_date;
					if (startDate) {
						return moment(startDate).utc().format('MM-DD-YY');
					}
					return '';
				}
			},
			{
				key: 'job_name',
				name: 'Job Name',
				formatter(props) {
					return <div title={props.row.job_name} className='has-ellipsis'>{props.row.job_name ? props.row.job_name : '--'}</div>
				},
				minWidth: 150,
				width: 308
			},
			{
				key: 'notebook_path',
				name: 'Notebook',
				formatter(props) {
					return <div title={props.row.notebook_path} className='has-ellipsis'>{props.row.notebook_path ? props.row.notebook_path: '--'}</div>
				},
				minWidth: 100,
				width: 200
			},
			{
				key: 'project_name',
				name: 'Project',
				formatter(props) {
					return <div title={props.row.project_name} className='has-ellipsis'>{props.row.project_name ? props.row.project_name : '--'}</div>
				},
				minWidth: 130
			},
			{
				key: 'cluster_version',
				name: 'Cluster Version',
				minWidth: 130,
				formatter(props) {
					return <div title={props.row.cluster_version} className='has-ellipsis'>{props.row.cluster_version ? props.row.cluster_version : '--'}</div>
				}
			}
		];
	}

	const defaultColumnNames = [
		'Sector Name',
		'Job Name',
		'Project',
		'Workspace',
		'Notebook',
		'Start Date',
		'Job Run Time',
		'Cluster',
		'Cluster Version'
	];

	const dataFetchedRef = useRef(false);
	const [runningJobsList, setrunningJobsList] = useState<Row[]>([]);
	const [sortingInfo, setSortingInfo] = useState({ sortBy:  'project_name', sortDir: 'desc' });
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);	

	// Date range
	const [filterData, setFilterData] = useState<Row[]>([]);
	const [searchDetails, setFilterSearchInfo] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [filterInfo, setFilterInfo] = useState([]);
	const [filterByColumnInfo, setFilterByColumnInfo] = useState([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const hasDrillDowned = searchParams.has('isDrillDowned');
	

	const handlePageChange = (event: any, value: number) => {
		setLoading(true);
		let nextPage = value.toString(); 
		if(event.target.dataset.testid == "LastPageIcon" || event.target.dataset.testid == "FirstPageIcon"){
			nextPage = value.toString(); 
		}
		else if(event.target.dataset.testid == "NavigateBeforeIcon"){
			nextPage = (Number(page) - 1).toString();
		}
		else if (nextPage === event.target.textContent) { 
			nextPage = event.target.textContent;
		} else {
			nextPage = (Number(page) + 1).toString();
		}
		const promise1 = DBGovernanceJobsListingFunc(
			parseInt(nextPage),
			pageSize,
			sortingInfo,
			fromDateTime,
			fromDateTime,
			parseFilter(filterInfo),
			sectorFilter
		).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};


	const handlepageSizeChange = (event: any) => {
		setLoading(true);
		const promise1 = DBGovernanceJobsListingFunc(
			'1',
			event.target.value,
			sortingInfo,
			fromDateTime,
			fromDateTime,
			parseFilter(filterInfo),
			sectorFilter
		).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

	
	const handleSorting = (sortingInfo: any) => {
		setLoading(true);
		const promise1 = DBGovernanceJobsListingFunc(
			page,
			pageSize,
			sortingInfo,
			fromDateTime,
			fromDateTime,
			parseFilter(filterInfo),
			sectorFilter
		).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

	const resetSearchParams = () => {
		console.log(hasDrillDowned, 'hadDrillDownCheck')
		if (searchParams.has('fromDate') || searchParams.has('fromDate')) {
			searchParams.delete('status');
			searchParams.delete('scheduleDate');
			searchParams.delete('fromDate');
			searchParams.delete('fromDate');
			setSearchParams(searchParams);
		}
		else if(hasDrillDowned) {
			// console.log(searchParams, 'searchParams=')
			// searchParams.delete('status');
			// searchParams.delete('isDrillDowned');
			// setSearchParams(searchParams);
		}	
		
	};

	const handleFilter = (filterByColumnInfo: any) => {
		resetSearchParams();
		setFilterByColumnInfo(parseFilter(filterByColumnInfo));
		setLoading(true);
		const promise1 = DBGovernanceJobsListingFunc(
			1,
			pageSize,
			sortingInfo,
			fromDateTime,
			fromDateTime,
			parseFilter(filterInfo),
			sectorFilter
		).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

	async function jobSearchForDBGovernanceFunc(searchDetails: any, columnKey: any) {
		const res = await GovernanceRunningjobSearch(
			'1',
			pageSize,
			fromDateTime,
			parseFilter(filterInfo),
			sectorFilter,
			searchDetails,
			columnKey,
			parseFilter(filterByColumnInfo)
			);
		if (res.success) {
			setFilterSearchInfo(res.data);
		}
	}
	const handleSearch = (searchDetails: any, columnKey: any) => {
		console.log(searchDetails, columnKey);
		if(searchDetails?.length >= 3)
		jobSearchForDBGovernanceFunc(searchDetails, columnKey);
	};
	
	const formatColumnFilterOptions = (apiData: any) => {
		const filterOptions: any = {
			job_name: [],
			project_name: [],
			workspace_name: [],
			cluster_version: []
		}
		apiData.filter((job: any) => {
			Object.entries(job).forEach(([key, value]) => {
				if (key == 'job_name' && !filterOptions.job_name.includes(value)) {
					filterOptions.job_name.push(value);
				} else if (key == 'project_name' && !filterOptions.project_name.includes(value)) {
					filterOptions.project_name.push(value);
				} else if (key == 'workspace_name' && !filterOptions.workspace_name.includes(value)) {
					filterOptions.workspace_name.push(value);
				} else if (key == 'cluster_version' && !filterOptions.cluster_version.includes(value)) {
					filterOptions.cluster_version.push(value);
				}
			});
		});
		return filterOptions;
	}

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

	// initial load API call
	async function DBGovernanceJobsListingFunc(
		pageNumber: any,
		size: any,
		sortingInfo: any,
		fromDateTime: any,
		toDateTime: any,
		jobFilterInfo: any,
		sector: any
	) {
		setLoading(true);
		try {
			// Check if there's a stored sector filter in localStorage
			let storedSectorFilter = localStorage.getItem('d-resource-sector-filter')
				? JSON.parse(localStorage.getItem('d-resource-sector-filter') ?? '')
				: null;
			if (storedSectorFilter) {
				sector = storedSectorFilter;
			}
	
			// Make a call to Databricks
			const databricksresponse = await axios.get(`${databricksurl}`);
		
			console.log('Databricks Call initiated', databricksresponse);
	
			if (databricksresponse && databricksresponse.status) {
				// Fetch the governance running jobs data
				const res = await getGovernanceRunningJobs(
			pageNumber.toString(),
			size.toString(),
			sortingInfo,
			jobFilterInfo,
			fromDateTime,
			fromDateTime,
			sector,
			parseFilter(filterByColumnInfo)
		);
		if (res.success) {
			setTimeout(() => {
				console.log('scroll to table - ', hasDrillDowned);
				if (hasDrillDowned) {
					scrollToBottom();
				}
			}, 1000);
			if(res.data.result !== null){
			const addDollar = res.data.result.map((item: any) => ({
					...item,
					job_runTimeInMin: item.job_runTimeInMin !== null && item.job_runTimeInMin !== undefined
											? formatRunTime(item.job_runTimeInMin)
							: null,
						}));
			setrunningJobsList(addDollar);
			setPaginationData(res.data.page);
			setFilterData(res.data.filterOptions);
			setFilterInfo(jobFilterInfo);
			setPage(pageNumber);
			setPageSize(size);
			setFromDateTime(localStorage.getItem('processedDate'));
			setSortingInfo(sortingInfo);
			setLoading(false);
						setError(null);
		}
				} else {
					setLoading(false);
					setError('Failed to fetch data'); // Set the error state if API call fails
				}
			} else {
				setLoading(false);
				setError('Cluster Inactive');
		}
	} catch (error) {
		setLoading(false);
		setError('Failed to fetch data'); // Set the error state if API call fails
	}
	}

	function formatRunTime(number: number) {
		let numStr = number.toString();
		numStr = numStr.replace(/0+$/, '');
		const remainingDigits = numStr.slice(-6).padStart(6, '0'); 
		const hours = remainingDigits.slice(0, 2);
		const minutes = remainingDigits.slice(2, 4);
		const seconds = remainingDigits.slice(4, 6);
		const time = `${hours}:${minutes}:${seconds}`;
		return time;	
	}
	// User select specific date API call
	async function DBGovernanceJobsListingDateFunc(
		pageNumber: any,
		size: any,
		sortingInfo: any,
		fromDateTime: any,
		toDateTime: any,
		jobFilterInfo: any,
		sector: any
	) {
		setLoading(true);
		try {	
			const res = await getGovernanceRunningJobsDate(
			pageNumber.toString(),
			size.toString(),
			sortingInfo,
			jobFilterInfo,
			fromDateTime,
			fromDateTime,
			sector,
			filterByColumnInfo
		);
		if (res.success) {
			if(res.data.result !== null){
			const addDollar = res.data.result.map((item: any) => ({
					...item,
					job_runTimeInMin: `${item.job_runTimeInMin !== null && item.job_runTimeInMin !== undefined ? formatRunTime(item.job_runTimeInMin) : null}`,
				}));
			setrunningJobsList(addDollar);
			setPaginationData(res.data.page);
			setFilterData(res.data.filterOptions);
			// setFilterData(formatColumnFilterOptions(res.data.result));
			setFilterInfo(jobFilterInfo);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);	
			setLoading(false);
		}}else {
			setLoading(false);
			setError('Failed to fetch data'); // Set the error state if API call fails
		}
	} catch (error) {
		setLoading(false);
		setError('Failed to fetch data'); // Set the error state if API call fails
	}
	}

	// API
	useEffect(() => {	
		setError(null);
		const filterQuerySkeleton = {} as any;
		const _status = searchParams.get('status');
		console.log(_status,"_status==");
		console.log(hasDrillDowned, 'hasDrillDown==')
		if (hasDrillDowned) {
			switch (_status) {
				case 'LongRunningNotebooks':
					filterQuerySkeleton.longRunningNotebooks = 'true';
					break;
				case 'JobsRunningOlderVersionofSpark':
					console.log("failedJobs=")
					filterQuerySkeleton.jobsRunningOlderVersionofSpark = 'true';
					break;
				case 'JobsRunningonNotebooks':
					console.log("failedJobs=")
					filterQuerySkeleton.jobsRunningonNotebooks = 'true';
					break;
				default:
					break;
			}
		} else {
			getColumns().forEach((column: any) => {
				filterQuerySkeleton[column.key] = [];
			});
		}
		setFilterInfo(filterQuerySkeleton);
		if (hasDrillDowned) {
			DBGovernanceJobsListingFunc(page, pageSize, sortingInfo, fromDateTime, fromDateTime, parseFilter(filterQuerySkeleton), sectorFilter);
		} else {
			DBGovernanceJobsListingFunc(page, pageSize, sortingInfo, fromDateTime, fromDateTime,[],sectorFilter)
			.catch(
				(error) => {
					setError(error); // Set error state when API call fails during initial load
				}
			);
		}

		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
	}, [sectorFilter]);

	useEffect(() => {
		setError(null);
		if (dateSelect != 0) {
			DBGovernanceJobsListingDateFunc(
				"1",
				pageSize,
				sortingInfo,
				fromDateTime,
				fromDateTime,
				parseFilter(filterInfo),
				sectorFilter
			);
		}
	}, [dateSelect]);

	return (
			
		<div ref={messagesEndRef} className='governance_tabledata-wrap'>
				<p className="databricks_title">Running Jobs</p>
				{error == 'Cluster Inactive' ? (
					// Display error message or handle error condition in UI
					<div
						style={{
							color: '#e5e5e5',
							marginLeft: '25%',
							padding: '10px',
							fontWeight: 500,
							gridColumn: '1 / -1'
						}}
					>
						Cluster is Inactive, it will take Max 2mins to start, Please try again later
					</div>
				) :error? (
					// Display error message or handle error condition in UI
					<div
						style={{
							color: '#e5e5e5',
							marginLeft: '25%',
							padding: '10px',
							fontWeight: 500,
							gridColumn: '1 / -1'
						}}
					>
						Error occurred while fetching data. Please try again.
					</div>
				) : (
				<Grid  className="governance_tabledata">
				{!loading ? (<>
					<CustomizedDataGrid
						tableColumns={getColumns()}
						tableData={runningJobsList}
						tableType="governanceRunningJobs"
						defaultColumnNames={defaultColumnNames}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						filterDataOptions={filterData}
						onFilterCheck={handleFilter}
						currFilter={filterByColumnInfo}
						searchDataValues={searchDetails}
						onFilterSearch={handleSearch}
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={runningJobsList?.length}
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isDateRangeFilter={false}
						isDisabled={false}
					/>
					{runningJobsList?.length ? <p className="disclaimer">Note: Double hyphens (--) indicate that the field has no data.</p> : ''}
					</>
					) : (
						<Loader />
					)}
			</Grid>
				
				)}
		</div>
		

	);
};

export default GovernanceJobs;
