import React, { useEffect, useState, useRef } from 'react';
import { Grid } from '@mui/material';
import { getModels } from '../../../service/service';
import moment from 'moment-timezone';

import type { Column } from 'react-data-grid';
import { CustomizedDataGrid } from '../../customized_data_grid';
import '../models/model.css';
import Loader from '../../Loader/loader';
import { Link, useSearchParams } from 'react-router-dom';

export default function ModelListing({ sharedValue }: any) {
	interface Row {
		id: string;
		name: string;
		model_type: string;
		sector: string;
		project: string;
		training_date: Date;
		version: string;
		updatedAt: Date;
		columns_with_drift: number;
	}
	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'name',
				name: 'Model Name',
				minWidth: 150,
				formatter(props) {
					return (
						<Link to={`/machineLearning/models/${props.row.id}`} state={props.row} className="modelsNameList">
							{props.row.name}
						</Link>
					);
				}
			},
			{
				key: 'sector',
				name: 'Sector',
				minWidth: 170
			},
			{
				key: 'project',
				name: 'Project',
				minWidth: 170
			},
			{
				key: 'model_type',
				name: 'Model Type',
				minWidth: 170
			},
			{
				key: 'updatedAt',
				name: 'Last Updated On',
				minWidth: 170,
				formatter(props) {
					return (
						props.row.updatedAt && `${moment(props.row.updatedAt).format()} ${moment().isDST() ? '(UTC-4)' : '(UTC-5)'}`
					);
				}
			},
			{
				key: 'training_date',
				name: 'Training Date',
				minWidth: 170,
				formatter(props) {
					return (
						props.row.training_date && `${moment(props.row.training_date).format()} ${moment().isDST() ? '(UTC-4)' : '(UTC-5)'}`
					);
				}
			},
			{
				key: 'version',
				name: 'Version',
				minWidth: 170
			}
		];
	}
	const defaultColumnNames = [
		'Model Name',
		'Sector',
		'Project',
		'Model Type',
		'Last Updated On',
		'Training Date',
		'Version'
	];
	const dataFetchedRef = useRef(false);
	const [modelList, setModelList] = useState<Row[]>([]);
	const [loading, setLoading] = useState(false);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);

	const [searchParams, setSearchParams] = useSearchParams();
	const hasDrillDown = searchParams.has('isDrillDowned');

	const [filterData, setFilterData] = useState<Row[]>([]);
	const [filterInfo, setFilterInfo] = useState({} as any);

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		listModels(value, pageSize, sortingInfo, parseFilter(filterInfo));
	};

	const handlepageSizeChange = (event: any) => {
		listModels(page, event.target.value, sortingInfo, parseFilter(filterInfo));
	};

	const resetSearchParams = () => {
		if (hasDrillDown) {
			searchParams.delete('status');
			searchParams.delete('isDrillDowned');
			setSearchParams(searchParams);
		}
	};

	const handleFilter = (filterInfo: any) => {
		setFilterInfo(filterInfo);
		resetSearchParams();
		listModels(1, pageSize, sortingInfo, parseFilter(filterInfo));
	};

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				// parsedFilter[key] = filterInfo[key].map((value: string) => encodeURIComponent(value));
				parsedFilter[key] = filterInfo[key].map((value: string) => {
					// Check if the value includes "S&T" and replace it with "SNT"
					if (value.includes('S&T')) {
						return encodeURIComponent(value.replace('S&T', 'SNT'));
					} else {
						return encodeURIComponent(value);
					}
				});
				console.log(parsedFilter[key]);
			}
		});
		return parsedFilter;
	};

	useEffect(() => {
		const sectorFilter = sharedValue.sectorFilter;
		if (sectorFilter && sectorFilter.length) {
			filterInfo.sector = sectorFilter;
			listModels(page, pageSize, sortingInfo, parseFilter(filterInfo));
		}
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;

		let storedModelsState = localStorage.getItem('modelsState')
			? JSON.parse(localStorage.getItem('modelsState') ?? '')
			: null;
		if (storedModelsState && storedModelsState.pageNumber && storedModelsState.pageSize) {
			listModels(
				1,
				storedModelsState.pageSize,
				storedModelsState.sortingInfo,
				parseFilter(storedModelsState.filterInfo)
			);
		} else {
			listModels(page, pageSize, sortingInfo, parseFilter(filterInfo));
		}

		const activeId = document.getElementById('root');
		if (activeId) {
			const mainContent = document.getElementsByClassName('main-content')[0];

			if (mainContent) {
				// Use type assertion to let TypeScript know that myElement has a style property
				(mainContent as HTMLElement).style.overflow = 'scroll';
				(mainContent as HTMLElement).style.height = 'calc(100vh - 35px)';
			}

			activeId.classList.add('jobs-list-table');
			activeId.classList.remove('root');
			document.body.classList.add('jobs-exe-datas');
			document.body.classList.add('jobs-list-datas');
		}
	}, [sharedValue]);

	async function listModels(pageNumber: any, size: any, sortingInfo: any, modelFilterInfo: any) {
		setLoading(true);
		const drillDownStatus = hasDrillDown ? searchParams.get('status') : '';
		const res = await getModels(pageNumber.toString(), size.toString(), sortingInfo, modelFilterInfo, drillDownStatus);
		if (res.success) {
			setModelList(res.data.models);
			setPaginationData(res.data.page);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
			setFilterData(res.data.filterOptions);
			setFilterInfo(modelFilterInfo);
			setLoading(false);
			const modelsState = {
				page: res.data.page,
				filterOptions: res.data.filterOptions,
				pageSize: size,
				pageNumber,
				sortingInfo,
				filterInfo: modelFilterInfo
			};
			localStorage.setItem('modelsState', JSON.stringify(modelsState));
		}
	}
	const handleSorting = (sortingInfo: any) => {
		listModels(page, pageSize, sortingInfo, parseFilter(filterInfo));
	};

	return (
		<div className="page-table models_tabledata">
			<h3>Models Details</h3>
			<Grid>
				{!loading ? (
					<CustomizedDataGrid
						tableColumns={getColumns()}
						tableData={modelList}
						tableType="ML_Models"
						defaultColumnNames={defaultColumnNames}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						filterDataOptions={filterData}
						onFilterCheck={handleFilter}
						currFilter={filterInfo}
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={modelList.length}
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isDateRangeFilter={false}
						isDisabled={false}
						isFetching={loading}
					/>
				) : (
					<Loader />
				)}
			</Grid>
		</div>
	);
}
