import React, { useEffect } from 'react';
import AnalyticsVisualization from '../framework/analyticsVisualization';
import { dashboardUrl } from '../../service/config';
import UnderDevelopment from '../../assets/under-construction.svg';
import './vv.scss';
const VVTableau = () => {	
	useEffect(() => {
	
	const url = window.location.pathname;
	const activeId = document.getElementById('root');
	if (window.location.pathname === '/verificationValidation/dashboard') {
		if (activeId) {				
			activeId.classList.remove('root');
			document.body.classList.add('iframe-height-vv');
		}
	}
}, []);
	return (
		<div>
			{dashboardUrl.verificationValidationTableau !== undefined ? (
				<div style={{ marginTop: '-102px' }}>
					<AnalyticsVisualization
						params={{
							dashboardUrl: dashboardUrl.verificationValidationTableau,
							className: 'powerBiCapacityIframeContainer',

							otherParams: {
								from: 'now-6h',
								to: 'now'
							},
							seamless: true
						}}
					/>
				</div>
			) : (
				<div className='under-development'>
					<img src={UnderDevelopment} />
					<p className='under-development-text'>Dashboard under development!</p>
				</div>
			)}
		</div>
	);
};

export default VVTableau;

