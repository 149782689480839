import { Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Popover, Select, Stack } from '@mui/material';

import '../DataEstate/data-estate-dashboard.scss';
import React, { useEffect, useRef, useState } from 'react';
import { fetchServiceRequestSummary, getDecodedToken, getSector, programSummaryDetail, getServiceReq } from '../../service/service';
import { ReactComponent as FilterIcon } from '../../assets/ml_summary_filter.svg';

import useStyles from '../jobs/jobs_styles';
import moment from 'moment';
import { ReactComponent as CalendarIcon } from '../../assets/calendar-outline_white.svg';
import Loader from '../Loader/loader';
import { ReactComponent as Downarrow } from '../../assets/down-arrow.svg';
import { CustomizedDataGrid } from '../customized_data_grid';
import { Column, Row } from 'react-data-grid';
import { getSectorSql } from '../../service/service';
import { isEnvTypePROD } from '../../service/config';
import NoPrgramFound from '../../assets/no-program-found.svg';
import { formatNumberWithCommas } from '../../utils/common-util';

export default function serviceRequestSummary() {

	const [MTTRData, SetMTTRData] = useState([]);
	const [MTTRDataObject, SetMTTRDataObject] = useState<any>({});
	const [MTBFData, SetMTBFData] = useState([]);
	const [MTBFDataObject, SetMTBFDataObject] = useState<any>({});
	const [IncidentsData, SetIncidentsData] = useState([]);
	const [IncidentsDataObject, SetIncidentsDataObject] = useState<any>({});
	const [RequestsData, SetRequestsData] = useState<any>({});
	const [OutagesData, SetOutagesData] = useState<any>({});
	
	// sector filter
	const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
	const [filterMenu, setFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	
	const [dateFilterMenu, setDateFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	
	const classes = useStyles();
	const [itemsChecked, setItemsChecked] = useState(false);
	const [sectorOptionsList, setSectorOptionsList] = useState<any[]>([]);
	const [checkedList, setCheckedList] = useState<any[]>([]);
	const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);
	const [sector, setCurrSector] = useState<any[]>([]);
	const [filterMonth, setFilterMonth] = useState("0M");
	const [filterDefaultMonth, setFilterDefaultMonth] = useState("0M");
	const [customYear, setCustomYear] = useState(moment().format("YYYY"));
	const [isDateOpen, setIsDateOpen] = useState(false);
	const isCustomMonth = useRef(false);
	const dataFetchedRef = useRef(false);
	const [loading, setLoading] = useState(false);
	// server filter
	const [dateFilter, setDateFilter] = useState<any>('Apr 2024');
	const [customMonth, setCustomMonth] = useState('');

	// Server filter code
	const handleDateFilter = (field: any, selectedDate: any) => {	
		selectedDate = selectedDate.target.value	
		if (field === 'dateFilter') {
			isCustomMonth.current = false;
			setFilterMonth(selectedDate);
			setCustomMonth('');
			if(selectedDate === "2023-01") {
				isCustomMonth.current = true;
				setIsDateOpen(true)
				return;
			}
		}
		setFilterDefaultMonth(selectedDate)
		setFilterMonth(selectedDate);
		applyDateFilter(selectedDate);
	};
	const handleMonthOnclick = (value:any) => {
		isCustomMonth.current = false;
		setIsDateOpen(false);
		const selectedDate = customYear + "-" + value;
		setCustomMonth(selectedDate);
		applyDateFilter(selectedDate);
		setDateFilterMenu(null);
	}
	const handleYearAdd = () => {
		let selectedYear = Number(customYear);
		selectedYear++;
		setCustomYear(selectedYear.toString());
	}
	const handleYearMinus = () => {
		let selectedYear = Number(customYear);
		selectedYear--;
		setCustomYear(selectedYear.toString());
	}
	const handleCustomOnclick = (e:any) => {
		if (e.target.textContent == 'Custom Month') {
			isCustomMonth.current = true;
			dateFilterMenuClick(e);
		}
		if(isCustomMonth.current)
			setIsDateOpen(true);
		else
			setIsDateOpen(false);

		if (e.type == 'click' && e.target.className.includes('MuiModal-backdrop')) {
			isCustomMonth.current = false;
			setDateFilterMenu(null);
			setIsDateOpen(false);
			if (customMonth == '') {
				setFilterMonth(filterDefaultMonth)
			}
		}
	}

	const applyDateFilter = (selectedDate: any) => {
		getServiceRequestSummary(selectedDate);
		setDateFilter(selectedDate);
};

	const convertShortDateToActualDateFormat = (selectedDate: any) => {
		let formattedDate = moment().format('YYYY-MM');
		if (selectedDate == '0M') {
			formattedDate = moment().format('YYYY-MM');
		} else if (selectedDate == '1M') {
			formattedDate = moment().subtract(1, 'months').format('YYYY-MM');
		} else if (selectedDate == '3M') {
			formattedDate = moment().subtract(3, 'months').format('YYYY-MM');
		} else if (selectedDate == '6M') {
			formattedDate = moment().subtract(6, 'months').format('YYYY-MM');
		} else if (selectedDate == '9M') {
			formattedDate = moment().subtract(9, 'months').format('YYYY-MM');
		} else if (selectedDate == '1Y') {
			formattedDate = moment().subtract(1, 'years').format('YYYY-MM');
		} else if (selectedDate == '2Y') {
			formattedDate = moment().subtract(2, 'years').format('YYYY-MM');
		} else {
			formattedDate = selectedDate
		}
		return formattedDate;
	}

	const dateFilterMenuClick = (event: any) => {
		setDateFilterMenu(
			dateFilterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 260,
						mouseY: parseInt(event.clientY) - 50
				  }
				: null
		);
	};

	const handleDateFilterMenuClose = (event: any) => {
		setDateFilterMenu(null);
	};

	const sectorFilterClick = (event: any) => {
		event.preventDefault();
		setFilterMenu(
			filterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 20,
						mouseY: parseInt(event.clientY) - 6
				  }
				: null
		);
	};

	const handleSectorClose = (event: any) => {
		setFilterMenu(null);
	};

	const selectAllSectorCheck = (e: any) => {
		const { checked } = e.target;
		const collection: any[] = [];
		if (checked) setCheckedList(sectorOptionsList);
		else setCheckedList([]);
		setItemsChecked(checked);
	};

	const handleSectorChange = (e: any) => {
		const { value, checked } = e.target;
		const newlyChecked = [];
		if (checked && e.target.value) {
			newlyChecked.push(e.target.value);
		}
		if (checked) {
			setCheckedList([...checkedList, ...newlyChecked]);
			if(sectorOptionsList.length === (checkedList.length + newlyChecked.length)){
				setItemsChecked(true);
			}
		} else {
			setCheckedList(checkedList.filter((item) => item !== value));
			setItemsChecked(false);
		}
	};
	const handleSectorOkClick = (event: any) => {
		localStorage.setItem('selectedSector', JSON.stringify(checkedList)); // Tableau drilldown filter changes
		if (checkedList.length > 0) {
			setCurrSectorFilter(checkedList);
		} else {
			setCurrSectorFilter([]);
		}
		console.log(checkedList)
		setCurrSector(checkedList);
		getServiceRequestSummary(filterMonth);
		handleSectorClose(event);
	};

	async function fetchSectorOptionsData() {
		const sectorRes = await getSector('1', '100', '');
		if (sectorRes.success) {
			const filteredSectorOptions = sectorRes.data.sector.filter((x: any) => x.name !== 'S&T').map((x: any) => x.name);
			filteredSectorOptions.push('Blank');
        	setSectorOptionsList(filteredSectorOptions);
		}
	}
	
	async function getServiceRequestSummary(selectedDate?: string) {
		setLoading(true);
		const formattedDate = convertShortDateToActualDateFormat(selectedDate);
		fetchServiceRequestSummary('MTTR', selectedDate, checkedList, formattedDate).then(res => {
			SetMTTRData(res?.data?.result);
			const mttrFiltered: any = {edw: null, azure: null, databricks: null, presto: null};
			res?.data?.result.filter((mttr: any) => {
				if (mttr.report_dimension == 'EDW') {
					mttrFiltered['edw'] = mttr.count;
				} else if (mttr.report_dimension == 'Azure') {
					mttrFiltered['azure'] = mttr.count;
				} else if (mttr.report_dimension == 'Databricks') {
					mttrFiltered['databricks'] = mttr.count;
				} else if (mttr.report_dimension == 'Presto') {
					mttrFiltered['presto'] = mttr.count;
				}
			})
			SetMTTRDataObject(mttrFiltered);
			setLoading(false);
		}).catch(error => { 
			console.error(error);
		})
		fetchServiceRequestSummary('MTBF', selectedDate, checkedList, formattedDate).then(res => {
			SetMTBFData(res?.data?.result);
			const mtbfFiltered: any = {edw: null, azure: null, databricks: null, presto: null};
			res?.data?.result.filter((mtbf: any) => {
				if (mtbf.report_dimension == 'EDW') {
					mtbfFiltered['edw'] = mtbf.count;
				} else if (mtbf.report_dimension == 'Azure') {
					mtbfFiltered['azure'] = mtbf.count;
				} else if (mtbf.report_dimension == 'Databricks') {
					mtbfFiltered['databricks'] = mtbf.count;
				} else if (mtbf.report_dimension == 'Presto') {
					mtbfFiltered['presto'] = mtbf.count;
				}
			})
			SetMTBFDataObject(mtbfFiltered);
		}).catch(error => { 
			console.error(error);
		})
		fetchServiceRequestSummary('incidents', selectedDate, checkedList, formattedDate).then(res => {
			SetIncidentsData(res?.data?.result);
			const incidentFiltered: any = {edw: null, azure: null, databricks: null, presto: null};
			res?.data?.result.filter((incident: any) => {
				if (incident.report_dimension == 'EDW') {
					incidentFiltered['edw'] = incident.count;
				} else if (incident.report_dimension == 'Azure') {
					incidentFiltered['azure'] = incident.count;
				} else if (incident.report_dimension == 'Databricks') {
					incidentFiltered['databricks'] = incident.count;
				} else if (incident.report_dimension == 'Presto') {
					incidentFiltered['presto'] = incident.count;
				}
			})
			SetIncidentsDataObject(incidentFiltered);
		}).catch(error => { 
			console.error(error);
		})
		fetchServiceRequestSummary('serviceRequest', selectedDate, checkedList, formattedDate).then(res => {
			const requestFiltered: any = {edw: null, azure: null, databricks: null, presto: null};
			res?.data?.result.filter((request: any) => {
				if (request.REQUEST_TITLE.toLowerCase().includes('edw')) {
					requestFiltered['edw'] = request.count;
				} else if (request.REQUEST_TITLE.toLowerCase().includes('azure')) {
					requestFiltered['azure'] = request.count;
				} else if (request.REQUEST_TITLE.includes('databricks')) {
					requestFiltered['databricks'] = request.count;
				} else if (request.REQUEST_TITLE.toLowerCase().includes('presto')) {
					requestFiltered['presto'] = request.count;
				}
			})
			SetRequestsData(requestFiltered);
		}).catch(error => { 
			console.error(error);
		}) 
		fetchServiceRequestSummary('outage', selectedDate, checkedList).then(res => {
			const outageFiltered: any = {edw: null, azure: null, databricks: null, presto: null};
			res?.data?.result.filter((request: any) => {
				if (request.GRP_NM.toLowerCase().includes('edw')) {
					outageFiltered['edw'] += request.count;
				} else if (request.TTL_TXT.toLowerCase().includes('azure')) {
					outageFiltered['azure'] += request.count;
				} else if (request.TTL_TXT.toLowerCase().includes('databrick')) {
					outageFiltered['databricks'] += request.count;
				} else if (request.TTL_TXT.toLowerCase().includes('presto')) {
					outageFiltered['presto'] += request.count;
				}
			})
			SetOutagesData(outageFiltered);
		}).catch(error => { 
			console.error(error);
		})
	}
	
	useEffect(() => {
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/dataestate/service-request') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				document.body.classList.add('custom-class');
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('datefilter-align-sector');
				document.body.classList.add('sector-label');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				document.body.classList.add('dashboard-panel-dataestate');
				document.body.classList.add('datefilter-align-sector');
			}
		}
		if (userSector === 'all') fetchSectorOptionsData();
		getServiceRequestSummary(filterMonth);
	}, []);

	const [defaultColumnNames, setDynamicValue] = useState(['']);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [isTableVisible, setIsTableVisible] = useState(false);
	const [tableDataList, setTableData] = useState<Row[]>([]);
	const [tableHeading, setTableHeading] = useState('');
	const [typeName, setTypeName] = useState('');
	
	interface MTBF{
		incidentID: string;
		incidentTitle: string;
		priority: string;
		status: string;
		MTBF: string;
	}
	interface MTTR{
		incidentID: string;
		incidentTitle: string;
		priority: string;
		status: string;
		MTTR: string;
	}
	interface Incident{
		incidentID: string;
		priority: string;
		incidentTitle: string;
        groupName: string;
		serviceName:string;
		incidentSector:string;
		openedBy: string;
		resolvedBy: string;
		updatedBy: string;
		closedBy: string;
		assignedTo:string;
		status: string;
		MTTR: string;
	}
	interface Request{
		RequestID: string;
		requestTitle: string;
		requestAge: string;
        groupName: string;
		serviceName:string;
		incidentSector:string;
		openedBy: string;
		updatedBy: string;
		createdBy: string;
		closedBy: string;
		openDate: string;
		closedDate: string;
		dueDate: string;
		status: string;
	}
	interface Outage{
		incidentID: string;
        groupName: string;
		criticality: string;
		outagedurationInDays: string;
		outageDurationInHours: string;
		outageStartDatetime: string;
		outageEndDatetime: string;
	}
	interface Row{};
	function getColumns(): Array<Column<Row>> {
		if(typeName === 'MTBF'){
			return [
				{
					key: 'incidentID',
					name: 'Incident ID',
					minWidth: 180
				},
				{
					key: 'priority',
					name: 'Priority',
					minWidth: 170
				},
				{
					key: 'incidentTitle',
					name: 'Incident Title',
					minWidth: 170
				},
				{
					key: 'status',
					name: 'Status',
					minWidth: 170
				},
				{
					key: 'MTBF',
					name: 'MTBF',
					minWidth: 170
				},
			];
		}
		else if(typeName === 'MTTR'){
			return [
				{
					key: 'incidentID',
					name: 'Incident ID',
					minWidth: 180
				},
				{
					key: 'priority',
					name: 'Priority',
					minWidth: 170
				},
				{
					key: 'incidentTitle',
					name: 'Incident Title',
					minWidth: 170
				},
				{
					key: 'status',
					name: 'Status',
					// formatter(props:any) {
					// 	return <div>{props.row.status}</div>
					// },
					minWidth: 170
				},
				{
					key: 'MTTR',
					name: 'MTTR',
					minWidth: 170
				},
			];
		}
		else if(typeName === 'Incident')
		{
			return [
				{
					key: 'incidentID',
					name: 'Incident ID',
					minWidth: 180
				},
				{
					key: 'priority',
					name: 'Priority',
					minWidth: 170
				},
				{
					key: 'incidentTitle',
					name: 'Incident Title',
					minWidth: 170
				},
				{
					key: 'incidentAge',
					name: 'Incident Age',
					minWidth: 170
				},
				{
					key: 'groupName',
					name: 'Group name',
					minWidth: 170
				},
				{
					key: 'serviceName',
					name: 'Service name',
					minWidth: 170
				},
				{
					key: 'incidentSector',
					name: 'Incident sector',
					minWidth: 170
				},
				{
					key: 'openedBy',
					name: 'Opened by',
					minWidth: 170
				},
				{
					key: 'resolvedBy',
					name: 'Resolved by',
					minWidth: 170
				},
				{
					key: 'updatedBy',
					name: 'Updated by',
					minWidth: 170
				},
				{
					key: 'closedBy',
					name: 'Closed by',
					minWidth: 170
				},
				{
					key: 'assignedTo',
					name: 'Assigned to',
					minWidth: 170
				},
				{
					key: 'status',
					name: 'Status',
					minWidth: 170
				},
				{
					key: 'MTTR',
					name: 'MTTR',
					minWidth: 170
				},
			];
		}
		else if(typeName === 'Request'){
			return [
				{
					key: 'requestID',
					name: 'Request ID',
					minWidth: 180
				},
				{
					key: 'requestTitle',
					name: 'Request Title',
					minWidth: 170
				},
				{
					key: 'requestAge',
					name: 'Request Age',
					minWidth: 170
				},
				{
					key: 'groupName',
					name: 'Group name',
					minWidth: 170
				},
				{
					key: 'serviceName',
					name: 'Service name',
					minWidth: 170
				},
				{
					key: 'assignedTo',
					name: 'Assigned To',
					minWidth: 170
				},
				{
					key: 'openedBy',
					name: 'Opened by',
					minWidth: 170
				},
				{
					key: 'updatedBy',
					name: 'Updated by',
					minWidth: 170
				},
				{
					key: 'createdBy',
					name: 'Created by',
					minWidth: 170
				},
				
				{
					key: 'closedBy',
					name: 'Closed by',
					minWidth: 170
				},
				{
					key: 'openDate',
					name: 'Open Date',
					minWidth: 170
				},
				{
					key: 'closedDate',
					name: '	Closed Date',
					minWidth: 170
				},
				{
					key: 'dueDate',
					name: '	Due Date',
					minWidth: 170
				},
				{
					key: 'status',
					name: 'Status',
					minWidth: 170
				},
			];
		}
		else if(typeName === 'Outage'){
			return [
				{
					key: 'incidentID',
					name: 'Incident ID',
					minWidth: 180
				},
				{
					key: 'groupName',
					name: 'Group name',
					minWidth: 170
				},
				{
					key: 'criticality',
					name: 'Criticality',
					minWidth: 170
				},
				{
					key: 'outagedurationInDays',
					name: 'Outage duration in Days',
					minWidth: 170
				},
				{
					key: 'outageDurationInHours',
					name: 'Outage duration in Hours',
					minWidth: 170
				},
				{
					key: 'outageStartDatetime',
					name: 'Outage Start Datetime',
					minWidth: 170
				},
				{
					key: 'outageEndDatetime',
					name: 'Outage End Datetime',
					minWidth: 170
				},
			];
		}
		else{
			return[];
		}
	}
	const MTBFColumns =['Incident ID','Priority','Incident Title','Status','MTBF']
	const MTTRColumns =['Incident ID','Priority','Incident Title','Status','MTTR']
	const MTBFIncidentColumns =['Incident ID', 'Priority','Incident Title','Group name','Service name','Incident sector','Opened by','Resolved by','Updated by','Closed by','Assigned to','Status','MTTR']
	const RequestColumns =['Request ID', 'Request Title','Request Age','Group name','Service name','Assigned To','Opened by','Closed by','Open Date','Closed Date','Due Date','Status']
	const OutageColumns =['Incident ID', 'Group name','Criticality','Outage duration in Days','Outage duration in Hours','Outage Start Datetime','Outage End Datetime']
	async function sectorListingFunc(pageNumber: any, size: any, sortingInfo: any) {
		const res = await getSectorSql(pageNumber.toString(), size.toString(), sortingInfo);
		if (res.success) {
			setPaginationData(res.data.page);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
		}
	}
	const handleSorting = (sortingInfo: any) => {
		sectorListingFunc(page, pageSize, sortingInfo);
	};
	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		sectorListingFunc(value, pageSize, sortingInfo);
	};
	const handlepageSizeChange = (event: any) => {
		sectorListingFunc(page, event.target.value, sortingInfo);
	};
	async function programListingFunc(pageNumber: any, size: any, sortingInfo: any,diamension:string,month:string) {
		const kpiName:string = "serviceRequest";
		const sector:any =[];
		const res = await getServiceReq(kpiName,diamension,sector,month,pageNumber.toString(), size.toString());
		if (res.success) {
			setTableData(res.data.summary);
			setPaginationData(res.data.page);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
		}
		console.log(defaultColumnNames,"default final")
	}
	const handleClick = (typeName:any) => {
		setDynamicValue(['']);
		setTableData([]);
		setIsTableVisible(true);
		let diamension='';
		if(typeName === 'EDWMTBF'){
			setDynamicValue(MTBFColumns);
			setTableHeading("Legacy EDW MTBF");
			type Row = keyof MTBF;
			setTypeName('MTBF')
			diamension = "legacy_MTBF"
		}
		if(typeName === 'AzureMTBF'){
			setDynamicValue(MTBFColumns);
			setTableHeading("Azure MTBF");
			type Row = keyof MTBF;
			setTypeName('MTBF')
			diamension = "azure_MTBF"
		}
		if(typeName === 'DatabricksMTBF'){
			setDynamicValue(MTBFColumns);
			setTableHeading("Databricks MTBF");
			type Row = keyof MTBF;
			setTypeName('MTBF')
			diamension = "databricks_MTBF"
		}
		if(typeName === 'PrestoMTBF'){
			setDynamicValue(MTBFColumns);
			setTableHeading("Presto MTBF");
			type Row = keyof MTBF;
			setTypeName('MTBF')
			diamension = "presto_MTBF"
		}
		if(typeName === 'EDWMTTR'){
			setDynamicValue(MTTRColumns);
			setTableHeading("Legacy EDW MTTR");
			type Row = keyof MTTR;
			setTypeName('MTTR')
			diamension = "legacy_MTTR"
		}
		if(typeName === 'AzureMTTR'){
			setDynamicValue(MTTRColumns);
			setTableHeading("Azure MTTR");
			type Row = keyof MTTR;
			setTypeName('MTTR')
			diamension = "azure_MTTR"
		}
		if(typeName === 'DatabricksMTTR'){
			setDynamicValue(MTTRColumns);
			setTableHeading("Databricks MTTR");
			type Row = keyof MTTR;
			setTypeName('MTTR')
			diamension = "databricks_MTTR"	
		}
		if(typeName === 'PrestoMTTR'){
			setDynamicValue(MTTRColumns);
			setTableHeading("Presto MTTR");
			type Row = keyof MTTR;
			setTypeName('MTTR')
			diamension = "presto_MTTR"
		}
		if(typeName === 'EDWIncident'){
			setDynamicValue(MTBFIncidentColumns);
			setTableHeading("Legacy EDW Incidents");
			type Row = keyof Incident;
			setTypeName('Incident')
			diamension = "legacy_incidents"
		}
		if(typeName === 'AzureIncident'){
			setDynamicValue(MTBFIncidentColumns);
			setTableHeading("Azure Incidents");
			type Row = keyof Incident;
			setTypeName('Incident')
			diamension = "azure_incidents"	
		}
		if(typeName === 'DatabricksIncident'){
			setDynamicValue(MTBFIncidentColumns);
			setTableHeading("Databricks Incidents");
			type Row = keyof Incident;
			setTypeName('Incident')
			diamension = "databricks_incidents"
		}
		if(typeName === 'PrestoIncident'){
			setDynamicValue(MTBFIncidentColumns);
			setTableHeading("Presto Incidents");
			type Row = keyof Incident;
			setTypeName('Incident')
			diamension = "presto_incidents";
		}if(typeName === 'LegacyEDWRequests'){
			setDynamicValue(RequestColumns);
			setTableHeading("Legacy EDW Requests");
			type Row = keyof Request;
			setTypeName('Request')
			diamension = "legacy_request";
		}if(typeName === 'azureRequests'){
			setDynamicValue(RequestColumns);
			setTableHeading("Azure Requests");
			type Row = keyof Request;
			setTypeName('Request');
			diamension = "azure_request";
		}
		if(typeName === 'databricksRequests'){
			setDynamicValue(RequestColumns);
			setTableHeading("Databricks Requests");
			type Row = keyof Request;
			setTypeName('Request');
			diamension = "databricks_request";
		}
		if(typeName === 'prestoRequests'){
			setDynamicValue(RequestColumns);
			setTableHeading("Presto Requests");
			type Row = keyof Request;
			setTypeName('Request');
			diamension = "presto_request";
		}if(typeName === 'legacyEDWOutage'){
			setDynamicValue(OutageColumns);
			setTableHeading("Legacy EDW Outage");
			type Row = keyof Outage;
			setTypeName('Outage')
			diamension = "legacy_outage";
		}if(typeName === 'azureOutage'){
			setDynamicValue(OutageColumns);
			setTableHeading("Azure Outage");
			type Row = keyof Outage;
			setTypeName('Outage');
			diamension = "azure_outage";
		}
		if(typeName === 'databricksOutage'){
			setDynamicValue(OutageColumns);
			setTableHeading("Databricks Outage");
			type Row = keyof Outage;
			setTypeName('Outage');
			diamension = "databricks_outage";
		}
		if(typeName === 'prestoOutage'){
			setDynamicValue(OutageColumns);
			setTableHeading("Presto Outage");
			type Row = keyof Outage;
			setTypeName('Outage');
			diamension = "presto_outage";
		}
		programListingFunc(page,pageSize,sortingInfo,diamension,filterMonth)
	}
	return (
		<div className="dataestate-dashboard-wrap service-request-wrap">
			{ loading && <Loader /> }
			{/* <div className="service-date-filter date-picker-summary"> */}
			<div className="service-date-filter date-picker-summary data-estate-right">
				{/* Code for Date filter */}
				<Stack>
					<div className="date-select">
						<FormControl fullWidth>
							<CalendarIcon className="date-icon" />
							<Select
								name="date-select"
								labelId="demo-simple-select-label"
								id="single-date-select"
								value={filterMonth}
								label="Date"
								onChange={(e) => {
									handleDateFilter('dateFilter', e);
								}}
								classes={{ select: classes.select }}
								IconComponent={() => null} 
								open={isDateOpen} 
								onClick={(e) =>handleCustomOnclick(e)}
								onOpen={() => setIsDateOpen(true)}
								onClose={() => setIsDateOpen(false)} 
							>
								<MenuItem value={"0M"}>This Month</MenuItem>
								<MenuItem value={"1M"}>Last Month</MenuItem>
								<MenuItem value={"3M"}>Last 3 Months</MenuItem>
								<MenuItem value={"6M"}>Last 6 Months</MenuItem>
								<MenuItem value={"9M"}>Last 9 Months</MenuItem>
								<MenuItem value={"0Y"}>This Year</MenuItem>
								<MenuItem value={"1Y"}>Last Year</MenuItem>
								<MenuItem value={"2Y"}>Last 2 Years</MenuItem>
								<MenuItem value={"2023-01"}>Custom Month</MenuItem>
							</Select>
						</FormControl>
						{isCustomMonth.current && 
							<Popover
								open={dateFilterMenu !== null}
								onClose={handleDateFilterMenuClose}
								className="custom-date-filter-menu"
								anchorReference="anchorPosition"
								anchorPosition={dateFilterMenu !== null ? { top: dateFilterMenu.mouseY, left: dateFilterMenu.mouseX } : undefined}
							><div className="custom-class-date date-custom">
							<div className='custom-dis'>
								<InputLabel>{customYear}</InputLabel>
								<div style={{marginLeft:'60px'}}>
									<Downarrow className='up-arrow' onClick={handleYearAdd}/>
									<Downarrow className='year-arrow' onClick={handleYearMinus}/>
								</div>
							</div>
							<div className='custom-dis'>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("01")}>January</Button>
								<Button  variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("02")}>February</Button>
							</div>
							<div className='custom-dis'>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("03")}>March</Button>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("04")}>April</Button>
							</div>
							<div className='custom-dis'>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("05")}>May</Button>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("06")}>Jun</Button>
							</div>
							<div className='custom-dis'>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("07")}>July</Button>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("08")}>August</Button>
							</div>
							<div className='custom-dis'>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("09")}>September</Button>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("10")}>October</Button>
							</div>
							<div className='custom-dis'>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("11")}>November</Button>
								<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick("12")}>December</Button>
							</div>
						</div></Popover>}
					</div>
				</Stack>
			</div>
			{/* <div className="service-sector-filter"> */}
				{/* Code for sector filter */}
				{/* <Stack className="sector-filter-dataestate">
					{userSector === 'all' ? (
						<><span className='sector-cta' onClick={sectorFilterClick}><span className='sector-label'>Sectors</span> <FilterIcon /></span></>
					) : (
						<div className="separeat_sector">Sector: {getDecodedToken()?.sector?.name}</div>
					)}
					<Popover
						open={filterMenu !== null}
						onClose={handleSectorClose}
						className="filterContainer"
						anchorReference="anchorPosition"
						anchorPosition={filterMenu !== null ? { top: filterMenu.mouseY, left: filterMenu.mouseX } : undefined}
					>
						<div className="text-filter-row">
							<div className="selectAll-sec">
								<MenuItem className="selectAll">
									<FormControlLabel
										className={classes.checkbox}
										label="All"
										control={
											<Checkbox
												color="success"
												value="all"
												checked={itemsChecked}
												onChange={(e) => {
													selectAllSectorCheck(e);
												}}
											/>
										}
									/>
								</MenuItem>
								<div className="checkbox-sec">
									{sectorOptionsList &&
										sectorOptionsList.map((filter: any) => (
											<MenuItem key={filter}>
												<FormControlLabel
													className={classes.checkbox}
													label={filter === true ? 'Yes' : filter === false ? 'No' : filter}
													control={
														<Checkbox
															color="success"
															value={filter}
															checked={
																(filter === true && checkedList.includes('true')) ||
																(filter === false && checkedList.includes('false')) ||
																checkedList.includes(filter)
															}
															onChange={(e) => {
																handleSectorChange(e);
															}}
														/>
													}
												/>
											</MenuItem>
										))}
								</div>
							</div>
							<div className="buttonSec">
								<Button variant="contained" className={classes.cancelBtn} onClick={handleSectorClose}>
									cancel
								</Button>
								<Button variant="contained" className={classes.okBtn} onClick={handleSectorOkClick}>
									ok
								</Button>
							</div>
						</div>
					</Popover>
				</Stack>
			</div> */}

			<Grid container spacing={0} className="headerIPB mb-10">
				<Grid item xs={12} className="">
					<div className="panel-legend">
						<h2 className="panel-legend--title">Mean Time To Resolve (MTTR)</h2>
						<Grid container spacing={0} className="inner-grid-parent">
							{/* Commented this to avoid showing empty widget */}
							{/* { MTTRData && MTTRData.map((mttr: any, index: number) => (
								<Grid item xs={3} onClick={() =>{ !isEnvTypePROD ? handleClick(mttr.report_dimension+"MTTR") : '' } }>
									<div className={index == 3 ? 'panel-box mb-10' : 'panel-box mr-10 mb-10'}>
										<h2 className="panel-box--title">{
											mttr.report_dimension == 'EDW' ? 'Legacy EDW MTTR' :
											mttr.report_dimension == 'Azure' ? 'Azure MTTR' :
											mttr.report_dimension == 'Databricks' ? 'Databricks MTTR' :
											mttr.report_dimension == 'Presto' ? 'Presto MTTR' : '' }
											</h2>
									<div className="panel-box--count" title={mttr.count}>{mttr.count.toFixed(2)} <br /><span className="conversion">hrs</span></div>
									</div>
								</Grid>
							))} */}
							
							<Grid item xs={3} onClick={() =>{ !isEnvTypePROD ? handleClick("EDWMTTR") : '' } }>
								<div className='panel-box mr-10  mb-10'>
									<h2 className="panel-box--title">Legacy EDW MTTR</h2>
									<div className="panel-box--count" title={MTTRDataObject.edw}>
										{MTTRDataObject.edw ? <>{formatNumberWithCommas(MTTRDataObject.edw.toFixed(2),true)} <span className="conversion">hrs</span></> : 
										<span className="noDataTextColor">No Data</span>}
									</div>
								</div>
							</Grid>
							<Grid item xs={3} onClick={() =>{ !isEnvTypePROD ? handleClick("AzureMTTR") : '' } }>
								<div className='panel-box mr-10  mb-10'>
									<h2 className="panel-box--title">Azure MTTR</h2>
									<div className="panel-box--count" title={MTTRDataObject.azure}>
										{MTTRDataObject.azure ? <>{formatNumberWithCommas(MTTRDataObject.azure.toFixed(2),true)} <span className="conversion">hrs</span></> : 
										<span className="noDataTextColor">No Data</span>}
									</div>
								</div>
							</Grid>
							<Grid item xs={3} onClick={() =>{ !isEnvTypePROD ? handleClick("DatabricksMTTR") : '' } }>
								<div className='panel-box mr-10  mb-10'>
									<h2 className="panel-box--title">Databricks MTTR</h2>
									<div className="panel-box--count" title={MTTRDataObject.databricks}>
										{MTTRDataObject.databricks ? <>{formatNumberWithCommas(MTTRDataObject.databricks.toFixed(2),true)} <span className="conversion">hrs</span></> : 
										<span className="noDataTextColor">No Data</span>}
									</div>
								</div>
							</Grid>
							<Grid item xs={3} onClick={() =>{ !isEnvTypePROD ? handleClick("PrestoMTTR") : '' } }>
								<div className='panel-box mr-10 mb-10'>
									<h2 className="panel-box--title">Presto MTTR</h2>
									<div className="panel-box--count" title={MTTRDataObject.presto}>
										{MTTRDataObject.presto ? <>{formatNumberWithCommas(MTTRDataObject.presto.toFixed(2),true)} <span className="conversion">hrs</span></> : 
										<span className="noDataTextColor">No Data</span>}
									</div>
								</div>
							</Grid>
						</Grid>
					</div>
				</Grid>
			</Grid>

			<Grid container spacing={0} className="headerIPB mb-10">
				<Grid item xs={12} className="">
				<div className="panel-legend">
						<h2 className="panel-legend--title">Mean Time Between Failures (MTBF)</h2>
						<Grid container spacing={0} className="inner-grid-parent">
							{/* { MTBFData && MTBFData.map((mtbf: any, index: number) => (
								<Grid item xs={3} onClick={() =>{ !isEnvTypePROD ? handleClick(mtbf.report_dimension+"MTBF") : '' } }>
									<div className={index == 3 ? 'panel-box mb-10' : 'panel-box mr-10 mb-10'}>
										<h2 className="panel-box--title">{
											mtbf.report_dimension == 'EDW' ? 'Legacy EDW MTBF' :
											mtbf.report_dimension == 'Azure' ? 'Azure MTBF' :
											mtbf.report_dimension == 'Databricks' ? 'Databricks MTBF' :
											mtbf.report_dimension == 'Presto' ? 'Presto MTBF' : '' }
											</h2>
									<div className="panel-box--count" title={mtbf.count}>{mtbf.count.toFixed(2)} <br /><span className="conversion">hrs</span></div>
									<div className='data-estate-flex-center de-service'>
										<img className='data-esate-image de-exe-icon' src={NoPrgramFound} alt="" />
										<p className='de-no-program-txt de-exe-txt de-summary-fs'>In&nbsp;Progress/Validation</p>
									</div>
									</div>
								</Grid>
							))}  */}
							
							<Grid item xs={3} onClick={() =>{ !isEnvTypePROD ? handleClick("EDWMTBF") : '' } }>
								<div className='panel-box mr-10'>
									<h2 className="panel-box--title">Legacy EDW MTBF</h2>
									<div className="panel-box--count" title={MTBFDataObject.edw}>
										{MTBFDataObject.edw ? <>{formatNumberWithCommas(MTBFDataObject.edw.toFixed(2),true)} <span className="conversion">hrs</span></> : 
										<span className="noDataTextColor">No Data</span>}
									</div>
								</div>
							</Grid>
							<Grid item xs={3} onClick={() =>{ !isEnvTypePROD ? handleClick("AzureMTBF") : '' } }>
								<div className='panel-box mr-10'>
									<h2 className="panel-box--title">Azure MTBF</h2>
									<div className="panel-box--count" title={MTBFDataObject.azure}>
										{MTBFDataObject.azure ? <>{formatNumberWithCommas(MTBFDataObject.azure.toFixed(2),true)} <span className="conversion">hrs</span></> : 
										<span className="noDataTextColor">No Data</span>}
									</div>
								</div>
							</Grid>
							<Grid item xs={3} onClick={() =>{ !isEnvTypePROD ? handleClick("DatabricksMTBF") : '' } }>
								<div className='panel-box mr-10'>
									<h2 className="panel-box--title">Databricks MTBF</h2>
									<div className="panel-box--count" title={MTBFDataObject.databricks}>
										{MTBFDataObject.databricks ? <>{formatNumberWithCommas(MTBFDataObject.databricks.toFixed(2),true)} <span className="conversion">hrs</span></> : 
										<span className="noDataTextColor">No Data</span>}
									</div>
								</div>
							</Grid>
							<Grid item xs={3} onClick={() =>{ !isEnvTypePROD ? handleClick("PrestoMTBF") : '' } }>
								<div className='panel-box mr-10 mb-10'>
									<h2 className="panel-box--title">Presto MTBF</h2>
									<div className="panel-box--count" title={MTBFDataObject.presto}>
										{MTBFDataObject.presto ? <>{formatNumberWithCommas(MTBFDataObject.presto.toFixed(2),true)} <span className="conversion">hrs</span></> : 
										<span className="noDataTextColor">No Data</span>}
									</div>
								</div>
							</Grid>
						</Grid>
					</div>
				</Grid>
			</Grid>

			<Grid container spacing={0} className="headerIPB mb-10">
				<Grid item xs={12} className="">
					<div className="panel-legend">
						<h2 className="panel-legend--title">Major INC Details</h2>
						<Grid container spacing={0} className="inner-grid-parent">
						{/* { IncidentsData && IncidentsData.map((incident: any, index: number) => (
								<Grid item xs={3}  onClick={() =>{ !isEnvTypePROD ? handleClick(incident.report_dimension+"Incident") : '' } }>
									<div className={index == 3 ? 'panel-box mb-10' : 'panel-box mr-10 mb-10'}>
										<h2 className="panel-box--title">{
											incident.report_dimension == 'EDW' ? 'Legacy EDW Incidents' :
											incident.report_dimension == 'Azure' ? 'Azure Incidents' :
											incident.report_dimension == 'Databricks' ? 'Databricks Incidents' :
											incident.report_dimension == 'Presto' ? 'Presto Incidents' : '' }
											</h2>
									<div className="panel-box--count" title={incident.count}>{incident.count}</div>
									</div>
								</Grid>
							))} */}
							
							<Grid item xs={3} onClick={() =>{ !isEnvTypePROD ? handleClick("EDWIncident") : '' } }>
								<div className='panel-box mr-10 mb-10'>
									<h2 className="panel-box--title">Legacy EDW Incidents</h2>
									<div className="panel-box--count" title={IncidentsDataObject.edw}>
										{IncidentsDataObject.edw ? formatNumberWithCommas(IncidentsDataObject.edw): <span className="noDataTextColor">No Data</span>}
									</div>
								</div>
							</Grid>
							<Grid item xs={3} onClick={() =>{ !isEnvTypePROD ? handleClick("AzureIncident") : '' } }>
								<div className='panel-box mr-10 mb-10'>
									<h2 className="panel-box--title">Azure Incidents</h2>
									<div className="panel-box--count" title={IncidentsDataObject.azure}>
										{IncidentsDataObject.azure ? formatNumberWithCommas(IncidentsDataObject.azure): <span className="noDataTextColor">No Data</span>}
									</div>
								</div>
							</Grid>
							<Grid item xs={3} onClick={() =>{ !isEnvTypePROD ? handleClick("DatabricksIncident") : <span className="noDataTextColor">No Data</span> } }>
								<div className='panel-box mr-10 mb-10'>
									<h2 className="panel-box--title">Databricks Incidents</h2>
									<div className="panel-box--count" title={IncidentsDataObject.databricks}>
										{IncidentsDataObject.databricks ? formatNumberWithCommas(IncidentsDataObject.databricks): <span className="noDataTextColor">No Data</span>}
									</div>
								</div>
							</Grid>
							<Grid item xs={3} onClick={() =>{ !isEnvTypePROD ? handleClick("PrestoIncident") : '' } }>
								<div className='panel-box mb-10'>
									<h2 className="panel-box--title">Presto Incidents</h2>
									<div className="panel-box--count" title={IncidentsDataObject.presto}>
										{IncidentsDataObject.presto ? formatNumberWithCommas(IncidentsDataObject.presto): <span className="noDataTextColor">No Data</span>}
									</div>
								</div>
							</Grid>
						</Grid>
						{/* Commented service request widgets as per 9/25 release mail */}
						{/* <Grid container spacing={0} className="inner-grid-parent">
							<Grid item xs={3}>
								<div className="panel-box mr-10 mh-86" onClick={() =>{ !isEnvTypePROD ? handleClick("LegacyEDWRequests") : '' } }>
									<h2 className="panel-box--title">Legacy EDW Requests</h2>
									<div className="panel-box--count" title={RequestsData.edw}>
										{RequestsData.edw ? <>{formatNumberWithCommas(RequestsData.edw)} </>: '-'}
									</div>
								</div>
							</Grid>

							<Grid item xs={3} className="">
								<div className="panel-box mr-10 mh-86" onClick={() =>{ !isEnvTypePROD ? handleClick("azureRequests") : '' } }>
									<h2 className="panel-box--title">Azure Requests</h2>
									<div className="panel-box--count" title={RequestsData.azure}>
										{RequestsData.azure ? <>{formatNumberWithCommas(RequestsData.azure)} </> : '-'}
									</div>
								</div>
							</Grid>

							<Grid item xs={3} className="">
								<div className="panel-box mr-10 mh-86" onClick={() =>{ !isEnvTypePROD ? handleClick("databricksRequests") : '' } }>
									<h2 className="panel-box--title">Databricks Requests</h2>
									<div className="panel-box--count" title={RequestsData.databricks}>
										{RequestsData.databricks ? <>{formatNumberWithCommas(RequestsData.databricks)} </> : '-'}
									</div>
								</div>
							</Grid>

							<Grid item xs={3} className="" onClick={() =>{ !isEnvTypePROD ? handleClick("prestoRequests") : '' } }>
								<div className="panel-box mh-86">
									<h2 className="panel-box--title">Presto Requests</h2>
									<div className="panel-box--count" title={RequestsData.presto}>
										{RequestsData.presto ? <>{formatNumberWithCommas(RequestsData.presto)} </> : '-'}
									</div>
								</div>
							</Grid>
						</Grid> */}
					</div>
				</Grid>
			</Grid>
			

			<Grid container spacing={0} className="headerIPB mb-10">
				<Grid item xs={12} className="">
					<div className="panel-legend mb-20">
						<h2 className="panel-legend--title">Outages</h2>
						<Grid container spacing={0} className="inner-grid-parent">
							<Grid item xs={3}>
								<div className="panel-box mr-10" onClick={() => { !isEnvTypePROD ? handleClick("legacyEDWOutage") : '' } }>
									<h2 className="panel-box--title">Legacy EDW Outages</h2>
									<div className="panel-box--count" title={OutagesData.edw}>
										{OutagesData.edw ? <>{formatNumberWithCommas(OutagesData.edw)} </> : <span className="noDataTextColor">No Data</span>}
									</div>
								</div>
							</Grid>

							<Grid item xs={3} className="">
								<div className="panel-box mr-10" onClick={() => { !isEnvTypePROD ? handleClick("azureOutage") : '' } }>
									<h2 className="panel-box--title">Azure Outages</h2>
									<div className="panel-box--count" title={OutagesData.azure}>
										{OutagesData.azure ? <>{formatNumberWithCommas(OutagesData.azure)} </> : <span className="noDataTextColor">No Data</span>}
									</div>
								</div>
							</Grid>

							<Grid item xs={3} className="">
								<div className="panel-box mr-10" onClick={() => { !isEnvTypePROD ? handleClick("databricksOutage") : '' } }>
									<h2 className="panel-box--title">Databricks Outages</h2>
									<div className="panel-box--count" title={OutagesData.databricks}>
										{OutagesData.databricks ? <>{formatNumberWithCommas(OutagesData.databricks)} </> : <span className="noDataTextColor">No Data</span>}
									</div>
								</div>
							</Grid>

							<Grid item xs={3} className="">
								<div className="panel-box" onClick={() => { !isEnvTypePROD ? handleClick("prestoOutage") : '' } }>
									<h2 className="panel-box--title">Presto Outages</h2>
									<div className="panel-box--count" title={OutagesData.presto}>
										{OutagesData.presto ? <>{formatNumberWithCommas(OutagesData.presto)} </> : <span className="noDataTextColor">No Data</span>}
									</div>
								</div>
							</Grid>
						</Grid>
					</div>
				</Grid>
			</Grid>

			
			{/* <Grid container spacing={0} className="headerIPB mb-10 small-boxes"> */}
				{/* <Grid item xs={12} className="">
					<div className="panel-legend mr-10">
						<h2 className="panel-legend--title">Unplanned Downtime</h2>
						<Grid container spacing={0} className="inner-grid-parent">
							<Grid item xs={3}>
								<div className="panel-box mr-10 mb-10">
									<h2 className="panel-box--title">Legacy EDW</h2>
									<div className="panel-box--count">4 <span className="conversion">hrs</span></div>
								</div>
							</Grid>

							<Grid item xs={3} className="">
								<div className="panel-box mr-10 mb-10">
									<h2 className="panel-box--title">Azure</h2>
									<div className="panel-box--count">3 <span className="conversion">hrs</span></div>
								</div>
							</Grid>

							<Grid item xs={3} className="">
								<div className="panel-box mr-10 mb-10">
									<h2 className="panel-box--title">Databricks</h2>
									<div className="panel-box--count">7 <span className="conversion">hrs</span></div>
								</div>
							</Grid>

							<Grid item xs={3} className="">
								<div className="panel-box mb-10">
									<h2 className="panel-box--title">Presto</h2>
									<div className="panel-box--count">2 <span className="conversion">hrs</span></div>
								</div>
							</Grid>
						</Grid>
					</div>
				</Grid> */}

							{/* descoped Planned downtime */}
				{/* <Grid item xs={6} className="">
					<div className="panel-legend mr-10">
						<h2 className="panel-legend--title">Planned Downtime</h2>
						<Grid container spacing={0} className="inner-grid-parent">
							<Grid item xs={3}>
								<div className="panel-box mr-10 mb-10">
									<h2 className="panel-box--title">Legacy EDW Outages</h2>
									<div className="panel-box--count">4</div>
								</div>
							</Grid>

							<Grid item xs={3} className="">
								<div className="panel-box mr-10 mb-10">
									<h2 className="panel-box--title">Azure Outages</h2>
									<div className="panel-box--count">3</div>
								</div>
							</Grid>

							<Grid item xs={3} className="">
								<div className="panel-box mr-10 mb-10">
									<h2 className="panel-box--title">Databricks Outages</h2>
									<div className="panel-box--count">7</div>
								</div>
							</Grid>

							<Grid item xs={3} className="">
								<div className="panel-box mb-10">
									<h2 className="panel-box--title">Presto Outages</h2>
									<div className="panel-box--count">2</div>
								</div>
							</Grid>
						</Grid>
					</div>
				</Grid> */}
			{/* </Grid> */}
			{isTableVisible && (
			<>
			<h2 className="panel-legend--title mt-35 ">{tableHeading}</h2>
			<div className=" finop_tabledata detail_sum_tbl">
				<Grid>
					{tableDataList && tableDataList.length > 0 ? (
						<CustomizedDataGrid
							tableColumns={getColumns()}
							tableData={tableDataList}
							tableType="sector"
							defaultColumnNames={defaultColumnNames}
							onSorting={handleSorting}
							currSorting={sortingInfo}
							page={page}
							pageSize={pageSize}
							paginationData={paginationData}
							listLength={tableDataList.length}
							handlepageSizeChange={handlepageSizeChange}
							handlePageChange={handlePageChange}
							isDateRangeFilter={false}
							isDisabled={false}
						/>
					) : (
						<Loader />
					)}
				</Grid>
			</div>
			</>
	  		)}
		</div>
	);
}
