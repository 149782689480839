import moment from 'moment-timezone';

export async function exportToXlsx(fileName: string, tableData: any, tableColumns: any) {
	const [{ utils, writeFile }, { head, body }] = await Promise.all([
		import('xlsx'),
		getGridContent(tableData, tableColumns)
	]);
	const wb = utils.book_new();
	const ws = utils.aoa_to_sheet([...head, ...body]);
	const wscols = [];
	for (let i = 0; i < head[0].length; i++) {
		wscols.push({ wch: parseInt(head[0][i].length) + 10 });
	}
	ws['!cols'] = wscols;
	utils.book_append_sheet(wb, ws, 'Sheet 1');
	writeFile(wb, fileName);
}

async function getGridContent(tableData: any, tableColumns: any) {
	const extractedColumns = [tableColumns.map((x: any) => x.name)];
	const columnKeys = tableColumns.map((x: any) => x.key);
	const extractedRows = tableData.map((x: any) => {
		const row: any = [];
		columnKeys.forEach((key: any) => {
			switch (key) {
				case 'runStartTime':
				case 'runEndTime':
				case 'createdAt':
				case 'deletedAt':
				case 'lastSuccessRun':
					if (x[key]) row.push(moment(x[key]).format());
					else row.push('');
					break;
				case 'criticalFlag':
					row.push(x[key] ? 'Yes' : 'No');
					break;
				default:
					row.push(x[key]);
			}
		});
		return row;
	});

	return {
		head: extractedColumns,
		body: extractedRows
	};
}
