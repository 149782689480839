import PrivateRoute from 'utils/privateRoute';
import Capacity from 'components/PowerBIObservability/Capacity';
import Dataset from 'components/PowerBIObservability/Dataset';
import UsageCover from 'components/PowerBIObservability/Usage_Cover';
import DataGrowth from 'components/PowerBIObservability/DataGrowth';
import Infrastructure from 'components/PowerBIObservability/Infrastructure';
import CapacityObservability from 'components/PowerBIObservability/CapacityObservability';

const PowerBIObsRoutes = [
	{
		key: 'powerbi_caps_dash',
		route: '/powerBI/capacities/dashboard',
		component: (
			<PrivateRoute
				Component={CapacityObservability}
				restrictedRoles={[
					'Data Operations Admin',
					'Data Operations User',
					'D&AI Executive User',
					'Program Executive'
				]}
			/>
		)
	},
	{
		key: 'power_capa_dash',
		route: '/powerBI/capacity/dashboard',
		component: (
			<PrivateRoute
				Component={Capacity}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'power_data_dash',
		route: '/powerBI/dataset/dashboard',
		component: (
			<PrivateRoute
				Component={Dataset}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'power_usage_dash',
		route: '/powerBI/usage/dashboard',
		component: (
			<PrivateRoute
				Component={UsageCover}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'power_data_grow_dash',
		route: '/powerBI/datagrowth/dashboard',
		component: (
			<PrivateRoute
				Component={DataGrowth}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'power_infra_dash',
		route: '/powerBI/infrastructure/dashboard',
		component: (
			<PrivateRoute
				Component={Infrastructure}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'power_capa_obs_dash',
		route: '/powerBI/capacityObservability/dashboard',
		component: (
			<PrivateRoute
				Component={CapacityObservability}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	}
];

export default PowerBIObsRoutes;
