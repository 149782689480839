import React, { useState, useEffect, useRef } from 'react';
import { Button, Chip, Grid, Menu, TextField, FormControlLabel, Stack } from '@mui/material';
import './presto.css';
import { getDecodedToken, getPrestoSummaryQueryDetails, getSector } from '../../service/service';
import useStyles from '../jobs/jobs_styles';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import { ReactComponent as FilterIcon } from '../../assets/ml_summary_filter.svg';
import UnderDevelopment from '../../assets/under-construction.svg';
import './summary';
import AnalyticsVisualization from '../framework/analyticsVisualization';
import { dashboardUrl } from '../../service/config';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import SummaryDrilldown from './SummaryDrilldown';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ReactComponent as DatePickerIcon } from '../../assets/calendar-outline_white.svg';
import { Column } from 'react-data-grid';
import Loader from '../Loader/loader';
import { CustomizedDataGrid } from '../customized_data_grid';

const prestoSummary = () => {
	interface Row {
		userId: string;
		queryDurationInSec: string;
		query: string;
		isGreaterThan10Mins: string;
		queryStartTime: string;
		queryEndTime: string;
	}

	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'userId',
				name: 'Batch Id/User Id',
				width: 150
			},
			{
				key: 'queryDurationInSec',
				name: 'Query Duration',
				formatter(props) {
                    let seconds = Number(props.row.queryDurationInSec);
                    if (seconds && seconds >= 60) {
                        seconds = Number(seconds);
						var h = Math.floor(seconds / 3600);
						var m = Math.floor(seconds % 3600 / 60);

						var hDisplay = h > 0 ? h + (h == 1 ? " hr " : " hrs ") : "";
						var mDisplay = m > 0 ? m + (m == 1 ? " min" : " mins") : "";
						return hDisplay + mDisplay; 
                    } else if (seconds && seconds < 60) {
						return seconds + ' Secs'
					}
                    return '0 Sec';
                },
				width: 150
			},
			{
				key: 'query',
				name: 'Query Details',
				formatter(props) {
					return <div title={props.row.query} className='has-ellipsis'>{props.row.query ? props.row.query : '--'}</div>
				},
				width: 250,
				sortable: false
			},

			{
				key: 'isGreaterThan10Mins',
				name: 'Greater Than 10 Mins?',
				width: 200
			},
			{
				key: 'queryStartTime',
				name: 'Query Start Time',
				width: 200,
				formatter(props) {
                    const startTime = props.row.queryStartTime;
                    if (startTime !== 'undefined') {
                        return moment(startTime).utc().format('MM-DD-YY HH:mm');
                    }
                    return '';
                }
			},
			{
				key: 'queryEndTime',
				name: 'Query End Time',
				width: 200,
				formatter(props) {
                    const endTime = props.row.queryEndTime;
                    if (endTime !== 'undefined') {
                        return moment(endTime).utc().format('MM-DD-YY HH:mm');
                    }
                    return '';
                }
			}
		];
	}

	const defaultColumnNames = [
		'Batch Id/User Id',
		'Query Duration',
		'Query Details',
		'Greater Than 10 Mins?',
		'Query Start Time',
		'Query End Time'
	];

	const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
	console.log(userSector, 'userSector test');
	const classes = useStyles();
	const dataFetchedRef = useRef(false);
	const [sectorOptionsList, setSectorOptionsList] = useState<any[]>([]);
	const [filterMenu, setFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	const [itemsChecked, setItemsChecked] = useState(false);
	const [checkedList, setCheckedList] = useState<any[]>([]);
	const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);
	const [instance, setInstance] = useState<any>('all');

	//
	const [searchParams, setSearchParams] = useSearchParams();
	// const [drillDownStatus, setDrillDownStatus] = useState<string | null>(searchParams.get('drillDownStatus') || 'batchIdUsed');
	const hasDrillDown = searchParams.has('isDrillDowned');
	const minJobExecDate = '2023-03-01';
	const dateFrom: string = searchParams.get('fromDate')?.substring(0, 10) as string;
	const startDt = moment(dateFrom).startOf('day').subtract(1, 'hours').format('YYYY-MM-DD');
	const [fromDateTime, setFromDateTime] = useState<any>(
		searchParams.has('fromDate') ? startDt : moment().startOf('day').subtract(1, 'hours')
	);
	const [propsFromDateTime, setPropsFromDateTime] = useState<any>(
		searchParams.has('fromDate') ? startDt : moment().startOf('day').subtract(1, 'hours')
	);
	
	const [queryDetails, setQueryDetails] = useState<Row[]>([]);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [filterData, setFilterData] = useState<Row[]>([]);
	const [searchDetails, setFilterSearchInfo] = useState([]);
	const [loading, setLoading] = useState(false);
	const [filterInfo, setFilterInfo] = useState([]);
	const [error, setError] = useState<string | null>(null);

	// [Date Range Menu]
	const [dateRangeMenuOpen, setDateRangeMenuOpen] = React.useState<null | HTMLElement>(null);

	const handleDateClick = (event: React.MouseEvent<HTMLElement>) => {
		setDateRangeMenuOpen(event.currentTarget);
	};

	const handleDateRangeMenu = () => {
		setDateRangeMenuOpen(null);
	};

	const open = Boolean(dateRangeMenuOpen);

	const handleDateTimeChange = (field: any, dateTime: any) => {
		localStorage.setItem('prestoSelectedDateTime', dateTime); // Tableau drilldown filter changes
		if (field === 'fromDateTime') setFromDateTime(dateTime);
		// setDateSelected(true);
		// else setToDateTime(dateTime);
		// applyDateTimeRange(dateTime);
		if (hasDrillDown) {
			// Call function for drilldown case
			applyDateTimeRangeDrillDown(dateTime);
		} else {
			// Call function for non-drilldown case
			applyDateTimeRange(dateTime);
		}
	};

	const resetSearchParams = () => {
		if (searchParams.has('fromDate')) {
			searchParams.delete('status');
			searchParams.delete('scheduleDate');
			searchParams.delete('fromDate');
		}
		setSearchParams(searchParams);
	};
	const applyDateTimeRangeDrillDown = (dateTime: any) => {
		setPropsFromDateTime(dateTime);
		resetSearchParams();
		setDateRangeMenuOpen(null);
	};

	const applyDateTimeRange = (fromDateTime: any) => {
		setPropsFromDateTime(fromDateTime);
		resetSearchParams();
		setLoading(true);
		const promise1 = PrestoQueryDetailsListingFunc(
			page,
			pageSize,
			fromDateTime,
			sortingInfo
	
		)
		.catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		setDateRangeMenuOpen(null);
		Promise.all([promise1]).then((values) => {
			// setLoading(false);
		});
	};
	const filterClick = (event: any) => {
		event.preventDefault();
		setFilterMenu(
			filterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 20,
						mouseY: parseInt(event.clientY) - 6
				  }
				: null
		);
	};
	const handleClose = (event: any) => {
		setFilterMenu(null);
	};
	const selectAllCheck = (e: any) => {
		const { checked } = e.target;
		const collection: any[] = [];
		if (checked) setCheckedList(sectorOptionsList);
		else setCheckedList([]);
		setItemsChecked(checked);
	};
	const handleChange = (e: any) => {
		const { value, checked } = e.target;
		const newlyChecked = [];
		if (checked && e.target.value) {
			newlyChecked.push(e.target.value);
		}
		if (checked) {
			setCheckedList([...checkedList, ...newlyChecked]);
		} else {
			setCheckedList(checkedList.filter((item) => item !== value));
		}
	};

	const handleOkClick = (event: any) => {
		if (checkedList.length > 0) {
			setCurrSectorFilter(checkedList);
		} else {
			setCurrSectorFilter([]);
		}
	};
	async function fetchSectorOptionsData() {
		const sectorRes = await getSector('1', '100', '');
		if (sectorRes.success) {
			setSectorOptionsList(sectorRes.data.sector.map((x: any) => x.name));
		}
	}

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setLoading(true);
		const promise1 = PrestoQueryDetailsListingFunc(
			value,
			pageSize,
			fromDateTime,
			sortingInfo
	
		);
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};
	const handlepageSizeChange = (event: any) => {
		setLoading(true);
		const promise1 = PrestoQueryDetailsListingFunc(
			'1',
			event.target.value,
			fromDateTime,
			sortingInfo
		);
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};
	const handleSorting = (sortingInfo: any) => {
		// disable sorting for query column
		if (sortingInfo.sortBy == 'query') return false
		setLoading(true);
		const promise1 = PrestoQueryDetailsListingFunc(
			page,
			pageSize,
			fromDateTime,
			sortingInfo
		);
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};
	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

	const handleFilter = (filterInfo: any) => {
		resetSearchParams();
		setFilterInfo(filterInfo);
		setLoading(true);
		const promise1 = PrestoQueryDetailsListingFunc(
			1,
			pageSize,
			fromDateTime,
			sortingInfo
		);
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

	async function PrestoQueryDetailsListingFunc(
		pageNumber: any,
		size: any,
		fromDateTime:any,
		sortingInfo: any
	) {
				setLoading(true);
		try {
						const res = await getPrestoSummaryQueryDetails(
							pageNumber.toString(),
							size.toString(),
							fromDateTime,
							sortingInfo
			);
						if (res.success) {
							
				setQueryDetails(res.data.data);
				setPaginationData(res.data.page);
				setFilterData(res.data.filterOptions);
				// setFilterInfo(jobFilterInfo);
				setPage(pageNumber);
				setPageSize(size);
				setSortingInfo(sortingInfo);
				setError(null);
			} else {
				setError('Failed to fetch data'); // Set the error state if API call fails
			}
		} catch (error) {
			setError('Failed to fetch data'); // Set the error state if API call fails
		}
		setLoading(false);
	}

	useEffect(() => {
		// if (hasDrillDown) {
			// Tableau drilldown filter changes
			let selectedDateTime = localStorage.getItem('prestoSelectedDateTime')
			if (selectedDateTime) {
				setFromDateTime(selectedDateTime)
				setPropsFromDateTime(selectedDateTime);
			}
		// }
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;

		if (userSector === 'all') fetchSectorOptionsData();
		let storedSectorFilter = localStorage.getItem('presto-summary-sector-filter')
			? JSON.parse(localStorage.getItem('presto-summary-sector-filter') ?? '')
			: null;
		if (storedSectorFilter) {
			setCheckedList(storedSectorFilter);
			setCurrSectorFilter(storedSectorFilter);
		}
		if (!hasDrillDown) {
			const sd = selectedDateTime ? moment(selectedDateTime).format('YYYY-MM-DD') : fromDateTime
			PrestoQueryDetailsListingFunc(page, pageSize, sd, sortingInfo).catch(
		(error) => {
			setError(error); // Set error state when API call fails during initial load
		}
	);
	}
		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/presto/dashboard') {
			if (activeId) {
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('databricks-jobs');
				document.body.classList.add('datefilter-align-sector');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				activeId.classList.remove('jobs-list-table');
				activeId.classList.remove('jobs-list');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.remove('jobs-exe-datas');
			}
		}
	}, []);

	let fromDate = moment(propsFromDateTime).format('YYYY-MM-DD');
	return (
		<div className="job-schedules-wrap">
			<div className="job-exexutions-dateFilter-div">
				{/* [Date Range Menu] */}
				<div className="single-calendar-summary">
					{/* condition for default Today  */}

					{
						<LocalizationProvider className="outerDateContainer" dateAdapter={AdapterMoment}>
							<DatePicker
								showToolbar={true}
								components={{
									OpenPickerIcon: DatePickerIcon
								}}
								PopperProps={{ placement: 'auto' }}
								className="dateFilterTD"
								value={moment(fromDateTime).tz('America/New_York')}
								onChange={(dateTime) => {
									handleDateTimeChange('fromDateTime', dateTime);
								}}
								renderInput={(params: any) => <TextField name="startDateTime" {...params} />}
							/>
						</LocalizationProvider>
					}
				</div>
				{/* <Stack>
			{userSector === 'all' ? (
				<FilterIcon onClick={filterClick} className="sector-filter" />
			) : (
				<div className="sector-filter-text">Sector: {getDecodedToken()?.sector?.name}</div>
			)}

			<Popover
				open={filterMenu !== null}
				onClose={handleClose}
				className="filterContainer"
				anchorReference="anchorPosition"
				anchorPosition={filterMenu !== null ? { top: filterMenu.mouseY, left: filterMenu.mouseX } : undefined}
			>
				<div className="text-filter-row">
					<div className="selectAll-sec">
						<MenuItem className="selectAll">
							<FormControlLabel
								className={classes.checkbox}
								label="All"
								control={
									<Checkbox
										color="success"
										value="all"
										checked={itemsChecked}
										onChange={(e) => {
											selectAllCheck(e);
										}}
									/>
								}
							/>
						</MenuItem>
						<div className="checkbox-sec">
							{sectorOptionsList &&
								sectorOptionsList.map((filter: any) => (
									<MenuItem key={filter}>
										<FormControlLabel
											className={classes.checkbox}
											label={filter === true ? 'Yes' : filter === false ? 'No' : filter}
											control={
												<Checkbox
													color="success"
													value={filter}
													checked={
														(filter === true && checkedList.includes('true')) ||
														(filter === false && checkedList.includes('false')) ||
														checkedList.includes(filter)
													}
													onChange={(e) => {
														handleChange(e);
													}}
												/>
											}
										/>
									</MenuItem>
								))}
						</div>
					</div>
					<div className="buttonSec">
						<Button variant="contained" className={classes.cancelBtn} onClick={handleClose}>
							cancel
						</Button>
						<Button variant="contained" className={classes.okBtn} onClick={handleOkClick}>
							ok
						</Button>
					</div>
				</div>
			</Popover>
			
		</Stack> */}
			</div>

			{!hasDrillDown && (
				<>
					<Stack>
						<AnalyticsVisualization
							params={{
								dashboardUrl: dashboardUrl.prestoSummaryPanels,
								className: 'prestoSummaryPanelsContainer',
								otherParams: {
									'var-fromDateTime': fromDate,
									// 'var-toDateTime': fromDate
								},
								seamless: true
							}}
						/>
					</Stack>
					<Stack>
						<AnalyticsVisualization
							params={{
								dashboardUrl: dashboardUrl.prestoSummarySecondPanels,
								className: 'prestoSummarySecondPanelsContainer',
								otherParams: {
									'var-fromDateTime': fromDate,
									// 'var-toDateTime': fromDate
								},
								seamless: true
							}}
						/>
					</Stack>
				</>
			)}

			{hasDrillDown ? (
				<SummaryDrilldown fromDateTime={propsFromDateTime} sector={currSectorFilter} serverFilter={instance} />
			) : (<div className="tableauJobs_tabledata">
			<div className="tableau_title">Query Details</div>
			{error ? (
				// Display error message or handle error condition in UI
				<div
					style={{
						color: '#e5e5e5',
						marginLeft: '25%',
						padding: '10px',
						fontWeight: 500,
						gridColumn: '1 / -1'
					}}
				>
					Error occurred while fetching data. Please try again.
				</div>
			) : (
				<Grid>
					{!loading ? (
						<CustomizedDataGrid
							tableColumns={getColumns()}
							tableData={queryDetails}
							tableType="Presto"
							defaultColumnNames={defaultColumnNames}
							onSorting={handleSorting}
							currSorting={sortingInfo}
							filterDataOptions={filterData}
							onFilterCheck={handleFilter}
							currFilter={filterInfo}
							searchDataValues={searchDetails}
							// onFilterSearch={handleSearch}
							page={page}
							pageSize={pageSize}
							paginationData={paginationData}
							listLength={queryDetails.length}
							handlepageSizeChange={handlepageSizeChange}
							handlePageChange={handlePageChange}
							isDateRangeFilter={false}
							isDisabled={true}
						/>
					) : (
						<Loader />
					)}
				</Grid>
			)}
		</div>
	)}
		</div>
	);
};

export default prestoSummary;
