import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Chip, Collapse, Dialog, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, MenuProps, Popover, Select, Stack, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { DataPipelineModal } from './modal-component';
import FilterIcon from '../../assets/filter-projects.svg'
import { ReactComponent as EditIcon } from '../../assets/edit-img.svg'
import { ReactComponent as DeleteIcon } from '../../assets/delete-img.svg'
import AddIcon from '../../assets/plus-icon.svg'
import ExpandMoreIcon from '../../assets/down-arrow.svg'
import ViewIcon from '../../assets/view-detail-img.svg'
import ExportIcon from '../../assets/export-img.svg'
import CalenderDateIcon from '../../assets/calender-date.svg'
import NoPrgramFound from '../../assets/no-program-found.svg'
import useStyles from './data-pipeline-styles';
import '../DataPipeline/dataPipeline.scss';
import { getJobPerformanceChartData, getMappedJobScheduleList, getProjectById, getSlaCountDetail } from '../../service/service';
import { CustomizedDataGrid } from '../customized_data_grid';
import { Column } from 'react-data-grid';
import Loader from '../Loader/loader';
import { ReactComponent as CalendarIcon} from '../../assets/calendar-outline_white.svg';
import { ReactComponent as Downarrow } from '../../assets/down-arrow.svg';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { DateRange } from '@mui/lab';
import { ReactComponent as CalendarGreyIcon} from '../../assets/icon-calendar-grey.svg';
import { styled, alpha } from '@mui/material/styles';
import { ReactComponent as MoreIcon } from '../../assets/more-vertical.svg';
import { ReactComponent as InfoGreyIcon } from '../../assets/icon-grey-info.svg';
import { Diversity1Outlined } from '@mui/icons-material';
import { DateRange as DateRangeCustom } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import { enUS } from 'date-fns/locale';

const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));

const ProjectDetails = () => {
    interface Row {
        id: number;
        Job_Name: string;
        Platform_Id: number;
        Start_Time: string;
        Normal_Completion_Time: number;
        slaStatus: number;
        jobStatus: string;
        estimatedTime: string;
        Days: number;
        actualStartTime: string;
        actualEndTime: string;
        scheduleStartTime: number;
        Date: string;
        successor: number;
    };

    const classes = useStyles();
    
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault()
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = (event: any) => {
      event.preventDefault()
      event.stopPropagation();
      setAnchorEl(null);
    };

    const [projectList, setProjectList] = useState<any>([])
    const [openModal, setOpenModal] = useState<any>({isOpen: false})
    const [projectDetail, setProjectDetail] = useState<any>({})
    const [loading, setLoading] = useState(false);
    const [slaLoading, setSlaLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [jobSchedulesList, setjobSchedulesList] = useState<Row[]>([]);
    const [slaData, setSlaData] = useState<any>({
    });
    const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: 'desc' });
    const [filterInfo, setFilterInfo] = useState([]);
    const [filterOptions, setFilterOptions] = useState<any>({
    });
    const [slaReport, setSlaReport] = useState<any>({
    });
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState('1');

	const [startDate, setStartDate] = useState<string>(moment().startOf('day').format('YYYY-MM-DD HH:mm'));
	const [endDate, setEndDate] = useState<string>(moment().endOf('day').format('YYYY-MM-DD HH:mm'));
	const [isDateOpen, setIsDateOpen] = useState(false);
	const [filterMonth, setFilterMonth] = useState("Today");
    const [filterDefaultMonth, setFilterDefaultMonth] = useState("Today");
    const [dateRangeClickCount, setDateRangeClickCount] = useState(0);
	const isCustomMonth = useRef(false);
    const [dateFilter, setDateFilter] = useState<any>('Aug 2024');
    const [customYear, setCustomYear] = useState(moment().format("YYYY"));
    const [value, setValue] = useState<DateRange<Date>>([null, null])
	const [grafanaChartLoading, setGrafanaChartLoading] = useState(true);
	const [grafanaChartOptions, setGrafanaChartOptions] = useState<any>();
	const [performanceByDay, setPerformanceByDay] = useState<any>(false);
    
    // const [is24Hrs, setIs24Hrs] = useState<any>(false);
    let is24Hrs: boolean = false;
    const [customMonth, setCustomMonth] = useState('');

    const [dateFilterMenu, setDateFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
    const [locale, setLocale] = useState(enUS);
    const [selectionRange, setSelectionRange] = useState<any>({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      });
	const dateFilterMenuClick = (event: any) => {
		setDateFilterMenu(
			dateFilterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 400,
						mouseY: parseInt(event.clientY) - 110
				  }
				: null
		);
	};
    
	// Server filter code
	const handleDateFilter = (field: any, selectedDate: any) => {	
		selectedDate = selectedDate.target.value	
        let stDt = '', edDt = '';
		if (field === 'dateFilter') {
			isCustomMonth.current = false;
            is24Hrs = false
            setCustomMonth('');
            edDt = moment().endOf('day').format('YYYY-MM-DD HH:mm')
            if (selectedDate == 'Today') {
                stDt = moment().startOf('day').format('YYYY-MM-DD HH:mm');
            } else if (selectedDate == 'Hrs') {
                stDt = moment().subtract(24,"hours").format('YYYY-MM-DD HH:mm');
               is24Hrs = true
            } else if (selectedDate == '7d') {
                stDt = moment().subtract(6, 'd').format('YYYY-MM-DD HH:mm');
            } else if (selectedDate == '30d') {
                stDt = moment().subtract(29, 'd').format('YYYY-MM-DD HH:mm');
            } else if (selectedDate == '3M') {
                stDt = moment().subtract(3, 'M').format('YYYY-MM-DD HH:mm');
            }
            setStartDate(stDt);
            setEndDate(edDt);
			setFilterMonth(selectedDate);
			if(selectedDate === "custom") {
                setDateRangeClickCount(0);
				isCustomMonth.current = true;
				setIsDateOpen(true)
				return;
			} else {
                projectMappedJobScheduleFunc(page, pageSize, stDt, edDt, parseFilter(filterInfo), sortingInfo);
                jobPerformanceBarChartData(stDt, edDt);
                getSlaCountData(stDt, edDt)
            }
            setFilterDefaultMonth(selectedDate)
		}
		setFilterMonth(selectedDate);
		applyDateFilter(selectedDate);
	};

    const handleCustomOnclick = (e:any) => {
        if (e.target.textContent == 'Custom Date Range') {
            setDateRangeClickCount(0);
			isCustomMonth.current = true;
			setTimeout(() => {
                dateFilterMenuClick(e);
            }, 200);
		}
        if(isCustomMonth.current)
            setIsDateOpen(true);
        else
            setIsDateOpen(false);
    
        if (e.type == 'click' && e.target.className.includes('MuiModal-backdrop')) {
            isCustomMonth.current = false;
            setDateFilterMenu(null);
            setIsDateOpen(false);
            if (customMonth == '') {
                setFilterMonth(filterDefaultMonth)
            }
        }
    }
    const handleSelectDateRange = (e: any) => {
        let clickCount = dateRangeClickCount + 1;
        setSelectionRange(e.selection);
        setDateRangeClickCount(clickCount);
        if (clickCount == 2) {
            setCustomMonth(e.selection.key);
            handleDateFilterMenuClose(e);
            setIsDateOpen(false);
            const stDt = moment(e.selection.startDate).startOf('day').format('YYYY-MM-DD HH:mm');
            const edDt = moment(e.selection.endDate).endOf('day').format('YYYY-MM-DD HH:mm');
            projectMappedJobScheduleFunc(page, pageSize, stDt, edDt, parseFilter(filterInfo),sortingInfo);
            jobPerformanceBarChartData(stDt, edDt);
        }
    }
	const handleDateFilterMenuClose = (event: any) => {
		setDateFilterMenu(null);
	};

	const applyDateFilter = (selectedDate: any) => {
		// getServiceRequestSummary(selectedDate);
		setDateFilter(selectedDate);
    };

    const handleTableAction = (props: any, type: string) => {
        if (type == 'delete') {
            setOpenModal({
                isOpen: true,
                type: 'Schedule',
                action: 'DELETE',
                from: 'delete',
                data: props
            })
        }
    }

    const renderStatus = (status: any) => {
		switch (status) {
			case 'SUCCESS':
			case 'Successful':
			case 'YES':
			case 'Completed':
				return <Chip label={status} style={{ backgroundColor: 'rgba(1, 192, 8, 0.2)', color: 'rgba(1, 192, 8, 1)' }} />;
			case 'SLA Met':
				return <Chip label={status} style={{ backgroundColor: 'rgba(1, 192, 8, 0.2)', color: 'rgba(1, 192, 8, 1)' }} />;
			case 'FAILURE':
			case 'Failure':
			case 'TERMINATED':
			case 'Terminated':
			case 'SUSPENDED':
			case 'NO':
				return <Chip label={status} style={{ backgroundColor: 'rgba(254, 38, 38, 0.2)', color: 'rgba(254, 38, 38, 1)' }}/>;
			case 'SLA Missed':
				return <Chip label={status} style={{ backgroundColor: 'rgba(255, 162, 0, 0.2)', color: 'rgba(255, 162, 0, 1)' }} />;
			case 'NOT STARTED':
			case 'Not Started':
				return <Chip label={status} style={{ backgroundColor: '#1B1E35CC', color: '#71758F' }} />;
			case 'N/A':
            case 'Not Run':
				return <Chip label={status} style={{ backgroundColor: 'rgba(169, 173, 203, 0.2)', color: 'rgba(169, 173, 203, 1)' }} />;
			case 'Scheduled':
				return <Chip label={status} style={{ backgroundColor: 'rgba(255, 162, 0, 0.2)', color: 'rgba(255, 162, 0, 1)' }} />;
			case 'RUNNING':
			case 'ACTIVATED':
			case 'In Progress':
			case 'Hold':
				return <Chip label={status} style={{ backgroundColor: '#299BFF26', color: '#299BFF' }} />;
			default:
				return <Chip label={status} style={{ backgroundColor: '#299BFF26', color: '#299BFF' }} />;
		}
	};

    const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

    const handleFilter = (filterData: any) => {
        setFilterInfo(filterData);
        projectMappedJobScheduleFunc(page, pageSize, startDate, endDate, parseFilter(filterData), sortingInfo);
	};

	const [pageSize, setPageSize] = useState('100');
    const defaultColumnNames = ['Job','Platform', 'SLA Status', 'Normal Completion Time (CST)', 'Job Status', 'Schedule Start Time (CST)', 'Act. Start Time (CST)', 'Act. End Time (CST)', 'Estimated Completion (CST)', 'Date', 'Actions'];

    function getColumns(): Array<Column<Row>> {
        return [
            {
                key: 'Job_Name',
                name: 'Job',
                formatter(props) {
					return <div title={props.row.Job_Name} className={`has-ellipsis ${props.row.successor >0? 'has-successor' : ''}`}>{props.row.Job_Name ? props.row.Job_Name : '--'}</div>
				},
                minWidth: 170
            },
            {
                key: 'Platform_Id',
                name: 'Platform',
                formatter(props) {
					return <div className={`${props.row.successor >0? 'has-successor' : ''}`}>{props.row.Platform_Id == 1 ? 'Teradata' : ''}</div>
				},
                minWidth: 110
            },
    
            {
                key: 'slaStatus',
                name: 'SLA Status',
                formatter(props) {
                    const status = props.row.slaStatus == 1 ? 'SLA Met' : props.row.slaStatus == 0 ? 'SLA Missed' : 'Not Run';
					return renderStatus(status);
					// return <div className={`${props.row.successor >0? 'has-successor' : ''}`}>{props.row.slaStatus == 1 ? 'Met' : props.row.slaStatus == 0 ? 'Not Met' : 'NA'}</div>
				},
                minWidth: 120
            },
            
            {
                key: 'Normal_Completion_Time',
                name: 'Normal Completion Time (CST)',
                formatter(props) {
					return <div className={`${props.row.successor >0? 'has-successor' : ''}`} style={{textTransform: 'capitalize'}}>{props.row.Normal_Completion_Time ?  (props.row.Normal_Completion_Time < 10 ? `000${props.row.Normal_Completion_Time}`.replace(/(.{2})$/,':$1') : props.row.Normal_Completion_Time < 100 ? `00${props.row.Normal_Completion_Time}`.replace(/(.{2})$/,':$1') : props.row.Normal_Completion_Time.toString().replace(/(.{2})$/,':$1')) : '--'}</div>
				},
                minWidth: 250
            },
            {
                key: 'actualStartTime',
                name: 'Act. Start Time (CST)',
                formatter(props) {
					const startDate = props.row.actualStartTime;
					// if (startDate) {
						return <span className={`${props.row.successor >0? 'has-successor' : ''}`}> {startDate && startDate != null ? moment(props.row.actualStartTime).tz('America/New_York').format('MM-DD-YY HH:mm:ss')  : '--'} </span>
					// }
					// return '';
				},
                minWidth: 180
            },
            {
                key: 'actualEndTime',
                name: 'Act. End Time (CST)',
                formatter(props) {
					const startDate = props.row.actualEndTime;
					// if (startDate) {
						return <span className={`${props.row.successor >0? 'has-successor' : ''}`}> {startDate ? moment(props.row.actualEndTime).tz('America/New_York').format('MM-DD-YY HH:mm:ss') : '--'} </span>
					// }
					// return '';
				},
                minWidth: 180
            },
            // {
            //     key: 'Days',
            //     name: 'Schedule Start Day (CST)',
            //     formatter(props) {
			// 		return <div className={`${props.row.successor >0? 'has-successor' : ''}`} style={{textTransform: 'capitalize'}}>{props.row.Days ? getDays(props.row.Days) : '--'}</div>
			// 	},
            //     minWidth: 220
            // },
            {
                key: 'scheduleStartTime',
                name: 'Schedule Start Time (CST)',
                formatter(props) {
					return <div className={`${props.row.successor >0? 'has-successor' : ''}`} style={{textTransform: 'capitalize'}}>{props.row.scheduleStartTime ? getDays(props.row.Days) +', ' + (props.row.scheduleStartTime < 10 ? `000${props.row.scheduleStartTime}`.replace(/(.{2})$/,':$1') : props.row.scheduleStartTime < 100 ? `00${props.row.scheduleStartTime}`.replace(/(.{2})$/,':$1') : props.row.scheduleStartTime.toString().replace(/(.{2})$/,':$1')) : '--'}</div>
				},
                minWidth: 220
            },
            {
                key: 'jobStatus',
                name: 'Job Status',
                formatter(props) {
                    const status = props.row.slaStatus == 2 ?'Not Run' : props.row.jobStatus
					return renderStatus(status);
					// return <div className={`${props.row.successor >0? 'has-successor' : ''}`}>{props.row.jobStatus ? props.row.jobStatus : '--'}</div>
				},
                minWidth: 120
            },
            {
                key: 'estimatedTime',
                name: 'Estimated Completion (CST)',
                formatter(props) {
					const startDate = props.row.estimatedTime;
					if (startDate) {
						return <span className={`${props.row.successor >0? 'has-successor' : ''}`}> {startDate ?  moment(props.row.estimatedTime).tz('America/New_York').format('MM-DD-YY HH:mm:ss') : '--'}  </span>
					}
					return '';
				},
                minWidth: 220
            },
            {
                key: 'Date',
                name: 'Date',
                formatter(props) {
					const startDate = props.row.Date;
					if (startDate) {
						return <span className={`${props.row.successor >0? 'has-successor' : ''}`}> {startDate ? moment(startDate).utc().format('MM-DD-YY') : '--'} </span>
					}
					return '';
				},
                minWidth: 100
            },
            {
                key: 'action',
                name: 'Actions',
                minWidth: 100,
                resizable: false,
                formatter: (props: any) => (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      
                      { props.row.successor >0? <span className="has-successor">-</span> : <Button className='action-cta-link' onClick={() =>handleTableAction(props.row, 'delete')} title='Click to Delete'>
                        <DeleteIcon className='icon-actions' />
                      </Button>
                        }
                      
                    </div>
                  ),
            },
        ];
	}
    
    const getDays = (day: number) => {
        let days;
        if (day == 0) {
            days = 'Sun'
        } else if (day == 1) {
            days = 'Mon'
        } else if (day == 2) {
            days = 'Tue'
        } else if (day == 3) {
            days = 'Wed'
        } else if (day == 4) {
            days = 'Thur'
        } else if (day == 5) {
            days = 'Fri'
        } else if (day == 6) {
            days = 'Sat'
        }
        return days;
    } 

    const chartOption: any = {}
    
	const jobPerformanceBarChartData = async (stdt: any ='', eddt: any = '') => {
        let params = {
            projectId: window.location.pathname.split('/')[4],
            programId: window.location.pathname.split('/')[3],
            startDate: stdt ? stdt : startDate,
            endDate: eddt ? eddt : endDate,
            hours: is24Hrs ? 24 : ''
        }
         

        setGrafanaChartLoading(true)
        let result = await getJobPerformanceChartData(params)
			if (result) {
				const sd = moment(startDate);
				const ed = moment(endDate);
				const duration = moment.duration(ed.diff(sd));
				const hours = duration.asHours();
                let perfByDay = false;
				if (hours >= 48) {
                    perfByDay = true;
					setPerformanceByDay(true)
				}
				let option = {};
				let xAxisArray:any =[];
				let sla_met:any =[];
				let sla_failed:any=[];
				let sla_total:any=[];
				let data: any = []
			
                result.data.slaMetrics.map((res:any,i:number)=>{
					xAxisArray.push(Object.keys(res)[0])
					// if (paramsData.incidentId != 'INC08935506') {
						sla_met.push(res[xAxisArray[i]].sla_met)
						sla_total.push(res[xAxisArray[i]].sla_pending)
					// }
					sla_failed.push(res[xAxisArray[i]].sla_missed)
				})
				data.map((res:any,i:number)=>{
					xAxisArray.push(Object.keys(res)[0])
                    sla_met.push(res[xAxisArray[i]].sla_met)
                    sla_total.push(res[xAxisArray[i]].sla_pending)
					sla_failed.push(res[xAxisArray[i]].sla_missed)
				})


  const getHighestSlaMetAndMissed = (data: any) => {
    let maxSum = 0;
  
    data.forEach((entry: any) => {
         
      const dayData: any = Object.values(entry)[0]; // Get the object with sla_met, sla_missed, sla_pending
      const sum = dayData.sla_met + dayData.sla_missed; // Sum sla_met and sla_missed
      console.log(dayData, sum, maxSum)
      if (sum > maxSum) {
        maxSum = sum; // Update maxSum if current sum is higher
      }
    });
  
    return maxSum;
  }

  const interval = Math.ceil(getHighestSlaMetAndMissed(result.data.slaMetrics) / 5);
  console.log(getHighestSlaMetAndMissed(result.data.slaMetrics), interval)

				option = {
                    title: {
                        text: 'Job Performance',
                        textStyle: {
                            color: '#fff',
                            fontWeight: 600,
                            fontSize: '16px'
                        },
                        top: '15px',
                        left: '15px'
                    },
					backgroundColor: '#053367',
					grid: {
						top: '20%',
						left: '5%',
						right: '2%',
						bottom: '30%'
					},
					xAxis: {
						type: 'category',
						data: xAxisArray,
						axisLabel: {
							color: '#fff'
						}
					},
					yAxis: {
						type: 'value',
                        interval,
						axisLabel: {
							color: '#fff'
						}
					},
					series: [
						{
							silent: true,
							name: 'SLA Met',
							type: 'bar',
							stack: 'total',
							data: sla_met,
							itemStyle: {
								color: 'rgba(1, 192, 8, 1)'
							}
						},
						{
							silent: true,
							name: 'SLA Missed',
							type: 'bar',
							stack: 'total',
							data: sla_failed,
							itemStyle: {
								color: 'rgba(255, 162, 0, 1)'
							}
						},
						// {
						// 	silent: true,
						// 	name: 'N/A',
						// 	type: 'bar',
						// 	stack: 'total',
						// 	data: sla_total,
						// 	itemStyle: {
						// 		color: '#A9ADCB'
						// 	}
						// }
					],
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						}
					},
					legend: {
						show: true,
						left: '35px',
						bottom: '15px',
						data: ['SLA Met', 'SLA Missed'],
						textStyle: {
							color: '#fff'
						}
					},
				};
				setGrafanaChartOptions(option);
				setGrafanaChartLoading(false);
				}
		}
    useEffect(() => {
        const activeId = document.getElementById('root');
        if (window.location.pathname.includes('/data-pipeline/projects')) {
            if (activeId) {
                document.head.classList.add('theme-bg-blue');
                document.body.classList.add('theme-bg-blue');
            }
        }
        getProjectDetails()
        getSlaCountData(startDate, endDate)
        jobPerformanceBarChartData();
	}, []);

    const getProjectDetails = async () => {
        let projectID = window.location.pathname.split('/')[4];
        let programID = window.location.pathname.split('/')[3];
        setLoading(true)
        let result = await getProjectById({projectID, programID})
        setLoading(false)
        setProjectDetail(result.data?.programData ? result.data.programData[0] : '')
        if (result.data.programData[0].jobCount) {
            projectMappedJobScheduleFunc('1', '100', startDate, endDate, parseFilter(filterInfo),sortingInfo)
        }
    }

    const passInfoToParent = (data: any) => {
        console.log(data);
        getProjectDetails();
        jobPerformanceBarChartData();
    }

    const handleEditClose = (data: any) => {
       
        setOpenModal({
            isOpen: false,
           
        })
    }

    const onCreateProgramClick = (event: any) => {
        setOpenModal({
            isOpen: true,
            type: 'Project',
            action:'CREATE'
        })
    }

    function convertToTimeFormat(num: number) {
        // Ensure the number is a string with leading zeros if necessary
        const numStr = num.toString().padStart(4, '0');
        
        // Extract hours and minutes
        const hours = numStr.slice(0, 2);
        const minutes = numStr.slice(2);
      
        // Format as 'HH:MM'
        return `${hours}:${minutes}`;
      }

    const handleSorting = (sortingInfo: any) => {
        console.log("SORTTT", sortingInfo)
        projectMappedJobScheduleFunc(page, pageSize, startDate, endDate, parseFilter(filterInfo), sortingInfo);

	};
    
	const handlePageChange = (event: React.ChangeEvent<unknown>, value: string) => {
       
        projectMappedJobScheduleFunc(value, pageSize, startDate, endDate, parseFilter(filterInfo), sortingInfo);
	};
	const handlepageSizeChange = (event: any) => {
		projectMappedJobScheduleFunc(page, event.target.value, startDate, endDate, parseFilter(filterInfo), sortingInfo);
	};

    async function getSlaCountData(start: string, end:string)  {
        let projectId: string = window.location.pathname.split('/')[4];
        setSlaLoading(true);
        const res: any = await getSlaCountDetail(projectId, start, end, is24Hrs);
        let objData ={
            slaMet: 0,
            slaMissed: 0,
            slaPending: 0,
            completed: 0,
            failed: 0
        }
        res.data.map((obj: any) => {
          objData.slaMet += obj.slaMet
          objData.slaMissed += obj.slaMissed
          objData.slaPending += obj.slaPending,
          objData.completed += obj.completed,
          objData.failed += obj.failed
        })
        setSlaReport({
           
            ...objData
        })
        setSlaLoading(false);
    }

    const removeDuplicates =(arr: any) => {
        const seenIds = new Set();
        
        return arr.filter((item: any) => {
          const duplicate = seenIds.has(item.id);
          seenIds.add(item.id);
          return !duplicate; // Only return items with unique ids
        });
      }

    async function projectMappedJobScheduleFunc(pageNumber: string, pageSize: string, startDate: string, endDate: string, filterData: any, sortingInfo: any) {
        setTableLoading(true);
        let projectId = window.location.pathname.split('/')[4];
		const res = await getMappedJobScheduleList(projectId, pageNumber, pageSize, startDate, endDate, filterData, is24Hrs, sortingInfo);
		if (res.success) {
            setTableLoading(false);
            let jobArr = res.data.scheduleList.filter((obj: any) => !obj.successor || obj.successor === null)
            let depJobArr = res.data.scheduleList.filter((obj: any) => obj.successor && obj.successor !== null)
            let reqData: any = []
            setSlaData({
                ...slaData,
                ...res.data.slaData
            })
            const filters = res.data.filterOptions;
            let formatedDates: any = [];
			Object.entries(filters).filter((d: any) => { if (d[0] == 'date') {
				formatedDates = d[1].map((s: any) => moment(s).utc().format('YYYY-MM-DD'))
			}  });
            filters['date'] = formatedDates

           Object.keys(filters).map((key: any, index: number) => {
               if (key === 'slaStatus') {
                  filters['sla'] = []
                  filters[key].map((val: any) => {
                     switch (val) {
                        case 1:
                            filters['sla'].push('SLA Met')
                            break;
                        case 0:
                            filters['sla'].push('SLA Missed')
                            break;
                        case 2:
                            filters['sla'].push('Not Run')
                            break;
                        default:
                            break;
                     }
                  })
                  filters['slaStatus'] = filters['sla']
                 delete filters['sla']
               } else if(key === 'status'){
                filters['jobStatus'] = []
                filters[key].map((val: any) => {
                  if (val === 'Blank') {
                    filters['jobStatus'].push('Not Run')
                  } else {
                    filters['jobStatus'].push(val)
                  }
                })
                delete filters['status']
               } else if(key === 'Platform_Id'){
                filters[key].map((val: any, index: number) => {
                    if (val === 1) {
                      filters['Platform_Id'][index] = 'Teradata'
                    } 
                  })
               } else if(key === 'startAt'){
                filters['actualStartTime'] = filters['startAt']
               } else if(key === 'endAt'){
                filters['actualEndTime'] = filters['endAt'] 
               }  else if(key === 'Start_Time'){
               
                filters['scheduleStartTime'] = filters['Start_Time']
                
                filters['scheduleStartTime'].map((data: any, i: number) => {
                   
                    data && (data < 10 ? `00:0${data}` : data < 100 ? `00:${data}` : convertToTimeFormat(data))
                    filters['scheduleStartTime'][i] = data
                    
                })
               }else if(key === 'Normal_Completion_Time'){
               
 
                    filters['Normal_Completion_Time'].map((data: any, i: number) => {
                        data && (data < 10 ? `00:0${data}` : data < 100 ? `00:${data}` : convertToTimeFormat(data))
                        filters['Normal_Completion_Time'][i] = data
                    })
                 
           
               }
           })
           console.log("HGDFJ", filters)
            setFilterOptions(filters)
            if (is24Hrs) {
                jobArr.map((data: any) => {
                
              
                    depJobArr.map((dep: any) => {
                        if (dep.successor === data.projectScheduleId ) {
                            reqData.push(dep)
                          
                        }
                    })
                    reqData.push(data);
                } )
            } else {
                jobArr.map((data: any) => {
                
              
                    depJobArr.map((dep: any) => {
                        if (dep.successor === data.projectScheduleId && moment(dep.Date).format("YYYY-MM-DD") === moment(data.Date).format("YYYY-MM-DD")) {
                            reqData.push(dep)
                          
                        }
                    })
                    reqData.push(data);
                } )
            }

            reqData = removeDuplicates(reqData)
            

            reqData.sort(function (a: any, b: any) {
                // First, compare by date
                const dateDiff = new Date(b.Date).getTime() - new Date(a.Date).getTime();
                
                // If dates are equal, compare by Days
                if (dateDiff === 0) {
                  const daysDiff = a.Days - b.Days;
                  
                  // If Days are also equal, compare by Start_Time
                  if (daysDiff === 0) {
                    return a.Start_Time - b.Start_Time; // Assuming Start_Time is a comparable number or timestamp
                  }
              
                  return daysDiff;
                }
              
                return dateDiff;
              });
            

     
            setjobSchedulesList(reqData)
            setPaginationData(res.data.page);
            setPage(res.data.page.currentPage);
            setPageSize(res.data.page.size);
            // const pagination = {"size":100,"totalElements": reqData.length || res.data.scheduleList.length,"totalPages": 1,"currentPage":1};
            // setPaginationData(pagination);
            // setPage('1');
            // setPageSize('100');
		}
	}

    const formatDate = (isoString: string) => {
        const date = new Date(isoString);

        const padTo2Digits = (num: any) => String(num).padStart(2, '0');
      
        const month = padTo2Digits(date.getUTCMonth() + 1); // getUTCMonth() returns months from 0-11
        const day = padTo2Digits(date.getUTCDate());
        const year = String(date.getUTCFullYear()).slice(-2); // Get last two digits of year
        const hours = padTo2Digits(date.getUTCHours());
        const minutes = padTo2Digits(date.getUTCMinutes());
        const seconds = padTo2Digits(date.getUTCSeconds());
      
        return `${month}-${day}-${year}`;
      }

      const handleAction = (module: string, type: string, data: any, e:any) => {
        e.preventDefault()
        e.stopPropagation();
        handleMenuClose(e);
        data = {
            ...data,
            ProgramName: projectDetail?.program_Name
        }
        // debugger
        switch (type) {
            case 'edit':
                console.log(projectDetail)
                setOpenModal({
                    isOpen: true,
                    type: module,
                    action: 'EDIT',
                    from: 'view',
                    data: module === 'Project' ? projectDetail : data
                })
                break;
                case 'delete':
                    setOpenModal({
                        isOpen: true,
                        type: module,
                        action: 'DELETE',
                        from: 'delete',
                        data: module === 'Project' ? projectDetail : data
                    })
                    break;
                case 'view':
                    const pgmId = window.location.pathname.split('/')[3];
                    const prjId = window.location.pathname.split('/')[4];
                    const pgmName = window.location.pathname.split('/')[5];
                    const prjName = window.location.pathname.split('/')[6];
                    window.location.href = `/data-pipeline/search-jobs/${pgmId}/${prjId}/${pgmName}/${prjName}`
                    break;
            default:
                break;
        }
    }



return (
 <div className="dashboard-panel-dataestate job-schedules-wrap data-pipeline-wrapper">    
        <div  className="service-sector-filter w-201">
				{/* Code for sector filter */}
				<Stack className="sector-filter-dataestate">
					<>
                    <div className="service-date-filter date-picker-summary pb-10">
				{/* Code for Date filter */}
				<Stack>
					<div className="date-select">
						<FormControl fullWidth>
							<CalendarIcon className="date-icon" />
							<Select
								name="date-select"
								labelId="demo-simple-select-label"
								id="single-date-select"
								value={filterMonth}
								label="Date"
								onChange={(e) => {
									handleDateFilter('dateFilter', e);
								}}
								classes={{ select: classes.select }}
								IconComponent={() => null} 
								open={isDateOpen} 
								onClick={(e) =>handleCustomOnclick(e)}
								onOpen={() => setIsDateOpen(true)}
								onClose={() => setIsDateOpen(false)} 
							>
                                <MenuItem value={'Today'}>Today</MenuItem>
                                <MenuItem value={'Hrs'}>Last 24 Hrs</MenuItem>
                                <MenuItem value={'7d'}>Last 7 days</MenuItem>
                                <MenuItem value={'30d'}>Last 30 days</MenuItem>
                                <MenuItem value={'3M'}>Last 3 Months</MenuItem>
                                <MenuItem value={'custom'}>Custom Date Range</MenuItem>
							</Select>
						</FormControl>
						{isCustomMonth.current && <Popover
                            open={dateFilterMenu !== null}
                            onClose={handleDateFilterMenuClose}
                            className="custom-date-filter-menu"
                            anchorReference="anchorPosition"
                            anchorPosition={dateFilterMenu !== null ? { top: dateFilterMenu.mouseY, left: dateFilterMenu.mouseX } : undefined}
                        ><div className="">
                            <DateRangeCustom
                                className='custom-date-range-wrapper'
                                showPreview={false}
                                editableDateInputs={true}
                                moveRangeOnFirstSelection={false}
                                locale={locale}
                                ranges={[selectionRange]}
                                onChange={ (date)=>handleSelectDateRange(date)}
                            />
						</div></Popover>}
					</div>
				</Stack>
			</div>
						<span className="sector-cta d-flex">
                        {/* <img className='icon cursor' src={ExportIcon} alt="" /> */}
                        <Button variant="contained" className='cta-btn'>
                            <Link className='cta-link fs16 fw700' to={`/data-pipeline/search-jobs/${window.location.pathname.split('/')[3]}/${window.location.pathname.split('/')[4]}/${window.location.pathname.split('/')[5]}/${window.location.pathname.split('/')[6]}`}>
                                <img src={AddIcon} alt="" /> ADD SCHEDULES
                            </Link>
						</Button>
						</span>
					</>
					
				</Stack>
		</div>
        <Dialog open={openModal.isOpen} onClose={handleEditClose} fullWidth={true} maxWidth="md" className="edit-job-modal">
				<DataPipelineModal handleEditClose={handleEditClose} programData={openModal} passInfoToParent={passInfoToParent} />
		</Dialog>
            
        <div className='d-flex-main'>
            <Grid container spacing={0} className='m-5'>
                <Grid xs={6} item>
                <div className='project-data-card'>
                 {/* <div className='justify-btw-items-center'> */}
                    {/* <p className='tile-txt'>Details</p> */}
                    <div className='actions-item actions-item-details'>
                    <span className="icon-info">

                        <Tooltip arrow title={<div className='info-tooltip-wrapper'>
                            <div className="info-date w-100 mb-10">
                                <label className="title">Project Owner</label>
                                <div className='value'>
                                    <span title={projectDetail.Project_Owner}>
                                    {projectDetail?.Project_Owner ? projectDetail.Project_Owner : 'Loading...'}</span>
                                </div>
                            </div>

                            <div className="info-date w-100 mb-10">
                                <label className="title">Description</label>
                                <div className="value">
                                    <span title={projectDetail.Project_Description}>
                                    {projectDetail?.Project_Description ? projectDetail.Project_Description : 'Loading...'}</span>
                                </div>
                            </div>
                            <div className="info-date">
                                <label className='title'>Date Created</label>
                                <div className='value'>
                                {projectDetail?.Created_Dt ? <>{formatDate(projectDetail.Created_Dt)} <CalendarIcon className='img' /></> : 'NA'}
                                </div>
                            </div>
                            <div className="info-date">
                                <label className='title'>Last Updated</label>
                                <div className='value'>
                                {projectDetail?.Updated_Dt ? <>{formatDate(projectDetail.Updated_Dt)} <CalendarIcon className='img' /></> : 'NA'}
                                </div>
                            </div>
                            </div>}>
                            <IconButton
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <InfoGreyIcon />
                            </IconButton>
                        </Tooltip>

                        </span>

                        <Button
                            aria-controls={open ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClick}
                            className='actions-btn'
                        >
                            <MoreIcon />
                        </Button>
                        <StyledMenu
                            className="menu-list-wrapper"
                            MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleMenuClose}
                        >
                            { !projectDetail.Clarity_Project_Id && <MenuItem title='Click to edit' onClick={(e) => handleAction('Project', 'edit', {}, e)}>
                            <EditIcon className='mr-5 icon-actions' /> Edit
                            </MenuItem>
                            }
                            <MenuItem onClick={(e) => handleAction('Project', 'delete', {}, e)}>
                                <DeleteIcon title='Click to delete' className='mr-5 icon-actions' /> Delete
                            </MenuItem>
                        </StyledMenu>
                        
                    {/* </div> */}
                 </div>
                 <Grid container spacing={0}>
                    <Grid xs={12} item>
                      <p className='tile-sub-header-txt w-90'>
                        <div className="label-txt">Project Name</div>
                         <div className='tile-detail-txt' title={projectDetail.Project_Name}>
                            {projectDetail?.Project_Name ? projectDetail.Project_Name : 'Loading...'}</div></p>
                      {/* <p className='tile-sub-header-txt'>
                        <span className="label-txt">Project Owner</span>
                        <span  title={projectDetail.Project_Owner} className='tile-detail-txt'>
                            {projectDetail?.Project_Owner ? projectDetail.Project_Owner : 'Loading...'}</span></p>
                      <p className='tile-sub-header-txt'>
                        <span className="label-txt">Description</span>
                        <span className='tile-detail-txt tile-detail-txt-description has-ellipsis' title={projectDetail.Project_Description}>
                            {projectDetail?.Project_Description ? projectDetail.Project_Description : 'Loading...'}</span></p> */}
                    </Grid>
                    {/* <Grid xs={4} item>
                        <p className='tile-sub-header-txt'>
                        <span className="label-txt">Date Created</span>
                        <span className='tile-detail-txt small'>
                            {projectDetail?.Created_Dt ? <>{formatDate(projectDetail.Created_Dt)} <CalendarIcon className='img' /></> : 'NA'}</span></p>
                        <p className='tile-sub-header-txt'>
                            <span className="label-txt">Last Updated</span>
                            <span className='tile-detail-txt small'>
                                {projectDetail?.Updated_Dt ? <>{formatDate(projectDetail.Updated_Dt)} <CalendarIcon className='img' /></> : 'NA'}</span></p>
                    </Grid> */}
                 </Grid>
            </div>
                </Grid>
                <Grid xs={6} item>
                    <div className='tot-jobs-status-card tot-jobs-card'>
                        <p className='tile-txt m-0'>Schedules</p>
                        <p className='count-box-txt m-0'>{projectDetail.jobCount ? projectDetail.jobCount : 0}</p>
                    </div>

                    <div className='tot-jobs-status-card completed-card'>
                        <p className='tile-txt m-0'>Completed</p>
                        <p className='count-box-txt m-0'>{slaLoading ? <span style={{fontSize: '12px'}}>Loading...</span> : slaReport.completed || 0}</p>
                    </div>

                    <div className='tot-jobs-status-card failed-card'>
                        <p className='tile-txt m-0'>Failed</p>
                        <p className='count-box-txt m-0'>{slaLoading ? <span style={{fontSize: '12px'}}>Loading...</span> : slaReport.failed || 0}</p>
                    </div>
                    
                    <div className='tot-jobs-status-card sla-met-card'>
                        <p className='tile-txt m-0'>SLA Met</p>
                        <p className='count-box-txt m-0'>{slaLoading ? <span style={{fontSize: '12px'}}>Loading...</span> : slaReport.slaMet }</p>
                    </div>
               
                    <div className='tot-jobs-status-card sla-missed-card'>
                        <p className='tile-txt m-0'>SLA Missed</p>
                        <p className='count-box-txt m-0'>{slaLoading ? <span style={{fontSize: '12px'}}>Loading...</span> : slaReport.slaMissed }</p>
                    </div>

                    <div className='tot-jobs-status-card na-card'>
                        <p className='tile-txt m-0'>Not Run</p>
                        <p className='count-box-txt m-0'>{slaLoading ? <span style={{fontSize: '12px'}}>Loading...</span> : slaReport.slaPending }</p>
                    </div>

                </Grid>
            </Grid>
            {/* <div className='project-data-card'>
                 <div className='justify-btw-items-center'>
                    <p className='tile-txt'>Details</p>
                    <div>
                        <img onClick={(e) => handleAction('Project', 'edit', {}, e)}  src={EditIcon} className='mr-5 cursor' alt="" />
                        <img onClick={(e) => handleAction('Project', 'delete', {}, e)} src={DeleteIcon} className='cursor' alt="" />
                    </div>
                 </div>
                 <div className='d-flex-main'>
                   <div className='w-40 mr-5'>
                      <p className='tile-sub-header-txt'>Project Name: <span className='tile-detail-txt'>{projectDetail?.Project_Name ? projectDetail.Project_Name : ''}</span></p>
                      <p className='tile-sub-header-txt'>Project Owner: <span className='tile-detail-txt'>{projectDetail?.Project_Owner ? projectDetail.Project_Owner : ''}</span></p>
                      <p className='tile-sub-header-txt'>Description: <span className='tile-detail-txt'>{projectDetail?.Project_Description ? projectDetail.Project_Description : ''}</span></p>
                   </div>
                   <div className='w-60'>
                      <p className='tile-sub-header-txt'>Date Created: <span className='tile-detail-txt'>{projectDetail?.Created_Dt ? formatDate(projectDetail.Created_Dt) : 'NA'}<img src={CalenderDateIcon} className='m-5' alt="" /></span></p>
                      <p className='tile-sub-header-txt'>Last Updated: <span className='tile-detail-txt'>{projectDetail?.Updated_Dt ? formatDate(projectDetail.Updated_Dt) : 'NA'}<img src={CalenderDateIcon} className='m-5' alt="" /></span></p>
                   </div>
                 </div>
            </div> */}
           {/* <div className='projects-cards-div'>
              <div className='tot-jobs-card'>
              <p className='tile-txt m-75'>Total Jobs</p>
              <p className='count-box-txt m-0'>0</p>
              </div>
              <div className='d-flex-main'>
               <div className='tot-jobs-status-card completed-card mr-5'>
               <p className='tile-txt m-75'>Completed</p>
               <p className='count-box-txt m-0'>0</p>
               </div>
               <div className='tot-jobs-status-card sla-met-card mr-5'>
               <p className='tile-txt m-75'>SLA Met</p>
               <p className='count-box-txt m-0'>0</p>
               </div>
               <div className='mr-5 tot-jobs-status-card sla-missed-card'>
               <p className='tile-txt m-75'>SLA Missed</p>
               <p className='count-box-txt m-0'>0</p>
               </div>
               <div className='tot-jobs-status-card failed-card'>
               <p className='tile-txt m-75'>Failed</p>
               <p className='count-box-txt m-0'>0</p>
               </div>
              </div>
           </div> */}
        </div>
    <div className='schedules-list-wrapper'>
        {/* <div className="schedules-graph">
        {
        grafanaChartLoading ? 'Loading...' : <ReactECharts
            option={grafanaChartOptions}
            theme={{
                backgroundColor: '#1b1e35'
            }}
        />}
        </div>
        <br /> */}
        {/* Bar Chart */}
        {
             !tableLoading && jobSchedulesList.length ? <Grid item xs={6} className="chart-widget chart-widget-query bgcolor-blue">
             <div className="">
                 </div>
                 {
                     grafanaChartLoading  ? 'Loading...' : <ReactECharts
                         option={grafanaChartOptions}
                         theme={{
                             backgroundColor: '#1b1e35'
                         }}
                     />}
             </Grid> : ''
        }
			
    <Grid>
        {!tableLoading && projectDetail.jobCount > 0 ? (
            <CustomizedDataGrid
                tableColumns={getColumns()}
                tableData={jobSchedulesList}
                tableType="job-schedules-1"
                defaultColumnNames={defaultColumnNames}
                filterDataOptions={filterOptions}
                onSorting={handleSorting}
                currSorting={sortingInfo}
                onFilterCheck={handleFilter}
                currFilter={filterInfo}
                page={page}
                pageSize={pageSize}
                paginationData={paginationData}
                listLength={jobSchedulesList.length}
                handlepageSizeChange={handlepageSizeChange}
                handlePageChange={handlePageChange}
                isDateRangeFilter={false}
                isDisabled={false}
                isFetching={true}
                />
            ) : !tableLoading && jobSchedulesList.length == 0 && projectDetail.jobCount == 0 ?  <div style={{height: '50vh'}} className='flex-center'>
            <img className='image' src={NoPrgramFound} alt="" />
            <p className='no-program-txt'>Add Schedules</p>
        </div> : ( <Loader /> )
        }
    </Grid>
    </div>
        
      
  </div>
)
}

export default ProjectDetails