import { useLocation, useNavigate } from 'react-router-dom';

export const useRedirectLogin = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const redirectToLogin = (redirectTo = '/') => {
		localStorage.setItem('redirectAfterLogin', location.pathname);
		if (process.env.REACT_APP_IS_LOCAL === 'true') {
			redirectTo = '/userLogin';
		}
		navigate(redirectTo, { replace: true, state: { from: location } });
	};

	const redirectAfterLogin = () => {
		const storedUrl = localStorage.getItem('redirectAfterLogin');
		if (storedUrl) {
			localStorage.removeItem('redirectAfterLogin');
			navigate(storedUrl, { replace: true });
		}
	};
	return { redirectToLogin, redirectAfterLogin };
};
