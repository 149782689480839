
import { ReactComponent as FilterIcon } from '../../assets/ml_summary_filter.svg';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import DataQualityLogo from '../../assets/Vector-sync.svg';
import DemandControlImg from '../../assets/Demand-Control-DC-chart.png';
import DemandControl2Img from '../../assets/Demand-Control-DC-chart2.png';
import DemandControl3Img from '../../assets/Demand-Control-DC-chart3.png';

import './ibp-obs.scss';
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';

export default function IbpDataQuality() {

	return (
		<div className="page-table jobs-tabledata page-table-ibp-data-quality">
            <div className="ibp-filter-wrapper">
                <div className="IBP-filter-text">IBP-Turkey </div> <FilterIcon className="sector-filter1" />
            </div>
            
            <div className="ibp-dashboard-wrapper ibp-platform-grids ibp-data-quality">
                <Grid container spacing={1} className="headerIPB">
                    <Grid item xs={6} className="monitor-title bdr-none">
                        <div className="monitor-head">Total GDOs</div>
                        <div className="monitor-head head-value">55</div>
                    </Grid>
                    <Grid item xs={6} className="monitor-title bgcolor-light-blue bdr-light-blue">
                        <div className="monitor-head">GDOs Data Quality Score Above 95%</div>
                        <div className="monitor-head head-value">40</div>
                    </Grid>
                </Grid>
            </div>

            
            <div className="business-process-accordion ibp-platform-accordion">
                <div className="bp-list--header">
                    <span className="bp--name">Business Processes</span>
                    <span className="count-badge total-gdos">GDOs Data Quality Score</span>
                </div>
                <Accordion className="w-full">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className="according-expand-icon" />}
                        aria-controls="panel-1-content"
                        id="panel-1-header"
                    >
                        <div className="business-process--list">
                            <span className="bp--name">
                                <img src={DataQualityLogo} alt="" className='databricks-icon' />
                                Demand Control DC
                                </span>
                            <span className="total-cost-label success-jobs count-badge">96%</span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <h2 className="title">DQ Score & Shortfall by GDO</h2>
                        <div className="demand-chart">
                            <img src={DemandControlImg} alt="" />
                        </div>
                    </AccordionDetails>
                </Accordion>

                
                <Accordion className="w-full">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className="according-expand-icon" />}
                        aria-controls="panel-1-content"
                        id="panel-1-header"
                    >
                        <div className="business-process--list">
                            <span className="bp--name">
                                <img src={DataQualityLogo} alt="" className='databricks-icon' />
                                Supply Control SC
                                </span>
                            <span className="total-cost-label failed-jobs count-badge">92.5%</span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        
                    <h2 className="title">DQ Score & Shortfall by GDO</h2>
                        <div className="demand-chart">
                            <img src={DemandControl2Img} alt="" />
                        </div>
                    </AccordionDetails>
                </Accordion>

                
                <Accordion className="w-full">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className="according-expand-icon" />}
                        aria-controls="panel-1-content"
                        id="panel-1-header"
                    >
                        <div className="business-process--list">
                            <span className="bp--name">
                                <img src={DataQualityLogo} alt="" className='databricks-icon' />
                                iS&OE Review
                                </span>
                            <span className="total-cost-label at-risk-jobs count-badge">83%</span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        
                    <h2 className="title">DQ Score & Shortfall by GDO</h2>
                        <div className="demand-chart">
                            <img src={DemandControl3Img} alt="" />
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>

		</div>
	);
}
