import { useEffect, useState } from 'react';
import { Column } from 'react-data-grid';
import { getDBMemoryUtilization, getDBMemoryUtilizationDate, getDatabricksResourceOptimizationChart, jobSearchMemoryUtilization } from '../../../service/service';
import { CustomizedDataGrid } from '../../customized_data_grid';
import { Grid } from '@mui/material';
import Loader from '../../Loader/loader';
import { useSearchParams } from 'react-router-dom';
import '../Summary/Summary.scss';
import './ResourceOpt.scss';
import moment from 'moment';
import { databricksurl } from '../../../service/config';

import axios from 'axios';
const MemoryUtilization = ({ fromDateTime, sectorFilter, setFromDateTime, dateSelect }: any) => {
	interface Row {
		workspace_name: string;
		project_name: string;
		sku: string;
		cluster_name: string;
		sector_name: string;
		job_name: string;
		start_date: string;
		end_date: string;
		gc_differential: string;
		job_count: string;
	}

	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'sector_name',
				name: 'Sector Name',
				formatter(props) {
					return <div title={props.row.sector_name} className='has-ellipsis'>{props.row.sector_name}</div>
				},
				minWidth: 180
			},
			{
				key: 'workspace_name',
				name: 'Workspace',
				formatter(props) {
					return <div title={props.row.workspace_name} className='has-ellipsis'>{props.row.workspace_name}</div>
				},
				minWidth: 120,
				width: 250
			},
			{
				key: 'cluster_name',
				name: 'Cluster Name',
				formatter(props) {
					return <div title={props.row.cluster_name} className='has-ellipsis'>{props.row.cluster_name?props.row.cluster_name:'--'}</div>
				},
				minWidth: 120,
				width: 200
			},
			{
				key: 'sku',
				name: 'Cluster Type',
				minWidth: 120,
				formatter(props) {
					return <div title={props.row.sku} className='has-ellipsis'>{props.row.sku ? props.row.sku : '--'}</div>
				},
				width: 130
			},
			{
				key: 'job_name',
				name: 'Job Name',
				formatter(props) {
					return <div title={props.row.job_name} className='has-ellipsis'>{props.row.job_name}</div>
				},
				minWidth: 120,
				width: 250
			},
			{
				key: 'gc_differential',
				name: 'GC Differential',
				minWidth: 130
			},
			{
				key: 'job_count',
				name: 'Job Count',
				minWidth: 120
			},
			{
				key: 'start_date',
				name: 'Start Time',
				minWidth: 120,
				width: 130,
				formatter(props) {
					const startDate = props.row.start_date;
					if (startDate) {
						return moment(startDate).utc().format('MM-DD-YY HH:mm:ss');
					}
					return '';
				}
			},
			{
				key: 'end_date',
				name: 'End Time',
				minWidth: 120,
				width: 130,
				formatter(props) {
					const startDate = props.row.end_date;
					if (startDate) {
						return moment(startDate).utc().format('MM-DD-YY HH:mm:ss');
					}
					return '';
				}
			},
			
			{
				key: 'project_name',
				name: 'Project Name',
				formatter(props) {
					return <div title={props.row.project_name} className='has-ellipsis'>{props.row.project_name}</div>
				},
				minWidth: 120,
				width: 250
			}	
		];
	}

	const defaultColumnNames = [
		'Sector Name',
		'Workspace',
		'Cluster Name',
		'Cluster Type',
		'Job Name',
		'GC Differential',
		'Job Count',
		'Start Time',
		'End Time',
		'Project Name'
	];

	const [MemoryUtilizationList, setMemoryUtilizationList] = useState<Row[]>([]);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: 'gc_differential', sortDir: 'desc' });
	const [paginationData, setPaginationData] = useState({ totalPages: 0, totalCount: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [searchDetails, setFilterSearchInfo] = useState([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const [loading, setLoading] = useState(false);
	const [filterInfo, setFilterInfo] = useState([]);
	const [filterData, setFilterData] = useState<Row[]>([]);
	const [error, setError] = useState<string | null>(null);

	const handlePageChange = (event: any, value: number) => {
		setLoading(true);
		let nextPage = value.toString(); 
		if(event.target.dataset.testid == "LastPageIcon" || event.target.dataset.testid == "FirstPageIcon"){
			nextPage = value.toString(); 
		}
		else if(event.target.dataset.testid == "NavigateBeforeIcon"){
			nextPage = (Number(page) - 1).toString();
		}
		else if (nextPage === event.target.textContent) { 
			nextPage = event.target.textContent;
		} else {
			nextPage = (Number(page) + 1).toString();
		}
		const promise1 = DBMemoryUtilizationListingFunc(
			parseInt(nextPage),
			pageSize,
			sortingInfo,
			fromDateTime,
			fromDateTime,
			parseFilter(filterInfo),
			sectorFilter
		).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

	const handlepageSizeChange = (event: any) => {
		setLoading(true);
		const promise1 = DBMemoryUtilizationListingFunc(
			'1',
			event.target.value,
			sortingInfo,
			fromDateTime,
			fromDateTime,
			parseFilter(filterInfo),
			sectorFilter
		).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

	const handleSorting = (sortingInfo: any) => {
		setLoading(true);
		const promise1 = DBMemoryUtilizationListingFunc(
			page,
			pageSize,
			sortingInfo,
			fromDateTime,
			fromDateTime,
			parseFilter(filterInfo),
			sectorFilter
		).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

	const resetSearchParams = () => {
		if (searchParams.has('fromDate') || searchParams.has('fromDate')) {
			searchParams.delete('status');
			searchParams.delete('scheduleDate');
			searchParams.delete('fromDate');
			searchParams.delete('fromDate');
			setSearchParams(searchParams);
		}
	};

	const handleFilter = (filterInfo: any) => {
		resetSearchParams();
		setFilterInfo(filterInfo);
		setLoading(true);
		const promise1 = DBMemoryUtilizationListingFunc(
			1,
			pageSize,
			sortingInfo,
			fromDateTime,
			fromDateTime,
			parseFilter(filterInfo),
			sectorFilter
		).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

	async function jobSearchForDBMemoryFunc(searchDetails: any, columnKey: any) {
		const res = await jobSearchMemoryUtilization(searchDetails, columnKey, fromDateTime, filterInfo,
			sectorFilter);
		if (res.success) {
			setFilterSearchInfo(res.data);
		}
	}

	const handleSearch = (searchDetails: any, columnKey: any) => {
		jobSearchForDBMemoryFunc(searchDetails, columnKey);
	};

	const formatColumnFilterOptions = (apiData: any) => {
		const filterOptions: any = {
			workspace_name: [],
			project_name: [],
			cluster_type: [],
			cluster_name: [],
			job_name: []
		}
		apiData.filter((job: any) => {
			Object.entries(job).forEach(([key, value]) => {
				if (key == 'workspace_name' && !filterOptions.workspace_name.includes(value)) {
					filterOptions.workspace_name.push(value);
				} else if (key == 'project_name' && !filterOptions.project_name.includes(value)) {
					filterOptions.project_name.push(value);
				} else if (key == 'cluster_type' && !filterOptions.cluster_type.includes(value)) {
					filterOptions.cluster_type.push(value);
				} else if (key == 'cluster_name' && !filterOptions.cluster_name.includes(value)) {
					filterOptions.cluster_name.push(value);
				} else if (key == 'job_name' && !filterOptions.job_name.includes(value)) {
					filterOptions.job_name.push(value);
				}
			});
		});
		return filterOptions;
	}

// initial load API call
	async function DBMemoryUtilizationListingFunc(
		pageNumber: any,
		size: any,
		sortingInfo: any,
		fromDateTime: any,
		toDateTime: any,
		jobFilterInfo: any,
		sector: any
	) {
		setLoading(true);
			try {
		let storedSectorFilter = localStorage.getItem('d-resource-sector-filter')
		? JSON.parse(localStorage.getItem('d-resource-sector-filter') ?? '')
		: null;
		if (storedSectorFilter) {
			sector = storedSectorFilter;
		}

		// Make a call to Databricks
		const databricksresponse = await axios.get(`${databricksurl}`);
	
		console.log('Databricks Call initiated', databricksresponse);

		if (databricksresponse && databricksresponse.status) {
			// Fetch the memory utilization data
			const res = await getDBMemoryUtilization(
				pageNumber.toString(),
				size.toString(),
				sortingInfo,
				jobFilterInfo,
				fromDateTime,
				fromDateTime,
				sector
			);

			if (res.success) {
				// Format the response data
				const addDollar = res.data.data.map((item: any) => ({
					...item,
					gc_differential: item.gc_differential !== null && item.gc_differential !== undefined
						? `${item.gc_differential.toFixed(2)}`
						: '--',
					project_name: item.project_name !== null && item.project_name !== undefined
						? `${item.project_name}`
						: '--'
				}));

				// Update the state with the formatted data
				setMemoryUtilizationList(addDollar);
				setPaginationData(res.data.page);
				setFilterData(res.data.filterOptions);
				setFilterInfo(jobFilterInfo);
				setPage(pageNumber);
				setPageSize(size);
				setFromDateTime(localStorage.getItem('processedDate'));
				setSortingInfo(sortingInfo);
				setError(null);
			} else {
				setError('Failed to fetch data');
			}
		} else {
			setError('Cluster Inactive');
		}
	} catch (error) {
		setError('Failed to fetch data');
	}
	setLoading(false);
}


		// User select specific date API call
		async function DBMemoryUtilizationListingDateFunc(
		pageNumber: any,
		size: any,
		sortingInfo: any,
		fromDateTime: any,
		toDateTime: any,
		jobFilterInfo: any,
		sector: any
	) {
		setLoading(true);
			try {
		const res = await getDBMemoryUtilizationDate(
			pageNumber.toString(),
			size.toString(),
			sortingInfo,
			jobFilterInfo,
			fromDateTime,
			fromDateTime,
			sector
		);
		if (res.success) {
			const addDollar = res.data.data.map((item: any) => {
				return {
					...item,
					gc_differential: `${
						item.gc_differential !== null && item.gc_differential !== undefined
							? `${item.gc_differential.toFixed(2)}`
							: '--'
					}`,

					project_name: `${
						item.project_name !== null && item.project_name !== undefined ? `${item.project_name}` : '--'
					}`
				};
			});
			setMemoryUtilizationList(addDollar);
			setPaginationData(res.data.page);
			setFilterData(res.data.filterOptions);
			// setFilterData(formatColumnFilterOptions(res.data.data));
			setFilterInfo(jobFilterInfo);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
			}else {
			setError('Failed to fetch data'); // Set the error state if API call fails
		}
	}
	 catch (error) {
		setError('Failed to fetch data'); // Set the error state if API call fails
	}
			setLoading(false);
		}

	// API
	useEffect(() => {
		setError(null);
		DBMemoryUtilizationListingFunc(page, pageSize, sortingInfo, fromDateTime, fromDateTime, [], sectorFilter);
	}, [sectorFilter]);

	useEffect(() => {
		setError(null);
		if (dateSelect != 0) {
			DBMemoryUtilizationListingDateFunc(
				"1",
				pageSize,
				sortingInfo,
				fromDateTime,
				fromDateTime,
				[],
				sectorFilter
			);
		}
	}, [dateSelect]);

	return (
		<div className='res-opt-table-wrap mem-utl'>
			<p className="databricks_title">Memory Efficiency</p>
			{error == 'Cluster Inactive' ? (
					// Display error message or handle error condition in UI
					<div
						style={{
							color: '#e5e5e5',
							marginLeft: '25%',
							padding: '10px',
							fontWeight: 500,
							gridColumn: '1 / -1'
						}}
					>
						Cluster is Inactive, it will take Max 2mins to start, Please try again later
					</div>
				) :error? (
					// Display error message or handle error condition in UI
					<div
						style={{
							color: '#e5e5e5',
							marginLeft: '25%',
							padding: '10px',
							fontWeight: 500,
							gridColumn: '1 / -1'
						}}
					>
						Error occurred while fetching data. Please try again.
					</div>
				) : (
			<Grid className="resourceOpt_tabledata">
				{!loading ? ( <>
					<CustomizedDataGrid
						tableColumns={getColumns()}
						tableData={MemoryUtilizationList}
						tableType="MemoryUtilization"
						defaultColumnNames={defaultColumnNames}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						filterDataOptions={filterData}
						onFilterCheck={handleFilter}
						currFilter={filterInfo}
						searchDataValues={searchDetails}
						onFilterSearch={handleSearch}
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={MemoryUtilizationList.length}
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isDateRangeFilter={false}
						isDisabled={false}
					/>
					{MemoryUtilizationList?.length ? <p className="disclaimer">Note: Double hyphens (--) indicate that the field has no data.</p> : ''}
					</>
				) : (
					<Loader />
				)}
			</Grid>
			)}
		</div>
	);
};

export default MemoryUtilization;
