import React from 'react';
import '../JobsInfrastructure/Infrastructure.css';
import { dashboardUrl } from '../../service/config';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AnalyticsVisualization from '../framework/analyticsVisualization';
import UnderDevelopment from '../../assets/under-construction.svg';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function CustomTabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	};
}

const JobsInfrastructure = () => {
	const [value, setValue] = React.useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	function getLocalStorageItem(key: string) {
		const value = localStorage.getItem(key);
		localStorage.setItem(key, value === 'drill-true' ? 'false' : 'true');
	}
	
	getLocalStorageItem('drill-down-schedules');
	getLocalStorageItem('drill-down-jobs');
	getLocalStorageItem('drill-down-execution');
	
	return (
		<div className="base-tab-infra">
			<Box sx={{ width: '100%' }}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs className="TabsControl" value={value} onChange={handleChange} aria-label="basic tabs example">
						<Tab  style={{ float: 'right' }} label="CONTROLM" {...a11yProps(0)} />
						<Tab  style={{ float: 'right' }} label="AUTOSYS" {...a11yProps(1)} />
					</Tabs>
				</Box>
				<CustomTabPanel value={value} index={0}>
				{dashboardUrl.controlMJobsInfrastructure !== undefined ? (
					<AnalyticsVisualization
						params={{
							dashboardUrl: dashboardUrl.controlMJobsInfrastructure,
							className: 'powerBiIframeContainer',
							otherParams: {
								name: 'PowerBI'
							},
							seamless: true
						}}
					/>
					
			) : (
				<div style={{ position: 'absolute', left: '45%', top: '50%', color: 'darkgray' }}>
					<img src={UnderDevelopment} />
					<p style={{ position: 'relative', right: '25%', margin: '0' }}>Dashboard under development!</p>
				</div>
			)}
				</CustomTabPanel>
				<CustomTabPanel value={value} index={1}>
					
				{dashboardUrl.autoSysJobsInfrastructure !== undefined ? (
					<AnalyticsVisualization
						params={{
							dashboardUrl: dashboardUrl.autoSysJobsInfrastructure,
							className: 'powerBiIframeContainer',
							otherParams: {
								name: 'PowerBI'
							},
							seamless: true
						}}
					/>
					
					) : (
						<div style={{ position: 'absolute', left: '45%', top: '50%', color: 'darkgray' }}>
							<img src={UnderDevelopment} />
							<p style={{ position: 'relative', right: '25%', margin: '0' }}>Dashboard under development!</p>
						</div>
					)}
				</CustomTabPanel>
			</Box>
		</div>
	);
};

export default JobsInfrastructure;
