import React, { useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import type { Column } from 'react-data-grid';
import moment from 'moment-timezone';
import { useSearchParams } from 'react-router-dom';
import Loader from '../Loader/loader';
import { CustomizedDataGrid } from '../customized_data_grid';
import {  getPrestoSummaryBatchDrilldown, getPrestoSummaryCompletedQueriesDrilldown, getPrestoSummaryFailedQueriesDrilldown, getPrestoSummaryQueriesExecutedDrilldown, getPrestoSummaryUserDrilldown, getPrestoSummaryLargestQueriesDrilldown } from '../../service/service';
import './summary';
import { ReactComponent as GoBackIcon } from '../../assets/healthicons_ui-menu-grid-outline.svg';

const SummaryDrilldown = ({ fromDateTime }: any) => {
	const messagesEndRef = useRef<null | HTMLDivElement>(null);

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
	};

	const [searchParams, setSearchParams] = useSearchParams();
	const hasDrillDown = searchParams.has('isDrillDowned');
	const drillDownStatus = hasDrillDown ? searchParams.get('drillDownStatus') : 'all';
	console.log(hasDrillDown, drillDownStatus, 'hasDrillDown test');

	interface Row {
		userId: string;
		queryDurationInSec: string;
		query: string;
		query_duration_in_sec: string;
		is_greater_than_10mins: string;
		QueryCreationDateTime: string;
		QueryEndDateTime: string;
		isGreaterThan10Mins: string;
		queryStartTime: string;
		queryEndTime: string;
	}

	function getColumnsForSummary(): Array<Column<Row>> {
		return [
			{
				key: 'userId',
				name: 'Batch Id/User Id',
				width: 150
			},
			{
				key: 'queryDurationInSec',
				name: 'Query Duration',
				formatter(props) {
                    let seconds = Number(props.row.queryDurationInSec);
                    if (seconds && seconds >= 60) {
                        seconds = Number(seconds);
						var h = Math.floor(seconds / 3600);
						var m = Math.floor(seconds % 3600 / 60);

						var hDisplay = h > 0 ? h + (h == 1 ? " hr " : " hrs ") : "";
						var mDisplay = m > 0 ? m + (m == 1 ? " min" : " mins") : "";
						return hDisplay + mDisplay; 
                    } else if (seconds && seconds < 60) {
						return seconds + ' Secs'
					}
                    return '0 Sec';
                },
				width: 150
			},
			{
				key: 'query',
				name: 'Query Details',
				formatter(props) {
					return <div title={props.row.query} className='has-ellipsis'>{props.row.query ? props.row.query : '--'}</div>
				},
				width: 250,
				sortable: false
			},

			{
				key: 'isGreaterThan10Mins',
				name: 'Greater Than 10 Mins?',
				width: 200
			},
			{
				key: 'queryStartTime',
				name: 'Query Start Time',
				width: 200,
				formatter(props) {
                    const startTime = props.row.queryStartTime;
                    if (startTime !== 'undefined') {
                        return moment(startTime).utc().format('MM-DD-YY HH:mm:ss');
                    }
                    return '';
                }
			},
			{
				key: 'queryEndTime',
				name: 'Query End Time',
				width: 200,
				formatter(props) {
                    const endTime = props.row.queryEndTime;
                    if (endTime !== 'undefined') {
                        return moment(endTime).utc().format('MM-DD-YY HH:mm:ss');
                    }
                    return '';
                }
			}
		];
	}

	function getColumnsForLargestQueries(): Array<Column<Row>> {
		return [
			{
				key: 'userId',
				name: 'Batch Id/User Id',
				width: 150
			},
			{
				key: 'queryDurationInSec',
				name: 'Query Duration',
				formatter(props) {
                    let seconds = Number(props.row.queryDurationInSec);
                    if (seconds && seconds >= 60) {
                        seconds = Number(seconds);
						var h = Math.floor(seconds / 3600);
						var m = Math.floor(seconds % 3600 / 60);

						var hDisplay = h > 0 ? h + (h == 1 ? " hr " : " hrs ") : "";
						var mDisplay = m > 0 ? m + (m == 1 ? " min" : " mins") : "";
						return hDisplay + mDisplay; 
                    } else if (seconds && seconds < 60) {
						return seconds + ' Secs'
					}
                    return '0 Sec';
                },
				width: 150
			},
			{
				key: 'query',
				name: 'Query Details',
				formatter(props) {
					return <div title={props.row.query} className='has-ellipsis'>{props.row.query ? props.row.query : '--'}</div>
				},
				width: 250
			},

			{
				key: 'isGreaterThan10Mins',
				name: 'Greater Than 10 Mins?',
				width: 200
			},
			{
				key: 'queryStartTime',
				name: 'Query Start Time',
				width: 200,
				formatter(props) {
                    const startTime = props.row.queryStartTime;
                    if (startTime !== 'undefined') {
                        return moment(startTime).utc().format('MM-DD-YY HH:mm:ss');
                    }
                    return '';
                }
			},
			{
				key: 'queryEndTime',
				name: 'Query End Time',
				width: 200,
				formatter(props) {
                    const endTime = props.row.queryEndTime;
                    if (endTime !== 'undefined') {
                        return moment(endTime).utc().format('MM-DD-YY HH:mm:ss');
                    }
                    return '';
                }
			}
		];
	}

	function getColumns(): Array<Column<Row>> {
		const drillDownStatus = hasDrillDown ? searchParams.get('drillDownStatus') : 'all';
		if (drillDownStatus === 'batchIdUsed' || drillDownStatus === 'queriesExecuted' || drillDownStatus === 'userIdsUsed' || drillDownStatus === 'failedQueries' || drillDownStatus === 'completedQueries') {
			return getColumnsForSummary();
		} else if (drillDownStatus === 'largestQueries') {
			return getColumnsForLargestQueries();
		}
		else {
			return []; // Return empty array when none of the conditions are met
		}
	}

	const defaultColumnNames = [
		'Batch Id/User Id',
		'Query Duration',
		'Query Details',
		'Greater Than 10 Mins?',
		'Query Start Time',
		'Query End Time'
	];

	const [details, setDetailsList] = useState<Row[]>([]);
	const [searchDetails, setFilterSearchInfo] = useState([]);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [filterInfo, setFilterInfo] = useState([]);
	const [filterData, setFilterData] = useState<Row[]>([]);
	const dataFetchedRef = useRef(false);
	const [onlyBatchId, setOnlyBatchId] = useState(true);
	const [onlyUserId, setOnlyUserId] = useState(true);
	const [onlyCompletedQueries, setonlyCompletedQueries] = useState(true);
	const [onlyFailedQueries, setonlyFailedQueries] = useState(true)
	const [onlyQueriesExecuted, setonlyQueriesExecuted] = useState(true)
	const [onlyLargestQueries, setonlyLargestQueries] = useState(true)
	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setLoading(true);
		const promise1 = PrestoListingFunc(
			value,
			pageSize,
			fromDateTime,
			onlyBatchId,
			onlyQueriesExecuted,
			onlyUserId,
			onlyFailedQueries,
			onlyCompletedQueries,
			onlyLargestQueries,
			sortingInfo
	
		);
		Promise.all([promise1]).then((values) => {
			// setLoading(false);
		});
	};
	const handlepageSizeChange = (event: any) => {
		setLoading(true);
		const promise1 = PrestoListingFunc(
			'1',
			event.target.value,
			fromDateTime,
			onlyBatchId,
			onlyQueriesExecuted,
			onlyUserId,
			onlyFailedQueries,
			onlyCompletedQueries,
			onlyLargestQueries,
			sortingInfo
		);
		Promise.all([promise1]).then((values) => {
			// setLoading(false);
		});
	};
	const handleSorting = (sortingInfo: any) => {
		// disable sorting for query column
		if (sortingInfo.sortBy == 'query') return false
		setLoading(true);
		const promise1 = PrestoListingFunc(
			page,
			pageSize,
			fromDateTime,
			onlyBatchId,
			onlyQueriesExecuted,
			onlyUserId,
			onlyFailedQueries,
			onlyCompletedQueries,
			onlyLargestQueries,
			sortingInfo
		);
		Promise.all([promise1]).then((values) => {
			// setLoading(false);
		});
	};
	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};
	const resetSearchParams = () => {
		if (searchParams.has('fromDate')) {
			searchParams.delete('status');
			searchParams.delete('scheduleDate');
			searchParams.delete('fromDate');
			setSearchParams(searchParams);
		}
	};
	const handleFilter = (filterInfo: any) => {
		resetSearchParams();
		setFilterInfo(filterInfo);
		setLoading(true);
		const promise1 = PrestoListingFunc(
			1,
			pageSize,
			fromDateTime,
			onlyBatchId,
			onlyQueriesExecuted,
			onlyUserId,
			onlyFailedQueries,
			onlyCompletedQueries,
			onlyLargestQueries,
			sortingInfo
		);
		Promise.all([promise1]).then((values) => {
			// setLoading(false);
		});
	};

	

	// Batch Id used
	async function PrestoBatchListingFunc(
		pageNumber: any,
		size: any,
		fromDateTime:any,
		onlyBatchId: any,
		sortingInfo: any
		
	) {
		setLoading(true);
		try {
			const res = await getPrestoSummaryBatchDrilldown(
				pageNumber.toString(),
				size.toString(),
				fromDateTime,
				onlyBatchId,
				sortingInfo
);
			if (res.success) {
				setTimeout(() => {
					if (hasDrillDown) {
						scrollToBottom();
					}
				}, 1000);
				console.log('result tableau', res);
				console.log('setDetail', res.data.data);
				setDetailsList(res.data.data);
				setPaginationData(res.data.page);
				setFilterData(res.data.filterOptions);
				// setFilterInfo(jobFilterInfo);
				setPage(pageNumber);
				setPageSize(size);
				setSortingInfo(sortingInfo);
				// setError('Failed to fetch data'); // Set the error state if API call fails
			} else {
				setError('Failed to fetch data'); // Set the error state if API call fails
			}
		} catch (error) {
			setError('Failed to fetch data'); // Set the error state if API call fails
		} finally {
			setLoading(false);
		}
	}

	// Queries Executed
	async function PrestoQueriesExecutedListingFunc(
		pageNumber: any,
		size: any,
		fromDateTime:any,
		onlyQueriesExecuted: any,
		sortingInfo: any
		
	) {
		setLoading(true);
		try {
			const res = await getPrestoSummaryQueriesExecutedDrilldown(
				pageNumber.toString(),
				size.toString(),
				fromDateTime,
				onlyQueriesExecuted,
				sortingInfo
);
			if (res.success) {
				setTimeout(() => {
					if (hasDrillDown) {
						scrollToBottom();
					}
				}, 1000);
				console.log('result tableau', res);
				console.log('setDetail', res.data.data);
				setDetailsList(res.data.data);
				setPaginationData(res.data.page);
				setFilterData(res.data.filterOptions);
				// setFilterInfo(jobFilterInfo);
				setPage(pageNumber);
				setPageSize(size);
				setSortingInfo(sortingInfo);
				// setError('Failed to fetch data'); // Set the error state if API call fails
			} else {
				setError('Failed to fetch data'); // Set the error state if API call fails
			}
		} catch (error) {
			setError('Failed to fetch data'); // Set the error state if API call fails
		} finally {
			setLoading(false);
		}
	}

	// Failed Queries
	async function PrestoFailedQueriesListingFunc(
		pageNumber: any,
		size: any,
		fromDateTime:any,
		onlyFailedQueries: any,
		sortingInfo: any
		
	) {
		setLoading(true);
		try {
			const res = await getPrestoSummaryFailedQueriesDrilldown(
				pageNumber.toString(),
				size.toString(),
				fromDateTime,
				onlyFailedQueries,
				sortingInfo
);
			if (res.success) {
				setTimeout(() => {
					if (hasDrillDown) {
						scrollToBottom();
					}
				}, 1000);
				console.log('result tableau', res);
				console.log('setDetail', res.data.data);
				setDetailsList(res.data.data);
				setPaginationData(res.data.page);
				setFilterData(res.data.filterOptions);
				// setFilterInfo(jobFilterInfo);
				setPage(pageNumber);
				setPageSize(size);
				setSortingInfo(sortingInfo);
				// setError('Failed to fetch data'); // Set the error state if API call fails
			} else {
				setError('Failed to fetch data'); // Set the error state if API call fails
			}
		} catch (error) {
			setError('Failed to fetch data'); // Set the error state if API call fails
		} finally {
			setLoading(false);
		}
	}

	// UserId used
	async function PrestoUserListingFunc(
		pageNumber: any,
		size: any,
		fromDateTime:any,
		onlyUserId: any,
		sortingInfo: any
		
	) {
		setLoading(true);
		try {
			const res = await getPrestoSummaryUserDrilldown(
				pageNumber.toString(),
				size.toString(),
				fromDateTime,
				onlyUserId,
				sortingInfo
);
			if (res.success) {
				setTimeout(() => {
					if (hasDrillDown) {
						scrollToBottom();
					}
				}, 1000);
				console.log('result tableau', res);
				console.log('setDetail', res.data.data);
				setDetailsList(res.data.data);
				setPaginationData(res.data.page);
				setFilterData(res.data.filterOptions);
				// setFilterInfo(jobFilterInfo);
				setPage(pageNumber);
				setPageSize(size);
				setSortingInfo(sortingInfo);
				// setError('Failed to fetch data'); // Set the error state if API call fails
			} else {
				setError('Failed to fetch data'); // Set the error state if API call fails
			}
		} catch (error) {
			setError('Failed to fetch data'); // Set the error state if API call fails
		} finally {
			setLoading(false);
		}
	}

	
	// Largest Queries
	async function PrestoLargestQueriesListingFunc(
		pageNumber: any,
		size: any,
		fromDateTime:any,
		onlyLargestQueries: any,
		sortingInfo: any
		
	) {
		setLoading(true);
		try {
			const res = await getPrestoSummaryLargestQueriesDrilldown(
				pageNumber.toString(),
				size.toString(),
				fromDateTime,
				onlyLargestQueries,
				sortingInfo
);
			if (res.success) {
				setTimeout(() => {
					if (hasDrillDown) {
						scrollToBottom();
					}
				}, 1000);
				console.log('result tableau', res);
				console.log('setDetail', res.data.data);
				setDetailsList(res.data.data);
				const pageData = res.data.page;
				pageData['totalPages'] = 1;
				pageData['totalElements'] = 20;
				console.log(pageData)
				setPaginationData(pageData);
				setFilterData(res.data.filterOptions);
				// setFilterInfo(jobFilterInfo);
				setPage(pageNumber);
				setPageSize(size);
				setSortingInfo(sortingInfo);
				// setError('Failed to fetch data'); // Set the error state if API call fails
			} else {
				setError('Failed to fetch data'); // Set the error state if API call fails
			}
		} catch (error) {
			setError('Failed to fetch data'); // Set the error state if API call fails
		} finally {
			setLoading(false);
		}
	}


	// Completed Queries
	async function PrestoCompletedQueriessListingFunc(
		pageNumber: any,
		size: any,
		fromDateTime:any,
		onlyCompletedQueries: any,
		sortingInfo: any
		
	) {
		setLoading(true);
		try {
			const res = await getPrestoSummaryCompletedQueriesDrilldown(
				pageNumber.toString(),
				size.toString(),
				fromDateTime,
				onlyCompletedQueries,
				sortingInfo
);
			if (res.success) {
				setTimeout(() => {
					if (hasDrillDown) {
						scrollToBottom();
					}
				}, 1000);
				console.log('result tableau', res);
				console.log('setDetail', res.data.data);
				setDetailsList(res.data.data);
				setPaginationData(res.data.page);
				setFilterData(res.data.filterOptions);
				// setFilterInfo(jobFilterInfo);
				setPage(pageNumber);
				setPageSize(size);
				setSortingInfo(sortingInfo);
				// setError('Failed to fetch data'); // Set the error state if API call fails
			} else {
				setError('Failed to fetch data'); // Set the error state if API call fails
			}
		} catch (error) {
			setError('Failed to fetch data'); // Set the error state if API call fails
		} finally {
			setLoading(false);
		}
	}
	const PrestoListingFunc = (page: any,
        pageSize: any,
        fromDateTime: any,
        onlyBatchId: any,
		onlyQueriesExecuted: any,
		onlyUserId: any,
		onlyFailedQueries: any,
		onlyCompletedQueries: any,
		onlyLargestQueries: any,
		sortingInfo: any
	) => {
			const selectedDateTime = localStorage.getItem('prestoSelectedDateTime')
			if (selectedDateTime) {
				fromDateTime = selectedDateTime;
			}
            if (drillDownStatus == 'batchIdUsed') {
                PrestoBatchListingFunc(page, pageSize, fromDateTime, onlyBatchId, sortingInfo)
                .catch(
                    (error) => {
                        setError(error); // Set error state when API call fails during initial load
                    }
                );
            } else if (drillDownStatus == 'queriesExecuted') {
                PrestoQueriesExecutedListingFunc(page, pageSize, fromDateTime, onlyQueriesExecuted, sortingInfo)
                .catch(
                    (error) => {
                        setError(error); // Set error state when API call fails during initial load
                    }
                );
				
            }
			else if (drillDownStatus == 'userIdsUsed') {
                PrestoUserListingFunc(page, pageSize, fromDateTime, onlyUserId, sortingInfo)
                .catch(
                    (error) => {
                        setError(error); // Set error state when API call fails during initial load
                    }
                );
				
            }
				else if (drillDownStatus == 'failedQueries') {
                PrestoFailedQueriesListingFunc(page, pageSize, fromDateTime, onlyFailedQueries, sortingInfo)
                .catch(
                    (error) => {
                        setError(error); // Set error state when API call fails during initial load
                    }
                );
				
            }

			else if (drillDownStatus == 'completedQueries') {
                PrestoCompletedQueriessListingFunc(page, pageSize, fromDateTime, onlyCompletedQueries, sortingInfo)
                .catch(
                    (error) => {
                        setError(error); // Set error state when API call fails during initial load
                    }
                );
				
            }

			else if (drillDownStatus == 'largestQueries') {
                PrestoLargestQueriesListingFunc(page, pageSize, fromDateTime, onlyLargestQueries, sortingInfo)
                .catch(
                    (error) => {
                        setError(error); // Set error state when API call fails during initial load
                    }
                );
				
            }
    }

	const [isMounted, setIsMounted] = useState(false);
	useEffect(() => {
		setIsMounted(true);
		return () => {
			setIsMounted(false);
		};
	}, []);
	useEffect(() => {
		if (!isMounted) return;

		PrestoListingFunc(
			page, pageSize, fromDateTime, onlyBatchId, onlyQueriesExecuted, onlyUserId, onlyFailedQueries, onlyCompletedQueries, onlyLargestQueries, sortingInfo);
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = false;
	}, [drillDownStatus, fromDateTime, isMounted])

	const handleGoBack = () => {
		window.location.href = '/presto/dashboard';
	}

	return (
		<div className="presto_tabledata">
		
			<div className="presto_drilldown_heading">
			{drillDownStatus === 'batchIdUsed' ? 'Batch Ids Used' : 
        		drillDownStatus === 'queriesExecuted' ? 'Queries Executed' :
        		drillDownStatus === 'userIdsUsed' ? 'User IDs Used' :
       		 	drillDownStatus === 'failedQueries' ? 'Failed Queries' :
        		drillDownStatus === 'completedQueries' ? 'Completed Queries' :
        		drillDownStatus === 'largestQueries' ? 'Time Taken By Top 20 Longest Queries' : 'Default Heading'}
				  <div className='prestoGoBack_button' onClick={handleGoBack}>
					<GoBackIcon className='back_icon' />
					Go Back
					</div>
				</div>
				
			{error ? (
				// Display error message or handle error condition in UI
				<div
					style={{
						color: '#e5e5e5',
						marginLeft: '25%',
						padding: '10px',
						fontWeight: 500,
						gridColumn: '1 / -1'
					}}
				>
					Error occurred while fetching data. Please try again.
				</div>
			) : (
				<Grid>
					{!loading ? (
						<CustomizedDataGrid
							tableColumns={getColumns()}
							tableData={details}
							tableType="Presto-details"
							defaultColumnNames={defaultColumnNames}
							onSorting={handleSorting}
							currSorting={sortingInfo}
							filterDataOptions={filterData}
							onFilterCheck={handleFilter}
							currFilter={filterInfo}
							searchDataValues={searchDetails}
							// onFilterSearch={handleSearch}
							page={page}
							pageSize={pageSize}
							paginationData={paginationData}
							listLength={details?.length}
							handlepageSizeChange={handlepageSizeChange}
							handlePageChange={handlePageChange}
							isDateRangeFilter={false}
							isDisabled={true}
						/>
					) : (
						<Loader />
					)}
				</Grid>
			)}
		</div>
	);
};

export default SummaryDrilldown;
