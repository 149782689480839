import React, { useEffect, useState } from 'react';
import { Chip, Grid } from '@mui/material';
import type { Column } from 'react-data-grid';
import { CustomizedDataGrid } from '../../customized_data_grid';
import Loader from '../../Loader/loader';
import './datagrowthObsRules.scss';
import { getTableauObsRules } from '../../../service/service';
import { Link, useParams, useLocation, useSearchParams } from 'react-router-dom';
import { getDefaultRuleData } from './datagrowthObsRulesUtils'; 

const datagrowthObsRules = () => {
	interface Row {
		instance: string;
		project: string;
		name: string;
		sizeInMB: string;
		contentType: string;
		lastUpdatedAt: string;
		lastRefreshedAt: string;
	}
	function getColumns(): Array<Column<Row>> {
		return getDefaultRuleData(paramStatus).column;
	}


	const [searchParams, setSearchParams] = useSearchParams();
	const ruleName = searchParams.get('ruleName');
	const [defaultColumnNames, setDefaultColumnNames] = useState<any[]>([]);	
	const [pageTitle, setPageTitle] = useState<string | null>(ruleName);
	const [paramStatus, setParamStatus] = useState(ruleName);

	const [extractFailureList, setExtractFailureList] = useState<Row[]>([]);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [filterInfo, setFilterInfo] = useState([]);
	const [filterData, setFilterData] = useState<Row[]>([]);
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);

	const updatePageTitleStatus = () => {
		const statusParam = searchParams.get('ruleName');
		console.log(statusParam);	
		setParamStatus(statusParam);
		const updatedData = getDefaultRuleData(statusParam);
		setPageTitle(updatedData.title);		
		setDefaultColumnNames(updatedData.columnNames);
	};


	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setLoading(true);
		const promise1 = ExtractFailureListingFunc(value, pageSize, sortingInfo, filterInfo).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

	const handlepageSizeChange = (event: any) => {
		setLoading(true);
		const promise1 = ExtractFailureListingFunc(page, event.target.value, sortingInfo, filterInfo).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

	const handleSorting = (sortingInfo: any) => {
		setLoading(true);
		const promise1 = ExtractFailureListingFunc(page, pageSize, sortingInfo, filterInfo).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

	const handleFilter = (filterInfo: any) => {
		// resetSearchParams();
		setFilterInfo(filterInfo);
		setLoading(true);
		const promise1 = ExtractFailureListingFunc(
			1,
			pageSize,
			sortingInfo,
			parseFilter(filterInfo)
		)
		.catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};
	
	async function ExtractFailureListingFunc(pageNumber: any, size: any, sortingInfo: any, filterInfo: any) {
		setLoading(true);

		try {				
			const res = await getTableauObsRules(pageNumber.toString(), size.toString(), sortingInfo, filterInfo, ruleName);
			if (res.success) {
				
				setExtractFailureList(res.data.observations);
				setPaginationData(res.data.page);
				setFilterData(res.data.filterOptions)
				setPage(pageNumber);
				setPageSize(size);
				setSortingInfo(sortingInfo);
				setFilterInfo(filterInfo);
			} else {
				setError('Failed to fetch data'); // Set the error state if API call fails
			}
		} catch (error) {
			setError('Failed to fetch data'); // Set the error state if API call fails
		}
		setLoading(false);
	}

	useEffect(() => {
		updatePageTitleStatus();
		ExtractFailureListingFunc(page, pageSize, sortingInfo, filterInfo).catch((error) => {
			setError(error); // Set error state when API call fails during initial load
		});
	}, [searchParams]);

	return (
		<div className="page-table extractFailure_tabledata">
			<div className="extract_failure_title">{pageTitle}</div>
            {error ? (
					// Display error message or handle error condition in UI
					<div
						style={{
							color: '#e5e5e5',
							marginLeft: '25%',
							padding: '10px',	
							fontWeight: 500,
							gridColumn: '1 / -1'
						}}
					>
						Error occurred while fetching data. Please try again.
					</div>
				) : (
				

			<Grid>
				{!loading ? (
					<CustomizedDataGrid
						tableColumns={getColumns()}
						tableData={extractFailureList}
						tableType={pageTitle}
						defaultColumnNames={defaultColumnNames}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						filterDataOptions={filterData}
						onFilterCheck={handleFilter}
						currFilter={filterInfo}
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={extractFailureList.length}
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isDateRangeFilter={false}
						isDisabled={false}
					/>
				) : (
					<Loader />
				)}
			</Grid>
            	)}
		</div>
	);
};

export default datagrowthObsRules;
