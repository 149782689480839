import PrivateRoute from 'utils/privateRoute';
import VVPowerBI from 'components/VerificationValidation/PowerBI';
import VVTableau from 'components/VerificationValidation/Tableau';

const VerificationValidationRoutes = [
	{
		key: 'ver_val_dash',
		route: '/verificationValidation/dashboard',
		component: (
			<PrivateRoute
				Component={VVTableau}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'ver_val_power_dash',
		route: '/verificationValidation/powerbi/dashboard',
		component: (
			<PrivateRoute
				Component={VVPowerBI}
				restrictedRoles={['Data Operations Admin', 'Data Operations User', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	}
];

export default VerificationValidationRoutes;
