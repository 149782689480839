
export const GDOList = () => {
  return [{name: 'GDO 39', status: '3/10', class: 'green'},
  {name: 'GDO 33', status: '7/10', class: 'red'},
  {name: 'GDO 48', status: '5/10', class: 'red'},
  {name: 'GDO 23', status: '4/10', class: 'green'},
  {name: 'GDO 2 ', status: '7/10', class: 'red'},
  {name: 'GDO 22', status: '1/10', class: 'red'},
  {name: 'GDO 49', status: '5/10', class: 'red'},
  {name: 'GDO 13', status: '2/10', class: 'green'},
  {name: 'GDO 36', status: '4/10', class: 'red'},
  {name: 'GDO 28', status: '3/10', class: 'red'},
  {name: 'GDO 43', status: '5/10', class: 'red'},
  {name: 'GDO 26', status: '7/10', class: 'red'},
  {name: 'GDO 5 ', status: '2/10', class: 'green'},
  {name: 'GDO 40', status: '5/10', class: 'red'},
  {name: 'GDO 29', status: '7/10', class: 'red'},
  {name: 'GDO 12', status: '3/10', class: 'red'},
  {name: 'GDO 18', status: '7/10', class: 'green'},
  {name: 'GDO 20', status: '5/10', class: 'red'},
  {name: 'GDO 19', status: '7/10', class: 'red'},
  {name: 'GDO 38', status: '6/10', class: 'red'},
  {name: 'GDO 31', status: '7/10', class: 'green'},
  {name: 'GDO 3 ', status: '2/10', class: 'red'},
  {name: 'GDO 41', status: '1/10', class: 'red'},
  {name: 'GDO 35', status: '7/10', class: 'green'},
  {name: 'GDO 21', status: '7/10', class: 'red'},
  {name: 'GDO 16', status: '2/10', class: 'red'},
  {name: 'GDO 42', status: '7/10', class: 'green'},
  {name: 'GDO 44', status: '3/10', class: 'red'},
  {name: 'GDO 30', status: '7/10', class: 'red'},
  {name: 'GDO 10', status: '5/10', class: 'green'},
  {name: 'GDO 8 ', status: '7/10', class: 'red'},
  {name: 'GDO 45', status: '7/10', class: 'red'},
  {name: 'GDO 11', status: '7/10', class: 'green'},
  {name: 'GDO 50', status: '7/10', class: 'red'},
  {name: 'GDO 1 ', status: '7/10', class: 'red'},
  {name: 'GDO 24', status: '7/10', class: 'green'},
  {name: 'GDO 14', status: '7/10', class: 'red'},
  {name: 'GDO 27', status: '7/10', class: 'red'},
  {name: 'GDO 37', status: '7/10', class: 'green'},
  {name: 'GDO 32', status: '7/10', class: 'red'},
  {name: 'GDO 46', status: '7/10', class: 'red'},
  {name: 'GDO 4 ', status: '7/10', class: 'green'},
  {name: 'GDO 25', status: '7/10', class: 'red'},
  {name: 'GDO 34', status: '7/10', class: 'red'},
  {name: 'GDO 17', status: '7/10', class: 'green'},
  {name: 'GDO 15', status: '7/10', class: 'red'},
  {name: 'GDO 6 ', status: '7/10', class: 'red'},
  {name: 'GDO 47', status: '7/10', class: 'red'},
  {name: 'GDO 9', status: '7/10', class: 'red'}]
}