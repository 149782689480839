import React, { useEffect, useState, useRef  } from 'react';
import { Grid, Chip } from '@mui/material';
import { getExtractTrendDetails, getExtractTrendDetailsSearch } from '../../../service/service';

import type { Column } from 'react-data-grid';
import { CustomizedDataGrid } from '../../customized_data_grid';
import './tableauJobs';
import Loader from '../../Loader/loader';
import { Link, useSearchParams } from 'react-router-dom';
import moment from 'moment-timezone';

export default function ExtractTrendDetails ({ fromDateTime, sector, trigger, serverFilter }: any)  {
	const messagesEndRef = useRef<null | HTMLDivElement>(null);

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
	}
	
	const [searchParams, setSearchParams] = useSearchParams();
	const hasDrillDown = searchParams.has('isDrillDowned');
	const drillDownStatus = hasDrillDown ? searchParams.get('extractTrendStatus') : 'all'
	console.log(hasDrillDown, drillDownStatus ,'hasDrillDown test');
	interface Row {
		jobId: string;
		jobName: string;
		jobStatus: string;
		jobType: string;
		contentId: string;
		contentType: string;
		contentName: string;
		projectName: string;
		averageExecution: string;
		lastExecutionDuration: string;
		scheduleId: string;
		scheduleName: string;
		scheduleType: number;
		schedulePriority: string;
		scheduleState: string;
		scheduleFrequency: string;
		nextRunAt: string;
	}
	const renderStatus = (status: any) => {
		switch (status) {
			case 'Success':
				return <Chip label={status} color="success" />;
			case 'Failed':
				return <Chip label={status} color="secondary" />;
			default:
				return <Chip label={status} style={{ backgroundColor: '#299BFF26', color: '#299BFF' }} />;
		}
	};

	function getColumnsForSchedule(): Array<Column<Row>> {
		return [
			{
				key: 'scheduleId',
				name: 'Schedule Id',
				minWidth: 150,
			},
			{
				key: 'scheduleName',
				name: 'Schedule Name',
				minWidth: 150
			},
			{
				key: 'scheduleType',
				name: 'Schedule Type',
				minWidth: 140
			},
			{
				key: 'contentId',
				name: 'Content Id',
				minWidth: 100
			},
			{
				key: 'contentType',
				name: 'Content Type',
				minWidth: 140
			},
			{
				key: 'contentName',
				name: 'Job Name',
				minWidth: 140
			},
			{
				key: 'schedulePriority',
				name: 'Schedule Priority',
				minWidth: 180
			},
			{
				key: 'scheduleState',
				name: 'Schedule State',
				minWidth: 150
			},
			{
				key: 'scheduleFrequency',
				name: 'Schedule Frequency',
				minWidth: 200
			},
			{
				key: 'nextRunAt',
				name: 'Schedule Time',
				minWidth: 160,
				formatter(props) {
					const startDate = props.row.nextRunAt;
					if (startDate) {
						return moment(startDate).local().format('MM-DD-YY HH:mm:ss');
					}
					return '';
				}
			}
		];
	}

	function getColumnsForJobs(): Array<Column<Row>> {
		return [
			{
				key: 'contentName',
				name: 'Job Name',
				formatter(props) {
					return (
						<Link to={`/tableau/jobdetails/${props.row.jobId}`} state={props.row} className="jobsNameList">
							{props.row.contentName}
						</Link>
					);
				},
				minWidth: 220,
				width: 220
			},
			{
				key: 'jobId',
				name: 'Job Id',
				minWidth: 150,
				width: 150
			},
			{
				key: 'jobStatus',
				name: 'Status',
				minWidth: 120,
				formatter(props) {
					const status = props.row.jobStatus;
					return renderStatus(status);
				}
			},
			{
				key: 'jobType',
				name: 'Type',
				minWidth: 120,
				width: 120
			},
			{
				key: 'contentType',
				name: 'Content Type',
				minWidth: 140,
				width: 140
			},
			{
				key: 'projectName',
				name: 'Project Name',
				minWidth: 120
			},
			{
				key: 'averageExecution',
				name: 'Average Execution Time',
				minWidth: 200
			},
			{
				key: 'lastExecutionDuration',
				name: 'Last Execution Duration',
				minWidth: 200
			}
		];
	}

	function getColumns(): Array<Column<Row>> {
		const drillDownStatus = hasDrillDown ? searchParams.get('extractTrendStatus') : 'all';
		if (drillDownStatus == 'active' || drillDownStatus == 'suspended') {
			return getColumnsForSchedule();
		} else {
			return getColumnsForJobs();
		}
	}

	const [DefaultColumnNames, setDefaultColumnNames] = useState<any[]>([]);	


	const dataFetchedRef = useRef(false);
	const [extractList, setextractList] = useState<Row[]>([]);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	// Date range
	const [filterData, setFilterData] = useState<Row[]>([]);
	const [searchDetails, setFilterSearchInfo] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [filterInfo, setFilterInfo] = useState([]);

	
	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setLoading(true);
		const promise1 = ExtractTrendDetailsListingFunc(
			value,
			pageSize,
			sortingInfo,
			fromDateTime,
			parseFilter(filterInfo),
            drillDownStatus,
			sector
		).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};


	const handlepageSizeChange = (event: any) => {
		setLoading(true);
		const promise1 = ExtractTrendDetailsListingFunc(
			page,
			event.target.value,
			sortingInfo,
			fromDateTime,
			parseFilter(filterInfo),
            drillDownStatus,
			sector
		).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

	
	const handleSorting = (sortingInfo: any) => {
		setLoading(true);
		const promise1 = ExtractTrendDetailsListingFunc(
			page,
			pageSize,
			sortingInfo,
			fromDateTime,
			parseFilter(filterInfo),
            drillDownStatus,
			sector
	
		).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

	const resetSearchParams = () => {
		if (searchParams.has('fromDate')) {
			searchParams.delete('status');
			searchParams.delete('scheduleDate');
			searchParams.delete('fromDate');
			setSearchParams(searchParams);
		}
		else if (hasDrillDown) {
			searchParams.delete('status');
			// searchParams.delete('isDrillDowned');
			setSearchParams(searchParams);
		}
	};

	const handleFilter = (filterInfo: any) => {
		console.log('handle filter')
		resetSearchParams();
		setFilterInfo(filterInfo);
		setLoading(true);
		const promise1 = ExtractTrendDetailsListingFunc(
			1,
			pageSize,
			sortingInfo,
			fromDateTime,
			parseFilter(filterInfo),
            drillDownStatus,
			sector
		).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

	async function ExtractTrendDetailsListingSearchFunc(
		searchQuery: any, searchField: any
	) {		
		try {	
	    
		const res = await getExtractTrendDetailsSearch(
			'1',
			pageSize,
			sortingInfo,
			fromDateTime,
			parseFilter(filterInfo),
            drillDownStatus,
			sector,
			serverFilter,
			searchQuery,
			searchField
		);
		if (res.success) {
			setFilterSearchInfo(res.data);
		}else {
			setError('Failed to fetch data'); // Set the error state if API call fails
		}
	} catch (error) {
		setError('Failed to fetch data'); // Set the error state if API call fails
	}
	}

	const handleSearch = (searchDetails: any, columnKey: any) => {
		console.log(searchDetails, columnKey)
		if(searchDetails?.length >= 3)
		ExtractTrendDetailsListingSearchFunc(searchDetails, columnKey);
	};

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

		async function ExtractTrendDetailsListingFunc(
		pageNumber: any,
		size: any,
		sortingInfo: any,
		fromDateTime: any,
		jobFilterInfo: any,
        drillDownStatus: any,
		sector: any
	) {		
		try {	
	    
		setLoading(true);
		const res = await getExtractTrendDetails(
			pageNumber.toString(),
			size.toString(),
			sortingInfo,
			jobFilterInfo,
            drillDownStatus,
			fromDateTime,
			sector,
			serverFilter
		);
		if (res.success) {
			setTimeout(() => {
				if (hasDrillDown) {
					scrollToBottom()
				}
			}, 1000);
			if(res.data.result !== null){
				setextractList(res.data.result);
			setPaginationData(res.data.page);
			setFilterData(res.data.filterOptions);
			setFilterInfo(jobFilterInfo);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);			
		}}else {
			setError('Failed to fetch data'); // Set the error state if API call fails
		}
	} catch (error) {
		setError('Failed to fetch data'); // Set the error state if API call fails
	}
	setLoading(false);
	}

	useEffect(() => {
		const drillDownStatus = hasDrillDown ? searchParams.get('extractTrendStatus') : 'all';
		const DefaultJobColumnNames = ['Job Name','Job Id','Status','Type','Content Type','Project Name','Average Execution Time','Last Execution Duration'];
		const DefaultScheduleColumnNames = ['Schedule Id', 'Schedule Name', 'Schedule Type','Content Id', 'Content Type', 'Job Name', 'Schedule Priority', 'Schedule State', 'Schedule Frequency', 'Schedule Time'];
		if (drillDownStatus == 'active' || drillDownStatus == 'suspended') {
			setDefaultColumnNames(DefaultScheduleColumnNames);
		} else {
			setDefaultColumnNames(DefaultJobColumnNames);
		}
		// Tableau drilldown filter changes
		const selectedDateTime = localStorage.getItem('selectedDateTime')
		if (selectedDateTime) {
			fromDateTime = selectedDateTime;
		}
		const selectedSector = localStorage.getItem('selectedSector')
		if (selectedSector) {
			sector = JSON.parse(selectedSector);
		}
		const selectedServer = localStorage.getItem('selectedServer');
		if (selectedServer) {
			serverFilter = selectedServer;
		}
		ExtractTrendDetailsListingFunc(page, pageSize, sortingInfo, fromDateTime, parseFilter(filterInfo), drillDownStatus, sector)
		.catch(
			(error) => {
				setError(error); // Set error state when API call fails during initial load
			}
		);
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;	
	}, [fromDateTime, drillDownStatus, trigger, serverFilter]);

	const extractStatuses: Record<string, string> = {
		all: '',
		success: 'Success',
		failed: 'Failed',
		active: 'Active',
		suspended: 'Suspended',
		longRunning: 'Long Running'
	};

	return (
		<div className="tableauJobs_tabledata">
			<div ref={messagesEndRef} />
			<div className="tableau_title">{drillDownStatus && extractStatuses[drillDownStatus]} Jobs</div>
				{error ? (
					// Display error message or handle error condition in UI
					<div
						style={{
							color: '#e5e5e5',
							marginLeft: '25%',
							padding: '10px',
							fontWeight: 500,
							gridColumn: '1 / -1'
						}}
					>
						Error occurred while fetching data. Please try again.
					</div>
				) : (
			<Grid className="governance_tabledata">
				{!loading ? (
					<CustomizedDataGrid
						tableColumns={getColumns()}
						tableData={extractList}
						tableType={drillDownStatus}
						defaultColumnNames={DefaultColumnNames}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						filterDataOptions={filterData}
						onFilterCheck={handleFilter}
						currFilter={filterInfo}
						searchDataValues={searchDetails}
						onFilterSearch={handleSearch}
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={extractList?.length}
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isDateRangeFilter={false}
						isDisabled={true}
					/>
					) : (
						<Loader />
					)}
			</Grid>
				
				)}
		</div>

	);
}

