import React, { useState, useEffect, useRef } from 'react';
import { Grid, Stack, Button, Menu, TextField, FormControlLabel } from '@mui/material';
import { dashboardUrl,databricksurl } from '../../../service/config';

import axios from 'axios';
import AnalyticsVisualization from '../../framework/analyticsVisualization';
import './Summary.scss';
import type { Column } from 'react-data-grid';
import { CustomizedDataGrid } from '../../customized_data_grid';
import { getDBSummary, getDBSummaryDate, getDatabricksSummaryChart, getDecodedToken, getDatabricksSectors, jobSearchForDBSummary } from '../../../service/service';
import moment from 'moment-timezone';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as DatePickerIcon } from '../../../assets/calendar-outline_white.svg';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Loader from '../../Loader/loader';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import useStyles from '../../jobs/jobs_styles';
import { ReactComponent as FilterIcon } from '../../../assets/ml_summary_filter.svg';

const Summary: React.FC = () => {
	interface Row {
		workspace_name: string;
		project_name: string;
		cluster_category: string;
		cluster_name: string;
		job_name: string;
		timestamp_state_start: string;
		timestamp_state_end: string;
		total_dbus: string;
		total_DBU_cost: string;
	}

	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'workspace_name',
				name: 'Workspace',
				formatter(props) {
					return <div title={props.row.workspace_name} className='has-ellipsis'>{props.row.workspace_name}</div>
				},
				minWidth: 160,
				width: 270
			},
			{
				key: 'cluster_name',
				name: 'Cluster Name',
				formatter(props) {
					return <div title={props.row.cluster_name} className='has-ellipsis'>{props.row.cluster_name ? props.row.cluster_name : '--'}</div>
				},
				minWidth: 140,
				width: 380
			},
			{
				key: 'cluster_category',
				name: 'Cluster Type',
				formatter(props) {
					return <div title={props.row.cluster_category}>{props.row.cluster_category ? props.row.cluster_category : '--'}</div>
				},
				minWidth: 135,
				width: 135
			},
			{
				key: 'total_dbus',
				name: 'Daily DBUs',
				formatter(props) {
					return <div title={props.row.total_dbus}>{props.row.total_dbus ? props.row.total_dbus : '--'}</div>
				},
				minWidth: 110
			},
			{
				key: 'project_name',
				name: 'Project Name',
				formatter(props) {
					return <div title={props.row.project_name} className='has-ellipsis'>{props.row.project_name ? props.row.project_name : '--'}</div>
				},
				width: 150,
				minWidth: 150
			},
			{
				key: 'job_name',
				name: 'Job Name',
				formatter(props) {
					return <div title={props.row.job_name} className='has-ellipsis'>{props.row.job_name ? props.row.job_name : '--'}</div>
				},
				minWidth: 100,
				width: 200
			},
			{
				key: 'timestamp_state_start',
				name: 'Start Time',
				minWidth: 110,
				formatter(props) {
					const startDate = props.row.timestamp_state_start;
					if (startDate) {
						return moment(startDate).utc().format('MM-DD-YY HH:mm:ss');
					}
					return '--';
				}
			},
			{
				key: 'timestamp_state_end',
				name: 'End Time',
				minWidth: 110,
				formatter(props) {
					const startDate = props.row.timestamp_state_end;
					if (startDate) {
						return moment(startDate).utc().format('MM-DD-YY HH:mm:ss');
					}
					return '--';
				}
			},
			// {
			// 	key: 'total_DBU_cost',
			// 	name: 'DBU Cost',
			// 	minWidth: 130
			// }
		];
	}

	const defaultColumnNames = [
		'Workspace',
		'Cluster Name',
		'Cluster Type',
		'Daily DBUs',
		'Project Name',
		'Job Name',
		'Start Time',
		'End Time',
		// 'DBU Cost'
	];

	const [summaryList, setSummaryList] = useState<Row[]>([]);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: 'project_name', sortDir: 'desc' });
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	// Date range
	const [filterData, setFilterData] = useState<Row[]>([]);
	const [searchDetails, setFilterSearchInfo] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [filterInfo, setFilterInfo] = useState([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const minJobExecDate = '2023-03-01';
	const dateFrom: string = searchParams.get('fromDate')?.substring(0, 10) as string;
	const dateTo: string = searchParams.get('toDate')?.substring(0, 10) as string;
	const startDt = moment(dateFrom).startOf('day').subtract(1, 'hours').format('YYYY-MM-DD HH:mm');
	const endDt = moment(dateTo).endOf('day').subtract(1, 'hours').format('YYYY-MM-DD HH:mm');
	const [fromDateTime, setFromDateTime] = useState<any>(
		searchParams.has('fromDate') ? startDt : null
	);
	// const [toDateTime, setToDateTime] = useState<any>(
	// 	searchParams.has('toDate') ? endDt : null
	// );
	// const [processDate, setProcessDate] = useState<any>()

	// sector filter
	const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
	// const userSector:any = "PBNE".toLowerCase();
	const classes = useStyles();
	const dataFetchedRef = useRef(false);
	const [sectorOptionsList, setSectorOptionsList] = useState<any[]>([]);
	const [filterMenu, setFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	const [itemsChecked, setItemsChecked] = useState(false);
	const [checkedList, setCheckedList] = useState<any[]>([]);
	const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);
	const [sharedValue, setSharedValue] = useState('');
	const updateSharedValue = (newValue: any) => {
		setSharedValue(newValue);
	};

	const filterClick = (event: any) => {
		event.preventDefault();
		setFilterMenu(
			filterMenu === null
				? {
					mouseX: parseInt(event.clientX) - 20,
					mouseY: parseInt(event.clientY) - 6
				}
				: null
		);
	};
	const handleClose = (event: any) => {
		setFilterMenu(null);
	};
	const selectAllCheck = (e: any) => {
		const { checked } = e.target;
		const collection: any[] = [];
		if (checked) setCheckedList(sectorOptionsList);
		else setCheckedList([]);
		setItemsChecked(checked);
	};
	const handleChange = (e: any) => {
		const { value, checked } = e.target;
		const newlyChecked = [];
		if (checked && e.target.value) {
			newlyChecked.push(e.target.value);
		}
		if (checked) {
			setCheckedList([...checkedList, ...newlyChecked]);
			if(sectorOptionsList.length === (checkedList.length + newlyChecked.length)){
				setItemsChecked(true);
			}
		} else {
			setCheckedList(checkedList.filter((item) => item !== value));
			setItemsChecked(false);
		}
	};

	const handleOkClick = (event: any) => {
		localStorage.setItem('ds-summary-sector-filter', JSON.stringify(checkedList));
		if (checkedList.length > 0) {
			setCurrSectorFilter(checkedList);
		} else {
			setCurrSectorFilter([]);
		}
		
		if (sectorOptionsList.length == checkedList.length) {
			setCheckedList([])
			localStorage.setItem('ds-summary-sector-filter', JSON.stringify([]));
		} else {
			localStorage.setItem('ds-summary-sector-filter', JSON.stringify(checkedList));
		}
		
		DBSummaryListingFunc(page, pageSize, sortingInfo, fromDateTime, fromDateTime, parseFilter(filterInfo), checkedList);
		// updateSharedValue({ sectorFilter: checkedList });
		handleClose(event);
	};
	async function fetchSectorOptionsData() {
		const sectorRes = await getDatabricksSectors('1', '100', '');
		if (sectorRes.success) {
			setSectorOptionsList(
				sectorRes.data.sector.map((x: any) => {
					if (x.name === 'S&T') {
						return 'SNT';
					}
					else if (x.name === 'Europe') {
						return 'EUROPE';
					}
					else {
						return x.name;
					}
				})
			);
		}
	}

	const handlePageChange = (event: any, value: number) => {
		setLoading(true);
		let nextPage = value.toString(); 
		if(event.target.dataset.testid == "LastPageIcon" || event.target.dataset.testid == "FirstPageIcon"){
			nextPage = value.toString(); 
		}
		else if(event.target.dataset.testid == "NavigateBeforeIcon"){
			nextPage = (Number(page) - 1).toString();
		}
		else if (nextPage === event.target.textContent) { 
			nextPage = event.target.textContent;
		} else {
			nextPage = (Number(page) + 1).toString();
		}
		const promise1 = DBSummaryListingFunc(
			parseInt(nextPage),
			pageSize,
			sortingInfo,
			fromDateTime,
			fromDateTime,
			parseFilter(filterInfo),
			checkedList
		).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

	const handlepageSizeChange = (event: any) => {
		setLoading(true);
		const promise1 = DBSummaryListingFunc(
			'1',
			event.target.value,
			sortingInfo,
			fromDateTime,
			fromDateTime,
			parseFilter(filterInfo),
			checkedList
		).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

	const handleSorting = (sortingInfo: any) => {
		setLoading(true);
		const promise1 = DBSummaryListingFunc(
			page,
			pageSize,
			sortingInfo,
			fromDateTime,
			fromDateTime,
			parseFilter(filterInfo),
			checkedList
		).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

	// [Date Range Menu]
	const [dateRangeMenuOpen, setDateRangeMenuOpen] = React.useState<null | HTMLElement>(null);

	const handleDateClick = (event: React.MouseEvent<HTMLElement>) => {
		setDateRangeMenuOpen(event.currentTarget);
	};

	const handleDateRangeMenu = () => {
		setDateRangeMenuOpen(null);
	};

	const open = Boolean(dateRangeMenuOpen);

	const resetSearchParams = () => {
		if (searchParams.has('fromDate') || searchParams.has('fromDate')) {
			searchParams.delete('status');
			searchParams.delete('scheduleDate');
			searchParams.delete('fromDate');
			searchParams.delete('fromDate');
			setSearchParams(searchParams);
		}
	};

	const handleDateTimeChange = (field: any, dateTime: any) => {
		if (field === 'fromDateTime') setFromDateTime(dateTime);
		localStorage.setItem('processedDate', moment(dateTime).format('YYYY-MM-DD'));
		applyDateTimeRange(dateTime);
	};
	const applyDateTimeRange = (fromDateTime: any) => {
		resetSearchParams();
		setLoading(true);
		const promise1 = DBSummaryListingDateFunc(
			"1",
			pageSize,
			sortingInfo,
			fromDateTime,
			fromDateTime,
			parseFilter(filterInfo),
			checkedList
		).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		setDateRangeMenuOpen(null);
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};


	const handleFilter = (filterInfo: any) => {
		resetSearchParams();
		setFilterInfo(filterInfo);
		setLoading(true);
		const promise1 = DBSummaryListingFunc(
			1,
			pageSize,
			sortingInfo,
			fromDateTime,
			fromDateTime,
			parseFilter(filterInfo),
			checkedList
		).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

	async function jobSearchForDBSummaryFunc(searchQuery: any, columnKey: any) {
		const res = await jobSearchForDBSummary(
			1,
			pageSize,
			sortingInfo,
			fromDateTime,
			parseFilter(filterInfo),
			checkedList,
			searchQuery,
			columnKey
			);
		if (res.success) {
			setFilterSearchInfo(res.data);
		}
	}

	const handleSearch = (searchDetails: any, columnKey: any) => {
		console.log(searchDetails, columnKey)
		if(searchDetails?.length >= 3)
		jobSearchForDBSummaryFunc(searchDetails, columnKey);
	};
	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

	
	const numberFormat = (data: number) => {
		return parseFloat(data.toFixed(2)).toLocaleString();
	}

	// initial load API call
	async function DBSummaryListingFunc(
		pageNumber: any,
		size: any,
		sortingInfo: any,
		fromDateTime: any,
		toDateTime: any,
		jobFilterInfo: any,
		sector: any
	) {
		setLoading(true);
		try {
			// Check if there's a stored sector filter in localStorage
			let storedSectorFilter = localStorage.getItem('ds-summary-sector-filter')
				? JSON.parse(localStorage.getItem('ds-summary-sector-filter') ?? '')
				: null;
			if (storedSectorFilter) {
				sector = storedSectorFilter;
			}
	
			// Make a call to Databricks
			const databricksresponse = await axios.get(`${databricksurl}`);
	
			console.log('Databricks Call initiated', databricksresponse);
	
			if (databricksresponse && databricksresponse.status) {
				// Fetch the summary data
				const res = await getDBSummary(
					pageNumber.toString(),
					size.toString(),
					sortingInfo,
					jobFilterInfo,
					fromDateTime,
					fromDateTime,
					sector
				);
	
				if (res.success) {
					// Format the response data
					const addDollar = res?.data?.result?.map((item: any) => ({
						...item,
						total_DBU_cost: item.total_DBU_cost !== null && item.total_DBU_cost !== undefined
							? `${item.total_DBU_cost.toFixed(4)}`
							: '0.00',
						total_dbus: item.total_dbus !== null && item.total_dbus !== undefined
							? `${item.total_dbus.toFixed(2)}`
							: '0.00'
					}));
	
					// Update the state with the formatted data
					setSummaryList(addDollar);
	
					const tempPageData = res.data.page;
					tempPageData['totalElements'] = numberFormat(res.data.page['totalElements']);
					console.log(tempPageData);
	
					setPaginationData(tempPageData);
					setFilterData(res.data.filterOptions);
				setFilterInfo(jobFilterInfo);
				setPage(pageNumber);
				setPageSize(size);
				setSortingInfo(sortingInfo);
				setFromDateTime(localStorage.getItem("processedDate"));
				setError(null);
			} else {
				setError('Failed to fetch data');
				}
			} else {
				setError('Cluster Inactive');
			}
		} catch (error) {
			setError('Failed to fetch data');
		}
		setLoading(false);
	}
	                        

	// When user select the specific date from datepicker API call
	async function DBSummaryListingDateFunc(
		pageNumber: any,
		size: any,
		sortingInfo: any,
		fromDateTime: any,
		toDateTime: any,
		jobFilterInfo: any,
		sector: any
	) {
		setLoading(true);
		try {
			// const sectorValue = userSector === "all" ? sector : getDecodedToken()?.sector.name
			let storedSectorFilter = localStorage.getItem('ds-summary-sector-filter')
				? JSON.parse(localStorage.getItem('ds-summary-sector-filter') ?? '')
				: null;
			if (storedSectorFilter) {
				sector = storedSectorFilter;
			}
			const res = await getDBSummaryDate(
				pageNumber.toString(),
				size.toString(),
				sortingInfo,
				jobFilterInfo,
				fromDateTime,
				fromDateTime,
				sector
			);
			if (res.success) {
				const addDollar = res?.data?.result?.map((item: any) => ({
					...item,
					total_DBU_cost: `${item.total_DBU_cost !== null && item.total_DBU_cost !== undefined
						? `${item.total_DBU_cost.toFixed(4)}`
						: 0.00
						}`,
					total_dbus: `${item.total_dbus !== null && item.total_dbus !== undefined ? `${item.total_dbus.toFixed(2)}` : 0.00
						}`
				}));
				setSummaryList(addDollar);
				const tempPageData = res.data.page;
				tempPageData['totalElements'] = numberFormat(res.data.page['totalElements']);
				setPaginationData(tempPageData);
				setFilterData(res.data.filterOptions);
				// const filterOptionRes: any = {'cluster_name': [], 'project_name': [], 'workspace_name': []};
				// if (Object.keys(res.data.filterOptions).length) {
				// 	Object.keys(res.data.filterOptions).forEach((key: any) => {
				// 		filterOptionRes[key] = res.data.filterOptions[key].map((d: any) => d[key]);
				// 	});
				// }
				// console.log(filterOptionRes)
				// setFilterData(filterOptionRes);
				setFilterInfo(jobFilterInfo);
				setPage(pageNumber);
				setPageSize(size);
				setSortingInfo(sortingInfo);
				setError(null);
			} else {
				setLoading(false);
				setError('Failed to fetch data'); // Set the error state if API call fails
			}
		} catch (error) {
			setLoading(false);
			setError('Failed to fetch data'); // Set the error state if API call fails
		}
		setLoading(false);
	}

	const callSummaryAPI = () => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;

		if (userSector === 'all') fetchSectorOptionsData();
		let storedSectorFilter = localStorage.getItem('ds-summary-sector-filter')
			? JSON.parse(localStorage.getItem('ds-summary-sector-filter') ?? '')
			: null;
		if (storedSectorFilter) {
			setCheckedList(storedSectorFilter);
			setCurrSectorFilter(storedSectorFilter);
		}
		DBSummaryListingFunc(
			page,
			pageSize,
			sortingInfo,
			fromDateTime,
			fromDateTime,
			parseFilter(filterInfo),
			checkedList
		).catch((error) => {
			setError(error); // Set error state when API call fails during initial load
		});
	}

	useEffect(() => {
		fetchDate();
		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/databricks/summary/dashboard') {
			if (activeId) {
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('databricks-jobs');
				document.body.classList.add('datefilter-align-sector');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				activeId.classList.remove('jobs-list-table');
				activeId.classList.remove('jobs-list');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.remove('jobs-exe-datas');
			}
		}
	}, []);

	async function fetchDate() {
		setLoading(true);
		const res = await getDatabricksSummaryChart();
		if (res.success) {
			if (!localStorage.getItem('processedDate')) {
				setFromDateTime(moment(res.data.processedDate));
				localStorage.setItem('processedDate',res.data.processedDate);
				console.log("data",res.data.processedDate)
			} else {
				setFromDateTime(moment(localStorage.getItem('processedDate')));
			}
				// call summary API after setting processedDate 
				callSummaryAPI();
		}
	}

	let fromDate = moment(fromDateTime).format('YYYY-MM-DD');
	let toDate = moment(fromDateTime).format('YYYY-MM-DD');

	return (
		<div className="dashboard-panel job-schedules-wrap">
			<div className="job-exexutions-dateFilter-div">
				{/* [Date Range Menu] */}
				<div style={{ display: 'flex', alignItems: 'center', padding: '-12px', marginRight: '-22px' }}>
					{/* condition for default Today  */}
					{/* {moment(fromDateTime).format('DD MM YYYY') ===
						moment().startOf('day').subtract(1, 'hours').format('DD MM YYYY') &&
						 <div style={{ marginRight: '10px' }}>Today</div>} */}
					{
						<LocalizationProvider className="outerDateContainer" dateAdapter={AdapterMoment}>
							<DatePicker
								showToolbar={true}
								components={{
									OpenPickerIcon: DatePickerIcon
								}}
								 maxDate={new Date()} 
								PopperProps={{ placement: 'auto' }}
								className="dateFilterTD"
								value={moment(fromDateTime).tz('America/New_York')}
								// value={null}
								onChange={(dateTime) => {
									handleDateTimeChange('fromDateTime', dateTime);
								}}
								renderInput={(params: any) => <TextField name="startDateTime" {...params} />}
							/>
						</LocalizationProvider>
					}
				</div>

				{/* Code for sector filter */}
				<Stack>
					{userSector === 'all' ? (
						<FilterIcon onClick={filterClick} className="sector-filter1" />
					) : (
						<div className="separeat_sector">Sector: {getDecodedToken()?.sector?.name}</div>
					)}
					<Popover
						open={filterMenu !== null}
						onClose={handleClose}
						className="filterContainer"
						anchorReference="anchorPosition"
						anchorPosition={filterMenu !== null ? { top: filterMenu.mouseY, left: filterMenu.mouseX } : undefined}
					>
						<div className="text-filter-row">
							<div className="selectAll-sec">
								<MenuItem className="selectAll">
									<FormControlLabel
										className={classes.checkbox}
										label="All"
										control={
											<Checkbox
												color="success"
												value="all"
												checked={itemsChecked}
												onChange={(e) => {
													selectAllCheck(e);
												}}
											/>
										}
									/>
								</MenuItem>
								<div className="checkbox-sec">
									{sectorOptionsList &&
										sectorOptionsList.map((filter: any) => (
											<MenuItem key={filter}>
												<FormControlLabel
													className={classes.checkbox}
													label={filter === true ? 'Yes' : filter === false ? 'No' : filter}
													control={
														<Checkbox
															color="success"
															value={filter}
															checked={
																(filter === true && checkedList.includes('true')) ||
																(filter === false && checkedList.includes('false')) ||
																checkedList.includes(filter)
															}
															onChange={(e) => {
																handleChange(e);
															}}
														/>
													}
												/>
											</MenuItem>
										))}
								</div>
							</div>
							<div className="buttonSec">
								<Button variant="contained" className={classes.cancelBtn} onClick={handleClose}>
									cancel
								</Button>
								<Button variant="contained" className={classes.okBtn} onClick={handleOkClick}>
									ok
								</Button>
							</div>
						</div>
					</Popover>
				</Stack>
			</div>
			{/* <div className="dashboard-panel"> */}
			{location.pathname === '/databricks/summary/dashboard' && (
				// Grafana
				<>
					<Stack>
						<AnalyticsVisualization
							params={{
								dashboardUrl: dashboardUrl.databricksSummary,
								className: 'dataBricksSummaryIframeContainer',
								otherParams: {
									'var-fromDate': fromDate,
									'var-toDate': toDate,
									'var-sector': currSectorFilter
								},
								seamless: true
							}}
						/>
					</Stack>
				</>
			)}

			<div className='summary-table-wrap'>
				<p className="databricks_title">Workspace Details</p>
				{error == 'Cluster Inactive' ? (
					// Display error message or handle error condition in UI
					<div
						style={{
							color: '#e5e5e5',
							marginLeft: '25%',
							padding: '10px',
							fontWeight: 500,
							gridColumn: '1 / -1'
						}}
					>
						Cluster is Inactive, it will take Max 2mins to start, Please try again later
					</div>
				) :error? (
					// Display error message or handle error condition in UI
					<div
						style={{
							color: '#e5e5e5',
							marginLeft: '25%',
							padding: '10px',
							fontWeight: 500,
							gridColumn: '1 / -1'
						}}
					>
						Error occurred while fetching data. Please try again.
					</div>
				) : (
					<Grid className="databricks_summary_tabledata">
						{!loading ? (<>
							<CustomizedDataGrid
								tableColumns={getColumns()}
								tableData={summaryList || []}
								tableType="DBSummary"
								defaultColumnNames={defaultColumnNames}
								onSorting={handleSorting}
								currSorting={sortingInfo}
								filterDataOptions={filterData}
								onFilterCheck={handleFilter}
								currFilter={filterInfo}
								searchDataValues={searchDetails}
								onFilterSearch={handleSearch}
								page={page}
								pageSize={pageSize}
								paginationData={paginationData}
								listLength={summaryList?.length}
								handlepageSizeChange={handlepageSizeChange}
								handlePageChange={handlePageChange}
								isDateRangeFilter={true}
								isDisabled={false}
							/>
							{summaryList?.length ? <p className="disclaimer">Note: Double hyphens (--) indicate that the field has no data.</p> : ''}
							</>
						) : (
							<Loader />
						)}
					</Grid>
				)}
			</div>
		</div>
	);
};

export default Summary;
