import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
	jobIcon: {
		paddingRight: '0px',
		width: '35px',
		display: 'inline-block',
		float: 'left'
	},
	jobScheduleIcon: {
		paddingTop: '0',
		width: '40px',
		marginLeft: '-10px',
		marginTop: '-4px'
	},
	userIconDiv: {
		position: 'absolute',
		bottom: '0px',
		display: 'block !important',
		paddingTop: '20px',
		paddingBottom: '20px'
	},
	activeUserIcons: {
		position: 'absolute',
		bottom: '0px',
		display: 'block !important',
		width: '100%',
		paddingTop: '20px',
		paddingBottom: '20px'
	},
	notificationImg: {
		height: '15px',
		width: '15px'
	},
	logoutImg: {
		height: '30px',
		width: '30px'
	},
	calandarSchedules: {
		colorScheme: 'dark',
		"& input[type='date']": {
			color: '#AAAAAA',
			border: 'none !important',
			borderRadius: '4px',
			backgroundColor: '#71758F15 !important'
		},
		'input[type="date"]::before': {
			content: '1F4C5',
			backgroundPosition: '50px center !important',
			backgroundColor: '#71758F15 !important'
		}
	},
	scheduleCal: {
		float: 'right',
		display: 'flex'
	},
	timeSection: {
		float: 'right',
		color: '#71758F'
	},
	clock: {
		marginTop: '12px',
		marginRight: '7px',
		marginBottom: '-4px',
		width: '20px'
	},
	timeText: {
		color: '#71758F !important',
		fontSize: '16px !important',
		marginRight: '12px'
	},
	ibpMonitoring: {
		width: '20px',
		display: 'inline-block',
		marginTop: '8px',
		marginRight: '5px',
		verticalAlign: 'text-bottom'
	},
	ibpCost: {
		width: '20px',
		display: 'inline-block',
		marginTop: '2px',
		marginRight: '5px',
		verticalAlign: 'text-top'
	},
	ibpPlatform: {
		width: '40px',
		display: 'inline-block',
		marginRight: '5px',
		verticalAlign: 'bottom'
	},
	ibpDataQuality: {
		width: '25px',
		display: 'inline-block',
		marginRight: '5px',
		verticalAlign: 'text-bottom'		
	},
	serviceRequestIcon: {
		width: '25px',
		display: 'inline-block',
		marginRight: '5px',
		verticalAlign: 'text-bottom'
	},
	nextArrowIcon: {
		display: 'inline-block'
	}
});

export default useStyles;
