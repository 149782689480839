import moment from 'moment-timezone';
import { Chip } from '@mui/material';
export const getDefaultRuleData = (statusParam: string | null) => {
	let title = 'Long Running Clusters',
		status = {} as any,
		column: any = [],
		columnNames = [];
		function MillisecondsToMinutes(count:any) {
			// Convert milliseconds to hours and minutes
			const hours = Math.floor(count / (1000 * 60 * 60));
			const minutes = Math.round((count % (1000 * 60 * 60)) / (1000 * 60));
			// Format the result
			const formattedResult = `${hours}H ${minutes}M`;
			return formattedResult;
		}
		
	function CountRoundUp(count:any) {
		const cost1 = parseFloat(count); 
		const roundedCluster= parseFloat(cost1.toFixed(2));
		return roundedCluster;
	}
	  
	const renderStatus = (status: any) => {
		switch (status) {
			case 'SUCCESS':
			case 'Succeeded':
				return <Chip label={status} color="success" />;
			case 'Failed':
			case 'Failure':
			// case 'Error':
				return <Chip label={status} color="secondary" />;
			case 'NOT STARTED':
			case 'Not Started':
				return <Chip label={status} style={{ backgroundColor: '#1B1E35CC', color: '#71758F' }} />;
			default:
				return <Chip label={status} style={{ backgroundColor: '#299BFF26', color: '#299BFF' }} />;
		}
	};
	switch (statusParam) {
		case 'Long Running Clusters':
			title = 'Long Running Clusters';
			status = 'Long Running Clusters';
			columnNames = [
				'Workspace',
				'Project Name',
				'Cluster Type',
				'Cluster Id',
				'Cluster Name',
				'Status',
				'Start Time',
				'End Time',
				'Avg Spark Run Time',
				'Current Run Duration',
				'Business Sector',
				'Billing Information',
				'Business Contact',
				'Sector'
			];
			column = [
				{ key: 'workspace_name', name: 'Workspace', minWidth: 150 },
				{ key: 'project_name', name: 'Project Name', minWidth: 150 },
				{ key: 'cluster_type', name: 'Cluster Type', minWidth: 150, maxWidth: 150 },
				{ key: 'cluster_id', name: 'Cluster Id', minWidth: 150 },
				{ key: 'name', name: 'Cluster Name', minWidth: 150, maxWidth: 300 },
				{
					key: 'terminal_state',
					name: 'Status',
					minWidth: 220,
					formatter(props: any) {
						const terminal_state = props.row.terminal_state;
						return renderStatus(terminal_state);
					}
				},
				{
					key: 'start_time',
					name: 'Start Time',
					minWidth: 100,
					formatter(props: any) {
						const start_time = props.row.start_time;
						if (start_time !== 'undefined') {
							return moment(start_time).format('MM-DD-YY HH:mm:ss');
						}
						return '';
					}
				},
				{
					key: 'end_time',
					name: 'End Time',
					minWidth: 100,
					formatter(props: any) {
						const end_time = props.row.end_time;
						if (end_time !== 'undefined') {
							return moment(end_time).format('MM-DD-YY HH:mm:ss');
						}
						return '';
					}
				},
				{
					key: 'avgRunTime',
					name: 'Avg Spark Run Time',
					minWidth: 200,
					formatter(props: any) {
						const avgRunTime = props.row.avgRunTime;
						return MillisecondsToMinutes(avgRunTime);
					}
				},
				{
					key: 'last_run_time',
					name: 'Current Run Duration',
					minWidth: 200,
					formatter(props: any) {
						const last_run_time = props.row.last_run_time;
						return MillisecondsToMinutes(last_run_time);
					}
				},
				{
					key: 'business_sector',
					name: 'Business Sector',
					minWidth: 150
				},
				{ key: 'billing_info', name: 'Billing Information', minWidth: 150, maxWidth: 150 },
				{ key: 'business_contact', name: 'Business Contact', minWidth: 150 },
				{ key: 'sector_name', name: 'Sector', minWidth: 150 }
			];
			break;
		case 'Job Failures':
			title = 'Job Failures';
			status = 'Job Failures';
			columnNames = [
				'Workspace',
				'ClusterType',
				'Cluster Name',
				'Job Name',
				'Status',
				'Count Of Times Failed in Past 6 Executions',
				'Count Of Failures in Past 7 Days',
				'Business Sector',
				'Billing Information',
				'Business Contact',
				'Sector'
			];
			column = [
				{ key: 'workspace_name', name: 'Workspace', minWidth: 150 },
				{ key: 'cluster_type', name: 'ClusterType', minWidth: 150, maxWidth: 200 },
				{ key: 'cluster_name', name: 'Cluster Name', minWidth: 150 },
				{ key: 'name', name: 'Job Name', minWidth: 100, maxWidth: 150,
				formatter(props: any) {
                    const job_name = props.row.name;
                    if (job_name !== null) {
                        return job_name ;
                    }
                    return 'Untitled';
                }  },
				{
					key: 'terminal_state',
					name: 'Status',
					minWidth: 220,
					formatter(props: any) {
						const terminal_state = props.row.terminal_state;
						return renderStatus(terminal_state);
					}
				},
				{ key: 'failures_last_six_executions', name: 'Count Of Times Failed in Past 6 Executions', minWidth: 300 },
				{ key: 'failures_last_seven_days', name: 'Count Of Failures in Past 7 Days', minWidth: 300 },
				{ key: 'business_sector', name: 'Business Sector', minWidth: 150 },
				{ key: 'billing_info', name: 'Billing Information', minWidth: 150, maxWidth: 200 },
				{ key: 'business_contact', name: 'Business Contact', minWidth: 150 },
				{ key: 'sector_name', name: 'Sector', minWidth: 150 }
			];
			break;
		case 'Long Running Jobs':
			title = 'Long Running Jobs';
			status = 'Long Running Jobs';
			columnNames = [
				'Workspace',
				'Project Name',
				'Cluster Type',
				'Cluster Id',
				'Cluster Name',
				'Job Name',
				'Start Time',
				'End Time',
				'Current Run Duration',
				'Status',
				'Business Sector',
				'Billing Information',
				'Business Contact',
				'Sector'
			];
			column = [
				{ key: 'Workspace', name: 'Workspace', minWidth: 150 },
				{ key: 'project_name', name: 'Project Name', minWidth: 150 },
				{ key: 'cluster_type', name: 'Cluster Type', minWidth: 150 },
				{ key: 'cluster_id', name: 'Cluster Id', minWidth: 150 },
				{ key: 'cluster_name', name: 'Cluster Name', minWidth: 150, maxWidth: 150 },
				{ key: 'name', name: 'Job Name', minWidth: 100, maxWidth: 100,
				formatter(props: any) {
                    const job_name = props.row.name;
                    if (job_name !== null) {
                        return job_name ;
                    }
                    return 'Untitled';
                }  },
				{
					key: 'start_time',
					name: 'Start Time',
					minWidth: 100,
					formatter(props: any) {
						const start_time = props.row.start_time;
						if (start_time !== 'undefined') {
							return moment(start_time).format('MM-DD-YY HH:mm:ss');
						}
						return '';
					}
				},
				{
					key: 'end_time',
					name: 'End Time',
					minWidth: 100,
					formatter(props: any) {
						const end_time = props.row.end_time;
						if (end_time !== 'undefined') {
							return moment(end_time).format('MM-DD-YY HH:mm:ss');
						}
						return '';
					}
				},
				{
					key: 'count',
					name: 'Current Run Duration',
					minWidth: 250,
					formatter(props: any) {
						const duration = props.row.count;
						return MillisecondsToMinutes(duration);
					}
				},
				{
					key: 'terminal_state',
					name: 'Status',
					minWidth: 150,
					formatter(props: any) {
						const status = props.row.terminal_state;
						return renderStatus(status);
					}
				},
				{ key: 'business_sector', name: 'Business Sector', minWidth: 150 },
				{ key: 'billing_info', name: 'Billing Information', minWidth: 150, maxWidth: 150 },
				{ key: 'business_contact', name: 'Business Contact', minWidth: 150 },
				{ key: 'sector_name', name: 'Sector', minWidth: 100 }
			];
			break;
		case 'Spend Threshold Breach':
			title = 'Spend Threshold Breach';
			status = 'Spend Threshold Breach';
			columnNames = [
				'Workspace',
				'Cluster Count',
				'Total DB Units MTD',
				'Total Compute DB Units MTD',
				'Average Cost Last 3 Months',
				'Sector'
			];
			column = [
				{ key: 'name', name: 'Workspace', minWidth: 150 },
				{
					key: 'count',
					name: 'Cluster Count',
					minWidth: 250,
					formatter(props: any) {
						const cluster_count = props.row.count;
						return CountRoundUp(cluster_count);
					}
				},
				{ key: 'costMTD', name: 'Total DB Units MTD', minWidth: 250,
                formatter(props: any) {
                    const costMTD = props.row.costMTD;
                    if (costMTD !== null) {
                        return costMTD ;
                    }
                    return 0;
                }},
				{ key: 'dbuMTD', name: 'Total Compute DB Units MTD', minWidth: 250,
				formatter(props: any) {
                    const dbuMTD = props.row.dbuMTD;
                    if (dbuMTD !== null) {
                        return dbuMTD ;
                    }
                    return 0;
                } },
				{
					key: 'AverageCost',
					name: 'Average Cost Last 3 Months',
					minWidth: 300,
					formatter(props: any) {
						const avg_cost = props.row.AverageCost;
						return CountRoundUp(avg_cost);
					}
				},
				{ key: 'sector_name', name: 'Sector', minWidth: 300 }
			];
			break;
		default:
			title = 'Long Running Clusters';
			status = 'Long Running Clusters';
			columnNames = [
				'Workspace',
				'Project Name',
				'Cluster Type',
				'Cluster Id',
				'Cluster Name',
				'Status',
				'Start Time',
				'End Time',
				'Current Run Duration',
				'Business Sector',
				'Billing Information',
				'Business Contact',
				'Sector'
			];
			column = [
				{ key: 'workspace_name', name: 'Workspace', minWidth: 150 },
				{ key: 'project_name', name: 'Project Name', minWidth: 150 },
				{ key: 'cluster_type', name: 'Cluster Type', minWidth: 150, maxWidth: 150 },
				{ key: 'cluster_id', name: 'Cluster Id', minWidth: 150 },
				{ key: 'name', name: 'Cluster Name', minWidth: 150 },
				{
					key: 'terminal_state',
					name: 'Status',
					minWidth: 220,
					formatter(props: any) {
						const terminal_state = props.row.terminal_state;
						return renderStatus(terminal_state);
					}
				},
				{
					key: 'start_time',
					name: 'Start Time',
					minWidth: 100,
					formatter(props: any) {
						const start_time = props.row.start_time;
						if (start_time !== 'undefined') {
							return moment(start_time).format('MM-DD-YY HH:mm:ss');
						}
						return '';
					}
				},
				{
					key: 'end_time',
					name: 'End Time',
					minWidth: 100,
					formatter(props: any) {
						const end_time = props.row.end_time;
						if (end_time !== 'undefined') {
							return moment(end_time).format('MM-DD-YY HH:mm:ss');
						}
						return '';
					}
				},
				{
					key: 'lastRunDuration',
					name: 'Current Run Duration',
					minWidth: 200,
					formatter(props: any) {
						const lastRunDuration = props.row.lastRunDuration;
						if (lastRunDuration !== 'NaN Minutes') {
							return lastRunDuration;
						}
						return '';
					}
				},
				{
					key: 'business_sector',
					name: 'Business Sector',
					minWidth: 150
				},
				{ key: 'billing_info', name: 'Billing Information', minWidth: 150, maxWidth: 150 },
				{ key: 'business_contact', name: 'Business Contact', minWidth: 150 },
				{ key: 'sector_name', name: 'Sector', minWidth: 150 }
			];
			break;
	}
	return { title, status, columnNames, column };
};
