import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import { ReactComponent as FilterIcon } from '../../assets/ml_summary_filter.svg';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClusterLogo from '../../assets/vaadin_cluster.svg';
import ExeMemoryUtilLogo from '../../assets/Executor-Memory-Utilization.svg';
import ExeCPUutilLogo from '../../assets/Executor-CPU-Utilization.svg';
import ExeNWLogo from '../../assets/Executor-N-W.svg';

import './ibp-obs.scss';

export default function IbpPlatform() {

	return (
		<div className="page-table jobs-tabledata page-table-ibp-platform">
            
            <div className="ibp-filter-wrapper">
				<div className="IBP-filter-text">IBP-Turkey </div> <FilterIcon className="sector-filter1" />
			</div>
            <div className="ibp-dashboard-wrapper ibp-platform-grids">
                <Grid container spacing={1} className="headerIPB">
                    <Grid item xs={2} className="monitor-title">
                        <div className="monitor-head highlight-text-green">Databricks</div>
                    </Grid>
                    <Grid item xs={2} className="monitor-title bgcolor-red bdr-red">
                        <div className="monitor-head">ADF</div>
                    </Grid>
                    <Grid item xs={2} className="monitor-title bgcolor-green bdr-green">
                        <div className="monitor-head">09</div>
                    </Grid>
                    <Grid item xs={2} className="monitor-title bgcolor-maroon bdr-maroon">
                        <div className="monitor-head">MDT</div>
                    </Grid>
                    <Grid item xs={2} className="monitor-title bgcolor-red bdr-red">
                        <div className="monitor-head">Oracle</div>
                    </Grid>
                </Grid>
            </div>

            <div className="business-process-accordion ibp-platform-accordion">
                <div className="bp-list--header">
                    <span className="bp--name">Databricks Clusters</span>
                    <span className="count-badge total-gdos">Status</span>
                </div>
                <Accordion className="w-full">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className="according-expand-icon" />}
                        aria-controls="panel-1-content"
                        id="panel-1-header"
                    >
                        <div className="business-process--list">
                            <span className="bp--name">
                                <img src={ClusterLogo} alt="" className='databricks-icon' />
                                geotab_small_External
                                </span>
                            <span className="total-cost-label success-jobs count-badge">Active</span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <h2 className="title">Status</h2>
                        <div className="ibp-dashboard-wrapper ibp-platform-grids ibp-platform-status">
                            <Grid container spacing={1} className="headerIPB">
                                <Grid item xs={2} className="monitor-title">
                                    <div className="monitor-head">Driver CPU</div>
                                    <div className="monitor-value">1.24%</div>
                                </Grid>
                                <Grid item xs={2} className="monitor-title">
                                    <div className="monitor-head">Driver Memory</div>
                                    <div className="monitor-value">27.2%</div>
                                </Grid>
                                <Grid item xs={2} className="monitor-title">
                                    <div className="monitor-head">Disk Spills</div>
                                    <div className="monitor-value">100 GB</div>
                                </Grid>
                                <Grid item xs={2} className="monitor-title">
                                    <div className="monitor-head">Executor CPU</div>
                                    <div className="monitor-value">0.0562%</div>
                                </Grid>
                                <Grid item xs={2} className="monitor-title">
                                    <div className="monitor-head">Executor Memory</div>
                                    <div className="monitor-value">6.66%</div>
                                </Grid>
                                <Grid item xs={2} className="monitor-title">
                                    <div className="monitor-head">Executor Running</div>
                                    <div className="monitor-value">2</div>
                                </Grid>
                            </Grid>
                        </div>                        
                        <h2 className="title">Executor</h2>
                        <div className="executor-girds">                            
                            <img src={ExeMemoryUtilLogo} alt="" className='databricks-icon' />
                        </div>
                        <div className="executor-girds">                            
                            <img src={ExeCPUutilLogo} alt="" className='databricks-icon' />
                        </div>
                        <div className="executor-girds">                            
                            <img src={ExeNWLogo} alt="" className='databricks-icon' />
                        </div>
                    </AccordionDetails>
                </Accordion>

                
                <Accordion className="w-full">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className="according-expand-icon" />}
                        aria-controls="panel-1-content"
                        id="panel-1-header"
                    >
                        <div className="business-process--list">
                            <span className="bp--name">
                                <img src={ClusterLogo} alt="" className='databricks-icon' />
                                PBNA_MRA_DF_1
                                </span>
                            <span className="total-cost-label failed-jobs count-badge">Failed</span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        
                    <h2 className="title">Status</h2>
                        <div className="ibp-dashboard-wrapper ibp-platform-grids ibp-platform-status">
                            <Grid container spacing={1} className="headerIPB">
                                <Grid item xs={2} className="monitor-title">
                                    <div className="monitor-head">Driver CPU</div>
                                    <div className="monitor-value">6%</div>
                                </Grid>
                                <Grid item xs={2} className="monitor-title">
                                    <div className="monitor-head">Driver Memory</div>
                                    <div className="monitor-value">33.5%</div>
                                </Grid>
                                <Grid item xs={2} className="monitor-title">
                                    <div className="monitor-head">Disk Spills</div>
                                    <div className="monitor-value">60 GB</div>
                                </Grid>
                                <Grid item xs={2} className="monitor-title">
                                    <div className="monitor-head">Executor CPU</div>
                                    <div className="monitor-value">8.92%</div>
                                </Grid>
                                <Grid item xs={2} className="monitor-title">
                                    <div className="monitor-head">Executor Memory</div>
                                    <div className="monitor-value">11.06%</div>
                                </Grid>
                                <Grid item xs={2} className="monitor-title">
                                    <div className="monitor-head">Executor Running</div>
                                    <div className="monitor-value">3</div>
                                </Grid>
                            </Grid>
                        </div>
                                                
                        <h2 className="title">Executor</h2>
                        <div className="executor-girds">                            
                            <img src={ExeCPUutilLogo} alt="" className='databricks-icon' />
                        </div>
                        <div className="executor-girds">                            
                            <img src={ExeNWLogo} alt="" className='databricks-icon' />
                        </div>
                        <div className="executor-girds">                            
                            <img src={ExeMemoryUtilLogo} alt="" className='databricks-icon' />
                        </div>
                    </AccordionDetails>
                </Accordion>

                
                <Accordion className="w-full">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className="according-expand-icon" />}
                        aria-controls="panel-1-content"
                        id="panel-1-header"
                    >
                        <div className="business-process--list">
                            <span className="bp--name">
                                <img src={ClusterLogo} alt="" className='databricks-icon' />
                                pep-gblsplr-cgf-dev-eus-02-de01
                                </span>
                            <span className="total-cost-label at-risk-jobs count-badge">At Risk</span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        
                    <h2 className="title">Status</h2>
                        <div className="ibp-dashboard-wrapper ibp-platform-grids ibp-platform-status">
                            <Grid container spacing={1} className="headerIPB">
                                <Grid item xs={2} className="monitor-title">
                                    <div className="monitor-head">Driver CPU</div>
                                    <div className="monitor-value">5.77%</div>
                                </Grid>
                                <Grid item xs={2} className="monitor-title">
                                    <div className="monitor-head">Driver Memory</div>
                                    <div className="monitor-value">12.2%</div>
                                </Grid>
                                <Grid item xs={2} className="monitor-title">
                                    <div className="monitor-head">Disk Spills</div>
                                    <div className="monitor-value">220 GB</div>
                                </Grid>
                                <Grid item xs={2} className="monitor-title">
                                    <div className="monitor-head">Executor CPU</div>
                                    <div className="monitor-value">5.62%</div>
                                </Grid>
                                <Grid item xs={2} className="monitor-title">
                                    <div className="monitor-head">Executor Memory</div>
                                    <div className="monitor-value">10.8%</div>
                                </Grid>
                                <Grid item xs={2} className="monitor-title">
                                    <div className="monitor-head">Executor Running</div>
                                    <div className="monitor-value">5</div>
                                </Grid>
                            </Grid>
                        </div>                        
                        <h2 className="title">Executor</h2>
                        <div className="executor-girds">                            
                            <img src={ExeNWLogo} alt="" className='databricks-icon' />
                        </div>
                        <div className="executor-girds">                            
                            <img src={ExeMemoryUtilLogo} alt="" className='databricks-icon' />
                        </div>
                        <div className="executor-girds">                            
                            <img src={ExeCPUutilLogo} alt="" className='databricks-icon' />
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>


		</div>
	);
}
