import {
	Box,
	Grid,
	FormControl,
	FormControlLabel,
	Select,
	InputLabel,
	MenuItem,
	Checkbox,
	InputAdornment
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import '../resourceGroups/bulkUpdate.css';
import { useState, useEffect, useRef } from 'react';
import { getResourceGroupSectors, patchResourceGroup } from '../../../service/service';
import Swal from 'sweetalert2';
import Loader from '../../Loader/loader';
import ActiveResourceMappingIcon from '../../../assets/activeResourceMapping.svg';


export function BulkUpdate({ bulkData, handleEditClose }: any) {
	console.log(bulkData.BillingInformation)
	const swalOptions = {
		// showCancelButton: true,
		// confirmButtonText: 'Yes, update it!',
		cancelButtonText: 'Close',
		customClass: {
			container: 'custom-swal-container',
			title: 'custom-swal-title',
			htmlContainer: 'custom-swal-html-container',
			confirmButton: 'custom-swal-confirm-button',
			cancelButton: 'custom-swal-cancel-button'
		}
	};
	const dataFetchedRef = useRef(false);
	const [sectorList, setSectorList] = useState<any[]>([]);
	const [loading, setLoading] = useState(false);
	const [saveBtnLoader, setSaveBtnLoader] = useState(false);
	const [formData, setFormData] = useState({
		id:[],
		DFSectorname: '',
		ProjectName: '',
		BillingInformation: '',
		DFProjectcode: '',
		Environment: '',
	});
	console.log(formData)
	const [formErrors, setFormErrors] = useState({
		estimatedDurationError: ''
	});

	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		fetchOptionsData();
	});

	async function fetchOptionsData() {
		setLoading(true);
		const sectorRes = await getResourceGroupSectors('1', '100', '');

		let currentSector: any = '';
	
		if (sectorRes.success) {
			// if (bulkData.DFSectorname) {
				currentSector = sectorRes.data.sectors.filter((sectors: any) => sectors !== null);
			// }
			setSectorList(sectorRes.data.sectors);
		}
		setLoading(false);
		// setFormData({
		// 	...formData,
		// 	DFSectorname: currentSector ? currentSector[0] : '',
		// });
	}

	const onSubmit = (event: any) => {
		if (saveBtnLoader) return;
		setSaveBtnLoader(true);
		event.preventDefault();
		let formPayload = formData;
		formPayload.id = bulkData.map((item:any) => item.id)
		updateBulkData(formPayload);

	};

	async function updateBulkData(formPayload: any) {
		console.log(formPayload.id.length)
		let count  = formPayload.id.length;
		// setLoading(true);
		const res = await patchResourceGroup(formPayload);
		if (res.success) {
			// setLoading(false);
			setSaveBtnLoader(false);
			Swal.fire({
				html: `
				<div>
				<h3 style="margin-left: 70px;">Resource Bulk Update Successfull</h3>
				  <div style="margin-right: 20px;">
					<img src=${ActiveResourceMappingIcon}  alt="Side Image" style="width: 100px; position: absolute; left: 40px; top: 10px;">
				  </div>
				  <div style="margin-top: -10px; margin-right: 12px;">
				  ${count} resources updated Successfull
				  </div>
				</div>
			  `,
			  confirmButtonText: 'Close',
				...swalOptions
			})
			handleClose();
		}
	}
	const handleChange = (event: any) => {
		setFormErrors({ estimatedDurationError: '' });
		const { name, value } =
			event && event.target
				? event.target.type === 'checkbox'
					? { name: event.target.name, value: event.target.checked }
					: event.target
				: '';

		setFormData({
			...formData,
			[name]: value
		});
	};


	const handleClose = () => {
		handleEditClose();
	};

	

	return (
		<>
			<DialogTitle>
				<Box display="flex" alignItems="center">
					<Box flexGrow={1}>Resource Bulk Update</Box>
					<Box>
						<IconButton onClick={handleClose} color="primary">
							<CloseIcon />
						</IconButton>
					</Box>
				</Box>
			</DialogTitle>
			{!loading ? (
				<form onSubmit={onSubmit} noValidate className="editJobForm" id="editJobForm">
					<DialogContent>
					
						<br />
						<Grid container item xs={12}>
							<Grid item xs={6}>
								<FormControl sx={{ m: 1, width: '95%' }}>
									<TextField
										id="ProjectName"
										label="Project Name"
										name="ProjectName"
										variant="outlined"
										onChange={handleChange}
										value={formData.ProjectName}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<FormControl sx={{ m: 1, width: '95%' }}>
								<InputLabel>{`Select Sector`}</InputLabel>
									<Select
										id="DFSectorname"
										name="DFSectorname"
										value={formData.DFSectorname}
										onChange={handleChange}
										autoWidth
									>
										{[ ...sectorList].map((sectors) => (
											<MenuItem  key={sectors} value={sectors}>
												{sectors}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
						<Grid container item xs={12}>
							<Grid item xs={6}>
								<FormControl sx={{ m: 1, width: '95%' }} error={true}>
								<InputLabel>{`Select Environment`}</InputLabel>
									<Select
										id="Environment"
										name="Environment"
										value={formData.Environment}
										onChange={handleChange}
										autoWidth
									>
										{['Prod', 'Non-prod'].map((environment) => (
									<MenuItem key={environment} value={environment}>
										{environment}
									</MenuItem>
								))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<FormControl sx={{ m: 1, width: '95%' }}>
								<TextField
										id="DFProjectcode"
										label="Project Number"
										name="DFProjectcode"
										variant="outlined"
										onChange={handleChange}
										value={formData.DFProjectcode}
									/>
								</FormControl>
							</Grid>
						</Grid>
						<Grid container item xs={12}>
							<Grid item xs={6}>
								<FormControl sx={{ m: 1, width: '95%' }}>
									<TextField
										id="BillingInformation"
										label="Billing Information"
										name="BillingInformation"
										variant="outlined"
										onChange={handleChange}
										value={formData.BillingInformation}
									/>
								</FormControl>
							</Grid>
							
						</Grid>
					</DialogContent>
					
					<DialogActions>
					
						<Button onClick={handleClose} variant="outlined" color="error">
							Cancel
						</Button>
						<Button type="submit" variant="contained" color="success" >
							{ saveBtnLoader ? 'Saving...' : 'Save' }
						</Button>
					</DialogActions>
				</form>
			) : (
				<Box sx={{ padding: '40px 20px', textAlign: 'center', fontSize: '14px', backgroundColor: '#0c0f25' }}>
					Fetching Data...
				</Box>
				// <Loader />
			)}
		</>
	);
}
