import React, { useEffect, useState, useRef } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { businessDashboard, getProcessRuns } from '../../service/service';
import moment from 'moment-timezone';
import './dashboard.css';
import { MetricsDashboardOverview } from './metricsDashboardOverview';
import GreenLegend from '../../assets/green.svg';
import PinkLegend from '../../assets/pink.svg';
import GreyLegend from '../../assets/grey.svg';
import OrangeLegend from '../../assets/orange.svg';
import BlueLegend from '../../assets/blue.svg';
import Info from '../../assets/lucide_info.svg';
import { Grid, Button, Popover, Typography, Stack, Tooltip } from '@mui/material';
import renderSLAStatus from '../../utils/renderSLAStatus';

import { ReactComponent as BackwardIcon } from '../../assets/page_backward.svg';
import { ReactComponent as PrevIcon } from '../../assets/page_prev.svg';
import { ReactComponent as NextICon } from '../../assets/page_next.svg';
import { ReactComponent as ForwardIcon } from '../../assets/page_forward.svg';
import { ReactComponent as OpenOutIcon } from '../../assets/open_out.svg';

export default function metricsDashboard({sectorList, schedulerList}:any) {
	const [apiData, setApiData] = useState<any>(null);
	const dataFetchedRef = useRef(false);
	const [currSkip, setCurrSkip] = useState<any>(6);
	const [columnDates, setColumnDates] = useState<any>([]);
	const [selectedProcess, setSelectedProcess] = useState<any>(null);
	const [popedRunData, setPopedRunData] = useState<any>(null);
	const [selectedDate, setSelectedDate] = useState<any>(null);
	const [selectedRunData, setSelectedRunData] = useState<any>(null);
	const [selectedEL, setSelectedEL] = useState<any>(null);

	const [cellPopup, setCellPopup] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);

	const [slaCellPopup, setSlaCellPopup] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);

	const disableDrillDowns = false;

	async function businessDash(forDate: any,sectorList:any, schedulerList:any) {
		const res = await businessDashboard(forDate,sectorList, schedulerList);
		if (res.success && res.data && res.data.process.length) {
			// Sort the data by sector and system
			const sortedData = [...res.data.process].sort((a: any, b: any) => {
				if (a.sectorName !== b.sectorName) {
					return a.sectorName.localeCompare(b.sectorName);
				}
				return a.systemName.localeCompare(b.systemName);
			});
			res.data.process = sortedData;
			setApiData(res.data);
		}
	}

	function drillDownCell(date: any, runData: any, process: any): any {
		if (disableDrillDowns) return;
		if (runData.notStarted) return;
		localStorage.removeItem('alertJobExecutionsState');
		const jobExecutionsState = {
			pageSize: 100,
			pageNumber: 1,
			selectedDate: date,
			sortingInfo: { sortBy: '', sortDir: '' },
			filterInfo: {
				process: [process],
				criticalFlag: [true]
			}
		};
		localStorage.setItem('alertJobExecutionsState', JSON.stringify(jobExecutionsState));
		localStorage.setItem('selectedAlert', 'businessDashboardCell');
		setTimeout(function () {
			window.location.href = `/jobExecutions`;
		}, 0);
	}

	function handleCellPopup(event: any, process: any): any {
		event.preventDefault();
		setSelectedProcess(process);
		setCellPopup(
			cellPopup === null
				? {
					mouseX: parseInt(event.clientX) + 10,
					mouseY: parseInt(event.clientY) - 10
				}
				: null
		);
	}

	const handleClose = (event: any) => {
		setCellPopup(null);
	};

	function handleSLACellPopup(event: any, date: any, runData: any, process: any, processId: any): any {
		if (disableDrillDowns) return false;
		event.preventDefault();
		event.target.classList.add('slaCellInfoIconShow'); // revert this to show info icon
		event.target.parentElement.classList.add('slaTrendCellHover');
		setSelectedEL(event.target);
		fetchProcessRuns(process, processId, date, runData, event);
	}

	async function fetchProcessRuns(process: any, processId: any, forDate: any, runData: any, event: any) {
		const res = await getProcessRuns(processId, forDate);
		if (res.success && res.data) {
			setSelectedDate(forDate);
			setSelectedProcess(process);
			setPopedRunData(res.data);
			setSelectedRunData(runData);
			setSlaCellPopup(
				slaCellPopup === null
					? {
						mouseX: parseInt(event.clientX) - 100,
						mouseY: parseInt(event.clientY) - Number(res.data.length) * 50
					}
					: null
			);
		}
	}

	const handleSLACellPopupClose = (event: any) => {
		selectedEL.classList.remove('slaCellInfoIconShow');
		selectedEL.parentElement.classList.remove('slaTrendCellHover');
		setSlaCellPopup(null);
		setPopedRunData(null);
		setSelectedProcess(null);
		setSelectedDate(null);
		setSelectedRunData(null);
		setSelectedEL(null);
	};

	const isDrillable = (runData: any) => {
		return !['NA', 'Not Started'].includes(runData.runStatus) && ['Met', 'Not Met', 'NA'].includes(runData.slaStatus);
	};

	function renderRunCell(date: any, runs: any, process: any, processId: any, idx: any): any {
		let runData: any = null;
		let cellColor: any = '#71758F';
		runs.forEach((run: any, idx: any) => {
			Object.entries(run).forEach(([key, value]) => {
				if (key === date) runData = value;
			});
		});
		if (runData) {
			if (runData.runStatus === 'Not Started') cellColor = '#299BFF'; // Not started
			else if (runData.slaStatus === 'Met') cellColor = '#5C7A37'; // Met
			else if (runData.slaStatus === 'Not Met') cellColor = '#A62F40'; // Not Met
			else if (runData.runStatus !== 'NA' && runData.slaStatus === 'NA') cellColor = '#B1730C'; // Others
			else if (runData.runStatus === 'NA') cellColor = '#71758F'; // Not Scheduled

			return (
				<React.Fragment>
					<TableCell
						key={`${date}_${idx}`}
						align="center"
						sx={{
							backgroundColor: `${cellColor} !important`,
							cursor: `${isDrillable(runData) ? 'pointer' : 'default'}`
						}}
						onClick={(event) => {
							isDrillable(runData) && drillDownCell(date, runData, process);
						}}
						className={isDrillable(runData) ? 'slaTrendCell' : 'slaNoPopupCell'}
					>
						{isDrillable(runData) ? (
							<>
								{runData.runStatus === 'SUCCESS' && moment(runData.runEndTime).format('HH:mm')}
								<img
									style={{ height: '10px', marginLeft: '5px', cursor: 'default', display: 'block' }}
									src={Info}
									className="slaCellInfoIcon"
									onMouseOver={(event) => handleSLACellPopup(event, date, runData, process, processId)}
								/>
							</>
						) : (
							''
						)}
					</TableCell>
				</React.Fragment>
			);
		} else {
			return (
				<React.Fragment>
					<TableCell
						key={`${date}_${idx}`}
						align="center"
						sx={{ backgroundColor: `${cellColor} !important` }}
					></TableCell>
				</React.Fragment>
			);
		}
	}

	function goTo(skipCount: any) {
		const dates = [];
		const newSkip = Number(currSkip) + Number(skipCount);
		setCurrSkip(newSkip);
		for (let i = newSkip; i >= newSkip - 6; i--) {
			dates.push(moment().subtract(i, 'days').format('YYYY-MM-DD'));
		}
		businessDash(dates[dates.length - 1], sectorList, schedulerList);
		setColumnDates(dates);
	}
	useEffect(() => {
		// if (dataFetchedRef.current) return;
		// dataFetchedRef.current = true;
		setCurrSkip(6);
		const dates = [];
		for (let i = currSkip; i >= currSkip - 6; i--) {
			dates.push(moment().subtract(i, 'days').format('YYYY-MM-DD'));
		}
		businessDash(dates[dates.length - 1], sectorList, schedulerList);
		setColumnDates(dates);
	}, [sectorList, schedulerList]);


	const renderTable = () => {
		const groupedData: any = apiData.process.reduce((acc: any, item: any) => {
			const sectorKey = item.sectorName;
			const systemKey = `${item.sectorName}-${item.systemName}`;

			if (!acc[sectorKey]) {
				acc[sectorKey] = { systems: {} };
			}

			if (!acc[sectorKey].systems[systemKey]) {
				acc[sectorKey].systems[systemKey] = { stateRowSpan: 0, items: [] };
			}

			acc[sectorKey].systems[systemKey].stateRowSpan++;
			acc[sectorKey].systems[systemKey].items.push(item);

			return acc;
		}, {});
		const rows = [];

		for (const sectorKey in groupedData) {
			if (groupedData.hasOwnProperty(sectorKey)) {
				const sectorData = groupedData[sectorKey];
				let systemLoopCount: number = 0;
				for (const systemKey in sectorData.systems) {
					if (sectorData.systems.hasOwnProperty(systemKey)) {
						const { stateRowSpan, items } = sectorData.systems[systemKey];
						const [firstItem, ...restItems] = items;
						console.log(firstItem,"firstItem--")
						const sectorRowSpan: any =
							Object.values(sectorData.systems).reduce((sum: any, item: any) => sum + item.stateRowSpan, 0) || 0;
						rows.push(
							<TableRow key={systemKey}>
								{systemLoopCount === 0 && <TableCell rowSpan={sectorRowSpan}>{firstItem.sectorName}</TableCell>}
								<TableCell rowSpan={stateRowSpan}>{firstItem.systemName}</TableCell>
								<TableCell>
									<span style={{ fontWeight: 400, paddingRight: '5px' }}>{firstItem.name}</span>
									<span style={{ fontWeight: 300, fontStyle: 'italic' }}>(in {moment().isDST() ? 'EDT' : 'EST'})</span>
									<img
										style={{ height: '13px', marginLeft: '5px', cursor: 'pointer' }}
										src={Info}
										onMouseOver={(event) => handleCellPopup(event, firstItem)}
									/>
									<br />
									{firstItem.scheduleTime !== null ? (
										<Tooltip title={firstItem.scheduleTime}>
											<span className="pDetailsInfo">
												{firstItem.scheduleDays} /{' '}
												{firstItem.scheduleTime.length < 50
													? `${firstItem.scheduleTime} - `
													: firstItem.scheduleTime.substring(0, 50) + '... -'}{' '}
												{firstItem.normalCompletion}
											</span>
										</Tooltip>
									) : (
										<span className="pDetailsInfo">No schedule time available</span>
									)}
								</TableCell>
								{columnDates.map((date: any, idx: any) =>
									renderRunCell(date, firstItem.runs, firstItem.name, firstItem.processId, idx)
								)}
							</TableRow>
						);

						restItems.forEach((p: any, index: any) => {
							rows.push(
								<TableRow key={p.name}>
									<TableCell>
										<span style={{ fontWeight: 400, paddingRight: '5px' }}>{p.name}</span>
										<span style={{ fontWeight: 300, fontStyle: 'italic' }}>
											(in {moment().isDST() ? 'EDT' : 'EST'})
										</span>
										<img
											style={{ height: '13px', marginLeft: '5px', cursor: 'pointer' }}
											src={Info}
											onMouseOver={(event) => handleCellPopup(event, p)}
										/>
										<br />
										{p.scheduleTime !== null ? (
											<Tooltip title={p.scheduleTime}>
												<span className="pDetailsInfo">
													{p.scheduleDays} /{' '}
													{p.scheduleTime.length < 50
														? `${p.scheduleTime} - `
														: p.scheduleTime.substring(0, 50) + '... -'}{' '}
													{p.normalCompletion}
												</span>
											</Tooltip>
										) : (
											<span className="pDetailsInfo">No schedule time available</span>
										)}
									</TableCell>

									{columnDates.map((date: any, idx: any) => renderRunCell(date, p.runs, p.name, p.processId, idx))}
								</TableRow>
							);
						});
						systemLoopCount++;
					}
				}
			}
		}

		return rows;
	};

	return (
		apiData && (
			<div className="bd-container businessDashboard">
				<div className="processTitlesla">Process SLA Score Card (Trend)</div>
				<Paper>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Sector</TableCell>
								<TableCell>System</TableCell>
								<TableCell>Process</TableCell>
								{columnDates.map((date: any) =>
									date === moment().format('YYYY-MM-DD') ? (
										<TableCell
											key={date}
											align="center"
											sx={{
												padding: '0px 0px !important',
												bgcolor: '#00D08D !important'
											}}
										>
											Today
										</TableCell>
									) : (
										<TableCell key={date} align="center">
											{moment(date).format('DD MMM').toUpperCase()}
										</TableCell>
									)
								)}
							</TableRow>
						</TableHead>
						<TableBody>{renderTable()}</TableBody>
					</Table>
					<MetricLegends showProcessInfo={true} goTo={goTo} currSkip={currSkip} />
				</Paper>
				<div className="processTitle">SLA Score Card by Category (Trend)</div>
				<Paper className="bd-overview">
					<MetricsDashboardOverview apiData={apiData} sectorList={sectorList} schedulerList={schedulerList}/>
				</Paper>
				<Popover
					open={cellPopup !== null}
					anchorReference="anchorPosition"
					anchorPosition={cellPopup !== null ? { top: cellPopup.mouseY, left: cellPopup.mouseX } : undefined}
				>
					<Stack
						direction="column"
						onMouseLeave={handleClose}
						spacing={1}
						style={{
							width: '400px',
							height: 'auto'
						}}
					>
						<Typography className="popoverText">Process: {selectedProcess && selectedProcess.name}</Typography>
						<Typography className="popoverLabel">Description</Typography>
						<Typography className="popoverText">{selectedProcess && selectedProcess.description}</Typography>
						<Typography className="popoverLabel">Impact</Typography>
						<Typography className="popoverText">{selectedProcess && selectedProcess.imapact}</Typography>
					</Stack>
				</Popover>
				{/* SLA popup */}
				<Popover
					className="slaPopup dashboardSlaPopup"
					open={slaCellPopup !== null}
					anchorReference="anchorPosition"
					anchorPosition={slaCellPopup !== null ? { top: slaCellPopup.mouseY, left: slaCellPopup.mouseX } : undefined}
				>
					<Stack
						direction="column"
						onMouseLeave={handleSLACellPopupClose}
						spacing={1}
						style={{
							width: 'auto',
							height: 'auto',
							padding: '10px'
						}}
					>
						{popedRunData && (
							<>
								<Stack direction="row" spacing={1}>
									<Typography className="popoverText">
										{selectedProcess} - {selectedDate && moment(selectedDate).format('dddd, DD MMM YYYY')}
									</Typography>
									<OpenOutIcon
										className="popoverLink"
										onClick={(event) => {
											isDrillable(selectedRunData) && drillDownCell(selectedDate, selectedRunData, selectedProcess);
										}}
									/>
								</Stack>
								<Table>
									<TableHead>
										<TableRow sx={{ borderBottom: '1px solid #71758F' }}>
											<TableCell>Job Name</TableCell>
											<TableCell>SLA Status</TableCell>
											<TableCell>Delay In Duration</TableCell>
											<TableCell>Reason</TableCell>
											<TableCell>Action Taken</TableCell>
										</TableRow>
										{popedRunData.map((run: any, idx: any) => (
											<TableRow key={idx} sx={{ borderBottom: '1px solid #71758F' }}>
												<TableCell>{run.name}</TableCell>
												<TableCell sx={{ textAlign: 'center' }}>{renderSLAStatus(run.slaStatus)}</TableCell>
												<TableCell>{run.durationDelay}</TableCell>
												<TableCell>{run.reasonForDelay}</TableCell>
												<TableCell>{run.actionTaken}</TableCell>
											</TableRow>
										))}
									</TableHead>
								</Table>
							</>
						)}
					</Stack>
				</Popover>
			</div>
		)
	);
}

export function MetricLegends({ showProcessInfo, goTo, currSkip }: any) {
	return (
		<div className="bd-container1">
			<>
				<Grid container item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
					<Grid item xs={6} style={{ fontSize: '10px' }}>
						<span style={{ paddingRight: '15px' }}>
							<img src={GreenLegend} style={{ paddingRight: '10px' }} />
							Success SLAs{' '}
						</span>
						<span style={{ paddingRight: '15px' }}>
							<img src={PinkLegend} style={{ paddingRight: '10px' }} /> Missed SLAs{' '}
						</span>
						<span style={{ paddingRight: '15px' }}>
							<img src={OrangeLegend} style={{ paddingRight: '10px' }} /> Others{' '}
						</span>
						<span style={{ paddingRight: '15px' }}>
							<img src={GreyLegend} style={{ paddingRight: '10px' }} /> Not Scheduled{' '}
						</span>
						<span style={{ paddingRight: '15px' }}>
							<img src={BlueLegend} style={{ paddingRight: '10px' }} /> Not Started{' '}
						</span>
					</Grid>
					<Grid item xs={5} style={{ textAlign: 'end', fontSize: '10px' }}>
						<Button
							className="dashboardPageBtn"
							onClick={(event) => {
								goTo(7);
							}}
						>
							<BackwardIcon />
						</Button>
						<Button
							className="dashboardPageBtn"
							onClick={(event) => {
								goTo(1);
							}}
						>
							<PrevIcon />
						</Button>
						<Button
							className="dashboardPageBtn"
							disabled={currSkip <= 6}
							onClick={(event) => {
								goTo(-1);
							}}
						>
							<NextICon />
						</Button>
						<Button
							className="dashboardPageBtn"
							disabled={Number(currSkip) - 6 <= 6}
							onClick={(event) => {
								goTo(-7);
							}}
						>
							<ForwardIcon />
						</Button>
						<Button
							className="dashboardPageBtn"
							disabled={Number(currSkip) <= 6}
							onClick={(event) => {
								goTo(-Number(currSkip) + 6);
							}}
						>
							Today
						</Button>
					</Grid>
				</Grid>
			</>
		</div>
	);
}
