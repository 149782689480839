import { useState, useEffect, useRef } from 'react';
import { Typography, Table, TableBody, TableRow, TableCell, Paper } from '@mui/material';
import moment from 'moment';

const CommonDetails = ({ sharedValue }: any) => {
	const dataFetchedRef = useRef(false);

	const [model, setModel] = useState(sharedValue);

	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		setModel(sharedValue);
	}, [sharedValue]);
	return (
		<Paper elevation={8} className="width-65">
			<Typography variant="h6" component="h6">
				Common Details
			</Typography>
			<Table className="model-overview-table">
				<TableBody>
					<TableRow>
						<TableCell className="attrTitle">Model Name</TableCell>
						<TableCell className="attrValue">{model?.name}</TableCell>
						<TableCell className="attrTitle">Last Updated on</TableCell>
						<TableCell className="attrValue">
							{model?.updatedAt && `${moment(model.updatedAt).format()} ${moment().isDST() ? '(UTC-4)' : '(UTC-5)'}`}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell className="attrTitle">Sector</TableCell>
						<TableCell className="attrValue">{model?.sector}</TableCell>
						<TableCell className="attrTitle">Training Date</TableCell>
						<TableCell className="attrValue">{model?.training_date && moment(model.training_date).format()}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell className="attrTitle">Model Type</TableCell>
						<TableCell className="attrValue">{model?.model_type}</TableCell>
						<TableCell className="attrTitle">Version</TableCell>
						<TableCell className="attrValue">{model?.version}</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</Paper>
	);
};

export default CommonDetails;
