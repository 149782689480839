import { useState, useEffect } from 'react';
import { CustomizedDataGrid } from '../customized_data_grid';
import '../DataPipeline/dataPipeline.scss';
import '../DataEstate/data-estate.scss';
import { getCustomDashboard } from '../../service/service';
import Loader from '../Loader/loader';
import { Grid } from '@mui/material';

export default function CustomDashboard() {
	const [loading, setLoading] = useState(false);
	const [dashboardList, setDashboardList] = useState<any>([]);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });

	const defaultColumnNames = ['Dashboard Name', 'Created by', 'Last Updated', 'Created Date', 'Tags', 'Actions'];

	const getDashboardData = async () => {
		setLoading(true);
		try {
			const data: any = await getCustomDashboard();
			setDashboardList(data.data);
		} catch (error) {
			console.error('Error fetching dashboard data:', error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const activeId = document.getElementById('root');
		console.log(window.location.pathname);
		if (window.location.pathname.includes('/data-pipeline/customDashboards')) {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
			}
		}
		getDashboardData();
	}, []);

	const getColumns = () => {
		return [
			{ key: 'Dashboard_Name', name: 'Dashboard Name', width: 340 },
			{ key: 'Created_By', name: 'Created By', width: 340 },
			{ key: 'Last_Updated', name: 'Last Updated', width: 240 },
			{ key: 'Created_Date', name: 'Created Date', width: 180 },
			{ key: 'Tags', name: 'Tags', width: 240 },
			{ key: 'Actions', name: 'Actions', width: 240 }
		];
	};

	return (
		<div className="dashboard-panel-dataestate job-schedules-wrap">
			<div className="program-list-container">
				<Grid>
					{!loading ? (
						<CustomizedDataGrid
							tableColumns={getColumns()}
							tableData={dashboardList}
							tableType="dashboard-list"
							defaultColumnNames={defaultColumnNames}
							currSorting={sortingInfo}
							listLength={dashboardList.length}
							isFetching={loading}
							showExport={false}
							isDateRangeFilter={false}
							isDisabled={false}
						/>
					) : (
						<Loader />
					)}
				</Grid>
			</div>
		</div>
	);
}
