import moment from 'moment';
import React, { useState, useEffect, useRef } from 'react';
import { Column } from 'react-data-grid';
import { Link, useSearchParams } from 'react-router-dom';
import { getTableauJobs, jobSearchTableauJobs, getDecodedToken, getSector } from '../../../service/service';
import Loader from '../../Loader/loader';
import { CustomizedDataGrid } from '../../customized_data_grid';
import {
	Button,
	Chip,
	Grid,
	Menu,
	TextField,
	FormControlLabel,
	Stack,
	Select,
	InputLabel,
	FormControl
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ReactComponent as DatePickerIcon } from '../../../assets/calendar-outline_white.svg';
import './tableauJobs.scss';
import PerformanceGrafana from './performanceGrafana';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import useStyles from '../../jobs/jobs_styles';
import { ReactComponent as FilterIcon } from '../../../assets/ml_summary_filter.svg';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ExtractTrendDetails from './extractTrendDetails';
import { ReactComponent as ServerIcon } from '../../../assets/solar_server-outline.svg';
import { ClassNames } from '@emotion/react';

const Jobs = () => {
	interface Row {
		jobId: string;
		contentName: string;
		jobStatus: string;
		jobType: string;
		contentType: string;
		projectName: string;
		averageExecution: string;
		lastExecutionDuration: string;
	}

	const renderStatus = (status: any) => {
		switch (status) {
			case 'Success':
				return <Chip label={status} color="success" />;
			case 'Failed':
				return <Chip label={status} color="secondary" />;
			default:
				return <Chip label={status} style={{ backgroundColor: '#299BFF26', color: '#299BFF' }} />;
		}
	};

	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'contentName',
				name: 'Job Name',
				formatter(props) {
					return (
						<Link to={`/tableau/jobdetails/${props.row.jobId}`} state={props.row} className="jobsNameList">
							{props.row.contentName}
						</Link>
					);
				},
				minWidth: 220,
				width: 220
			},
			{
				key: 'jobId',
				name: 'Job Id',
				minWidth: 150,
				width: 150
			},
			{
				key: 'jobStatus',
				name: 'Status',
				minWidth: 120,
				formatter(props) {
					const status = props.row.jobStatus;
					return renderStatus(status);
				}
			},
			{
				key: 'jobType',
				name: 'Type',
				minWidth: 120,
				width: 120
			},
			{
				key: 'contentType',
				name: 'Content Type',
				minWidth: 140,
				width: 140
			},
			{
				key: 'projectName',
				name: 'Project Name',
				minWidth: 140
			},
			{
				key: 'averageExecution',
				name: 'Average Execution Time',
				minWidth: 200
			},
			{
				key: 'lastExecutionDuration',
				name: 'Last Execution Duration',
				minWidth: 200
			}
		];
	}

	const defaultColumnNames = [
		'Job Name',
		'Job Id',
		'Status',
		'Type',
		'Content Type',
		'Project Name',
		'Average Execution Time',
		'Last Execution Duration'
	];
	const [jobsList, setJobsList] = useState<Row[]>([]);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);

	const [searchParams, setSearchParams] = useSearchParams();
	const hasDrillDown = searchParams.has('isDrillDowned');

	const [filterData, setFilterData] = useState<Row[]>([]);
	const [searchDetails, setFilterSearchInfo] = useState([]);
	const [loading, setLoading] = useState(false);
	const [filterInfo, setFilterInfo] = useState([]);
	const minJobExecDate = '2023-03-01';
	const dateFrom: string = searchParams.get('fromDate')?.substring(0, 10) as string;
	const startDt = moment(dateFrom).startOf('day').subtract(1, 'hours').format('YYYY-MM-DD HH:mm');
	const [fromDateTime, setFromDateTime] = useState<any>(
		searchParams.has('fromDate') ? startDt : moment().startOf('day').subtract(1, 'hours')
	);
	
	const [error, setError] = useState<string | null>(null);
	const [trigger, setTrigger] = useState(0);

	// sector filter
	const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
	const [filterMenu, setFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	const classes = useStyles();
	const [itemsChecked, setItemsChecked] = useState(false);
	const [sectorOptionsList, setSectorOptionsList] = useState<any[]>([]);
	const [checkedList, setCheckedList] = useState<any[]>([]);
	const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);
	const [sector, setCurrSector] = useState<any[]>([]);
	const dataFetchedRef = useRef(false);
	const [instance, setInstance] = useState<any>('all');

	// [Date Range Menu]
	const [dateRangeMenuOpen, setDateRangeMenuOpen] = React.useState<null | HTMLElement>(null);

	const handleDateClick = (event: React.MouseEvent<HTMLElement>) => {
		setDateRangeMenuOpen(event.currentTarget);
	};

	const handleDateRangeMenu = () => {
		setDateRangeMenuOpen(null);
	};

	const open = Boolean(dateRangeMenuOpen);

	const resetSearchParams = () => {
		if (searchParams.has('fromDate')) {
			searchParams.delete('status');
			searchParams.delete('scheduleDate');
			searchParams.delete('fromDate');
			setSearchParams(searchParams);
		} else if (hasDrillDown) {
			// searchParams.delete('status');
			// searchParams.delete('isDrillDowned');
			setSearchParams(searchParams);
		}
	};

		const applyDateTimeRange = (fromDateTime: any) => {
		resetSearchParams();
		setLoading(true);
		const promise1 = jobsListingFunc(
			page,
			pageSize,
			sortingInfo,
			fromDateTime,
			parseFilter(filterInfo),
			checkedList,
			instance
		).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		setDateRangeMenuOpen(null);
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};
	const filterClick = (event: any) => {
		event.preventDefault();
		setFilterMenu(
			filterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 20,
						mouseY: parseInt(event.clientY) - 6
				  }
				: null
		);
	};
	const handleClose = (event: any) => {
		setFilterMenu(null);
	};
	const selectAllCheck = (e: any) => {
		const { checked } = e.target;
		const collection: any[] = [];
		if (checked) setCheckedList(sectorOptionsList);
		else setCheckedList([]);
		setItemsChecked(checked);
	};
	const handleChange = (e: any) => {
		const { value, checked } = e.target;
		const newlyChecked = [];
		if (checked && e.target.value) {
			newlyChecked.push(e.target.value);
		}
		if (checked) {
			setCheckedList([...checkedList, ...newlyChecked]);
			if(sectorOptionsList.length === (checkedList.length + newlyChecked.length)){
				setItemsChecked(true);
			}
		} else {
			setCheckedList(checkedList.filter((item) => item !== value));
			setItemsChecked(false);
		}
	};

	const handleOkClick = (event: any) => {
				setTrigger((trigger) => trigger + 1);
		// Tableau drilldown filter changes
		localStorage.setItem('selectedSector', JSON.stringify(checkedList));
		if (checkedList.length > 0) {
			setCurrSectorFilter(checkedList);
		} else {
			setCurrSectorFilter([]);
		}
		
		jobsListingFunc(page, pageSize, sortingInfo, fromDateTime, parseFilter(filterInfo), checkedList, instance);
		console.log(jobsListingFunc, 'jobsListingFunc');
		// updateSharedValue({ sectorFilter: checkedList });
		// localStorage.setItem('ds-summary-sector-filter', JSON.stringify(checkedList));
		handleClose(event);
	};
	async function fetchSectorOptionsData() {
		const sectorRes = await getSector('1', '100', '');
		if (sectorRes.success) {
			const filteredSectorOptions = sectorRes.data.sector.filter((x: any) => x.name !== 'S&T').map((x: any) => x.name);
			filteredSectorOptions.push('Blank');
        	setSectorOptionsList(filteredSectorOptions);
		}
	}
  
	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setLoading(true);
		const promise1 = jobsListingFunc(
			value,
			pageSize,
			sortingInfo,
			fromDateTime,
			parseFilter(filterInfo),
			checkedList,
			instance
		).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

		const handlepageSizeChange = (event: any) => {
		setLoading(true);
		const promise1 = jobsListingFunc(
			'1',
			event.target.value,
			sortingInfo,
			fromDateTime,
			parseFilter(filterInfo),
			checkedList,
			instance
		).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

		const handleSorting = (sortingInfo: any) => {
		setLoading(true);
		const promise1 = jobsListingFunc(
			page,
			pageSize,
			sortingInfo,
			fromDateTime,
			parseFilter(filterInfo),
			checkedList,
			instance
		).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

	const handleDateTimeChange = (field: any, dateTime: any) => {
		// Tableau drilldown filter changes
		localStorage.setItem('selectedDateTime', dateTime);
		if (field === 'fromDateTime') setFromDateTime(dateTime);
		applyDateTimeRange(dateTime);
	};
	
		const handleFilter = (filterInfo: any) => {
			console.log(filterInfo)
		resetSearchParams();
		setFilterInfo(filterInfo);
		setLoading(true);
		const promise1 = jobsListingFunc(
			1,
			pageSize,
			sortingInfo,
			fromDateTime,
			parseFilter(filterInfo),
			checkedList,
			instance
		).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

	// Server filter code
	const handleServerChange = (field: any, e: any) => {
		// Tableau server filter changes
		if (field === 'instance') {
			setInstance(e.target.value);
			localStorage.setItem('selectedServer', e.target.value);
		}
		applyServerFilter(e.target.value);
	};

	const applyServerFilter = (instance: any) => {
		resetSearchParams();
		setLoading(true);
		const promise1 = jobsListingFunc(
			page,
			pageSize,
			sortingInfo,
			fromDateTime,
			parseFilter(filterInfo),
			checkedList,
			instance
		).catch((error) => {
			setLoading(false);
			setError(error); // Set error state when API call fails
		});
		setDateRangeMenuOpen(null);
		Promise.all([promise1]).then((values) => {
			setLoading(false);
		});
	};

	async function jobSearchForTableauJobsFunc(searchQuery: any, searchField: any) {
		const res = await jobSearchTableauJobs(
			'1',
			pageSize,
			sortingInfo,
			fromDateTime,
			parseFilter(filterInfo),
			checkedList,
			instance,			
			searchQuery, searchField, 
			);
		if (res.success) {
			setFilterSearchInfo(res.data);
		}
	}

	const handleFilterClick= async (columnKey: any)=>{
		let filterOptions: any = {}
		const res = await jobSearchTableauJobs(
			'1',
			pageSize,
			sortingInfo,
			fromDateTime,
			parseFilter(filterInfo),
			checkedList,
			instance,			
			searchDetails, columnKey, 
			);
		if (res.success) {
			console.log(res)
			filterOptions[columnKey] = res.data?.name || [];
            setFilterData(filterOptions)		}
	}
	const handleSearch = (searchDetails: any, columnKey: any) => {
		console.log(searchDetails, columnKey)
		if(searchDetails?.length >= 3)
		jobSearchForTableauJobsFunc(searchDetails, columnKey);
	};
	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

	async function jobsListingFunc(
		pageNumber: any,
		size: any,
		sortingInfo: any,
		fromDateTime: any,
		jobFilterInfo: any,
		sector: any,
		instance: any
	) {
				setLoading(true);
		try {
						const res = await getTableauJobs(
				pageNumber.toString(),
				size.toString(),
				sortingInfo,
				jobFilterInfo,
				fromDateTime,
				sector,
				instance
			);
						if (res.success) {
							console.log('sdsd')
				const addDollar = res?.data?.result?.map((item: any) => ({
					...item,
					averageExecution: `${
						item.averageExecution !== null && item.averageExecution !== undefined ? `${item.averageExecution}` : null
					}`
				}));
				setJobsList(addDollar);
				setPaginationData(res.data.page);
				setFilterData(res.data.filterOptions);
				setFilterInfo(jobFilterInfo);
				setPage(pageNumber);
				setPageSize(size);
				setSortingInfo(sortingInfo);
				setError(null);
			} else {
				setError('Failed to fetch data'); // Set the error state if API call fails
			}
		} catch (error) {
			setError('Failed to fetch data'); // Set the error state if API call fails
		}
		setLoading(false);
	}
		useEffect(() => {
		// 	if (dataFetchedRef.current) return;
		// dataFetchedRef.current = true;
		// Tableau drilldown filter changes
		if (hasDrillDown) {
			const selectedDateTime = localStorage.getItem('selectedDateTime');
			if (selectedDateTime) {
				setFromDateTime(selectedDateTime);
			}
			const selectedSector = localStorage.getItem('selectedSector');
			if (selectedSector) {
				setCheckedList(JSON.parse(selectedSector));
				setCurrSectorFilter(JSON.parse(selectedSector));
			}
			const selectedServer = localStorage.getItem('selectedServer');
			if (selectedServer) {
				setInstance(selectedServer);
			}
		} else {
			localStorage.removeItem('selectedDateTime');
			localStorage.removeItem('selectedFilter')
			localStorage.removeItem('selectedSector');
			localStorage.removeItem('selectedServer');
		}
		if (userSector === 'all') fetchSectorOptionsData();
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		// let storedSectorFilter = localStorage.getItem('ds-summary-sector-filter')
		// 	? JSON.parse(localStorage.getItem('ds-summary-sector-filter') ?? '')
		// 	: null;
		// if (storedSectorFilter) {
		// 	setCheckedList(storedSectorFilter);
		// 	setCurrSectorFilter(storedSectorFilter);
		// }
		if (!hasDrillDown) {
				jobsListingFunc(page, pageSize, sortingInfo, fromDateTime, parseFilter(filterInfo), checkedList, instance).catch(
			(error) => {
				setError(error); // Set error state when API call fails during initial load
			}
		);
		}
		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/tableau/performance/dashboard') {
			if (activeId) {
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('databricks-jobs');
				document.body.classList.add('datefilter-align-sector');
				document.body.classList.add('sector-label');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				activeId.classList.remove('jobs-list-table');
				activeId.classList.remove('jobs-list');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.remove('jobs-exe-datas');
			}
		}
	}, []);
	
	return (
		<div className="job-schedules-wrap">
			<>
				<PerformanceGrafana fromDateTime={fromDateTime} currSectorFilter={currSectorFilter} serverFilter={instance} />
			</>
			<div className="job-exexutions-dateFilter-div">
				{/* [Date Range Menu] */}
				<div className="single-calendar-performance">
					{/* condition for default Today  */}
					{/* {moment(fromDateTime).format('DD MM YYYY') ===
						moment().startOf('day').subtract(1, 'hours').format('DD MM YYYY') &&
						 <div style={{ marginRight: '10px' }}>Today</div>} */}
					{
						<LocalizationProvider className="outerDateContainer" dateAdapter={AdapterMoment}>
							<DatePicker
								showToolbar={true}
								components={{
									OpenPickerIcon: DatePickerIcon
								}}
								PopperProps={{ placement: 'auto' }}
								className="dateFilterTD"
								value={moment(fromDateTime).tz('America/New_York')}
								onChange={(dateTime) => {
									handleDateTimeChange('fromDateTime', dateTime);
								}}
								renderInput={(params: any) => <TextField name="startDateTime" {...params} />}
							/>
						</LocalizationProvider>
					}
				</div>

				{/* Code for sector filter */}
				<Stack>
					{userSector === 'all' ? (
						<FilterIcon onClick={filterClick} className="sector-filter1" />
					) : (
						<div className="separeat_sector">Sector: {getDecodedToken()?.sector?.name}</div>
					)}
					<Popover
						open={filterMenu !== null}
						onClose={handleClose}
						className="filterContainer"
						anchorReference="anchorPosition"
						anchorPosition={filterMenu !== null ? { top: filterMenu.mouseY, left: filterMenu.mouseX } : undefined}
					>
						<div className="text-filter-row">
							<div className="selectAll-sec">
								<MenuItem className="selectAll">
									<FormControlLabel
										className={classes.checkbox}
										label="All"
										control={
											<Checkbox
												color="success"
												value="all"
												checked={itemsChecked}
												onChange={(e) => {
													selectAllCheck(e);
												}}
											/>
										}
									/>
								</MenuItem>
								<div className="checkbox-sec">
									{sectorOptionsList &&
										sectorOptionsList.map((filter: any) => (
											<MenuItem key={filter}>
												<FormControlLabel
													className={classes.checkbox}
													label={filter === true ? 'Yes' : filter === false ? 'No' : filter}
													control={
														<Checkbox
															color="success"
															value={filter}
															checked={
																(filter === true && checkedList.includes('true')) ||
																(filter === false && checkedList.includes('false')) ||
																checkedList.includes(filter)
															}
															onChange={(e) => {
																handleChange(e);
															}}
														/>
													}
												/>
											</MenuItem>
										))}
								</div>
							</div>
							<div className="buttonSec">
								<Button variant="contained" className={classes.cancelBtn} onClick={handleClose}>
									cancel
								</Button>
															<Button variant="contained" className={classes.okBtn} onClick={handleOkClick}>
									ok
								</Button>
							</div>
						</div>
					</Popover>
				</Stack>

				{/* Code for server filter */}
				<Stack>
					<div className="server-select">
						<FormControl fullWidth>
							<ServerIcon className="server-icon" />
							<Select
								name="server-select"
								labelId="demo-simple-select-label"
								id="single-server-select"
								value={instance}
								label="Server"
								onChange={(e) => {
									handleServerChange('instance', e);
								}}
								classes={{ select: classes.select }}
								IconComponent={() => null} 
							>
								<div>Servers</div>
								<MenuItem style={instance === 'all' ? {   background: '#676a88', borderRadius: '5px' } : {}} value={'all'}>All Servers</MenuItem>
								<MenuItem style={instance === 'global' ? { background: '#676a88', borderRadius: '5px' } : {}} value={'global'}>Global</MenuItem>
								<MenuItem style={instance === 'cockpit' ? { background: '#676a88', borderRadius: '5px' } : {}} value={'cockpit'}>Cockpit</MenuItem>
							</Select>
						</FormControl>
					</div>
				</Stack>
			</div>

			{hasDrillDown ? (
				<ExtractTrendDetails fromDateTime={fromDateTime} sector={checkedList} trigger={trigger} serverFilter={instance} />
			) : (
				<div className="tableauJobs_tabledata">
					<div className="tableau_title">Jobs</div>
					{error ? (
						// Display error message or handle error condition in UI
						<div
							style={{
								color: '#e5e5e5',
								marginLeft: '25%',
								padding: '10px',
								fontWeight: 500,
								gridColumn: '1 / -1'
							}}
						>
							Error occurred while fetching data. Please try again.
						</div>
					) : (
						<Grid>
							{!loading ? (
								<CustomizedDataGrid
									tableColumns={getColumns()}
									tableData={jobsList}
									tableType="Performance"
									defaultColumnNames={defaultColumnNames}
									onSorting={handleSorting}
									currSorting={sortingInfo}
									filterDataOptions={filterData}
									onFilterCheck={handleFilter}
									currFilter={filterInfo}
									searchDataValues={searchDetails}
									onFilterSearch={handleSearch}
									onFilterClick={handleFilterClick}
									page={page}
									pageSize={pageSize}
									paginationData={paginationData}
									listLength={jobsList.length}
									handlepageSizeChange={handlepageSizeChange}
									handlePageChange={handlePageChange}
									isDateRangeFilter={false}
									isDisabled={true}
								/>
							) : (
								<Loader />
							)}
						</Grid>
					)}
				</div>
			)}
		</div>
	);
};

export default Jobs;
