import React, { useEffect, useState, useRef } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment-timezone';
import { PieChart } from 'react-minimal-pie-chart';
import CollapseDiv from '../../assets/collapseDiv.svg';
import { MetricLegends } from './metricsDashboard';
import { businessDashboardPieChart } from '../../service/service';
import { Popper, Typography, Stack } from '@mui/material';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { ReactComponent as GreenLegend } from '../../assets/green.svg';
import { ReactComponent as PinkLegend } from '../../assets/pink.svg';
import { ReactComponent as OrangeLegend } from '../../assets/orange.svg';
import { ReactComponent as BlueLegend } from '../../assets/blue.svg';
import { ReactComponent as GreyLegend } from '../../assets/grey.svg';
import '../dashboard/metricsDashboardOverview.css';

export function MetricsDashboardOverview({ apiData, sectorList, schedulerList }: any) {
	if (schedulerList.includes('control-m') && schedulerList.includes('autosys') && schedulerList.includes('control-m-pgt')) {
		schedulerList = 'all';
	}
	const [pieChartData, setPiechartData] = useState<any>(null);
	const dataFetchedRef = useRef(false);
	const [currSkip, setCurrSkip] = useState<any>(6);
	const [hoveredSliceData, setHoveredSliceData] = useState({
		date: '',
		type: '',
		value: '',
		percent: '',
		label: '',
		lgIcon: ''
	});
	const [slaPopupEl, setSlaPopupEl] = React.useState<HTMLButtonElement | null>(null);
	const [hoveredSegmentIndex, setHoveredSegmentIndex] = useState(null);
	const [hoveredSegmentDate, setHoveredSegmentDate] = useState(null);

	async function businessDash(forDate: any,sectorList:any, schedulerList:any) {
		const pieChartRes = await businessDashboardPieChart(forDate,sectorList, schedulerList);
		if (pieChartRes.success && pieChartRes.data) {
			pieChartRes?.data.sort((a: any, b: any) => {
				return a.sector?.localeCompare(b.sector);
			});
			pieChartRes.data.forEach((respdata: any) => {
				respdata.chartData.forEach((cData: any) => {
					cData.date = cData.date.substring(0, 10);
				});
				respdata.chartData?.sort((a: any, b: any) => {
					return a.date?.localeCompare(b.date);
				});
			});
			setPiechartData(pieChartRes.data);
		}
	}
	const disableDrillDowns = true;
	function handlePieSegmentClick(segmentIndex: any, slaType: any, date: any) {
		if (disableDrillDowns) return;
		const slaStatusMap = ['Met', 'Not Met', 'NA'];
		// only 'Met', 'Not Met', 'NA' are drillable
		if (!slaStatusMap.includes(slaType)) return;
		// if (slaType === 'Not Started') {
		// 	localStorage.removeItem('alertJobExecutionsState');
		// 	const jobExecutionsState = {
		// 		pageSize: 100,
		// 		pageNumber: 1,
		// 		selectedDate: date,
		// 		sortingInfo: { sortBy: '', sortDir: '' },
		// 		filterInfo: {
		// 			criticalFlag: [true],
		// 			sector: ['PBNA'],
		// 			slaStatus: ['NA'],
		// 			runStatus: ['Not Started']
		// 		}
		// 	};
		// 	localStorage.setItem('alertJobExecutionsState', JSON.stringify(jobExecutionsState));
		// 	localStorage.setItem('selectedAlert', 'businessDashboardCell');
		// 	setTimeout(function () {
		// 		window.location.href = `/jobExecutions`;
		// 	}, 0);
		// } else
		{
			localStorage.removeItem('alertJobExecutionsState');
			const jobExecutionsState = {
				pageSize: 100,
				pageNumber: 1,
				selectedDate: date,
				sortingInfo: { sortBy: '', sortDir: '' },
				filterInfo: {
					criticalFlag: [true],
					sector: ['PBNA'],
					slaStatus: [slaType]
				}
			};
			localStorage.setItem('alertJobExecutionsState', JSON.stringify(jobExecutionsState));
			localStorage.setItem('selectedAlert', 'businessDashboardCell');
			setTimeout(function () {
				window.location.href = `/jobExecutions`;
			}, 0);
		}
	}
	function handlePieSegmentHover(event: any, segmentIndex: any, dataSet: any, date: any) {
		event.preventDefault();
		const totalResult = dataSet.reduce(function (previousValue: any, currentValue: any) {
			return {
				value: Number(previousValue.value) + Number(currentValue.value)
			};
		});
		const sliceData = dataSet[segmentIndex];
		setHoveredSliceData({
			...sliceData,
			percent: calPer(sliceData.value, totalResult.value),
			date
		});
		setTimeout(() => {
			setHoveredSegmentIndex(segmentIndex);
			setHoveredSegmentDate(date);
		}, 50);
		setSlaPopupEl(event.currentTarget);
	}
	function calPer(itemCount: any, totalCount: any) {
		return `${Math.round((itemCount / totalCount) * 100)}%`;
	}
	const handleSLASlicePopupClose = (event: any) => {
		setHoveredSliceData({ date: '', type: '', value: '', percent: '', label: '', lgIcon: '' });
		setSlaPopupEl(null);
		setHoveredSegmentIndex(null);
	};

	const getSegmentShift = (dataSet: any, index: any, date: any) => {
		const totalResult = dataSet.reduce(function (previousValue: any, currentValue: any) {
			return {
				value: Number(previousValue.value) + Number(currentValue.value)
			};
		});
		const sliceData = dataSet[index];
		return calPer(sliceData.value, totalResult.value) !== '100%' &&
			index === hoveredSegmentIndex &&
			moment(date).format('YYYY-MM-DD') === hoveredSegmentDate
			? 5
			: 0;
	};
	const segmentLabelVisibility = (index: any, date: any) => {
		return index === hoveredSegmentIndex && moment(date).format('YYYY-MM-DD') === hoveredSegmentDate ? 'none' : 'block';
	};

	function renderPieChartCell(data: any, idx: any): any {
		const dataSet: any = [];
		dataSet.push({
			value: data?.met,
			color: '#5C7A37',
			type: 'Met',
			label: 'SLAs Met',
			lgIcon: <GreenLegend style={{ paddingTop: '5px', paddingRight: '8px' }} />
		});

		dataSet.push({
			value: data?.notMet,
			color: '#A62F40',
			type: 'Not Met',
			label: 'Missed SLAs',
			lgIcon: <PinkLegend style={{ paddingTop: '5px', paddingRight: '8px' }} />
		});
		dataSet.push({
			value: parseInt(data?.notApplicable) + parseInt(data?.actionTaken),
			color: '#B1730C',
			type: 'NA',
			label: 'Others',
			lgIcon: <OrangeLegend style={{ paddingTop: '5px', paddingRight: '8px' }} />
		});
		if (data?.total && data?.scheduled === 0 && data?.met === 0 && data?.notMet === 0) {
			dataSet.push({
				value: parseInt(data?.total),
				color: '#71758F',
				type: 'Not Scheduled',
				label: 'Not Scheduled',
				lgIcon: <GreyLegend style={{ paddingTop: '5px', paddingRight: '8px' }} />
			});
		}
		dataSet.push({
			value: data?.notStarted,
			color: '#299BFF',
			type: 'Not Started',
			label: 'Not Started',
			lgIcon: <BlueLegend style={{ paddingTop: '5px', paddingRight: '8px' }} />
		});

		return (
			<React.Fragment>
				<TableCell key={idx} align="center" className="pieChart-container">
					<ClickAwayListener onClickAway={handleSLASlicePopupClose}>
						<Popper className="slaPopup" open={slaPopupEl !== null} anchorEl={slaPopupEl} placement="top-start">
							{hoveredSliceData && hoveredSliceData.type && (
								<Stack
									direction="column"
									onMouseLeave={handleSLASlicePopupClose}
									spacing={3}
									style={{
										width: 'auto',
										height: 'auto',
										padding: '10px'
									}}
								>
									<Typography sx={{ fontSize: '14px', fontWeight: '700' }}>
										{moment(hoveredSliceData.date).format('dddd, DD MMM YYYY')}
									</Typography>
									<div>
										<Stack direction="row" spacing={0}>
											{hoveredSliceData.lgIcon}
											<Stack direction="row" spacing={3}>
												<span style={{ color: '#71758F', fontSize: '12px', fontWeight: '700' }}>
													{hoveredSliceData.label}
												</span>
												<span style={{ fontSize: '12px', fontWeight: '700' }}>
													{hoveredSliceData.value} ({hoveredSliceData.percent})
												</span>
											</Stack>
										</Stack>
									</div>
								</Stack>
							)}
						</Popper>
					</ClickAwayListener>
					<PieChart
						data={dataSet}
						viewBoxSize={[110, 110]}
						center={[55, 55]}
						segmentsShift={(index) => getSegmentShift(dataSet, index, data.date)}
						segmentsStyle={(index) => {
							return ['Met', 'Not Met', 'NA'].includes(dataSet[index].type) ? { cursor: 'pointer' } : {};
						}}
						label={({ dataEntry }) =>
							`${
								dataEntry.percentage > 5 && dataEntry.percentage < 100
									? Math.round(dataEntry.percentage).toString() + '%'
									: ''
							}`
						}
						labelStyle={(index) => ({
							fill: '#FFFFFF',
							display: segmentLabelVisibility(index, data.date),
							cursor: 'pointer'
						})}
						animate={true}
						animationDuration={1000}
						labelPosition={70}
						onClick={(e, segmentIndex) => {
							handlePieSegmentClick(segmentIndex, dataSet[segmentIndex].type, moment(data.date).format('YYYY-MM-DD'));
						}}
						className="pie-chart-over"
						onMouseOver={(e, segmentIndex) => {
							handlePieSegmentHover(e, segmentIndex, dataSet, moment(data.date).format('YYYY-MM-DD'));
						}}
					/>
				</TableCell>
			</React.Fragment>
		);
	}

	function goTo(skipCount: any) {
		const dates = [];
		const newSkip = Number(currSkip) + Number(skipCount);
		setCurrSkip(newSkip);
		for (let i = newSkip; i >= newSkip - 6; i--) {
			dates.push(moment().subtract(i, 'days').format('YYYY-MM-DD'));
		}
		businessDash(dates[dates.length - 1],sectorList, schedulerList);
	}
	useEffect(() => {
		// if (dataFetchedRef.current) return;
		// dataFetchedRef.current = true;
		setCurrSkip(6);
		const dates = [];
		for (let i = currSkip; i >= currSkip - 6; i--) {
			dates.push(moment().subtract(i, 'days').format('YYYY-MM-DD'));
		}
		businessDash(dates[dates.length - 1], sectorList, schedulerList);
	}, [sectorList, schedulerList]);

	return (
		<>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>
							Sector
							{/* <span style={{ float: 'right', paddingTop: '5px' }}>
								<img src={CollapseDiv} style={{ paddingRight: '10px' }} />
							</span> */}
						</TableCell>
						{pieChartData?.length ?
							pieChartData[0].chartData.map((pd: any) =>
								pd.date === moment().format('YYYY-MM-DD') ? (
									<TableCell
										key={pd.date}
										align="center"
										sx={{
											padding: '0px 0px !important',
											bgcolor: '#00D08D !important'
										}}
									>
										Today {pd.sector}
									</TableCell>
								) : (
									<TableCell key={pd.date} align="center">
										{moment(pd.date).format('DD MMM').toUpperCase()}
									</TableCell>
								)
							) : ''}
					</TableRow>
				</TableHead>
				<TableBody>
					{pieChartData?.length ?
						pieChartData.map((pd: any) => (
							<TableRow
								onMouseLeave={(e) => {
									handleSLASlicePopupClose(e);
								}}
							>
								<TableCell>{pd?.sector}</TableCell>
								{pd?.chartData.map((data: any, idx: any) => renderPieChartCell(data, idx))}
							</TableRow>
						)) : ''}
				</TableBody>
			</Table>

			<MetricLegends showProcessInfo={false} goTo={goTo} currSkip={currSkip} />
		</>
	);
}
