import { lazy } from 'react';
import PrivateRoute from 'utils/privateRoute';

import OverallSummary from 'components/DataEstate/overall-summary';
import BIApplicationSummary from 'components/DataEstate/biApplication-summary';
import CostSummary from 'components/DataEstate/cost-summary';
import DataProcessingSummary from 'components/DataEstate/data-processing-summary';
import IncidentDetails from 'components/DataEstate/incident-details';
import IncidentImpactAnalysis from 'components/DataEstate/incident-impact-analysis';
import QueriesSummary from 'components/DataEstate/queries-summary';
import ServiceRequestSummary from 'components/DataEstate/service-request';
import StorageSummary from 'components/DataEstate/storage-summary';

// const OverallSummary = lazy(() => import('components/DataEstate/overall-summary'));
// const BIApplicationSummary = lazy(() => import('components/DataEstate/biApplication-summary'));
// const CostSummary = lazy(() => import('components/DataEstate/cost-summary'));
// const DataProcessingSummary = lazy(() => import('components/DataEstate/data-processing-summary'));
// const IncidentDetails = lazy(() => import('components/DataEstate/incident-details'));
// const IncidentImpactAnalysis = lazy(() => import('components/DataEstate/incident-impact-analysis'));
// const QueriesSummary = lazy(() => import('components/DataEstate/queries-summary'));
// const ServiceRequestSummary = lazy(() => import('components/DataEstate/service-request'));
// const StorageSummary = lazy(() => import('components/DataEstate/storage-summary'));

const DataEstateRoutes = [
	{
		key: 'data_est_exec',
		route: '/dataestate/executive-summary',
		component: (
			<PrivateRoute
				Component={OverallSummary}
				restrictedRoles={[
					'Platform Operations Admin/SRE',
					'Data Operations Admin',
					'Data Operations User',
					'Program Executive'
				]}
			/>
		)
	},
	{
		key: 'data_est_stg',
		route: '/dataestate/storagesummary',
		component: (
			<PrivateRoute
				Component={StorageSummary}
				restrictedRoles={[
					'Platform Operations Admin/SRE',
					'Data Operations Admin',
					'Data Operations User',
					'Program Executive'
				]}
			/>
		)
	},
	{
		key: 'data_est_qrysum',
		route: '/dataestate/queriessummary',
		component: (
			<PrivateRoute
				Component={QueriesSummary}
				restrictedRoles={[
					'Platform Operations Admin/SRE',
					'Data Operations Admin',
					'Data Operations User',
					'Program Executive'
				]}
			/>
		)
	},
	{
		key: 'data_est_biappsum',
		route: '/dataestate/biApplicationSummary',
		component: (
			<PrivateRoute
				Component={BIApplicationSummary}
				restrictedRoles={[
					'Platform Operations Admin/SRE',
					'Data Operations Admin',
					'Data Operations User',
					'Program Executive'
				]}
			/>
		)
	},
	{
		key: 'data_est_costsum',
		route: '/dataestate/costSummary',
		component: (
			<PrivateRoute
				Component={CostSummary}
				restrictedRoles={[
					'Platform Operations Admin/SRE',
					'Data Operations Admin',
					'Data Operations User',
					'Program Executive'
				]}
			/>
		)
	},
	{
		key: 'data_est_dataproc',
		route: '/dataestate/data-processing',
		component: (
			<PrivateRoute
				Component={DataProcessingSummary}
				restrictedRoles={[
					'Platform Operations Admin/SRE',
					'Data Operations Admin',
					'Data Operations User',
					'Program Executive'
				]}
			/>
		)
	},
	{
		key: 'data_est_svcreq',
		route: '/dataestate/service-request',
		component: (
			<PrivateRoute
				Component={ServiceRequestSummary}
				restrictedRoles={[
					'Platform Operations Admin/SRE',
					'Data Operations Admin',
					'Data Operations User',
					'Program Executive'
				]}
			/>
		)
	},
	{
		key: 'data_est_incdtl',
		route: '/dataestate/incident-details',
		component: (
			<PrivateRoute
				Component={IncidentDetails}
				restrictedRoles={[
					'Platform Operations Admin/SRE',
					'Data Operations Admin',
					'Data Operations User',
					'Program Executive'
				]}
			/>
		)
	},
	{
		key: 'data_est_inc_impct_id',
		route: '"/dataestate/incident-impact-analysis/:incidentId',
		component: (
			<PrivateRoute
				Component={IncidentImpactAnalysis}
				restrictedRoles={[
					'Platform Operations Admin/SRE',
					'Data Operations Admin',
					'Data Operations User',
					'Program Executive'
				]}
			/>
		)
	}
];

export default DataEstateRoutes;
