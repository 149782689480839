import { Box } from '@mui/material';
import LoaderComponent from 'components/Loader';

const DrawerLoader = () => {
	return (
		<Box width={500} mt={15}>
			<LoaderComponent />
		</Box>
	);
};

export default DrawerLoader;
