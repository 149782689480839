import { useEffect, useRef } from 'react';
import moment from 'moment-timezone';

import './ibp-obs.scss';
import AnalyticsVisualization from '../framework/analyticsVisualization';
import { powerBIdashboardUrl } from '../../service/config';

export default function BDRPowerPI() {
	const dataFetchedRef = useRef(false);

	moment.updateLocale(moment.locale(), { invalidDate: '' });

	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
	}, []);
    
	return (
		<div className="page-table jobs_tabledata">
			<AnalyticsVisualization
				params={{
					dashboardUrl: powerBIdashboardUrl.IBPBDRPowerBIDashboard,
					className: 'bdrPowerBiIframeContainer',
					otherParams: {
						name: 'PowerBI'
					},
					seamless: true
				}}
			/>
		</div>
	);
}
