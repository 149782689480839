import moment from 'moment-timezone';
import { Chip } from '@mui/material';

export const getDefaultRuleData = (statusParam: string | null) => {
    let title = 'Query Failures', status = {} as any, column: any[] = [], columnNames: string[] = [];

    function formatDuration(duration: any) {
        const durationInMinutes = parseInt(duration); // Assuming duration is in minutes
        const hours = Math.floor(durationInMinutes / 60);
        const minutes = durationInMinutes % 60;

        let formattedDuration = '';
        if (hours > 0) {
            formattedDuration += hours === 1 ? '1 Hour ' : `${hours} Hours `;
        }
        if (minutes > 0 || formattedDuration === '') {
            formattedDuration += minutes === 0 ? '0 Minute' : `${minutes} Minute${minutes === 1 ? '' : 's'}`;
        }
        return formattedDuration.trim();
    }

    const renderStatus = (status: any) => {
        switch (status) {
            case 'SUCCESS':
            case 'Successful':
                return <Chip label={status} color="success" />;
            case 'Failed':
            case 'Failure':
                return <Chip label={status} color="secondary" />;
            case 'NOT STARTED':
            case 'Not Started':
                return <Chip label={status} style={{ backgroundColor: '#1B1E35CC', color: '#71758F' }} />;
            default:
                return <Chip label={status} style={{ backgroundColor: '#299BFF26', color: '#299BFF' }} />;
        }
    };

    switch (statusParam) {
        case 'Query Failures':
            title = 'Query Failures';
            status = 'Query Failures';
            columnNames = [
                'Batch ID',
                'Query Duration',
                'Status',
               // 'Memory Utilized %',
                'Query Details',
                'Average Run time'
            ];
            column = [
                { key: 'name', name: 'Batch ID', minWidth: 270 },
                //{ key: 'Query Duration', name: 'Query Duration', minWidth: 200},
                { key: 'Query Duration', name: 'Query Duration', minWidth: 270,
                formatter(props: any) {
                    return formatDuration(props.row['Query Duration']);
                } },
            
                {
                    key: 'Status',
                    name: 'Status',
                    minWidth: 220,
                    formatter(props: any) {
                        const status = props.row.Status;
                        return renderStatus(status);
                    }
                },
                {
                     key: 'count',
                    //name: 'Memory Utilized %',
                     minWidth: 250,
                     formatter(props: any) {
                        return (
                            <div title={props.row.reason} className="has-ellipsis">
                                {props.row.reason}
                            </div>
                        );
                    }
                },
                { key: 'Query Details', name: 'Query Details', minWidth: 270 ,maxWidth:270},
                { key: 'project', name: 'Project Name', minWidth: 270 }, // Add comma here
                {
                    // key: 'Average Run time',
                    // name: 'Average Run time',
                    // minWidth: 20,
                    // formatter(props: any) {
                    //     const avgTime = props.row.avgTime;
                    //     return formatDuration(avgTime);
                    // }
                     key: 'Average Run time', name: 'Average Run time', minWidth: 270,
                    formatter(props: any) {
                    return formatDuration(props.row['Average Run time']);
                } },
            
                
                {
                    key: 'lastRunDuration',
                    name: 'Last Execution Duration',
                    minWidth: 200,
                    formatter(props: any) {
                        const lastRunDuration = props.row.lastRunDuration;
                        return formatDuration(lastRunDuration);
                    }
                },
                { key: 'instance', name: 'Instance', minWidth: 100 }
            ];
            break;
        case 'Long running Batch Id queries':
            title = 'Long running Batch Id queries';
            status = 'Long running Batch Id queries';
            columnNames = [
                'Batch ID',
                'Query Duration',
                //'Memory Utilized %',
                'Query Details',
                'Greater than10 minutes (yes/no)',
                'Average Run time'
            ];
            column = [
                { key: 'name', name: 'Batch ID', minWidth: 250 },
               // { key: 'Query Duration', name: 'Query Duration', minWidth: 220 },
               { key: 'Query Duration', name: 'Query Duration', minWidth: 270,
               formatter(props: any) {
                   return formatDuration(props.row['Query Duration']);
               } },
                {
                    key: 'jobStatus',
                    name: 'Status',
                    minWidth: 100,
                    formatter(props: any) {
                        const status = props.row.jobStatus;
                        return renderStatus(status);
                    }
                },
                {
                    key: 'count',
                    // name: 'Memory Utilized %',
                     minWidth: 200,
                    formatter(props: any) {
                        return (
                            <div title={props.row.reason} className="has-ellipsis">
                                {props.row.reason}
                            </div>
                        );
                    },
                },
                { key: 'Query Details', name: 'Query Details', minWidth: 200,maxWidth:250 },
                { key: 'Greater than 10 minutes (Yes/No)', name: 'Greater than10 minutes (yes/no)', minWidth: 250 }, // Add comma here
                {
                    // key: 'Average Run time',
                    // name: 'Average Run time',
                    // minWidth: 200,
                    // formatter(props: any) {
                    //     const avgTime = props.row.avgTime;
                    //     return formatDuration(avgTime);
                    key: 'Average Run time', name: 'Average Run time', minWidth: 270,
                    formatter(props: any) {
                        return formatDuration(props.row['Average Run time']);
                    }
                },
                {
                    key: 'lastRunDuration',
                    name: 'Last Execution Duration',
                    minWidth: 200,
                    formatter(props: any) {
                        const lastRunDuration = props.row.lastRunDuration;
                        return formatDuration(lastRunDuration);
                    }
                },
                { key: 'instance', name: 'Instance', minWidth: 100 }
            ];
            break;
default:
			title = 'Query Failures';
			status = 'Query Failures';
			columnNames = [
				                'Batch ID',
                                'Query Duration',
                                'Status',
                                //'Memory Utilized %',
                                'Query Details',
                                'Average Run time'
			];
			column = [
				{ key: 'name', name: 'Batch ID', minWidth: 150 },
				{ key: 'Query Duration', name: 'Query Duration', minWidth: 100 },
				{ key: 'Status', name: 'Status', minWidth: 100 },
				//{ key: 'count', name: 'Memory Utilized %', minWidth: 250 },
				{ key: 'Query Details', name: 'Query Details', minWidth: 100 ,maxWidth:200},
				{ key: 'count', name: 'Average Run time', minWidth: 100 },
							];
            }
    return { title, status, columnNames, column };
};
